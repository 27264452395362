import { types } from '@catalytic/react-view';
import { Box } from 'grommet';
import React from 'react';
import { FormField } from '../../components/FormField';
import { Markdown } from '../../components/Markdown';
import { StyledTextInput } from './TextView.styled';

export function TextViewReader(props: types.TextView) {
  return <Box pad={{ bottom: 'small' }}><Markdown value={props.value} /></Box>
}

export function TextViewEditor(props: types.TextView) {
  return <StyledTextInput
    disabled={props.node().disabled}
    name={props.node().reference}
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue(e.target.value);
    }}
    value={props.value ?? props.defaultValue() ?? ''} />
}

export function TextView(props: types.TextView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <TextViewReader {...props} />
      : <TextViewEditor {...props} />}
  </FormField>
}
