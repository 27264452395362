import { toSQL } from '@catalytic/query-util-to-sql';
import { Select, select } from '@catalytic/query';
import { Filter, Sort } from 'grommet-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelect } from '../../query/query.hooks';
import { ViewContextExtend } from '../../util/ViewContextExtend';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { EditorViewMap } from './CollectionToolbar.constants';
import { FilterList, FilterListChangeEvent } from './FilterList';
import { SortList, SortListChangeEvent } from './SortList';
import { CollectionViewProps } from './View.interfaces';
import { fromWhereClause } from '../../query/query.transforms';
import { isIdentifier, isLiteral } from '../../query/query.guards';

export type Query = string | Select | Record<string, Select | string>;

export interface QueryChangeEvent<S extends string | Select = string> {
  select: S
}

export interface CollectionToolbarProps<S extends string | Select = string> extends CollectionViewProps {
  select?: S,
  maxSortCount?: number,
  onChange?: (event: QueryChangeEvent<S>) => void
}

export const CollectionToolbar = (props: CollectionToolbarProps) => {
  const defaultQuery = useSelect(props.select ?? (props.node?.select as string) ?? 'SELECT * FROM c');

  const [query, setQueryState] = useState(defaultQuery)
  const [active, setActive] = useState([
    ...(query?.where?.length ? ['filter'] : []),
    ...(query?.order?.length ? ['sort'] : [])
  ])
  useEffect(
    () => {
      setActive([
        ...(query?.where?.length ? ['filter'] : []),
        ...(query?.order?.length ? ['sort'] : [])
      ])
    },
    [query]
  )
  const handleChange = useCallback(
    (query: Select) => {
      const selectProp = props.select ?? (props.node?.select as string);
      const select = selectProp && typeof selectProp !== 'string' ? query : toSQL(query);
      if (props.onChange) props.onChange({ select } as any)
      console.log({ select })
      setQueryState(query);
    },
    [props.select, props.node?.select]
  );
  const handleOperationChange = useCallback(
    ({ operation }: FilterListChangeEvent) => {
      console.log({ operation })
      const where = operation.filter(op => !isLiteral(op.clause.right) || op.clause.right.value !== '')
      if (where.length) {
        const whereClause = fromWhereClause(where, undefined, query?.from);
        handleChange(
          select(
            {
              ...query,
              where: whereClause ? [whereClause] : []
            },
          ),
        )
      } else {
        if (query?.where) delete query.where;
        handleChange(select({ ...query }))
      }
    },
    [query, defaultQuery]
  );
  const handleOrderChange = useCallback(
    ({ order }: SortListChangeEvent) => {
      if (order.length) {
        handleChange(
          select({
            ...query,
            order
          })
        )
      } else {
        if (query?.order) delete query.order;
        handleChange(select({ ...query }))
      }
    },
    [query, defaultQuery]
  );

  return <ViewContextExtend editor={EditorViewMap} reader={EditorViewMap} >
    <ButtonGroup multiple toggle value={active} options={
      [
        {
          dropAlign: { top: 'bottom', right: 'right' },
          dropContent: <FilterList {...props} fromMap={query?.from as any} operation={query?.where as any} onChange={handleOperationChange} />,
          icon: <Filter />,
          label: 'Filter',
          value: 'filter'
        },
        {
          dropAlign: { top: 'bottom', right: 'right' },
          dropContent: <SortList {...props} order={query?.order as any} onChange={handleOrderChange} />,
          icon: <Sort />,
          label: 'Sort',
          value: 'sort'
        }
      ]
    } />
  </ViewContextExtend>
}
