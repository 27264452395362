import { ThemeProvider, ViewProvider } from "@catalytic/react-form";
import { Anchor, Box, Button, Heading, List, Text } from "grommet";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "../config/theme";
import { Header } from "../header/header";
import { LOGIN_PROVIDERS, LOGIN_REQUEST_FORM } from "./login.constants";

export default function Login() {
  return (
    <Router>
      <ThemeProvider full theme={theme}>
        <ViewProvider>
          <Box fill direction="row" background="background-contrast" align="center" justify="center">
            <Box width="medium" background="background" pad="medium" elevation="small">
              <Header />
              <Heading level={3}>Welcome back!</Heading>
              <List data={LOGIN_PROVIDERS} pad="none" primaryKey="name">
                {({ icon, name, url }) => (
                  <Button alignSelf="start" fill plain href={url}>
                    <Box direction="row" pad="medium" gap="xsmall">
                      {icon} <Text>Sign in with {name}</Text>
                    </Box>
                  </Button>
                )}
              </List>
              <Box pad="medium">
                <Text>No Account? <Anchor href={LOGIN_REQUEST_FORM}>Request one</Anchor></Text>
              </Box>
            </Box>
          </Box>
        </ViewProvider>
      </ThemeProvider>
    </Router>
  )
}
