import PQueue, { DefaultAddOptions, Options } from 'p-queue';
import PriorityQueue from 'p-queue/dist/priority-queue';
import React, { Context, createContext, PropsWithChildren } from 'react';
import { QueueConfigurationMap, QueueType } from './queue.interfaces';

export type QueueContextProps = {
  configuration: QueueConfigurationMap;
};

export const QueueContext: Context<QueueContextProps> = createContext({
  configuration: {}
});

export type QueueProviderProps = React.PropsWithChildren<Partial<QueueContextProps>>

export const QueueProvider = ({ children, configuration = {} }: QueueProviderProps) =>
  <QueueContext.Provider children={children} value={{ configuration }} />

export type QueueRegisterProps = React.PropsWithChildren<{
  name: string,
  options?: Options<PriorityQueue, DefaultAddOptions>
}>;


export const QueueRegister = ({ children, name, options }: QueueRegisterProps) => (
  <QueueContext.Consumer>
    {({ configuration }) => {
      return <QueueContext.Provider value={{
        configuration: {
          ...configuration,
          [name]: new PQueue(options)
        }
      }}>
        {children}
      </QueueContext.Provider>
    }}
  </QueueContext.Consumer>
);

export type FormQueueProviderProps = PropsWithChildren<{}>;

export const FormQueueProvider = ({ children }: FormQueueProviderProps) => (
  <QueueRegister name={QueueType.Form}> {children} </QueueRegister>
)
