import { types } from '@catalytic/react-view';
import { Text } from 'grommet';
import React from 'react';
import { FormField } from '../../components/FormField';
import { useLocaleDateTime, useLocalISODateTime } from './DateTimeView.hooks';
import { StyledDateInput } from './DateTimeView.styled';

export function DateTimeViewEditor(props: types.DateTimeView) {
  const value = useLocalISODateTime(props);
  return <StyledDateInput
    buttonProps={{
      disabled: props.node().disabled
    }}
    inputProps={{
      disabled: props.node().disabled
    }}
    dropProps={{ stretch: false }}
    name={props.node().reference}
    format="mm/dd/yyyy"
    onChange={(e: any) => {
      props.setValue(e.value);
    }}
    value={value} />
}

export function DateTimeViewReader(props: types.DateTimeView) {
  const value = useLocaleDateTime(props);
  return <Text>{value}</Text>
}

export function DateTimeView(props: types.DateTimeView) {
  const { children, ...others } = props;
  return <FormField {...others} >
    {props.node().role === 'reader'
      ? <DateTimeViewReader {...props} />
      : <DateTimeViewEditor {...props} />}
  </FormField>
}
