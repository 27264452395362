import { LiteralToken, TokenType, ReferenceToken, Token } from './template.interfaces';

export const literal = (value: string): LiteralToken =>
  ({ type: TokenType.Literal, value })

export const reference = (name: string): ReferenceToken =>
  ({ type: TokenType.Reference, name })

export const isLiteral = (token: Token): token is LiteralToken =>
  token.type === TokenType.Literal

export const isReference = (token: Token): token is ReferenceToken =>
  token.type === TokenType.Reference

const TOKEN_PATTERN = /\$\{(?<name>.+?)}/;

export function parse(template: string): Token[] {
  let tail = template;
  const tokens: Token[] = [];
  let match = tail.match(TOKEN_PATTERN);
  while (tail && match) {

    if (match.index) {
      tokens.push(literal(tail.substr(0, match.index)))
      tail = tail.substr(match.index)
    };

    tokens.push(reference((match as any).groups.name));
    tail = tail.substr(match[0].length)
    match = tail.match(TOKEN_PATTERN);
  }

  if (tail) tokens.push(literal(tail))

  return tokens;
}

export function format(template: string | Token[], data: Record<string, any>): string {
  const tokens = typeof template === 'string' ? parse(template) : template;
  return tokens.reduce(
    (o, token) => {
      if (isLiteral(token)) {
        return o + token.value
      }

      const value = data[token.name] || '';

      return typeof value === 'string'
        ? o + value
        : o + JSON.stringify(value)
    },
    ''
  )
};

export function validate(template: string | Token[], data: Record<string, any> = {}): boolean {
  const tokens = typeof template === 'string' ? parse(template) : template;

  const missing = tokens.find(
    token => isReference(token) && (data[token.name] === null || data[token.name] === undefined)
  )

  return !missing;
}
