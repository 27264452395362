import { InstanceStep } from './instance-step.interfaces';
import { CatalyticClient, InstanceStepsPage, Instance } from '@catalytic/sdk';
import { OrchestrationAPI } from '@catalytic/orchestration-sdk';
import pMap from 'p-map';

export const getStepActionType = (step: InstanceStep): string => (step as any).actionTypeId || 'manual';

const createStepFilter = (include: string[]) =>
  (step: InstanceStep): boolean => {
    const action: string = getStepActionType(step);
    return include.find(filter => action.startsWith(filter)) !== undefined
  }

export const isDone = (instance: Instance | InstanceStep) => instance.status === 'skipped' || instance.status === 'completed'

export const isManualTask = createStepFilter(['manual']);

export const isEmailWebFormTask = createStepFilter(['email/web-form']);

type FlattenStep = InstanceStep | InstanceStep[] | undefined;
type FlattenStepList = (FlattenStep)[];

const flattenSteps = (step: FlattenStep | FlattenStepList): InstanceStep[] =>
  step === undefined
    ? []
    : !Array.isArray(step)
      ? [step]
      : step.reduce(
        (o, s) => {
          const steps = flattenSteps(s);
          return (o as any).concat(steps)
        },
        [] as any
      ) as InstanceStep[]

const RUN_ID_PATTERN = /.*\/runs\/(?<runID>[^)]*)/;
const runIDFromDescription = (step: InstanceStep): string | undefined =>
  step.description?.match(RUN_ID_PATTERN)?.groups?.runID

export const findRunID = (step: InstanceStep): string | undefined => {
  try {
    const [{ outputFieldName }] = step.appStep?.params || [];
    if (!outputFieldName) return;
    const referenceName = `${outputFieldName.replace(/[.]/g, '-')}--run-id`;
    return step.outputFields?.find(field => field.referenceName === referenceName)?.value
      || runIDFromDescription(step);
  } catch (e) { }
}

type FindStepProps = {
  id: string,
  recursive?: boolean,
  catalytic: CatalyticClient,
  orchestration: OrchestrationAPI
}

export const findSteps = async ({ id, recursive = false, catalytic, orchestration }: FindStepProps): Promise<InstanceStep[] | undefined> => {
  try {
    const { steps }: InstanceStepsPage = await catalytic.instances.findInstanceSteps({ instanceID: id });
    const { tasks = [] } = await orchestration.getRun(id, { includeChildren: true });
    let nextSteps = steps?.map(step => {
      const task = tasks.find(task => task.taskID === step.id);
      // Manually set the app step information
      if (task) {
        (step as InstanceStep).appStep = task.appStep
      }
      return step
    })

    if (!recursive || !nextSteps) return nextSteps

    return flattenSteps(
      await pMap(
        nextSteps,
        async step => {
          const runID = findRunID(step)
          return !runID ? step : findSteps({ id: runID, recursive, catalytic, orchestration })
        }
      )
    )
  } catch (e) {
    console.error(e);
  }
}
