import {
  Clause,
  Expression,
  Func,
  Identifier,
  Limit,
  Literal,
  Map,
  Node,
  NodeType,
  Operation,
  Order,
  Range,
  Result,
  Select,
} from "@catalytic/query";
import { createNodeGuard, createNodeUnionGuard } from "./guard.factory";

export const isClause = createNodeUnionGuard<Clause>([
  NodeType.EXPRESSION,
  NodeType.FUNCTION,
  NodeType.IDENTIFIER,
  NodeType.LITERAL,
  NodeType.OPERATION,
  NodeType.RANGE,
  NodeType.SELECT,
]);
export const isExpression = createNodeGuard<Expression>(NodeType.EXPRESSION);
export const isFunction = createNodeGuard<Func>(NodeType.FUNCTION);
export const isIdentifier = createNodeGuard<Identifier>(NodeType.IDENTIFIER);
export const isLimit = createNodeGuard<Limit>(NodeType.LIMIT);
export const isLiteral = createNodeGuard<Literal>(NodeType.LITERAL);
export const isNode = createNodeUnionGuard<Node>(Object.values(NodeType));
export const isMap = createNodeGuard<Map>(NodeType.MAP);
export const isOperation = createNodeGuard<Operation>(NodeType.OPERATION);
export const isOrder = createNodeGuard<Order>(NodeType.ORDER);
export const isRange = createNodeGuard<Range>(NodeType.RANGE);
export const isResult = createNodeGuard<Result>(NodeType.RESULT);
export const isSelect = createNodeGuard<Select>(NodeType.SELECT);
