export function mergeKind<TKind = any>(...kind: any[]): TKind {
  const [first, ...others] = kind;
  return others.reduce<TKind>(
    (kind, next) => {
      if (kind !== undefined && typeof kind !== 'object') return kind;

      if (typeof next !== 'object') return kind

      if (Array.isArray(next)) return mergeKind(kind, ...next)

      return {
        ...next,
        ...kind
      }
    },
    Array.isArray(first) ? mergeKind(...first) : first
  )
}
