import { DateTime, Duration } from 'luxon';
import { factory } from 'mathjs';
import { Typed, TypedFactory, TypeSignatureFactory } from './types';

const dateTimeGetterFactory = <T>(key: string): Function =>
  factory(
    key,
    [],
    (): TypeSignatureFactory => (date: string): T => DateTime.fromISO(date)[key]
  );

export const createDateTime = factory(
  'DateTime',
  ['typed'],
  ({ typed }): TypeSignatureFactory => {
    ((typed as unknown) as Typed).addType({
      name: 'DateTime',
      test: (value: string): boolean => DateTime.fromISO(value).isValid
    });

    return (value: string): DateTime => DateTime.fromISO(value);
  }
);

export const createDuration = factory(
  'Duration',
  ['typed'],
  ({ typed }): TypeSignatureFactory => {
    ((typed as unknown) as Typed).addType({
      name: 'Duration',
      test: (value: string): boolean => Duration.fromISO(value).isValid
    });

    return (value: string): Duration => Duration.fromISO(value);
  }
);

export const now = factory(
  'now',
  [],
  (): TypeSignatureFactory => (): DateTime => DateTime.local()
);

export const day = dateTimeGetterFactory<number>('day');
export const daysInMonth = dateTimeGetterFactory<number>('daysInMonth');
export const daysInYear = dateTimeGetterFactory<number>('daysInYear');
export const hour = dateTimeGetterFactory<number>('hour');
export const millisecond = dateTimeGetterFactory<number>('millisecond');
export const minute = dateTimeGetterFactory<number>('minute');
export const month = dateTimeGetterFactory<number>('month');
export const monthLong = dateTimeGetterFactory<string>('monthLong');
export const monthShort = dateTimeGetterFactory<string>('monthShort');
export const offset = dateTimeGetterFactory<string>('offset');
export const quarter = dateTimeGetterFactory<number>('quarter');
export const second = dateTimeGetterFactory<number>('second');
export const weekNumber = dateTimeGetterFactory<number>('weekNumber');
export const weekYear = dateTimeGetterFactory<number>('weekYear');
export const weekday = dateTimeGetterFactory<number>('weekday');
export const weekdayLong = dateTimeGetterFactory<string>('weekdayLong');
export const weekdayShort = dateTimeGetterFactory<string>('weekdayShort');
export const weeksInWeekYear = dateTimeGetterFactory<number>('weeksInWeekYear');
export const year = dateTimeGetterFactory<number>('year');
export const zoneName = dateTimeGetterFactory<string>('zoneName');

export const dateAdd = factory(
  'dateAdd',
  [],
  (): TypeSignatureFactory => (date: string, duration: string): DateTime =>
    DateTime.fromISO(date).plus(Duration.fromISO(duration))
);
export const dateSubtract = factory(
  'dateSubtract',
  [],
  (): TypeSignatureFactory => (date: string, duration: string): DateTime =>
    DateTime.fromISO(date).minus(Duration.fromISO(duration))
);

export const createAddDateTime = factory(
  'add',
  ['typed', 'DateTime', 'Duration'] as any[],
  ({ typed }): TypedFactory => {
    return (typed as TypedFactory)('add', {
      'DateTime, Duration': (date: DateTime, duration: Duration): DateTime =>
        date.plus(duration)
    });
  }
);

export const createSubtractDateTime = factory(
  'subtract',
  ['typed', 'DateTime', 'Duration'] as any[],
  ({ typed }): TypedFactory => {
    return (typed as TypedFactory)('subtract', {
      'DateTime, Duration': (date: DateTime, duration: Duration): DateTime =>
        date.minus(duration)
    });
  }
);
