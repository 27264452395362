import { Context, createContext } from "react";
import createValidator, {
  JSONSchemaValidator,
} from "@catalytic/json-schema-validator";

const ValidatorContext: Context<JSONSchemaValidator> = createContext(
  createValidator()
);

export default ValidatorContext;
