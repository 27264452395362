import { Instance } from '@catalytic/sdk';
import { useCatalyticClient } from '../catalytic-client/catalytic-client.hooks';
import { useState, useEffect } from 'react';
import { UseClient, UseClientState } from '../client/client.hooks';

export const useInstance: UseClient<Instance> = (id?: string) => {
  const { instance: catalytic } = useCatalyticClient();
  const [state, setState] = useState<UseClientState<Instance>>({
    loading: true
  });
  useEffect(
    () => {
      if (!id) return;
      const init = async () => {
        try {
          const data = await catalytic.instances.get(id);
          setState({
            loading: false,
            data
          })
        } catch (e) {
          setState({
            loading: false,
            errors: [e]
          })
        }
      };
      init();
    },
    [id]
  )
  return state
};
