const types = require('./types');

const n = types.namedTypes;
const b = types.builders;

const Validator = require('./validator');

const validator = new Validator();

module.exports = validator
  .valid(n.File)
  .transform(
    n.BinaryExpression,
    path => b.pbxBinaryExpression(
      path.node.operator,
      path.node.left,
      path.node.right
    )
  )
  .transform(
    n.ExpressionStatement,
    path => b.pbxExpressionStatement(
      path.node.expression
    )
  )
  .transform(
    n.CallExpression,
    path => b.pbxFunctionExpression.apply(
      undefined,
      [path.node.callee].concat(path.node.arguments)
    ),
    path => b.pbxUnaryFunctionExpression(
      path.node.callee,
      path.node.arguments[0]
    )
  )
  .transform(
    n.Identifier,
    path => {
      if (n.CallExpression.check(path.parentPath.node)
          && path.parentPath.node.callee === path.node) {
        return path.node.name
      } else if (n.MemberExpression.check(path.parentPath.node)
                 && path.parentPath.node.property === path.node) {
        return b.pbxLiteral(path.node.name)
      }
        return b.pbxIdentifier(path.node.name)

    }
  )
  .transform(
    n.Literal,
    path => b.pbxLiteral(
      path.node.value
    )
  )
  .transform(
    n.LogicalExpression,
    path => path.node.operator === '&&'
        ? b.pbxLogicalAndExpression(
          path.node.left,
          path.node.right
        )
        : b.pbxLogicalOrExpression(
          path.node.left,
          path.node.right
        )
  )
  .transform(
    n.MemberExpression,
    path => b.pbxMemberExpression(
      path.node.object,
      path.node.property
    )
  )
  .transform(
    n.Program,
    path => b.pbxProgram.apply(undefined, path.node.body)
  )
  .transform(
    n.UnaryExpression,
    path => b.pbxUnarySignExpression(
      path.node.operator,
      path.node.argument
    ),
    path => b.pbxUnaryNotExpression(
      path.node.argument,
      path.node.operator
    )
  )
  .build();
