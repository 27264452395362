import React from 'react';
import { Button, ButtonType, DropButtonProps, DropButton, ButtonProps } from 'grommet';
import styled, { css } from 'styled-components';
import { createStyleFactory } from '../../style/style.factories';
import { applyButtonStyle } from '../../style/style.button';

const defaultButtonStyle = createStyleFactory<ButtonType>({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.components?.buttonGroup?.button || theme?.components?.button
})

export interface ExtendedButtonProps {
  index: number,
  hasLabel: boolean
}

export const buttonGroupStyle = css`
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1px solid;
  flex-grow: 1;
  min-height: 1.875em;
  ${({ index }: ExtendedButtonProps) => index === 0 && css`
border-left: none;
`}
  &:hover, &:active {
    box-shadow: none;
  }
`

export const StyledButton = styled<React.FC<ExtendedButtonProps & ButtonProps & Omit<JSX.IntrinsicElements['button'], 'color'>>>(Button)`
  ${defaultButtonStyle}
  ${buttonGroupStyle}
`

export const StyledDropButton = styled<React.FC<ExtendedButtonProps & DropButtonProps & ButtonProps & Omit<JSX.IntrinsicElements['button'], 'color'>>>(DropButton as any)`
  ${defaultButtonStyle}
  ${buttonGroupStyle}
`
