import {
  all,
  create as createMathJS,
  MathExpression,
  MathJsStatic
} from 'mathjs';

import * as comparator from './comparator';
import { preparse } from './preparse';

export interface Context extends Record<string, any> {}

export interface ExpressionContext extends Context {
  this: any;
  fields?: Context;
}

export interface Expression<N extends Context = Context> extends MathJsStatic {
  evaluate: (expr: MathExpression, scope?: N) => any;
}

export interface ExpressionFactory<N extends Context = Context> {
  (): Expression<N>;
}

export function create<N extends Context = ExpressionContext>(
  imports: math.ImportObject[]
): ExpressionFactory<N> {
  return function expression(): Expression<N> {
    const expression = createMathJS(
      { ...all, ...comparator },
      { matrix: 'Array' }
    ) as Expression<N>;
    expression.import(imports, {});
    const evaluate = expression.evaluate.bind(expression);
    expression.evaluate = function(expr: MathExpression, scope?: N): any {
      const expression = typeof expr === 'string' ? preparse(expr) : expr;
      return scope ? evaluate(expression, scope) : evaluate(expression);
    };
    return expression;
  };
}
