export enum TokenType {
  Literal = 'Literal',
  Reference = 'Reference'
}

export interface BaseToken {
  type: TokenType
}

export interface LiteralToken extends BaseToken {
  type: TokenType.Literal,
  value: string
}

export interface ReferenceToken extends BaseToken {
  type: TokenType.Reference,
  name: string
}

export type Token = LiteralToken | ReferenceToken;
