import React from 'react';
import { types, View } from '@catalytic/react-view';
import { TextInput } from 'grommet';
import { FormField } from '../../components/FormField';
import { ViewContextExtend } from '../../util/ViewContextExtend';
import { TableViewInput } from '../TableView';
import { useDataTableForm } from '../../data-table-form/data-table-form.hooks';
import { Error } from '../../components/Error';
import { Loading } from '../../components/Loading';

export function DefaultReferenceView(props: types.ReferenceView) {
  return <TextInput
    disabled={props.node().disabled}
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue(e.target.value);
    }}
    value={props.value ?? props.defaultValue() ?? ''} />
}

export function DataTableReferenceView(props: types.ReferenceView) {
  const { data, error, loading, submit } = useDataTableForm({ id: props.value, disabled: props.node().disabled });

  if (error) return <Error pad="small" message={error.message} />;
  if (loading || !data) return <Loading pad="small" />;

  return <ViewContextExtend editor={{ tableView: TableViewInput }}>
    <View
      node={data.node}
      schema={data.schema}
      defaultValue={data.value}
      onUpdate={({ value }) => {
        if (submit) submit(value as any);
      }} />
  </ViewContextExtend>
}

const refTypes = {
  DATA_TABLE: DataTableReferenceView,
  DEFAULT: DefaultReferenceView
}

export function ReferenceView(props: types.ReferenceView) {
  const { children, ...others } = props;
  const value = props.value || props.defaultValue();
  const refType = value === undefined || value === ''
    ? 'DEFAULT'
    : props.node().refType || props.schema()?.refType || 'DEFAULT'
  const Component = refTypes[refType] || DefaultReferenceView;
  return <FormField {...others}>
    <Component {...props} />
    <TextInput
      disabled={props.node().disabled}
      type="hidden"
      name={props.node().reference}
      value={props.value ?? props.defaultValue() ?? ''} />
  </FormField>
}
