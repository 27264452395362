import mem from 'mem';
import { FormBinder, FormConfiguration, FormBinderProps } from './form-binding.interfaces'

export abstract class MemoizedFormBinder<C extends FormConfiguration = FormConfiguration, O = any> implements FormBinder<C, O> {

  private cache = mem(this.memoizedBind, {
    cacheKey: ([{ configuration }]) => JSON.stringify(configuration)
  });

  abstract type: string;

  abstract memoizedBind(props: FormBinderProps<C>): Promise<O>;

  async bind(props: FormBinderProps<C>): Promise<O> {
    return this.cache(props);
  }

};
