export const DELIMITER = ':';
export const SECONDARY_DELIMITER = '/';
export const TERTIARY_DELIMITER = '|';
export const QUATERNARY_DELIMITER = '@';

const PATTERN_DELIMITER = /:/g;
const PATTERN_SECONDARY_DELIMITER = /\//g;
const PATTERN_TERTIARY_DELIMITER = /\|/g;
const PATTERN_QUATERNARY_DELIMITER = /@/g;
const PATTERN_ANY_DELIMITER = /[:/|@]/;

export type URISignature = {
  type: string;
  contextType?: string;
};

export type URI = URISignature & {
  id: string;
  context?: string;
};

export type URIComponents = [
  string,
  string | undefined,
  string | undefined,
  string
];

export type ParserOptions = {
  delimiter?: string | RegExp;
  signature?: URISignature;
};

export type FormatterOptions = {
  delimiter?: string;
};

export type CreateParserOptions<O = any> = ParserOptions & {
  transform: (uri: URI) => O;
};

export type CreateFormatterOptions<
  I = URI | URIComponents
> = FormatterOptions & {
  transform: (uri: I) => URI;
};

export const isURI = (originalID: string, options?: ParserOptions): boolean => {
  const pattern = options?.delimiter || PATTERN_ANY_DELIMITER;

  const id = options?.signature ? find(originalID, options) : originalID; // eslint-disable-line @typescript-eslint/no-use-before-define

  if (!id) {
    return false;
  }

  const delimiter =
    pattern instanceof RegExp ? (id.match(pattern) || [])[0] : pattern;
  return id.indexOf(delimiter) !== -1 && id.split(delimiter).length === 4;
};

export const shift = (uri: string): string =>
  uri
    .replace(PATTERN_TERTIARY_DELIMITER, QUATERNARY_DELIMITER)
    .replace(PATTERN_SECONDARY_DELIMITER, TERTIARY_DELIMITER)
    .replace(PATTERN_DELIMITER, SECONDARY_DELIMITER);

export const unshift = (uri: string): string =>
  uri
    .replace(PATTERN_SECONDARY_DELIMITER, DELIMITER)
    .replace(PATTERN_TERTIARY_DELIMITER, SECONDARY_DELIMITER)
    .replace(PATTERN_QUATERNARY_DELIMITER, TERTIARY_DELIMITER);

export const normalize = (uri: string): string => {
  let delimiter = (uri.match(PATTERN_ANY_DELIMITER) || [])[0];

  if (!delimiter) return uri;

  let nextURI = uri;

  while (delimiter !== DELIMITER) {
    nextURI = unshift(nextURI);
    delimiter = (nextURI.match(PATTERN_ANY_DELIMITER) || [])[0];
  }

  return nextURI;
};

export interface Formatter<I = URI | URIComponents> {
  (uri: I, options?: FormatterOptions): string;
}

export const format: Formatter = (
  uri: URI | URIComponents,
  options?: FormatterOptions
): string => {
  const [type, contextType, context, id] = Array.isArray(uri)
    ? uri
    : [uri.type, uri.contextType, uri.context, uri.id];
  return [
    type,
    contextType,
    context && isURI(context) ? shift(context) : context,
    isURI(id) ? shift(id) : id
  ].join((options || {}).delimiter || DELIMITER);
};

export interface Parser<O = URI> {
  (uri: string, options?: ParserOptions): O;
}

export const parse: Parser = (uri: string, options?: ParserOptions): URI => {
  const pattern = options?.delimiter || PATTERN_ANY_DELIMITER;
  const signature = options?.signature;

  const delimiter =
    pattern instanceof RegExp ? (uri.match(pattern) || [])[0] : pattern;
  const [type, contextType, context, id] = uri.split(delimiter);

  const components = {
    type,
    contextType,
    context:
      typeof context === 'string' && isURI(context)
        ? unshift(context)
        : context,
    id
  };

  if (
    !signature ||
    (components.type === signature.type &&
      components.contextType === signature.contextType)
  ) {
    return components;
  }

  if (isURI(components.context)) {
    return parse(components.context, options);
  }

  throw new Error(`Can not parse URI ${uri}`);
};

export const find = (
  uri: string,
  options?: ParserOptions
): string | null | undefined => {
  try {
    return format(parse(uri, options));
  } catch (e) {}
};

const DEFAULT_TRANSFORM = (v: any): any => v;

export function createFormatter<I = any>(
  options?: CreateFormatterOptions<I>
): Formatter<I> {
  const { delimiter = DELIMITER, transform = DEFAULT_TRANSFORM } =
    options || {};

  return (uri: I, options?: FormatterOptions): string =>
    format(transform(uri), { delimiter: options?.delimiter || delimiter });
}

export function createParser<O = URI>(
  options?: CreateParserOptions<O>
): Parser<O> {
  const {
    delimiter = PATTERN_ANY_DELIMITER,
    signature,
    transform = DEFAULT_TRANSFORM
  } = options || {};

  return (uri: string, options?: ParserOptions): O => {
    const components = parse(uri, {
      delimiter: options?.delimiter || delimiter,
      signature: options?.signature || signature
    });

    return components ? transform(components) : undefined;
  };
}
