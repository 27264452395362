import React, { PropsWithChildren } from 'react';
import { ThemeProvider, ThemeProviderProps } from '../theme/theme.provider';
import { ViewProvider, ViewProviderProps } from '../view/view.provider';
import { FormBinderContext } from '../form-binding/form-binding.providers';

export type FormProviderType = {
  view?: Omit<ViewProviderProps, 'children'>
} & ThemeProviderProps

export type FormProviderProps = PropsWithChildren<FormProviderType>

export const FormProvider = ({ children, view, ...props }: FormProviderProps) => {
  return <ThemeProvider {...props}>
    <FormBinderContext.Provider value={{ configuration: {} }}>
      <ViewProvider {...view}>
        {children}
      </ViewProvider>
    </FormBinderContext.Provider>
  </ThemeProvider>
}
