import React, { useMemo, useState, useCallback, useEffect, ReactNode } from 'react';
import { BoxProps, Nav } from 'grommet';
import { StyledButton, StyledDropButton } from './ButtonGroup.styled';
import { createNextID } from '../../util/nextID';
import { useHandler } from '../../util/useHandler';

const nextID = createNextID({ prefix: '' });

export type ButtonGroupOptionValue = string | number | boolean;

export interface ButtonGroupOption {
  disabled?: boolean,
  dropAlign?: {
    top?: 'top' | 'bottom';
    bottom?: 'top' | 'bottom';
    right?: 'left' | 'right';
    left?: 'left' | 'right';
  };
  dropContent?: JSX.Element;
  href?: string;
  icon?: JSX.Element;
  key?: string;
  label?: ReactNode;
  target?: '_self' | '_blank' | '_parent' | '_top' | string;
  value: ButtonGroupOptionValue
}


export interface ButtonGroupProps extends BoxProps {
  disabled?: boolean,
  multiple?: boolean,
  onChange?: (selected: ButtonGroupOptionValue[]) => void,
  options?: (ButtonGroupOptionValue | ButtonGroupOption)[]
  size?: "small" | "medium" | "large",
  toggle?: boolean,
  value?: ButtonGroupOptionValue | ButtonGroupOptionValue[]
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  direction = 'row',
  disabled,
  gap = '0px',
  multiple = false,
  onChange,
  options: optionsProp,
  size,
  toggle = false,
  value,
  ...rest
}) => {

  const handleChange = useHandler(onChange);
  const options = useMemo<ButtonGroupOption[]>(
    () =>
      (optionsProp || []).map(o =>
        typeof o !== 'object'
          ? {
            disabled,
            key: `${o}`,
            label: typeof o !== 'string' ? JSON.stringify(o) : o,
            value: o
          }
          : {
            disabled,
            key: `${o.value}`,
            ...o
          },
      ),
    [disabled, optionsProp],
  )

  const [selected, setSelected] = useState<string[]>([])

  useEffect(
    () => {
      if (value === undefined) return;
      const nextValues = (
        Array.isArray(value) ? value : [value]
      ).map(
        value => `${value}`
      );
      setSelected(nextValues)
    },
    [value]
  )

  const isSelected = useCallback(
    (key: string) => selected.includes(key),
    [selected, value]
  )

  const select = useCallback(
    (key: string) => {
      if (!toggle && (!multiple && isSelected(key))) return;

      const next = !multiple
        ? [key]
        : isSelected(key)
          ? selected.filter(k => k !== key)
          : selected.slice().concat([key]);

      handleChange(
        options.filter(
          option => typeof option.key === 'string' && next.includes(option.key)
        ).map(
          option => option.value
        )
      );

      if (value === undefined) {
        setSelected(next);
      }
    },
    [toggle, selected, value]
  )

  return <Nav
    background="white"
    border={{
      color: 'border-1'
    }}
    direction={direction}
    gap={gap}
    round="5px"
    {...rest}
  >
    {options.map(
      ({ key = nextID(), value, dropContent, dropAlign, ...props }, index) =>
        dropContent
          ? <StyledDropButton {...props} active={toggle && isSelected(key)} dropAlign={dropAlign} dropContent={dropContent} hasLabel index={index} key={key} onClick={(() => { select(key) }) as any} size={size} />
          : <StyledButton {...props} active={toggle && isSelected(key)} hasLabel index={index} key={key} onClick={() => { select(key) }} size={size} />
    )}
  </Nav>
}
