import { JSONSchema, View } from '@catalytic/react-view';
import { Box, Button, Form as GrommetForm } from 'grommet';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Node } from 'unist';
import { UseFormState, FormDisabled } from '../../form/form.hooks';
import { FormSubmitEvent, FormTypeProps, FormValue } from '../../form/form.interfaces';
import { fromJSONDictionary } from '../../util/toJSONValue';
import { disableNode } from './Form.utils';
import { FormQueue } from '../../queue/queue.hooks';

export interface FormState<N extends Node = Node, V = FormValue> {
  node: N,
  value?: V
}

export interface FormProps<N extends Node = Node, V = FormValue> extends Omit<UseFormState<N, V>, 'data' | 'disabled' | 'loading'>, FormTypeProps<N, V>, FormState<N, V> {
  defaultValue?: V,
  disabled?: FormDisabled<N, V> | boolean,
  schema: JSONSchema
}

export function Form<N extends Node = Node, V = FormValue>({ disabled: disabledProp, defaultValue, error, node: nodeProp, onChange, onSubmit, onReset, schema, submit, value: valueProp, ...props }: FormProps<N, V>) {
  const valuePropRef = useRef(valueProp ?? defaultValue);
  const disabled = useMemo<boolean>(
    () =>
      typeof disabledProp === 'function'
        ? disabledProp({ node: nodeProp, value: (valueProp ?? defaultValue) as any, schema })
        : disabledProp === true,
    [disabledProp, nodeProp, valueProp, defaultValue]
  )
  const [state, setState] = useState<FormState<N, V>>({
    node: disabled ? disableNode(nodeProp) : nodeProp,
    value: valueProp ?? defaultValue
  })
  const isControlled = valueProp !== undefined;

  useEffect(
    () => {
      if (valueProp !== undefined) {
        setState({
          ...state,
          value: valueProp
        })
      }
    },
    [valueProp]
  )

  useEffect(
    () => {
      setState({
        ...state,
        node: disabled
          ? disableNode(nodeProp)
          : nodeProp
      })
    },
    [disabled, nodeProp]
  )

  const handleChange = useCallback((v: V) => {
    if (onChange) {
      onChange({ value: v });
    }
    if (!isControlled) {
      setState({
        ...state,
        value: v
      });
    }
  }, [onChange, isControlled])

  const handleReset = useCallback(() => {
    const initialValue = { ...valuePropRef.current } as V
    if (onReset) {
      onReset({ node: state.node, value: initialValue })
    }
    if (!isControlled) {
      setState({
        ...state,
        value: initialValue
      });
    }
  }, [onReset, isControlled])

  const handleSubmit = useCallback(
    (e: FormSubmitEvent) => {
      const handle = async () => {
        if (!submit && !onSubmit) return;

        let value: any = fromJSONDictionary(e.value as any, schema);

        if (submit) {
          value = await submit(value)
        }

        e.value = value;

        if (onSubmit) {
          onSubmit(e as any);
        }
      }

      handle();
    },
    [onSubmit, submit]
  )


  return <Box {...props}>
    <FormQueue>
      {({ active }) => <GrommetForm
        onSubmit={handleSubmit as any}
        onReset={handleReset}
      >
        <Box gap="medium">
          <View node={state.node} schema={schema} value={state.value as any} onUpdate={
            ({ value, valueReference }) => {
              // Only send change events on root value
              if (valueReference === '') handleChange(value as any)
            }
          } />
          <Box direction="row-reverse" gap="small" align="start" pad={{ bottom: "medium" }}>
            <Button type="submit" label="Submit" primary disabled={!!active || disabled} />
            <Button type="reset" label="Reset" secondary disabled={!!active || disabled} />
          </Box>
        </Box>
      </GrommetForm>}
    </FormQueue>
  </Box>;
}

Form.whyDidYouRender = true
