/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const accessrequestid: msRest.OperationURLParameter = {
  parameterPath: "accessrequestid",
  mapper: {
    required: true,
    serializedName: "accessrequestid",
    type: {
      name: "String"
    }
  }
};
export const action0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "action"
  ],
  mapper: {
    serializedName: "action",
    type: {
      name: "String"
    }
  }
};
export const action1: msRest.OperationURLParameter = {
  parameterPath: "action",
  mapper: {
    required: true,
    serializedName: "action",
    type: {
      name: "String"
    }
  }
};
export const actionID: msRest.OperationURLParameter = {
  parameterPath: "actionID",
  mapper: {
    required: true,
    serializedName: "actionID",
    type: {
      name: "String"
    }
  }
};
export const actionInvocationID: msRest.OperationURLParameter = {
  parameterPath: "actionInvocationID",
  mapper: {
    required: true,
    serializedName: "actionInvocationID",
    type: {
      name: "String"
    }
  }
};
export const addFields: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "addFields"
  ],
  mapper: {
    serializedName: "addFields",
    type: {
      name: "String"
    }
  }
};
export const after: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "after"
  ],
  mapper: {
    serializedName: "after",
    type: {
      name: "String"
    }
  }
};
export const allUsers: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "allUsers"
  ],
  mapper: {
    serializedName: "allUsers",
    type: {
      name: "Boolean"
    }
  }
};
export const appname: msRest.OperationURLParameter = {
  parameterPath: "appname",
  mapper: {
    required: true,
    serializedName: "appname",
    type: {
      name: "String"
    }
  }
};
export const appversion: msRest.OperationURLParameter = {
  parameterPath: "appversion",
  mapper: {
    required: true,
    serializedName: "appversion",
    type: {
      name: "String"
    }
  }
};
export const authWords: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "authWords"
  ],
  mapper: {
    serializedName: "authWords",
    type: {
      name: "String"
    }
  }
};
export const capabilityID: msRest.OperationURLParameter = {
  parameterPath: "capabilityID",
  mapper: {
    required: true,
    serializedName: "capabilityID",
    type: {
      name: "String"
    }
  }
};
export const category: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "category"
  ],
  mapper: {
    serializedName: "category",
    type: {
      name: "String"
    }
  }
};
export const childprocessid: msRest.OperationURLParameter = {
  parameterPath: "childprocessid",
  mapper: {
    required: true,
    serializedName: "childprocessid",
    type: {
      name: "String"
    }
  }
};
export const clientId: msRest.OperationQueryParameter = {
  parameterPath: "clientId",
  mapper: {
    required: true,
    serializedName: "client_id",
    type: {
      name: "String"
    }
  }
};
export const columns: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "columns"
  ],
  mapper: {
    serializedName: "columns",
    type: {
      name: "String"
    }
  }
};
export const configurationFileID: msRest.OperationURLParameter = {
  parameterPath: "configurationFileID",
  mapper: {
    required: true,
    serializedName: "configurationFileID",
    type: {
      name: "String"
    }
  }
};
export const contentType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "contentType"
  ],
  mapper: {
    serializedName: "content_type",
    type: {
      name: "String"
    }
  }
};
export const controlID: msRest.OperationURLParameter = {
  parameterPath: "controlID",
  mapper: {
    required: true,
    serializedName: "controlID",
    type: {
      name: "String"
    }
  }
};
export const createdBy: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "createdBy"
  ],
  mapper: {
    serializedName: "createdBy",
    type: {
      name: "String"
    }
  }
};
export const customIntegrationWildcard: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "customIntegrationWildcard"
  ],
  mapper: {
    serializedName: "customIntegrationWildcard",
    type: {
      name: "String"
    }
  }
};
export const done: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "done"
  ],
  mapper: {
    serializedName: "done",
    type: {
      name: "String"
    }
  }
};
export const email: msRest.OperationQueryParameter = {
  parameterPath: "email",
  mapper: {
    required: true,
    serializedName: "email",
    type: {
      name: "String"
    }
  }
};
export const end: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "end"
  ],
  mapper: {
    serializedName: "end",
    type: {
      name: "Date"
    }
  }
};
export const endDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "endDate"
  ],
  mapper: {
    serializedName: "endDate",
    type: {
      name: "String"
    }
  }
};
export const exportid: msRest.OperationURLParameter = {
  parameterPath: "exportid",
  mapper: {
    required: true,
    serializedName: "exportid",
    type: {
      name: "String"
    }
  }
};
export const exportParameter: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "exportParameter"
  ],
  mapper: {
    serializedName: "export",
    type: {
      name: "String"
    }
  }
};
export const exportType: msRest.OperationQueryParameter = {
  parameterPath: "exportType",
  mapper: {
    required: true,
    serializedName: "exportType",
    type: {
      name: "String"
    }
  }
};
export const featurename: msRest.OperationURLParameter = {
  parameterPath: "featurename",
  mapper: {
    required: true,
    serializedName: "featurename",
    type: {
      name: "String"
    }
  }
};
export const fieldname: msRest.OperationURLParameter = {
  parameterPath: "fieldname",
  mapper: {
    required: true,
    serializedName: "fieldname",
    type: {
      name: "String"
    }
  }
};
export const fields: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fields"
  ],
  mapper: {
    serializedName: "fields",
    type: {
      name: "String"
    }
  }
};
export const fileID: msRest.OperationURLParameter = {
  parameterPath: "fileID",
  mapper: {
    required: true,
    serializedName: "fileID",
    type: {
      name: "String"
    }
  }
};
export const fileid0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileid"
  ],
  mapper: {
    serializedName: "fileid",
    type: {
      name: "String"
    }
  }
};
export const fileid1: msRest.OperationURLParameter = {
  parameterPath: "fileid",
  mapper: {
    required: true,
    serializedName: "fileid",
    type: {
      name: "String"
    }
  }
};
export const filename: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filename"
  ],
  mapper: {
    serializedName: "filename",
    type: {
      name: "String"
    }
  }
};
export const fileSize: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileSize"
  ],
  mapper: {
    serializedName: "file_size",
    type: {
      name: "String"
    }
  }
};
export const filter: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filter"
  ],
  mapper: {
    serializedName: "filter",
    type: {
      name: "String"
    }
  }
};
export const format: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "format"
  ],
  mapper: {
    serializedName: "format",
    type: {
      name: "String"
    }
  }
};
export const grantAction: msRest.OperationURLParameter = {
  parameterPath: "grantAction",
  mapper: {
    required: true,
    serializedName: "grantAction",
    type: {
      name: "String"
    }
  }
};
export const grantType0: msRest.OperationURLParameter = {
  parameterPath: "grantType",
  mapper: {
    required: true,
    serializedName: "grantType",
    type: {
      name: "String"
    }
  }
};
export const grantType1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "grantType"
  ],
  mapper: {
    serializedName: "grantType",
    type: {
      name: "String"
    }
  }
};
export const ignoreAvailability: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "ignoreAvailability"
  ],
  mapper: {
    serializedName: "ignoreAvailability",
    type: {
      name: "String"
    }
  }
};
export const importedCollection: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "importedCollection"
  ],
  mapper: {
    serializedName: "importedCollection",
    type: {
      name: "Boolean"
    }
  }
};
export const importid: msRest.OperationURLParameter = {
  parameterPath: "importid",
  mapper: {
    required: true,
    serializedName: "importid",
    type: {
      name: "String"
    }
  }
};
export const includeChildren: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeChildren"
  ],
  mapper: {
    serializedName: "includeChildren",
    type: {
      name: "Boolean"
    }
  }
};
export const includeDownloadUrls: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeDownloadUrls"
  ],
  mapper: {
    serializedName: "includeDownloadUrls",
    type: {
      name: "String"
    }
  }
};
export const includeIgnored: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeIgnored"
  ],
  mapper: {
    serializedName: "includeIgnored",
    type: {
      name: "Boolean"
    }
  }
};
export const includeSharedApps: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeSharedApps"
  ],
  mapper: {
    serializedName: "includeSharedApps",
    type: {
      name: "String"
    }
  }
};
export const includeVersions: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeVersions"
  ],
  mapper: {
    serializedName: "includeVersions",
    type: {
      name: "String"
    }
  }
};
export const includeWhollyOwned: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeWhollyOwned"
  ],
  mapper: {
    serializedName: "includeWhollyOwned",
    type: {
      name: "Boolean"
    }
  }
};
export const integrationDefinition: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "integrationDefinition"
  ],
  mapper: {
    serializedName: "integrationDefinition",
    type: {
      name: "String"
    }
  }
};
export const invitetoken: msRest.OperationURLParameter = {
  parameterPath: "invitetoken",
  mapper: {
    required: true,
    serializedName: "invitetoken",
    type: {
      name: "String"
    }
  }
};
export const isAvailable: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isAvailable"
  ],
  mapper: {
    serializedName: "isAvailable",
    type: {
      name: "Boolean"
    }
  }
};
export const jwt: msRest.OperationQueryParameter = {
  parameterPath: "jwt",
  mapper: {
    required: true,
    serializedName: "jwt",
    type: {
      name: "String"
    }
  }
};
export const limit0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "limit"
  ],
  mapper: {
    serializedName: "limit",
    type: {
      name: "String"
    }
  }
};
export const limit1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "limit"
  ],
  mapper: {
    serializedName: "limit",
    type: {
      name: "Number"
    }
  }
};
export const listRoots: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "listRoots"
  ],
  mapper: {
    serializedName: "listRoots",
    type: {
      name: "String"
    }
  }
};
export const lockRequestID: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "lockRequestID"
  ],
  mapper: {
    serializedName: "lockRequestID",
    type: {
      name: "String"
    }
  }
};
export const messageType: msRest.OperationURLParameter = {
  parameterPath: "messageType",
  mapper: {
    required: true,
    serializedName: "messageType",
    type: {
      name: "String"
    }
  }
};
export const modulename: msRest.OperationURLParameter = {
  parameterPath: "modulename",
  mapper: {
    required: true,
    serializedName: "modulename",
    type: {
      name: "String"
    }
  }
};
export const multipart: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "multipart"
  ],
  mapper: {
    serializedName: "multipart",
    type: {
      name: "String"
    }
  }
};
export const notificationid: msRest.OperationURLParameter = {
  parameterPath: "notificationid",
  mapper: {
    required: true,
    serializedName: "notificationid",
    type: {
      name: "String"
    }
  }
};
export const number: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "number"
  ],
  mapper: {
    serializedName: "number",
    type: {
      name: "String"
    }
  }
};
export const optional: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "optional"
  ],
  mapper: {
    serializedName: "optional",
    type: {
      name: "Boolean"
    }
  }
};
export const orderBy: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderBy"
  ],
  mapper: {
    serializedName: "orderBy",
    type: {
      name: "String"
    }
  }
};
export const owner: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "owner"
  ],
  mapper: {
    serializedName: "owner",
    type: {
      name: "String"
    }
  }
};
export const pageSize: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pageSize"
  ],
  mapper: {
    serializedName: "pageSize",
    type: {
      name: "Number"
    }
  }
};
export const pageToken: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pageToken"
  ],
  mapper: {
    serializedName: "pageToken",
    type: {
      name: "String"
    }
  }
};
export const parentprocessid: msRest.OperationURLParameter = {
  parameterPath: "parentprocessid",
  mapper: {
    required: true,
    serializedName: "parentprocessid",
    type: {
      name: "String"
    }
  }
};
export const partSize: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "partSize"
  ],
  mapper: {
    serializedName: "part_size",
    type: {
      name: "String"
    }
  }
};
export const predictorid: msRest.OperationURLParameter = {
  parameterPath: "predictorid",
  mapper: {
    required: true,
    serializedName: "predictorid",
    type: {
      name: "String"
    }
  }
};
export const previousVersion: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "previousVersion"
  ],
  mapper: {
    serializedName: "previous_version",
    type: {
      name: "String"
    }
  }
};
export const priority: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "priority"
  ],
  mapper: {
    serializedName: "priority",
    type: {
      name: "String"
    }
  }
};
export const processid: msRest.OperationURLParameter = {
  parameterPath: "processid",
  mapper: {
    required: true,
    serializedName: "processid",
    type: {
      name: "String"
    }
  }
};
export const processID: msRest.OperationURLParameter = {
  parameterPath: "processID",
  mapper: {
    required: true,
    serializedName: "processID",
    type: {
      name: "String"
    }
  }
};
export const processname: msRest.OperationURLParameter = {
  parameterPath: "processname",
  mapper: {
    required: true,
    serializedName: "processname",
    type: {
      name: "String"
    }
  }
};
export const progressSecret: msRest.OperationURLParameter = {
  parameterPath: "progressSecret",
  mapper: {
    required: true,
    serializedName: "progressSecret",
    type: {
      name: "String"
    }
  }
};
export const published: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "published"
  ],
  mapper: {
    serializedName: "published",
    type: {
      name: "String"
    }
  }
};
export const q: msRest.OperationQueryParameter = {
  parameterPath: "q",
  mapper: {
    required: true,
    serializedName: "q",
    type: {
      name: "String"
    }
  }
};
export const rowid: msRest.OperationURLParameter = {
  parameterPath: "rowid",
  mapper: {
    required: true,
    serializedName: "rowid",
    type: {
      name: "String"
    }
  }
};
export const rows: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "rows"
  ],
  mapper: {
    serializedName: "rows",
    type: {
      name: "String"
    }
  }
};
export const runid: msRest.OperationURLParameter = {
  parameterPath: "runid",
  mapper: {
    required: true,
    serializedName: "runid",
    type: {
      name: "String"
    }
  }
};
export const select: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "select"
  ],
  mapper: {
    serializedName: "select",
    type: {
      name: "String"
    }
  }
};
export const showOnStartMenu: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "showOnStartMenu"
  ],
  mapper: {
    serializedName: "showOnStartMenu",
    type: {
      name: "String"
    }
  }
};
export const signature: msRest.OperationQueryParameter = {
  parameterPath: "signature",
  mapper: {
    required: true,
    serializedName: "signature",
    type: {
      name: "String"
    }
  }
};
export const sort: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sort"
  ],
  mapper: {
    serializedName: "sort",
    type: {
      name: "String"
    }
  }
};
export const start: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "start"
  ],
  mapper: {
    serializedName: "start",
    type: {
      name: "Date"
    }
  }
};
export const startDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "startDate"
  ],
  mapper: {
    serializedName: "startDate",
    type: {
      name: "String"
    }
  }
};
export const status: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "status"
  ],
  mapper: {
    serializedName: "status",
    type: {
      name: "String"
    }
  }
};
export const stepid: msRest.OperationURLParameter = {
  parameterPath: "stepid",
  mapper: {
    required: true,
    serializedName: "stepid",
    type: {
      name: "String"
    }
  }
};
export const stepname: msRest.OperationURLParameter = {
  parameterPath: "stepname",
  mapper: {
    required: true,
    serializedName: "stepname",
    type: {
      name: "String"
    }
  }
};
export const subtype: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "subtype"
  ],
  mapper: {
    serializedName: "subtype",
    type: {
      name: "String"
    }
  }
};
export const system: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "system"
  ],
  mapper: {
    serializedName: "system",
    type: {
      name: "String"
    }
  }
};
export const tableid: msRest.OperationURLParameter = {
  parameterPath: "tableid",
  mapper: {
    required: true,
    serializedName: "tableid",
    type: {
      name: "String"
    }
  }
};
export const taskid: msRest.OperationURLParameter = {
  parameterPath: "taskid",
  mapper: {
    required: true,
    serializedName: "taskid",
    type: {
      name: "String"
    }
  }
};
export const taskID: msRest.OperationURLParameter = {
  parameterPath: "taskID",
  mapper: {
    required: true,
    serializedName: "taskID",
    type: {
      name: "String"
    }
  }
};
export const taskname: msRest.OperationURLParameter = {
  parameterPath: "taskname",
  mapper: {
    required: true,
    serializedName: "taskname",
    type: {
      name: "String"
    }
  }
};
export const teamname: msRest.OperationURLParameter = {
  parameterPath: "teamname",
  mapper: {
    required: true,
    serializedName: "teamname",
    constraints: {
      Pattern: /^[a-z0-9][a-z0-9-_]+$/
    },
    type: {
      name: "String"
    }
  }
};
export const teamName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "teamName"
  ],
  mapper: {
    serializedName: "teamName",
    type: {
      name: "String"
    }
  }
};
export const timestamp: msRest.OperationQueryParameter = {
  parameterPath: "timestamp",
  mapper: {
    required: true,
    serializedName: "timestamp",
    type: {
      name: "String"
    }
  }
};
export const token: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "token"
  ],
  mapper: {
    serializedName: "token",
    type: {
      name: "String"
    }
  }
};
export const transactionid: msRest.OperationURLParameter = {
  parameterPath: "transactionid",
  mapper: {
    required: true,
    serializedName: "transactionid",
    type: {
      name: "String"
    }
  }
};
export const types: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "types"
  ],
  mapper: {
    serializedName: "types",
    type: {
      name: "String"
    }
  }
};
export const upcomingid: msRest.OperationURLParameter = {
  parameterPath: "upcomingid",
  mapper: {
    required: true,
    serializedName: "upcomingid",
    type: {
      name: "String"
    }
  }
};
export const userID: msRest.OperationURLParameter = {
  parameterPath: "userID",
  mapper: {
    required: true,
    serializedName: "userID",
    type: {
      name: "String"
    }
  }
};
export const username: msRest.OperationURLParameter = {
  parameterPath: "username",
  mapper: {
    required: true,
    serializedName: "username",
    type: {
      name: "String"
    }
  }
};
export const userTokenID: msRest.OperationURLParameter = {
  parameterPath: "userTokenID",
  mapper: {
    required: true,
    serializedName: "userTokenID",
    type: {
      name: "String"
    }
  }
};
export const waitTimeMillis: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "waitTimeMillis"
  ],
  mapper: {
    serializedName: "waitTimeMillis",
    type: {
      name: "Number"
    }
  }
};
export const webhookid: msRest.OperationURLParameter = {
  parameterPath: "webhookid",
  mapper: {
    required: true,
    serializedName: "webhookid",
    type: {
      name: "String"
    }
  }
};
export const webhookname: msRest.OperationURLParameter = {
  parameterPath: "webhookname",
  mapper: {
    required: true,
    serializedName: "webhookname",
    type: {
      name: "String"
    }
  }
};
export const workerID: msRest.OperationURLParameter = {
  parameterPath: "workerID",
  mapper: {
    required: true,
    serializedName: "workerID",
    type: {
      name: "String"
    }
  }
};
export const xContentMd5: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "xContentMd5"
  ],
  mapper: {
    serializedName: "x-content-md5",
    type: {
      name: "String"
    }
  }
};
export const xForwardedFor0: msRest.OperationParameter = {
  parameterPath: "xForwardedFor",
  mapper: {
    required: true,
    serializedName: "x-forwarded-for",
    type: {
      name: "String"
    }
  }
};
export const xForwardedFor1: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "xForwardedFor"
  ],
  mapper: {
    serializedName: "x-forwarded-for",
    type: {
      name: "String"
    }
  }
};
