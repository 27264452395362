import React, { Context } from 'react';
import { FormBinderMap, FormBinder } from './form-binding.interfaces';

export type FormBinderContextProps = {
  configuration: FormBinderMap;
};

export const FormBinderContext: Context<FormBinderContextProps> = React.createContext({
  configuration: {}
});

export type FormBinderProviderProps = React.PropsWithChildren<Partial<FormBinderContextProps>>

export const FormBinderProvider = ({ children, configuration = {} }: FormBinderProviderProps) =>
  <FormBinderContext.Provider children={children} value={{ configuration }} />

export type FormBinderRegisterProps = React.PropsWithChildren<{
  binder: FormBinder,
  type?: string
}>;

export const FormBinderRegister = ({ children, binder, type }: FormBinderRegisterProps) => (
  <FormBinderContext.Consumer>
    {({ configuration }) => {
      return <FormBinderContext.Provider value={{
        configuration: {
          ...configuration,
          [type || binder.type]: binder
        }
      }}>
        {children}
      </FormBinderContext.Provider>
    }}
  </FormBinderContext.Consumer>
);
