import { DirectionType, identifier, order, Order, Identifier } from '@catalytic/query';
import { Button } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useCallback, useMemo } from 'react';
import { createNextID } from '../../util/nextID';
import { useColumns } from './Column.hooks';
import { List } from './List';
import { useList } from './List.hooks';
import { ListContainer } from './ListContainer';
import { ListFooter } from './ListFooter';
import { SortItem } from './SortItem';
import { CollectionViewProps } from './View.interfaces';

const nextID = createNextID({ prefix: 'sort-list-item' });

export interface SortListChangeEvent {
  order: Order<DirectionType, Identifier>[]
}

export interface SortListProps extends CollectionViewProps {
  order?: Order<DirectionType, Identifier>[],
  maxSortCount?: number,
  onChange?: (event: SortListChangeEvent) => void
}

export const SortList = (props: SortListProps) => {
  const { add, items: orders, remove, update } = useList(props.order);
  const columns = useColumns(props);
  const isAddDisabled = useMemo(
    () =>
      props.maxSortCount === undefined
        ? false
        : orders.length >= props.maxSortCount,
    [props.maxSortCount, orders.length]
  )
  const handleChange = useCallback<(event: SortListChangeEvent) => void>(
    ({ order }) => {
      if (props.onChange) {
        props.onChange({ order });
      }
    },
    [props.onChange]
  );
  const handleItemChange = useCallback(
    ({ index, order }: { index: number, order: Order }) => {
      handleChange({ order: update({ index, item: order }) })
    },
    [update]
  )
  const handleItemAdd = useCallback(
    () => {
      const [column] = columns;
      handleChange({
        order: add({
          item: order({
            direction: DirectionType.ASC,
            expression: identifier({
              name: column.value
            })
          }) as any
        })
      })
    },
    [columns, handleChange, add]
  );
  const handleItemRemove = useCallback(
    ({ index }: { index: number }) => {
      handleChange({ order: remove({ index }) })
    },
    []
  );

  return <List width={{ min: "35.75em", max: "35.75em" }}>
    <ListContainer>
      {
        orders.map(
          (order, index) =>
            <SortItem key={nextID()} columns={columns} order={order} onChange={
              ({ order }) => handleItemChange({ index, order })
            } onRemove={() => handleItemRemove({ index })} />
        )
      }
    </ListContainer>
    <ListFooter>
      <Button icon={<Add />} label="Add a sort" onClick={handleItemAdd} disabled={isAddDisabled} />
    </ListFooter>
  </List >
}
