import { types } from '@catalytic/react-view';
import { fromBlob } from 'file-type/browser';
import { useState, useEffect } from 'react';

export function useFile(props: types.FileView): File | undefined {
  const [file, setFile] = useState<File>();

  useEffect(
    () => {
      const value = props.value ?? props.defaultValue;
      if (value === undefined) return;

      const createFile = async () => {
        let file: File;

        if (value instanceof File) {
          file = value;
        } else {
          const info = await fromBlob(new Blob([value]));
          file = new File(
            [value],
            info?.ext ? `file.${info.ext}` : 'file',
            {
              endings: 'native',
              type: info?.mime ?? props.schema()?.contentMediaType ?? 'application/octet-stream'
            }
          )
        }

        setFile(file)
      }
      createFile()
    },
    [props.defaultValue, props.value]
  )

  return file;
}
