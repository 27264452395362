import { useMemo } from 'react';
import { types } from '@catalytic/react-view';
import { toLocalISO, toLocalLocaleString } from '../../date-time/date-time.utils';

export function useLocalISODateTime(props: types.DateTimeView): string {
  return useMemo(
    () => {
      const value = props.value ?? props.defaultValue();
      if (!value) return '';
      return toLocalISO(value)
    },
    [props.value]
  )
}

export function useLocaleDateTime(props: types.DateTimeView): string {
  return useMemo(
    () => {
      const value = props.value ?? props.defaultValue();
      if (!value) return '';
      return toLocalLocaleString(value, props.node())
    },
    [props.value]
  )
}
