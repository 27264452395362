import {
  Alias,
  Clause,
  Func,
  func,
  identifier,
  Identifier,
  Limit,
  limit,
  literal,
  Literal,
  map,
  Map,
  operation,
  Operation,
  order,
  Order,
  Range,
  range,
  result,
  Result,
  Select,
  select,
  Expression,
  expression,
  MapType,
} from "@catalytic/query";
import {
  AccessorCloseToken,
  AccessorOpenToken,
  AccessorToken,
  BinaryOperatorToken,
  ComparisonOperatorToken,
  Direction,
  DirectionToken,
  IdentifierToken,
  LiteralToken,
  MapToken,
  ResultFlag,
  ResultToken,
} from "../lexer/lexer.interfaces";
import {
  BinaryOperationMap,
  ComparisonOperationMap,
  DirectionMap,
  Transform,
} from "./grammar.interfaces";

export const fromLiteral: Transform<[LiteralToken], Literal> = ([
  { value },
]) => {
  return literal({ value });
};

export const fromIdentifier: Transform<[IdentifierToken], Identifier> = ([
  { value },
]) => {
  return identifier({ name: value });
};

export const fromMap: Transform<[MapToken, Clause], Map> = ([
  { value },
  source,
]) => {
  return map({
    source,
    variant: MapType[value],
  });
};

export const fromMapJoin: Transform<[Map, Map], Map> = ([left, right]) => {
  const next = map({ ...left });
  next.map = next.map ?? [];
  next.map.push(right);
  if (right.map) {
    next.map = next.map.concat(map({ ...right }));
  }
  return next;
};

export const fromRange: Transform<[Clause, Clause], Range> = ([start, end]) => {
  return range({ start, end });
};

export const fromResult: Transform<
  [ResultToken, ResultToken, Clause],
  Result
> = ([r1, r2, source]) => {
  const r = result({ source });
  if (
    (r1 && r1.value === ResultFlag.DISTINCT) ||
    (r2 && r2.value === ResultFlag.DISTINCT)
  ) {
    r.distinct = true;
  }
  if (
    (r1 && r1.value === ResultFlag.VALUE) ||
    (r2 && r2.value === ResultFlag.VALUE)
  ) {
    r.value = true;
  }
  return r;
};

export const fromAlias: Transform<[Alias, Identifier], Alias> = ([
  node,
  { name: alias },
]) => {
  return { ...node, alias };
};

export const fromExpression: Transform<Clause[][], Expression> = ([args]) => {
  return expression({ expression: args });
};

export const fromFunction: Transform<[Identifier, Expression], Func> = ([
  name,
  args,
]) => {
  return func({
    args,
    name,
  });
};

export const fromDotAccessorExpression: Transform<
  [Identifier, AccessorToken, Identifier],
  Identifier
> = ([{ name: left }, , { name: right }]) => {
  return identifier({
    name: `${left}.${right}`,
  });
};

export const fromBracketAccessorExpression: Transform<
  [Identifier, AccessorOpenToken, Literal, AccessorCloseToken],
  Identifier
> = ([{ name: left }, , { value: right }]) => {
  return identifier({
    name: `${left}[${JSON.stringify(right)}]`,
  });
};

export const fromComparisonOperation: Transform<
  [Clause, ComparisonOperatorToken, Clause],
  Operation
> = ([left, { value }, right]) => {
  return operation({
    left,
    operation: ComparisonOperationMap[value],
    right,
  });
};

export const fromBinaryOperation: Transform<
  [Clause, BinaryOperatorToken, Clause],
  Operation
> = ([left, { value }, right]) => {
  return operation({
    left,
    operation: BinaryOperationMap[value],
    right,
  });
};

export const fromLimit: Transform<
  [Clause | undefined, Clause | undefined, Limit | undefined],
  Limit
> = ([length, offset, limitToken = limit({})]) => {
  if (length) limitToken.length = length;
  if (offset) limitToken.offset = offset;
  return limitToken;
};

export const fromOrder: Transform<
  [Clause, DirectionToken | undefined, Order[] | undefined],
  Order[]
> = ([expression, directionToken, orders = []]) => {
  const direction = DirectionMap[directionToken?.value ?? Direction.ASC];
  return [
    ...orders,
    order({
      direction,
      expression,
    }),
  ];
};

export const fromSelect: Transform<
  [
    Select | undefined,
    Result[] | undefined,
    Map | undefined,
    Operation | undefined,
    Order[] | undefined,
    Limit | undefined,
    Limit | undefined
  ],
  Select
> = ([selectNode, result, fromNode, operation, order, offset, limit]) => {
  const node = selectNode ?? select({});
  if (result?.length) node.result = result;
  if (fromNode) node.from = fromNode as any;
  if (operation) node.where = [operation];
  if (order?.length) node.order = order;
  if (offset) node.limit = node.limit ? { ...node.limit, ...offset } : offset;
  if (limit) node.limit = node.limit ? { ...node.limit, ...limit } : limit;
  return node;
};
