import { FontType, TextType, FontKindType } from "../theme/theme.interfaces";
import { StyleFactory } from './style.interfaces';
import { css } from 'styled-components';

export const applyFontTypeStyle: StyleFactory<FontType> = (props, kind = props.theme?.types?.font) => {
  if (!kind) return '';
  const { size, weight } = kind;
  const text: TextType | undefined = typeof size === 'string' ? props.theme?.text?.[size] : undefined;

  const results: any[] = []

  if (weight) results.push(
    css`font-weight: ${weight};`
  )

  if (size) results.push(
    css`font-size: ${size};`
  )

  if (text?.height) results.push(
    css`line-height: ${text.height};`
  )

  return results
};

export const applyFontStyle: StyleFactory<FontKindType> = (props, kind) => {
  const font = kind?.font || props.theme?.types?.font;
  return font ? applyFontTypeStyle(props, font, font) : []
}
