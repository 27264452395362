import { TokenCredentials } from "@azure/ms-rest-js";
import { OrchestrationAPI as InternalOrchestrationAPI } from "./orchestrationAPI";
import { OrchestrationAPIOptions } from "./models";
import { AccessToken, parse } from "./token";

export class OrchestrationAPI extends InternalOrchestrationAPI {
  static formatBaseUri = ({ domain }: AccessToken): string => {
    return `https://${domain}/_`;
  };

  static parseTeamName = ({ domain }: AccessToken): string => {
    const [team] = domain.split(".");
    return team;
  };

  constructor(
    accessToken: string | AccessToken,
    options: OrchestrationAPIOptions = {}
  ) {
    const token =
      typeof accessToken === "string" ? parse(accessToken) : accessToken;
    const credentials = new TokenCredentials(token.secret);
    const teamname = OrchestrationAPI.parseTeamName(token);
    options.baseUri = options.baseUri || OrchestrationAPI.formatBaseUri(token);
    super(credentials, teamname, options);
  }
}
