import { css } from 'styled-components';
import { mergeDeepRight } from 'ramda';
import { ThemeType } from '../../theme/theme.interfaces';
import { theme as base } from '../base';

export enum Colors {
  LightPurple = '#f3eff9',
  Purple = '#4f2d7f',
  Gray = '#c8beaf',
  Blue = '#00a7b5',
  Green = '#9bd732',
  Magnesium = '#C0C0C0',
  Orange = '#ff7d1e',
  DarkOrange = '#F57B26',
  Red = '#de002e',
  Text = '#707070',
  Border = '#e0e0e0',
  BackSmoke = '#F5F5F5'
}

const extendedTheme: ThemeType = {
  global: {
    colors: {
      brand: Colors.Purple,
      control: Colors.Blue,
      background: { light: 'white' },
      'background-contrast': Colors.BackSmoke,
      border: Colors.Border,
      'border-1': Colors.Magnesium,
      'neutral-1': Colors.BackSmoke,
      'neutral-2': Colors.LightPurple,
      'status-error': Colors.Red,
      'status-warning': Colors.DarkOrange,
      'status-ok': Colors.Green,
      'status-unknown': 'white',
      text: {
        dark: "#f8f8f8",
        light: Colors.Text
      }
    },
    focus: {
      border: {
        color: 'border-1',
      },
      outline: {
        size: '0px'
      }
    },
    font: {
      size: '16px'
    },
    input: {
      font: {
        size: 'small',
        weight: '400'
      }
    }
  },
  accordion: {
    border: undefined
  },
  button: {
    border: {
      width: '1px',
      radius: '5px'
    },
    extend: ({ primary }) => primary ? 'color: white' : ''
  },
  icon: {
    size: {
      medium: '16px'
    }
  },
  dataTable: {
    primary: {
      weight: 'normal'
    }
  },
  formField: {
    border: {
      side: 'all'
    },
    round: '0px'
  },
  select: {
    background: 'white'
  },
  tab: {
    active: {
      background: 'control',
      color: 'white'
    },
    background: 'white',
    border: {
      side: 'all',
      color: 'border-1',
      size: '1px',
      active: {
        color: 'border-1'
      }
    },
    hover: {
      background: 'control',
      color: 'white'
    },
    margin: 'none',
    pad: {
      horizontal: 'medium',
      vertical: 'xxsmall'
    }
  },
  tabs: {
    background: 'neutral-1',
    extend: ({ theme }) =>
      `border: 1px solid ${theme?.global?.colors?.['border-1']}`,
    gap: 'none',
    header: {
      extend: ({ theme }) => css`
        margin-top: -1em;
        button:hover div {
          border-color: ${theme?.global?.colors?.['border-1']};
        }
        button ~ button div {
          border-left: none;
        }
        button:first-child div {
          border-radius: ${theme?.button?.border?.radius} 0 0 ${theme?.button?.border?.radius};
        }
        button:last-child div {
          border-radius: 0 ${theme?.button?.border?.radius} ${theme?.button?.border?.radius} 0;
        }
      `
    }
  },
  text: {
    medium: {
      height: '22px',
      size: '16px'
    }
  },
  textInput: {
    extend: () => 'background: white'
  },
  components: {
    label: {
      background: 'background-back'
    },
    input: {
      border: false
    },
    pageHeader: {
      heading: {
        color: 'brand'
      }
    }
  },
  views: {
    checkbox: {
      button: {
        checked: {
          background: 'control'
        },
        unchecked: {
          background: 'white'
        }
      }
    },
    date: {
      input: {
        background: 'white'
      }
    },
    dateTime: {
      input: {
        background: 'white'
      }
    },
    text: {
      input: {
        background: 'white'
      }
    },
    textArea: {
      input: {
        background: 'white'
      }
    },
    table: {
      container: {
        background: 'white'
      }
    },
    toggleSwitch: {
      button: {
        checked: {
          background: 'control'
        },
        unchecked: {
          background: 'white'
        }
      }
    }
  }
}

export const theme = mergeDeepRight(
  base,
  extendedTheme
) as ThemeType;
