import AJV, { Ajv } from 'ajv';
import ajvErrors from 'ajv-errors';
import { Duration } from 'luxon';
import { create } from '@catalytic/expression';
import { parse } from '@catalytic/pushbot-expression';
import { isURI } from '@catalytic/uri';
import isEmail from 'validator/lib/isEmail';
import isUUID from 'validator/lib/isUUID';
import { isTeamname } from './teamname';
import { JSONRefType } from './type';
import { isUsername } from './username';

type JSONSchemaValidatorOptions = {
  assertReference: (options: any) => boolean;
};
const expression = create();
const passthrough = (): boolean => true;

// AJV factor configured with domain keywords
export default function createJSONSchemaValidator(
  options?: JSONSchemaValidatorOptions
): Ajv {
  const { assertReference = passthrough } = options || {};

  const ajv = new AJV({
    allErrors: true,
    jsonPointers: true,
    meta: true,
    nullable: true,
    schemaId: 'auto',
    validateSchema: true
  });

  // Support Binary
  ajv.addFormat('binary', { type: 'string', validate: () => true });

  // Support ISO 8601 durations
  ajv.addFormat('duration', value => {
    try {
      const duration = Duration.fromISO(value);
      return duration.isValid;
    } catch (e) {
      return false;
    }
  });

  // Expression
  ajv.addFormat('expression', value => {
    try {
      expression.compile(value);
      return true;
    } catch (e) {
      return false;
    }
  });

  // Custom Formats
  ajv.addFormat('json', value => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  });
  ajv.addFormat('password', () => true);
  ajv.addFormat(
    'ref',
    value =>
      isURI(value) ||
      isUUID(value) ||
      isEmail(value) ||
      isUsername(value) ||
      isTeamname(value)
  );
  // Custom Key Words
  ajv.addKeyword('conditionalDependencies', {
    inline: (_it, _keyword, schema) => {
      parse(schema);
      return schema;
    },
    metaSchema: {
      type: 'string'
    }
  });
  ajv.addKeyword('expression', {
    validate: (
      schema,
      data,
      _parentSchema,
      _path,
      _parentData,
      _parentProperty,
      rootData
    ) => expression.evaluate(schema, { this: data, fields: rootData }),
    metaSchema: {
      format: 'expression',
      type: 'string'
    }
  });
  ajv.addKeyword('displayOnly', {
    metaSchema: {
      type: 'boolean'
    }
  });
  ajv.addKeyword('legacyFormat', {
    metaSchema: {
      type: 'string'
    }
  });
  ajv.addKeyword('minHeight', {
    metaSchema: {
      minimum: 1,
      type: 'number'
    }
  });
  ajv.addKeyword('minWidth', {
    metaSchema: {
      minimum: 1,
      type: 'number'
    }
  });
  ajv.addKeyword('maxHeight', {
    metaSchema: {
      minimum: 1,
      type: 'number'
    }
  });
  ajv.addKeyword('maxWidth', {
    metaSchema: {
      minimum: 1,
      type: 'number'
    }
  });
  ajv.addKeyword('refType', {
    validate: (type: string, id: string) => {
      if (!assertReference({ id, type })) {
        throw new Error(`Unable to resolve reference ${type} ${id}`);
      }
      return true;
    },
    metaSchema: {
      enum: Object.values(JSONRefType),
      type: 'string'
    }
  });

  // Apply AJV Errors to the schema.
  // Must come after the addKeyword calls otherwise custom error messages get
  // swallowed. https://github.com/epoberezkin/ajv-errors/issues/32
  ajvErrors(ajv, { keepErrors: true });

  return ajv;
}
