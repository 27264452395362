import { Box, BoxProps, Image, Text, Stack, Anchor } from 'grommet'
import React, { useRef, useCallback, useState } from 'react'
import { Document, Page } from 'react-pdf';
import { FileMetadata } from '../../file/file.interfaces'
import { Loading } from '../Loading/Loading';
import useResizeObserver from "use-resize-observer";
import { Download } from 'grommet-icons';

export interface FileViewerProps extends FileMetadata {
  containerProps?: BoxProps,
  scale?: number
}

export const FileViewer = ({
  containerProps,
  contentType,
  scale = 1,
  url
}: FileViewerProps) => {
  const { ref, width } = useResizeObserver<HTMLDivElement>();
  const [pages, setPages] = useState<any[]>([])
  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      const pages = Array.from(Array(numPages));
      console.log({ numPages, pages })
      setPages(pages)
    },
    []
  );
  return <Box background="background-contrast" alignContent="center" pad="medium" fill overflow="auto" {...containerProps}>
    <Stack anchor="center" alignSelf="center" fill>
      <Box ref={ref as any}>
        {
          url === undefined
            ? <Loading />
            : contentType === 'application/pdf'
              ? <Document file={url} loading={<Loading />} onLoadSuccess={onDocumentLoadSuccess}>
                <Box gap="medium">
                  {
                    pages.map(
                      (_value, pageNumber) => (
                        <Box overflow="hidden" key={pageNumber} background="white" elevation="small" >
                          <Page pageNumber={pageNumber + 1} width={(width ?? 0) * scale} />
                        </Box>
                      )
                    )
                  }
                </Box>
              </Document>
              : contentType?.startsWith('image/')
                ? <Box elevation="small"><Image src={url} width={(width ?? 0) * scale} /></Box>
                : <Box elevation="small" background="background" pad="small">
                  <Box align="center" pad="large">
                    <Anchor icon={<Download />} size="large" label="Download" href={url} target="_blank" />
                  </Box>
                </Box>
        }
      </Box>
    </Stack>
  </Box >
}
