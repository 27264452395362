import { Lexer as MooLexer, Token as MooToken } from "moo";
import { ALIAS, STAR } from "./lexer.constants";

export type Alias = typeof ALIAS;
export type Star = typeof STAR;

export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum MapVariant {
  FROM = "FROM",
  JOIN = "JOIN",
}

export enum Statement {
  SELECT = "SELECT",
  WHERE = "WHERE",
  LIMIT = "LIMIT",
  OFFSET = "OFFSET",
  ORDER = "ORDER",
  BY = "BY",
}

export enum BinaryOperator {
  AND = "AND",
  BETWEEN = "BETWEEN",
  IN = "IN",
  NOT_BETWEEN = "NOT BETWEEN",
  NOT_IN = "NOT IN",
  OR = "OR",
}

export enum ComparisonOperator {
  EQ = "=",
  GT = ">",
  GTE = ">=",
  IS = "IS",
  LIKE = "LIKE",
  LT = "<",
  LTE = "<=",
  NOT_EQ = "!=",
  NOT_IS = "IS NOT",
  NOT_LIKE = "NOT LIKE",
}

export enum ResultFlag {
  DISTINCT = "DISTINCT",
  VALUE = "VALUE",
}

export type Operator = BinaryOperator | ComparisonOperator;

export enum Type {
  ACCESSOR = "ACCESSOR",
  ACCESSOR_CLOSE = "ACCESSOR_CLOSE",
  ACCESSOR_OPEN = "ACCESSOR_OPEN",
  ALIAS = "ALIAS",
  DIRECTION = "DIRECTION",
  IDENTIFIER = "IDENTIFIER",
  LIST_CLOSE = "LIST_CLOSE",
  LIST_DELIMETER = "LIST_DELIMETER",
  LIST_OPEN = "LIST_OPEN",
  LITERAL = "LITERAL",
  MAP = "MAP",
  OPERATOR_BINARY = "OPERATOR_BINARY",
  OPERATOR_COMPARISON = "OPERATOR_COMPARISON",
  OPERATOR_UNARY = "OPERATOR_UNARY",
  RESULT = "RESULT",
  STAR = "STAR",
  STATEMENT_BY = "STATEMENT_BY",
  STATEMENT_LIMIT = "STATEMENT_LIMIT",
  STATEMENT_FROM = "STATEMENT_FROM",
  STATEMENT_OFFSET = "STATEMENT_OFFSET",
  STATEMENT_ORDER = "STATEMENT_ORDER",
  STATEMENT_SELECT = "STATEMENT_SELECT",
  STATEMENT_WHERE = "STATEMENT_WHERE",
  TERMINATOR = "TERMINATOR",
  WHITESPACE = "WHITESPACE",
}

export interface Token<T extends Type, V = string>
  extends Omit<MooToken, "value"> {
  col: number;
  line: number;
  lineBreaks: number;
  offset: number;
  text: string;
  toString: () => string;
  type: T;
  value: V;
}

export type AccessorCloseToken = Token<Type.ACCESSOR_CLOSE>;
export type AccessorOpenToken = Token<Type.ACCESSOR_OPEN>;
export type AccessorToken = Token<Type.ACCESSOR>;
export type AliasToken = Token<Type.ALIAS, Alias>;
export type BinaryOperatorToken = Token<Type.OPERATOR_BINARY, BinaryOperator>;
export type ByStatementToken = Token<Type.STATEMENT_BY, Statement>;
export type ComparisonOperatorToken = Token<
  Type.OPERATOR_COMPARISON,
  ComparisonOperator
>;
export type DirectionToken = Token<Type.DIRECTION, Direction>;
export type IdentifierToken = Token<Type.IDENTIFIER>;
export type LimitStatementToken = Token<Type.STATEMENT_LIMIT, Statement>;
export type ListCloseToken = Token<Type.LIST_CLOSE>;
export type ListDelimiterToken = Token<Type.LIST_DELIMETER>;
export type ListOpenToken = Token<Type.LIST_OPEN>;
export type LiteralToken = Token<
  Type.LITERAL,
  string | number | boolean | null
>;
export type MapToken = Token<Type.MAP, MapVariant>;
export type FromStatementToken = Token<Type.STATEMENT_FROM, Statement>;
export type OffsetStatementToken = Token<Type.STATEMENT_OFFSET, Statement>;
export type OrderStatementToken = Token<Type.STATEMENT_ORDER, Statement>;
export type ResultToken = Token<Type.RESULT, ResultFlag>;
export type SelectStatementToken = Token<Type.STATEMENT_SELECT, Statement>;
export type StarToken = Token<Type.STAR, Star>;
export type TerminatorToken = Token<Type.TERMINATOR>;
export type WhereStatementToken = Token<Type.STATEMENT_WHERE, Statement>;
export type WhitespaceToken = Token<Type.WHITESPACE>;

export type AnyToken =
  | AccessorCloseToken
  | AccessorOpenToken
  | AccessorToken
  | AliasToken
  | BinaryOperatorToken
  | ByStatementToken
  | ComparisonOperatorToken
  | DirectionToken
  | IdentifierToken
  | FromStatementToken
  | LimitStatementToken
  | ListCloseToken
  | ListDelimiterToken
  | ListOpenToken
  | LiteralToken
  | MapToken
  | OffsetStatementToken
  | OrderStatementToken
  | ResultToken
  | SelectStatementToken
  | StarToken
  | TerminatorToken
  | WhereStatementToken
  | WhitespaceToken;

export interface Lexer extends Omit<MooLexer, "next">, Iterator<AnyToken> {
  [Symbol.iterator](): Iterator<AnyToken>;
}
