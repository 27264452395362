import { Clause, Map, MapType, OperatorType } from '@catalytic/query';
import { isExpression, isFunction, isIdentifier, isLiteral, isOperation } from '@catalytic/query-util-is';
import { AnyOperation, BinaryOperation, ExistsInArrayFunction, FromMap, InArrayOperation, LeftToRightOperation, RightToLeftOperation } from './query.interfaces';
export { isFunction, isIdentifier, isLiteral, isOperation } from '@catalytic/query-util-is';

export const isBinaryOperation = (item: Clause): item is BinaryOperation =>
  isOperation(item) &&
  (item.operation === OperatorType.AND || item.operation === OperatorType.OR)

export const isLeftToRightOperation = (item: Clause): item is LeftToRightOperation =>
  isOperation(item) &&
  (item.operation !== OperatorType.AND && item.operation !== OperatorType.OR) &&
  isIdentifier(item.left) &&
  isLiteral(item.right)

export const isRightToLeftOperation = (item: Clause): item is RightToLeftOperation =>
  isOperation(item) &&
  (item.operation !== OperatorType.AND && item.operation !== OperatorType.OR) &&
  isLiteral(item.left) &&
  isIdentifier(item.right)

export const isAnyOperation = (item: Clause): item is AnyOperation =>
  isBinaryOperation(item) ||
  isLeftToRightOperation(item) ||
  isRightToLeftOperation(item)

export const isExistsInArrayFunction = (item: Clause): item is ExistsInArrayFunction =>
  isFunction(item) &&
  item.name.name === 'EXISTS'

export const isInArrayOperation = (item: Clause): item is InArrayOperation =>
  isOperation(item) &&
  (item.operation === OperatorType.IN || item.operation === OperatorType.NOT_IN) &&
  isIdentifier(item.left) &&
  isExpression(item.right) &&
  item.right.expression.every(i => isLiteral(i))

export const isFromMap = (item: Map): item is FromMap =>
  item.variant === MapType.FROM &&
  isIdentifier(item.source)
