import React from 'react';
import { ConnectedFormProps } from '../../form/form.hooks';
import { useStepForm } from '../../step-form/step-form.hooks';
import { ConnectedForm } from '../Form/Form';

export type StepFormProps = ConnectedFormProps;

export function StepForm({ formProps, ...props }: StepFormProps) {
  const state = useStepForm(props);
  return <ConnectedForm {...{ ...formProps, ...state }} />
}
