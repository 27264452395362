import React from 'react';
import { types } from '@catalytic/react-view';
import { Anchor, TextInput } from 'grommet';
import { FormField } from '../../components/FormField';

export function EmailViewEditor(props: types.EmailView) {
  return <TextInput
    disabled={props.node().disabled}
    name={props.node().reference}
    type="email"
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue(e.target.value);
    }}
    value={props.value ?? props.defaultValue() ?? ''} />
}

export function EmailViewReader(props: types.EmailView) {
  const value = props.value ?? props.defaultValue() ?? undefined
  return value ? <Anchor href={`mailto:${value}`}>{value}</Anchor> : null
}

export function EmailView(props: types.EmailView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <EmailViewReader {...props} />
      : <EmailViewEditor {...props} />}
  </FormField>
}
