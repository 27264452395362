import { StyleFactory } from './style.interfaces';
import { EdgeType, EdgeSizeType, MarginKindType, PadKindType } from '../theme/theme.interfaces';
import { css } from 'styled-components';
import { normalizeEdgeSize } from '../theme/theme.utils';

export const createEdgeStyleFactory = (type: string): StyleFactory<EdgeType> => (props, kind) => {
  if (kind === undefined) return '';
  if (typeof kind === 'string') {
    return css`${type}: ${normalizeEdgeSize(props, kind)};`
  }

  const { horizontal, vertical, top, bottom, left, right, start, end } = kind;

  const hvSame = horizontal && horizontal === vertical;
  const trblSame = top && top === bottom && top === left && top === right;

  if (hvSame || trblSame) {
    return css`${type}: ${normalizeEdgeSize(props, hvSame ? horizontal : top)};`
  }

  const results: any[] = [];

  if (horizontal) {
    results.push(
      css`${type}-left: ${normalizeEdgeSize(props, horizontal)};`,
      css`${type}-right: ${normalizeEdgeSize(props, horizontal)};`
    )
  }

  if (vertical) {
    results.push(
      css`${type}-top: ${normalizeEdgeSize(props, vertical)};`,
      css`${type}-bottom: ${normalizeEdgeSize(props, vertical)};`
    )
  }

  if (top) {
    results.push(
      css`${type}-top: ${normalizeEdgeSize(props, top)};`,
    )
  }

  if (right) {
    results.push(
      css`${type}-right: ${normalizeEdgeSize(props, right)};`,
    )
  }

  if (bottom) {
    results.push(
      css`${type}-bottom: ${normalizeEdgeSize(props, bottom)};`,
    )
  }

  if (left) {
    results.push(
      css`${type}-left: ${normalizeEdgeSize(props, left)};`,
    )
  }

  if (start) {
    results.push(
      css`${type}-inline-start: ${normalizeEdgeSize(props, start)};`,
    )
  }

  if (end) {
    results.push(
      css`${type}-inline-end: ${normalizeEdgeSize(props, end)};`,
    )
  }
  return results
}

export const applyMarginTypeStyle = createEdgeStyleFactory('margin')
export const applyPadTypeStyle = createEdgeStyleFactory('padding')

export const applyMarginStyle: StyleFactory<MarginKindType> = (props, kind) =>
  css`${kind?.margin ? applyMarginTypeStyle(props, kind.margin, kind.margin) : ''}`

export const applyPadStyle: StyleFactory<PadKindType> = (props, kind) =>
  css`${kind?.pad ? applyPadTypeStyle(props, kind.pad, kind.pad) : ''}`
