import { types } from '@catalytic/react-view';
import { Text } from 'grommet';
import React from 'react';
import { FormField } from '../../components/FormField/FormField';
import { StyledCheckBoxButton, StyledRadioButtonGroup } from './CheckBoxView.styled';

export function CheckboxViewEditor(props: types.CheckboxView) {
  return <StyledRadioButtonGroup
    name={props.node().reference as any}
    direction="row"
    gap="xsmall"
    disabled={props.node().disabled}
    options={[
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]}
    value={props.value ?? props.defaultValue() ?? ''}
  >
    {(option: any) => {
      const isChecked = option.value === props.value;
      return (
        <StyledCheckBoxButton
          label={option.label}
          primary={isChecked}
          secondary={!isChecked}
          onClick={
            (e: any) => {
              props.setValue(option.value)
            }
          } />
      );
    }}
  </StyledRadioButtonGroup>
}

export function CheckboxViewReader(props: types.CheckboxView) {
  return <Text>{props.value ? 'Yes' : 'No'}</Text>
}

export function CheckboxView(props: types.CheckboxView) {
  const { children, ...others } = props;
  return <FormField {...others} >
    {props.node().role === 'reader'
      ? <CheckboxViewReader {...props} />
      : <CheckboxViewEditor {...props} />}
  </FormField>
}
