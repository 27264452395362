import { Extender, PropsWithTheme, ThemeFragment, ThemeType, ThemeVariantFragment, ColorType, EdgeSizeType, BoxSizeType } from './theme.interfaces';

export const isExtender = <T = any>(value: any): value is Extender<T> =>
  Array.isArray(value) && typeof value[0] === 'function';

export const isThemeVariantFragment = (value: any): value is ThemeVariantFragment =>
  value &&
  typeof value === 'object' &&
  !Array.isArray(value) &&
  typeof value.hasOwnProperty === 'function' &&
  (value.hasOwnProperty('dark') || value.hasOwnProperty('light'))

export const callExtender = <T = any>([fn]: Extender<T>, theme: ThemeType): T | undefined => fn({ theme });

export type NormalizeFragmentOptions = {
  forceDark?: boolean
};

export const normalizeFragment = <TKind = any, TProps = any>(
  props: PropsWithTheme<TProps>,
  fragment: ThemeFragment<TKind> | undefined,
  options: NormalizeFragmentOptions = {}
): TKind | undefined => {
  let result: TKind | undefined | Extender<TKind> =
    typeof fragment !== 'object' && typeof fragment !== 'function'
      ? fragment
      : isExtender(fragment)
        ? callExtender<TKind>(fragment, props.theme)
        : !isThemeVariantFragment(fragment)
          ? fragment
          : (options.forceDark || props.theme.dark) && fragment.dark
            ? fragment.dark
            : fragment.light

  result = isExtender(result) ? callExtender(result, props.theme) : result;

  return result;
};

export interface FragmentReferenceLookup<TKind = any, TProps = any> {
  (
    props: PropsWithTheme<TProps>,
    fragment: string
  ): TKind | undefined
}

export const createNormalizeFactory = <TKind = any, TProps = any>(
  lookup: FragmentReferenceLookup
) => {
  const factory = (
    props: PropsWithTheme<TProps>,
    fragment: ThemeFragment<TKind> | undefined,
    options: NormalizeFragmentOptions = {}
  ): TKind | undefined => {
    let result = normalizeFragment(props, fragment, options);

    let reference = typeof result === 'string' ? lookup(props, result) : undefined;

    return reference !== undefined ? factory(props, reference, options) : result;
  };

  return factory;
}

export const normalizeColor = createNormalizeFactory<ColorType>(
  (props, fragment) =>
    props.theme?.global?.colors?.[fragment]
)

export const normalizeEdgeSize = createNormalizeFactory<EdgeSizeType>(
  (props, fragment) =>
    props.theme?.global?.edgeSize?.[fragment]
)

export const normalizeBorderSize = createNormalizeFactory<BoxSizeType>(
  (props, fragment) =>
    props.theme?.global?.borderSize?.[fragment]
)
