import { Clause, expression, func, Identifier, identifier, Map, map, MapType, operation, OperatorType, result, select } from "@catalytic/query";
import clone from 'ramda/src/clone';
import { isBinaryOperation, isExistsInArrayFunction, isFromMap, isIdentifier, isInArrayOperation, isLeftToRightOperation, isLiteral, isOperation, isRightToLeftOperation } from './query.guards';
import { WhereClause, WhereClauseOperation } from './query.interfaces';

const getPrefix = (fromMap?: Map): string | undefined =>
  fromMap && isFromMap(fromMap) ? (fromMap.source.alias || fromMap.source.name) : undefined

const prefixPattern = (prefix: string): RegExp =>
  new RegExp(`^${prefix}(?:\\[['"]?|\\.)(?<suffix>[^'"\\]]*)?`);

const wrapPrefix = (identifier: Identifier, fromMap?: Map): string => {
  const prefix = getPrefix(fromMap);
  let name = identifier.name
  if (prefix && !name.match(prefixPattern(prefix))) {
    name = `${prefix}['${name}']`
  }
  return name;
}

const unwrapPrefix = (identifier: Identifier, fromMap?: Map): string => {
  const prefix = getPrefix(fromMap);

  if (!prefix) return identifier.name;

  const name = identifier.name
  return name.match(prefixPattern(prefix))?.groups?.suffix ?? name;
}

export const toWhereClause = (clause: Clause | Clause[], operation?: OperatorType, fromMap?: Map): WhereClause[] => {
  if (Array.isArray(clause)) {
    return clause
      .reduce(
        (results, clause) =>
          results.concat(toWhereClause(clause, operation, fromMap))
        ,
        [] as WhereClause[]
      ).map(
        (clause, index) => {
          if (index !== 0 && !clause.operation) {
            clause.operation = OperatorType.OR
          }
          return clause;
        }
      )
  }

  if (isBinaryOperation(clause)) {
    return [
      ...toWhereClause(clause.left, operation, fromMap),
      ...toWhereClause(clause.right, clause.operation, fromMap)
    ]
  };


  if (isOperation(clause) && isIdentifier(clause.left)) {
    clause.left.name = unwrapPrefix(clause.left, fromMap)
  }

  if (
    isLeftToRightOperation(clause)
  ) {
    return [{
      clause,
      operation
    }];
  }

  if (isExistsInArrayFunction(clause)) {
    const arrayOperation = clause.args.expression[0].where?.[0];

    if (arrayOperation) {
      if (clause.args.expression[0].from?.source.right) {
        arrayOperation.left = clause.args.expression[0].from?.source.right;
        arrayOperation.left.name = unwrapPrefix(arrayOperation.left, fromMap)
      }
      return [{
        clause: arrayOperation,
        operation
      }]
    }
  }

  return []
}

export const fromWhereClause = (left: WhereClause | WhereClause[], right: WhereClause[] = [], fromMap?: Map): Clause | undefined => {
  if (Array.isArray(left)) {
    if (left.length === 0) return;
    const [first, ...right] = left;
    return fromWhereClause(first, right, fromMap);
  }

  let leftClause = clone(left.clause);

  leftClause.left.name = wrapPrefix(leftClause.left, fromMap);

  if (isInArrayOperation(leftClause)) {
    leftClause = func({
      name: identifier({ name: 'EXISTS' }),
      args: expression({
        expression: [
          select({
            from: map({
              source: operation({
                left: identifier({ name: '_a' }),
                operation: OperatorType.IN,
                right: leftClause.left
              }),
              variant: MapType.FROM
            }) as any,
            result: [
              result({
                source: identifier({ name: '_a' }),
                value: true
              })
            ],
            where: [
              operation({
                left: identifier({ name: '_a' }),
                operation: leftClause.operation,
                right: leftClause.right
              })
            ]
          })
        ]
      })
    }) as any
  }

  if (right.length === 0) {
    return leftClause;
  }

  const [firstRight] = right;

  const rightClause = fromWhereClause(right, undefined, fromMap);

  if (!rightClause) return leftClause;

  return operation({
    left: leftClause,
    operation: firstRight.operation ?? OperatorType.OR,
    right: rightClause
  })
}
