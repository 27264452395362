const functions = require('./function');
const parse = require('./parse');
const types = require('./types');

const binaryOperator = {
  '==': (l, r) => l == r,  // eslint-disable-line eqeqeq
  '!=': (l, r) => l != r,  // eslint-disable-line eqeqeq
  '<': (l, r) => l < r,
  '<=': (l, r) => l <= r,
  '>': (l, r) => l > r,
  '>=': (l, r) => l >= r
}

const unarySignOperator = {
  '-': (a) => -a,
  '+': (a) => +a
}

const capture = fn => function captureFN(path) {
  this.traverse(path);
  try {
    path.node._value = fn(path); // eslint-disable-line no-underscore-dangle, no-param-reassign
  } catch (e) {
    if (!(e instanceof TypeError)) throw e
    // Type Errors will resolve to false
    path.node._value = false; // eslint-disable-line no-underscore-dangle, no-param-reassign
  }
}

const getFunction = (operator, options = {}) => {
  if (operator === 'IS_EMPTY' && options.useCorrectIsEmpty === true) {
    return functions.IS_EMPTY2;
  }
  return functions[operator];
}

const evaluate = (source, context, options) => {
  const value = node => node._value; // eslint-disable-line no-underscore-dangle

  const node = types.visit(parse(source), {
    visitPBXBinaryExpression: capture(
      path => binaryOperator[path.node.operator](
        value(path.node.left),
        value(path.node.right)
      )
    ),
    visitPBXExpressionStatement: capture(
      path => value(path.node.expression)
    ),
    visitPBXFunctionExpression: capture(
      path => getFunction(path.node.operator, options)(
          value(path.node.left),
          value(path.node.right)
        )
    ),
    visitPBXIdentifier: capture(
      path => context[path.node.name]
    ),
    visitPBXLiteral: capture(
      path => path.node.value
    ),
    visitPBXLogicalAndExpression: capture(
      path => (value(path.node.left) && value(path.node.right))
    ),
    visitPBXLogicalOrExpression: capture(
      path => (value(path.node.left) || value(path.node.right))
    ),
    visitPBXMemberExpression: capture(
      path => value(path.node.object)[value(path.node.property)]
    ),
    visitPBXProgram: capture(
      path => value(path.node.body)
    ),
    visitPBXUnaryFunctionExpression: capture(
      path => getFunction(path.node.operator, options)(value(path.node.argument))
    ),
    visitPBXUnaryNotExpression: capture(
      path => !value(path.node.argument)
    ),
    visitPBXUnarySignExpression: capture(
      path => unarySignOperator[path.node.operator](value(path.node.argument))
    )
  });

  return !!value(node);
}

module.exports = evaluate;
