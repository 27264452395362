const types = require('ast-types');

const def = types.Type.def;
const or = types.Type.or;

const BinaryAssignment = or(
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXMemberExpression'),
  def('PBXUnarySignExpression')
);

const BinaryOperator = or(
  '==', '!=',
  '<', '<=',
  '>', '>='
)

const ExpressionStatementAssignment = or(
  def('PBXBinaryExpression'),
  def('PBXFunctionExpression'),
  def('PBXIdentifier'),
  def('PBXLogicalAndExpression'),
  def('PBXLogicalOrExpression'),
  def('PBXLiteral'),
  def('PBXMemberExpression'),
  def('PBXUnaryFunctionExpression'),
  def('PBXUnaryNotExpression'),
  def('PBXUnarySignExpression')
)

const FunctionAssignment = or(
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXMemberExpression'),
  def('PBXUnarySignExpression')
);

const FunctionOperator = or(
  'IN',
  'STARTS_WITH',
  'ENDS_WITH',
  'IS_EMPTY'
);

const IdentifierName = or(
  'fields',
  'files',
  'run',
  'task',
  'tasks',
  'team'
);

const LiteralAssignment = or(
  Boolean,
  Number,
  String
);

const LogicalAndAssignment = or(
  def('PBXBinaryExpression'),
  def('PBXFunctionExpression'),
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXLogicalAndExpression'),
  def('PBXMemberExpression'),
  def('PBXUnaryFunctionExpression'),
  def('PBXUnaryNotExpression'),
  def('PBXUnarySignExpression')
);

const LogicalAndOperator = '&&';

const LogicalOrLeftAssignment = or(
  def('PBXBinaryExpression'),
  def('PBXFunctionExpression'),
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXUnaryFunctionExpression'),
  def('PBXLogicalAndExpression'),
  def('PBXLogicalOrExpression'),
  def('PBXMemberExpression'),
  def('PBXUnaryNotExpression'),
  def('PBXUnarySignExpression')
);

const LogicalOrRightAssignment = or(
  def('PBXBinaryExpression'),
  def('PBXFunctionExpression'),
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXUnaryFunctionExpression'),
  def('PBXLogicalAndExpression'),
  def('PBXMemberExpression'),
  def('PBXUnaryNotExpression'),
  def('PBXUnarySignExpression')
);

const LogicalOrOperator = '||';

const MemberAssignment = or(
  def('PBXIdentifier'),
  def('PBXMemberExpression')
);

const UnaryFunctionOperator = or(
  'IS_EMPTY'
);

const UnaryFunctionAssignment = or(
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXMemberExpression')
);

const UnaryNotOperator = '!';

const UnaryNotAssignment = or(
  def('PBXFunctionExpression'),
  def('PBXUnaryFunctionExpression')
);

const UnarySignAssignment = or(
  def('PBXIdentifier'),
  def('PBXLiteral'),
  def('PBXMemberExpression')
);

const UnarySignOperator = or(
  '+',
  '-'
);

def('PBXBinaryExpression')
  .bases('BinaryExpression')
  .build('operator', 'left', 'right')
  .field('operator', BinaryOperator)
  .field('left', BinaryAssignment)
  .field('right', BinaryAssignment);

def('PBXExpressionStatement')
  .bases('ExpressionStatement')
  .build('expression')
  .field('expression', ExpressionStatementAssignment);

def('PBXFunctionExpression')
  .bases('LogicalExpression')
  .build('operator', 'left', 'right')
  .field('operator', FunctionOperator)
  .field('left', FunctionAssignment)
  .field('right', FunctionAssignment)

def('PBXIdentifier')
  .bases('Identifier')
  .build('name')
  .field('name', IdentifierName)

def('PBXLiteral')
  .bases('Literal')
  .build('value')
  .field('value', LiteralAssignment)

def('PBXLogicalAndExpression')
  .bases('LogicalExpression')
  .build('left', 'right')
  .field('left', LogicalAndAssignment)
  .field('right', LogicalAndAssignment)
  .field('operator', LogicalAndOperator, () => LogicalAndOperator);

def('PBXLogicalOrExpression')
  .bases('LogicalExpression')
  .build('left', 'right')
  .field('left', LogicalOrLeftAssignment)
  .field('right', LogicalOrRightAssignment)
  .field('operator', LogicalOrOperator, () => LogicalOrOperator);

def('PBXMemberExpression')
  .bases('MemberExpression')
  .build('object', 'property')
  .field('object', MemberAssignment)
  .field('property', def('PBXLiteral'))
  .field('computed', true, () => true)

def('PBXProgram')
  .bases('Program')
  .build('body')
  .field('body', def('PBXExpressionStatement'));

def('PBXUnaryFunctionExpression')
  .bases('UnaryExpression')
  .build('operator', 'argument')
  .field('operator', UnaryFunctionOperator)
  .field('argument', UnaryFunctionAssignment);

def('PBXUnaryNotExpression')
  .bases('UnaryExpression')
  .build('argument', 'operator')
  .field('argument', UnaryNotAssignment)
  .field('operator', UnaryNotOperator, () => UnaryNotOperator)
  .field('prefix', Boolean, () => true);

def('PBXUnarySignExpression')
  .bases('UnaryExpression')
  .build('operator', 'argument')
  .field('operator', UnarySignOperator)
  .field('argument', UnarySignAssignment)
  .field('prefix', Boolean, () => true);

types.finalize();

module.exports = types;
