import { DateInput } from 'grommet';
import styled from 'styled-components';
import { applyInputStyle } from '../../style/style.input';
import { createStyleFactory } from '../../style/style.factories';
import { mergeKind } from '../../style/style.utils';

const defaultInputStyle = createStyleFactory({
  applyStyle: applyInputStyle,
  getKind: ({ theme }) =>
    mergeKind(theme?.views?.date?.input, theme?.components?.input)
})

export const StyledDateInput = styled(DateInput)`
  ${defaultInputStyle}
`;
