import { css } from 'styled-components';
import { ButtonKindType, ButtonVariant, ButtonVariantMap } from '../theme/theme.interfaces';
import { applyFontStyle } from './style.font';
import { applyForegroundBackgroundStyle } from './style.foreground-background';
import { StyleFactory } from './style.interfaces';
import { applyMarginStyle, applyPadStyle } from './style.edge';
import { createStyleFactory } from './style.factories';
import { applyBorderStyle } from './style.border';
import { ButtonProps } from 'grommet';
import { mergeKind } from './style.utils';

const getButtonVariants = <TKind = ButtonKindType>(props: ButtonProps, kind?: ButtonVariantMap): TKind => {
  const variants: any[] = [
    kind?.default
  ]

  if (props.primary) variants.push(kind?.primary)
  if (props.secondary) variants.push(kind?.secondary)

  if (props.active && kind?.active) variants.push(getButtonVariants(props, kind.active))

  return mergeKind<TKind>(...variants.reverse());
}

const applyButtonPadStyle = createStyleFactory<ButtonKindType, ButtonProps>({
  applyStyle: applyPadStyle,
  getKind: (props) =>
    mergeKind(
      getButtonVariants(props, props.theme?.components?.button),
      props.theme?.button?.padding
        ? { pad: props.theme.button.padding }
        : undefined
    )
})

const applyButtonBorderStyle = createStyleFactory({
  applyStyle: applyBorderStyle,
  getKind: (props) =>
    mergeKind(
      getButtonVariants(props, props.theme?.components?.button),
      getButtonVariants(props, props.theme?.button),
      props.theme.button
    )
})

const applyButtonForegroundBackgroundStyle = createStyleFactory({
  applyStyle: applyForegroundBackgroundStyle,
  getKind: (props) =>
    mergeKind(
      getButtonVariants(props, props.theme?.components?.button),
      getButtonVariants(props, props.theme?.button),
      props.theme.button
    )
})

export const applyButtonStyle: StyleFactory<ButtonKindType> = (props, kind, fragment) => css`
${applyButtonForegroundBackgroundStyle(props, kind, fragment)}
${applyMarginStyle(props, kind, fragment)}
${applyButtonPadStyle(props, kind, fragment)}
${applyFontStyle(props, kind, fragment)}
${applyButtonBorderStyle(props, kind, fragment)}
`
