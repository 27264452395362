import { JSONValue } from '@catalytic/react-view';
import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Node } from 'unist';
import { FormData, FormChangeEvent } from '../form/form.interfaces';
import { FormConfigurationMap } from './form-binding.interfaces';
import { FormBinderContext, FormBinderContextProps } from './form-binding.providers';
import { bindForm, formatDeep, validateDeep } from './form-binding.utils';

export const useFormBinderContext = (): FormBinderContextProps => {
  const context = useContext(FormBinderContext);
  return context;
}

export interface UseFormBindingProps<
  N extends Node = Node,
  V = Record<string, JSONValue> | Record<string, JSONValue>[]
  > {
  data?: FormData<N, V>,
  configuration?: FormConfigurationMap
}

export interface UseFormBindingState<
  N extends Node = Node,
  V = Record<string, JSONValue> | Record<string, JSONValue>[]
  > {
  handleChange: (event: FormChangeEvent<V>) => void,
  data?: FormData<N, V>,
  loading: boolean,
  errors?: Error[]
}

export const useFormBinding = <
  N extends Node = Node,
  V = Record<string, JSONValue> | Record<string, JSONValue>[]
>({ data: dataProp, configuration }: UseFormBindingProps<N, V>): UseFormBindingState<N, V> => {
  const { configuration: binderConfiguration } = useFormBinderContext();
  const reloading = useRef(false);
  const [inboundValue, setInboudValue] = useState(dataProp?.value);
  useEffect(
    () => {
      setInboudValue(dataProp?.value)
    },
    [dataProp]
  )
  const handleChange = useCallback<(event: FormChangeEvent<V>) => void>(
    ({ value }) => {
      setInboudValue(value)
    },
    []
  )
  const [state, setState] = useState<UseFormBindingState<N, V>>({
    data: {
      ...dataProp,
      value: inboundValue,
    } as any,
    loading: true,
    handleChange
  });


  useEffect(
    () => {
      if (reloading.current || !inboundValue) return;
      let data = {
        ...dataProp,
        value: inboundValue,
      } as any;

      const bindings = Object.entries(configuration || {});

      if (state.loading && !bindings.length) {
        // +1 rerender
        setState({
          handleChange,
          data,
          loading: false
        });
        return;
      }

      setState({
        ...state,
        data
      });

      // Prevent other changes from rerunning the binding calls
      reloading.current = true;
      const load = async () => {
        let { value } = data;
        await Promise.all(
          bindings.map(
            async ([reference, config]) => {
              const type = config.type;
              const binder = binderConfiguration[type];
              if (!binder || !validateDeep(config, value)) {
                return;
              }

              // Hydrate any template strings
              const configuration = formatDeep(config, value);

              try {
                let results = await binder.bind({ configuration, data })
                data = bindForm({
                  data,
                  reference,
                  results,
                  configuration
                })
              } catch (e) {
                state.errors = state.errors || [];
                state.errors.push(e);
                setState(state);
              }
            }
          )
        )
        reloading.current = false
        // +1 rerender
        setState({
          handleChange,
          data,
          loading: false
        });
      }
      load()
    },
    [inboundValue, configuration, binderConfiguration]
  )

  return state;
}
