import React, { Context } from 'react';
import { OrchestrationAPI } from '@catalytic/orchestration-sdk';

export type OrchestrationClientContextProps = {
  instance: OrchestrationAPI
};

export const OrchestrationClientContext: Context<OrchestrationClientContextProps> = React.createContext({
  instance: {} as OrchestrationAPI
});

export type OrchestrationClientProviderProps = React.PropsWithChildren<OrchestrationClientContextProps>

export const OrchestrationClientProvider = ({ children, ...value }: OrchestrationClientProviderProps) =>
  <OrchestrationClientContext.Provider children={children} value={value} />
