import { Button, RadioButtonGroup } from 'grommet';
import styled from 'styled-components';
import { applyButtonStyle } from '../../style/style.button';
import { createStyleFactory } from '../../style/style.factories';
import { ButtonKindType } from '../../theme/theme.interfaces';
import { applyInputStyle } from '../../style/style.input';
import { mergeKind } from '../../style/style.utils';

const defaultStyle = createStyleFactory<ButtonKindType>({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.views?.toggleSwitch?.button?.default || theme.components?.button?.default
})

const checkedStyle = createStyleFactory<ButtonKindType>({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.views?.toggleSwitch?.button?.checked,
  test: ({ primary }) => !!primary
})

const uncheckedStyle = createStyleFactory<ButtonKindType>({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.views?.toggleSwitch?.button?.unchecked,
  test: ({ secondary }) => !!secondary
})

export const StyledToggleSwitchButton = styled(Button)`
  ${defaultStyle}
  ${checkedStyle}
  ${uncheckedStyle}
`;

const defaultInputStyle = createStyleFactory({
  applyStyle: applyInputStyle,
  getKind: ({ theme }) =>
    mergeKind(theme?.views?.toggleSwitch?.input, theme?.components?.input)
})

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  ${defaultInputStyle}
`;
