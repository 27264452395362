import { Select } from '@catalytic/query';
import { NodeEvent, types, ViewProps } from '@catalytic/react-view';
import { Node } from '@catalytic/view';
import pointer from 'json-pointer';
import clone from 'ramda/src/clone';
import { useCallback, useEffect, useState } from 'react';
import { select as selectNode } from 'unist-util-select';
import visit from 'unist-util-visit';
import { useSelect } from '../../query/query.hooks';


interface BoundNode extends Node {
  reference: string
}

export enum CollectionViewItemEventType {
  Click = 'CollectionView.Item.Click',
  Focus = 'CollectionView.Item.Focus',
  LoadMore = 'CollectionView.List.LoadMore',
  Query = 'CollectionView.List.Query',
  Sort = 'CollectionView.List.Sort'
}

export interface CollectionViewItemEvent<Type extends CollectionViewItemEventType = CollectionViewItemEventType, Item = unknown> extends NodeEvent {
  type: Type,
  item: Item
}

export const isCollectionViewItemEvent = <Item = unknown>(event: NodeEvent, type: CollectionViewItemEventType): event is CollectionViewItemEvent<typeof type, Item> =>
  event.type === type

export interface CollectionViewState<Item = unknown> {
  blur: () => void,
  columnViewProps: (reference: string) => Node | undefined
  deleteSelected: (selected?: string[]) => void,
  deselect: (selected: string[]) => void,
  deselectAll: () => void,
  deselectOne: (id: string) => void,
  focus: (id: string | string[]) => void,
  focused?: string[],
  focusedViewProps?: ViewProps,
  properties?: BoundNode[]
  query?: Select,
  setQuery: (select: Select) => void,
  resetQuery: () => void,
  rowViewProps?: ViewProps,
  select: (selected: string[]) => void,
  selectAll: () => void,
  selectOne: (id: string) => void,
  selected: string[],
  toggleSelectOne: (id: string) => void,
  updateFocused: (item: Partial<Item>) => void,
  updateSelected: (item: Partial<Item>, selected?: string[]) => void,
  updateValue: (nextValue: Item[]) => void,
  value: Item[],
  append: (item: Item) => void
}

export function useCollectionView<Item = unknown>(props: types.CollectionView): CollectionViewState<Item> {
  const [value, setValue] = useState<Item[]>([]);
  const [checked, setChecked] = useState<string[]>([]);
  const [rowViewProps, setRowViewProps] = useState<ViewProps>()
  const [properties, setProperties] = useState<BoundNode[]>()
  const [focused, setFocused] = useState<string[]>();
  const [focusedViewProps, setFocusedViewProps] = useState<ViewProps>()
  const disabled = props.node().role === 'reader';
  const defaultQuery = useSelect(props.node().select as string);
  const [query, setQueryState] = useState(defaultQuery)

  const resetQuery = useCallback(
    () => {
      if (defaultQuery) setQuery(defaultQuery)
    },
    []
  );

  const setQuery = useCallback(
    (query: Select) => {
      const event: CollectionViewItemEvent<CollectionViewItemEventType.Query, Select> = {
        type: CollectionViewItemEventType.Query,
        item: query
      }
      props.dispatch(event);
      setQueryState(query)
    },
    []
  )

  useEffect(
    () => {
      if (!Array.isArray(props.value)) return;
      const value = props.value.map((props, index) => ({ _id: `id-${index}`, ...props }))
      setValue(value)
    },
    [props.value]
  )

  useEffect(
    () => {
      const schema = props.schema()?.items;
      const node = clone(props.node().items);
      const root = node.reference
      const schemaRoot = node.schemaReference

      if (schemaRoot) {
        visit(
          node,
          ((node: any) => {
            return node?.schemaReference.startsWith(schemaRoot)
          }) as any,
          (node) => {
            const schemaReference = node?.schemaReference;
            if (typeof schemaReference === 'string') {
              node.schemaReference = schemaReference.substr(schemaRoot.length)
            }
          }
        )
      }

      if (root) {
        visit(
          node,
          ((node: any) => {
            return node?.reference.startsWith(root)
          }) as any,
          (node) => {
            const reference = node?.reference;
            if (typeof reference === 'string') {
              node.reference = reference.substr(root.length)
            }
          }
        )
      }

      if (disabled) {
        visit(
          node,
          (node: any) => node.role === 'editor',
          (node: any) => {
            node.role = 'reader'
          }
        )
      }

      setRowViewProps({
        node,
        schema,
        defaultValue: {}
      })

      if (schema && Array.isArray(node?.children)) {
        const properties: BoundNode[] = node.children.filter(
          (child: Node) => typeof child.reference === 'string' && child.reference.match(/^\/[^\/]+$/)
        ).map(
          (property: BoundNode) => {
            // Hydrate any missing view information
            if (
              property.title === undefined
              && typeof property.schemaReference === 'string'
              && pointer.has(schema, property.schemaReference)
            ) {
              const propertySchema = pointer.get(schema, property.schemaReference)
              property.title = propertySchema.title
            }

            return property
          }
        )

        setProperties(properties)
      }
    },
    []
  )

  const updateValue = (nextValue: Item[]) => {
    props.setValue(nextValue.map((item: any) => {
      const { _id, ...nextItem } = item;
      return nextItem;
    }));
    setValue(nextValue);
  }

  const selectOne = (id: string) => {
    const next = [...checked, id];
    setChecked(next);
  };

  const deselectOne = (id: string) => {
    const next = checked.filter(v => v !== id);
    setChecked(next);
  };

  const toggleSelectOne = (id: string) => {
    checked.includes(id) ? deselectOne(id) : selectOne(id)
  };

  const select = (selected: string[]) => {
    setChecked(selected)
  }

  const deselect = (selected: string[]) => {
    const nextChecked = checked.filter(id => !selected.includes(id));
    setChecked(nextChecked)
  }

  const selectAll = () => {
    select(value.map((datum: any) => datum._id as string));
  };

  const deselectAll = () => {
    select([]);
  };

  const append = (item: Item) => {
    const nextValue = [
      ...value,
      {
        ...item,
        _id: `id-${value.length}`
      }
    ]
    updateValue(nextValue)
  }

  const deleteSelected = (selected: string[] = checked) => {
    const nextValue = value.filter(({ _id }: any) => !selected.includes(_id)) || [];
    deselect(selected);
    updateValue(nextValue)
  }

  const updateSelected = (data: Partial<Item>, selected: string[] = checked) => {
    const nextValue = value.map(
      (row: any) =>
        selected.includes(row._id)
          ? { ...row, ...data }
          : row
    )
    updateValue(nextValue)
  }

  const getByID = (id: string): Item | undefined => {
    const item = value.find((value: any) => value._id === id)
    return item;
  }

  const updateFocused = (data: Partial<Item>) => {
    if (!focused) return;
    updateSelected(data, focused)
  }

  const focus = (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    const items = ids.map(id => getByID(id)).filter(id => id !== undefined);
    if (!items.length || !rowViewProps) return;

    const defaultValue: any = Object.entries(
      items
        .reduce(
          (o, d: any) => Object.entries(d).reduce(
            (o: any, [k, v]) => {
              o[k] = o[k] || [];
              o[k].push(v);
              return o;
            },
            o
          ),
          {}
        )
    ).reduce(
      (o, [k, v]: any[]) => {
        const values = Array.from(new Set(v));
        o[k] = values.length === 1
          ? values[0]
          : undefined
        return o;
      },
      {}
    );

    const event: CollectionViewItemEvent<CollectionViewItemEventType.Focus, string[]> = {
      type: CollectionViewItemEventType.Focus,
      item: ids
    }
    props.dispatch(event);

    setFocused(ids)
    setFocusedViewProps({
      ...rowViewProps,
      defaultValue
    })
  }

  const blur = () => {
    setFocused(undefined);
    setFocusedViewProps(undefined);
  }

  const columnViewProps = useCallback<(reference: string) => Node | undefined>(
    reference =>
      (rowViewProps?.node ? selectNode(`[reference=${reference}]`, rowViewProps.node) : undefined) ?? undefined
    ,
    [rowViewProps]
  )

  return {
    append,
    blur,
    columnViewProps,
    deleteSelected,
    deselect,
    deselectAll,
    deselectOne,
    focus,
    focused,
    focusedViewProps,
    properties,
    query,
    setQuery,
    resetQuery,
    rowViewProps,
    select,
    selected: checked,
    selectAll,
    selectOne,
    toggleSelectOne,
    updateFocused,
    updateSelected,
    updateValue,
    value
  }
}
