import { OrchestrationAPI } from '@catalytic/orchestration-sdk';
import { CatalyticClient } from '@catalytic/sdk';
import React, { useEffect, useState } from 'react';
import { CatalyticClientProvider } from '../catalytic-client/catalytic-client.provider';
import { OrchestrationClientProvider } from '../orchestration-client/orchestration-client.provider';

export type CatalyticProps = React.PropsWithChildren<{
  token: string
}>

export const Catalytic = ({ children, token }: CatalyticProps) => {
  const [catalytic] = useState<CatalyticClient>(
    () => new CatalyticClient(token)
  )
  const [orchestration, setOrchestration] = useState<OrchestrationAPI>(
    () => new OrchestrationAPI(token)
  )

  useEffect(
    () => {
      catalytic.setAccessToken(token);
      setOrchestration(new OrchestrationAPI(token))
    },
    [token]
  )

  return <CatalyticClientProvider instance={catalytic}>
    <OrchestrationClientProvider instance={orchestration}>
      {children}
    </OrchestrationClientProvider>
  </CatalyticClientProvider >
}
