import PQueue from 'p-queue';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { QueueType } from './queue.interfaces';
import { FormQueueProvider, QueueContext } from './queue.providers';

export interface UseQueueState {
  active: boolean,
  queue: PQueue
};

export const useQueue = (name: string): UseQueueState => {
  const context = useContext(QueueContext);
  const queue = context.configuration[name] ?? new PQueue();
  const [active, setActive] = useState(queue.size !== 0 || queue.pending !== 0);
  useEffect(
    () => {
      const active = () => {
        setActive(true)
      }
      const idle = () => {
        setActive(false)
      }
      queue.on('active', active)
      queue.on('idle', idle)

      return () => {
        queue.removeListener('active', active);
        queue.removeListener('idle', idle)
      }
    },
    [queue]
  )
  return { active, queue };
}

export const useFormQueue = (): UseQueueState => {
  const context = useQueue(QueueType.Form);
  return context;
}

export type QueueConsumerProps = {
  children: (state: UseQueueState) => ReactNode
  name: string
};


export const QueueConsumer = ({ children, name }: QueueConsumerProps) => {
  const state = useQueue(name);
  return <>
    {children(state)}
  </>
};


export type FormQueueProps = {
  children: (state: UseQueueState) => ReactNode
};

export const FormQueue = ({ children }: FormQueueProps) => (
  <FormQueueProvider>
    <QueueConsumer name={QueueType.Form}>
      {(state) => children(state)}
    </QueueConsumer>
  </FormQueueProvider>
)
