import { Select } from "@catalytic/query";
import { Grammar, Parser } from "nearley";
import grammar from "../grammar/grammar";
import { SQLParser, SQLParserExtension } from "./transform.interfaces";

const createParser = (extensions: SQLParserExtension[] = []) => {
  const fn = (sql: string): Select => {
    const parser = new Parser(Grammar.fromCompiled(grammar));
    parser.feed(sql);
    const [results] = parser.results as Select[];
    return extensions.reduce(
      (results, extension) => extension(results),
      results
    );
  };
  fn.extend = (extension: SQLParserExtension) =>
    createParser([...extensions, extension]);
  return fn;
};

export const fromSQL: SQLParser = createParser();

export function sql([sql]: TemplateStringsArray): Select {
  return fromSQL(sql);
}
