import Color from 'color';

export const isColor = (value: string): boolean => {
  try {
    new Color(value);
    return true
  } catch (e) {
    return false
  }
}

export interface ColorFactory<TOutput, TArgs extends any[] = any[]> {
  (value: Color, ...args: TArgs): TOutput | undefined
}

export const createColorFactory = <TOutput, TArgs extends any[] = any[]>(factory: ColorFactory<TOutput, TArgs>) => {
  return (value: string, ...args: TArgs) => {
    return isColor(value) ? factory(new Color(value), ...args) : undefined
  }
}

export const color = createColorFactory((color: Color): Color => color);

export const isDark = createColorFactory((color: Color): boolean => color.isDark())

export const setOpacity = createColorFactory((color: Color, opacity: number): string => color.alpha(opacity).rgb().string())
