import { columnArrayToJSONSchema } from '@catalytic/json-schema-util-from-field';
import { JSONType } from '@catalytic/json-schema-validator';
import { useDataTable, useDataTableData, useDataTableUtilities } from '@catalytic/react-sdk';
import { JSONSchema } from '@catalytic/react-view';
import { rejectNull } from '@catalytic/reject';
import { View } from '@catalytic/view';
import { jsonSchemaToView } from '@catalytic/view-util-from-json-schema';
import { useEffect, useState } from 'react';
import visit from 'unist-util-visit';
import { useFormBinding } from '../form-binding/form-binding.hooks';
import { UseForm, UseFormState } from '../form/form.hooks';
import { FormData, FormSubmit, FormValue } from '../form/form.interfaces';

export const useDataTableForm: UseForm = ({ defaultData, disabled, id, overrideData, configuration }) => {
  const [state, setState] = useState<UseFormState>({
    loading: true
  })
  const [formData, setFormData] = useState<FormData>()
  const [submit, setSubmit] = useState<FormSubmit>()
  const boundData = useFormBinding({ data: formData, configuration });
  const dataTable = useDataTable(id);
  let dataTableData = useDataTableData<FormValue>(id);
  const { replace } = useDataTableUtilities();

  useEffect(
    () => {
      if (dataTable?.loading || dataTableData?.loading || !dataTable?.data?.columns || !dataTableData?.data) return;
      const columns = dataTable.data.columns;

      const columnViews: Record<string, View> = dataTable.data.columns.reduce(
        (o, column) => {
          const view = (column as any).view as View
          if (view) {
            o[`/${column.referenceName}`] = view
          }
          return o;
        },
        {} as Record<string, View>
      )

      let schema: JSONSchema = {
        type: JSONType.ARRAY,
        items: columnArrayToJSONSchema(columns as any),
        default: dataTableData.data.map(
          v => ({
            ...defaultData?.value,
            ...v,
            ...overrideData?.value
          })
        )
      }

      const header = columns.reduce(
        (o, column) => {
          if (column.referenceName && column.name) {
            o[column.referenceName] = column.name;
          }
          return o;
        },
        {}
      )

      let node = jsonSchemaToView(schema);

      if (node.items) {
        visit(
          (node as any).items,
          ((node: View) => typeof node.reference === 'string') as any,
          node => {
            if (columnViews[(node as any).reference]) {
              Object.assign(node, columnViews[(node as any).reference])
            }
          }
        );
      }

      const submit: FormSubmit = async (nextValue) => {
        const value = [
          header,
          ...(Array.isArray(nextValue) ? nextValue : []).map(
            (v: any) => rejectNull({
              ...defaultData?.value,
              ...v
            })
          )
        ]
        await replace(id, value)
        return value;
      };

      setSubmit(() => submit);

      setFormData({
        node,
        schema,
        value: dataTableData.data as any
      });
    },
    [dataTable?.loading, dataTableData?.loading]
  )

  useEffect(
    () => {
      if (boundData.loading) return;
      setState({
        disabled: typeof disabled === 'function' ? disabled((boundData as any).data) : disabled,
        data: boundData.data,
        loading: false,
        submit
      })
    },
    [boundData.data, boundData.loading, submit, disabled]
  )
  return state;
}
