import { useMemo } from 'react';
import { types } from '@catalytic/react-view';

export function useLocaleInteger(props: types.IntegerView): string {
  return useMemo(
    () => {
      if (!props.value) return '';
      const int = parseInt(props.value, 10);
      if (isNaN(int)) return '';
      return int.toLocaleString(undefined, props.node())
    },
    [props.value]
  )
}
