import { Box, BoxProps, ButtonProps, Button } from 'grommet'
import styled, { css } from 'styled-components'
import { applyBoxStyle } from '../../style/style.box'
import { createStyleFactory } from '../../style/style.factories'
import { BoxKindType } from '../../theme/theme.interfaces'
import { FormClose, IconProps } from 'grommet-icons'

export const applyLabelStyle = createStyleFactory<BoxKindType>({
  applyStyle: applyBoxStyle,
  getKind: ({ theme }) => theme?.components?.label,
  defaults: {
    pad: { start: 'small', end: 'xsmall' }
  }
})

export const StyledLabel = styled<React.FC<BoxProps>>(Box)`
  ${applyLabelStyle}
`

export const StyledLabelContainer = styled<React.FC<BoxProps>>(Box)`
  ${applyBoxStyle}

`

export const StyledCloseIcon = styled<React.FC<IconProps>>(FormClose as any)``
export const StyledCloseButton = styled<React.FC<ButtonProps>>(Button)`
  display: flex;
  padding: 0;
`
