import React, { useRef, InputHTMLAttributes } from 'react';
import { types } from '@catalytic/react-view';
import { Anchor, Box, TextInput, Avatar } from 'grommet';
import { FormField } from '../../components/FormField';
import { StyledFileBoxInput } from './FileView.styled';
import { Label } from '../../components/Label/Label';
import { useFile } from './FileView.hooks';
import { Attachment } from 'grommet-icons';

export function FileViewReader(props: types.FileView) {
  const file = useFile(props);
  const url = file ? URL.createObjectURL(file) : file;
  return <Box direction="row" gap="xsmall" align="center">
    {
      !file
        ? null
        : file.type.startsWith('image/')
          ? <Avatar size="small" src={url} />
          : <Attachment size="small" />
    }
    {file ? <Anchor href={url}>{file.name}</Anchor> : null}
  </Box>
}

export function FileViewEditor(props: types.FileView) {
  const value = props.value ?? props.defaultValue() ?? undefined
  const ref = useRef<HTMLInputElement>(null);
  return <>
    {
      value !== undefined
        ? <StyledFileBoxInput>
          <Label onClose={() => {
            if (ref.current) ref.current.value = '';
            props.setValue(undefined)
          }}>
            <FileViewReader {...props} />
          </Label>
        </StyledFileBoxInput>
        : null
    }
    <TextInput
      ref={ref as any}
      disabled={props.node().disabled}
      name={props.node().reference}
      style={value !== undefined ? { display: 'none' } : {}}
      type="file"
      accept={props.schema()?.contentMediaType}
      onChange={(e: any) => {
        e.stopPropagation();
        props.setValue(e.target.files[0]);
      }}
    />
  </>
}

export function FileView(props: types.FileView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <FileViewReader {...props} />
      : <FileViewEditor {...props} />}
  </FormField>
}
