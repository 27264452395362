import React, { PropsWithChildren } from 'react';
import { ViewContext, ViewContextProps } from '@catalytic/react-view';

export type ViewContextExtendProps = PropsWithChildren<Partial<ViewContextProps>>;

export const ViewContextExtend = ({ children, editor: nextEditor = {}, reader: nextReader = {} }: ViewContextExtendProps) => (
  <ViewContext.Consumer>
    {({ editor, reader }) => (
      <ViewContext.Provider value={{
        editor: { ...editor, ...nextEditor },
        reader: { ...reader, ...nextReader }
      }}>
        {children}
      </ViewContext.Provider>
    )}
  </ViewContext.Consumer>
);
