import {
  Clause,
  ClauseNodeType,
  Expression,
  Func,
  Identifier,
  Limit,
  Literal,
  Map,
  Node,
  NodeType,
  Operation,
  Order,
  Range,
  Result,
  Select,
} from "./type";

export interface NodeBuilder<
  T extends NodeType,
  N extends Node<T>,
  K extends string = "type"
> {
  <O extends N = N>(props: Omit<N, K>): O;
}

export type ClauseBuilder<
  T extends ClauseNodeType,
  N extends Clause<T>
> = NodeBuilder<T, N>;

export type ExpressionBuilder<N extends Expression = Expression> = NodeBuilder<
  NodeType.EXPRESSION,
  N
>;

export type FunctionBuilder<N extends Func = Func> = NodeBuilder<
  NodeType.FUNCTION,
  N
>;

export type IdentifierBuilder<
  N extends Identifier = Identifier
> = ClauseBuilder<NodeType.IDENTIFIER, N>;

export type LimitBuilder<N extends Limit = Limit> = NodeBuilder<
  NodeType.LIMIT,
  N
>;

export type LiteralBuilder<N extends Literal = Literal> = ClauseBuilder<
  NodeType.LITERAL,
  N
>;

export type MapBuilder<N extends Map = Map> = NodeBuilder<NodeType.MAP, N>;

export type ResultBuilder<N extends Result = Result> = NodeBuilder<
  NodeType.RESULT,
  N
>;

export type OperationBuilder<N extends Operation = Operation> = ClauseBuilder<
  NodeType.OPERATION,
  N
>;

export type OrderBuilder<N extends Order = Order> = NodeBuilder<
  NodeType.ORDER,
  N
>;

export type RangeBuilder<N extends Range = Range> = ClauseBuilder<
  NodeType.RANGE,
  N
>;

export type SelectBuilder<N extends Select = Select> = NodeBuilder<
  NodeType.SELECT,
  N
>;

export interface QueryBuilder {
  expression: ExpressionBuilder;
  func: FunctionBuilder;
  identifier: IdentifierBuilder;
  limit: LimitBuilder;
  literal: LiteralBuilder;
  map: MapBuilder;
  operation: OperationBuilder;
  order: OrderBuilder;
  range: RangeBuilder;
  result: ResultBuilder;
  select: SelectBuilder;
}

export function createNodeBuilder<
  T extends NodeType,
  N extends Node<T>,
  K extends string = "type"
>(type: T): NodeBuilder<T, N, K> {
  return <O extends N = N>(props: Omit<N, K>): O =>
    ({
      ...props,
      type,
    } as O);
}

export function createExpressionBuilder<
  N extends Expression = Expression
>(): ExpressionBuilder<N> {
  return createClauseBuilder(NodeType.EXPRESSION);
}

export function createFunctionBuilder<
  N extends Func = Func
>(): FunctionBuilder<N> {
  return createNodeBuilder(NodeType.FUNCTION);
}

export function createClauseBuilder<
  T extends ClauseNodeType,
  N extends Clause<T>
>(type: T): ClauseBuilder<T, N> {
  return createNodeBuilder(type);
}

export function createIdentifierBuilder<
  N extends Identifier = Identifier
>(): IdentifierBuilder<N> {
  return createClauseBuilder(NodeType.IDENTIFIER);
}

export function createLimitBuilder<N extends Limit = Limit>(): LimitBuilder<N> {
  return createNodeBuilder(NodeType.LIMIT);
}

export function createLiteralBuilder<
  N extends Literal = Literal
>(): LiteralBuilder<N> {
  return createClauseBuilder(NodeType.LITERAL);
}

export function createOperationBuilder<
  N extends Operation = Operation
>(): OperationBuilder<N> {
  return createClauseBuilder(NodeType.OPERATION);
}

export function createMapBuilder<N extends Map = Map>(): MapBuilder<N> {
  return createNodeBuilder(NodeType.MAP);
}

export function createResultBuilder<
  N extends Result = Result
>(): ResultBuilder<N> {
  return createNodeBuilder(NodeType.RESULT);
}

export function createRangeBuilder<N extends Range = Range>(): RangeBuilder<N> {
  return createClauseBuilder(NodeType.RANGE);
}

export function createOrderBuilder<N extends Order = Order>(): OrderBuilder<N> {
  return createNodeBuilder(NodeType.ORDER);
}

export function createSelectBuilder<
  N extends Select = Select
>(): SelectBuilder<N> {
  return createClauseBuilder(NodeType.SELECT);
}

export function createQueryBuilder(): QueryBuilder {
  return {
    expression: createExpressionBuilder(),
    func: createFunctionBuilder(),
    identifier: createIdentifierBuilder(),
    limit: createLimitBuilder(),
    literal: createLiteralBuilder(),
    map: createMapBuilder(),
    operation: createOperationBuilder(),
    order: createOrderBuilder(),
    range: createRangeBuilder(),
    result: createResultBuilder(),
    select: createSelectBuilder(),
  };
}

export const {
  expression,
  func,
  identifier,
  limit,
  literal,
  map,
  operation,
  order,
  range,
  result,
  select,
} = createQueryBuilder();
