const recast = require('recast');
const types = require('./types');
const eachField = require('ast-types').eachField;

const n = types.namedTypes;
const b = types.builders;
const Type = types.Type;

const rename = type => function renameFN(path) {
  path.node.type = type.name; // eslint-disable-line no-param-reassign
  this.traverse(path);
};

const visitors = {
  visitPBXBinaryExpression: rename(n.BinaryExpression),
  visitPBXExpressionStatement: rename(n.ExpressionStatement),
  visitPBXFunctionExpression: path => b.callExpression(
    b.identifier(path.node.operator),
    [
      path.node.left,
      path.node.right
    ]
  ),
  visitPBXIdentifier: rename(n.Identifier),
  visitPBXLiteral: rename(n.Literal),
  visitPBXLogicalAndExpression: rename(n.LogicalExpression),
  visitPBXLogicalOrExpression: rename(n.LogicalExpression),
  visitPBXMemberExpression: rename(n.MemberExpression),
  visitPBXProgram: rename(n.Program),
  visitPBXUnaryFunctionExpression: path => b.callExpression(
    b.identifier(path.node.operator),
    [
      path.node.argument
    ]
  ),
  visitPBXUnaryNotExpression: rename(n.UnaryExpression),
  visitPBXUnarySignExpression: rename(n.UnaryExpression)
};

const copy = ast => {
  const node = {};
  eachField(ast, (name, value) => {
    node[name] = value instanceof Type ? copy(value) : value;
  });
  return node;
}

const normalize = ast => types.visit(copy(ast), visitors);

const print = (ast, options) => {
  let tree = normalize(ast);

  if (n.Program.check(tree)) {
    tree = tree.body.expression
  } else if (n.ExpressionStatement.check(tree)) {
    tree = tree.expression
  }

  return recast.print(tree, options)
}
;

module.exports = print;
