import { StyleFactory } from './style.interfaces';
import { BorderType, BorderTypeKind, BorderKindType } from '../theme/theme.interfaces';
import { normalizeColor, normalizeBorderSize } from '../theme/theme.utils';
import { css } from 'styled-components';
import { mergeKind } from './style.utils';

export const applyBorderTypeStyle: StyleFactory<BorderType> = (props, kindProp) => {
  const kind: BorderTypeKind[] = typeof kindProp === 'string'
    ? [{ side: kindProp } as BorderTypeKind]
    : kindProp === true
      ? [{ side: 'all' } as BorderTypeKind] as BorderTypeKind[]
      : kindProp === false
        ? [] as BorderTypeKind[]
        : Array.isArray(kindProp)
          ? kindProp
          : kindProp !== undefined
            ? [kindProp] as BorderTypeKind[]
            : [] as BorderTypeKind[]

  return kind.reduce(
    (styles, kind) => {
      const color = normalizeColor(props, kind.color);
      const size = normalizeBorderSize(props, kind.size || 'xsmall');
      const style = kind.style || 'solid';
      const side = kind.side || 'all';
      const value = `${style} ${size} ${color}`;
      switch (side) {
        case 'top':
        case 'bottom':
        case 'left':
        case 'right':
          styles.push(css`border-${side}: ${value};`);
          break;
        case 'end':
        case 'start':
          styles.push(css`border-inline-${side}: ${value};`);
          break;
        case 'vertical':
          styles.push(
            css`border-left: ${value};`,
            css`border-right: ${value};`
          );
          break;
        case 'horizontal':
          styles.push(
            css`border-top: ${value};`,
            css`border-bottom: ${value};`
          );
          break;
        case 'all':
          styles.push(
            css`border: ${value};`
          );
          break;
      }
      if (kind.radius) {
        styles.push(
          css`border-radius: ${kind.radius};`
        )
      }
      return styles
    },
    [] as any[]
  )
}

export const applyBorderStyle: StyleFactory<BorderKindType> = (props, kind) => {
  const border = mergeKind(kind?.border, props.theme?.types?.border);
  return border ? applyBorderTypeStyle(props, border, border) : []
}
