import React from 'react';
import compose from 'ramda/src/compose';
import { Markdown as GrommetMarkdown } from 'grommet';
import styled from 'styled-components';

const components = {
  hr: styled.hr`
margin: 0.5em 0 1em 0
`,
  p: styled.p`
max-width: none
`,
  ul: styled.ul`
padding-left: 1em
`
};

export interface MarkdownProps {
  value?: string
}

const singleLineBreaks = (value: string): string => value.replace(/(?<!\n)\n(?!\n)/gm, '\n\n');
const horizontalRules = (value: string): string => value.replace(/^_{3,}/gm, '---')
const emptyHeading = (value: string): string => value.replace(/^(#+)$/gm, '$1 ')
const format = compose(
  horizontalRules,
  singleLineBreaks,
  emptyHeading
);

export function Markdown(props: MarkdownProps) {
  return <GrommetMarkdown components={components}>{format(
    typeof props.value === 'string' ? props.value : ''
  )}</GrommetMarkdown>
}
