import { Anchor, Avatar, Box, Image } from 'grommet';
import React from 'react';
import imageTMC from '../images/powered-by-tmc.svg';
import imageLogo from '../images/sgws-logo-small.svg';

export const Header = () => (
  <Anchor href="/" style={{ textDecoration: 'none' }}>
    <Box direction="row" gap="small">
      <Avatar size="50px" src={imageLogo} title="Southern Glazer's Wines & Spirits Portal" />
      <Image height="50px" src={imageTMC} title="Powered By TMC a division of C.H. Robinson" />
    </Box>
  </Anchor>
)
