import { Identifier, Select } from "@catalytic/query";
import clone from "ramda/src/clone";
import { SQLFormatter, SQLFormatterExtension } from "./transform.interfaces";
import { fromSelect } from "./transform.utils";

const createFormatter = (extensions: SQLFormatterExtension[] = []) => {
  const fn = (n: Select, base: Identifier[] = []): string => {
    const select = extensions.reduce(
      (results, extension) => extension(results),
      clone(n)
    );
    return fromSelect(select, base);
  };
  fn.extend = (extension: SQLFormatterExtension) =>
    createFormatter([...extensions, extension]);
  return fn;
};

export const toSQL: SQLFormatter = createFormatter();
