import { types } from '@catalytic/react-view';
import { toLocalISO, toLocalLocaleString } from '../../date-time/date-time.utils';
import { useMemo } from 'react';

export function useLocalISODate(props: types.DateView): string {
  return useMemo(
    () => {
      const value = props.value ?? props.defaultValue();
      if (!value) return '';
      return toLocalISO(value)
    },
    [props.value]
  )
}


export function useLocaleDate(props: types.DateView): string {
  return useMemo(
    () => {
      const value = props.value ?? props.defaultValue();
      if (!value) return '';
      const {
        hour,
        hour12,
        hourCycle,
        minute,
        second,
        timeStyle,
        ...node
      } = props.node();
      return toLocalLocaleString(value, node)
    },
    [props.value]
  )
}
