import { types } from '@catalytic/react-view';
import { Select, Text } from 'grommet';
import React, { useMemo } from 'react';
import { FormField } from '../../components/FormField';

export function SelectionViewEditor(props: types.SelectionView) {
  const options = useMemo(
    (): any[] => {
      const schema = props.schema();
      const options = props.optionValues() || schema?.enum || schema?.items?.enum || []
      if (schema?.nullable && !options.includes(null)) {
        options.push(null)
      }
      return options
    },
    [props.optionValues, props.schema]
  )

  return <Select
    disabled={props.node().disabled}
    name={props.node().reference}
    options={options}
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue(e.option);
    }}
    value={props.value || props.defaultValue() || props.schema()?.default || ''} />
}

export function SelectionViewReader(props: types.SelectionView) {
  const value = props.value || props.defaultValue() || props.schema()?.default || '';
  return <Text>{value}</Text>
}


export function SelectionView(props: types.SelectionView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <SelectionViewReader {...props} />
      : <SelectionViewEditor {...props} />}
  </FormField>
}
