import React, { Context, ReactType, useContext } from 'react';
import { JSONRefType } from "@catalytic/json-schema-validator";
import { NodeProps } from '@catalytic/react-view';

export type CatalyticReferenceViewMap = Partial<Record<JSONRefType, ReactType<NodeProps>>>;

export type CatalyticReferenceViewContextProps = {
  editor: CatalyticReferenceViewMap;
  reader: CatalyticReferenceViewMap;
};

export const CatalyticReferenceViewContext: Context<CatalyticReferenceViewContextProps> = React.createContext({
  editor: {},
  reader: {}
});

export type CatalyticReferenceViewProviderProps = React.PropsWithChildren<CatalyticReferenceViewContextProps>

export const CatalyticReferenceViewProvider = ({ children, ...value }: CatalyticReferenceViewProviderProps) =>
  <CatalyticReferenceViewContext.Provider children={children} value={value} />

export const useCatalyticReferenceViewContext = (): CatalyticReferenceViewContextProps => {
  const context = useContext(CatalyticReferenceViewContext);
  return context;
}

export type CatalyticReferenceViewContextExtendProps = Partial<CatalyticReferenceViewContextProps>;

export const CatalyticReferenceViewContextExtend = ({ children, editor: nextEditor = {}, reader: nextReader = {} }) =>
  <CatalyticReferenceViewContext.Consumer>
    {({ editor, reader }) => (
      <CatalyticReferenceViewContext.Provider value={{
        editor: { ...editor, ...nextEditor },
        reader: { ...reader, ...nextReader }
      }}>
        {children}
      </CatalyticReferenceViewContext.Provider>
    )}
  </CatalyticReferenceViewContext.Consumer>
