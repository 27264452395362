import { ThemeType } from '../../theme/theme.interfaces';

export enum Colors {
  // Applied
  Cascade = '#96A99E',
  Elephant = '#193441',
  WhiteSmoke = '#F6F6F6',
  // Southern
  BlackCow = '#474745',
  DarkGrey = '#393937',
  DarkJungleGreen = '#252524',
  FallLeafBrown = '#D2B357',
  Fantasy = '#F7F5EC',
  Merino = '#F1F0E6',
  Platinum = '#E2E2E2',
  Soyabean = '#6A6453',
  VividAuburn = '#9f1e20',
  White = '#FFF',
  // GT
  LightPurple = '#f3eff9',
  Purple = '#4f2d7f',
  Gray = '#c8beaf',
  Blue = '#00a7b5',
  Green = '#9bd732',
  Magnesium = '#C0C0C0',
  Orange = '#ff7d1e',
  DarkOrange = '#F57B26',
  Red = '#de002e',
  Text = '#707070',
  Border = '#e0e0e0',
  BackSmoke = '#F5F5F5'
}

export const theme: ThemeType = {
  global: {
    colors: {
      'accent-1': Colors.FallLeafBrown,
      brand: Colors.Cascade,
      control: {
        light: Colors.Cascade,
        dark: Colors.DarkJungleGreen
      },
      'control-inverse': {
        dark: Colors.Cascade,
        light: Colors.DarkJungleGreen
      },
      background: {
        light: Colors.White,
        dark: Colors.DarkGrey
      },
      'background-contrast': {
        light: Colors.WhiteSmoke,
        dark: Colors.DarkJungleGreen
      },
      'background-inverse': {
        light: Colors.DarkGrey,
        dark: Colors.White
      },
      border: {
        light: Colors.Platinum,
        dark: Colors.BlackCow
      },
      'border-1': Colors.Magnesium,
      'focus': Colors.FallLeafBrown,
      'neutral-1': Colors.BackSmoke,
      'neutral-2': Colors.LightPurple,
      'status-error': Colors.Red,
      'status-warning': Colors.DarkOrange,
      'status-ok': Colors.Green,
      'status-unknown': Colors.White,
      text: {
        dark: Colors.Merino,
        light: Colors.Elephant
      },
      'text-inverse': {
        light: Colors.Merino,
        dark: Colors.Soyabean
      }
    },
    font: {
      family: '"Museo Sans W01", Arial, "Lucida Grande", sans-serif',
      size: '16px'
    },
    input: {
      font: {
        weight: '400'
      }
    }
  },
  formField: {
    error: {
      margin: 'none'
    },
    help: {
      margin: 'none'
    },
    info: {
      margin: 'none'
    },
    label: {
      margin: 'none'
    },
    border: false
  },
  select: {
    background: 'white'
  },
  textInput: {
    extend: () => 'background: white'
  },
  button: {
    border: {
      radius: '2px'
    }
  },
  heading: {
    level: {
      1: {
        medium: {
          size: '38px',
          height: '40px'
        }
      },
      2: {
        medium: {
          size: '38px',
          height: '40px'
        }
      },
      3: {
        medium: {
          size: '28px',
          height: '30px'
        }
      },
      4: {
        medium: {
          size: '23px',
          height: '34px'
        }
      },
      5: {
        medium: {
          size: '18px',
          height: '19px'
        }
      },
      6: {
        medium: {
          size: '16px',
          height: '17px'
        }
      }
    },
    weight: 400
  },
  components: {
    button: {
      primary: {
        color: 'white'
      }
    },
    input: {
      background: 'white'
    },
    label: {
      background: 'background-back'
    },
    pageHeader: {
      heading: {
        color: 'text-inverse',
        font: {
          size: 'xlarge'
        }
      },
      header: {
        background: 'background-inverse',
        pad: {
          horizontal: 'medium'
        }
      }
    }
  },
  types: {
    font: {
      size: 'large',
      weight: '400'
    }
  },
  views: {
    checkbox: {
      button: {
        unchecked: {
          background: 'white'
        }
      }
    },
    table: {
      container: {
        background: 'white'
      }
    },
    toggleSwitch: {
      button: {
        unchecked: {
          background: 'white'
        }
      }
    }
  }
}
