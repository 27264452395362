

const print = require('./print');
const sentenceCase = require('change-case').sentenceCase;

class ParseError extends SyntaxError {
  constructor(message, path) {
    const node = path.node;
    const start = node.loc.start;
    const lineNumber = start.line
    const columnNumber = start.column
    const fileName = '@pushbot-expression';
    const description = `${message} (${fileName}:${lineNumber}:${columnNumber})`;
    super(description, fileName, lineNumber);
    this.columnNumber = columnNumber;
  }
};

class InvalidTypeError extends ParseError {
  constructor(path) {
    super(`${sentenceCase(path.node.type)}s '${print(path.node).code}' are not supported`, path);
  }
};

module.exports.ParseError = ParseError;
module.exports.InvalidTypeError = InvalidTypeError;
