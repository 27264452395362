import { Facebook, Google, Twitter, Windows } from "grommet-icons";
import React from "react";
import { LoginProvider } from "./login.interfaces";

export const LOGIN_PROVIDERS: LoginProvider[] = [
  {
    name: "Microsoft",
    icon: <Windows color="plain" />,
    url: '/.auth/login/aad'
  },
  {
    name: "Google",
    icon: <Google color="plain" />,
    url: '/.auth/login/google'
  },
  {
    name: "Facebook",
    icon: <Facebook color="plain" />,
    url: '/.auth/login/facebook'
  },
  {
    name: "Twitter",
    icon: <Twitter color="plain" />,
    url: '/.auth/login/twitter'
  }
]


export const LOGIN_REQUEST_FORM = 'https://tmc.pushbot.com/form/sgws-supplierportal-user-request-form';
