import React, { Context } from 'react';
import { CatalyticClient } from '@catalytic/sdk';

export type CatalyticClientContextProps = {
  instance: CatalyticClient
};

export const CatalyticClientContext: Context<CatalyticClientContextProps> = React.createContext({
  instance: new CatalyticClient()
});

export type CatalyticClientProviderProps = React.PropsWithChildren<CatalyticClientContextProps>

export const CatalyticClientProvider = ({ children, ...value }: CatalyticClientProviderProps) =>
  <CatalyticClientContext.Provider value={value} >
    {children}
  </CatalyticClientContext.Provider>
