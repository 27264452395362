import React from 'react';
import { types } from '@catalytic/react-view';
import { Box, Heading } from 'grommet';

export function GroupView(props: types.GroupView) {
  const title = props.node()?.title;
  return <Box>
    {title ? <Heading level={4} color='brand'>{title}</Heading> : null}
    {props.children()}
  </Box>
}
