import { DependencyList, useCallback } from 'react'

export const useHandler = <T extends Array<any>>(handler: ((...args: T) => void) | undefined, dependencies: DependencyList = []) =>
  useCallback(
    (...args: T) => {
      if (typeof handler !== 'function') return;
      handler(...args)
    },
    [handler, ...dependencies]
  )
