import compose from 'ramda/src/compose';

const REPLACE_NOT = /(?:^|\s)!\b(?!:\w)\b/g;

export const replaceNot = (source: string): string =>
  source.replace(REPLACE_NOT, ' not ').trim();

const REPLACE_LOGICAL_AND = /&&/g;

export const replaceAnd = (source: string): string =>
  source.replace(REPLACE_LOGICAL_AND, ' and ').trim();

const REPLACE_LOGICAL_OR = /\|\|/g;

export const replaceOr = (source: string): string =>
  source.replace(REPLACE_LOGICAL_OR, ' or ').trim();

export const preparse = compose<string, string, string, string>(
  replaceAnd,
  replaceOr,
  replaceNot
);
