import { DataTableExportFormat } from '@catalytic/sdk/dist/src/clients/DataTableClient';
import { useEffect, useState, useCallback } from 'react';
import { Column, Cell } from 'tdast-types';
import fromArray from 'tdast-util-from-array';
import { parse } from 'papaparse';
import toJSON from 'tdast-util-to-json';
import visit from 'unist-util-visit';
import { useCatalyticClient } from '../catalytic-client/catalytic-client.hooks';
import { UseClient, UseClientState } from '../client/client.hooks';
import { useOrchestrationClient } from '../orchestration-client/orchestration-client.hooks';
import { toCSV } from './data-table.utils';
import { DataTable } from './data-table.interfaces';

export const useDataTable: UseClient<DataTable> = (id?: string) => {
  const { instance: catalytic } = useCatalyticClient();
  const { instance: orchestration } = useOrchestrationClient();

  const [state, setState] = useState<UseClientState<DataTable>>({
    loading: true
  });

  useEffect(
    () => {
      if (!id) return;
      const init = async () => {
        try {
          const data: DataTable = await catalytic.dataTables.get(id);
          const { columns = [] } = await orchestration.getTable(id)

          // Apply additional field information
          data.columns.map(
            column => {
              const f = columns.find(f => f.fieldName === column.referenceName);

              // Manually Correct Instruction Field Type
              if ((f?.restrictions as any)?.displayOnly) {
                column.type = 'instructions';
              }
              // Manually Add View
              if (f?.view) {
                column.view = f.view;
              }

              // Manually Add Field Conditions
              column.conditionCode = f?.conditionCode;

              return column
            }
          )

          setState({
            loading: false,
            data
          })
        } catch (e) {
          setState({
            loading: false,
            errors: [e]
          })
        }
      };
      init();
    },
    [id]
  )

  return state
};

export function useDataTableData<T extends Object = Object>(id?: string): UseClientState<T[]> {
  const { instance } = useCatalyticClient();
  const [state, setState] = useState<UseClientState<T[]>>();

  useEffect(
    () => {
      if (!id) return;
      const init = async () => {
        try {
          const csv = await (await instance.dataTables.getDownloadBlob(id, DataTableExportFormat.CSV)).text();
          const results = parse(csv, { header: true });
          const tree = fromArray(
            results.data,
            {
              columns: results.meta.fields.map(
                (field, index) => ({
                  type: 'column',
                  label: field,
                  value: field,
                  index
                }) as Column
              )
            }
          )

          // Normalize Header Column Names
          visit(
            tree,
            'column',
            (node: Column) => {
              if (typeof node.value === 'string') {
                node.value = node.value.trim().toLowerCase().replace(/\s+/g, '-')
              }
            }
          )

          visit(
            tree,
            'cell',
            (node: Cell) => {
              if (typeof node.value !== 'string') {
                node.value = node.value.toString()
              }
            }
          )

          const [header] = tree.children;
          tree.children = tree.children.map(
            (row, index) => {
              if (index === 0) return row;
              row.children = header.children.reduce(
                (o, column: Column) => {
                  const cell: Cell = (row.children.find(
                    (cell: Cell) => cell.columnIndex === column.index
                  ) as Cell) || {
                    type: 'cell',
                    rowIndex: index,
                    columnIndex: column.index,
                    value: ''
                  }
                  o.push(cell);
                  return o;
                },
                []
              )
              return row;
            }
          )

          const data = JSON.parse(toJSON(tree));

          setState({
            loading: false,
            data
          })
        } catch (e) {
          setState({
            loading: false,
            errors: [e]
          })
        }
      };
      init();
    },
    [id]
  )

  return state
};

export type UseDataTableUtilitiesState<T extends Object = Object> = {
  replace: (id: string, data: T[]) => Promise<DataTable>,
  upload: (data: T[]) => Promise<DataTable>
};

export function useDataTableUtilities<T extends Object = Object>(): UseDataTableUtilitiesState<T> {
  const { instance: catalytic } = useCatalyticClient();
  const { instance: orchestration } = useOrchestrationClient();
  const replace = useCallback<(id: string, data: T[]) => Promise<DataTable>>(
    async (id, data) => {
      const csv = await toCSV(data, { prependHeader: false, emptyFieldValue: '' });
      const contents = new Blob([csv], { type: 'text/csv' });
      const { columns = [] } = await orchestration.getTable(id);
      const table = await catalytic.dataTables.replace(id, { contents: contents as any, path: `${id}.csv` });
      await Promise.all(
        columns?.map(
          async column => {
            await orchestration.updateTableColumn(column, id, column.fieldName as any)
          }
        )
      )
      return table
    },
    [catalytic, orchestration]
  )
  const upload = useCallback<(data: T[]) => Promise<DataTable>>(
    async (data) => {
      const csv = await toCSV(data, { prependHeader: true, emptyFieldValue: '' });
      const contents = new Blob([csv], { type: 'text/csv' });
      const table = await catalytic.dataTables.upload({ contents: contents as any, path: `new.csv` });
      return table
    },
    [catalytic, orchestration]
  )
  return {
    replace,
    upload
  }
}
