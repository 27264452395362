import { Box, Button, ButtonProps } from 'grommet';
import { FormClose } from 'grommet-icons';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { StyledLabel, StyledCloseIcon, StyledCloseButton, StyledLabelContainer } from './Label.styled';

export type LabelProps = PropsWithChildren<{
  onClose?: MouseEventHandler
  closeButtonProps?: Omit<ButtonProps, 'onClick' | 'onFocus'>
}>

export const Label = ({ onClose, children, closeButtonProps }: LabelProps) => {
  return <StyledLabel
    align="center"
    direction="row"
    gap="xsmall"
  >
    <StyledLabelContainer flex>
      {children}
    </StyledLabelContainer>
    {
      onClose ? <StyledCloseButton icon={<StyledCloseIcon />} onClick={onClose} onFocus={event => event.stopPropagation()}  {...closeButtonProps} /> : null
    }
  </StyledLabel>
};
