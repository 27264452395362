import * as React from "react";
import * as view from "@catalytic/view";
import * as component from "./type";

export type ViewMap = {
  [view.NodeType.BLOCKQUOTE]?: React.ReactType<component.Blockquote>;
  [view.NodeType.BOARD_VIEW]?: React.ReactType<component.BoardView>;
  [view.NodeType.BOOLEAN_VIEW]?: React.ReactType<component.BooleanView>;
  [view.NodeType.BREAK]?: React.ReactType<component.Break>;
  [view.NodeType.CALENDAR_VIEW]?: React.ReactType<component.CalendarView>;
  [view.NodeType
    .CHECKBOX_SELECTION_VIEW]?: React.ReactType<component.CheckboxSelectionView>;
  [view.NodeType.CHECKBOX_VIEW]?: React.ReactType<component.CheckboxView>;
  [view.NodeType.CODE]?: React.ReactType<component.Code>;
  [view.NodeType.CODE_VIEW]?: React.ReactType<component.CodeView>;
  [view.NodeType.CURRENCY_VIEW]?: React.ReactType<component.CurrencyView>;
  [view.NodeType
    .CURRENCY_RANGE_VIEW]?: React.ReactType<component.CurrencyRangeView>;
  [view.NodeType.DATE_RANGE_VIEW]?: React.ReactType<component.DateRangeView>;
  [view.NodeType
    .DATE_TIME_RANGE_VIEW]?: React.ReactType<component.DateTimeRangeView>;
  [view.NodeType.DATE_TIME_VIEW]?: React.ReactType<component.DateTimeView>;
  [view.NodeType.DATE_VIEW]?: React.ReactType<component.DateView>;
  [view.NodeType.DEFINITION]?: React.ReactType<component.Definition>;
  [view.NodeType.DELETE]?: React.ReactType<component.Delete>;
  [view.NodeType.EMAIL_VIEW]?: React.ReactType<component.EmailView>;
  [view.NodeType.EMPHASIS]?: React.ReactType<component.Emphasis>;
  [view.NodeType
    .FILE_REFERENCE_VIEW]?: React.ReactType<component.FileReferenceView>;
  [view.NodeType
    .FILE_TABLE_REFERENCE_VIEW]?: React.ReactType<component.FileTableReferenceView>;
  [view.NodeType.FILE_VIEW]?: React.ReactType<component.FileView>;
  [view.NodeType.FOOTNOTE]?: React.ReactType<component.Footnote>;
  [view.NodeType
    .FOOTNOTE_DEFINITION]?: React.ReactType<component.FootnoteDefinition>;
  [view.NodeType
    .FOOTNOTE_REFERENCE]?: React.ReactType<component.FootnoteReference>;
  [view.NodeType.GALLERY_VIEW]?: React.ReactType<component.GalleryView>;
  [view.NodeType.GROUP_VIEW]?: React.ReactType<component.GroupView>;
  [view.NodeType.HEADING]?: React.ReactType<component.Heading>;
  [view.NodeType.HTML]?: React.ReactType<component.HTML>;
  [view.NodeType.IMAGE]?: React.ReactType<component.Image>;
  [view.NodeType.IMAGE_REFERENCE]?: React.ReactType<component.ImageReference>;
  [view.NodeType
    .IMAGE_REFERENCE_VIEW]?: React.ReactType<component.ImageReferenceView>;
  [view.NodeType.INLINE_CODE]?: React.ReactType<component.InlineCode>;
  [view.NodeType.INTEGER_VIEW]?: React.ReactType<component.IntegerView>;
  [view.NodeType.LINK]?: React.ReactType<component.Link>;
  [view.NodeType.LINK_REFERENCE]?: React.ReactType<component.LinkReference>;
  [view.NodeType.LIST]?: React.ReactType<component.List>;
  [view.NodeType.LIST_ITEM]?: React.ReactType<component.ListItem>;
  [view.NodeType.LIST_VIEW]?: React.ReactType<component.ListView>;
  [view.NodeType
    .MULTIPLE_SELECTION_VIEW]?: React.ReactType<component.MultipleSelectionView>;
  [view.NodeType
    .NUMBER_RANGE_VIEW]?: React.ReactType<component.NumberRangeView>;
  [view.NodeType.NUMBER_VIEW]?: React.ReactType<component.NumberView>;
  [view.NodeType.NODE]?: React.ReactType<component.Node>;
  [view.NodeType.OBJECT_VIEW]?: React.ReactType<component.ObjectView>;
  [view.NodeType.PARAGRAPH]?: React.ReactType<component.Paragraph>;
  [view.NodeType.PARENT_VIEW]?: React.ReactType<component.ParentView>;
  [view.NodeType.PASSWORD_VIEW]?: React.ReactType<component.PasswordView>;
  [view.NodeType.PERCENT_VIEW]?: React.ReactType<component.PercentView>;
  [view.NodeType
    .PERCENT_RANGE_VIEW]?: React.ReactType<component.PercentRangeView>;
  [view.NodeType.PICKER_VIEW]?: React.ReactType<component.PickerView>;
  [view.NodeType
    .RADIO_SELECTION_VIEW]?: React.ReactType<component.RadioSelectionView>;
  [view.NodeType.RANGE_VIEW]?: React.ReactType<component.RangeView>;
  [view.NodeType.REFERENCE_VIEW]?: React.ReactType<component.ReferenceView>;
  [view.NodeType
    .RELATIVE_DELAY_VIEW]?: React.ReactType<component.RelativeDelayView>;
  [view.NodeType
    .RELATIVE_DURATION_VIEW]?: React.ReactType<component.RelativeDurationView>;
  [view.NodeType
    .RELATIVE_TIME_VIEW]?: React.ReactType<component.RelativeTimeView>;
  [view.NodeType.RICH_TEXT_VIEW]?: React.ReactType<component.RichTextView>;
  [view.NodeType.ROOT]?: React.ReactType<component.Root>;
  [view.NodeType.SELECTION_VIEW]?: React.ReactType<component.SelectionView>;
  [view.NodeType.STRING_VIEW]?: React.ReactType<component.StringView>;
  [view.NodeType.STRONG]?: React.ReactType<component.Strong>;
  [view.NodeType.SWITCH_VIEW]?: React.ReactType<component.SwitchView>;
  [view.NodeType.TABLE]?: React.ReactType<component.Table>;
  [view.NodeType.TABLE_CELL]?: React.ReactType<component.TableCell>;
  [view.NodeType.TABLE_ROW]?: React.ReactType<component.TableRow>;
  [view.NodeType.TABLE_VIEW]?: React.ReactType<component.TableView>;
  [view.NodeType.TEXT]?: React.ReactType<component.Text>;
  [view.NodeType.TEXT_AREA_VIEW]?: React.ReactType<component.TextAreaView>;
  [view.NodeType.TEXT_VIEW]?: React.ReactType<component.TextView>;
  [view.NodeType.THEMATIC_BREAK]?: React.ReactType<component.ThematicBreak>;
  [view.NodeType.TIME_RANGE_VIEW]?: React.ReactType<component.TimeRangeView>;
  [view.NodeType.TIME_VIEW]?: React.ReactType<component.TimeView>;
  [view.NodeType
    .TOGGLE_SWITCH_VIEW]?: React.ReactType<component.ToggleSwitchView>;
  [view.NodeType.URL_VIEW]?: React.ReactType<component.URLView>;
  [view.NodeType.VIEW]?: React.ReactType<component.View>;
  [view.NodeType.YAML]?: React.ReactType<component.YAML>;
};

export type ViewContextProps = {
  editor: ViewMap;
  reader: ViewMap;
};

const DEFAULT_PROPS = {
  get editor(): ViewMap {
    throw new Error("Missing <ViewContext />");
  },
  set editor(_types: ViewMap) {
    throw new Error("Missing <ViewContext />");
  },
  get reader(): ViewMap {
    throw new Error("Missing <ViewContext />");
  },
  set reader(_types: ViewMap) {
    throw new Error("Missing <ViewContext />");
  },
};

const ViewContext: React.Context<ViewContextProps> = React.createContext(
  DEFAULT_PROPS
);

export default ViewContext;
