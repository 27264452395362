/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";

const packageName = "";
const packageVersion = "0.3.5";

export class OrchestrationAPIContext extends msRest.ServiceClient {
  teamname: string;
  credentials: msRest.ServiceClientCredentials;

  /**
   * Initializes a new instance of the OrchestrationAPIContext class.
   * @param teamname
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, teamname: string, options?: Models.OrchestrationAPIOptions) {
    if (teamname == undefined) {
      throw new Error("'teamname' cannot be null.");
    }
    if (credentials == undefined) {
      throw new Error("'credentials' cannot be null.");
    }

    if (!options) {
      options = {};
    }

    if (!options.userAgent) {
      const defaultUserAgent = msRest.getDefaultUserAgentValue();
      options.userAgent = `${packageName}/${packageVersion} ${defaultUserAgent}`;
    }

    super(credentials, options);

    this.baseUri = options.baseUri || this.baseUri || "http://localhost";
    this.requestContentType = "application/json; charset=utf-8";
    this.teamname = teamname;
    this.credentials = credentials;
  }
}
