import { types } from '@catalytic/react-view';
import { Anchor, Avatar, Box, TextInput } from 'grommet';
import { Attachment } from 'grommet-icons';
import React from 'react';
import { FormField } from '../../components/FormField';
import { Label } from '../../components/Label/Label';
import { Loading } from '../../components/Loading';
import { useFileMetadata, useFileUploadHandler } from './FileReferenceView.hooks';
import { StyledFileBoxInput, StyledFileInput } from './FileReferenceView.styled';

export function FileReferenceViewEditor(props: types.FileReferenceView) {
  const uploadFile = useFileUploadHandler(props);
  const value = props.value ?? props.defaultValue() ?? undefined
  return <>
    {!value
      ? <StyledFileInput
        type="file"
        disabled={props.node().disabled}
        onChange={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          uploadFile(e)
        }}
      />
      : <StyledFileBoxInput>
        <Label onClose={() => props.setValue(undefined)}>
          <FileReferenceViewReader {...props} />
        </Label>
      </StyledFileBoxInput>
    }
    <TextInput
      type="hidden"
      disabled={props.node().disabled}
      name={props.node().reference}
      value={value ?? ''} />
  </>
}

export function FileReferenceViewReader(props: types.FileReferenceView) {
  const file = useFileMetadata(props);
  return <Box direction="row" gap="xsmall" align="center">
    {
      file?.contentType?.startsWith('image/')
        ? <Avatar size="small" src={file.id} />
        : <Attachment size="small" />
    }
    {file ? <Anchor href={file.url}>{file?.name}</Anchor> : <Loading />}
  </Box>
}

export function FileReferenceView(props: types.FileReferenceView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <FileReferenceViewReader {...props} />
      : <FileReferenceViewEditor {...props} />}
  </FormField>
}
