import {
  OperatorType,
  DirectionType,
  Select,
  Identifier,
} from "@catalytic/query";

export enum SQLOperatorType {
  AND = "AND",
  BETWEEN = "BETWEEN",
  EQ = "=",
  GT = ">",
  GTE = ">=",
  IN = "IN",
  IS = "IS",
  LIKE = "LIKE",
  LT = "<",
  LTE = "<=",
  NOT_BETWEEN = "NOT BETWEEN",
  NOT_EQ = "!=",
  NOT_IS = "IS NOT",
  NOT_IN = "NOT IN",
  NOT_LIKE = "NOT LIKE",
  OR = "OR",
}

export const SQL_OPERATOR_MAP: Record<OperatorType, SQLOperatorType> = {
  [OperatorType.AND]: SQLOperatorType.AND,
  [OperatorType.BETWEEN]: SQLOperatorType.BETWEEN,
  [OperatorType.EQ]: SQLOperatorType.EQ,
  [OperatorType.GT]: SQLOperatorType.GT,
  [OperatorType.GTE]: SQLOperatorType.GTE,
  [OperatorType.IN]: SQLOperatorType.IN,
  [OperatorType.IS]: SQLOperatorType.IS,
  [OperatorType.LIKE]: SQLOperatorType.LIKE,
  [OperatorType.LT]: SQLOperatorType.LT,
  [OperatorType.LTE]: SQLOperatorType.LTE,
  [OperatorType.NOT_BETWEEN]: SQLOperatorType.NOT_BETWEEN,
  [OperatorType.NOT_EQ]: SQLOperatorType.NOT_EQ,
  [OperatorType.NOT_IN]: SQLOperatorType.NOT_IN,
  [OperatorType.NOT_IS]: SQLOperatorType.NOT_IS,
  [OperatorType.NOT_LIKE]: SQLOperatorType.NOT_LIKE,
  [OperatorType.OR]: SQLOperatorType.OR,
};

export enum SQLOperatorType {
  ASC = "ASC",
  DESC = "DESC",
}

export const SQL_DIRECTION_MAP: Record<DirectionType, SQLOperatorType> = {
  [DirectionType.ASC]: SQLOperatorType.ASC,
  [DirectionType.DESC]: SQLOperatorType.DESC,
};

export interface SQLFormatterExtension {
  (select: Select): Select;
}

export interface SQLFormatter {
  (n: Select, base?: Identifier[]): string;
  extend: (extension: SQLFormatterExtension) => SQLFormatter;
}
