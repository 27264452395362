import { Box } from 'grommet';
import styled from 'styled-components';
import { createStyleFactory } from '../../style/style.factories';
import { applyInputStyle } from '../../style/style.input';

const defaultInputStyle = createStyleFactory({
  applyStyle: applyInputStyle,
  getKind: ({ theme }) =>
    theme?.views?.fileReference?.input
})

export const StyledFileBoxInput = styled(Box)`
  ${defaultInputStyle}
  align-items: flex-start;
`;
