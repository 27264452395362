import { Box, Button, Select } from 'grommet';
import { FormClose } from 'grommet-icons';
import React, { useState, useCallback, useMemo } from 'react';
import { DirectionType, Identifier, Order } from '@catalytic/query';
import { Option } from './Option.interfaces';
import { fullname, basename } from './Identifier.utils';
import { ListItem } from './ListItem';

export interface SortItemChangeEvent {
  order: Order
}

export interface SortItemRemoveEvent {
  order: Order
}

export interface SortItemProps {
  order: Order<DirectionType, Identifier>,
  columns: Option[],
  onChange?: (event: SortItemChangeEvent) => void
  onRemove?: (event: SortItemRemoveEvent) => void
}

export const SortItem = (props: SortItemProps) => {
  const value = useMemo(
    () => {
      const column = props.columns.find(
        ({ value }) =>
          value === basename(props.order.expression.name)
      )
      return column?.value
    },
    [props.columns, props.order.expression.name]
  );
  const handleChange = useCallback(
    ({ column, direction }: { column?: string, direction?: DirectionType }) => {
      if (props.onChange) {
        const order = { ...props.order };
        if (direction) order.direction = direction
        if (column) {
          order.expression.name = fullname(order.expression.name, column)
        }
        props.onChange({ order });
      }
      if (direction) setDirection(direction)
      if (column) setColumn(column)
    },
    [props.onChange]
  )
  const handleRemove = useCallback(
    () => {
      if (props.onRemove) {
        const order = { ...props.order };
        props.onRemove({ order });
      }
    },
    [props.onRemove]
  )
  const [column, setColumn] = useState(value);
  const [direction, setDirection] = useState(props.order.direction);
  return <ListItem>
    <Select
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={props.columns}
      onChange={({ value }) => handleChange({ column: value })}
      value={column}
    />
    <Select
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={[
        { label: 'Ascending', value: 'ASC' },
        { label: 'Descending', value: 'DESC' }
      ]}
      onChange={({ value }) => handleChange({ direction: value })}
      value={direction}
    />
    {props.onRemove ? <Button icon={<FormClose />} onClick={handleRemove} /> : null}
  </ListItem>
}
