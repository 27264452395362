import { TextArea } from 'grommet';
import styled from 'styled-components';
import { createStyleFactory } from '../../style/style.factories';
import { applyInputStyle } from '../../style/style.input';
import { mergeKind } from '../../style/style.utils';

const defaultInputStyle = createStyleFactory({
  applyStyle: applyInputStyle,
  getKind: ({ theme }) =>
    mergeKind(theme?.views?.textArea?.input, theme?.components?.input)
})

export const StyledTextArea = styled(TextArea)`
  ${defaultInputStyle}
`;
