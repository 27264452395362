import { rejectUndefined } from '@catalytic/reject';
import { css } from 'styled-components';
import { ForegroundBackgroundKindType } from '../theme/theme.interfaces';
import { normalizeColor } from '../theme/theme.utils';
import { isDark, setOpacity } from './style.color';
import { SimpleForegroundBackgroundColors, StyleFactory, StyleTransform } from './style.interfaces';

export const isURLString = (value: any): value is string => typeof value === 'string' && value.startsWith('url');

export const transformForegroundBackgroundColors: StyleTransform<ForegroundBackgroundKindType, SimpleForegroundBackgroundColors> = (props, kind = {}) => {
  kind.color = kind.color || 'text';

  let {
    background,
    color
  } = kind;

  const forceDark = typeof background !== 'string' && background?.dark ? true : false;

  color = normalizeColor(
    props,
    color,
    { forceDark }
  ) || 'text'

  if (!background) return rejectUndefined({ color })

  const opacity: number | undefined = typeof background === 'string'
    ? undefined
    : background.opacity === true
      ? props.theme?.global?.opacity?.medium
      : typeof background.opacity === 'string'
        ? props.theme?.global?.opacity?.[background?.opacity?.toString()]
        : background.opacity !== false
          ? background.opacity
          : undefined

  background = typeof background === 'string'
    ? normalizeColor(props, background, { forceDark }) as string
    : background.color
      ? normalizeColor(props, background.color, { forceDark }) as string
      : undefined

  background = background !== undefined && opacity !== undefined ? setOpacity(background, opacity) : background

  const shade = background ? isDark(background) : false;

  color = normalizeColor(props, kind.color, { forceDark: shade }) || color;

  return rejectUndefined({ background, color })
}

export const applyForegroundBackgroundStyle: StyleFactory<ForegroundBackgroundKindType> = (props, kind = {}, fragment) => {
  const { background, color } = transformForegroundBackgroundColors(
    props,
    kind,
    fragment
  );

  // for Grommet component, if the background isn't defined, don't set it
  if (kind.background === undefined && kind.color === undefined) return

  const colorStyle = css`${color ? `color: ${color};` : ''}`

  if (isURLString(kind.background)) {
    return css`
  ${colorStyle}
  background: ${kind.background} no-repeat center center;
  background-size: cover;
  `;
  }

  if (kind.background?.image) {
    const backgroundStyles = `
  ${colorStyle}
  ${background ? `background-color: ${background};` : ''}
  background-image: ${kind.background.image};
  background-repeat: ${kind.background.repeat || 'no-repeat'};
  background-position: ${kind.background.position || 'center center'};
  background-size: ${kind.background.size || 'cover'};
  `;

    // allow both background color and image, in case the image doesn't fill
    // when image and opacity are used together, we need to use pseudo :before
    // to ensure that only image and background color are affected by opacity
    // but not the container contents
    return css`
  ${colorStyle}
  ${!kind.background.opacity
        ? backgroundStyles
        : `position: relative;
        z-index: 0;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: -1;
          ${backgroundStyles}
          opacity: ${kind.background.opacity === true
          ? props.theme?.global?.opacity?.medium
          : props.theme?.global?.opacity?.[kind.background.opacity] || kind.background.opacity
        };
        }`}
  `;
  }

  if (background) {
    return css`
  ${colorStyle}
  background-color: ${background};
  `;
  }

  if (typeof background === 'string')
    // This case takes care of gradients
    // or theme colors that use CSS names like 'crimson' that we don't parse
    return css`
  ${colorStyle}
  background: ${normalizeColor(background, props.theme)};
  `;

  return colorStyle;
};
