import { types } from '@catalytic/react-view';
import { Box, Select, Text } from 'grommet';
import React, { useMemo } from 'react';
import { FormField } from '../../components/FormField';
import { Label } from '../../components/Label/Label';
import { useLocaleList } from './MultipleSelectionView.hooks';

export function MultipleSelectionViewEditor(props: types.MultipleSelectionView) {
  const options = useMemo(
    (): any[] => {
      const schema = props.schema();
      const options = props.optionValues()
      return options || schema?.enum || schema?.items?.enum || []
    },
    [props.optionValues, props.schema]
  )
  return <Select
    closeOnChange={false}
    disabled={props.node().disabled}
    multiple
    name={props.node().reference}
    options={options.filter((v: any) => !(Array.isArray(props.value) ? props.value : []).includes(v))}
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue((Array.isArray(props.value) ? props.value : []).concat(e.value));
    }}
    value={props.value}
    valueLabel={
      <Box wrap direction="row" height={{ min: "41px" }} justify="start" pad="small" gap="small">
        {(Array.isArray(props.value) ? props.value : []).map(
          (value: any, index: number) => <Label
            key={`${index}`}
            onClose={(e) => {
              e.stopPropagation();
              const next = (props.value || []).filter((v: any) => v !== value)
              props.setValue(next);
            }}
          >{value}</Label>
        )}
      </Box>
    } />
}

export function MultipleSelectionViewReader(props: types.MultipleSelectionView) {
  const value = useLocaleList(props);
  return <Text>{value}</Text>
}

export function MultipleSelectionView(props: types.MultipleSelectionView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <MultipleSelectionViewReader {...props} />
      : <MultipleSelectionViewEditor {...props} />}
  </FormField>
}
