import { useMemo } from 'react';
import { jsonSchemaToView } from '@catalytic/view-util-from-json-schema';
import { ColumnOption } from './Column.interfaces';
import { CollectionViewProps } from './View.interfaces';
import { Role } from '@catalytic/view';

export interface UseColumnsProps extends CollectionViewProps { }

export const useColumns = (props: UseColumnsProps): ColumnOption[] => {
  return useMemo<ColumnOption[]>(
    () => {
      const properties = props.schema?.items?.properties ?? {}
      return Object.entries(properties).map(
        ([value, schema]) => ({
          label: schema.title ?? value,
          value,
          schema,
          node: { ...jsonSchemaToView(schema), role: Role.EDITOR }
        })
      )
    },
    [props.schema]
  )
}
