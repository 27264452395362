const includes = require('lodash/includes');
const startsWith = require('lodash/startsWith');
const endsWith = require('lodash/endsWith');
const isBoolean = require('lodash/isBoolean');
const isEmpty = require('lodash/isEmpty');
const isNumber = require('lodash/isNumber');

// Function Expressions

function ENDS_WITH(left, right) {
  return endsWith(left, right);
}

function IN(left, right) {
  return includes(right, left);
}

function STARTS_WITH(left, right) {
  return startsWith(left, right);
}

// Unary Function Expressions

function IS_EMPTY(argument) {
  return isNumber(argument) ? !argument : isEmpty(argument);
}

function IS_EMPTY2(argument) {
  if (isNumber(argument)) {
    return isNaN(argument) || !isFinite(argument);
  }
  if (isBoolean(argument)) {
    return false;
  }
  return isEmpty(argument);
}

module.exports.ENDS_WITH = ENDS_WITH;
module.exports.IN = IN;
module.exports.IS_EMPTY = IS_EMPTY;
module.exports.IS_EMPTY2 = IS_EMPTY2;
module.exports.STARTS_WITH = STARTS_WITH;
