import { factory } from 'mathjs';
import REndsWith from 'ramda/src/endsWith';
import RIncludes from 'ramda/src/includes';
import RIsEmpty from 'ramda/src/isEmpty';
import RIsNil from 'ramda/src/isNil';
import RStartsWith from 'ramda/src/startsWith';
import RIsType from 'ramda/src/type';
import { TypeSignatureFactory } from './types';

const ramdaFactory = (key: string, fn: TypeSignatureFactory): Function =>
  factory(key, [], () => fn);

const legacyEndsWith = (a: any, b: any): boolean => REndsWith(b, a);

export const ENDS_WITH = ramdaFactory('ENDS_WITH', legacyEndsWith);
export const endsWith = ramdaFactory('endsWith', REndsWith);

const empty = (value: any): any =>
  RIsType(value) === 'Number'
    ? isNaN(value) || !isFinite(value)
    : RIsType(value) === 'Boolean'
    ? false
    : RIsEmpty(value) || RIsNil(value);

export const IS_EMPTY = ramdaFactory('IS_EMPTY', empty);
export const isEmpty = ramdaFactory('isEmpty', empty);

export const IN = ramdaFactory('IN', RIncludes);
export const includes = ramdaFactory('includes', RIncludes);

const legacyStartsWith = (a: any, b: any): boolean => RStartsWith(b, a);

export const STARTS_WITH = ramdaFactory('STARTS_WITH', legacyStartsWith);
export const startsWith = ramdaFactory('startsWith', RStartsWith);
