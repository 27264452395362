import { css } from 'styled-components';
import { IconKindType } from '../theme/theme.interfaces';
import { applyFontStyle } from './style.font';
import { applyForegroundBackgroundStyle } from './style.foreground-background';
import { StyleFactory } from "./style.interfaces";

export const applyIconStyle: StyleFactory<IconKindType> = (props, kind, fragment) => css`
${applyForegroundBackgroundStyle(props, kind, fragment)}
${applyFontStyle(props, kind, fragment)}
`
