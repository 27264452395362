import { useMemo } from 'react';
import { types } from '@catalytic/react-view';

export function useLocaleNumber(props: types.NumberView): string {
  return useMemo(
    () => {
      if (!props.value) return '';
      const int = parseFloat(props.value);
      if (isNaN(int)) return '';
      return int.toLocaleString(undefined, props.node())
    },
    [props.value]
  )
}
