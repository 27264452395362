import { BoxProps, HeadingProps } from 'grommet';
import React from 'react';
import { StyledPageHeader, StyledPageHeaderHeading } from './PageHeader.styled';

export type PageHeaderProps = BoxProps & {
  action?: React.ReactNode;
  title?: string,
  headingProps?: HeadingProps
};

export const PageHeader = ({ action, title, headingProps, ...props }: PageHeaderProps) => {
  return <StyledPageHeader {...props}>
    <StyledPageHeaderHeading {...headingProps}>{title}</StyledPageHeaderHeading>
    {action}
  </StyledPageHeader>
}
