import { Box } from 'grommet';
import styled from 'styled-components';
import { applyBoxStyle } from '../../style/style.box';
import { createStyleFactory } from '../../style/style.factories';
import { BoxKindType } from '../../theme/theme.interfaces';

const defaultContainerStyle = createStyleFactory<BoxKindType>({
  applyStyle: applyBoxStyle,
  getKind: ({ theme }) => theme?.views?.table?.container
})

export const StyledTableViewContainer = styled(Box)`
  ${defaultContainerStyle}
`;
