import { StyleFactory } from "./style.interfaces";
import { BoxKindType } from '../theme/theme.interfaces';
import { css } from 'styled-components';
import { applyForegroundBackgroundStyle } from './style.foreground-background';
import { applyMarginStyle, applyPadStyle } from './style.edge';
import { applyFontStyle } from './style.font';
import { applyBorderStyle } from './style.border';

export const applyBoxStyle: StyleFactory<BoxKindType> = (props, kind, fragment) => css`
${applyForegroundBackgroundStyle(props, kind, fragment)}
${applyMarginStyle(props, kind, fragment)}
${applyPadStyle(props, kind, fragment)}
${applyFontStyle(props, kind, fragment)}
${applyBorderStyle(props, kind, fragment)}
`
