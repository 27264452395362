import React from 'react';
import ReactDOM from 'react-dom';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import Login from './login/login';

setUseWhatChange(process.env.NODE_ENV === 'development');

ReactDOM.render(
  <Login />,
  document.getElementById('root')
);
