import { Node, NodeType } from "@catalytic/query";
import { NodeGuard } from "./guard.interfaces";

export const createNodeGuard = <N extends Node = Node>(
  type: NodeType
): NodeGuard<N> => (node: Node): node is N =>
  node && typeof node === "object" && node.type === type;

export const createNodeUnionGuard = <N extends Node = Node>(
  types: NodeType[]
): NodeGuard<N> => (node: Node): node is N =>
  node && typeof node === "object" && node.type && types.includes(node?.type);
