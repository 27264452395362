const types = require('./types');

const n = types.namedTypes;
const Type = types.Type
const InvalidTypeError = require('./error').InvalidTypeError;

const visitorName = node => {
  let name;

  if (typeof node === 'string') {
    name = node;
  } else if (node instanceof Type) {
    name = node.name;
  } else {
    name = node.type;
  }

  return `visit${name}`
};

const build = visitors => {
  const programName = visitorName(n.Program);
  const program = visitors[programName] ||
                  function programFN(path) {
                    this.traverse(path);
                  };

  return function buildFN(node, options) {
    const tolerant = (options || {}).tolerant || false;
    const errors = [];
    let results;
    types.visit(node, Object.assign(
      {},
      visitors,
      {
        [programName] (path) {
          try {
            program.call(this, path);
          } catch (e) {
            errors.push(e);
            if (tolerant) e.cancel();
          }
          results = path.node;
        }
      }
    ));

    if (!tolerant && errors.length !== 0) {
      throw errors[0];
    };

    if (errors.length) results.errors = errors;

    return results;
  }
}

function abort (visitor, path) {
  try {
    visitor.abort();
  } catch (e) {
    const error = new InvalidTypeError(path);
    error.cancel = () => e.cancel();
    throw error;
  }
};

const combine = (stack) => function stackFN(path) {
  // Prepare children
  this.traverse(path);

  const next = stack.slice();
  let transform = next.shift();
  let node;

  while (transform) {
    try {
      node = transform.call(this, path);
      break;
    } catch (e) {
      // ignore error
    }
    transform = next.shift();
  }

  // No visitor passed, so assume type is invalid
  if (!node) abort(this, path);

  // Tranform the node
  path.replace(node);
}

class Validator {

  static abort (visitor, path) {
    try {
      visitor.abort();
    } catch (e) {
      const error = new InvalidTypeError(path);
      error.cancel = () => e.cancel();
      throw error;
    }
  }

  static invalid (path) {
    abort(this, path);
  }

  static valid (path) {
    this.traverse(path);
  }

  valid (type) {
    return this.transform(type, () => true, node => node);
  }

  transform (...tests) {
    const type = tests.shift();
    const name = type.name;
    const nextTypes = this.types || {};
    const stack = nextTypes[name] || [];
    nextTypes[name] = stack.concat(tests);
    this.types = nextTypes;
    return this;
  }

  visitors() {
    return Object.keys(n)
                 .reduce((o, k) => Object.assign(o, {
                   [visitorName(k)]: this.types[k] ? combine(this.types[k]) : Validator.invalid
                 }), {});
  }

  build() {
    return build(this.visitors());
  };
}

module.exports = Validator;
