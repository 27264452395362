import { DateTime, DateTimeFormatOptions, LocaleOptions as LuxonLocaleOptions } from 'luxon';

export const toLocal = (value: string): DateTime =>
  DateTime.fromISO(value).toLocal()

export const toLocalISO = (value: string): string =>
  toLocal(value).toISO()

export const toLocalISODate = (value: string): string =>
  toLocal(value).toISODate()

export const toISODate = (value: string): string =>
  DateTime.fromISO(value).toISODate()

export const toISODateTime = (value: string): string =>
  DateTime.fromISO(value).toISO()

export const toISOTime = (value: string): string =>
  DateTime.fromISO(value).toISOTime()

export type LocaleOptions = LuxonLocaleOptions & DateTimeFormatOptions

export const toLocalLocaleString = (value: string, options?: LocaleOptions): string => {
  const dateTime = toLocal(value);
  try {
    return dateTime.toLocaleString(options)
  } catch (e) {
    return dateTime.toLocaleString()
  }
}
