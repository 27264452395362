import { types } from '@catalytic/react-view';
import { useMemo } from 'react';

export function useLocaleList(props: types.MultipleSelectionView): string {
  return useMemo(
    () => {
      if (!props.value || !Array.isArray(props.value)) return '';
      const list: any[] = props.value;
      return list.toLocaleString()
    },
    [props.value]
  )
}
