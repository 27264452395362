import { JSONSchema, JSONValue } from '@catalytic/react-view';
import pointer from 'json-pointer';
import createValidator, { JSONSchemaValidator, JSONFormat } from '@catalytic/json-schema-validator'
import { toISODate, toISODateTime, toISOTime } from '../date-time/date-time.utils';

export type JSONDictionary = Record<string, JSONValue>;

const coerce = (
  () => {
    let validator: JSONSchemaValidator;
    return (value: any, schema?: JSONSchema): [JSONValue, boolean] => {
      if (!schema) return [value, true];
      if (!validator) {
        validator = createValidator({ ajvOptions: { coerceTypes: true } })
      }
      const validity = validator.validate(schema, value);
      return [value, validity];
    };
  }
)()

export const fromInputValue = (value: any, schema?: JSONSchema): JSONValue => {
  let next = value;
  try {
    next = value === undefined
      ? null
      : !schema || typeof value !== 'string'
        ? value
        : value.trim().length === 0
          ? null
          : schema.type === 'string'
            ? value
            : JSON.parse(value)
  } catch (e) { }

  if (schema?.format && typeof next === 'string') {
    switch (schema.format) {
      case JSONFormat.DATE:
        next = toISODate(next);
        break;
      case JSONFormat.DATE_TIME:
        next = toISODateTime(next);
        break;
      case JSONFormat.TIME:
        next = toISOTime(next);
        break;
    }
  }

  const [coercedValue, valid] = coerce(next, schema)
  return valid ? coercedValue : next;
};

export const getSchema = (ptr: string, schema?: JSONSchema): JSONSchema | undefined => {
  if (!schema) return;
  try {
    return pointer.get(
      schema,
      pointer.compile(
        pointer.parse(ptr).reduce(
          (o: string[], name: string) => {
            o.push('properties')
            o.push(name)
            return o
          },
          []
        )
      )
    )
  } catch (e) {
  }
};

export const fromJSONDictionary = (dict: JSONDictionary, schema?: JSONSchema): JSONValue => {
  return Object.entries(dict).reduce(
    (o, [k, v]) => {
      pointer.set(o, k, fromInputValue(v, getSchema(k, schema)));
      return o;
    },
    {}
  )
}
