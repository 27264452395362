import {
  literal,
  operation,
  OperatorType,
  Select,
  select,
} from "@catalytic/query";
import { fromSQL, SQLParser } from "@catalytic/query-util-from-sql";
import { SQLFormatter, toSQL } from "@catalytic/query-util-to-sql";
import clone from "ramda/src/clone";
import {
  MergeSQLOptions,
  Query,
  SQLMergeConfiguration,
} from "./merge.interfaces";

export const formatSQL = (
  query: Query,
  formatter: SQLFormatter = toSQL
): string =>
  typeof query !== "string"
    ? formatter(query)
    : formatter !== toSQL
    ? formatter(fromSQL(query))
    : query;

export const parseSQL = (query: Query, parser: SQLParser = fromSQL): Select =>
  typeof query === "string"
    ? parser(query)
    : parser !== fromSQL
    ? parser(toSQL(query))
    : clone(query);

const createMerge = (configuration: SQLMergeConfiguration) => {
  const fn = (
    left: Query,
    right: Query,
    options: MergeSQLOptions = {}
  ): Select => {
    const {
      limit = configuration.limit ?? 100,
      offset = configuration.offset ?? 0,
    } = options;
    const l = parseSQL(left, configuration.parser);
    const r = parseSQL(right, configuration.parser);

    const sql = select({
      from: r.from ?? l.from,
    });

    if (l.result || r.result) {
      sql.result = r.result ?? l.result;
    }

    if (l.where?.length || r.where?.length) {
      const where =
        l.where?.length || 0 > r.where?.length || 0 ? l.where : r.where;
      sql.where = where.map((_clause, index) => {
        const left = l.where?.[index];
        const right = r.where?.[index];
        const op = !left
          ? right
          : !right
          ? left
          : operation({ left, right, operation: OperatorType.AND });

        return op;
      });
    }

    if (l.order || r.order) {
      sql.order = [...(l.order ?? []), ...(r.order ?? [])];
    }

    if (l.limit || r.limit) {
      sql.limit = r.limit ?? l.limit;
      sql.limit.length =
        r.limit?.length ?? l.limit?.length ?? literal({ value: limit });
      sql.limit.offset =
        r.limit?.offset ?? l.limit?.offset ?? literal({ value: offset });
    }
    return sql;
  };

  fn.asString = (left: Query, right: Query, options?: MergeSQLOptions) => {
    return configuration.formatter(fn(left, right, options));
  };

  fn.extend = (options: Partial<SQLMergeConfiguration>) =>
    createMerge({ ...configuration, ...options });

  return fn;
};

export const mergeSQL = createMerge({
  formatter: toSQL,
  parser: fromSQL,
});
