import React from 'react';
import { types } from '@catalytic/react-view';
import { Text, TextInput } from 'grommet';
import { FormField } from '../../components/FormField';
import { useLocaleNumber } from './NumberView.hooks';

export function NumberViewEditor(props: types.NumberView) {
  return <TextInput
    disabled={props.node().disabled}
    name={props.node().reference}
    type="number"
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue(e.target.value);
    }}
    value={props.value ?? props.defaultValue() ?? ''} />
}

export function NumberViewReader(props: types.NumberView) {
  const value = useLocaleNumber(props);
  return <Text>{value}</Text>
}

export function NumberView(props: types.NumberView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <NumberViewReader {...props} />
      : <NumberViewEditor {...props} />}
  </FormField>
}
