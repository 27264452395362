import React, { useCallback } from "react";
import { types } from '@catalytic/react-view';
import { Box, FormField as GrommetFormField, Text, TextInput, BoxProps } from "grommet";
import { fromInputValue } from '../../util/toJSONValue'
import { useTheme } from '../../theme/theme.hooks';

export type FormFieldProps = Omit<types.NodeProps, 'children'> & {
  children: any,
  contentProps?: BoxProps
}

export const FormField = (props: FormFieldProps) => {
  //const theme = useTheme();
  //const contentProps = theme.formField?.content ? { ...theme.formField.content, ...contentPropsProps } : contentPropsProps
  const node = props.node();
  const schema = props.schema();
  const visible = props.visible();
  const value = props.value
  const required = !schema?.nullable;
  const label = (node?.title ?? schema?.title) as string;
  const help = (node?.label ?? schema?.description) as string;
  const name = node?.reference;

  const validate = useCallback(
    value => {
      const validate = props.validator();
      let isValid = true;
      try {
        isValid = validate(fromInputValue(value, schema)) as boolean;
      } catch (e) {
        console.error(e, value, schema);
      }
      if (!isValid) {
        const [error] = validate.errors || [];
        return {
          message: error?.message || 'unknown',
          status: 'error'
        }
      }
    },
    [props.validator]
  )

  if (!visible) {
    return <GrommetFormField name={name} contentProps={{ ...props.contentProps, border: false }} >
      <TextInput hidden name={name} value={value} />
    </GrommetFormField>
  }

  if (props.schema()?.displayOnly) {
    return <Box {...props.contentProps}>
      {props.children}
      <TextInput hidden name={name} value={value} />
    </Box>
  }

  return (
    <GrommetFormField
      contentProps={props.contentProps}
      onChange={e => {
        e.preventDefault()
      }}
      label={
        required ? (
          <Box direction="row">
            <Text>{label}</Text>
            <Text color="status-critical">*</Text>
          </Box>
        ) : (
            label
          )
      }
      help={<Text size="xsmall" margin={{ bottom: '2px' }}>{help}</Text>}
      required={required}
      name={name}
      validate={validate}
    >
      {props.children}
    </GrommetFormField >
  );
};
