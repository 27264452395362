import React from 'react';
import { Box, BoxProps } from 'grommet';
import { PageHeader, PageHeaderProps } from '../PageHeader';

export type ErrorProps = BoxProps & {
  contentProps?: BoxProps,
  title?: string,
  message?: React.ReactNode,
  pageHeaderProps?: PageHeaderProps
}

export const Error = ({ contentProps, pageHeaderProps, title = 'Error', message = 'An unknown error occured', ...props }: ErrorProps) => {
  return <Box {...props}>
    <PageHeader
      title={title} {...pageHeaderProps} />
    <Box {...contentProps}>
      {message}
    </Box>
  </Box>
}

export const Forbidden = ({
  message = 'You don’t have permissions for this page. Please check your login info and permissions and try again.',
  title = 'Forbidden',
  ...props
}: ErrorProps) =>
  <Error title={title} message={message} {...props} />

export const NotFound = ({
  message = 'This page does not exist.',
  title = 'Not Found',
  ...props
}: ErrorProps) =>
  <Error title={title} message={message} {...props} />
