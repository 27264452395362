import { ThemeType } from '../../theme/theme.interfaces';
import { css } from 'styled-components';

export const theme: ThemeType = {
  global: {
    colors: {
      background: {
        light: '#ffffff',
        dark: '#000000',
      },
    },
    control: {
      border: {
        width: '0px'
      }
    },
    font: {
      family: `-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Oxygen,
Ubuntu,
Cantarell,
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
Arial, sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol"`,
    },
  },
  button: {
    extend: css`${(props: any) => !props.plain && 'font-weight: bold;'}`,
  },
  views: {
    table: {
      container: {
        background: 'white'
      }
    }
  }
}
