import { OperatorType, DirectionType } from "@catalytic/query";
import {
  AnyToken,
  BinaryOperator,
  ComparisonOperator,
  Direction,
} from "../lexer/lexer.interfaces";

export interface Transform<I extends any[] = AnyToken[], O = I> {
  (input: I): O;
}

export const ComparisonOperationMap: Record<
  ComparisonOperator,
  OperatorType
> = {
  [ComparisonOperator.EQ]: OperatorType.EQ,
  [ComparisonOperator.GT]: OperatorType.GT,
  [ComparisonOperator.GTE]: OperatorType.GTE,
  [ComparisonOperator.IS]: OperatorType.IS,
  [ComparisonOperator.LIKE]: OperatorType.LIKE,
  [ComparisonOperator.LT]: OperatorType.LT,
  [ComparisonOperator.LTE]: OperatorType.LTE,
  [ComparisonOperator.NOT_EQ]: OperatorType.NOT_EQ,
  [ComparisonOperator.NOT_IS]: OperatorType.NOT_IS,
  [ComparisonOperator.NOT_LIKE]: OperatorType.NOT_LIKE,
};

export const BinaryOperationMap: Record<BinaryOperator, OperatorType> = {
  [BinaryOperator.AND]: OperatorType.AND,
  [BinaryOperator.BETWEEN]: OperatorType.BETWEEN,
  [BinaryOperator.IN]: OperatorType.IN,
  [BinaryOperator.NOT_BETWEEN]: OperatorType.NOT_BETWEEN,
  [BinaryOperator.NOT_IN]: OperatorType.NOT_IN,
  [BinaryOperator.OR]: OperatorType.OR,
};

export const DirectionMap: Record<Direction, DirectionType> = {
  [Direction.ASC]: DirectionType.ASC,
  [Direction.DESC]: DirectionType.DESC,
};
