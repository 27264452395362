/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * A configurable app parameter.
 */
export interface AppParameter {
  /**
   * A description of how the parameter is used by the app.
   */
  description?: string;
  /**
   * An example value for the parameter.
   */
  example?: string;
  /**
   * Possible values include: 'string', 'float', 'int', 'datetime', 'boolean', 'latlong', 'json',
   * 'file', 'choice', 'bool', 'date'
   */
  type?: Type;
}

/**
 * A Pushbot App is a step in a process that is done by Pushbot instead of a user.
 * @summary App schema.
 */
export interface App {
  /**
   * The name of the app.
   */
  appName?: string;
  /**
   * The name of the action for the app to take.
   */
  action?: string;
  /**
   * The major version of the app.
   */
  version?: string;
  /**
   * Valid app ID value.
   */
  appID: string;
  /**
   * The featureName this app is tied to.
   */
  featureName?: string;
  /**
   * List of moduleNames this app's feature is tied to.
   */
  moduleNames?: string[];
  /**
   * The appID of the base app that this is derived from, if any.
   */
  baseAppID?: string;
  /**
   * The display name of the app action.
   */
  displayName: string;
  /**
   * A description of how the parameter is used by the app.
   */
  description: string;
  /**
   * Indicates whether this app is in draft mode or is published. Default value: true.
   */
  published: boolean;
  /**
   * Indicates whether this app is shared in the community app directory for other teams to use.
   * Default value: false.
   */
  shared?: boolean;
  /**
   * Indicates whether this is a trigger. Default value: false.
   */
  trigger?: boolean;
  /**
   * App parameters.
   */
  params?: { [propertyName: string]: AppParameter };
  /**
   * Ordered list of app parameters
   */
  paramOrder?: string[];
  /**
   * The URL for OAuth, if the app uses OAuth to authenticate to a 3rd party system.
   */
  oauthUrl?: string;
  /**
   * Who the app was created by.
   */
  createdBy: string;
  /**
   * Indicates whether or not the app was configured with user defined fields.
   */
  configuredWithUserDefinedFields?: boolean;
  /**
   * List of appIDs that may provide authentication for this app
   */
  authAppIDs?: string[];
  /**
   * List of Output Fields associated to this app
   */
  outputFields?: string[];
  /**
   * Whether authentication by one of the apps given in authAppIDs is required to use this app
   */
  authRequired?: boolean;
  /**
   * Optional feature flag to hide app's visibility in the UI
   */
  featureFlag?: string;
  /**
   * Boolean that determines if targeting is on for a specific featureFlag
   */
  featureFlagActive?: boolean;
  /**
   * Boolean that determines if an app record is a custom integration
   */
  customIntegration?: boolean;
  /**
   * OAuth configuration for user-created app step
   */
  oauthConfig?: any;
  integrationDefinition?: boolean;
  requiresDbEntry?: boolean;
  helpDocumentPath?: boolean;
  credentialConfig?: any;
}

/**
 * An object with keys being the feature and the value being their enabled status
 * @summary Features schema.
 */
export interface FeatureState {
  triggers?: boolean;
}

/**
 * Pushbot fields are named, typed variables associated with a specific context (like a Run,
 * Process or User). They can be referenced in conditions, templates, apps and more. They can be
 * saved without a value, in which case they act as a placeholder.  Every time a value is assigned,
 * a new version of the field is created so that a history of the field is maintained.
 * @summary Field schema.
 */
export interface FieldPartial {
  /**
   * Universally unique identifier
   */
  fieldID?: string;
  /**
   * Valid Pushbot entity name.
   */
  fieldName?: string;
  /**
   * The display name of the field.
   */
  displayName?: string;
  /**
   * The description of the field.
   */
  description?: string;
  /**
   * The description template for fields with templated descriptions.
   */
  descriptionSourceText?: any;
  /**
   * An example of a value for the field.
   */
  example?: string;
  /**
   * Type of the field. Note that "choice", "date" and "bool" are deprecated and will be removed.
   * Possible values include: 'string', 'float', 'int', 'datetime', 'boolean', 'latlong', 'json',
   * 'file', 'choice', 'bool', 'date', 'array', 'table'. Default value: 'string'.
   */
  type?: Type9;
  /**
   * Value of the field.
   */
  value?: string;
  /**
   * Indicates whether this field is required or not. Default value: false.
   */
  isRequired?: boolean;
  /**
   * The user-intent of this field. For example, "password" or "photo".
   */
  intent?: string;
  restrictions?: any;
  conditionCode?: string;
  /**
   * Visibility of the field, defining which users can see its value. Possible values include:
   * 'public', 'internal', 'confidential', 'highlyConfidential'. Default value: 'public'.
   */
  visibility?: Visibility1;
  /**
   * An array of the users who have access to this field's content.
   */
  visibleToUsers?: string[];
  /**
   * ID for the process.
   */
  processID?: string;
  /**
   * ID for the run.
   */
  runID?: string;
  /**
   * ID for the table associated with this field
   */
  tableID?: string;
  /**
   * ID for the row associated with this field, if any
   */
  rowID?: string;
  /**
   * The ID of the Predictor used to generate this field's value, if any
   */
  predictedBy?: string;
  /**
   * Whether this field is created by an app step
   */
  isCreatedByApp?: boolean;
  /**
   * If the field is for display only purposes
   */
  displayOnly?: boolean;
  /**
   * Whether this field is a metadata field that is not persisted
   */
  isMetadata?: boolean;
  /**
   * The username of a user with access to this field's content.
   */
  items?: FieldPartialItems;
  /**
   * Deprecated path attribute added to support old builder UI
   */
  path?: string;
  /**
   * The details of the view for this field.
   */
  view?: any;
}

/**
 * An interface representing Field.
 */
export interface Field extends FieldPartial {
}

/**
 * An interface representing Module.
 */
export interface Module {
  moduleID: string;
  moduleName: string;
  displayName: string;
  description?: string;
  createTime: string;
  updateTime: string;
  createdBy: string;
  updatedBy: string;
}

/**
 * An interface representing
 * Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema.
 */
export interface Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  modules: Module[];
}

/**
 * An interface representing PostContentSchemaModule.
 */
export interface PostContentSchemaModule {
  /**
   * The name of the module. Must be unique across all other modules.
   */
  moduleName?: string;
  /**
   * The human readable name of the module. Must be unique across all other modules.
   */
  displayName: string;
  description?: string;
}

/**
 * An interface representing
 * Pathsdk40osmoduleFeaturesModulespostrequestbodycontentapplicationJsonschema.
 */
export interface Pathsdk40osmoduleFeaturesModulespostrequestbodycontentapplicationJsonschema {
  module?: PostContentSchemaModule;
}

/**
 * An interface representing PatchContentSchemaModule.
 */
export interface PatchContentSchemaModule {
  /**
   * The human readable name of the module. Must be unique across all other modules.
   */
  displayName?: string;
  description?: string;
}

/**
 * An interface representing
 * Paths1t80n3vmoduleFeaturesModulesModulenamepatchrequestbodycontentapplicationJsonschema.
 */
export interface Paths1t80n3vmoduleFeaturesModulesModulenamepatchrequestbodycontentapplicationJsonschema {
  module: PatchContentSchemaModule;
}

/**
 * An interface representing
 * Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema.
 */
export interface Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema {
  isAvailable: boolean;
}

/**
 * An interface representing
 * Paths1l7qp5smoduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschemapropertiesmodule.
 */
export interface Paths1l7qp5smoduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschemapropertiesmodule {
  isAvailable?: boolean;
}

/**
 * An interface representing
 * Paths1bbj998moduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschema.
 */
export interface Paths1bbj998moduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschema {
  module: Paths1l7qp5smoduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschemapropertiesmodule;
}

/**
 * A feature.
 */
export interface Feature {
  featureID: string;
  featureName: string;
  displayName: string;
  description?: string;
  createTime: string;
  updateTime: string;
  createdBy: string;
  updatedBy: string;
}

/**
 * An interface representing
 * Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema.
 */
export interface Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  results: Feature[];
}

/**
 * An interface representing PostContentSchemaFeature.
 */
export interface PostContentSchemaFeature {
  featureName: string;
}

/**
 * An interface representing
 * Paths16s58acmoduleFeaturesModulesModulenameFeaturespostrequestbodycontentapplicationJsonschema.
 */
export interface Paths16s58acmoduleFeaturesModulesModulenameFeaturespostrequestbodycontentapplicationJsonschema {
  feature: PostContentSchemaFeature;
}

/**
 * An interface representing
 * Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema.
 */
export interface Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  features: Feature[];
}

/**
 * An interface representing
 * Paths1r5u2qmmoduleFeaturesFeaturespostrequestbodycontentapplicationJsonschemapropertiesfeature.
 */
export interface Paths1r5u2qmmoduleFeaturesFeaturespostrequestbodycontentapplicationJsonschemapropertiesfeature {
  /**
   * The name of the feature. Must be unique across all other features.
   */
  featureName: string;
  /**
   * The human readable name of the feature. Must be unique across all other features.
   */
  displayName: string;
  description?: string;
}

/**
 * An interface representing
 * Paths10iunt5moduleFeaturesFeaturespostrequestbodycontentapplicationJsonschema.
 */
export interface Paths10iunt5moduleFeaturesFeaturespostrequestbodycontentapplicationJsonschema {
  feature: Paths1r5u2qmmoduleFeaturesFeaturespostrequestbodycontentapplicationJsonschemapropertiesfeature;
}

/**
 * An interface representing PatchContentSchemaFeature.
 */
export interface PatchContentSchemaFeature {
  /**
   * The human readable name of the feature. Must be unique across all other features.
   */
  displayName?: string;
  description?: string;
}

/**
 * An interface representing
 * Paths1j5fzv6moduleFeaturesFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema.
 */
export interface Paths1j5fzv6moduleFeaturesFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema {
  feature: PatchContentSchemaFeature;
}

/**
 * An interface representing
 * PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema.
 */
export interface PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema {
  isAvailable: boolean;
}

/**
 * An interface representing
 * Paths1vk5ombmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschemapropertiesfeature.
 */
export interface Paths1vk5ombmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschemapropertiesfeature {
  isAvailable?: boolean;
}

/**
 * An interface representing
 * Paths23ngpnmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema.
 */
export interface Paths23ngpnmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema {
  feature: Paths1vk5ombmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschemapropertiesfeature;
}

/**
 * An interface representing
 * Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema.
 */
export interface Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  results: Module[];
}

/**
 * An interface representing ActionInputsItem.
 */
export interface ActionInputsItem {
  /**
   * The display name of the input parameter
   */
  displayName?: string;
  /**
   * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
   * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
   * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
   */
  type?: Type1;
  /**
   * Does the input parameter require a value. Default value: false.
   */
  isRequired?: boolean;
  /**
   * Description of the input parameter
   */
  description?: string;
  /**
   * Default value for the input parameter
   */
  defaultValue?: string;
}

/**
 * An interface representing ActionOutputsItem.
 */
export interface ActionOutputsItem {
  /**
   * The display name of the output field
   */
  displayName?: string;
  /**
   * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
   * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
   * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
   */
  type?: Type2;
  /**
   * Description of the output field
   */
  description?: string;
}

/**
 * A Pushbot Action is a step in a process that is done by an automated action instead of a user.
 * @summary Action schema.
 */
export interface Action {
  /**
   * Universally unique identifier
   */
  actionID?: string;
  /**
   * The display name of the action
   */
  displayName: string;
  /**
   * A description of the action
   */
  description?: string;
  /**
   * List of tags that apply to this action
   */
  tags?: string[];
  /**
   * List of tags that a worker must have to execute this action
   */
  requiredWorkerTags?: string[];
  /**
   * Action input parameters
   */
  inputs?: ActionInputsItem[];
  /**
   * Action input parameters
   */
  outputs?: ActionOutputsItem[];
  /**
   * If the action is available to users. Default value: true.
   */
  published?: boolean;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdByID?: string;
}

/**
 * A page of actions
 */
export interface Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  actions?: Action[];
}

/**
 * An ActionConfigurationFile is a file used to configure a specific Action.
 * @summary ActionConfigurationFile schema.
 */
export interface ActionConfigurationFile {
  /**
   * Universally unique identifier
   */
  actionConfigurationFileID?: string;
  /**
   * Universally unique identifier
   */
  actionID?: string;
  /**
   * Name of the file. Uniqueness is not enforced.
   */
  fileName?: string;
  /**
   * Content-Type of the field.
   */
  contentType?: string;
  /**
   * Size of the file in bytes.
   */
  size?: string;
  /**
   * A unique ETag for this file. May differ from MD5Hash.
   */
  eTag?: string;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
}

/**
 * An interface representing
 * Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema.
 */
export interface Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema {
  actionConfigurationFiles?: ActionConfigurationFile;
  nextPageKey?: string;
}

/**
 * A page of actions
 */
export interface Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  actions?: Action[];
}

/**
 * An interface representing
 * Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema.
 */
export interface Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema {
  actionConfigurationFiles?: ActionConfigurationFile;
  nextPageKey?: string;
}

/**
 * A Pushbot Worker executes actions
 * @summary Worker schema.
 */
export interface Worker {
  /**
   * Universally unique identifier
   */
  workerID?: string;
  /**
   * The display name of the worker
   */
  displayName: string;
  /**
   * The description of the worker
   */
  description?: string;
  /**
   * List of tags that apply to this worker
   */
  tags?: string[];
  /**
   * The hostname of the worker VM
   */
  hostname?: string;
  /**
   * The IP address of the worker VM
   */
  ipAddress?: string;
  /**
   * IDs of Actions this worker is capable of executing
   */
  implementedActionIDs?: string[];
  /**
   * Valid time stamp value.
   */
  lastSeenTime?: Date;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdByID?: string;
}

/**
 * A page of workers
 */
export interface Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  workers?: Worker[];
}

/**
 * An interface representing
 * Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema.
 */
export interface Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema {
  /**
   * Universally unique identifier
   */
  workerID?: string;
  /**
   * The display name of the worker
   */
  displayName: string;
  /**
   * The description of the worker
   */
  description?: string;
  /**
   * List of tags that apply to this worker
   */
  tags?: string[];
  /**
   * The hostname of the worker VM
   */
  hostname?: string;
  /**
   * The IP address of the worker VM
   */
  ipAddress?: string;
  /**
   * IDs of Actions this worker is capable of executing
   */
  implementedActionIDs?: string[];
  /**
   * Valid time stamp value.
   */
  lastSeenTime?: Date;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdByID?: string;
  /**
   * The secret that the worker should use as the basic auth password
   * (along with `workerID` as username) to authenticate to the
   * [#tag/Action-Worker-Execution](Action Worker Execution API)
   */
  secretKey?: string;
}

/**
 * An interface representing
 * Pathsoaf39rteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschemaallof1.
 */
export interface Pathsoaf39rteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschemaallof1 {
  /**
   * The secret that the worker should use as the basic auth password
   * (along with `workerID` as username) to authenticate to the
   * [#tag/Action-Worker-Execution](Action Worker Execution API)
   */
  secretKey?: string;
}

/**
 * Worker Update
 */
export interface Paths1mf3sbjteamsTeamnameWorkbenchWorkersWorkeridpatchrequestbodycontentapplicationJsonschema {
  action?: Worker;
  mask?: string;
}

/**
 * An interface representing PendingWorkerTaskInputsItem.
 */
export interface PendingWorkerTaskInputsItem {
  /**
   * The display name of the input parameter
   */
  displayName?: string;
  /**
   * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
   * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
   * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
   */
  type?: Type3;
  /**
   * Does the input parameter require a value. Default value: false.
   */
  isRequired?: boolean;
  /**
   * Description of the input parameter
   */
  description?: string;
  /**
   * Default value for the input parameter
   */
  defaultValue?: string;
  /**
   * Concrete value for the input parameter
   */
  value?: string;
}

/**
 * A Pending Worker Task is an Action task in a run that is either ready to execute
 * or currently being executed by a worker.

 * @summary Pending Worker Task schema.
 */
export interface PendingWorkerTask {
  /**
   * Universally unique identifier
   */
  taskID?: string;
  /**
   * List of tags that a worker must have to execute this action
   */
  requiredWorkerTags?: string[];
  /**
   * Universally unique identifier
   */
  actionID?: string;
  /**
   * Universally unique identifier
   */
  lockRequestID?: string;
  /**
   * Universally unique identifier
   */
  workerID?: string;
  /**
   * A description of how work on the task is progressing
   */
  progressStatus?: string;
  /**
   * The percentage of the work on this task that has been completed
   */
  progressPercent?: number;
  /**
   * Valid time stamp value.
   */
  taskReadyTime?: Date;
  /**
   * Valid time stamp value.
   */
  lockAcquisitionTime?: Date;
  /**
   * Valid time stamp value.
   */
  lockLastRenewedTime?: Date;
  /**
   * Valid time stamp value.
   */
  lockExpirationTime?: Date;
  /**
   * Task input parameters
   */
  inputs?: PendingWorkerTaskInputsItem[];
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
}

/**
 * A page of acquired tasks
 */
export interface Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  tasks?: PendingWorkerTask[];
}

/**
 * A Worker control limits which tasks are dispatched to workers
 * @summary Worker Control schema.
 */
export interface WorkerControl {
  /**
   * Universally unique identifier
   */
  controlID?: string;
  /**
   * Is this control enabled. Default value: true.
   */
  enabled?: boolean;
  /**
   * If true, all workers will stop executing tasks. Default value: false.
   */
  stopAllExecution?: boolean;
  /**
   * Universally unique identifier
   */
  taskID?: string;
  /**
   * Universally unique identifier
   */
  runID?: string;
  /**
   * Universally unique identifier
   */
  rootRunID?: string;
  /**
   * Universally unique identifier
   */
  workerID?: string;
  /**
   * Universally unique identifier
   */
  actionID?: string;
  /**
   * List of task tags for which execution should be stopped
   */
  tags?: string[];
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdByID?: string;
}

/**
 * A page of worker controls
 */
export interface Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  workers?: WorkerControl[];
}

/**
 * Worker Control Update
 */
export interface Paths151lzq5workbenchControlsControlidpatchrequestbodycontentapplicationJsonschema {
  action?: WorkerControl;
  mask?: string;
}

/**
 * An interface representing
 * Paths1llxakgworkbenchWorkersWorkeridAcquireTaskpostrequestbodycontentapplicationJsonschema.
 */
export interface Paths1llxakgworkbenchWorkersWorkeridAcquireTaskpostrequestbodycontentapplicationJsonschema {
  /**
   * The number of seconds to hold a lock on the acquired task. Default value: 30.
   */
  lockDurationSeconds?: number;
  /**
   * The number of milliseconds to wait for a task to be ready to acquire. Default value: 0.
   */
  waitTimeMillis?: number;
}

/**
 * Acquired Task
 */
export interface Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema {
  /**
   * Possible values include: 'timeout', 'success'
   */
  status?: Status;
  lockRequestID?: string;
  /**
   * The number of enqueued tasks remaining for this worker
   */
  waitingTaskCount?: number;
  task?: PendingWorkerTask;
}

/**
 * An interface representing
 * Paths1idfmsmworkbenchWorkersWorkeridBatchAcquireTaskspostrequestbodycontentapplicationJsonschema.
 */
export interface Paths1idfmsmworkbenchWorkersWorkeridBatchAcquireTaskspostrequestbodycontentapplicationJsonschema {
  /**
   * The number of seconds to hold a lock on the acquired task. Default value: 30.
   */
  lockDurationSeconds?: number;
  /**
   * The number of milliseconds to wait for a task to be ready to acquire. Default value: 0.
   */
  waitTimeMillis?: number;
  /**
   * The maximum number of tasks to acquire in this request
   */
  maxTaskCount: number;
}

/**
 * Acquired Tasks
 */
export interface Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema {
  /**
   * Possible values include: 'timeout', 'success'
   */
  status?: Status1;
  lockRequestID?: string;
  /**
   * The total number of tasks acquired. Note that this may be
   * greater than the number of tasks in the tasks array, in
   * which case all acquried tasks can be acquired by paging
   * through the
   * `/workbench/workers/{workerID}/tasks?lockRequestID={lockRequestID}`
   * results.
   */
  count?: number;
  /**
   * The number of enqueued tasks remaining for this worker in the
   * backlog
   */
  waitingTaskCount?: number;
  tasks?: PendingWorkerTask[];
}

/**
 * A page of acquired tasks
 */
export interface Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  tasks?: PendingWorkerTask[];
}

/**
 * Acquired Task
 */
export interface Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema {
  /**
   * Possible values include: 'timeout', 'success'
   */
  status?: Status2;
  lockRequestID?: string;
  task?: PendingWorkerTask;
}

/**
 * Acquired Task
 */
export interface Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema {
  /**
   * Possible values include: 'timeout', 'success'
   */
  status?: Status3;
  lockRequestID?: string;
  task?: PendingWorkerTask;
}

/**
 * An interface representing
 * Paths8f3s9eworkbenchWorkersWorkeridTasksTaskidCommentspostrequestbodycontentapplicationJsonschema.
 */
export interface Paths8f3s9eworkbenchWorkersWorkeridTasksTaskidCommentspostrequestbodycontentapplicationJsonschema {
  /**
   * The text of the comment to post
   */
  comment: string;
}

/**
 * The posted comment
 */
export interface Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema {
  /**
   * The text of the posted comment
   */
  comment?: string;
}

/**
 * An interface representing
 * Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema.
 */
export interface Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema {
  actionConfigurationFiles?: ActionConfigurationFile;
  nextPageToken?: string;
}

/**
 * An interface representing Get200ApplicationJsonPropertiesItemsItem.
 */
export interface Get200ApplicationJsonPropertiesItemsItem {
  userID?: string;
  teamName?: string;
}

/**
 * A page of userIDs
 */
export interface Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  capabilities?: Get200ApplicationJsonPropertiesItemsItem[];
}

/**
 * A Workbench capability
 * @summary Workbench Cabability schema.
 */
export interface WorkbenchCapability {
  /**
   * Universally unique identifier
   */
  capabilityID?: string;
  /**
   * The display name of the capability
   */
  displayName?: string;
  /**
   * A description of the capability
   */
  description?: string;
  /**
   * Whether this capability is granted to users who are not individually granted or denied the
   * capability. Defaults to deny by default. Default value: true.
   */
  grantedByDefault?: boolean;
  /**
   * Whether this capability is visible to everyone or just those with management rights. Default
   * value: true.
   */
  isPublic?: boolean;
  /**
   * User-defined JSON metadata for this capability
   */
  metadata?: string;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdBy?: string;
  /**
   * Valid time stamp value.
   */
  updatedAt?: Date;
  /**
   * Universally unique identifier
   */
  updatedBy?: string;
}

/**
 * A page of capabilities
 */
export interface Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  capabilities?: WorkbenchCapability[];
}

/**
 * An interface representing PatchContentSchemaCapability.
 */
export interface PatchContentSchemaCapability {
  decription?: string;
  isPublic?: boolean;
}

/**
 * Capability Update
 */
export interface Paths4rs19sworkbenchCapabilitiesCapabilityidpatchrequestbodycontentapplicationJsonschema {
  capability?: PatchContentSchemaCapability;
  mask?: string;
}

/**
 * A Workbench capability grant
 * @summary Workbench Cabability Grant schema.
 */
export interface WorkbenchCapabilityGrant {
  /**
   * Universally unique identifier
   */
  capabilityID?: string;
  /**
   * Universally unique identifier
   */
  userID?: string;
  /**
   * Whether this capability is granted or denied. Default value: true.
   */
  granted?: boolean;
  /**
   * Whether this is a grant to use the capability or to manage it for others. Possible values
   * include: 'use', 'manage'
   */
  grantType?: GrantType;
  /**
   * Valid time stamp value.
   */
  expirationTime?: Date;
  /**
   * User-defined JSON metadata for this grant
   */
  metadata?: string;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdBy?: string;
}

/**
 * A page of grants
 */
export interface Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrant[];
}

/**
 * A page of grants
 */
export interface Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrant[];
}

/**
 * A page of grants
 */
export interface Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrant[];
}

/**
 * A page of capabilities
 */
export interface Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  capabilities?: WorkbenchCapability[];
}

/**
 * A page of grants
 */
export interface Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrant[];
}

/**
 * A page of grants
 */
export interface Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrant[];
}

/**
 * A page of grants
 */
export interface Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrant[];
}

/**
 * A Workbench capability grant
 * @summary Workbench Cabability Grant schema.
 */
export interface WorkbenchCapabilityGrantLog {
  /**
   * Valid time stamp value.
   */
  logTimestamp?: Date;
  /**
   * Is this a log of the grant being created or deleted. Possible values include: 'created',
   * 'deleted'
   */
  logEvent?: LogEvent;
  /**
   * Universally unique identifier
   */
  logUserID?: string;
  /**
   * The displayName of the capability this grant is for
   */
  capabilityName?: string;
  /**
   * Universally unique identifier
   */
  capabilityID?: string;
  /**
   * Universally unique identifier
   */
  userID?: string;
  /**
   * Whether this capability is granted or denied. Default value: true.
   */
  granted?: boolean;
  /**
   * Whether this is a grant to use the capability or to manage it for others. Possible values
   * include: 'use', 'manage'
   */
  grantType?: GrantType1;
  /**
   * Valid time stamp value.
   */
  expirationTime?: Date;
  /**
   * User-defined JSON metadata for this grant
   */
  metadata?: string;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdBy?: string;
}

/**
 * A page of grant logs
 */
export interface PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrantLog[];
}

/**
 * A page of grant logs
 */
export interface Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrantLog[];
}

/**
 * A page of grant logs
 */
export interface PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  grants?: WorkbenchCapabilityGrantLog[];
}

/**
 * Used to invite users to a Pushbot Team.
 * @summary Invite
 */
export interface Invite {
  /**
   * Valid Catalytic team name.
   */
  teamName?: string;
  /**
   * The username of the user who created this invitation.
   */
  invitedBy?: string;
  /**
   * Token for invite.
   */
  inviteToken?: string;
}

/**
 * An interface representing
 * Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema.
 */
export interface Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema {
  /**
   * The duration a run must be completed before the run should be automatically deleted.
   */
  durationCompletedBeforeDelete?: number;
}

/**
 * An interface representing
 * Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema.
 */
export interface Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema {
  durationCompletedBeforeDelete?: string;
}

/**
 * An interface representing GetResponses200ContentApplicationJsonSchemaGlobalFieldsItemField.
 */
export interface GetResponses200ContentApplicationJsonSchemaGlobalFieldsItemField {
  displayName?: string;
  type?: string;
}

/**
 * An interface representing Get200ApplicationJsonPropertiesItemsContextsItem.
 */
export interface Get200ApplicationJsonPropertiesItemsContextsItem {
  type?: string;
  id?: string;
  displayName?: string;
}

/**
 * An interface representing
 * Paths115ws3lteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesglobalfieldsitems.
 */
export interface Paths115ws3lteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesglobalfieldsitems {
  field?: GetResponses200ContentApplicationJsonSchemaGlobalFieldsItemField;
  contexts?: Get200ApplicationJsonPropertiesItemsContextsItem[];
}

/**
 * An interface representing GetResponses200ContentApplicationJsonSchemaFieldsItemField.
 */
export interface GetResponses200ContentApplicationJsonSchemaFieldsItemField {
  displayName?: string;
  fieldID?: string;
  fieldName?: string;
  path?: string;
  processID?: string;
  teamName?: string;
  type?: string;
}

/**
 * An interface representing
 * Pathsrqi8kvteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitemspropertiescontextsitems.
 */
export interface Pathsrqi8kvteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitemspropertiescontextsitems {
  type?: string;
  id?: string;
  displayName?: string;
}

/**
 * An interface representing
 * Paths1y5a2ixteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitems.
 */
export interface Paths1y5a2ixteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitems {
  field?: GetResponses200ContentApplicationJsonSchemaFieldsItemField;
  contexts?: Pathsrqi8kvteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitemspropertiescontextsitems[];
}

/**
 * An interface representing
 * PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema.
 */
export interface PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema {
  globalFields?: Paths115ws3lteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesglobalfieldsitems[];
  fields?: Paths1y5a2ixteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitems[];
  totalFieldCount?: number;
}

/**
 * An interface representing
 * Paths16pex2yteamsTeamnameProcessesProcessnameRunsgetresponses404contentapplicationJsonschema.
 */
export interface Paths16pex2yteamsTeamnameProcessesProcessnameRunsgetresponses404contentapplicationJsonschema {
  /**
   * A message about why a 404 was returned
   */
  message: string;
}

/**
 * A specific step
 */
export interface Pathsttt0dlteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschemapropertiesstepsitems {
  /**
   * The id of the step
   */
  stepID?: string;
  /**
   * The display name of the step
   */
  displayName?: string;
  /**
   * The percentage this step is present as a task in a process
   */
  percentPresent?: number;
  /**
   * The average duration of this step
   */
  averageDuration?: string;
  /**
   * The median duration of this step
   */
  medianDuration?: string;
  /**
   * The shortest duration of all tasks for this step
   */
  minDuration?: string;
  /**
   * The longest duration of all tasks for this step
   */
  maxDuration?: string;
}

/**
 * An interface representing
 * Paths1u5z66gteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschema.
 */
export interface Paths1u5z66gteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschema {
  steps: Pathsttt0dlteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschemapropertiesstepsitems[];
  /**
   * The total number of steps across all pages
   */
  totalCount: string;
  /**
   * The token used to get the next page of results. Empty string means there is no more pages.
   */
  nextPageToken: string;
}

/**
 * An interface representing
 * Pathsa9d74uteamsTeamnameProcessesProcessidStepMetricsgetresponses401contentapplicationJsonschema.
 */
export interface Pathsa9d74uteamsTeamnameProcessesProcessidStepMetricsgetresponses401contentapplicationJsonschema {
  /**
   * An error message
   */
  message?: string;
}

/**
 * An interface representing
 * Paths1h8t2whteamsTeamnameProcessesProcessidStepMetricsgetresponses403contentapplicationJsonschema.
 */
export interface Paths1h8t2whteamsTeamnameProcessesProcessidStepMetricsgetresponses403contentapplicationJsonschema {
  /**
   * An error message
   */
  message?: string;
}

/**
 * An interface representing
 * Paths1gxjkemteamsTeamnameProcessesProcessnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema.
 */
export interface Paths1gxjkemteamsTeamnameProcessesProcessnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema {
  message?: string;
}

/**
 * The full name of the user to search for
 */
export interface GetContentSchemaFullName {
  /**
   * The partial fullName to search for
   */
  contains?: string;
}

/**
 * The email of the user to search for
 */
export interface GetContentSchemaEmail {
  /**
   * The partial email to search for
   */
  contains?: string;
}

/**
 * An interface representing GetContentSchemaCreatedDate.
 */
export interface GetContentSchemaCreatedDate {
  between?: any;
}

/**
 * Criteria to search for users for
 */
export interface Paths1dpewitteamsTeamnameUsersSearchgetrequestbodycontentapplicationJsonschema {
  /**
   * The id of the user to search for
   */
  userID?: string;
  /**
   * The full name of the user to search for
   */
  fullName?: GetContentSchemaFullName;
  /**
   * The email of the user to search for
   */
  email?: GetContentSchemaEmail;
  /**
   * An or condition
   */
  or?: any[];
  and?: any[];
  isTeamOwner?: boolean;
  isTeamAdmin?: boolean;
  isDeactivated?: boolean;
  isLockedOut?: boolean;
  buildingAllowed?: boolean;
  createdDate?: GetContentSchemaCreatedDate;
}

/**
 * Specifies how a user would like to receive email notifications from Pushbot
 */
export interface UserNotificationPreferenceEmail {
  /**
   * Indicates whether or not a user wishes to receive Pushbot notifications via email. Default
   * value: true.
   */
  enabled: boolean;
}

/**
 * Specifies how a user would like to receive sms notifications from Pushbot
 */
export interface UserNotificationPreferenceSms {
  /**
   * Indicates whether or not a user wishes to receive Pushbot notifications via sms. Default
   * value: true.
   */
  enabled: boolean;
}

/**
 * Specifies the user's notification preferences.
 */
export interface UserNotificationPreference {
  /**
   * Specifies how a user would like to receive email notifications from Pushbot
   */
  email: UserNotificationPreferenceEmail;
  /**
   * Specifies how a user would like to receive sms notifications from Pushbot
   */
  sms?: UserNotificationPreferenceSms;
  /**
   * Specifies how a user would like to receive updates from Pushbot. Possible values include:
   * 'email', 'sms', ''. Default value: 'email'.
   */
  updates?: Updates;
  /**
   * Specifies how a user would like to receive task assignments from Pushbot. Possible values
   * include: 'email', 'sms', ''. Default value: 'email'.
   */
  assignment?: Assignment;
  /**
   * Specifies how a user would like to receive reminders from Pushbot. Possible values include:
   * 'email', 'sms', ''. Default value: 'email'.
   */
  reminders?: Reminders;
  /**
   * Specifies how a user would like to receive daily summaries from Pushbot. Possible values
   * include: 'email', ''
   */
  dailySummary?: DailySummary;
  /**
   * Specifies how a user would like to receive weekly team updates from Pushbot. Possible values
   * include: 'email', ''
   */
  weeklyTeamOwner?: WeeklyTeamOwner;
  /**
   * Specifies how a user would like to receive run completion notifications from Pushbot. Possible
   * values include: 'email', ''. Default value: 'email'.
   */
  runCompletion?: RunCompletion;
}

/**
 * Information about a user's login
 * @summary User Login schema.
 */
export interface UserLastLogin {
  /**
   * The IP address the user authenticated from for this login
   */
  ipAddress?: string;
  /**
   * Indicates whether the the login IP matches that of the current request. Default value: false.
   */
  currentIP?: boolean;
  /**
   * Valid time stamp value.
   */
  loginDate?: Date;
}

/**
 * Pushbot users represent authenticated actors within a Pushbot Team.
 * @summary User schema.
 */
export interface User {
  username: string;
  /**
   * Specifies how a user would like to receive email notifications from Pushbot.
   */
  email: string;
  /**
   * Primary phone number of the user.
   */
  phone?: string;
  /**
   * The slack username of the user.
   */
  slackUsername?: string;
  /**
   * A unique identifier for the user. This ID is unique across Pushbot teams.
   */
  userID: string;
  /**
   * The user's temporary, single-use authentication token for API authorization requests.
   */
  token?: string;
  /**
   * App state stored for a specific integration user.
   */
  appState?: string;
  /**
   * appID that created this integration user.
   */
  appID?: string;
  /**
   * Name of the app which created this integration user.
   */
  appName?: string;
  /**
   * Major version number of the app which created this integration user.
   */
  appVersion?: number;
  /**
   * Username of the user who created this integration user.
   */
  createdBy?: string;
  /**
   * Date that this integration user was created.
   */
  createdDate?: Date;
  /**
   * The full name of the user.
   */
  fullName?: string;
  /**
   * Determines the feature stability level user experiences. Possible values include: 'alpha',
   * 'beta', 'stable', ''
   */
  experience?: Experience;
  /**
   * FileID reference for the user's profile image.
   */
  userThumbnail?: string;
  /**
   * Default value: false.
   */
  isDeactivated?: boolean;
  /**
   * Indicates whether this user is an owner of their team or not. Default value: false.
   */
  isTeamOwner?: boolean;
  /**
   * Indicates whether this user is an administrator of their team or not. Default value: false.
   */
  isTeamAdmin?: boolean;
  /**
   * Indicates whether the user has sudo'ed in. Default value: false.
   */
  isSudoer?: boolean;
  /**
   * Indicates whether this user is a restricted user within in team, able to access only tasks to
   * which they are assigned. Default value: false.
   */
  isRestricted?: boolean;
  /**
   * Indicates whether this user is an integration user, used to authorize access between Pushbot
   * and a third-party system. Default value: false.
   */
  isIntegration?: boolean;
  /**
   * Indicates whether this integration user is shared with other users on the team. Default value:
   * false.
   */
  isShared?: boolean;
  /**
   * Indicates whether this user is a group type user, used to handle group task assignment.
   * Default value: false.
   */
  isGroup?: boolean;
  /**
   * Indicates whether this user was dynamically generated at runtime. Only applicable to groups.
   * Default value: false.
   */
  isRuntime?: boolean;
  /**
   * Indicates whether this group-type user is archived or not. Default value: false.
   */
  isArchived?: boolean;
  /**
   * Indicates whether this user is locked out. Default value: false.
   */
  isLockedOut?: boolean;
  /**
   * Indicates the list of usernames of users in a group.
   */
  members?: string[];
  /**
   * Text field indicating integration user visibility permissions
   */
  userVisibility?: string;
  /**
   * Array of usernames corresponding to users who can view the integration user (if userVisibility
   * is restricted)
   */
  integrationVisibleToUsers?: string[];
  /**
   * Specifies the user's notification preferences.
   */
  notificationPreference?: UserNotificationPreference;
  /**
   * User's last location.
   */
  lastLocation?: string;
  /**
   * Information about a user's login
   */
  lastLogin?: UserLastLogin;
  /**
   * User's nickname.
   */
  nickname?: string;
  /**
   * User's roles.
   */
  roles?: string;
  /**
   * Virtual field serving as the most user-friendly identifier for a user. Will be equal to the
   * first of these properties that exist on the user entity: fullName, nickname, email, username.
   */
  displayName?: string;
  /**
   * If this user can build. This setting overrides the value of buildingAllowedByDefault on this
   * user's team
   */
  buildingAllowed?: boolean;
  /**
   * If this user can build
   */
  canBuild?: boolean;
}

/**
 * An interface representing
 * Paths10k14zuteamsTeamnameUsersSearchgetresponses403contentapplicationJsonschema.
 */
export interface Paths10k14zuteamsTeamnameUsersSearchgetresponses403contentapplicationJsonschema {
  message?: string;
}

/**
 * The full name of the user to search for
 */
export interface GetContentSchemaDisplayName {
  /**
   * The partial fullName to search for
   */
  contains?: string;
}

/**
 * The email of the user to search for
 */
export interface GetContentSchemaOwner {
  /**
   * The partial email to search for
   */
  contains?: string;
}

/**
 * An interface representing GetContentSchemaDescription.
 */
export interface GetContentSchemaDescription {
  contains?: string;
}

/**
 * An interface representing
 * Paths1p531mrteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschemapropertiescreateddate.
 */
export interface Paths1p531mrteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschemapropertiescreateddate {
  between?: any;
}

/**
 * An interface representing GetContentSchemaCategory.
 */
export interface GetContentSchemaCategory {
  contains?: string;
}

/**
 * Criteria to search for processes for
 */
export interface Pathsqfzj7vteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschema {
  /**
   * The id of the user to search for
   */
  processID?: string;
  /**
   * The full name of the user to search for
   */
  displayName?: GetContentSchemaDisplayName;
  /**
   * The email of the user to search for
   */
  owner?: GetContentSchemaOwner;
  /**
   * An or condition
   */
  or?: any[];
  and?: any[];
  description?: GetContentSchemaDescription;
  createdDate?: Paths1p531mrteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschemapropertiescreateddate;
  category?: GetContentSchemaCategory;
}

/**
 * A Catalytic Process is a template for a run
 * @summary Process
 */
export interface ProcessPartial {
  /**
   * The unique name of the process. Cannot be changed.
   */
  processName?: string;
  /**
   * The unique ID of the process, set automatically when the process is created.
   */
  processID?: string;
  /**
   * The display name of the process.
   */
  displayName?: string;
  /**
   * The description name of the process.
   */
  description?: string;
  /**
   * A descriptive category for the process within the Team.
   */
  category?: string;
  /**
   * Username of the user who created this process.
   */
  createdBy?: string;
  /**
   * Date that this process was created.
   */
  createdDate?: Date;
  /**
   * Username of the owner of this process. This user will be the default owner of any runs of this
   * process if another owner is not specified when the run starts.
   */
  owner?: string;
  /**
   * The number of steps in this process.
   */
  stepCount?: number;
  /**
   * The amount of time between when the run starts and its deadline. Ex: '5 hrs' or '3 days'.
   */
  maximumDuration?: string;
  /**
   * Catalytic priority level. Possible values include: 'low', 'medium', 'high'
   */
  priority?: Priority2;
  /**
   * FileID reference for an image to use as the process thumbnail.
   */
  thumbnail?: string;
  /**
   * Indicates whether this process is ready to be used, or is still in draft mode. Default value:
   * false.
   */
  published?: boolean;
  /**
   * An array of the fields that are set as part of this step.
   */
  fields?: FieldPartial[];
  formFields?: string[];
  /**
   * The fieldNames of fields writable at the process level
   */
  writableFields?: string[];
  /**
   * Indicates whether this process should be listed on the team's Start Menu. Default value:
   * false.
   */
  showOnStartMenu?: boolean;
  /**
   * Default visibility for fields within this process, defining which users can see field content.
   * Possible values include: 'public', 'internal', 'confidential', 'highlyConfidential'. Default
   * value: 'public'.
   */
  fieldVisibility?: FieldVisibility;
  /**
   * The usernames of users with access to the content of this process's fields by default.
   */
  fieldVisibleToUsers?: string[];
  /**
   * Most recent date process was run.
   */
  lastRunDate?: string;
  /**
   * Roles within each process.
   */
  roles?: string;
  /**
   * Admin users for process.
   */
  adminUsers?: string[];
  /**
   * Checks whether process is manually startable.
   */
  manuallyStartable?: boolean;
  /**
   * List of standard users.
   */
  standardUsers?: string[];
  /**
   * Whether process is shared publicly or not.
   */
  isPublic?: boolean;
  /**
   * Whether to skip reporting on the process or not.
   */
  skipReporting?: string;
  /**
   * Whether to skip creating/populating a table for the process or not.
   */
  skipTable?: boolean;
  /**
   * Whether process is archived or not.
   */
  isArchived?: boolean;
  /**
   * Default value: false.
   */
  isAdHoc?: boolean;
  testMode?: string;
  /**
   * The visibility permissions of runs of this process.
   */
  runVisibility?: string;
  /**
   * The unique ID of the canonical processID the process is associated with.
   */
  rootProcessID?: string;
  /**
   * Whether the process is the active version of a canonical process
   */
  isActive?: boolean;
  /**
   * Username of the user who last modified this process.
   */
  modifiedBy?: string;
  /**
   * Date that this process was last published.
   */
  publishedDate?: Date;
  /**
   * Date that this process was last modified.
   */
  updatedAt?: Date;
  /**
   * The ID this process was based off of.
   */
  baseProcessID?: string;
  /**
   * ProcessID of the process that this process is wholly owned by
   */
  whollyOwnedBy?: string;
  /**
   * ProcessID of the root owner of a wholly owned process
   */
  rootWhollyOwnedBy?: string;
  /**
   * The duration after instances are completed or aborted before deleting all instance data
   */
  durationCompletedBeforeDelete?: string;
  /**
   * The default dependency behavior used for steps within this process. Possible values include:
   * 'complex', 'startsAfterPreviousStep'. Default value: 'startsAfterPreviousStep'.
   */
  defaultDependencyType?: DefaultDependencyType;
}

/**
 * An interface representing Process.
 */
export interface Process extends ProcessPartial {
}

/**
 * An interface representing
 * Pathsmxqu7steamsTeamnameProcessesSearchgetresponses403contentapplicationJsonschema.
 */
export interface Pathsmxqu7steamsTeamnameProcessesSearchgetresponses403contentapplicationJsonschema {
  message?: string;
}

/**
 * A User Token is used to access the Catalytic API as an authenticated user via SDK
 * @summary User Token schema.
 */
export interface UserToken {
  /**
   * The public identifier of the User Token
   */
  userTokenID?: string;
  /**
   * The user-provided vanity name of the User Token
   */
  displayName?: string;
  /**
   * Universally unique identifier
   */
  userID?: string;
  /**
   * The current status of this User Token. Possible values include: 'requested', 'approved',
   * 'revoked'
   */
  status: Status4;
  /**
   * The IP address of the creator of the User Token
   */
  creatorIPAddress?: string;
  /**
   * The IP address of the creator of the approval of the User Token
   */
  approverIPAddress?: string;
  /**
   * The IP address associated with the most recent verification of the User Token
   */
  lastSeenIPAddress?: string;
  /**
   * Valid time stamp value.
   */
  lastSeenTime?: Date;
  /**
   * Valid time stamp value.
   */
  approvedTime?: Date;
  /**
   * Valid time stamp value.
   */
  revokedTime?: Date;
  /**
   * Confidential token string returned only on creation
   */
  token?: string;
  /**
   * The first six characters of the confidential token for reference purposes
   */
  firstSixTokenChars?: string;
}

/**
 * A page of User Tokens
 */
export interface Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema {
  /**
   * the token to use to retrieve the next page of results
   */
  nextPageToken?: string;
  userTokens?: UserToken[];
}

/**
 * A JSON object with creatable User Token properties
 */
export interface Paths1ixwjwdteamsTeamnameUserTokenspostrequestbodycontentapplicationJsonschema {
  /**
   * displayName to apply to User Token
   */
  displayName?: string;
  /**
   * Status to apply to User Token. Options: "requested", "approved"
   */
  status?: string;
}

/**
 * An interface representing
 * Pathsr1wgloteamsTeamnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema.
 */
export interface Pathsr1wgloteamsTeamnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema {
  message?: string;
}

/**
 * An interface representing
 * Pathsbjy3erteamsTeamnameUsersUsernameNotificationsReadpostrequestbodycontentapplicationJsonschema.
 */
export interface Pathsbjy3erteamsTeamnameUsersUsernameNotificationsReadpostrequestbodycontentapplicationJsonschema {
  type: string;
}

/**
 * An interface representing
 * Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema.
 */
export interface Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema {
  updatedCount?: number;
}

/**
 * An interface representing
 * Paths1muma8lteamsTeamnameProcessesProcessidMetricsgetresponses401contentapplicationJsonschema.
 */
export interface Paths1muma8lteamsTeamnameProcessesProcessidMetricsgetresponses401contentapplicationJsonschema {
  /**
   * An error message
   */
  message?: string;
}

/**
 * An interface representing
 * Pathsk1n3clteamsTeamnameProcessesProcessidMetricsgetresponses403contentapplicationJsonschema.
 */
export interface Pathsk1n3clteamsTeamnameProcessesProcessidMetricsgetresponses403contentapplicationJsonschema {
  /**
   * An error message
   */
  message?: string;
}

/**
 * An interface representing
 * Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema.
 */
export interface Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  modules: Module[];
}

/**
 * An interface representing
 * Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema.
 */
export interface Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  features: Feature[];
}

/**
 * An interface representing
 * Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema.
 */
export interface Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  /**
   * The features in this page of results.
   */
  features: Feature[];
}

/**
 * An interface representing
 * Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema.
 */
export interface Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  modules?: Module[];
}

/**
 * An interface representing
 * Paths100lpxxteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems.
 */
export interface Paths100lpxxteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems {
  appID: string;
  appName?: string;
  action?: string;
  version?: string;
  displayName?: string;
  description?: string;
}

/**
 * An interface representing
 * Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema.
 */
export interface Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  actions?: Paths100lpxxteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems[];
}

/**
 * An interface representing
 * Pathswn3ek6teamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems.
 */
export interface Pathswn3ek6teamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems {
  appID: string;
  appName?: string;
  action?: string;
  version?: string;
  displayName?: string;
  description?: string;
}

/**
 * An interface representing
 * Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema.
 */
export interface Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema {
  nextPageToken?: string;
  actions?: Pathswn3ek6teamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems[];
}

/**
 * Action Update
 */
export interface ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema {
  action?: Action;
  mask?: string;
}

/**
 * An interface representing
 * Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema.
 */
export interface Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema {
  lifetimeSeconds?: number;
  /**
   * optional JSON string of user-supplied metadata
   */
  metadata?: string;
}

/**
 * Models a request to access a team, process, or table
 * @summary Access Request schema
 */
export interface AccessRequest {
  /**
   * Email address of the user making this access request
   */
  requesterEmailAddress?: string;
  /**
   * Type of object being requested. Possible values include: 'team', 'process', 'table'
   */
  requestedObjectType?: RequestedObjectType;
  /**
   * ID of object being requested
   */
  requestedObjectID?: string;
  /**
   * If this request was approved, the ID of user that approved this request
   */
  approvedByUserID?: string;
  /**
   * Valid time stamp value.
   */
  approvedAt?: Date;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
}

/**
 * A Catalytic User Defined (Custom) Integration Definition
 * @summary CustomIntegrationDefinition
 */
export interface CustomIntegrationDefinition {
  /**
   * The display name of the custom integration.
   */
  displayName: string;
  /**
   * The Authentication method of the custom integration
   */
  type: string;
  /**
   * Object containing configuration details for a custom integration
   */
  config: any;
}

/**
 * Used to invite users to a Pushbot Team.
 * @summary New invite request
 */
export interface InvitesNew {
  /**
   * Email address of the invitee.
   */
  email: string;
  /**
   * Phone number of the invitee.
   */
  phone?: string;
  /**
   * ID for the invitation thread.
   */
  invitationThreadID?: string;
  /**
   * Whether the invite is restricted or not.
   */
  isRestricted?: boolean;
  /**
   * Whether the person the invite is sent to is team admin.
   */
  isAdmin?: boolean;
  /**
   * `Admin` or `Restricted user`
   */
  permissions?: string;
  /**
   * Custom message to include in invitation
   */
  message?: string;
  /**
   * Roles for the person invite sent to.
   */
  roles?: string;
  /**
   * ID for webhook.
   */
  webhookID?: string;
}

/**
 * The rights the calling user has for this process
 * @summary User rights for a process
 */
export interface ProcessRights {
  /**
   * Whether the user can read this process
   */
  canView: boolean;
  /**
   * Whether the user can edit this process
   */
  canEdit: boolean;
}

/**
 * Indicate if an item should be a favorite or not.
 * @summary Update favorite request
 */
export interface FavoritesNew {
  /**
   * Whether the item is a favorite or not.
   */
  favorite: boolean;
}

/**
 * A user's favorite.
 * @summary Favorite schema.
 */
export interface Favorite {
  /**
   * Unique identifier for the item the user favorited.
   */
  id?: string;
  /**
   * The date the user favorited this item.
   */
  favoritedOn?: Date;
  /**
   * The type of item favorited.
   */
  type?: string;
}

/**
 * Pagination info
 */
export interface FavoritesListPageInfo {
  /**
   * The key to pass to 'after' when paging through a list
   */
  last?: string;
}

/**
 * A list of a user's favorites.
 * @summary List of favorites schema.
 */
export interface FavoritesList {
  /**
   * List of user favorites
   */
  results: Favorite[];
  /**
   * Total count of user favorites
   */
  totalCount: number;
  /**
   * Pagination info
   */
  pageInfo: FavoritesListPageInfo;
}

/**
 * Fields required in auth response body.
 */
export interface SAMLAuthResponseBody {
  /**
   * Response request ID.
   */
  requestId?: string;
  /**
   * Response user key.
   */
  userKey?: string;
  /**
   * Response user email.
   */
  userEmail?: string;
  /**
   * Response initial location.
   */
  initialLocation?: string;
}

/**
 * Saml auth headers.
 */
export interface SAMLAuthHeaders {
  /**
   * Headers set cookie.
   */
  setCookie?: string;
  /**
   * Headers location.
   */
  location?: string;
}

/**
 * Information regarding saml authentication.
 * @summary saml-auth schema.
 */
export interface SAMLAuth {
  /**
   * Fields required in auth response body.
   */
  responseBody?: SAMLAuthResponseBody;
  /**
   * Saml auth body.
   */
  body?: any;
  /**
   * Saml auth headers.
   */
  headers?: SAMLAuthHeaders;
}

/**
 * Service provider metadata body.
 */
export interface SAMLServiceProviderMetadataBody {
  /**
   * Body entity ID.
   */
  entityID?: string;
  /**
   * Acs URL.
   */
  acsURL?: string;
  /**
   * Metadata XML.
   */
  metadataXML?: string;
}

/**
 * Information regarding saml service provider.
 * @summary saml-serviceProviderMetadata schema.
 */
export interface SAMLServiceProviderMetadata {
  /**
   * Service provider metadata body.
   */
  body?: SAMLServiceProviderMetadataBody;
}

/**
 * Search result.
 */
export interface SearchV1ResponseResult {
  /**
   * Token for the next page of results.
   */
  pageToken?: string;
  /**
   * Token results.
   */
  results?: string;
}

/**
 * Information regarding online searches and the given results.
 * @summary Search schema.
 */
export interface SearchV1Response {
  /**
   * Search result.
   */
  result?: SearchV1ResponseResult;
  /**
   * The index of the page of results to return, starting from 0.
   */
  pageToken?: string;
  /**
   * Limit on the number of results returned.
   */
  limit?: string;
  /**
   * Search types.
   */
  types?: string;
  /**
   * Search query string.
   */
  q?: string;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterProcessesCreatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching processes based on these criteria
 */
export interface SearchV2RequestFilterProcesses {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  processID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  category?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  owner?: any;
  /**
   * A list of usernames, matches processes where the user or a group to which they belong
   * is the process owner or a permitted editor.
   */
  participatingUsers?: string[];
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: SearchV2RequestFilterProcessesCreatedDate;
  /**
   * Matches processes that the querying user or a group to which they belong
   * are allowed to edit
   */
  isEditable?: boolean;
  /**
   * Optionally filter for only archived processes. Defaults to `[ false, null ]`
   */
  isArchived?: any;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterRunsStartDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterRunsCreatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterRunsUpdatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterRunsDeadline {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching runs based on these criteria
 */
export interface SearchV2RequestFilterRuns {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  processID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  runID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  status?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  startDate?: SearchV2RequestFilterRunsStartDate;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  owner?: any;
  /**
   * A list of usernames. A run will match if one of the specified users, or any of the groups to
   * which
   * they belong are participating in this run. A user is participating in a run if they are the
   * run owner
   * or creator, or if they are assigned any tasks in the run. For waiting tasks that have not yet
   * been
   * assigned, this includes any users in the associated role who could be assigned.
   */
  participatingUsers?: string[];
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: SearchV2RequestFilterRunsCreatedDate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  updatedDate?: SearchV2RequestFilterRunsUpdatedDate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  deadline?: SearchV2RequestFilterRunsDeadline;
  /**
   * The unique ID of the canonical processID the process is associated with
   */
  rootProcessID?: string;
  /**
   * Whether to include instances of wholly owned processes. Defaults to [false, null]
   */
  isWhollyOwned?: boolean;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterTasksStartDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the numeric value is in the specified range

 */
export interface SearchV2RequestFilterTasksTaskNumber {
  /**
   * Exclusive upper bound on range
   */
  gt?: number;
  /**
   * Exclusive lower bound on range
   */
  lt?: number;
  /**
   * Inclusive upper bound on range
   */
  gte?: number;
  /**
   * Inclusive lower bound on range
   */
  lte?: number;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterTasksCreatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterTasksUpdatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterTasksDeadline {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching tasks based on these criteria
 */
export interface SearchV2RequestFilterTasks {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  processID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  runID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  status?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  startDate?: SearchV2RequestFilterTasksStartDate;
  /**
   * A username or list of usernames. Return only tasks where one of the specified users or any
   * of the groups to which they belong are assigned.
   */
  assignedTo?: any;
  /**
   * Filter for only records where the numeric value is in the specified range
   */
  taskNumber?: SearchV2RequestFilterTasksTaskNumber;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: SearchV2RequestFilterTasksCreatedDate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  updatedDate?: SearchV2RequestFilterTasksUpdatedDate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  deadline?: SearchV2RequestFilterTasksDeadline;
  /**
   * A list of usernames, matches tasks where one of the specified users or a group to which
   * they belong is assigned.
   */
  participatingUsers?: string[];
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterTablesCreatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching data tables based on these criteria
 */
export interface SearchV2RequestFilterTables {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  tableID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  tableType?: any;
  /**
   * Optionally filter for only archived tables. Defaults to `[ false, null ]`
   */
  isArchived?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: SearchV2RequestFilterTablesCreatedDate;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterUsersCreatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching data users based on these criteria
 */
export interface SearchV2RequestFilterUsers {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  userID?: any;
  isTeamAdmin?: boolean;
  /**
   * Optionally filter for integration users. Defaults to `[ false, null ]`
   */
  isIntegration?: any;
  /**
   * Optionally filter for only archived groups. Defaults to `[ false, null ]`
   */
  isArchived?: any;
  /**
   * Optionally filter for runtime groups. Defaults to `[ false, null ]`
   */
  isRuntime?: any;
  isGroup?: boolean;
  /**
   * Defaults to false, specify `true` to query amongst deactivated users
   */
  isDeactivated?: boolean;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: SearchV2RequestFilterUsersCreatedDate;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterHelpCreatedDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterHelpStartDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterHelpEndDate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching help articles based on these criteria
 */
export interface SearchV2RequestFilterHelp {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  title?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  helpLabels?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  article?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: SearchV2RequestFilterHelpCreatedDate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  startDate?: SearchV2RequestFilterHelpStartDate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  endDate?: SearchV2RequestFilterHelpEndDate;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterActionsVersion {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface SearchV2RequestFilterActionsBaseAppID {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching actions based on these criteria
 */
export interface SearchV2RequestFilterActions {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  appID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  appName?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  action?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  version?: SearchV2RequestFilterActionsVersion;
  /**
   * Filter for only records where the date is in the specified date range
   */
  baseAppID?: SearchV2RequestFilterActionsBaseAppID;
}

/**
 * Filter matching webhooks based on these criteria
 */
export interface SearchV2RequestFilterWebhooks {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  webhookID?: any;
}

/**
 * Filter matching upcomings based on these criteria
 */
export interface SearchV2RequestFilterUpcomings {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  upcomingID?: any;
}

/**
 * Specifies criteria that should be used to strictly filter results. Filter conditions do not
 * impact ranking.
 * Filter criteria are specified per type. Filter criteria for multiple properties within a single
 * type are combined as
 * AND condition. Filters between different types are combined as an OR condition.

 */
export interface SearchV2RequestFilter {
  /**
   * Filter matching processes based on these criteria
   */
  processes?: SearchV2RequestFilterProcesses;
  /**
   * Filter matching runs based on these criteria
   */
  runs?: SearchV2RequestFilterRuns;
  /**
   * Filter matching tasks based on these criteria
   */
  tasks?: SearchV2RequestFilterTasks;
  /**
   * Filter matching data tables based on these criteria
   */
  tables?: SearchV2RequestFilterTables;
  /**
   * Filter matching data users based on these criteria
   */
  users?: SearchV2RequestFilterUsers;
  /**
   * Filter matching help articles based on these criteria
   */
  help?: SearchV2RequestFilterHelp;
  /**
   * Filter matching actions based on these criteria
   */
  actions?: SearchV2RequestFilterActions;
  /**
   * Filter matching webhooks based on these criteria
   */
  webhooks?: SearchV2RequestFilterWebhooks;
  /**
   * Filter matching upcomings based on these criteria
   */
  upcomings?: SearchV2RequestFilterUpcomings;
}

/**
 * Properties by type to search for the specified text within. If blank, search will
 * apply to all supported properties of all supported types.

 */
export interface SearchV2RequestQueryProperties {
  /**
   * Names of process properties to search within
   */
  processes?: string[];
  /**
   * Names of run properties to search within
   */
  runs?: string[];
  /**
   * Names of task properties to search within. If searching over runs, runs whose child tasks
   * match on
   * these properties will also be included in results.
   */
  tasks?: string[];
  /**
   * Names of data table properties to search within
   */
  tables?: string[];
  /**
   * Names of user properties to search within
   */
  users?: string[];
  /**
   * Names of notification properties to search within
   */
  notifications?: string[];
  /**
   * Names of help properties to search within
   */
  help?: string[];
  /**
   * Names of action properties to search within
   */
  actions?: string[];
  /**
   * Names of webhook properties to search within
   */
  webhooks?: string[];
  /**
   * Names of upcoming properties to search within
   */
  upcomings?: string[];
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components17swrlnschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesprocessespropertiescreateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching processes based on these criteria
 */
export interface SearchV2RequestQueryBoostPropertiesProperties {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  processID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  category?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  owner?: any;
  /**
   * A list of usernames, matches processes where the user or a group to which they belong
   * is the process owner or a permitted editor.
   */
  participatingUsers?: string[];
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: Components17swrlnschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesprocessespropertiescreateddate;
  /**
   * Matches processes that the querying user or a group to which they belong
   * are allowed to edit
   */
  isEditable?: boolean;
  /**
   * Optionally filter for only archived processes. Defaults to `[ false, null ]`
   */
  isArchived?: any;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Componentslxzbm1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesstartdate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1kbs0jschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiescreateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Componentsg1pzuzschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesupdateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Componentscj5oc0schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesdeadline {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching runs based on these criteria
 */
export interface Componentsm3fkqkschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesruns {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  processID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  runID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  status?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  startDate?: Componentslxzbm1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesstartdate;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  owner?: any;
  /**
   * A list of usernames. A run will match if one of the specified users, or any of the groups to
   * which
   * they belong are participating in this run. A user is participating in a run if they are the
   * run owner
   * or creator, or if they are assigned any tasks in the run. For waiting tasks that have not yet
   * been
   * assigned, this includes any users in the associated role who could be assigned.
   */
  participatingUsers?: string[];
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: Components1kbs0jschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiescreateddate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  updatedDate?: Componentsg1pzuzschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesupdateddate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  deadline?: Componentscj5oc0schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesdeadline;
  /**
   * The unique ID of the canonical processID the process is associated with
   */
  rootProcessID?: string;
  /**
   * Whether to include instances of wholly owned processes. Defaults to [false, null]
   */
  isWhollyOwned?: boolean;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Componentsppoo6aschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesstartdate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the numeric value is in the specified range

 */
export interface Components15zr4uuschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiestasknumber {
  /**
   * Exclusive upper bound on range
   */
  gt?: number;
  /**
   * Exclusive lower bound on range
   */
  lt?: number;
  /**
   * Inclusive upper bound on range
   */
  gte?: number;
  /**
   * Inclusive lower bound on range
   */
  lte?: number;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components17gefuwschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiescreateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1rayy0mschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesupdateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1eok1u4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesdeadline {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching tasks based on these criteria
 */
export interface Componentsleqgx7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestasks {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  processID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  runID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  status?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  startDate?: Componentsppoo6aschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesstartdate;
  /**
   * A username or list of usernames. Return only tasks where one of the specified users or any
   * of the groups to which they belong are assigned.
   */
  assignedTo?: any;
  /**
   * Filter for only records where the numeric value is in the specified range
   */
  taskNumber?: Components15zr4uuschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiestasknumber;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: Components17gefuwschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiescreateddate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  updatedDate?: Components1rayy0mschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesupdateddate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  deadline?: Components1eok1u4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesdeadline;
  /**
   * A list of usernames, matches tasks where one of the specified users or a group to which
   * they belong is assigned.
   */
  participatingUsers?: string[];
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1p7ocp3schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestablespropertiescreateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching data tables based on these criteria
 */
export interface Components1cyn58ischemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestables {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  tableID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  tableType?: any;
  /**
   * Optionally filter for only archived tables. Defaults to `[ false, null ]`
   */
  isArchived?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: Components1p7ocp3schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestablespropertiescreateddate;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1b2vq0nschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesuserspropertiescreateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching data users based on these criteria
 */
export interface Componentsn3whvsschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesusers {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  userID?: any;
  isTeamAdmin?: boolean;
  /**
   * Optionally filter for integration users. Defaults to `[ false, null ]`
   */
  isIntegration?: any;
  /**
   * Optionally filter for only archived groups. Defaults to `[ false, null ]`
   */
  isArchived?: any;
  /**
   * Optionally filter for runtime groups. Defaults to `[ false, null ]`
   */
  isRuntime?: any;
  isGroup?: boolean;
  /**
   * Defaults to false, specify `true` to query amongst deactivated users
   */
  isDeactivated?: boolean;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: Components1b2vq0nschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesuserspropertiescreateddate;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Componentsp46hy5schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiescreateddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1j6sqk2schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesstartdate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components1mog8x1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesenddate {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching help articles based on these criteria
 */
export interface Componentsypjx3rschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelp {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  title?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  helpLabels?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  article?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  createdDate?: Componentsp46hy5schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiescreateddate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  startDate?: Components1j6sqk2schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesstartdate;
  /**
   * Filter for only records where the date is in the specified date range
   */
  endDate?: Components1mog8x1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesenddate;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Components178djz4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesversion {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter for only records where the date is in the specified date range

 */
export interface Componentsivzon7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesbaseappid {
  /**
   * Exclusive upper bound on date range
   */
  gt?: Date;
  /**
   * Exclusive lower bound on date range
   */
  lt?: Date;
  /**
   * Inclusive upper bound on date range
   */
  gte?: Date;
  /**
   * Inclusive lower bound on date range
   */
  lte?: Date;
}

/**
 * Filter matching actions based on these criteria
 */
export interface Componentsumb4thschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactions {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  appID?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  appName?: any;
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  action?: any;
  /**
   * Filter for only records where the date is in the specified date range
   */
  version?: Components178djz4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesversion;
  /**
   * Filter for only records where the date is in the specified date range
   */
  baseAppID?: Componentsivzon7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesbaseappid;
}

/**
 * Filter matching webhooks based on these criteria
 */
export interface Components17065sbschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieswebhooks {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  webhookID?: any;
}

/**
 * Filter matching upcomings based on these criteria
 */
export interface Componentsso3kq7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesupcomings {
  /**
   * If a string, then filter for records where this property matches exactly. If an array of
   * strings, filter for any
   * records matching any value in the array.
   */
  upcomingID?: any;
}

/**
 * Specifies criteria that should be used to strictly filter results. Filter conditions do not
 * impact ranking.
 * Filter criteria are specified per type. Filter criteria for multiple properties within a single
 * type are combined as
 * AND condition. Filters between different types are combined as an OR condition.

 */
export interface SearchV2RequestQueryBoostItemFilter {
  /**
   * Filter matching processes based on these criteria
   */
  processes?: SearchV2RequestQueryBoostPropertiesProperties;
  /**
   * Filter matching runs based on these criteria
   */
  runs?: Componentsm3fkqkschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesruns;
  /**
   * Filter matching tasks based on these criteria
   */
  tasks?: Componentsleqgx7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestasks;
  /**
   * Filter matching data tables based on these criteria
   */
  tables?: Components1cyn58ischemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestables;
  /**
   * Filter matching data users based on these criteria
   */
  users?: Componentsn3whvsschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesusers;
  /**
   * Filter matching help articles based on these criteria
   */
  help?: Componentsypjx3rschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelp;
  /**
   * Filter matching actions based on these criteria
   */
  actions?: Componentsumb4thschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactions;
  /**
   * Filter matching webhooks based on these criteria
   */
  webhooks?: Components17065sbschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieswebhooks;
  /**
   * Filter matching upcomings based on these criteria
   */
  upcomings?: Componentsso3kq7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesupcomings;
}

/**
 * An interface representing SearchV2RequestQueryBoostItem.
 */
export interface SearchV2RequestQueryBoostItem {
  /**
   * Specifies criteria that should be used to strictly filter results. Filter conditions do not
   * impact ranking.
   * Filter criteria are specified per type. Filter criteria for multiple properties within a
   * single type are combined as
   * AND condition. Filters between different types are combined as an OR condition.
   */
  filter?: SearchV2RequestQueryBoostItemFilter;
  /**
   * Optionally specify an entire set of typeto boost. Mutually exclusive with `filter`.
   */
  types?: string[];
  /**
   * Scores of individual results will be multiplied by this value if they match
   * the filter condition
   */
  boost?: number;
}

/**
 * Specifies criteria that should be used to filter and rank the results according to quality of
 * match

 */
export interface SearchV2RequestQuery {
  /**
   * Natural language text to query on, required in any `query`
   */
  text: string;
  /**
   * If true, allow documents in result where the final term in `text` matches the prefix of
   * a term in the document.
   */
  prefixSearch?: boolean;
  /**
   * Properties by type to search for the specified text within. If blank, search will
   * apply to all supported properties of all supported types.
   */
  properties?: SearchV2RequestQueryProperties;
  /**
   * Optional set of filters and associated boost values. This allows the caller to
   * impact the scoring and ranking of results that match a given set of filters.
   */
  boost?: SearchV2RequestQueryBoostItem[];
}

/**
 * Contextual options that affect how the search results are filtered or displayed.
 */
export interface SearchV2RequestOptionsActions {
  /**
   * Does not filter action search hits to only ones available to the team user via module
   * management.
   */
  ignoreAvailability?: boolean;
}

/**
 * Contextual options that affect how the search results are filtered or displayed.
 */
export interface SearchV2RequestOptions {
  /**
   * Contextual options that affect how the search results are filtered or displayed.
   */
  actions?: SearchV2RequestOptionsActions;
}

/**
 * Specifies filter and query parameters to execute a search.
 *
 * All of the properties in the search body are optional unless otherwise specified.

 * @summary Search V2 Request Schema.
 */
export interface SearchV2Request {
  /**
   * Types to search over, defaults to all searchable types
   */
  types?: string[];
  /**
   * Specifies criteria that should be used to strictly filter results. Filter conditions do not
   * impact ranking.
   * Filter criteria are specified per type. Filter criteria for multiple properties within a
   * single type are combined as
   * AND condition. Filters between different types are combined as an OR condition.
   */
  filter?: SearchV2RequestFilter;
  /**
   * Specifies criteria that should be used to filter and rank the results according to quality of
   * match
   */
  query?: SearchV2RequestQuery;
  /**
   * Contextual options that affect how the search results are filtered or displayed.
   */
  options?: SearchV2RequestOptions;
  /**
   * Used for pagination. Pass the value of `pageInfo.last` from a previous query to fetch
   * the next page.
   */
  after?: string;
  /**
   * Maximum number of results to return per page, defaults to 25
   */
  limit?: number;
  /**
   * Property on which to sort results. Default sort order is ascending. Suffix with `:asc` or
   * `:desc`
   * to override the default sort order. If this property is unspecified results will be returned
   * based
   * on the quality of match with the `query`.
   * . Possible values include: 'displayName', 'processDisplayName', 'endDate', 'startDate',
   * 'deadline', 'runStartDate', 'waitingCount', 'modifiedDate', 'createdDate', 'taskNumber',
   * 'status'
   */
  sort?: Sort;
}

/**
 * An interface representing SearchV2ResponseResultsItem.
 */
export interface SearchV2ResponseResultsItem {
  /**
   * The unique ID of the object that matched
   */
  id?: string;
  /**
   * Possible values include: 'processes', 'runs', 'tasks', 'tables', 'users', 'help',
   * 'notifications', 'actions', 'webhooks', 'upcomings'
   */
  type?: Type4;
  /**
   * The actual matched record. Schema matches objects returned
   * from individual type-specific endpoints.
   */
  item?: any;
}

/**
 * Paginination info
 * @summary PageInfo
 */
export interface SearchV2ResponsePageInfo {
  /**
   * The key to pass to 'after' when paging through a list
   */
  last?: string;
}

/**
 * An interface representing SearchV2Response.
 * @summary Search V2 Response Schema.
 */
export interface SearchV2Response {
  results?: SearchV2ResponseResultsItem[];
  /**
   * Paginination info
   */
  pageInfo?: SearchV2ResponsePageInfo;
  /**
   * The total number of results that matched across all pages of results
   */
  total?: number;
}

/**
 * Event form.
 */
export interface SMSEventForm {
  /**
   * Who the event form is from.
   */
  from?: string;
  /**
   * The event form body.
   */
  body?: string;
}

/**
 * SMS event.
 */
export interface SMSEvent {
  /**
   * Event form.
   */
  form?: SMSEventForm;
}

/**
 * Information regarding short messages and the attached forms or events that go with them.
 * @summary SMS schema.
 */
export interface SMS {
  /**
   * SMS event.
   */
  event?: SMSEvent;
}

/**
 * Represents a future scheduled (possibly recurring) action within Pushbot.
 * @summary Upcoming schema.
 */
export interface Upcoming {
  /**
   * The unique ID of this upcoming action.
   */
  upcomingID: string;
  /**
   * Username of the user on who's behalf the upcoming action will be taken.
   */
  authUser: string;
  /**
   * Username of the user who created this upcoming action.
   */
  createdBy?: string;
  /**
   * Date that this upcoming action was created.
   */
  createdDate?: Date;
  /**
   * The date of the next occurance of this upcoming action. If empty, the item will be processed
   * via queue in FIFO order.
   */
  schedule: Date;
  /**
   * The name of the action to take.
   */
  action: string;
  /**
   * The recurring action.
   */
  recurring?: any;
  /**
   * The timezone in which this upcoming action is scheduled.Default is UTC.
   */
  timezone?: string;
  /**
   * The display name of this upcoming action.
   */
  displayName: string;
  /**
   * The description of this upcoming action.
   */
  description?: string;
  /**
   * The specific request data for the upcoming action.
   */
  eventData: any;
  /**
   * A label for the type of upcoming action.Useful for filtering.
   */
  eventType?: string;
}

/**
 * Information about a user's login
 * @summary User Login schema.
 */
export interface UserLogins {
  /**
   * The IP address the user authenticated from for this login
   */
  ipAddress?: string;
  /**
   * Indicates whether the the login IP matches that of the current request. Default value: false.
   */
  currentIP?: boolean;
  /**
   * Valid time stamp value.
   */
  loginDate?: Date;
}

/**
 * SSO response.
 */
export interface CommunitySSOBody {
  /**
   * If there's an error, user readable error message validating SSO login.
   */
  message?: string;
  /**
   * If there's an error, Vanilla specific error
   */
  error?: string;
  /**
   * userID of authenticated user
   */
  uniqueid?: string;
  /**
   * email of authenticated user
   */
  email?: string;
  /**
   * URL for authenticated users profile picture
   */
  photourl?: string;
  /**
   * Client ID for Vanilla account
   */
  clientId?: string;
  /**
   * Hash signature of signed user parameters
   */
  signature?: string;
}

/**
 * Information regarding SSO authentication for Catalytic community.
 * @summary community-sso schema.
 */
export interface CommunitySSO {
  /**
   * SSO response.
   */
  body?: CommunitySSOBody;
}

/**
 * SSO response.
 */
export interface CommunitySSOV2Body {
  /**
   * Verified JWT that includes user authentication information, included in query string
   */
  jwt?: string;
}

/**
 * Information regarding SSO authentication for Catalytic community using V3 of Vanilla's
 * jsConnect.
 * @summary community-sso-v2 schema.
 */
export interface CommunitySSOV2 {
  /**
   * SSO response.
   */
  body?: CommunitySSOV2Body;
}

/**
 * Community authentication response.
 */
export interface CommunityAuthBody {
  /**
   * userID of authenticated user
   */
  uniqueid?: string;
  /**
   * email of authenticated user
   */
  email?: string;
  /**
   * URL for authenticated users profile picture
   */
  photourl?: string;
  /**
   * Client ID for Vanilla account
   */
  clientId?: string;
  /**
   * Hash signature of signed user parameters
   */
  signature?: string;
}

/**
 * Reponse for validating
 * @summary community-auth schema.
 */
export interface CommunityAuth {
  /**
   * Community authentication response.
   */
  body?: CommunityAuthBody;
}

/**
 * Community authentication response.
 */
export interface CommunityAuthV2Body {
  /**
   * Signed JWT that contains user auth info including email, name, photo and uniqueID
   */
  userJwt?: string;
  /**
   * Redirect URL provided by Vanilla
   */
  rurl?: string;
}

/**
 * Reponse for validating
 * @summary community-auth-v2 schema.
 */
export interface CommunityAuthV2 {
  /**
   * Community authentication response.
   */
  body?: CommunityAuthV2Body;
}

/**
 * An interface representing ActionInvocationInputsItem.
 */
export interface ActionInvocationInputsItem {
  /**
   * The display name of the input parameter
   */
  displayName?: string;
  /**
   * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
   * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
   * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
   */
  type?: Type5;
  /**
   * Does the input parameter require a value. Default value: false.
   */
  isRequired?: boolean;
  /**
   * Description of the input parameter
   */
  description?: string;
  /**
   * Default value for the input parameter
   */
  defaultValue?: string;
  /**
   * Actual input value for this invocation
   */
  value?: string;
}

/**
 * An interface representing ActionInvocationOutputsItem.
 */
export interface ActionInvocationOutputsItem {
  /**
   * The display name of the output field
   */
  displayName?: string;
  /**
   * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
   * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
   * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
   */
  type?: Type6;
  /**
   * Description of the output field
   */
  description?: string;
  /**
   * Actual output value for this invocation
   */
  value?: string;
}

/**
 * An Action Invocation represents a request to directly execute a specific action, outside of the
 * context of a workflow.
 * @summary Action Invocation schema.
 */
export interface ActionInvocation {
  /**
   * Universally unique identifier
   */
  actionID: string;
  /**
   * Universally unique identifier
   */
  actionInvocationID: string;
  /**
   * List of tags that a worker must have to execute this action invocation
   */
  requiredWorkerTags?: string[];
  /**
   * Optional JSON-serialized metadata about the invocation
   */
  metadata?: string;
  /**
   * Indicates whether the invocation is queued, running or completed. Possible values include:
   * 'queued', 'running', 'completed'
   */
  status?: Status5;
  /**
   * Action input parameters
   */
  inputs?: ActionInvocationInputsItem[];
  /**
   * Action output parameters
   */
  outputs?: ActionInvocationOutputsItem[];
  /**
   * Valid time stamp value.
   */
  expirationTime?: Date;
  /**
   * Universally unique identifier
   */
  completedByWorkerID?: string;
  /**
   * Valid time stamp value.
   */
  createdAt?: Date;
  /**
   * Universally unique identifier
   */
  createdByID?: string;
}

/**
 * An interface representing ActionInvocationRequestInputsItem.
 */
export interface ActionInvocationRequestInputsItem {
  /**
   * The display name of the input parameter
   */
  displayName?: string;
  /**
   * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
   * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
   * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
   */
  type?: Type7;
  /**
   * Does the input parameter require a value. Default value: false.
   */
  isRequired?: boolean;
  /**
   * Description of the input parameter
   */
  description?: string;
  /**
   * Default value for the input parameter
   */
  defaultValue?: string;
  /**
   * Actual input value for this invocation
   */
  value?: string;
}

/**
 * Represents a request to directly invoke an action with specified inputs and metadata.
 * @summary Action Invocation Request schema.
 */
export interface ActionInvocationRequest {
  /**
   * Universally unique identifier
   */
  actionID: string;
  /**
   * List of tags that a worker must have to execute this action invocation
   */
  requiredWorkerTags?: string[];
  /**
   * Optional JSON-serialized metadata about the invocation
   */
  metadata?: string;
  /**
   * Action input parameters
   */
  inputs?: ActionInvocationRequestInputsItem[];
  /**
   * The number of seconds before the invocation record expires and outputs are no longer
   * available. Default value: 86400.
   */
  lifetimeSeconds?: number;
}

/**
 * Information regarding application data.
 * @summary App data schema
 */
export interface ImportActionActionsItemAppData {
  /**
   * ID for the app data.
   */
  appID: string;
  /**
   * Key for the app data.
   */
  key: string;
}

/**
 * An interface representing ImportActionActionsItem.
 */
export interface ImportActionActionsItem {
  app?: App;
  /**
   * Information regarding application data.
   */
  appData?: ImportActionActionsItemAppData;
}

/**
 * An Imported Action is an action that lives in the database.
 * @summary Imported Action schema.
 */
export interface ImportAction {
  /**
   * An array of imported actions
   */
  actions: ImportActionActionsItem[];
}

/**
 * A request to create a Workbench capability
 * @summary Workbench Cabability Definition schema.
 */
export interface WorkbenchCapabilityDefinition {
  /**
   * The display name of the capability
   */
  displayName?: string;
  /**
   * A description of the capability
   */
  description?: string;
  /**
   * Whether this capability is granted to users who are not individually granted or denied the
   * capability. Defaults to deny by default. Default value: true.
   */
  grantedByDefault?: boolean;
  /**
   * Whether this capability is visible to everyone or just those with management rights. Default
   * value: true.
   */
  isPublic?: boolean;
  /**
   * User-defined JSON metadata for this capability
   */
  metadata?: string;
}

/**
 * The app step configuration, if this step is an app step.
 */
export interface AppStepConfiguration {
  /**
   * The unique ID of the app.
   */
  appID: string;
  /**
   * The display name of the app.
   */
  displayName?: string;
  /**
   * The name of the app
   */
  appName?: string;
  params?: any[];
}

/**
 * The metrics for a specific process
 */
export interface ProcessMetric {
  /**
   * The number of runs completed for this process
   */
  numberOfCompletedRuns: number;
  /**
   * The number of cancelled runs for this process
   */
  numberOfCancelledRuns: number;
  /**
   * The number of fix tasks created from these this process
   */
  numberOfFixTasks: number;
  /**
   * The average duration for each run from this process. This is in ISO 8601 Duration format.
   */
  averageRunDuration: string;
  /**
   * The shortest duration of the runs
   */
  minRunDuration: string;
  /**
   * The longest duration of the runs
   */
  maxRunDuration: string;
}

/**
 * A Pushbot Role is a group of users (or fields that resolve to users).
 * @summary Role schema.
 */
export interface RolePartial {
  /**
   * An array of the users included in this role.
   */
  users?: string[];
  /**
   * Role email.
   */
  email?: string;
  /**
   * Field name for role.
   */
  fieldName?: string;
  /**
   * Role process ID.
   */
  processID?: string;
  /**
   * Role rank.
   */
  rank?: string;
  /**
   * Role description.
   */
  roleID?: string;
  /**
   * The type of role.
   */
  roleType?: string;
  /**
   * The run ID for the role.
   */
  runID?: string;
  /**
   * Step name for the role.
   */
  stepName?: string;
  /**
   * Task name for the role.
   */
  taskName?: string;
  /**
   * User ID for the role.
   */
  userID?: string;
  /**
   * The username for the role.
   */
  username?: string;
}

/**
 * An interface representing Role.
 */
export interface Role extends RolePartial {
}

/**
 * SAML SSO Configuration.
 */
export interface SAMLSSOConfiguration {
  /**
   * SSO Login URL provided by the Identity Provider.
   */
  loginUrl: string;
  /**
   * SSO Logout URL provided by the Identity Provider.
   */
  logoutUrl?: string;
  /**
   * SSO Entity ID from Identity Provider.
   */
  entityId: string;
  /**
   * Certificate from the Identity Provider to sign SSO requests.
   */
  certificate: string;
  /**
   * Enabled. Whether SAML 2.0 SSO is enabled for this team.
   */
  enabled: boolean;
}

/**
 * Comment author.
 */
export interface CommentCommentsAuthor {
  /**
   * Author username.
   */
  username?: string;
  /**
   * Author email.
   */
  email?: string;
}

/**
 * List of comments.
 */
export interface CommentComments {
  /**
   * Comment date.
   */
  date?: string;
  /**
   * Comment thread ID.
   */
  threadID?: string;
  /**
   * Comment author.
   */
  author?: CommentCommentsAuthor;
}

/**
 * Who the message is from.
 */
export interface CommentMessageFrom {
  /**
   * Message addressee name.
   */
  name?: string;
}

/**
 * The message in the comment.
 */
export interface CommentMessage {
  /**
   * Message subject.
   */
  subject?: string;
  /**
   * Message notification.
   */
  isNotification?: string;
  /**
   * Message author.
   */
  actor?: string;
  /**
   * Who the message is from.
   */
  from?: CommentMessageFrom;
}

/**
 * Comments on Pushbot processes.
 * @summary The comments
 */
export interface Comment {
  /**
   * List of comments.
   */
  comments?: CommentComments;
  /**
   * The message in the comment.
   */
  message?: CommentMessage;
}

/**
 * Files uploaded to Pushbot, optionally in the context of a specific run, task, process, step or
 * field.
 * @summary File schema.
 */
export interface File {
  /**
   * Universally unique identifier
   */
  fileID: string;
  /**
   * Name of the file. Uniqueness is not enforced.
   */
  fileName: string;
  /**
   * The path of the file.
   */
  filePath?: string;
  /**
   * Content-Type of the field.
   */
  contentType?: string;
  /**
   * Size of the file in bytes.
   */
  size?: string;
  /**
   * Human-readable description of the file size (e.g., 4K).
   */
  displaySize?: string;
  /**
   * Indicates whether this file is shared publicly or not. Default value: false.
   */
  isPublic?: boolean;
  /**
   * Username of the user who created this field.
   */
  createdBy: string;
  /**
   * The MD5Hash of the file contents.
   */
  mD5Hash?: string;
  /**
   * A unique ETag for this file. May differ from MD5Hash.
   */
  eTag?: string;
  /**
   * Date that this file was created.
   */
  createdDate: Date;
  /**
   * The fileID of the previous version of this file, if it was uploaded times.
   */
  previousVersion?: string;
  /**
   * The fileID of the next version of this file, if it was uploaded times.
   */
  nextVersion?: string;
  /**
   * The fileID of the first version of this file, if it was uploaded multiple times.
   */
  originalFile?: string;
  /**
   * The thumbnails for the files.
   */
  thumbnails?: string[];
  /**
   * The extracted files.
   */
  extractedFiles?: string[];
  /**
   * The list of file versions.
   */
  versions?: string[];
  /**
   * An optional set of tags associated with this file.
   */
  tags?: string[];
  /**
   * URL to GET the contents of this file.
   */
  downloadUrl?: string;
  /**
   * The fileID of the original file of which this is a copy but with a different fileName or
   * filePath.
   */
  copyOf?: string;
  /**
   * The processID of the process that this file is attached to.
   */
  processID?: string;
  /**
   * The name of the process that this file is attached to.
   */
  processName?: string;
  /**
   * The name of the step that this file is attached to.
   */
  stepName?: string;
  /**
   * The runID of the run that this file is attached to.
   */
  runID?: string;
  /**
   * The tableID of the table that this file is related to.
   */
  tableID?: string;
  /**
   * The name of the task that this file is attached to.
   */
  taskName?: string;
  /**
   * The threadID of the message thread that this file is attached to.
   */
  threadID?: string;
  /**
   * The messageID of the message that this file is attached to.
   */
  messageID?: string;
  /**
   * Path to file icon/image.
   */
  icon?: string;
  /**
   * Server Side Encryption.
   */
  serverSideEncryption?: string;
}

/**
 * A Pushbot Message to or from a user.
 * @summary Message schema.
 */
export interface Message {
  /**
   * The ID of the message thread for this run.
   */
  threadID: string;
  /**
   * The ID of this message.
   */
  messageID: string;
  /**
   * The ID of this comment, if this message is a comment.
   */
  commentID?: string;
  username: string;
  /**
   * The medium of the message. E.g., "email", "message", "slack", etc.
   */
  medium: string;
  /**
   * The user's address within the medium. E.g., "user@example.com".
   */
  mediumAddress: string;
  /**
   * The ID of this message within its medium. E.g., the slack message ID or the email message ID
   * header.
   */
  mediumMessageID: string;
  /**
   * The subject of the message.
   */
  subject?: string;
  /**
   * DEPRECATED: use createdAt instead
   */
  timestamp: Date;
  /**
   * The date this message was created
   */
  createdAt?: Date;
  /**
   * Usernames of other users on this message.
   */
  participants: string[];
  /**
   * The tags applied to this message. Each tag should either be a string or object.
   */
  tags: string[];
  /**
   * Additional context for this message.
   */
  context: string;
  /**
   * The contents of this message
   */
  body?: string;
  /**
   * DEPRECATED: use body instead
   */
  shortBody?: string;
  /**
   * Indicates whether the shortBody was truncated or not.
   */
  shortBodyTruncated?: boolean;
  /**
   * Additional envelope information about the message.
   */
  envelope?: any;
  /**
   * List of message file names.
   */
  files?: File[];
  /**
   * The notification ID, if applicable.
   */
  notificationID?: string;
  /**
   * JSON blob for non relational data storage - to be used rarely
   */
  data?: any;
}

/**
 * An in-app notification.
 * @summary Notification schema.
 */
export interface Notification {
  /**
   * Unique identifier for this notification.
   */
  notificationID: string;
  /**
   * ID of user who received this notification.
   */
  userID: string;
  /**
   * The date of of this notification.
   */
  date: Date;
  /**
   * True iff notification has been read. False iff unread.
   */
  read?: boolean;
  /**
   * The subject of the notification.
   */
  subject?: string;
  /**
   * The content of the notification.
   */
  text?: string;
  /**
   * The type of the notification.
   */
  type: string;
  username: string;
  /**
   * The ID of the run this notification relates to (if applicable).
   */
  runID?: string;
  /**
   * The name of the task this notification relates to (if applicable).
   */
  taskName?: string;
}

/**
 * An interface representing Predictor.
 * @summary Predictor schema.
 */
export interface Predictor {
  /**
   * Universally unique identifier
   */
  predictorID: string;
  /**
   * Universally unique identifier
   */
  tableID: string;
  /**
   * The display name of the Predictor
   */
  displayName: string;
  /**
   * The type of predictions produced by this Predictor. Possible values include: 'binary',
   * 'categorical', 'regression'
   */
  type: Type8;
  /**
   * The system name of the field that this Predictor predicts from the referenced data table
   */
  dependentFieldName: string;
}

/**
 * An interface representing ProcessExport.
 * @summary Process Export Schema
 */
export interface ProcessExport {
  /**
   * Universally unique identifier
   */
  exportID: string;
  /**
   * Universally unique identifier
   */
  processID: string;
  /**
   * Universally unique identifier
   */
  fileID?: string;
  /**
   * The status of the Process Export
   */
  status?: string;
  /**
   * The error message associted with the Process Export
   */
  errorMessage?: string;
  /**
   * Username of the user who created the Process Export
   */
  createdBy: string;
  /**
   * Will be called with final Process Export when export is complete
   */
  callbackUrl?: string;
  /**
   * Password to be applied to Process Export file
   */
  password?: string;
}

/**
 * An interface representing ProcessImport.
 * @summary Process Import Schema
 */
export interface ProcessImport {
  /**
   * Universally unique identifier
   */
  importID: string;
  /**
   * Universally unique identifier
   */
  processID?: string;
  /**
   * Universally unique identifier
   */
  fileID: string;
  /**
   * The status of the Process Import
   */
  status?: string;
  /**
   * The error message associted with the Process Import
   */
  errorMessage?: string;
  /**
   * Username of the user who created the Process Import
   */
  createdBy: string;
  /**
   * Will be called with final Process Import when import is complete
   */
  callbackUrl?: string;
  /**
   * Password to be applied to Process Import file
   */
  password?: string;
}

/**
 * The app step configuration, if this step is an app step.
 */
export interface TaskDescriptionPieces {
  /**
   * The source template to be compiled with fieldMap.
   */
  sourceText: string;
  /**
   * The fields that should be used to fill in handlebars in sourceText.
   */
  fieldMap: string[];
}

/**
 * "A Catalytic Task is part of a Run and is
 * usually created from a Step template."

 * @summary Task schema.
 */
export interface Task {
  /**
   * Display name of the process that was the template for this task's run.
   */
  processDisplayName: string;
  /**
   * The processID of the process that was the template for this task's run.
   */
  processID: string;
  /**
   * Display name of run that this task is a part of.
   */
  runDisplayName: string;
  /**
   * The ID of the run that this task is a part of.
   */
  runID: string;
  /**
   * Task identifier.
   */
  taskID: string;
  /**
   * Name of the task, unique within the run.
   */
  taskName: string;
  /**
   * A number used to sort tasks for display.
   */
  taskNumber: number;
  /**
   * The display name of the task.
   */
  displayName: string;
  /**
   * The description of the task, usually including specific instructions.
   */
  description?: string;
  /**
   * The app step configuration, if this step is an app step.
   */
  descriptionPieces?: TaskDescriptionPieces;
  /**
   * The username of the user assigned to this task. Set automatically when the task is started.
   */
  assignedTo?: string;
  completedBy?: string;
  /**
   * The ID of the role from which a user will be chosen to perform this task.
   */
  roleID: string;
  /**
   * Username of the user who created this task.
   */
  createdBy: string;
  role?: Role;
  /**
   * The amount of time between when the tasks starts and its deadline.Ex: '5 hrs' or '3 days'.
   */
  maximumDuration?: string;
  /**
   * Valid time stamp value.
   */
  deadline?: Date;
  /**
   * Task priority.
   */
  priority: string;
  /**
   * The set of previous tasks which must all be completed before this task starts.
   */
  requiredPreviousTasks: string[];
  /**
   * The set of previous tasks at least one of which must be completedbefore this task starts.
   */
  previousTasks: string[];
  /**
   * The set of previous tasks which must all be completed or skipped before this task starts.
   */
  requiredResolvedTasks?: string[];
  /**
   * The set of expressions, all of which must evaluate to true or the
   */
  conditions: string[];
  /**
   * The fieldNames of fields writable by this task
   */
  writableFields?: string[];
  /**
   * Possible values include: 'waiting', 'running', 'completed', 'failed', 'skipped', 'rejected',
   * 'aborted'
   */
  status: Status6;
  /**
   * Valid time stamp value.
   */
  startDate?: Date;
  /**
   * Valid time stamp value.
   */
  endDate?: Date;
  /**
   * Default value: false.
   */
  isTerminal?: boolean;
  /**
   * DEPRECATED. Default value: false.
   */
  isOverdue?: boolean;
  /**
   * Indicates if the task is an adhoc task. Default value: false.
   */
  isAdHoc?: boolean;
  appStep?: AppStepConfiguration;
  files?: File[];
  conditionCode?: string;
  webhookID?: string;
  rejectedReason?: string;
  previousVersion?: string;
  nextVersion?: string;
  delayStartUntil?: string;
  /**
   * If this is a Fix Task, this will have the taskName of the task that failed
   */
  failedTaskName?: string;
  failedReason?: string;
  failedReasonDetail?: string;
  reminderIDs?: string;
  runStartDate?: string;
  deadlinePattern?: string;
  delayStartUntilPattern?: string;
  /**
   * The userID of the user that started the task.
   */
  startedByID?: string;
  /**
   * The duration of the task in ISO 8601 duration format from start until it was completed
   */
  duration?: string;
}

/**
 * A Pushbot Run is an instance of a Process.
 * @summary Run schema.
 */
export interface Run {
  /**
   * Display name of the process that was the template for this run.
   */
  processDisplayName: string;
  /**
   * Universally unique identifier
   */
  processID: string;
  /**
   * The ID of the run that this task is a part of.
   */
  runID: string;
  /**
   * The number of tasks in this run.
   */
  taskCount: number;
  /**
   * The display name of the task.
   */
  displayName?: string;
  /**
   * The description of the task, usually including specific instructions.
   */
  description?: string;
  /**
   * Username of the user who created this task.
   */
  createdBy: string;
  /**
   * Username of the owner of this run.
   */
  owner: string;
  /**
   * The amount of time between when the tasks starts and its deadline. Ex: '5 hrs' or '3 days'.
   */
  maximumDuration?: string;
  /**
   * An optional date after which this task is considered overdue if it is not completed.
   */
  deadline?: Date;
  /**
   * Catalytic priority level. Possible values include: 'low', 'medium', 'high'
   */
  priority: Priority;
  /**
   * A descriptive category for the run within the Team.
   */
  category?: string;
  /**
   * The tasks in this run.
   */
  tasks?: Task[];
  /**
   * An array of the fields that are set as part of this task.
   */
  fields?: Field[];
  /**
   * The fieldNames of fields writable at the run level
   */
  writableFields?: string[];
  /**
   * The status of this task. Possible values include: 'running', 'completed', 'failed', 'aborted'
   */
  status?: Status7;
  /**
   * The date this task was started, if it has been started.
   */
  startDate: Date;
  /**
   * The date this task was completed, if it was completed.
   */
  endDate?: Date;
  thumbnail?: string;
  files?: File[];
  /**
   * The ID of the message thread for this run.
   */
  threadID: string;
  abortedBy?: string;
  /**
   * Valid time stamp value.
   */
  abortedDate?: Date;
  percentComplete?: number;
  percentTimeElapsed?: number;
  waitingCount?: number;
  completedCount?: number;
  runningCount?: number;
  overdueCount?: number;
  /**
   * Possible values include: 'ok', 'warning', 'overdue'
   */
  deadlineWarning?: DeadlineWarning;
  /**
   * Default value: false.
   */
  isAdHoc?: boolean;
  /**
   * DEPRECATED. Default value: false.
   */
  isOverdue?: boolean;
  /**
   * Default value: false.
   */
  isTest?: boolean;
  callbackUrl?: string;
  ownerEmail?: string;
  ownerFullName?: string;
  progressSecret?: string;
  roles?: string;
  skipReporting?: string;
  /**
   * Default value: false.
   */
  skipTable?: boolean;
  skippedCount?: number;
  triggerID?: string;
  testMode?: string;
  adminUsers?: string[];
  standardUsers?: string[];
  /**
   * The ID of the parent task that spawned this child run.
   */
  parentTaskID?: string;
  /**
   * The ID of a run that has no parentRun.
   */
  rootRunID?: string;
  /**
   * The ID of the parent run that spawned this child run.
   */
  parentRunID?: string;
  /**
   * The visibility permissions of the run.
   */
  runVisibility?: string;
  /**
   * The users beyond the owner and process admins who have visibility permissions to view the run.
   */
  runVisibleToUsers?: string[];
  /**
   * The default visibility permissions for fields on the run.
   */
  fieldVisibility?: string;
  /**
   * The set of users associated with the Run.fieldVisibility value.
   */
  fieldVisibleToUsers?: string[];
  milestone?: string;
  /**
   * The scheduled date this instance and all related data will be deleted.
   */
  deletionEta?: Date;
  /**
   * The duration of the run from start until it was completed/aborted
   */
  duration?: string;
}

/**
 * A Pushbot Step is part of a Process and serves as a template for a task.
 * @summary Step schema.
 */
export interface StepPartial {
  /**
   * Valid parameter value.
   */
  processName?: string;
  /**
   * The processID of the process to which this step belongs.
   */
  processID?: string;
  /**
   * The stepID of the step.
   */
  stepID?: string;
  /**
   * Name of the step, unique within the process.
   */
  stepName?: string;
  /**
   * A number used to sort steps for display.
   */
  stepNumber?: number;
  /**
   * The display name of the step.
   */
  displayName?: string;
  /**
   * The description of the step, usually including specific instructions. This can include
   * {{field-name}} references that will be dereferenced when the task is started.
   */
  description?: string;
  /**
   * Username of the user who created this step.
   */
  createdBy?: string;
  /**
   * The amount of time between when the tasks starts and its deadline. Ex: '5 hrs' or '3 days'.
   */
  maximumDuration?: string;
  /**
   * An exact date or handlebars value representing a task's deadline.
   */
  deadlinePattern?: string;
  /**
   * Catalytic priority level. Possible values include: 'low', 'medium', 'high'
   */
  priority?: Priority1;
  /**
   * The set of previous steps which must all be completed before this step starts.
   */
  requiredPreviousSteps?: string[];
  /**
   * The set of previous steps at least one of which must be completed before this step starts.
   */
  previousSteps?: string[];
  /**
   * The set of previous steps which must all be completed or skipped before this step starts.
   */
  requiredResolvedSteps?: string[];
  /**
   * The set of expressions, all of which must evaluate to true or the step will be skipped.
   */
  conditions?: string[];
  /**
   * The fieldNames of fields writable by this step
   */
  writableFields?: string[];
  /**
   * The ID of the role from which a user will be chosen to perform this step.
   */
  roleID?: string;
  role?: RolePartial;
  /**
   * Indicates whether the process should complete immediately when this step is completed. Default
   * value: false.
   */
  isTerminal?: boolean;
  /**
   * Indicates whether the step was created in the course of creating an adhoc task. Default value:
   * false.
   */
  isAdHoc?: boolean;
  appStep?: AppStepConfiguration;
  /**
   * Valid JavaScript that has `tasks` and `fields` defined, and can optionally set `result` to one
   * of the valid `status` values.
   */
  conditionCode?: string;
  /**
   * Delay start until.
   */
  delayStartUntil?: string;
  /**
   * Delay start until pattern.
   */
  delayStartUntilPattern?: string;
  /**
   * The type of dependency behavior used for the step, where complex represents individual step
   * dependencies and startsAfterPreviousStep uses step position to determine when it should start.
   * Possible values include: 'complex', 'startsAfterPreviousStep'. Default value:
   * 'startsAfterPreviousStep'.
   */
  dependencyType?: DependencyType;
}

/**
 * An interface representing Step.
 */
export interface Step extends StepPartial {
}

/**
 * A Pushbot Data Table is the tabular representation of fields, either derived from a Pushbot
 * template and it's runs, or defined by the user.
 * @summary Table schema.
 */
export interface Table {
  /**
   * Universally unique identifier
   */
  tableID: string;
  /**
   * The display name of the table.
   */
  displayName: string;
  /**
   * Describes the type of data table. Possible values include: 'process', 'userDefined', 'team',
   * 'user'
   */
  tableType?: TableType;
  /**
   * Username of the user who created this step.
   */
  createdBy: string;
  /**
   * Valid time stamp value.
   */
  createdDate: Date;
  modifiedBy?: string;
  /**
   * Valid time stamp value.
   */
  modifiedDate?: Date;
  /**
   * Indicates whether or not the table is archived. Default value: false.
   */
  isArchived?: boolean;
  /**
   * A list of fieldNames whose fields define the name, type, sort order, etc. of data in a given
   * column.
   */
  columns?: Field[];
  /**
   * Visibility of the table, defining which users can see it. Possible values include: 'public',
   * 'internal', 'confidential', 'highlyConfidential'. Default value: 'public'.
   */
  visibility?: Visibility;
  /**
   * An array of the users who have access to this table's content
   */
  visibleToUsers?: string[];
}

/**
 * Slack Integration.
 */
export interface TeamSlackIntegration {
  /**
   * Slack token.
   */
  slackToken?: string;
  /**
   * Slack team name.
   */
  slackTeam?: string;
  /**
   * Slack team ID.
   */
  slackTeamID?: string;
  /**
   * Slack integration user name.
   */
  integrationUser?: string;
}

/**
 * All pushbot activity is in the context of a specific team.
 * @summary Team
 */
export interface Team {
  /**
   * The display name of the team.
   */
  displayName?: string;
  /**
   * The description of the team.
   */
  description?: string;
  thumbnailUrl?: string;
  emailImageBrandingUrl?: string;
  /**
   * Custom accent color for branding, in RGB hex code.
   */
  accentColor?: string;
  allowedCIDRs?: string[];
  /**
   * Username of the user who created the team.
   */
  ownerUsername?: any;
  /**
   * Email of the user who created the team.
   */
  ownerEmail?: string;
  /**
   * Slack token.
   */
  slackToken?: string;
  samlConfig?: SAMLSSOConfiguration;
  /**
   * Slack Integration.
   */
  slackIntegration?: TeamSlackIntegration;
  /**
   * Is Internal Team. Whether or not this is an internal (non-customer) team
   */
  isInternalTeam?: boolean;
  /**
   * Which team this team is a sandbox for, if any
   */
  sandboxFor?: string;
  /**
   * Whether or not the team is active
   */
  status?: string;
  /**
   * Whether or not TLS is required on emails
   */
  requireTlsOnEmails?: string;
  /**
   * Set of IP addresses to be used for outbound emails
   */
  emailIpAddresses?: string[];
  /**
   * Fully qualified domain name that emails are sent from
   */
  emailDomain?: string;
  /**
   * Local-part of the From address used by this team to send emails. If not set, `pushbot` is
   * used.
   */
  emailLocalPart?: string;
  /**
   * Building Allowed. If users can build by default
   */
  buildingAllowedByDefault?: boolean;
  /**
   * Domains allowed to host embedded webforms
   */
  allowedEmbeddedDomains?: string[];
  /**
   * Restrict Invites to Admins. If only admins are allowed to invite users
   */
  restrictInvitesToAdmins?: boolean;
  iconUrl?: string;
}

/**
 * A Pushbot webhook represents a publicly reachable endpoint which can be hit to trigger various
 * actions.
 * @summary Webhook schema.
 */
export interface Webhook {
  /**
   * The unique ID of this webhook.
   */
  webhookID: Date;
  createdBy: string;
  /**
   * Date that this webhook was created.
   */
  createdDate: Date;
  integrationUser?: string;
  /**
   * The display name of the webhook.
   */
  displayName?: string;
  /**
   * The description of the webhook.
   */
  description?: string;
  /**
   * Params to pass when the webhook is invoked.
   */
  params?: string[];
  /**
   * The URL at which this webhook can be invoked.
   */
  webhookUrl?: string;
  /**
   * The type of webhook. Possible values include: 'process', 'task', 'app'
   */
  webhookType?: WebhookType;
  /**
   * The processID of the process that this webhook is tied to, if any.
   */
  processID?: string;
  /**
   * Name of the process this ebhook is tied to, if any.
   */
  processName?: string;
  /**
   * Display name of the process this webhook is tied to, if any.
   */
  processDisplayName?: string;
  /**
   * The ID of the run of the task that this webhook is tied to, if any.
   */
  runID?: string;
  /**
   * Display name of the run of the task that this webhook is tied to, if any.
   */
  runDisplayName?: string;
  /**
   * Unique ID of the task to which this webhook is tied, if any.
   */
  taskID?: string;
  /**
   * Name of the task, unique within the run.
   */
  taskName?: string;
  /**
   * Display name of the task that this webhook is tied to, if ay.
   */
  taskDisplayName?: string;
  /**
   * The name of the app this webhook is tied to, if any.
   */
  appName?: string;
  /**
   * The name of the action for the app this webhook is tied to, if any.
   */
  appAction?: string;
  /**
   * The major version of the app this webhook is tied to, if any.
   */
  appVersion?: string;
  /**
   * The unique ID of the app this webhook is tied to, if any.
   */
  appID?: string;
  /**
   * The display name of the app this webhook is tied to, if any.
   */
  appDisplayName?: string;
  /**
   * Whether webhook is public.
   */
  isPublic?: boolean;
  /**
   * Webhook run and start date.
   */
  runStartDate?: string;
  /**
   * URL for webform.
   */
  webformUrl?: string;
  /**
   * Name of webhook.
   */
  webhookName?: string;
  /**
   * Indicates whether a webhook is available for use.
   */
  softDeleted?: boolean;
}

/**
 * The username of a user with access to this field's content.
 */
export interface FieldPartialItems {
  /**
   * The type of the items within the array
   */
  type?: any;
}

/**
 * An interface representing TableFileReference.
 * @summary Table File Reference
 */
export interface TableFileReference {
  fileID: string;
  headerRowNumber: number;
}

/**
 * An interface representing TableCopy.
 * @summary Table Copy Input
 */
export interface TableCopy {
  sourceTableID?: string;
  /**
   * Possible values include: 'process', 'runOnly', 'team', 'user', 'userDefined'
   */
  tableType?: TableType1;
}

/**
 * An interface representing OrchestrationAPIOptions.
 */
export interface OrchestrationAPIOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListAppsOptionalParams extends msRest.RequestOptionsBase {
  action?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetModuleFeaturesModulesOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  /**
   * Order results by one or more of the fields: [displayName] and direction [asc,desc] e.g.:
   * 'displayName asc'.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateModuleFeaturesModuleOptionalParams extends msRest.RequestOptionsBase {
  body?: Pathsdk40osmoduleFeaturesModulespostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateModuleFeaturesModuleOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths1t80n3vmoduleFeaturesModulesModulenamepatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateTeamModuleAvailabilityOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths1bbj998moduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetModuleFeaturesModuleFeaturesOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  /**
   * Order results by one or more of the fields: [displayName] and direction [asc,desc] e.g.:
   * 'displayName asc'.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIAddModuleFeaturesFeatureToModuleOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths16s58acmoduleFeaturesModulesModulenameFeaturespostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetModuleFeaturesFeaturesOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  /**
   * Order results by one or more of the fields: [displayName] and direction [asc,desc] e.g.:
   * 'displayName asc'.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateModuleFeaturesFeatureOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths10iunt5moduleFeaturesFeaturespostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateModuleFeaturesFeaturesOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths1j5fzv6moduleFeaturesFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateTeamFeatureAvailabilityOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths23ngpnmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetModuleFeaturesFeatureModulesOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  /**
   * Order results by one or more of the fields: [displayName] and direction [asc,desc] e.g.:
   * 'displayName asc'.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetAdminProcessOptionalParams extends msRest.RequestOptionsBase {
  exportParameter?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetAppsOptionalParams extends msRest.RequestOptionsBase {
  action?: string;
  includeSharedApps?: string;
  integrationDefinition?: string;
  customIntegrationWildcard?: string;
  ignoreAvailability?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamAppOptionalParams extends msRest.RequestOptionsBase {
  includeSharedApps?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIDeleteAppOptionalParams extends msRest.RequestOptionsBase {
  importedCollection?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamAppActionOptionalParams extends msRest.RequestOptionsBase {
  includeSharedApps?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListActionsOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateActionOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The action to register
   */
  body?: Action;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateActionOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The updated action attributes
   */
  body?: ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListGlobalActionsOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateGlobalActionOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The action to register
   */
  body?: Action;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateGlobalActionOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The updated action attributes
   */
  body?: ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListTeamWorkersOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateWorkerOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The worker to register
   */
  body?: Worker;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIInvokeActionOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The details of the invocation, including inputs and metadata
   */
  body?: ActionInvocationRequest;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateWorkerOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The updated worker attributes
   */
  body?: Paths1mf3sbjteamsTeamnameWorkbenchWorkersWorkeridpatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTasksOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListWorkerControlsOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateWorkerControlOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The worker to register
   */
  body?: Worker;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateWorkerControlOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The updated worker control attributes
   */
  body?: Paths151lzq5workbenchControlsControlidpatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIAcquireWorkerTaskOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Acquire Task Parameters
   */
  body?: Paths1llxakgworkbenchWorkersWorkeridAcquireTaskpostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIBatchAcquireTasksOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Acquire Tasks Parameters
   */
  body?: Paths1idfmsmworkbenchWorkersWorkeridBatchAcquireTaskspostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListWorkersOptionalParams extends msRest.RequestOptionsBase {
  lockRequestID?: string;
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIPostCommentToWorkerTaskOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Post Comment Parameters
   */
  body?: Paths8f3s9eworkbenchWorkersWorkeridTasksTaskidCommentspostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIAdminListUserIdsOptionalParams extends msRest.RequestOptionsBase {
  teamName?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateWorkbenchCapabilityOptionalParams extends msRest.RequestOptionsBase {
  /**
   * New Workbench Capability
   */
  body?: WorkbenchCapabilityDefinition;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateWorkbenchCapabilityOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Updated Workbench Capability
   */
  body?: Paths4rs19sworkbenchCapabilitiesCapabilityidpatchrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateGlobalCapabilityGrantOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Optional lifetime for the Grant
   */
  body?: Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIRevokeGlobalCapabilityGrantOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Possible values include: 'use', 'manage'
   */
  grantType?: GrantType2;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateTeamCapabilityGrantOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Optional lifetime for the Grant
   */
  body?: Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIRevokeTeamCapabilityGrantOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Possible values include: 'use', 'manage'
   */
  grantType?: GrantType3;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetAuthOptionalParams extends msRest.RequestOptionsBase {
  token?: string;
  authWords?: string;
  system?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateFileOptionalParams extends msRest.RequestOptionsBase {
  fileSize?: string;
  done?: string;
  contentType?: string;
  filename?: string;
  multipart?: string;
  xContentMd5?: string;
  fileid?: string;
  previousVersion?: string;
  number?: string;
  partSize?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListProcessesOptionalParams extends msRest.RequestOptionsBase {
  showOnStartMenu?: string;
  owner?: string;
  priority?: string;
  category?: string;
  createdBy?: string;
  published?: string;
  exportParameter?: string;
  limit?: string;
  after?: string;
  listRoots?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetProcessOptionalParams extends msRest.RequestOptionsBase {
  includeChildren?: boolean;
  exportParameter?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListProcessRunsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The status of runs to get
   */
  status?: string;
  /**
   * The beginning date to get runs from
   */
  startDate?: string;
  /**
   * The end date to get runs from
   */
  endDate?: string;
  format?: string;
  columns?: string;
  rows?: string;
  /**
   * The number of rows to return in the response
   */
  limit?: string;
  after?: string;
  /**
   * Whether to include each runs tasks in the response
   */
  includeChildren?: boolean;
  includeWhollyOwned?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetStepMetricsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The start date to get metrics for
   */
  start?: Date;
  /**
   * The end date to get metrics for
   */
  end?: Date;
  /**
   * The number of steps to be returned per page. Defaults to 25
   */
  pageSize?: number;
  /**
   * The page of results to return
   */
  pageToken?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIEvaluateStepOutputFieldsOptionalParams extends msRest.RequestOptionsBase {
  body?: any;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateCustomActionOptionalParams extends msRest.RequestOptionsBase {
  body?: Action;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUpdateCustomActionOptionalParams extends msRest.RequestOptionsBase {
  body?: Action;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetProcessVisibilityOptionalParams extends msRest.RequestOptionsBase {
  includeIgnored?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateProcessExportOptionalParams extends msRest.RequestOptionsBase {
  body?: ProcessExport;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateProcessImportOptionalParams extends msRest.RequestOptionsBase {
  body?: ProcessImport;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListRunsOptionalParams extends msRest.RequestOptionsBase {
  owner?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  columns?: string;
  rows?: string;
  format?: string;
  limit?: string;
  after?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetRunOptionalParams extends msRest.RequestOptionsBase {
  includeChildren?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListRunCommentsOptionalParams extends msRest.RequestOptionsBase {
  sort?: string;
  limit?: string;
  after?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateRunCommentOptionalParams extends msRest.RequestOptionsBase {
  body?: Comment;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListRunTasksOptionalParams extends msRest.RequestOptionsBase {
  status?: string;
  limit?: string;
  after?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListRunTaskFilesOptionalParams extends msRest.RequestOptionsBase {
  includeVersions?: string;
  includeDownloadUrls?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPISearchV1OptionalParams extends msRest.RequestOptionsBase {
  types?: string;
  limit?: string;
  pageToken?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPISearchV2OptionalParams extends msRest.RequestOptionsBase {
  body?: SearchV2Request;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIUsersSearchOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths1dpewitteamsTeamnameUsersSearchgetrequestbodycontentapplicationJsonschema;
  /**
   * The token of the page to fetch
   */
  pageToken?: string;
  /**
   * The number of results in the page to fetch
   */
  pageSize?: number;
  /**
   * The property to order the results by
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIProcessesSearchOptionalParams extends msRest.RequestOptionsBase {
  body?: Pathsqfzj7vteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschema;
  /**
   * The token of the page to fetch
   */
  pageToken?: string;
  /**
   * The number of results in the page to fetch
   */
  pageSize?: number;
  /**
   * The property to order the results by
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIEvaluateStepOutputsOptionalParams extends msRest.RequestOptionsBase {
  body?: any;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListTasksOptionalParams extends msRest.RequestOptionsBase {
  limit?: string;
  after?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIReopenTaskOptionalParams extends msRest.RequestOptionsBase {
  optional?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListTablesOptionalParams extends msRest.RequestOptionsBase {
  limit?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIIndexMetaTablesOptionalParams extends msRest.RequestOptionsBase {
  subtype?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListTableRowsOptionalParams extends msRest.RequestOptionsBase {
  limit?: string;
  pageToken?: string;
  fields?: string;
  filter?: string;
  select?: string;
  columns?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListUserTokensOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
  /**
   * Return User Tokens for all team members. Only usable by Team Admins.
   */
  allUsers?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPICreateUserTokenOptionalParams extends msRest.RequestOptionsBase {
  body?: Paths1ixwjwdteamsTeamnameUserTokenspostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIApproveUserTokenOptionalParams extends msRest.RequestOptionsBase {
  xForwardedFor?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIPollUserTokenOptionalParams extends msRest.RequestOptionsBase {
  body?: any;
  waitTimeMillis?: number;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListTriggersOptionalParams extends msRest.RequestOptionsBase {
  limit?: string;
  after?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIAuthenticateUserGetOptionalParams extends msRest.RequestOptionsBase {
  token?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIAuthenticateTeamUserOptionalParams extends msRest.RequestOptionsBase {
  token?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIMarkUserNotificationsReadOptionalParams extends msRest.RequestOptionsBase {
  body?: Pathsbjy3erteamsTeamnameUsersUsernameNotificationsReadpostrequestbodycontentapplicationJsonschema;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIListFavoritesOptionalParams extends msRest.RequestOptionsBase {
  limit?: number;
  after?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetWebhookOptionalParams extends msRest.RequestOptionsBase {
  addFields?: string;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetProcessMetricsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * The start date to get metrics for
   */
  start?: Date;
  /**
   * The end date to get metrics for
   */
  end?: Date;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamModulesOptionalParams extends msRest.RequestOptionsBase {
  isAvailable?: boolean;
  pageToken?: string;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamModuleFeaturesOptionalParams extends msRest.RequestOptionsBase {
  isAvailable?: boolean;
  pageToken?: string;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamFeaturesOptionalParams extends msRest.RequestOptionsBase {
  isAvailable?: boolean;
  pageToken?: string;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamFeatureModulesOptionalParams extends msRest.RequestOptionsBase {
  isAvailable?: boolean;
  pageToken?: string;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamFrequentlyUsedActionsOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface OrchestrationAPIGetTeamRecentlyUsedActionsOptionalParams extends msRest.RequestOptionsBase {
  pageToken?: string;
  pageSize?: number;
}

/**
 * Defines headers for get-worker-task-file operation.
 */
export interface GetWorkerTaskFileHeaders {
  cacheControl: string;
  contentType: string;
  contentDisposition: string;
  contentLength: string;
}

/**
 * Defines headers for saml-login operation.
 */
export interface SamlLoginHeaders {
  location: string;
}

/**
 * Defines values for Type.
 * Possible values include: 'string', 'float', 'int', 'datetime', 'boolean', 'latlong', 'json',
 * 'file', 'choice', 'bool', 'date'
 * @readonly
 * @enum {string}
 */
export enum Type {
  String = 'string',
  Float = 'float',
  Int = 'int',
  Datetime = 'datetime',
  Boolean = 'boolean',
  Latlong = 'latlong',
  Json = 'json',
  File = 'file',
  Choice = 'choice',
  Bool = 'bool',
  Date = 'date',
}

/**
 * Defines values for Type1.
 * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
 * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
 * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
 * @readonly
 * @enum {string}
 */
export enum Type1 {
  Array = 'array',
  Boolean = 'boolean',
  Bool = 'bool',
  Choice = 'choice',
  Date = 'date',
  Datetime = 'datetime',
  Email = 'email',
  File = 'file',
  Float = 'float',
  Int = 'int',
  Latlong = 'latlong',
  Json = 'json',
  Many = 'many',
  Number = 'number',
  Object = 'object',
  Photo = 'photo',
  Phone = 'phone',
  String = 'string',
  Table = 'table',
  Process = 'process',
  Run = 'run',
  User = 'user',
  Text = 'text',
  Undefined = 'undefined',
}

/**
 * Defines values for Type2.
 * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
 * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
 * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
 * @readonly
 * @enum {string}
 */
export enum Type2 {
  Array = 'array',
  Boolean = 'boolean',
  Bool = 'bool',
  Choice = 'choice',
  Date = 'date',
  Datetime = 'datetime',
  Email = 'email',
  File = 'file',
  Float = 'float',
  Int = 'int',
  Latlong = 'latlong',
  Json = 'json',
  Many = 'many',
  Number = 'number',
  Object = 'object',
  Photo = 'photo',
  Phone = 'phone',
  String = 'string',
  Table = 'table',
  Process = 'process',
  Run = 'run',
  User = 'user',
  Text = 'text',
  Undefined = 'undefined',
}

/**
 * Defines values for Type3.
 * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
 * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
 * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
 * @readonly
 * @enum {string}
 */
export enum Type3 {
  Array = 'array',
  Boolean = 'boolean',
  Bool = 'bool',
  Choice = 'choice',
  Date = 'date',
  Datetime = 'datetime',
  Email = 'email',
  File = 'file',
  Float = 'float',
  Int = 'int',
  Latlong = 'latlong',
  Json = 'json',
  Many = 'many',
  Number = 'number',
  Object = 'object',
  Photo = 'photo',
  Phone = 'phone',
  String = 'string',
  Table = 'table',
  Process = 'process',
  Run = 'run',
  User = 'user',
  Text = 'text',
  Undefined = 'undefined',
}

/**
 * Defines values for Status.
 * Possible values include: 'timeout', 'success'
 * @readonly
 * @enum {string}
 */
export enum Status {
  Timeout = 'timeout',
  Success = 'success',
}

/**
 * Defines values for Status1.
 * Possible values include: 'timeout', 'success'
 * @readonly
 * @enum {string}
 */
export enum Status1 {
  Timeout = 'timeout',
  Success = 'success',
}

/**
 * Defines values for Status2.
 * Possible values include: 'timeout', 'success'
 * @readonly
 * @enum {string}
 */
export enum Status2 {
  Timeout = 'timeout',
  Success = 'success',
}

/**
 * Defines values for Status3.
 * Possible values include: 'timeout', 'success'
 * @readonly
 * @enum {string}
 */
export enum Status3 {
  Timeout = 'timeout',
  Success = 'success',
}

/**
 * Defines values for GrantType.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for LogEvent.
 * Possible values include: 'created', 'deleted'
 * @readonly
 * @enum {string}
 */
export enum LogEvent {
  Created = 'created',
  Deleted = 'deleted',
}

/**
 * Defines values for GrantType1.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType1 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for Updates.
 * Possible values include: 'email', 'sms', ''
 * @readonly
 * @enum {string}
 */
export enum Updates {
  Email = 'email',
  Sms = 'sms',
  Null = "null",
}

/**
 * Defines values for Assignment.
 * Possible values include: 'email', 'sms', ''
 * @readonly
 * @enum {string}
 */
export enum Assignment {
  Email = 'email',
  Sms = 'sms',
  Null = "null",
}

/**
 * Defines values for Reminders.
 * Possible values include: 'email', 'sms', ''
 * @readonly
 * @enum {string}
 */
export enum Reminders {
  Email = 'email',
  Sms = 'sms',
  Null = "null",
}

/**
 * Defines values for DailySummary.
 * Possible values include: 'email', ''
 * @readonly
 * @enum {string}
 */
export enum DailySummary {
  Email = 'email',
  Null = "null",
}

/**
 * Defines values for WeeklyTeamOwner.
 * Possible values include: 'email', ''
 * @readonly
 * @enum {string}
 */
export enum WeeklyTeamOwner {
  Email = 'email',
  Null = "null",
}

/**
 * Defines values for RunCompletion.
 * Possible values include: 'email', ''
 * @readonly
 * @enum {string}
 */
export enum RunCompletion {
  Email = 'email',
  Null = "null",
}

/**
 * Defines values for Experience.
 * Possible values include: 'alpha', 'beta', 'stable', ''
 * @readonly
 * @enum {string}
 */
export enum Experience {
  Alpha = 'alpha',
  Beta = 'beta',
  Stable = 'stable',
  Null = "null",
}

/**
 * Defines values for Status4.
 * Possible values include: 'requested', 'approved', 'revoked'
 * @readonly
 * @enum {string}
 */
export enum Status4 {
  Requested = 'requested',
  Approved = 'approved',
  Revoked = 'revoked',
}

/**
 * Defines values for RequestedObjectType.
 * Possible values include: 'team', 'process', 'table'
 * @readonly
 * @enum {string}
 */
export enum RequestedObjectType {
  Team = 'team',
  Process = 'process',
  Table = 'table',
}

/**
 * Defines values for Sort.
 * Possible values include: 'displayName', 'processDisplayName', 'endDate', 'startDate',
 * 'deadline', 'runStartDate', 'waitingCount', 'modifiedDate', 'createdDate', 'taskNumber',
 * 'status'
 * @readonly
 * @enum {string}
 */
export enum Sort {
  DisplayName = 'displayName',
  ProcessDisplayName = 'processDisplayName',
  EndDate = 'endDate',
  StartDate = 'startDate',
  Deadline = 'deadline',
  RunStartDate = 'runStartDate',
  WaitingCount = 'waitingCount',
  ModifiedDate = 'modifiedDate',
  CreatedDate = 'createdDate',
  TaskNumber = 'taskNumber',
  Status = 'status',
}

/**
 * Defines values for Type4.
 * Possible values include: 'processes', 'runs', 'tasks', 'tables', 'users', 'help',
 * 'notifications', 'actions', 'webhooks', 'upcomings'
 * @readonly
 * @enum {string}
 */
export enum Type4 {
  Processes = 'processes',
  Runs = 'runs',
  Tasks = 'tasks',
  Tables = 'tables',
  Users = 'users',
  Help = 'help',
  Notifications = 'notifications',
  Actions = 'actions',
  Webhooks = 'webhooks',
  Upcomings = 'upcomings',
}

/**
 * Defines values for Type5.
 * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
 * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
 * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
 * @readonly
 * @enum {string}
 */
export enum Type5 {
  Array = 'array',
  Boolean = 'boolean',
  Bool = 'bool',
  Choice = 'choice',
  Date = 'date',
  Datetime = 'datetime',
  Email = 'email',
  File = 'file',
  Float = 'float',
  Int = 'int',
  Latlong = 'latlong',
  Json = 'json',
  Many = 'many',
  Number = 'number',
  Object = 'object',
  Photo = 'photo',
  Phone = 'phone',
  String = 'string',
  Table = 'table',
  Process = 'process',
  Run = 'run',
  User = 'user',
  Text = 'text',
  Undefined = 'undefined',
}

/**
 * Defines values for Type6.
 * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
 * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
 * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
 * @readonly
 * @enum {string}
 */
export enum Type6 {
  Array = 'array',
  Boolean = 'boolean',
  Bool = 'bool',
  Choice = 'choice',
  Date = 'date',
  Datetime = 'datetime',
  Email = 'email',
  File = 'file',
  Float = 'float',
  Int = 'int',
  Latlong = 'latlong',
  Json = 'json',
  Many = 'many',
  Number = 'number',
  Object = 'object',
  Photo = 'photo',
  Phone = 'phone',
  String = 'string',
  Table = 'table',
  Process = 'process',
  Run = 'run',
  User = 'user',
  Text = 'text',
  Undefined = 'undefined',
}

/**
 * Defines values for Status5.
 * Possible values include: 'queued', 'running', 'completed'
 * @readonly
 * @enum {string}
 */
export enum Status5 {
  Queued = 'queued',
  Running = 'running',
  Completed = 'completed',
}

/**
 * Defines values for Type7.
 * Possible values include: 'array', 'boolean', 'bool', 'choice', 'date', 'datetime', 'email',
 * 'file', 'float', 'int', 'latlong', 'json', 'many', 'number', 'object', 'photo', 'phone',
 * 'string', 'table', 'process', 'run', 'user', 'text', 'undefined'
 * @readonly
 * @enum {string}
 */
export enum Type7 {
  Array = 'array',
  Boolean = 'boolean',
  Bool = 'bool',
  Choice = 'choice',
  Date = 'date',
  Datetime = 'datetime',
  Email = 'email',
  File = 'file',
  Float = 'float',
  Int = 'int',
  Latlong = 'latlong',
  Json = 'json',
  Many = 'many',
  Number = 'number',
  Object = 'object',
  Photo = 'photo',
  Phone = 'phone',
  String = 'string',
  Table = 'table',
  Process = 'process',
  Run = 'run',
  User = 'user',
  Text = 'text',
  Undefined = 'undefined',
}

/**
 * Defines values for Type8.
 * Possible values include: 'binary', 'categorical', 'regression'
 * @readonly
 * @enum {string}
 */
export enum Type8 {
  Binary = 'binary',
  Categorical = 'categorical',
  Regression = 'regression',
}

/**
 * Defines values for Status6.
 * Possible values include: 'waiting', 'running', 'completed', 'failed', 'skipped', 'rejected',
 * 'aborted'
 * @readonly
 * @enum {string}
 */
export enum Status6 {
  Waiting = 'waiting',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
  Skipped = 'skipped',
  Rejected = 'rejected',
  Aborted = 'aborted',
}

/**
 * Defines values for Priority.
 * Possible values include: 'low', 'medium', 'high'
 * @readonly
 * @enum {string}
 */
export enum Priority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

/**
 * Defines values for Status7.
 * Possible values include: 'running', 'completed', 'failed', 'aborted'
 * @readonly
 * @enum {string}
 */
export enum Status7 {
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
  Aborted = 'aborted',
}

/**
 * Defines values for DeadlineWarning.
 * Possible values include: 'ok', 'warning', 'overdue'
 * @readonly
 * @enum {string}
 */
export enum DeadlineWarning {
  Ok = 'ok',
  Warning = 'warning',
  Overdue = 'overdue',
}

/**
 * Defines values for TableType.
 * Possible values include: 'process', 'userDefined', 'team', 'user'
 * @readonly
 * @enum {string}
 */
export enum TableType {
  Process = 'process',
  UserDefined = 'userDefined',
  Team = 'team',
  User = 'user',
}

/**
 * Defines values for Visibility.
 * Possible values include: 'public', 'internal', 'confidential', 'highlyConfidential'
 * @readonly
 * @enum {string}
 */
export enum Visibility {
  Public = 'public',
  Internal = 'internal',
  Confidential = 'confidential',
  HighlyConfidential = 'highlyConfidential',
}

/**
 * Defines values for WebhookType.
 * Possible values include: 'process', 'task', 'app'
 * @readonly
 * @enum {string}
 */
export enum WebhookType {
  Process = 'process',
  Task = 'task',
  App = 'app',
}

/**
 * Defines values for Type9.
 * Possible values include: 'string', 'float', 'int', 'datetime', 'boolean', 'latlong', 'json',
 * 'file', 'choice', 'bool', 'date', 'array', 'table'
 * @readonly
 * @enum {string}
 */
export enum Type9 {
  String = 'string',
  Float = 'float',
  Int = 'int',
  Datetime = 'datetime',
  Boolean = 'boolean',
  Latlong = 'latlong',
  Json = 'json',
  File = 'file',
  Choice = 'choice',
  Bool = 'bool',
  Date = 'date',
  Array = 'array',
  Table = 'table',
}

/**
 * Defines values for Visibility1.
 * Possible values include: 'public', 'internal', 'confidential', 'highlyConfidential'
 * @readonly
 * @enum {string}
 */
export enum Visibility1 {
  Public = 'public',
  Internal = 'internal',
  Confidential = 'confidential',
  HighlyConfidential = 'highlyConfidential',
}

/**
 * Defines values for Priority1.
 * Possible values include: 'low', 'medium', 'high'
 * @readonly
 * @enum {string}
 */
export enum Priority1 {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

/**
 * Defines values for DependencyType.
 * Possible values include: 'complex', 'startsAfterPreviousStep'
 * @readonly
 * @enum {string}
 */
export enum DependencyType {
  Complex = 'complex',
  StartsAfterPreviousStep = 'startsAfterPreviousStep',
}

/**
 * Defines values for Priority2.
 * Possible values include: 'low', 'medium', 'high'
 * @readonly
 * @enum {string}
 */
export enum Priority2 {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

/**
 * Defines values for FieldVisibility.
 * Possible values include: 'public', 'internal', 'confidential', 'highlyConfidential'
 * @readonly
 * @enum {string}
 */
export enum FieldVisibility {
  Public = 'public',
  Internal = 'internal',
  Confidential = 'confidential',
  HighlyConfidential = 'highlyConfidential',
}

/**
 * Defines values for DefaultDependencyType.
 * Possible values include: 'complex', 'startsAfterPreviousStep'
 * @readonly
 * @enum {string}
 */
export enum DefaultDependencyType {
  Complex = 'complex',
  StartsAfterPreviousStep = 'startsAfterPreviousStep',
}

/**
 * Defines values for TableType1.
 * Possible values include: 'process', 'runOnly', 'team', 'user', 'userDefined'
 * @readonly
 * @enum {string}
 */
export enum TableType1 {
  Process = 'process',
  RunOnly = 'runOnly',
  Team = 'team',
  User = 'user',
  UserDefined = 'userDefined',
}

/**
 * Defines values for GrantType2.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType2 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for GrantType3.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType3 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for GrantType4.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType4 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for GrantAction.
 * Possible values include: 'grant', 'deny'
 * @readonly
 * @enum {string}
 */
export enum GrantAction {
  Grant = 'grant',
  Deny = 'deny',
}

/**
 * Defines values for GrantType5.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType5 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for GrantType6.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType6 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for GrantType7.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType7 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Defines values for GrantAction1.
 * Possible values include: 'grant', 'deny'
 * @readonly
 * @enum {string}
 */
export enum GrantAction1 {
  Grant = 'grant',
  Deny = 'deny',
}

/**
 * Defines values for GrantType8.
 * Possible values include: 'use', 'manage'
 * @readonly
 * @enum {string}
 */
export enum GrantType8 {
  Use = 'use',
  Manage = 'manage',
}

/**
 * Contains response data for the listApps operation.
 */
export type ListAppsResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the getApp operation.
 */
export type GetAppResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the getAppAction operation.
 */
export type GetAppActionResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the getAppActionVersion operation.
 */
export type GetAppActionVersionResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the createAppActionVersion operation.
 */
export type CreateAppActionVersionResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the hellosignCallback operation.
 */
export type HellosignCallbackResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the slackCommands operation.
 */
export type SlackCommandsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the slackInteractive operation.
 */
export type SlackInteractiveResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the awaitTransaction operation.
 */
export type AwaitTransactionResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getEnv operation.
 */
export type GetEnvResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the communitySso operation.
 */
export type CommunitySsoResponse = CommunitySSO & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CommunitySSO;
    };
};

/**
 * Contains response data for the communitySsoV2 operation.
 */
export type CommunitySsoV2Response = CommunitySSOV2 & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CommunitySSOV2;
    };
};

/**
 * Contains response data for the onSes operation.
 */
export type OnSesResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the onSesSns operation.
 */
export type OnSesSnsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the snsOnHelpuUpdated operation.
 */
export type SnsOnHelpuUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the sesBounce operation.
 */
export type SesBounceResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the sesOpen operation.
 */
export type SesOpenResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getFeatures operation.
 */
export type GetFeaturesResponse = Array<FeatureState> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FeatureState[];
    };
};

/**
 * Contains response data for the reindexHelp operation.
 */
export type ReindexHelpResponse = Array<Field> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field[];
    };
};

/**
 * Contains response data for the getModuleFeaturesModules operation.
 */
export type GetModuleFeaturesModulesResponse = Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createModuleFeaturesModule operation.
 */
export type CreateModuleFeaturesModuleResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getModuleFeaturesModule operation.
 */
export type GetModuleFeaturesModuleResponse = Module & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Module;
    };
};

/**
 * Contains response data for the updateModuleFeaturesModule operation.
 */
export type UpdateModuleFeaturesModuleResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateTeamModuleAvailability operation.
 */
export type UpdateTeamModuleAvailabilityResponse = Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getModuleFeaturesModuleFeatures operation.
 */
export type GetModuleFeaturesModuleFeaturesResponse = Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the addModuleFeaturesFeatureToModule operation.
 */
export type AddModuleFeaturesFeatureToModuleResponse = Feature & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Feature;
    };
};

/**
 * Contains response data for the getModuleFeaturesModuleFeature operation.
 */
export type GetModuleFeaturesModuleFeatureResponse = Feature & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Feature;
    };
};

/**
 * Contains response data for the getModuleFeaturesFeatures operation.
 */
export type GetModuleFeaturesFeaturesResponse = Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createModuleFeaturesFeature operation.
 */
export type CreateModuleFeaturesFeatureResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getModuleFeaturesFeature operation.
 */
export type GetModuleFeaturesFeatureResponse = Feature & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Feature;
    };
};

/**
 * Contains response data for the updateModuleFeaturesFeatures operation.
 */
export type UpdateModuleFeaturesFeaturesResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateTeamFeatureAvailability operation.
 */
export type UpdateTeamFeatureAvailabilityResponse = PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getModuleFeaturesFeatureModules operation.
 */
export type GetModuleFeaturesFeatureModulesResponse = Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createProcessInAnotherTeam operation.
 */
export type CreateProcessInAnotherTeamResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the getAdminProcess operation.
 */
export type GetAdminProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the createTeam operation.
 */
export type CreateTeamResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the getTeam operation.
 */
export type GetTeamResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the updateTeam operation.
 */
export type UpdateTeamResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the deleteTeam operation.
 */
export type DeleteTeamResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the createAccessRequest operation.
 */
export type CreateAccessRequestResponse = AccessRequest & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AccessRequest;
    };
};

/**
 * Contains response data for the approveAccessRequest operation.
 */
export type ApproveAccessRequestResponse = AccessRequest & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AccessRequest;
    };
};

/**
 * Contains response data for the getApps operation.
 */
export type GetAppsResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the createApp operation.
 */
export type CreateAppResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the getTeamApp operation.
 */
export type GetTeamAppResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the deleteApp operation.
 */
export type DeleteAppResponse = ImportAction & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ImportAction;
    };
};

/**
 * Contains response data for the getTeamAppAction operation.
 */
export type GetTeamAppActionResponse = Array<App> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App[];
    };
};

/**
 * Contains response data for the getActionVersion operation.
 */
export type GetActionVersionResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the updateAppActionVersion operation.
 */
export type UpdateAppActionVersionResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the createTeamAppActionVersion operation.
 */
export type CreateTeamAppActionVersionResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the importApp operation.
 */
export type ImportAppResponse = ImportAction & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ImportAction;
    };
};

/**
 * Contains response data for the createCustomIntegration operation.
 */
export type CreateCustomIntegrationResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the getCustomIntegration operation.
 */
export type GetCustomIntegrationResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the updateCustomIntegration operation.
 */
export type UpdateCustomIntegrationResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the listActions operation.
 */
export type ListActionsResponse = Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createAction operation.
 */
export type CreateActionResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the getAction operation.
 */
export type GetActionResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the updateAction operation.
 */
export type UpdateActionResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the deleteAction operation.
 */
export type DeleteActionResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listActionConfigurationFiles operation.
 */
export type ListActionConfigurationFilesResponse = Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createActionConfigurationFile operation.
 */
export type CreateActionConfigurationFileResponse = ActionConfigurationFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionConfigurationFile;
    };
};

/**
 * Contains response data for the getActionConfigurationFile operation.
 */
export type GetActionConfigurationFileResponse = ActionConfigurationFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionConfigurationFile;
    };
};

/**
 * Contains response data for the downloadActionConfigurationFile operation.
 */
export type DownloadActionConfigurationFileResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listGlobalActions operation.
 */
export type ListGlobalActionsResponse = Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createGlobalAction operation.
 */
export type CreateGlobalActionResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the getGlobalAction operation.
 */
export type GetGlobalActionResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the updateGlobalAction operation.
 */
export type UpdateGlobalActionResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the deleteGlobalAction operation.
 */
export type DeleteGlobalActionResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listGlobalActionConfigurationFiles operation.
 */
export type ListGlobalActionConfigurationFilesResponse = Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createGlobalActionConfigurationFile operation.
 */
export type CreateGlobalActionConfigurationFileResponse = ActionConfigurationFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionConfigurationFile;
    };
};

/**
 * Contains response data for the getGlobalActionConfigurationFile operation.
 */
export type GetGlobalActionConfigurationFileResponse = ActionConfigurationFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionConfigurationFile;
    };
};

/**
 * Contains response data for the downloadGlobalActionConfigurationFile operation.
 */
export type DownloadGlobalActionConfigurationFileResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listTeamWorkers operation.
 */
export type ListTeamWorkersResponse = Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createWorker operation.
 */
export type CreateWorkerResponse = Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the invokeAction operation.
 */
export type InvokeActionResponse = ActionInvocation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionInvocation;
    };
};

/**
 * Contains response data for the getActionInvocationz operation.
 */
export type GetActionInvocationzResponse = ActionInvocation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionInvocation;
    };
};

/**
 * Contains response data for the getWorker operation.
 */
export type GetWorkerResponse = Worker & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Worker;
    };
};

/**
 * Contains response data for the updateWorker operation.
 */
export type UpdateWorkerResponse = Worker & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Worker;
    };
};

/**
 * Contains response data for the deleteWorker operation.
 */
export type DeleteWorkerResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getTasks operation.
 */
export type GetTasksResponse = Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listWorkerControls operation.
 */
export type ListWorkerControlsResponse = Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createWorkerControl operation.
 */
export type CreateWorkerControlResponse = WorkerControl & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkerControl;
    };
};

/**
 * Contains response data for the deleteWorkerControls operation.
 */
export type DeleteWorkerControlsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWorkerControl operation.
 */
export type GetWorkerControlResponse = WorkerControl & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkerControl;
    };
};

/**
 * Contains response data for the updateWorkerControl operation.
 */
export type UpdateWorkerControlResponse = WorkerControl & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkerControl;
    };
};

/**
 * Contains response data for the deleteWorkerControl operation.
 */
export type DeleteWorkerControlResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the acquireWorkerTask operation.
 */
export type AcquireWorkerTaskResponse = Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the batchAcquireTasks operation.
 */
export type BatchAcquireTasksResponse = Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listWorkers operation.
 */
export type ListWorkersResponse = Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the updateWorkerTask operation.
 */
export type UpdateWorkerTaskResponse = Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the completeWorkerTask operation.
 */
export type CompleteWorkerTaskResponse = Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getWorkerTaskFile operation.
 */
export type GetWorkerTaskFileResponse = GetWorkerTaskFileHeaders & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The parsed HTTP response headers.
       */
      parsedHeaders: GetWorkerTaskFileHeaders;
    };
};

/**
 * Contains response data for the postCommentToWorkerTask operation.
 */
export type PostCommentToWorkerTaskResponse = Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getActionAsWorker operation.
 */
export type GetActionAsWorkerResponse = Action & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Action;
    };
};

/**
 * Contains response data for the listActionConfigurationFilesAsWorker operation.
 */
export type ListActionConfigurationFilesAsWorkerResponse = Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getActionConfigurationFileAsWorker operation.
 */
export type GetActionConfigurationFileAsWorkerResponse = ActionConfigurationFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActionConfigurationFile;
    };
};

/**
 * Contains response data for the downloadActionConfigurationFileAsWorker operation.
 */
export type DownloadActionConfigurationFileAsWorkerResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the adminListUserIds operation.
 */
export type AdminListUserIdsResponse = Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the initializeWorkbenchCapabilities operation.
 */
export type InitializeWorkbenchCapabilitiesResponse = WorkbenchCapability & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapability;
    };
};

/**
 * Contains response data for the listGlobalWorkbenchCapabilities operation.
 */
export type ListGlobalWorkbenchCapabilitiesResponse = Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createWorkbenchCapability operation.
 */
export type CreateWorkbenchCapabilityResponse = WorkbenchCapability & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapability;
    };
};

/**
 * Contains response data for the getWorkbenchCapability operation.
 */
export type GetWorkbenchCapabilityResponse = WorkbenchCapability & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapability;
    };
};

/**
 * Contains response data for the updateWorkbenchCapability operation.
 */
export type UpdateWorkbenchCapabilityResponse = WorkbenchCapability & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapability;
    };
};

/**
 * Contains response data for the listGlobalWorkbenchCapabilityGrants operation.
 */
export type ListGlobalWorkbenchCapabilityGrantsResponse = Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listGlobalWorkbenchCapabilityGrantsToUser operation.
 */
export type ListGlobalWorkbenchCapabilityGrantsToUserResponse = Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listGlobalWorkbenchCapabilityGrantsToUser1 operation.
 */
export type ListGlobalWorkbenchCapabilityGrantsToUser1Response = Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createGlobalCapabilityGrant operation.
 */
export type CreateGlobalCapabilityGrantResponse = WorkbenchCapabilityGrant & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapabilityGrant;
    };
};

/**
 * Contains response data for the listTeamWorkbenchCapabilities operation.
 */
export type ListTeamWorkbenchCapabilitiesResponse = Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getTeamWorkbenchCapability operation.
 */
export type GetTeamWorkbenchCapabilityResponse = WorkbenchCapability & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapability;
    };
};

/**
 * Contains response data for the listTeamWorkbenchCapabilityGrants operation.
 */
export type ListTeamWorkbenchCapabilityGrantsResponse = Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listTeamWorkbenchCapabilityGrantsToUser operation.
 */
export type ListTeamWorkbenchCapabilityGrantsToUserResponse = Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listTeamWorkbenchCapabilityGrantsToUser1 operation.
 */
export type ListTeamWorkbenchCapabilityGrantsToUser1Response = Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listTeamWorkbenchCapabilityGrantLogs operation.
 */
export type ListTeamWorkbenchCapabilityGrantLogsResponse = PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listTeamWorkbenchCapabilityGrantLogs1 operation.
 */
export type ListTeamWorkbenchCapabilityGrantLogs1Response = Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listGlobalWorkbenchCapabilityGrantLogss operation.
 */
export type ListGlobalWorkbenchCapabilityGrantLogssResponse = PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createTeamCapabilityGrant operation.
 */
export type CreateTeamCapabilityGrantResponse = WorkbenchCapabilityGrant & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WorkbenchCapabilityGrant;
    };
};

/**
 * Contains response data for the getAuthinfo operation.
 */
export type GetAuthinfoResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the getAuth operation.
 */
export type GetAuthResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the authenticateUser operation.
 */
export type AuthenticateUserResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the communityAuthMethod operation.
 */
export type CommunityAuthMethodResponse = CommunityAuth & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CommunityAuth;
    };
};

/**
 * Contains response data for the communityAuthV2Method operation.
 */
export type CommunityAuthV2MethodResponse = CommunityAuthV2 & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CommunityAuthV2;
    };
};

/**
 * Contains response data for the getServiceProviderMetadata operation.
 */
export type GetServiceProviderMetadataResponse = SAMLServiceProviderMetadata & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SAMLServiceProviderMetadata;
    };
};

/**
 * Contains response data for the samlLogin operation.
 */
export type SamlLoginResponse = SamlLoginHeaders & {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The parsed HTTP response headers.
       */
      parsedHeaders: SamlLoginHeaders;

      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the authenticateWithSaml operation.
 */
export type AuthenticateWithSamlResponse = SAMLAuth & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SAMLAuth;
    };
};

/**
 * Contains response data for the createEncryptionKey operation.
 */
export type CreateEncryptionKeyResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the listtFiles operation.
 */
export type ListtFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the createFile operation.
 */
export type CreateFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the getFile operation.
 */
export type GetFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the updateFile operation.
 */
export type UpdateFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the deleteFile operation.
 */
export type DeleteFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the copyFile operation.
 */
export type CopyFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the downloadFile operation.
 */
export type DownloadFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the executeWebhook operation.
 */
export type ExecuteWebhookResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the executeWebformWebhook operation.
 */
export type ExecuteWebformWebhookResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the getFroups operation.
 */
export type GetFroupsResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the createGroup operation.
 */
export type CreateGroupResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the getUsersGroups operation.
 */
export type GetUsersGroupsResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the updateUserGroups operation.
 */
export type UpdateUserGroupsResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the listGroupMembers operation.
 */
export type ListGroupMembersResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the listIntegrations operation.
 */
export type ListIntegrationsResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the createIntegration operation.
 */
export type CreateIntegrationResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the integrationsHealthcheck operation.
 */
export type IntegrationsHealthcheckResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the getIntegration operation.
 */
export type GetIntegrationResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the updateIntegration operation.
 */
export type UpdateIntegrationResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the deleteIntegration operation.
 */
export type DeleteIntegrationResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the listInvites operation.
 */
export type ListInvitesResponse = Array<Invite> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Invite[];
    };
};

/**
 * Contains response data for the createInvite operation.
 */
export type CreateInviteResponse = Invite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Invite;
    };
};

/**
 * Contains response data for the getInvite operation.
 */
export type GetInviteResponse = Invite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Invite;
    };
};

/**
 * Contains response data for the createInviteToken operation.
 */
export type CreateInviteTokenResponse = Invite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Invite;
    };
};

/**
 * Contains response data for the listProcesses operation.
 */
export type ListProcessesResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the createProcess operation.
 */
export type CreateProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the getProcess operation.
 */
export type GetProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the updateProcess operation.
 */
export type UpdateProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the deleteProcess operation.
 */
export type DeleteProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the updateProcessDataRetentionPolicy operation.
 */
export type UpdateProcessDataRetentionPolicyResponse = Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getProcessAvailableFields operation.
 */
export type GetProcessAvailableFieldsResponse = PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the addWritableField operation.
 */
export type AddWritableFieldResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the removeWritableField operation.
 */
export type RemoveWritableFieldResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the listProcessFiles operation.
 */
export type ListProcessFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the createProcessFile operation.
 */
export type CreateProcessFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the updateProcessFile operation.
 */
export type UpdateProcessFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the listProcessRuns operation.
 */
export type ListProcessRunsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listProcessSteps operation.
 */
export type ListProcessStepsResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the updateProcessSteps operation.
 */
export type UpdateProcessStepsResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the createProcessStep operation.
 */
export type CreateProcessStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the getStepMetrics operation.
 */
export type GetStepMetricsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getProcessStep operation.
 */
export type GetProcessStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the updateProcessStep operation.
 */
export type UpdateProcessStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the deleteProcessStep operation.
 */
export type DeleteProcessStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the listProcessStepFiles operation.
 */
export type ListProcessStepFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the addProcessStepFile operation.
 */
export type AddProcessStepFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the updateProcessStepFile operation.
 */
export type UpdateProcessStepFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the getProcessStepTest operation.
 */
export type GetProcessStepTestResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createProcessStepTest operation.
 */
export type CreateProcessStepTestResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listProcessTriggers operation.
 */
export type ListProcessTriggersResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the listProcessTables operation.
 */
export type ListProcessTablesResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the linkProcessTable operation.
 */
export type LinkProcessTableResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the unlinkProcessTable operation.
 */
export type UnlinkProcessTableResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the listProcessPredictors operation.
 */
export type ListProcessPredictorsResponse = Predictor & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Predictor;
    };
};

/**
 * Contains response data for the linkProcessPredictor operation.
 */
export type LinkProcessPredictorResponse = Predictor & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Predictor;
    };
};

/**
 * Contains response data for the unlinkProcessPredictor operation.
 */
export type UnlinkProcessPredictorResponse = Predictor & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Predictor;
    };
};

/**
 * Contains response data for the listChildProcesses operation.
 */
export type ListChildProcessesResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the linkChildProcess operation.
 */
export type LinkChildProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the unlinkChildProcess operation.
 */
export type UnlinkChildProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the listParentProcesses operation.
 */
export type ListParentProcessesResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the linkParentProcess operation.
 */
export type LinkParentProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the unlinkParentProcess operation.
 */
export type UnlinkParentProcessResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the evaluateStepOutputFields operation.
 */
export type EvaluateStepOutputFieldsResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the addWritableFieldToStep operation.
 */
export type AddWritableFieldToStepResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the removeWritableFieldFromStep operation.
 */
export type RemoveWritableFieldFromStepResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the getCustomAction operation.
 */
export type GetCustomActionResponse = App & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: App;
    };
};

/**
 * Contains response data for the createCustomAction operation.
 */
export type CreateCustomActionResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the updateCustomAction operation.
 */
export type UpdateCustomActionResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the deleteCustomAction operation.
 */
export type DeleteCustomActionResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the listProcessWebhooks operation.
 */
export type ListProcessWebhooksResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the getProcessVisibility operation.
 */
export type GetProcessVisibilityResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the listUpcomings operation.
 */
export type ListUpcomingsResponse = Upcoming & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Upcoming;
    };
};

/**
 * Contains response data for the createUpcoming operation.
 */
export type CreateUpcomingResponse = Upcoming & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Upcoming;
    };
};

/**
 * Contains response data for the getProcessUpcoming operation.
 */
export type GetProcessUpcomingResponse = Upcoming & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Upcoming;
    };
};

/**
 * Contains response data for the updateProcessUpcoming operation.
 */
export type UpdateProcessUpcomingResponse = Upcoming & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Upcoming;
    };
};

/**
 * Contains response data for the deleteProcessUpcoming operation.
 */
export type DeleteProcessUpcomingResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listProcessFields operation.
 */
export type ListProcessFieldsResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the createProcessField operation.
 */
export type CreateProcessFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the getProcessField operation.
 */
export type GetProcessFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the updateProcessField operation.
 */
export type UpdateProcessFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the deleteProcessField operation.
 */
export type DeleteProcessFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the listProcessRights operation.
 */
export type ListProcessRightsResponse = ProcessRights & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProcessRights;
    };
};

/**
 * Contains response data for the createProcessExport operation.
 */
export type CreateProcessExportResponse = ProcessExport & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProcessExport;
    };
};

/**
 * Contains response data for the getProcessExport operation.
 */
export type GetProcessExportResponse = ProcessExport & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProcessExport;
    };
};

/**
 * Contains response data for the createProcessImport operation.
 */
export type CreateProcessImportResponse = ProcessImport & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProcessImport;
    };
};

/**
 * Contains response data for the getProcessImport operation.
 */
export type GetProcessImportResponse = ProcessImport & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProcessImport;
    };
};

/**
 * Contains response data for the listRuns operation.
 */
export type ListRunsResponse = Run & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Run;
    };
};

/**
 * Contains response data for the createRun operation.
 */
export type CreateRunResponse = Run & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Run;
    };
};

/**
 * Contains response data for the getRun operation.
 */
export type GetRunResponse = Run & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Run;
    };
};

/**
 * Contains response data for the updateRun operation.
 */
export type UpdateRunResponse = Run & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Run;
    };
};

/**
 * Contains response data for the getRunVisibility operation.
 */
export type GetRunVisibilityResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the getLog operation.
 */
export type GetLogResponse = Run & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Run;
    };
};

/**
 * Contains response data for the listRunComments operation.
 */
export type ListRunCommentsResponse = Comment & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Comment;
    };
};

/**
 * Contains response data for the createRunComment operation.
 */
export type CreateRunCommentResponse = Comment & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Comment;
    };
};

/**
 * Contains response data for the listRunFiles operation.
 */
export type ListRunFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the createRunFile operation.
 */
export type CreateRunFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the updateRunFile operation.
 */
export type UpdateRunFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the listRunFields operation.
 */
export type ListRunFieldsResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the createRunField operation.
 */
export type CreateRunFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the getRunField operation.
 */
export type GetRunFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the updateRunField operation.
 */
export type UpdateRunFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the deleteRunField operation.
 */
export type DeleteRunFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the getRunProgress operation.
 */
export type GetRunProgressResponse = Run & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Run;
    };
};

/**
 * Contains response data for the reenqueueRunTasks operation.
 */
export type ReenqueueRunTasksResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the listRunTasks operation.
 */
export type ListRunTasksResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the updateRunTasks operation.
 */
export type UpdateRunTasksResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the createRunTask operation.
 */
export type CreateRunTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the getRunTask operation.
 */
export type GetRunTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the updateRunTask operation.
 */
export type UpdateRunTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the deleteRunTask operation.
 */
export type DeleteRunTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the listRunTaskFiles operation.
 */
export type ListRunTaskFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the createRunTaskFile operation.
 */
export type CreateRunTaskFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the updateRunTaskFile operation.
 */
export type UpdateRunTaskFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the listRunTaskWebhooks operation.
 */
export type ListRunTaskWebhooksResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the completeRunTask operation.
 */
export type CompleteRunTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the searchV1 operation.
 */
export type SearchV1Response2 = SearchV1Response & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SearchV1Response;
    };
};

/**
 * Contains response data for the searchV2 operation.
 */
export type SearchV2Response2 = SearchV2Response & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SearchV2Response;
    };
};

/**
 * Contains response data for the usersSearch operation.
 */
export type UsersSearchResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the processesSearch operation.
 */
export type ProcessesSearchResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getStep operation.
 */
export type GetStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the updateStep operation.
 */
export type UpdateStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the deleteStep operation.
 */
export type DeleteStepResponse = Step & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Step;
    };
};

/**
 * Contains response data for the updateWritableField operation.
 */
export type UpdateWritableFieldResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the deleteWritableField operation.
 */
export type DeleteWritableFieldResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the evaluateStepOutputs operation.
 */
export type EvaluateStepOutputsResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the listTasks operation.
 */
export type ListTasksResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the createTask operation.
 */
export type CreateTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the getTask operation.
 */
export type GetTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the updateTask operation.
 */
export type UpdateTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the completeTask operation.
 */
export type CompleteTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the openTask operation.
 */
export type OpenTaskResponse = Task & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Task;
    };
};

/**
 * Contains response data for the reopenTask operation.
 */
export type ReopenTaskResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the listTaskFiles operation.
 */
export type ListTaskFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the createTaskFile operation.
 */
export type CreateTaskFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the updateTaskFile operation.
 */
export type UpdateTaskFileResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the listTaskWebhooks operation.
 */
export type ListTaskWebhooksResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the getTaskFields operation.
 */
export type GetTaskFieldsResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the createTaskField operation.
 */
export type CreateTaskFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the getTaskField operation.
 */
export type GetTaskFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the updateTaskField operation.
 */
export type UpdateTaskFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the deleteTaskField operation.
 */
export type DeleteTaskFieldResponse = Field & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Field;
    };
};

/**
 * Contains response data for the listTables operation.
 */
export type ListTablesResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createTable operation.
 */
export type CreateTableResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the indexMetaTables operation.
 */
export type IndexMetaTablesResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the importTable operation.
 */
export type ImportTableResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the getTable operation.
 */
export type GetTableResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the updateTable operation.
 */
export type UpdateTableResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the exportTable operation.
 */
export type ExportTableResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the createTableColumn operation.
 */
export type CreateTableColumnResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getTableColumn operation.
 */
export type GetTableColumnResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateTableColumn operation.
 */
export type UpdateTableColumnResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the deleteTableColumn operation.
 */
export type DeleteTableColumnResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the appendRowToTable operation.
 */
export type AppendRowToTableResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the listTableParentProcesses operation.
 */
export type ListTableParentProcessesResponse = Process & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Process;
    };
};

/**
 * Contains response data for the replaceTable operation.
 */
export type ReplaceTableResponse = Table & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Table;
    };
};

/**
 * Contains response data for the listTableRows operation.
 */
export type ListTableRowsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createTableRow operation.
 */
export type CreateTableRowResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getTableRow operation.
 */
export type GetTableRowResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the deleteTableRow operation.
 */
export type DeleteTableRowResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createTableRowField operation.
 */
export type CreateTableRowFieldResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getTableRowField operation.
 */
export type GetTableRowFieldResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateTableRowField operation.
 */
export type UpdateTableRowFieldResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the deleteTableRowField operation.
 */
export type DeleteTableRowFieldResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listUserTokens operation.
 */
export type ListUserTokensResponse = Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the createUserToken operation.
 */
export type CreateUserTokenResponse = UserToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserToken;
    };
};

/**
 * Contains response data for the getUserToken operation.
 */
export type GetUserTokenResponse = UserToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserToken;
    };
};

/**
 * Contains response data for the approveUserToken operation.
 */
export type ApproveUserTokenResponse = UserToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserToken;
    };
};

/**
 * Contains response data for the pollUserToken operation.
 */
export type PollUserTokenResponse = UserToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserToken;
    };
};

/**
 * Contains response data for the revokeUserToken operation.
 */
export type RevokeUserTokenResponse = UserToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserToken;
    };
};

/**
 * Contains response data for the sendTestMessage operation.
 */
export type SendTestMessageResponse = Message & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Message;
    };
};

/**
 * Contains response data for the listTriggers operation.
 */
export type ListTriggersResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the triggerHealthcheck operation.
 */
export type TriggerHealthcheckResponse = Team & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Team;
    };
};

/**
 * Contains response data for the getUpcoming operation.
 */
export type GetUpcomingResponse = Upcoming & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Upcoming;
    };
};

/**
 * Contains response data for the updateUpcoming operation.
 */
export type UpdateUpcomingResponse = Upcoming & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Upcoming;
    };
};

/**
 * Contains response data for the deleteUpcoming operation.
 */
export type DeleteUpcomingResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listUsers operation.
 */
export type ListUsersResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the createUser operation.
 */
export type CreateUserResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the getUser operation.
 */
export type GetUserResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the updateUser operation.
 */
export type UpdateUserResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the deleteUser operation.
 */
export type DeleteUserResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the authenticateUserGet operation.
 */
export type AuthenticateUserGetResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the authenticateTeamUser operation.
 */
export type AuthenticateTeamUserResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the unlockUserLogin operation.
 */
export type UnlockUserLoginResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the logout operation.
 */
export type LogoutResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listUserFiles operation.
 */
export type ListUserFilesResponse = File & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: File;
    };
};

/**
 * Contains response data for the listUserGroups operation.
 */
export type ListUserGroupsResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the listUserIntegrations operation.
 */
export type ListUserIntegrationsResponse = User & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: User;
    };
};

/**
 * Contains response data for the listUserNotifications operation.
 */
export type ListUserNotificationsResponse = Notification & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Notification;
    };
};

/**
 * Contains response data for the getUserNotification operation.
 */
export type GetUserNotificationResponse = Notification & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Notification;
    };
};

/**
 * Contains response data for the updateUserNotification operation.
 */
export type UpdateUserNotificationResponse = Notification & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Notification;
    };
};

/**
 * Contains response data for the markUserNotificationsRead operation.
 */
export type MarkUserNotificationsReadResponse = Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the listRecentLogins operation.
 */
export type ListRecentLoginsResponse = UserLogins & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserLogins;
    };
};

/**
 * Contains response data for the listFavorites operation.
 */
export type ListFavoritesResponse = FavoritesList & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FavoritesList;
    };
};

/**
 * Contains response data for the getFavoriteStatus operation.
 */
export type GetFavoriteStatusResponse = Favorite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Favorite;
    };
};

/**
 * Contains response data for the updateFavoriteStatus operation.
 */
export type UpdateFavoriteStatusResponse = Favorite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Favorite;
    };
};

/**
 * Contains response data for the listWebhooks operation.
 */
export type ListWebhooksResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the createTeamWebhook operation.
 */
export type CreateTeamWebhookResponse = Webhook & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Webhook;
    };
};

/**
 * Contains response data for the getTeamWebhook operation.
 */
export type GetTeamWebhookResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateWebhook operation.
 */
export type UpdateWebhookResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deleteWebhook operation.
 */
export type DeleteWebhookResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getWebhook operation.
 */
export type GetWebhookResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the createWebhook operation.
 */
export type CreateWebhookResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getProcessMetrics operation.
 */
export type GetProcessMetricsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getTeamModules operation.
 */
export type GetTeamModulesResponse = Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getTeamModule operation.
 */
export type GetTeamModuleResponse = Module & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Module;
    };
};

/**
 * Contains response data for the getTeamModuleFeatures operation.
 */
export type GetTeamModuleFeaturesResponse = Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getTeamFeatures operation.
 */
export type GetTeamFeaturesResponse = Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getTeamFeature operation.
 */
export type GetTeamFeatureResponse = Feature & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Feature;
    };
};

/**
 * Contains response data for the getTeamFeatureModules operation.
 */
export type GetTeamFeatureModulesResponse = Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getTeamFrequentlyUsedActions operation.
 */
export type GetTeamFrequentlyUsedActionsResponse = Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the getTeamRecentlyUsedActions operation.
 */
export type GetTeamRecentlyUsedActionsResponse = Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema;
    };
};

/**
 * Contains response data for the putTeamsTeamnameProcessesProcessidFormFieldsFieldname operation.
 */
export type PutTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the deleteTeamsTeamnameProcessesProcessidFormFieldsFieldname
 * operation.
 */
export type DeleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};
