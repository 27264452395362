import { fieldArrayToJSONSchema } from '@catalytic/json-schema-util-from-field';
import { useInstanceStep, useCatalyticClient } from '@catalytic/react-sdk';
import { jsonSchemaToView } from '@catalytic/view-util-from-json-schema';
import { FormData, FormValue, FormSubmit } from '../form/form.interfaces';
import { useEffect, useState } from 'react';
import { useFormBinding } from '../form-binding/form-binding.hooks';
import { UseForm, UseFormState } from '../form/form.hooks';

export const useStepForm: UseForm = ({ defaultData, disabled, id, overrideData, configuration }) => {
  const [formData, setFormData] = useState<FormData>()
  const [submit, setSubmit] = useState<FormSubmit>()
  const { instance: catalytic } = useCatalyticClient();
  const boundData = useFormBinding({ data: formData, configuration });
  const [state, setState] = useState<UseFormState>({
    loading: true
  })
  const step = useInstanceStep(id)

  useEffect(
    () => {
      if (!step?.data?.outputFields) return;
      let schema = fieldArrayToJSONSchema(step.data.outputFields);
      schema.default = {
        ...defaultData?.value,
        ...schema.default as any,
        ...overrideData?.value
      };

      if (schema.properties) {
        schema.properties = step.data.outputFields.reduce(
          (properties, field) => {
            if (field.referenceName && properties[field.referenceName] && field.conditionCode) {
              properties[field.referenceName].conditionalDependencies = field.conditionCode
            }
            return properties
          },
          schema.properties
        )
      }

      let value = step.data.outputFields.reduce(
        (o, { referenceName, value }) => {
          if (value !== undefined) {
            o[referenceName || ''] = value;
          }
          return o;
        },
        {
          ...schema.default as FormValue,
        } as FormValue
      )
      let node = jsonSchemaToView(schema);

      const submit = async (nextValue: FormValue): Promise<FormValue> => {
        const value = {
          ...defaultData?.value,
          ...nextValue
        };
        const fields: any[] = Object.entries(value).map(
          ([name, value]) => ({
            name,
            value: (value as any)?.toString()
          })
        )
        await catalytic.instances.completeInstanceStep(
          id,
          fields
        )
        return value;
      };

      setSubmit(() => submit);

      setFormData({
        node,
        schema,
        value
      });
    },
    [step?.data?.outputFields]
  )

  useEffect(
    () => {
      if (boundData.loading) return;
      setState({
        disabled: typeof disabled === 'function' ? disabled((boundData as any).data) : disabled,
        data: boundData.data,
        loading: false,
        submit
      })
    },
    [boundData.data, boundData.loading, submit, disabled]
  )

  return state;
}
