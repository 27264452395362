import { Box, Button, RadioButtonGroup } from 'grommet';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { applyButtonStyle } from '../../style/style.button';
import { createStyleFactory } from '../../style/style.factories';
import { applyInputStyle } from '../../style/style.input';
import { mergeKind } from '../../style/style.utils';

const defaultStyle = createStyleFactory({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.views?.checkbox?.button?.default
})

const checkedStyle = createStyleFactory({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.views?.checkbox?.button?.checked,
  test: ({ primary }) => !!primary
})

const uncheckedStyle = createStyleFactory({
  applyStyle: applyButtonStyle,
  getKind: ({ theme }) => theme?.views?.checkbox?.button?.unchecked,
  test: ({ secondary }) => !!secondary
})

export const StyledCheckBoxButton = styled(Button)`
  ${defaultStyle}
  ${checkedStyle}
  ${uncheckedStyle}
  align-self: flex-start;
`;

const defaultInputStyle = createStyleFactory({
  applyStyle: applyInputStyle,
  getKind: ({ theme }) =>
    mergeKind(theme?.views?.checkbox?.input, theme?.components?.input)
})

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  ${defaultInputStyle}
`;
