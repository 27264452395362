import { InstanceStepsPage } from '@catalytic/sdk';
import { FindInstanceStepsOptions } from '@catalytic/sdk/dist/src/clients/InstanceClient';
import { paramCase } from 'change-case';
import { DependencyList, useEffect, useState } from 'react';
import { useCatalyticClient } from '../catalytic-client/catalytic-client.hooks';
import { useOrchestrationClient } from '../orchestration-client/orchestration-client.hooks';
import { InstanceStep } from './instance-step.interfaces';
import { findSteps, isEmailWebFormTask } from './instance-step.utils';
import { UseClient, UseClientState } from '../client/client.hooks';

export const useInstanceStep: UseClient<InstanceStep> = (id?: string) => {
  const { instance: catalytic } = useCatalyticClient();
  const { instance: orchestration } = useOrchestrationClient();
  const [state, setState] = useState<UseClientState<InstanceStep>>({
    loading: true
  });

  useEffect(
    () => {
      if (!id) return;
      const init = async () => {
        try {
          const step: InstanceStep = await catalytic.instances.getInstanceStep(id);
          orchestration.updateTable
          const task = await orchestration.getTask(id);
          const { fields = [] } = await orchestration.getRun(step.instanceId, { includeChildren: true })

          // Manually Add App Step
          step.appStep = task.appStep;
          // Manually Add End Date
          step.endDate = task.endDate
          // Manually Add Start Date
          step.startDate = task.startDate

          step.outputFields = step.outputFields?.map(
            field => {
              const f = fields.find(f => f.fieldName === field.referenceName);

              // Manually Correct Instruction Field Type
              if ((f?.restrictions as any)?.displayOnly) {
                field.fieldType = 'instructions';
              }
              // Manually Add Field Conditions
              field.conditionCode = f?.conditionCode;
              // Manually Correct the Field Position
              const position = field.referenceName ? task.writableFields?.indexOf(field.referenceName) || -1 : -1;
              field.position = position >= 0 ? position : field.position;
              return field
            }
          )

          if (isEmailWebFormTask(step)) {
            // Manually Remove Description
            step.description = undefined;
            // Manually Remove Email Step Fields
            if (typeof step.appStep?.params?.[0]?.outputFieldName === 'string') {
              const prefix = paramCase(step.appStep?.params?.[0]?.outputFieldName);
              step.outputFields = step.outputFields?.filter(
                field => !field.referenceName?.startsWith(prefix)
              )
            }
          }

          setState({
            loading: false,
            data: step
          })
        } catch (e) {
          setState({
            loading: false,
            errors: [e]
          })
        }
      };
      init();
    },
    [id]
  )
  return state
};

export type UseInstanceStepListProps = {
  id?: string,
  dependencies?: DependencyList,
  recursive?: boolean
}

export function useInstanceStepList({ id, dependencies = [], recursive }: UseInstanceStepListProps): InstanceStep[] | undefined {
  const { instance: catalytic } = useCatalyticClient();
  const { instance: orchestration } = useOrchestrationClient();

  const [stepList, setStepList] = useState<InstanceStep[]>();
  useEffect(
    () => {
      if (!id) return;
      const init = async () => {
        try {
          setStepList(await findSteps({ id, recursive, catalytic, orchestration }))
        } catch (e) {
          console.error(e);
        }
      };
      init();
    },
    [id, ...dependencies]
  )
  return stepList
};

export type FindInstanceStepsPage = InstanceStepsPage & {
  loadMore: () => void
}

export function useFindInstanceSteps(options: FindInstanceStepsOptions): FindInstanceStepsPage | undefined {
  const { instance: catalytic } = useCatalyticClient();
  const [token, setToken] = useState<string>();
  const [page, setPage] = useState<FindInstanceStepsPage>();
  useEffect(
    () => {
      const init = async () => {
        try {
          const results = await catalytic.instances.findInstanceSteps({
            ...options,
            pageToken: page?.nextPageToken
          })
          setPage({
            ...results,
            loadMore: () => {
              setToken(page?.nextPageToken)
            }
          });
        } catch (e) {
          console.error(e);
        }
      };
      init();
    },
    [token]
  )
  return page
};
