import {
  Alias,
  Clause,
  DirectionType,
  Expression,
  Func,
  Identifier,
  Limit,
  Literal,
  Map,
  Node,
  Operation,
  OperatorType,
  Order,
  Range,
  Result,
  Select,
} from "@catalytic/query";
import {
  isExpression,
  isFunction,
  isIdentifier,
  isLiteral,
  isOperation,
  isRange,
  isSelect,
} from "@catalytic/query-util-is";
import { SQL_DIRECTION_MAP, SQL_OPERATOR_MAP } from "./transform.interfaces";

export function fromLiteral({ value }: Literal): string {
  if (value === null) return "NULL";
  const type = typeof value;
  switch (type) {
    case "string":
      return `'${value}'`;
    case "undefined":
      return "undefined";
    default:
      return JSON.stringify(value);
  }
}

const DELIMITER = /\./g;
const WRAPPED = /^(\w+)?\[/;
const WHITE_SPACE = /\W/;

export const joinName = (left: string, right: string): string => {
  return !right.trim().length
    ? left
    : right.startsWith("[")
    ? `${left}${right}`
    : right.startsWith(`${left}.`) ||
      right.startsWith(`${left}[`) ||
      !left.trim().length
    ? right
    : `${left}.${right}`;
};

export const fromName = (name: string, base: Identifier[] = []): string =>
  !!base.find(
    (b) => name.startsWith(`${b.name}.`) || name.startsWith(`${b.name}[`)
  )
    ? name
    : name.match(WRAPPED)
    ? name
    : DELIMITER.test(name)
    ? name
        .split(DELIMITER)
        .map((name) => fromName(name))
        .reduce((left, right) => joinName(left, right), "")
    : !WHITE_SPACE.test(name)
    ? name
    : base.length
    ? `${base[0].name}['${name}']`
    : `['${name}']`;

export function fromAlias(value: string, n: Alias): string {
  return n.alias ? `${value} AS ${n.alias}` : value;
}

export function fromIdentifier(n: Identifier, base: Identifier[] = []): string {
  const name = fromName(n.name, base);
  const b = base.find(
    (b) => name.startsWith(`${b.name}.`) || name.startsWith(`${b.name}[`)
  );
  const identifier =
    !b && base.length
      ? joinName(fromIdentifier(base[base.length - 1]), name)
      : name;
  return fromAlias(identifier, n);
}

export function fromOperatorType(n: OperatorType): string {
  return SQL_OPERATOR_MAP[n];
}

export function fromOperation(n: Operation, base?: Identifier[]): string {
  return `${fromClause(n.left, base)} ${fromOperatorType(
    n.operation
  )} ${fromClause(n.right, base)}`;
}

export function fromFunction(n: Func, base?: Identifier[]): string {
  return fromAlias(`${n.name.name}${fromExpression(n.args, base)}`, n);
}

export function fromRange(n: Range, base?: Identifier[]): string {
  return `${fromClause(n.start, base)} AND ${fromClause(n.end, base)}`;
}

export function fromClause(n: Clause, base?: Identifier[], p?: Node): string {
  if (isExpression(n)) return fromExpression(n);
  if (isFunction(n)) return fromFunction(n, base);
  if (isLiteral(n)) return fromLiteral(n);
  if (isIdentifier(n)) return fromIdentifier(n, base);
  if (isOperation(n)) return fromOperation(n, base);
  if (isRange(n)) return fromRange(n, base);
  if (isSelect(n))
    return p && isExpression(p)
      ? fromSelect(n, base)
      : `(${fromSelect(n, base)})`;
  throw `Unsupported clause type ${n.type}`;
}

export function fromExpression(n: Expression, base?: Identifier[]): string {
  const args = n.expression
    .map((clause) => fromClause(clause, base, n))
    .join(", ");
  return `(${args})`;
}

export function fromLimit(n: Limit, base?: Identifier[]): string {
  const query: string[] = [];
  if (n.offset) {
    query.push(`OFFSET ${fromClause(n.offset, base)}`);
  }
  if (n.length) {
    if (!n.offset) {
      query.push(`OFFSET 0`);
    }
    query.push(`LIMIT ${fromClause(n.length, base)}`);
  }
  return query.join(" ");
}

export function fromDirectionType(direction: DirectionType): string {
  return SQL_DIRECTION_MAP[direction];
}

export function fromOrder(n: Order, base?: Identifier[]): string {
  return `${fromClause(n.expression, base)} ${fromDirectionType(n.direction)}`;
}

export function fromMap(n: Map, base?: Identifier[]): string {
  const result = `${n.variant} ${fromClause(n.source, base)}`;
  return n.map
    ? n.map.reduce((result, n) => {
        return `${result} ${fromMap(n, base)}`;
      }, result)
    : result;
}

export function fromResult(n: Result, base?: Identifier[]): string {
  const result =
    n.distinct && n.value
      ? "DISTINCT VALUE "
      : n.distinct
      ? "DISTINCT "
      : n.value
      ? "VALUE "
      : "";
  return `${result}${fromClause(n.source, base)}`;
}

export function fromSelect(n: Select, base: Identifier[] = []): string {
  const nextBase = base.slice();
  //if (n.from?.source && isIdentifier(n.from.source))
  //nextBase.push(n.from.source);
  const fromIdentifier = nextBase[nextBase.length - 1];
  const limit = n.limit ? fromLimit(n.limit, nextBase) : undefined;
  const result = n.from
    ? fromMap(n.from)
    : fromIdentifier
    ? `FROM ${fromClause(fromIdentifier)}`
    : undefined;
  const order = n.order
    ? `ORDER BY ${n.order
        .map((order) => fromOrder(order, nextBase))
        .join(", ")}`
    : undefined;
  const select = n.result
    ? `SELECT ${n.result
        .map((result) => fromResult(result, nextBase))
        .join(", ")}`
    : "SELECT *";
  const where = n.where
    ? `WHERE ${n.where
        .map((operation) => fromClause(operation, nextBase))
        .join(" OR ")}`
    : undefined;
  return fromAlias(
    [select, result, where, order, limit]
      .filter((term) => term !== undefined)
      .join(" "),
    n
  );
}
