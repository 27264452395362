import React from 'react';
import { types } from '@catalytic/react-view';
import { Text } from 'grommet';
import { FormField } from '../../components/FormField';
import { StyledToggleSwitchButton, StyledRadioButtonGroup } from './ToggleSwitchView.styled';

export function ToggleSwitchViewEditor(props: types.ToggleSwitchView) {
  return <StyledRadioButtonGroup
    disabled={props.node().disabled}
    name={props.node().reference as any}
    direction="row"
    gap="xsmall"
    options={[
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]}
    value={(props.value ?? props.defaultValue() ?? '').toString()}
  >
    {(option: any) => {
      const isChecked = option.value === props.value?.toString();
      return (
        <StyledToggleSwitchButton
          label={option.label}
          primary={isChecked}
          secondary={!isChecked}
          onClick={
            (e: any) => {
              props.setValue(!isChecked ? option.value : undefined)
            }
          } />
      );
    }}
  </StyledRadioButtonGroup>
}

export function ToggleSwitchViewReader(props: types.ToggleSwitchView) {
  return <Text>{props.value === true ? 'Yes' : props.value === false ? 'No' : ''}</Text>
}

export function ToggleSwitchView(props: types.ToggleSwitchView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <ToggleSwitchViewReader {...props} />
      : <ToggleSwitchViewEditor {...props} />}
  </FormField>
}
