import React from 'react';
import { ConnectedFormProps } from '../../form/form.hooks';
import { useDataTableForm } from '../../data-table-form/data-table-form.hooks';
import { ConnectedForm } from '../Form/Form';

export type DataTableFormProps = ConnectedFormProps;

export function DataTableForm({ formProps, ...props }: DataTableFormProps) {
  const state = useDataTableForm(props);
  return <ConnectedForm {...{ ...formProps, ...state }} />
}
