import { Grommet, GrommetProps, ThemeContext, ThemeType } from 'grommet';
import React, { PropsWithChildren } from 'react';

export type ThemeProviderProps = PropsWithChildren<GrommetProps>;

export const ThemeProvider = (props: ThemeProviderProps) =>
  <Grommet {...props} />

export const ThemeContextExtend = (props: ThemeType) =>
  <ThemeContext.Extend value={props} />
