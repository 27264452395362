import * as date from './date';
import * as legacy from './legacy';
import { create as createExpression, ExpressionFactory } from './factory';
export {
  Context,
  ExpressionContext,
  Expression,
  ExpressionFactory
} from './factory';

export const create: ExpressionFactory = createExpression([date, legacy]);
