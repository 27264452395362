import { useCatalyticClient } from '@catalytic/react-sdk';
import { types } from '@catalytic/react-view';
import { FileDescriptor } from '@catalytic/sdk';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useFormQueue } from '../../queue/queue.hooks';
import { FileMetadata } from '../../file/file.interfaces';

export function useFileUploadHandler<T = HTMLInputElement>(props: types.FileReferenceView): ChangeEventHandler<T> {
  const { instance: catalytic } = useCatalyticClient();
  const { queue } = useFormQueue();
  return useCallback<ChangeEventHandler<T>>(
    (e: any) => {
      const files = e.target?.files || e.dataTransfer?.files || e.dataTransfer?.items;
      const upload = async () => {
        const vfiles: FileDescriptor[] = await Promise.all(Array.from(files).map(
          async (file: File) => {
            return {
              contents: file as any,
              path: file.name,
            } as FileDescriptor
          }
        ))

        const [data] = await catalytic.files.bulkUpload(vfiles);
        props.setValue(data.id);
      }
      queue.add(upload);
    },
    [catalytic, props.setValue]
  )
}

export function useFileMetadata(props: types.FileReferenceView): FileMetadata | undefined {
  const { instance: catalytic } = useCatalyticClient();
  const [file, setFile] = useState<FileMetadata>();
  useEffect(
    () => {
      const v = props.value
      if (v === undefined) return;
      const getFile = async () => {
        try {
          const file: FileMetadata = await catalytic.files.get(v);
          file.url = `https://${catalytic.accessToken.domain}/api/files/${v}/download`;
          setFile(file)
        } catch (e) { }
      }
      getFile();
    },
    [catalytic, props.value]
  )
  return file;
}
