import { User } from '@catalytic/sdk';
import { useState, useEffect } from 'react';
import { useCatalyticClient } from '../catalytic-client/catalytic-client.hooks';
import { UseClient, UseClientState } from '../client/client.hooks';

export const useUser: UseClient = (id: string | undefined) => {
  const { instance } = useCatalyticClient();
  const [state, setState] = useState<UseClientState<User>>({
    loading: true
  });

  useEffect(
    () => {
      if (!id) return
      const load = async () => {
        try {
          const data = await instance.users.get(id);
          setState({
            loading: false,
            data
          })
        } catch (e) {
          setState({
            loading: false,
            errors: [e]
          })
        }
      }
      load();
    },
    [id]
  )

  return state
}
