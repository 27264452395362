import { ViewMap } from '@catalytic/react-view';
import { CheckboxViewEditor } from '../../views/CheckboxView/CheckboxView';
import { DateTimeViewEditor } from '../../views/DateTimeView/DateTimeView';
import { DateViewEditor } from '../../views/DateView/DateView';
import { EmailViewEditor } from '../../views/EmailView/EmailView';
import { FileReferenceViewEditor } from '../../views/FileReferenceView/FileReferenceView';
import { IntegerViewEditor } from '../../views/IntegerView/IntegerView';
import { MultipleSelectionViewEditor } from '../../views/MultipleSelectionView/MultipleSelectionView';
import { NumberViewEditor } from '../../views/NumberView/NumberView';
import { DefaultReferenceView } from '../../views/ReferenceView/ReferenceView';
import { SelectionViewEditor } from '../../views/SelectionView/SelectionView';
import { TextViewEditor } from '../../views/TextView/TextView';
import { ToggleSwitchViewEditor } from '../../views/ToggleSwitchView/ToggleSwitchView';

export const EditorViewMap: ViewMap = {
  checkboxView: CheckboxViewEditor,
  dateView: DateViewEditor,
  dateTimeView: DateTimeViewEditor,
  emailView: EmailViewEditor,
  fileReferenceView: FileReferenceViewEditor,
  integerView: IntegerViewEditor,
  multipleSelectionView: MultipleSelectionViewEditor,
  numberView: NumberViewEditor,
  referenceView: DefaultReferenceView,
  selectionView: SelectionViewEditor,
  textView: TextViewEditor,
  textAreaView: TextViewEditor as any,
  toggleSwitchView: ToggleSwitchViewEditor
};
