import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { types, View } from '@catalytic/react-view';
import { Box, Button, DataTable, CheckBox, ColumnConfig, Footer, Form, Layer, MouseClick } from 'grommet';
import { Add, Edit, Trash } from 'grommet-icons';
import { fromJSONDictionary } from '../../util/toJSONValue'
import { useCollectionView } from '../CollectionView';
import { rejectNull } from '@catalytic/reject';

type SubmitActionEvent = React.FormEvent & {
  focused?: object
}

interface SubmitAction {
  (e: SubmitActionEvent): void
}

interface Action {
  handler: SubmitAction,
  primary: string
}

export function GalleryView(props: types.GalleryView) {

  const { append, blur, deleteSelected, deselectAll, focus, focused, selected, rowViewProps, selectAll, toggleSelectOne, updateSelected, value } = useCollectionView(props as any);
  const [open, setOpen] = React.useState<boolean>(false);
  const [columns, setColumns] = useState<ColumnConfig<any>[]>([])
  const [action, setAction] = useState<Action>()
  const disabled = props.node().disabled;

  useEffect(
    () => {
      // TODO Migrate this to collection view properties
      const properties = props.schema()?.items?.properties;

      if (!properties) return;

      const onCheckAll = (e: ChangeEvent<any>) => {
        if (e.target.checked) {
          selectAll()
        } else {
          deselectAll()
        }
      }

      const columns: ColumnConfig<any>[] = [
        {
          property: '_id',
          size: "48px",
          render: datum => (
            <CheckBox
              key={datum._id}
              checked={selected.indexOf(datum._id) !== -1}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onChange={(_e: ChangeEvent) => toggleSelectOne(datum._id)}
            />
          ),
          header: (
            <CheckBox
              checked={!!value.length && selected.length === value.length}
              indeterminate={
                selected.length > 0 && selected.length < value.length
              }
              onChange={onCheckAll}
            />
          ),
          sortable: false
        },
        ...Object.entries(properties).map(
          ([property, schema]) => {
            return {
              property,
              header: schema.title
            } as ColumnConfig<any>;
          }
        )
      ]

      setColumns(columns)
    },
    [value, selected]
  )

  const handleAdd = (e: React.FormEvent) => {
    append(fromJSONDictionary((e as any).value, rowViewProps?.schema))
  }

  const handleBulkDelete = () => {
    deleteSelected();
  }

  const handleBulkEdit = (e: SubmitActionEvent) => {
    const nextRow = rejectNull(fromJSONDictionary((e as any).value, rowViewProps?.schema) as any);
    const focused: any = e.focused;
    updateSelected(nextRow, focused && focused._id ? [focused._id] : selected)
  }

  const onClose = useCallback(
    () => {
      blur();
      setOpen(false)
    },
    [open, focused]
  );

  const onAdd = useCallback(
    () => {
      setOpen(true)
      setAction({
        handler: handleAdd,
        primary: "Add"
      })
    },
    [action, handleAdd]
  );

  const onBulkEdit = useCallback(
    () => {
      const selected = value
        .filter((d: any) => d._id && selected.includes(d._id))
        .reduce(
          (o, d: any) => Object.entries(d).reduce(
            (o: any, [k, v]) => {
              o[k] = o[k] || [];
              o[k].push(v);
              return o;
            },
            o
          ),
          {}
        )
      const datum = Object.entries(selected)
        .reduce(
          (o, [k, v]: any[]) => {
            const values = Array.from(new Set(v));
            o[k] = values.length === 1
              ? values[0]
              : undefined
            return o;
          },
          {}
        );
      focus((datum as any)._id);
      setOpen(true)
      setAction({
        handler: handleBulkEdit,
        primary: "Edit"
      })
    },
    [action, focused, handleBulkEdit]
  );

  const onClickRow = useCallback(
    (e: MouseClick<any>) => {
      e.stopPropagation();
      focus(e.datum._id);
      setOpen(true)
      setAction({
        handler: handleBulkEdit,
        primary: "Edit"
      })
    },
    [onBulkEdit, focused]
  )

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (action?.handler) {
        const event: SubmitActionEvent = e;
        (event as any).focused = focused;
        action.handler(event);
      }
      onClose();
    },
    [action, onClose]
  )

  return <Box>
    {!!value.length &&
      <Box overflow="auto">
        <DataTable
          columns={columns}
          data={value}
          primaryKey={false}
          resizeable
          step={10}
          sortable
          onClickRow={onClickRow}
        />
      </Box>
    }
    {rowViewProps &&
      <Footer align="end" direction="row">
        <Box pad="small" direction="row" align="center" gap="small">
          <Button hoverIndicator="light-1" icon={<Add />} label="Add" onClick={onAdd} disabled={disabled} />
          {
            !!selected.length &&
            <Button disabled={!selected.length || disabled} hoverIndicator="light-1" onClick={handleBulkDelete as any} icon={<Trash />} label="Delete" />
          }
          {
            !!selected.length &&
            <Button disabled={!selected.length || disabled} hoverIndicator="light-1" onClick={onBulkEdit} icon={<Edit />} label="Edit" />
          }
        </Box>
      </Footer>
    }
    {rowViewProps && open && <Layer
      modal
      position="center"
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Box width="large" pad="medium" flex overflow="auto">
        <Form onSubmit={onSubmit}>
          <Box gap="medium">
            <View {...rowViewProps} defaultValue={focused || rowViewProps.defaultValue} />
          </Box>
          <Box direction="row-reverse" gap="small" align="start" pad={{ bottom: "medium" }}>
            <Button type="submit" label={action?.primary} primary disabled={disabled} />
            <Button label="Close" secondary onClick={onClose} />
          </Box>
        </Form>
      </Box>
    </Layer>
    }
  </Box >
}
