import { Select } from '@catalytic/query';
import { parseSQL, Query } from '@catalytic/query-util-merge-sql';
import { useMemo } from 'react';
import { WhereClause } from './query.interfaces';
import { toWhereClause } from './query.transforms';

export interface UseSelectProps {
  select?: Query
}

export interface UseSelectState {
  node?: Select
}

export function useSelect(select?: Query): Select | undefined {
  const node = useMemo<Select | undefined>(
    () => select !== undefined ? parseSQL(select) : select,
    [select]
  )
  return node;
}

export function useWhereClauseList(select?: Query): WhereClause[] {
  return useMemo<WhereClause[]>(
    () => {
      if (select === undefined) return [];
      const { where = [] } = parseSQL(select);
      return toWhereClause(where);
    },
    [select]
  )
}
