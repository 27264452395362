/* eslint-disable jest/no-export, jest/valid-title */
import { isRejectTest } from "./reject.guards";
import { Reject, RejectOptions, RejectTest } from "./reject.interfaces";

const normalizeTest = (test: any): RejectTest =>
  isRejectTest(test) ? test : (v: any) => v !== test;

export const createRejectFactory = <T = any>(...tests: any[]): Reject<T> => {
  const fn = tests.map((test) => normalizeTest(test));
  const reject = <V = any>(value: V, options: RejectOptions = {}): V => {
    const { deep = false } = options;
    if (value && typeof value === "object") {
      if (Array.isArray(value)) {
        const array = (value as any as any[]).filter((v) =>
          fn.every((test) => test(v))
        ) as any[];
        return deep
          ? (array.map((v) => reject(v, options)) as any)
          : (array as any);
      } else {
        return Object.entries(value).reduce((o, [k, v]) => {
          if (fn.every((test) => test(v))) {
            o[k] = deep ? reject(v, options) : v;
          }
          return o;
        }, {} as V);
      }
    }

    return value;
  };

  reject.extend = (...next: any[]) => createRejectFactory(...tests, ...next);

  return reject;
};
