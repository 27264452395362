import styled from 'styled-components';
import { Heading, Header } from 'grommet';
import { BoxKindType } from '../../theme/theme.interfaces';
import { createStyleFactory } from '../../style/style.factories';
import { applyBoxStyle } from '../../style/style.box';

const defaultHeadingStyle = createStyleFactory<BoxKindType>({
  applyStyle: applyBoxStyle,
  getKind: ({ theme }) => theme?.components?.pageHeader?.heading
})

const defaultHeaderStyle = createStyleFactory<BoxKindType>({
  applyStyle: applyBoxStyle,
  getKind: ({ theme }) => theme?.components?.pageHeader?.header
})


export const StyledPageHeaderHeading = styled(Heading)`
${defaultHeadingStyle}
`;

export const StyledPageHeader = styled(Header)`
${defaultHeaderStyle}
`;
