import { types, View, ViewMap } from '@catalytic/react-view';
import { Box, Button, CheckBox, ColumnConfig, DataTable, Footer, Form, Layer, MouseClick, TextInput } from 'grommet';
import { Add, Edit, Trash } from 'grommet-icons';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormField } from '../../components/FormField';
import { fromJSONDictionary } from '../../util/toJSONValue';
import { ViewContextExtend } from '../../util/ViewContextExtend';
import { CheckboxViewReader } from '../CheckboxView/CheckboxView';
import { useCollectionView } from '../CollectionView';
import { DateTimeViewReader } from '../DateTimeView/DateTimeView';
import { DateViewReader } from '../DateView/DateView';
import { EmailViewReader } from '../EmailView/EmailView';
import { FileReferenceViewReader } from '../FileReferenceView/FileReferenceView';
import { FileViewReader } from '../FileView/FileView';
import { IntegerViewReader } from '../IntegerView/IntegerView';
import { MultipleSelectionViewReader } from '../MultipleSelectionView/MultipleSelectionView';
import { NumberViewReader } from '../NumberView/NumberView';
import { DefaultReferenceView } from '../ReferenceView/ReferenceView';
import { SelectionViewReader } from '../SelectionView/SelectionView';
import { TextAreaViewReader } from '../TextAreaView/TextAreaView';
import { TextViewReader } from '../TextView/TextView';
import { ToggleSwitchViewReader } from '../ToggleSwitchView/ToggleSwitchView';
import { StyledTableViewContainer } from './TableView.styled';
import { FormQueue } from '../../queue/queue.hooks';
import { rejectNull } from '@catalytic/reject';

type SubmitActionEvent = React.FormEvent & {
  focused?: object
}

interface SubmitAction {
  (e: SubmitActionEvent): void
}

interface Action {
  handler: SubmitAction,
  primary: string
}

export const TableReaderViewMap: ViewMap = {
  checkboxView: CheckboxViewReader,
  dateView: DateViewReader,
  dateTimeView: DateTimeViewReader,
  emailView: EmailViewReader,
  fileReferenceView: FileReferenceViewReader,
  fileView: FileViewReader,
  integerView: IntegerViewReader,
  multipleSelectionView: MultipleSelectionViewReader,
  numberView: NumberViewReader,
  referenceView: DefaultReferenceView,
  selectionView: SelectionViewReader,
  textView: TextViewReader,
  textAreaView: TextAreaViewReader,
  toggleSwitchView: ToggleSwitchViewReader
};

export function TableViewInput(props: types.TableView) {

  const { append, blur, columnViewProps, deleteSelected, deselectAll, focus, focused, focusedViewProps, selected, rowViewProps, selectAll, toggleSelectOne, updateSelected, value } = useCollectionView(props as any);
  const [open, setOpen] = React.useState<boolean>(false);
  const [columns, setColumns] = useState<ColumnConfig<any>[]>([])
  const [action, setAction] = useState<Action>()
  const disabled = props.node().disabled;


  useEffect(
    () => {
      // TODO Migrate this to collection view properties
      const properties = props.schema()?.items?.properties;

      if (!properties) return;

      const onCheckAll = (e: ChangeEvent<any>) => {
        if (e.target.checked) {
          selectAll()
        } else {
          deselectAll()
        }
      }

      const columns: ColumnConfig<any>[] = [
        {
          property: '_id',
          size: "48px",
          render: datum => (
            <CheckBox
              key={datum._id}
              checked={selected.indexOf(datum._id) !== -1}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onChange={(_e: ChangeEvent) => toggleSelectOne(datum._id)}
            />
          ),
          header: (
            <CheckBox
              checked={!!value.length && selected.length === value.length}
              indeterminate={
                selected.length > 0 && selected.length < value.length
              }
              onChange={onCheckAll}
            />
          ),
          sortable: false
        },
        ...Object.entries(properties).map(
          ([property, schema]) => {
            const ptr = `/${property}`;
            const node = columnViewProps(ptr) ?? { type: 'textAreaView' }
            return {
              property,
              header: schema.title,
              render: (value) => {
                return <Box pad={{ vertical: 'xsmall' }}>
                  <View node={node} value={value} schema={schema} />
                </Box>
              }
            } as ColumnConfig<any>;
          }
        )
      ]

      setColumns(columns)
    },
    [value, selected]
  )

  const handleAdd = (e: React.FormEvent) => {
    append(fromJSONDictionary((e as any).value, rowViewProps?.schema))
  }

  const handleBulkDelete = () => {
    deleteSelected();
  }

  const handleBulkEdit = (e: SubmitActionEvent) => {
    const nextRow = rejectNull(fromJSONDictionary((e as any).value, rowViewProps?.schema) as any);
    updateSelected(
      nextRow,
      Array.isArray(e.focused) && e.focused.length
        ? e.focused
        : typeof e.focused === 'string'
          ? [e.focused]
          : selected
    )
  }

  const onClose = useCallback(
    () => {
      blur();
      setOpen(false)
    },
    [open, focused]
  );

  const onAdd = useCallback(
    () => {
      setOpen(true)
      setAction({
        handler: handleAdd,
        primary: "Add"
      })
    },
    [action, handleAdd]
  );

  const onBulkEdit = useCallback(
    () => {
      focus(selected);
      setOpen(true)
      setAction({
        handler: handleBulkEdit,
        primary: "Edit"
      })
    },
    [action, focused, handleBulkEdit]
  );

  const onClickRow = useCallback(
    (e: MouseClick<any>) => {
      e.stopPropagation();
      focus(e.datum._id);
      setOpen(true)
      setAction({
        handler: handleBulkEdit,
        primary: "Edit"
      })
    },
    [onBulkEdit, focused]
  )

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (action?.handler) {
        const event: SubmitActionEvent = e;
        (event as any).focused = focused;
        action.handler(event);
      }
      onClose();
    },
    [action, onClose]
  )

  return <StyledTableViewContainer>
    {!!value.length &&
      <Box overflow="auto">
        <ViewContextExtend editor={TableReaderViewMap} reader={TableReaderViewMap}>
          <DataTable
            columns={columns}
            data={value}
            primaryKey={false}
            resizeable
            step={10}
            sortable
            onClickRow={onClickRow}
          />
        </ViewContextExtend>
      </Box>
    }
    {rowViewProps &&
      <Footer align="end" direction="row">
        <Box pad="small" direction="row" align="center" gap="small">
          <Button hoverIndicator="light-1" icon={<Add />} label="Add" onClick={onAdd} disabled={disabled} />
          {
            !!selected.length &&
            <Button disabled={!selected.length || disabled} hoverIndicator="light-1" onClick={handleBulkDelete as any} icon={<Trash />} label="Delete" />
          }
          {
            !!selected.length &&
            <Button disabled={!selected.length || disabled} hoverIndicator="light-1" onClick={onBulkEdit} icon={<Edit />} label="Edit" />
          }
        </Box>
      </Footer>
    }
    {rowViewProps && open && <FormQueue>
      {({ active }) => <Layer
        modal
        position="center"
        onClickOutside={() => !active && onClose()}
        onEsc={() => !active && onClose()}
      >
        <Box width="large" pad="medium" flex overflow="auto">
          <Form onSubmit={onSubmit}>
            <Box gap="medium">
              <View {...rowViewProps} defaultValue={focusedViewProps?.defaultValue ?? rowViewProps.defaultValue} />
            </Box>
            <Box direction="row-reverse" gap="small" align="start" pad={{ bottom: "medium" }}>
              <Button type="submit" label={action?.primary} primary disabled={!!active || disabled} />
              <Button label="Close" secondary disabled={!!active} onClick={onClose} />
            </Box>
          </Form>
        </Box>
      </Layer>}
    </FormQueue>
    }
  </StyledTableViewContainer >
}

export function TableView(props: types.TableView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    <TableViewInput {...props} />
    <TextInput
      type="hidden"
      name={props.node().reference}
      value={props.value ?? props.defaultValue() ?? ''} />
  </FormField>
}
