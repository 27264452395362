/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { OrchestrationAPIContext } from "./orchestrationAPIContext";

class OrchestrationAPI extends OrchestrationAPIContext {
  /**
   * Initializes a new instance of the OrchestrationAPI class.
   * @param teamname
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, teamname: string, options?: Models.OrchestrationAPIOptions) {
    super(credentials, teamname, options);
  }

  /**
   * Retrieve a list of apps
   * @summary List apps
   * @param [options] The optional parameters
   * @returns Promise<Models.ListAppsResponse>
   */
  listApps(options?: Models.OrchestrationAPIListAppsOptionalParams): Promise<Models.ListAppsResponse>;
  /**
   * @param callback The callback
   */
  listApps(callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listApps(options: Models.OrchestrationAPIListAppsOptionalParams, callback: msRest.ServiceCallback<Models.App[]>): void;
  listApps(options?: Models.OrchestrationAPIListAppsOptionalParams | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.ListAppsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listAppsOperationSpec,
      callback) as Promise<Models.ListAppsResponse>;
  }

  /**
   * Get an app by name
   * @summary Get app
   * @param appname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAppResponse>
   */
  getApp(appname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAppResponse>;
  /**
   * @param appname
   * @param callback The callback
   */
  getApp(appname: string, callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param appname
   * @param options The optional parameters
   * @param callback The callback
   */
  getApp(appname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App[]>): void;
  getApp(appname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.GetAppResponse> {
    return this.sendOperationRequest(
      {
        appname,
        options
      },
      getAppOperationSpec,
      callback) as Promise<Models.GetAppResponse>;
  }

  /**
   * Get app action
   * @summary Get app action
   * @param action
   * @param appname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAppActionResponse>
   */
  getAppAction(action: string, appname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAppActionResponse>;
  /**
   * @param action
   * @param appname
   * @param callback The callback
   */
  getAppAction(action: string, appname: string, callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param action
   * @param appname
   * @param options The optional parameters
   * @param callback The callback
   */
  getAppAction(action: string, appname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App[]>): void;
  getAppAction(action: string, appname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.GetAppActionResponse> {
    return this.sendOperationRequest(
      {
        action,
        appname,
        options
      },
      getAppActionOperationSpec,
      callback) as Promise<Models.GetAppActionResponse>;
  }

  /**
   * Get app action version
   * @summary Get app action version
   * @param appversion
   * @param action
   * @param appname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAppActionVersionResponse>
   */
  getAppActionVersion(appversion: string, action: string, appname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAppActionVersionResponse>;
  /**
   * @param appversion
   * @param action
   * @param appname
   * @param callback The callback
   */
  getAppActionVersion(appversion: string, action: string, appname: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param appversion
   * @param action
   * @param appname
   * @param options The optional parameters
   * @param callback The callback
   */
  getAppActionVersion(appversion: string, action: string, appname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  getAppActionVersion(appversion: string, action: string, appname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.GetAppActionVersionResponse> {
    return this.sendOperationRequest(
      {
        appversion,
        action,
        appname,
        options
      },
      getAppActionVersionOperationSpec,
      callback) as Promise<Models.GetAppActionVersionResponse>;
  }

  /**
   * Create app action version
   * @summary Create app action version
   * @param appversion
   * @param appname
   * @param action
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateAppActionVersionResponse>
   */
  createAppActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateAppActionVersionResponse>;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param callback The callback
   */
  createAppActionVersion(appversion: string, appname: string, action: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param options The optional parameters
   * @param callback The callback
   */
  createAppActionVersion(appversion: string, appname: string, action: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  createAppActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.CreateAppActionVersionResponse> {
    return this.sendOperationRequest(
      {
        appversion,
        appname,
        action,
        options
      },
      createAppActionVersionOperationSpec,
      callback) as Promise<Models.CreateAppActionVersionResponse>;
  }

  /**
   * hellosign callback
   * @summary Hellosign callback
   * @param [options] The optional parameters
   * @returns Promise<Models.HellosignCallbackResponse>
   */
  hellosignCallback(options?: msRest.RequestOptionsBase): Promise<Models.HellosignCallbackResponse>;
  /**
   * @param callback The callback
   */
  hellosignCallback(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  hellosignCallback(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  hellosignCallback(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.HellosignCallbackResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      hellosignCallbackOperationSpec,
      callback) as Promise<Models.HellosignCallbackResponse>;
  }

  /**
   * Slack commands
   * @summary Slack commands
   * @param [options] The optional parameters
   * @returns Promise<Models.SlackCommandsResponse>
   */
  slackCommands(options?: msRest.RequestOptionsBase): Promise<Models.SlackCommandsResponse>;
  /**
   * @param callback The callback
   */
  slackCommands(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  slackCommands(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  slackCommands(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SlackCommandsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      slackCommandsOperationSpec,
      callback) as Promise<Models.SlackCommandsResponse>;
  }

  /**
   * Slack interactive
   * @summary Slack interactive
   * @param [options] The optional parameters
   * @returns Promise<Models.SlackInteractiveResponse>
   */
  slackInteractive(options?: msRest.RequestOptionsBase): Promise<Models.SlackInteractiveResponse>;
  /**
   * @param callback The callback
   */
  slackInteractive(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  slackInteractive(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  slackInteractive(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SlackInteractiveResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      slackInteractiveOperationSpec,
      callback) as Promise<Models.SlackInteractiveResponse>;
  }

  /**
   * Await a transaction to complete.
   *
   * Certain operations that use a queue mechanism produce a transaction in the response. To know
   * when that transaction has completed you can use this endpoint which will return once the
   * transaction has completed.
   * @summary Await a transaction
   * @param transactionid
   * @param [options] The optional parameters
   * @returns Promise<Models.AwaitTransactionResponse>
   */
  awaitTransaction(transactionid: string, options?: msRest.RequestOptionsBase): Promise<Models.AwaitTransactionResponse>;
  /**
   * @param transactionid
   * @param callback The callback
   */
  awaitTransaction(transactionid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param transactionid
   * @param options The optional parameters
   * @param callback The callback
   */
  awaitTransaction(transactionid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  awaitTransaction(transactionid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.AwaitTransactionResponse> {
    return this.sendOperationRequest(
      {
        transactionid,
        options
      },
      awaitTransactionOperationSpec,
      callback) as Promise<Models.AwaitTransactionResponse>;
  }

  /**
   * Get info about the current environment
   * @summary Get environment
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEnvResponse>
   */
  getEnv(options?: msRest.RequestOptionsBase): Promise<Models.GetEnvResponse>;
  /**
   * @param callback The callback
   */
  getEnv(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getEnv(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getEnv(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetEnvResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getEnvOperationSpec,
      callback) as Promise<Models.GetEnvResponse>;
  }

  /**
   * Community SSO
   * @summary Community SSO
   * @param clientId
   * @param timestamp
   * @param signature
   * @param [options] The optional parameters
   * @returns Promise<Models.CommunitySsoResponse>
   */
  communitySso(clientId: string, timestamp: string, signature: string, options?: msRest.RequestOptionsBase): Promise<Models.CommunitySsoResponse>;
  /**
   * @param clientId
   * @param timestamp
   * @param signature
   * @param callback The callback
   */
  communitySso(clientId: string, timestamp: string, signature: string, callback: msRest.ServiceCallback<Models.CommunitySSO>): void;
  /**
   * @param clientId
   * @param timestamp
   * @param signature
   * @param options The optional parameters
   * @param callback The callback
   */
  communitySso(clientId: string, timestamp: string, signature: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CommunitySSO>): void;
  communitySso(clientId: string, timestamp: string, signature: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunitySSO>, callback?: msRest.ServiceCallback<Models.CommunitySSO>): Promise<Models.CommunitySsoResponse> {
    return this.sendOperationRequest(
      {
        clientId,
        timestamp,
        signature,
        options
      },
      communitySsoOperationSpec,
      callback) as Promise<Models.CommunitySsoResponse>;
  }

  /**
   * Community SSO V2
   * @summary Community SSO V2
   * @param jwt
   * @param [options] The optional parameters
   * @returns Promise<Models.CommunitySsoV2Response>
   */
  communitySsoV2(jwt: string, options?: msRest.RequestOptionsBase): Promise<Models.CommunitySsoV2Response>;
  /**
   * @param jwt
   * @param callback The callback
   */
  communitySsoV2(jwt: string, callback: msRest.ServiceCallback<Models.CommunitySSOV2>): void;
  /**
   * @param jwt
   * @param options The optional parameters
   * @param callback The callback
   */
  communitySsoV2(jwt: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CommunitySSOV2>): void;
  communitySsoV2(jwt: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunitySSOV2>, callback?: msRest.ServiceCallback<Models.CommunitySSOV2>): Promise<Models.CommunitySsoV2Response> {
    return this.sendOperationRequest(
      {
        jwt,
        options
      },
      communitySsoV2OperationSpec,
      callback) as Promise<Models.CommunitySsoV2Response>;
  }

  /**
   * Create inbound sms
   * @summary Create inbound sms
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  smsInbound(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  smsInbound(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  smsInbound(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  smsInbound(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      smsInboundOperationSpec,
      callback);
  }

  /**
   * Receive SES email
   * @summary Receive SES email
   * @param [options] The optional parameters
   * @returns Promise<Models.OnSesResponse>
   */
  onSes(options?: msRest.RequestOptionsBase): Promise<Models.OnSesResponse>;
  /**
   * @param callback The callback
   */
  onSes(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  onSes(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  onSes(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.OnSesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      onSesOperationSpec,
      callback) as Promise<Models.OnSesResponse>;
  }

  /**
   * SES SNS notification handler
   * @summary SES SNS notification handler
   * @param [options] The optional parameters
   * @returns Promise<Models.OnSesSnsResponse>
   */
  onSesSns(options?: msRest.RequestOptionsBase): Promise<Models.OnSesSnsResponse>;
  /**
   * @param callback The callback
   */
  onSesSns(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  onSesSns(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  onSesSns(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.OnSesSnsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      onSesSnsOperationSpec,
      callback) as Promise<Models.OnSesSnsResponse>;
  }

  /**
   * SNS Notification of new help doc data to re-index
   * @summary SNS Notification of new help doc data to re-index
   * @param [options] The optional parameters
   * @returns Promise<Models.SnsOnHelpuUpdatedResponse>
   */
  snsOnHelpuUpdated(options?: msRest.RequestOptionsBase): Promise<Models.SnsOnHelpuUpdatedResponse>;
  /**
   * @param callback The callback
   */
  snsOnHelpuUpdated(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  snsOnHelpuUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  snsOnHelpuUpdated(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SnsOnHelpuUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      snsOnHelpuUpdatedOperationSpec,
      callback) as Promise<Models.SnsOnHelpuUpdatedResponse>;
  }

  /**
   * SES bounce notification handler
   * @summary SES bounce notification handler
   * @param [options] The optional parameters
   * @returns Promise<Models.SesBounceResponse>
   */
  sesBounce(options?: msRest.RequestOptionsBase): Promise<Models.SesBounceResponse>;
  /**
   * @param callback The callback
   */
  sesBounce(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sesBounce(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  sesBounce(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SesBounceResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sesBounceOperationSpec,
      callback) as Promise<Models.SesBounceResponse>;
  }

  /**
   * SES open notification handler
   * @summary SES open notification handler
   * @param [options] The optional parameters
   * @returns Promise<Models.SesOpenResponse>
   */
  sesOpen(options?: msRest.RequestOptionsBase): Promise<Models.SesOpenResponse>;
  /**
   * @param callback The callback
   */
  sesOpen(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sesOpen(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  sesOpen(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SesOpenResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sesOpenOperationSpec,
      callback) as Promise<Models.SesOpenResponse>;
  }

  /**
   * Get all the features available and their status
   * @summary Get features
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFeaturesResponse>
   */
  getFeatures(options?: msRest.RequestOptionsBase): Promise<Models.GetFeaturesResponse>;
  /**
   * @param callback The callback
   */
  getFeatures(callback: msRest.ServiceCallback<Models.FeatureState[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFeatures(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FeatureState[]>): void;
  getFeatures(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FeatureState[]>, callback?: msRest.ServiceCallback<Models.FeatureState[]>): Promise<Models.GetFeaturesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getFeaturesOperationSpec,
      callback) as Promise<Models.GetFeaturesResponse>;
  }

  /**
   * Re-index help content for search
   * @summary Re-index help content for search
   * @param [options] The optional parameters
   * @returns Promise<Models.ReindexHelpResponse>
   */
  reindexHelp(options?: msRest.RequestOptionsBase): Promise<Models.ReindexHelpResponse>;
  /**
   * @param callback The callback
   */
  reindexHelp(callback: msRest.ServiceCallback<Models.Field[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reindexHelp(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field[]>): void;
  reindexHelp(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field[]>, callback?: msRest.ServiceCallback<Models.Field[]>): Promise<Models.ReindexHelpResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reindexHelpOperationSpec,
      callback) as Promise<Models.ReindexHelpResponse>;
  }

  /**
   * Returns a list of all modules.
   * @summary List modules.
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesModulesResponse>
   */
  getModuleFeaturesModules(options?: Models.OrchestrationAPIGetModuleFeaturesModulesOptionalParams): Promise<Models.GetModuleFeaturesModulesResponse>;
  /**
   * @param callback The callback
   */
  getModuleFeaturesModules(callback: msRest.ServiceCallback<Models.Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesModules(options: Models.OrchestrationAPIGetModuleFeaturesModulesOptionalParams, callback: msRest.ServiceCallback<Models.Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema>): void;
  getModuleFeaturesModules(options?: Models.OrchestrationAPIGetModuleFeaturesModulesOptionalParams | msRest.ServiceCallback<Models.Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema>): Promise<Models.GetModuleFeaturesModulesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getModuleFeaturesModulesOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesModulesResponse>;
  }

  /**
   * Create a module.
   * @summary Create module.
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateModuleFeaturesModuleResponse>
   */
  createModuleFeaturesModule(options?: Models.OrchestrationAPICreateModuleFeaturesModuleOptionalParams): Promise<Models.CreateModuleFeaturesModuleResponse>;
  /**
   * @param callback The callback
   */
  createModuleFeaturesModule(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createModuleFeaturesModule(options: Models.OrchestrationAPICreateModuleFeaturesModuleOptionalParams, callback: msRest.ServiceCallback<any>): void;
  createModuleFeaturesModule(options?: Models.OrchestrationAPICreateModuleFeaturesModuleOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateModuleFeaturesModuleResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createModuleFeaturesModuleOperationSpec,
      callback) as Promise<Models.CreateModuleFeaturesModuleResponse>;
  }

  /**
   * Returns a module.
   * @summary Returns a module.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesModuleResponse>
   */
  getModuleFeaturesModule(modulename: string, options?: msRest.RequestOptionsBase): Promise<Models.GetModuleFeaturesModuleResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  getModuleFeaturesModule(modulename: string, callback: msRest.ServiceCallback<Models.Module>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesModule(modulename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Module>): void;
  getModuleFeaturesModule(modulename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Module>, callback?: msRest.ServiceCallback<Models.Module>): Promise<Models.GetModuleFeaturesModuleResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      getModuleFeaturesModuleOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesModuleResponse>;
  }

  /**
   * Updates a module.
   * @summary Update module.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateModuleFeaturesModuleResponse>
   */
  updateModuleFeaturesModule(modulename: string, options?: Models.OrchestrationAPIUpdateModuleFeaturesModuleOptionalParams): Promise<Models.UpdateModuleFeaturesModuleResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  updateModuleFeaturesModule(modulename: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  updateModuleFeaturesModule(modulename: string, options: Models.OrchestrationAPIUpdateModuleFeaturesModuleOptionalParams, callback: msRest.ServiceCallback<any>): void;
  updateModuleFeaturesModule(modulename: string, options?: Models.OrchestrationAPIUpdateModuleFeaturesModuleOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateModuleFeaturesModuleResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      updateModuleFeaturesModuleOperationSpec,
      callback) as Promise<Models.UpdateModuleFeaturesModuleResponse>;
  }

  /**
   * Deletes a module.
   * @summary Delete module.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteModuleFeaturesModule(modulename: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  deleteModuleFeaturesModule(modulename: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteModuleFeaturesModule(modulename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteModuleFeaturesModule(modulename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      deleteModuleFeaturesModuleOperationSpec,
      callback);
  }

  /**
   * Update a module's availability to a team.
   * @summary Update module availability.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTeamModuleAvailabilityResponse>
   */
  updateTeamModuleAvailability(modulename: string, options?: Models.OrchestrationAPIUpdateTeamModuleAvailabilityOptionalParams): Promise<Models.UpdateTeamModuleAvailabilityResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  updateTeamModuleAvailability(modulename: string, callback: msRest.ServiceCallback<Models.Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTeamModuleAvailability(modulename: string, options: Models.OrchestrationAPIUpdateTeamModuleAvailabilityOptionalParams, callback: msRest.ServiceCallback<Models.Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema>): void;
  updateTeamModuleAvailability(modulename: string, options?: Models.OrchestrationAPIUpdateTeamModuleAvailabilityOptionalParams | msRest.ServiceCallback<Models.Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema>): Promise<Models.UpdateTeamModuleAvailabilityResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      updateTeamModuleAvailabilityOperationSpec,
      callback) as Promise<Models.UpdateTeamModuleAvailabilityResponse>;
  }

  /**
   * Returns a list of all features in a module.
   * @summary Return a module's features.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesModuleFeaturesResponse>
   */
  getModuleFeaturesModuleFeatures(modulename: string, options?: Models.OrchestrationAPIGetModuleFeaturesModuleFeaturesOptionalParams): Promise<Models.GetModuleFeaturesModuleFeaturesResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  getModuleFeaturesModuleFeatures(modulename: string, callback: msRest.ServiceCallback<Models.Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesModuleFeatures(modulename: string, options: Models.OrchestrationAPIGetModuleFeaturesModuleFeaturesOptionalParams, callback: msRest.ServiceCallback<Models.Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>): void;
  getModuleFeaturesModuleFeatures(modulename: string, options?: Models.OrchestrationAPIGetModuleFeaturesModuleFeaturesOptionalParams | msRest.ServiceCallback<Models.Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>): Promise<Models.GetModuleFeaturesModuleFeaturesResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      getModuleFeaturesModuleFeaturesOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesModuleFeaturesResponse>;
  }

  /**
   * Add an existing feature to a module. This operation will only succeed if the feature being
   * referenced already exists. To create a new feature, use "POST /feature-modules/features".
   * @summary Add existing feature to a module.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.AddModuleFeaturesFeatureToModuleResponse>
   */
  addModuleFeaturesFeatureToModule(modulename: string, options?: Models.OrchestrationAPIAddModuleFeaturesFeatureToModuleOptionalParams): Promise<Models.AddModuleFeaturesFeatureToModuleResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  addModuleFeaturesFeatureToModule(modulename: string, callback: msRest.ServiceCallback<Models.Feature>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  addModuleFeaturesFeatureToModule(modulename: string, options: Models.OrchestrationAPIAddModuleFeaturesFeatureToModuleOptionalParams, callback: msRest.ServiceCallback<Models.Feature>): void;
  addModuleFeaturesFeatureToModule(modulename: string, options?: Models.OrchestrationAPIAddModuleFeaturesFeatureToModuleOptionalParams | msRest.ServiceCallback<Models.Feature>, callback?: msRest.ServiceCallback<Models.Feature>): Promise<Models.AddModuleFeaturesFeatureToModuleResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      addModuleFeaturesFeatureToModuleOperationSpec,
      callback) as Promise<Models.AddModuleFeaturesFeatureToModuleResponse>;
  }

  /**
   * Returns a feature in a given module.
   * @summary Gets a feature in a module.
   * @param modulename
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesModuleFeatureResponse>
   */
  getModuleFeaturesModuleFeature(modulename: string, featurename: string, options?: msRest.RequestOptionsBase): Promise<Models.GetModuleFeaturesModuleFeatureResponse>;
  /**
   * @param modulename
   * @param featurename
   * @param callback The callback
   */
  getModuleFeaturesModuleFeature(modulename: string, featurename: string, callback: msRest.ServiceCallback<Models.Feature>): void;
  /**
   * @param modulename
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesModuleFeature(modulename: string, featurename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Feature>): void;
  getModuleFeaturesModuleFeature(modulename: string, featurename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Feature>, callback?: msRest.ServiceCallback<Models.Feature>): Promise<Models.GetModuleFeaturesModuleFeatureResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        featurename,
        options
      },
      getModuleFeaturesModuleFeatureOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesModuleFeatureResponse>;
  }

  /**
   * Deletes a feature from a module.
   * @summary Removes feature from module.
   * @param modulename
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteModuleFeaturesModuleFeature(modulename: string, featurename: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param modulename
   * @param featurename
   * @param callback The callback
   */
  deleteModuleFeaturesModuleFeature(modulename: string, featurename: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param modulename
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteModuleFeaturesModuleFeature(modulename: string, featurename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteModuleFeaturesModuleFeature(modulename: string, featurename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        featurename,
        options
      },
      deleteModuleFeaturesModuleFeatureOperationSpec,
      callback);
  }

  /**
   * Returns a list of all features.
   * @summary List features in a module.
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesFeaturesResponse>
   */
  getModuleFeaturesFeatures(options?: Models.OrchestrationAPIGetModuleFeaturesFeaturesOptionalParams): Promise<Models.GetModuleFeaturesFeaturesResponse>;
  /**
   * @param callback The callback
   */
  getModuleFeaturesFeatures(callback: msRest.ServiceCallback<Models.Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesFeatures(options: Models.OrchestrationAPIGetModuleFeaturesFeaturesOptionalParams, callback: msRest.ServiceCallback<Models.Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema>): void;
  getModuleFeaturesFeatures(options?: Models.OrchestrationAPIGetModuleFeaturesFeaturesOptionalParams | msRest.ServiceCallback<Models.Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema>): Promise<Models.GetModuleFeaturesFeaturesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getModuleFeaturesFeaturesOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesFeaturesResponse>;
  }

  /**
   * Create a feature.
   * @summary Create feature.
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateModuleFeaturesFeatureResponse>
   */
  createModuleFeaturesFeature(options?: Models.OrchestrationAPICreateModuleFeaturesFeatureOptionalParams): Promise<Models.CreateModuleFeaturesFeatureResponse>;
  /**
   * @param callback The callback
   */
  createModuleFeaturesFeature(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createModuleFeaturesFeature(options: Models.OrchestrationAPICreateModuleFeaturesFeatureOptionalParams, callback: msRest.ServiceCallback<any>): void;
  createModuleFeaturesFeature(options?: Models.OrchestrationAPICreateModuleFeaturesFeatureOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateModuleFeaturesFeatureResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createModuleFeaturesFeatureOperationSpec,
      callback) as Promise<Models.CreateModuleFeaturesFeatureResponse>;
  }

  /**
   * Return a feature that a module includes.
   * @summary Return a module's feature.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesFeatureResponse>
   */
  getModuleFeaturesFeature(featurename: string, options?: msRest.RequestOptionsBase): Promise<Models.GetModuleFeaturesFeatureResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  getModuleFeaturesFeature(featurename: string, callback: msRest.ServiceCallback<Models.Feature>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesFeature(featurename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Feature>): void;
  getModuleFeaturesFeature(featurename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Feature>, callback?: msRest.ServiceCallback<Models.Feature>): Promise<Models.GetModuleFeaturesFeatureResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      getModuleFeaturesFeatureOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesFeatureResponse>;
  }

  /**
   * Deletes a module feature.
   * @summary Delete a module's feature.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteModuleFeaturesFeature(featurename: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  deleteModuleFeaturesFeature(featurename: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteModuleFeaturesFeature(featurename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteModuleFeaturesFeature(featurename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      deleteModuleFeaturesFeatureOperationSpec,
      callback);
  }

  /**
   * Updates a feature.
   * @summary Update feature.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateModuleFeaturesFeaturesResponse>
   */
  updateModuleFeaturesFeatures(featurename: string, options?: Models.OrchestrationAPIUpdateModuleFeaturesFeaturesOptionalParams): Promise<Models.UpdateModuleFeaturesFeaturesResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  updateModuleFeaturesFeatures(featurename: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  updateModuleFeaturesFeatures(featurename: string, options: Models.OrchestrationAPIUpdateModuleFeaturesFeaturesOptionalParams, callback: msRest.ServiceCallback<any>): void;
  updateModuleFeaturesFeatures(featurename: string, options?: Models.OrchestrationAPIUpdateModuleFeaturesFeaturesOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateModuleFeaturesFeaturesResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      updateModuleFeaturesFeaturesOperationSpec,
      callback) as Promise<Models.UpdateModuleFeaturesFeaturesResponse>;
  }

  /**
   * Update a feature's availability to a team.
   * @summary Update feature availability.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTeamFeatureAvailabilityResponse>
   */
  updateTeamFeatureAvailability(featurename: string, options?: Models.OrchestrationAPIUpdateTeamFeatureAvailabilityOptionalParams): Promise<Models.UpdateTeamFeatureAvailabilityResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  updateTeamFeatureAvailability(featurename: string, callback: msRest.ServiceCallback<Models.PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTeamFeatureAvailability(featurename: string, options: Models.OrchestrationAPIUpdateTeamFeatureAvailabilityOptionalParams, callback: msRest.ServiceCallback<Models.PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema>): void;
  updateTeamFeatureAvailability(featurename: string, options?: Models.OrchestrationAPIUpdateTeamFeatureAvailabilityOptionalParams | msRest.ServiceCallback<Models.PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema>): Promise<Models.UpdateTeamFeatureAvailabilityResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      updateTeamFeatureAvailabilityOperationSpec,
      callback) as Promise<Models.UpdateTeamFeatureAvailabilityResponse>;
  }

  /**
   * Returns all modules a feature is contained in.
   * @summary Returns a feature's modules.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetModuleFeaturesFeatureModulesResponse>
   */
  getModuleFeaturesFeatureModules(featurename: string, options?: Models.OrchestrationAPIGetModuleFeaturesFeatureModulesOptionalParams): Promise<Models.GetModuleFeaturesFeatureModulesResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  getModuleFeaturesFeatureModules(featurename: string, callback: msRest.ServiceCallback<Models.Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  getModuleFeaturesFeatureModules(featurename: string, options: Models.OrchestrationAPIGetModuleFeaturesFeatureModulesOptionalParams, callback: msRest.ServiceCallback<Models.Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>): void;
  getModuleFeaturesFeatureModules(featurename: string, options?: Models.OrchestrationAPIGetModuleFeaturesFeatureModulesOptionalParams | msRest.ServiceCallback<Models.Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>): Promise<Models.GetModuleFeaturesFeatureModulesResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      getModuleFeaturesFeatureModulesOperationSpec,
      callback) as Promise<Models.GetModuleFeaturesFeatureModulesResponse>;
  }

  /**
   * Create a process in another team (admin only)
   * @summary Create a process in another team (admin only)
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessInAnotherTeamResponse>
   */
  createProcessInAnotherTeam(body: Models.Process, options?: msRest.RequestOptionsBase): Promise<Models.CreateProcessInAnotherTeamResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  createProcessInAnotherTeam(body: Models.Process, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessInAnotherTeam(body: Models.Process, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  createProcessInAnotherTeam(body: Models.Process, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.CreateProcessInAnotherTeamResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createProcessInAnotherTeamOperationSpec,
      callback) as Promise<Models.CreateProcessInAnotherTeamResponse>;
  }

  /**
   * Get a publicly shared process
   * @summary Get process
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAdminProcessResponse>
   */
  getAdminProcess(processid: string, options?: Models.OrchestrationAPIGetAdminProcessOptionalParams): Promise<Models.GetAdminProcessResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  getAdminProcess(processid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  getAdminProcess(processid: string, options: Models.OrchestrationAPIGetAdminProcessOptionalParams, callback: msRest.ServiceCallback<Models.Process>): void;
  getAdminProcess(processid: string, options?: Models.OrchestrationAPIGetAdminProcessOptionalParams | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.GetAdminProcessResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      getAdminProcessOperationSpec,
      callback) as Promise<Models.GetAdminProcessResponse>;
  }

  /**
   * Create a new team
   * @summary Create team
   * @param body Team to create
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTeamResponse>
   */
  createTeam(body: Models.Team, options?: msRest.RequestOptionsBase): Promise<Models.CreateTeamResponse>;
  /**
   * @param body Team to create
   * @param callback The callback
   */
  createTeam(body: Models.Team, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param body Team to create
   * @param options The optional parameters
   * @param callback The callback
   */
  createTeam(body: Models.Team, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  createTeam(body: Models.Team, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.CreateTeamResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createTeamOperationSpec,
      callback) as Promise<Models.CreateTeamResponse>;
  }

  /**
   * Get a specific team
   * @summary Get team
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamResponse>
   */
  getTeam(options?: msRest.RequestOptionsBase): Promise<Models.GetTeamResponse>;
  /**
   * @param callback The callback
   */
  getTeam(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeam(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  getTeam(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.GetTeamResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getTeamOperationSpec,
      callback) as Promise<Models.GetTeamResponse>;
  }

  /**
   * Update a specific team
   * @summary Update team
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTeamResponse>
   */
  updateTeam(options?: msRest.RequestOptionsBase): Promise<Models.UpdateTeamResponse>;
  /**
   * @param callback The callback
   */
  updateTeam(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTeam(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  updateTeam(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.UpdateTeamResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateTeamOperationSpec,
      callback) as Promise<Models.UpdateTeamResponse>;
  }

  /**
   * Delete a specific team
   * @summary Delete team
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteTeamResponse>
   */
  deleteTeam(options?: msRest.RequestOptionsBase): Promise<Models.DeleteTeamResponse>;
  /**
   * @param callback The callback
   */
  deleteTeam(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTeam(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  deleteTeam(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.DeleteTeamResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteTeamOperationSpec,
      callback) as Promise<Models.DeleteTeamResponse>;
  }

  /**
   * Create access request
   * @summary Create access request
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateAccessRequestResponse>
   */
  createAccessRequest(options?: msRest.RequestOptionsBase): Promise<Models.CreateAccessRequestResponse>;
  /**
   * @param callback The callback
   */
  createAccessRequest(callback: msRest.ServiceCallback<Models.AccessRequest>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createAccessRequest(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AccessRequest>): void;
  createAccessRequest(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AccessRequest>, callback?: msRest.ServiceCallback<Models.AccessRequest>): Promise<Models.CreateAccessRequestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createAccessRequestOperationSpec,
      callback) as Promise<Models.CreateAccessRequestResponse>;
  }

  /**
   * Approve a specific access request
   * @summary Approve access request
   * @param accessrequestid
   * @param [options] The optional parameters
   * @returns Promise<Models.ApproveAccessRequestResponse>
   */
  approveAccessRequest(accessrequestid: string, options?: msRest.RequestOptionsBase): Promise<Models.ApproveAccessRequestResponse>;
  /**
   * @param accessrequestid
   * @param callback The callback
   */
  approveAccessRequest(accessrequestid: string, callback: msRest.ServiceCallback<Models.AccessRequest>): void;
  /**
   * @param accessrequestid
   * @param options The optional parameters
   * @param callback The callback
   */
  approveAccessRequest(accessrequestid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AccessRequest>): void;
  approveAccessRequest(accessrequestid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AccessRequest>, callback?: msRest.ServiceCallback<Models.AccessRequest>): Promise<Models.ApproveAccessRequestResponse> {
    return this.sendOperationRequest(
      {
        accessrequestid,
        options
      },
      approveAccessRequestOperationSpec,
      callback) as Promise<Models.ApproveAccessRequestResponse>;
  }

  /**
   * Get apps for a specific team
   * @summary Get team apps
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAppsResponse>
   */
  getApps(options?: Models.OrchestrationAPIGetAppsOptionalParams): Promise<Models.GetAppsResponse>;
  /**
   * @param callback The callback
   */
  getApps(callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApps(options: Models.OrchestrationAPIGetAppsOptionalParams, callback: msRest.ServiceCallback<Models.App[]>): void;
  getApps(options?: Models.OrchestrationAPIGetAppsOptionalParams | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.GetAppsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAppsOperationSpec,
      callback) as Promise<Models.GetAppsResponse>;
  }

  /**
   * Create app for a specific team
   * @summary Create team app
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateAppResponse>
   */
  createApp(options?: msRest.RequestOptionsBase): Promise<Models.CreateAppResponse>;
  /**
   * @param callback The callback
   */
  createApp(callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApp(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App[]>): void;
  createApp(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.CreateAppResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createAppOperationSpec,
      callback) as Promise<Models.CreateAppResponse>;
  }

  /**
   * Get a specific app for a specific team
   * @summary Get app
   * @param appname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamAppResponse>
   */
  getTeamApp(appname: string, options?: Models.OrchestrationAPIGetTeamAppOptionalParams): Promise<Models.GetTeamAppResponse>;
  /**
   * @param appname
   * @param callback The callback
   */
  getTeamApp(appname: string, callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param appname
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamApp(appname: string, options: Models.OrchestrationAPIGetTeamAppOptionalParams, callback: msRest.ServiceCallback<Models.App[]>): void;
  getTeamApp(appname: string, options?: Models.OrchestrationAPIGetTeamAppOptionalParams | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.GetTeamAppResponse> {
    return this.sendOperationRequest(
      {
        appname,
        options
      },
      getTeamAppOperationSpec,
      callback) as Promise<Models.GetTeamAppResponse>;
  }

  /**
   * Deleting a group of apps that live in the database
   * @summary Delete app records by app name
   * @param appname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteAppResponse>
   */
  deleteApp(appname: string, options?: Models.OrchestrationAPIDeleteAppOptionalParams): Promise<Models.DeleteAppResponse>;
  /**
   * @param appname
   * @param callback The callback
   */
  deleteApp(appname: string, callback: msRest.ServiceCallback<Models.ImportAction>): void;
  /**
   * @param appname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteApp(appname: string, options: Models.OrchestrationAPIDeleteAppOptionalParams, callback: msRest.ServiceCallback<Models.ImportAction>): void;
  deleteApp(appname: string, options?: Models.OrchestrationAPIDeleteAppOptionalParams | msRest.ServiceCallback<Models.ImportAction>, callback?: msRest.ServiceCallback<Models.ImportAction>): Promise<Models.DeleteAppResponse> {
    return this.sendOperationRequest(
      {
        appname,
        options
      },
      deleteAppOperationSpec,
      callback) as Promise<Models.DeleteAppResponse>;
  }

  /**
   * Get a specific app action for a specific team
   * @summary Get team app action
   * @param appname
   * @param action
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamAppActionResponse>
   */
  getTeamAppAction(appname: string, action: string, options?: Models.OrchestrationAPIGetTeamAppActionOptionalParams): Promise<Models.GetTeamAppActionResponse>;
  /**
   * @param appname
   * @param action
   * @param callback The callback
   */
  getTeamAppAction(appname: string, action: string, callback: msRest.ServiceCallback<Models.App[]>): void;
  /**
   * @param appname
   * @param action
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamAppAction(appname: string, action: string, options: Models.OrchestrationAPIGetTeamAppActionOptionalParams, callback: msRest.ServiceCallback<Models.App[]>): void;
  getTeamAppAction(appname: string, action: string, options?: Models.OrchestrationAPIGetTeamAppActionOptionalParams | msRest.ServiceCallback<Models.App[]>, callback?: msRest.ServiceCallback<Models.App[]>): Promise<Models.GetTeamAppActionResponse> {
    return this.sendOperationRequest(
      {
        appname,
        action,
        options
      },
      getTeamAppActionOperationSpec,
      callback) as Promise<Models.GetTeamAppActionResponse>;
  }

  /**
   * Get a specific app action version for a specific team
   * @summary Get team app action version
   * @param appversion
   * @param appname
   * @param action
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActionVersionResponse>
   */
  getActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase): Promise<Models.GetActionVersionResponse>;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param callback The callback
   */
  getActionVersion(appversion: string, appname: string, action: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param options The optional parameters
   * @param callback The callback
   */
  getActionVersion(appversion: string, appname: string, action: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  getActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.GetActionVersionResponse> {
    return this.sendOperationRequest(
      {
        appversion,
        appname,
        action,
        options
      },
      getActionVersionOperationSpec,
      callback) as Promise<Models.GetActionVersionResponse>;
  }

  /**
   * Update an app action version for a specific team
   * @summary Update app action version
   * @param appversion
   * @param appname
   * @param action
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAppActionVersionResponse>
   */
  updateAppActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateAppActionVersionResponse>;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param callback The callback
   */
  updateAppActionVersion(appversion: string, appname: string, action: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAppActionVersion(appversion: string, appname: string, action: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  updateAppActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.UpdateAppActionVersionResponse> {
    return this.sendOperationRequest(
      {
        appversion,
        appname,
        action,
        options
      },
      updateAppActionVersionOperationSpec,
      callback) as Promise<Models.UpdateAppActionVersionResponse>;
  }

  /**
   * Create a new app action version for a specific team
   * @summary Create app action version
   * @param appversion
   * @param appname
   * @param action
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTeamAppActionVersionResponse>
   */
  createTeamAppActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateTeamAppActionVersionResponse>;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param callback The callback
   */
  createTeamAppActionVersion(appversion: string, appname: string, action: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param appversion
   * @param appname
   * @param action
   * @param options The optional parameters
   * @param callback The callback
   */
  createTeamAppActionVersion(appversion: string, appname: string, action: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  createTeamAppActionVersion(appversion: string, appname: string, action: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.CreateTeamAppActionVersionResponse> {
    return this.sendOperationRequest(
      {
        appversion,
        appname,
        action,
        options
      },
      createTeamAppActionVersionOperationSpec,
      callback) as Promise<Models.CreateTeamAppActionVersionResponse>;
  }

  /**
   * Import an app for a specific team
   * @summary Import app
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.ImportAppResponse>
   */
  importApp(body: Models.ImportAction, options?: msRest.RequestOptionsBase): Promise<Models.ImportAppResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  importApp(body: Models.ImportAction, callback: msRest.ServiceCallback<Models.ImportAction>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  importApp(body: Models.ImportAction, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ImportAction>): void;
  importApp(body: Models.ImportAction, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ImportAction>, callback?: msRest.ServiceCallback<Models.ImportAction>): Promise<Models.ImportAppResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      importAppOperationSpec,
      callback) as Promise<Models.ImportAppResponse>;
  }

  /**
   * Create custom integration for a specific team
   * @summary Create custom integration
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateCustomIntegrationResponse>
   */
  createCustomIntegration(body: Models.CustomIntegrationDefinition, options?: msRest.RequestOptionsBase): Promise<Models.CreateCustomIntegrationResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  createCustomIntegration(body: Models.CustomIntegrationDefinition, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createCustomIntegration(body: Models.CustomIntegrationDefinition, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  createCustomIntegration(body: Models.CustomIntegrationDefinition, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.CreateCustomIntegrationResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createCustomIntegrationOperationSpec,
      callback) as Promise<Models.CreateCustomIntegrationResponse>;
  }

  /**
   * Get custom integration for a specific team
   * @summary Get custom integration
   * @param appname
   * @param action
   * @param appversion
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomIntegrationResponse>
   */
  getCustomIntegration(appname: string, action: string, appversion: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCustomIntegrationResponse>;
  /**
   * @param appname
   * @param action
   * @param appversion
   * @param callback The callback
   */
  getCustomIntegration(appname: string, action: string, appversion: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param appname
   * @param action
   * @param appversion
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomIntegration(appname: string, action: string, appversion: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  getCustomIntegration(appname: string, action: string, appversion: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.GetCustomIntegrationResponse> {
    return this.sendOperationRequest(
      {
        appname,
        action,
        appversion,
        options
      },
      getCustomIntegrationOperationSpec,
      callback) as Promise<Models.GetCustomIntegrationResponse>;
  }

  /**
   * Update a custom integration for a specific team
   * @summary Update custom integration
   * @param body
   * @param appname
   * @param action
   * @param appversion
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateCustomIntegrationResponse>
   */
  updateCustomIntegration(body: Models.CustomIntegrationDefinition, appname: string, action: string, appversion: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateCustomIntegrationResponse>;
  /**
   * @param body
   * @param appname
   * @param action
   * @param appversion
   * @param callback The callback
   */
  updateCustomIntegration(body: Models.CustomIntegrationDefinition, appname: string, action: string, appversion: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param body
   * @param appname
   * @param action
   * @param appversion
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomIntegration(body: Models.CustomIntegrationDefinition, appname: string, action: string, appversion: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  updateCustomIntegration(body: Models.CustomIntegrationDefinition, appname: string, action: string, appversion: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.UpdateCustomIntegrationResponse> {
    return this.sendOperationRequest(
      {
        body,
        appname,
        action,
        appversion,
        options
      },
      updateCustomIntegrationOperationSpec,
      callback) as Promise<Models.UpdateCustomIntegrationResponse>;
  }

  /**
   * Delete a custom integration for a specific team
   * @summary Delete custom integration
   * @param appname
   * @param action
   * @param appversion
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteCustomIntegration(appname: string, action: string, appversion: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param appname
   * @param action
   * @param appversion
   * @param callback The callback
   */
  deleteCustomIntegration(appname: string, action: string, appversion: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param appname
   * @param action
   * @param appversion
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteCustomIntegration(appname: string, action: string, appversion: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteCustomIntegration(appname: string, action: string, appversion: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        appname,
        action,
        appversion,
        options
      },
      deleteCustomIntegrationOperationSpec,
      callback);
  }

  /**
   * Lists all actions registered in a team. Supports paging and sorting.
   * @summary List all actions in a team
   * @param [options] The optional parameters
   * @returns Promise<Models.ListActionsResponse>
   */
  listActions(options?: Models.OrchestrationAPIListActionsOptionalParams): Promise<Models.ListActionsResponse>;
  /**
   * @param callback The callback
   */
  listActions(callback: msRest.ServiceCallback<Models.Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listActions(options: Models.OrchestrationAPIListActionsOptionalParams, callback: msRest.ServiceCallback<Models.Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema>): void;
  listActions(options?: Models.OrchestrationAPIListActionsOptionalParams | msRest.ServiceCallback<Models.Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema>): Promise<Models.ListActionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listActionsOperationSpec,
      callback) as Promise<Models.ListActionsResponse>;
  }

  /**
   * Registers a new action in a team
   * @summary Register a new action in a team
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateActionResponse>
   */
  createAction(options?: Models.OrchestrationAPICreateActionOptionalParams): Promise<Models.CreateActionResponse>;
  /**
   * @param callback The callback
   */
  createAction(callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createAction(options: Models.OrchestrationAPICreateActionOptionalParams, callback: msRest.ServiceCallback<Models.Action>): void;
  createAction(options?: Models.OrchestrationAPICreateActionOptionalParams | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.CreateActionResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createActionOperationSpec,
      callback) as Promise<Models.CreateActionResponse>;
  }

  /**
   * Gets an action
   * @summary Get an action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActionResponse>
   */
  getAction(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  getAction(actionID: string, callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  getAction(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Action>): void;
  getAction(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.GetActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      getActionOperationSpec,
      callback) as Promise<Models.GetActionResponse>;
  }

  /**
   * Updates an action
   * @summary Update an action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateActionResponse>
   */
  updateAction(actionID: string, options?: Models.OrchestrationAPIUpdateActionOptionalParams): Promise<Models.UpdateActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  updateAction(actionID: string, callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAction(actionID: string, options: Models.OrchestrationAPIUpdateActionOptionalParams, callback: msRest.ServiceCallback<Models.Action>): void;
  updateAction(actionID: string, options?: Models.OrchestrationAPIUpdateActionOptionalParams | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.UpdateActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      updateActionOperationSpec,
      callback) as Promise<Models.UpdateActionResponse>;
  }

  /**
   * Deletes an action
   * @summary Delete an action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteActionResponse>
   */
  deleteAction(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  deleteAction(actionID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAction(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteAction(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      deleteActionOperationSpec,
      callback) as Promise<Models.DeleteActionResponse>;
  }

  /**
   * Fetch metadata for Action Configuration Files associated with Action
   * @summary List Action Configuration Files
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListActionConfigurationFilesResponse>
   */
  listActionConfigurationFiles(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListActionConfigurationFilesResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  listActionConfigurationFiles(actionID: string, callback: msRest.ServiceCallback<Models.Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  listActionConfigurationFiles(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): void;
  listActionConfigurationFiles(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): Promise<Models.ListActionConfigurationFilesResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      listActionConfigurationFilesOperationSpec,
      callback) as Promise<Models.ListActionConfigurationFilesResponse>;
  }

  /**
   * Upload a new Action Configuration File associated with the Action
   * @summary Create Action Configuration File
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateActionConfigurationFileResponse>
   */
  createActionConfigurationFile(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateActionConfigurationFileResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  createActionConfigurationFile(actionID: string, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  createActionConfigurationFile(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  createActionConfigurationFile(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActionConfigurationFile>, callback?: msRest.ServiceCallback<Models.ActionConfigurationFile>): Promise<Models.CreateActionConfigurationFileResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      createActionConfigurationFileOperationSpec,
      callback) as Promise<Models.CreateActionConfigurationFileResponse>;
  }

  /**
   * Fetch metadata for specific Action Configuration File associated with Action
   * @summary Get Action Configuration File
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActionConfigurationFileResponse>
   */
  getActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetActionConfigurationFileResponse>;
  /**
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  getActionConfigurationFile(actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  /**
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  getActionConfigurationFile(actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  getActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActionConfigurationFile>, callback?: msRest.ServiceCallback<Models.ActionConfigurationFile>): Promise<Models.GetActionConfigurationFileResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        configurationFileID,
        options
      },
      getActionConfigurationFileOperationSpec,
      callback) as Promise<Models.GetActionConfigurationFileResponse>;
  }

  /**
   * Delete a specific Action Configuration File associated with the Action
   * @summary Delete Action Configuration File
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  deleteActionConfigurationFile(actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteActionConfigurationFile(actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        configurationFileID,
        options
      },
      deleteActionConfigurationFileOperationSpec,
      callback);
  }

  /**
   * Download a specific Action Configuration File associated with the Action
   * @summary Download Action Configuration File
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadActionConfigurationFileResponse>
   */
  downloadActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadActionConfigurationFileResponse>;
  /**
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  downloadActionConfigurationFile(actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadActionConfigurationFile(actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  downloadActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DownloadActionConfigurationFileResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        configurationFileID,
        options
      },
      downloadActionConfigurationFileOperationSpec,
      callback) as Promise<Models.DownloadActionConfigurationFileResponse>;
  }

  /**
   * Lists all global actions registered in a team. Supports paging and sorting.
   * @summary List all global actions in a team
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalActionsResponse>
   */
  listGlobalActions(options?: Models.OrchestrationAPIListGlobalActionsOptionalParams): Promise<Models.ListGlobalActionsResponse>;
  /**
   * @param callback The callback
   */
  listGlobalActions(callback: msRest.ServiceCallback<Models.Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalActions(options: Models.OrchestrationAPIListGlobalActionsOptionalParams, callback: msRest.ServiceCallback<Models.Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema>): void;
  listGlobalActions(options?: Models.OrchestrationAPIListGlobalActionsOptionalParams | msRest.ServiceCallback<Models.Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalActionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listGlobalActionsOperationSpec,
      callback) as Promise<Models.ListGlobalActionsResponse>;
  }

  /**
   * Registers a new global action in a team
   * @summary Register a new global action in a team
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateGlobalActionResponse>
   */
  createGlobalAction(options?: Models.OrchestrationAPICreateGlobalActionOptionalParams): Promise<Models.CreateGlobalActionResponse>;
  /**
   * @param callback The callback
   */
  createGlobalAction(callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createGlobalAction(options: Models.OrchestrationAPICreateGlobalActionOptionalParams, callback: msRest.ServiceCallback<Models.Action>): void;
  createGlobalAction(options?: Models.OrchestrationAPICreateGlobalActionOptionalParams | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.CreateGlobalActionResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createGlobalActionOperationSpec,
      callback) as Promise<Models.CreateGlobalActionResponse>;
  }

  /**
   * Gets a Global Action
   * @summary Get a Global Action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetGlobalActionResponse>
   */
  getGlobalAction(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetGlobalActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  getGlobalAction(actionID: string, callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  getGlobalAction(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Action>): void;
  getGlobalAction(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.GetGlobalActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      getGlobalActionOperationSpec,
      callback) as Promise<Models.GetGlobalActionResponse>;
  }

  /**
   * Updates a Global Action
   * @summary Update a Global Action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateGlobalActionResponse>
   */
  updateGlobalAction(actionID: string, options?: Models.OrchestrationAPIUpdateGlobalActionOptionalParams): Promise<Models.UpdateGlobalActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  updateGlobalAction(actionID: string, callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  updateGlobalAction(actionID: string, options: Models.OrchestrationAPIUpdateGlobalActionOptionalParams, callback: msRest.ServiceCallback<Models.Action>): void;
  updateGlobalAction(actionID: string, options?: Models.OrchestrationAPIUpdateGlobalActionOptionalParams | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.UpdateGlobalActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      updateGlobalActionOperationSpec,
      callback) as Promise<Models.UpdateGlobalActionResponse>;
  }

  /**
   * Deletes a Global Action
   * @summary Delete a Global Action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteGlobalActionResponse>
   */
  deleteGlobalAction(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteGlobalActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  deleteGlobalAction(actionID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteGlobalAction(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteGlobalAction(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteGlobalActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      deleteGlobalActionOperationSpec,
      callback) as Promise<Models.DeleteGlobalActionResponse>;
  }

  /**
   * Fetch metadata for Action Configuration Files associated with Global Action
   * @summary List Global Action Configuration Files
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalActionConfigurationFilesResponse>
   */
  listGlobalActionConfigurationFiles(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalActionConfigurationFilesResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  listGlobalActionConfigurationFiles(actionID: string, callback: msRest.ServiceCallback<Models.Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalActionConfigurationFiles(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): void;
  listGlobalActionConfigurationFiles(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalActionConfigurationFilesResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      listGlobalActionConfigurationFilesOperationSpec,
      callback) as Promise<Models.ListGlobalActionConfigurationFilesResponse>;
  }

  /**
   * Upload a new Action Configuration File associated with the Global Action
   * @summary Create Global Action Configuration File
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateGlobalActionConfigurationFileResponse>
   */
  createGlobalActionConfigurationFile(actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateGlobalActionConfigurationFileResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  createGlobalActionConfigurationFile(actionID: string, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  createGlobalActionConfigurationFile(actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  createGlobalActionConfigurationFile(actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActionConfigurationFile>, callback?: msRest.ServiceCallback<Models.ActionConfigurationFile>): Promise<Models.CreateGlobalActionConfigurationFileResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      createGlobalActionConfigurationFileOperationSpec,
      callback) as Promise<Models.CreateGlobalActionConfigurationFileResponse>;
  }

  /**
   * Fetch metadata for specific Action Configuration File associated with the Global Action
   * @summary Get Global Action Configuration File
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetGlobalActionConfigurationFileResponse>
   */
  getGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetGlobalActionConfigurationFileResponse>;
  /**
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  getGlobalActionConfigurationFile(actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  /**
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  getGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  getGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActionConfigurationFile>, callback?: msRest.ServiceCallback<Models.ActionConfigurationFile>): Promise<Models.GetGlobalActionConfigurationFileResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        configurationFileID,
        options
      },
      getGlobalActionConfigurationFileOperationSpec,
      callback) as Promise<Models.GetGlobalActionConfigurationFileResponse>;
  }

  /**
   * Delete a specific Global Action Configuration File associated with the Action
   * @summary Delete Global Action Configuration File
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  deleteGlobalActionConfigurationFile(actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        configurationFileID,
        options
      },
      deleteGlobalActionConfigurationFileOperationSpec,
      callback);
  }

  /**
   * Download a specific Action Configuration File associated with the Global Action
   * @summary Download Global Action Configuration File
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadGlobalActionConfigurationFileResponse>
   */
  downloadGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadGlobalActionConfigurationFileResponse>;
  /**
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  downloadGlobalActionConfigurationFile(actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  downloadGlobalActionConfigurationFile(actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DownloadGlobalActionConfigurationFileResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        configurationFileID,
        options
      },
      downloadGlobalActionConfigurationFileOperationSpec,
      callback) as Promise<Models.DownloadGlobalActionConfigurationFileResponse>;
  }

  /**
   * Lists all action workers registered in a team. Supports paging and sorting.
   * @summary List workers
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkersResponse>
   */
  listTeamWorkers(options?: Models.OrchestrationAPIListTeamWorkersOptionalParams): Promise<Models.ListTeamWorkersResponse>;
  /**
   * @param callback The callback
   */
  listTeamWorkers(callback: msRest.ServiceCallback<Models.Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkers(options: Models.OrchestrationAPIListTeamWorkersOptionalParams, callback: msRest.ServiceCallback<Models.Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema>): void;
  listTeamWorkers(options?: Models.OrchestrationAPIListTeamWorkersOptionalParams | msRest.ServiceCallback<Models.Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listTeamWorkersOperationSpec,
      callback) as Promise<Models.ListTeamWorkersResponse>;
  }

  /**
   * Registers a new action worker instance in a team
   * @summary Register worker
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWorkerResponse>
   */
  createWorker(options?: Models.OrchestrationAPICreateWorkerOptionalParams): Promise<Models.CreateWorkerResponse>;
  /**
   * @param callback The callback
   */
  createWorker(callback: msRest.ServiceCallback<Models.Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createWorker(options: Models.OrchestrationAPICreateWorkerOptionalParams, callback: msRest.ServiceCallback<Models.Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema>): void;
  createWorker(options?: Models.OrchestrationAPICreateWorkerOptionalParams | msRest.ServiceCallback<Models.Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema>): Promise<Models.CreateWorkerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createWorkerOperationSpec,
      callback) as Promise<Models.CreateWorkerResponse>;
  }

  /**
   * Directly invokes a workbench action
   * @summary Invoke an action
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.InvokeActionResponse>
   */
  invokeAction(actionID: string, options?: Models.OrchestrationAPIInvokeActionOptionalParams): Promise<Models.InvokeActionResponse>;
  /**
   * @param actionID
   * @param callback The callback
   */
  invokeAction(actionID: string, callback: msRest.ServiceCallback<Models.ActionInvocation>): void;
  /**
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  invokeAction(actionID: string, options: Models.OrchestrationAPIInvokeActionOptionalParams, callback: msRest.ServiceCallback<Models.ActionInvocation>): void;
  invokeAction(actionID: string, options?: Models.OrchestrationAPIInvokeActionOptionalParams | msRest.ServiceCallback<Models.ActionInvocation>, callback?: msRest.ServiceCallback<Models.ActionInvocation>): Promise<Models.InvokeActionResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        options
      },
      invokeActionOperationSpec,
      callback) as Promise<Models.InvokeActionResponse>;
  }

  /**
   * Gets an action invocation, including completion status and outputs
   * @summary Get an action invocation
   * @param actionID
   * @param actionInvocationID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActionInvocationzResponse>
   */
  getActionInvocationz(actionID: string, actionInvocationID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetActionInvocationzResponse>;
  /**
   * @param actionID
   * @param actionInvocationID
   * @param callback The callback
   */
  getActionInvocationz(actionID: string, actionInvocationID: string, callback: msRest.ServiceCallback<Models.ActionInvocation>): void;
  /**
   * @param actionID
   * @param actionInvocationID
   * @param options The optional parameters
   * @param callback The callback
   */
  getActionInvocationz(actionID: string, actionInvocationID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActionInvocation>): void;
  getActionInvocationz(actionID: string, actionInvocationID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActionInvocation>, callback?: msRest.ServiceCallback<Models.ActionInvocation>): Promise<Models.GetActionInvocationzResponse> {
    return this.sendOperationRequest(
      {
        actionID,
        actionInvocationID,
        options
      },
      getActionInvocationzOperationSpec,
      callback) as Promise<Models.GetActionInvocationzResponse>;
  }

  /**
   * Gets an action worker
   * @summary Get an worker
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWorkerResponse>
   */
  getWorker(workerID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWorkerResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  getWorker(workerID: string, callback: msRest.ServiceCallback<Models.Worker>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  getWorker(workerID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Worker>): void;
  getWorker(workerID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Worker>, callback?: msRest.ServiceCallback<Models.Worker>): Promise<Models.GetWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      getWorkerOperationSpec,
      callback) as Promise<Models.GetWorkerResponse>;
  }

  /**
   * Updates an action worker
   * @summary Update an worker
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWorkerResponse>
   */
  updateWorker(workerID: string, options?: Models.OrchestrationAPIUpdateWorkerOptionalParams): Promise<Models.UpdateWorkerResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  updateWorker(workerID: string, callback: msRest.ServiceCallback<Models.Worker>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWorker(workerID: string, options: Models.OrchestrationAPIUpdateWorkerOptionalParams, callback: msRest.ServiceCallback<Models.Worker>): void;
  updateWorker(workerID: string, options?: Models.OrchestrationAPIUpdateWorkerOptionalParams | msRest.ServiceCallback<Models.Worker>, callback?: msRest.ServiceCallback<Models.Worker>): Promise<Models.UpdateWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      updateWorkerOperationSpec,
      callback) as Promise<Models.UpdateWorkerResponse>;
  }

  /**
   * Deletes a registered action worker
   * @summary Delete a worker
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWorkerResponse>
   */
  deleteWorker(workerID: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWorkerResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  deleteWorker(workerID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWorker(workerID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWorker(workerID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      deleteWorkerOperationSpec,
      callback) as Promise<Models.DeleteWorkerResponse>;
  }

  /**
   * Lists all tasks currently being executed by a specific worker. Supports
   * paging and sorting.
   * @summary List all tasks locked by a worker
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTasksResponse>
   */
  getTasks(workerID: string, options?: Models.OrchestrationAPIGetTasksOptionalParams): Promise<Models.GetTasksResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  getTasks(workerID: string, callback: msRest.ServiceCallback<Models.Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  getTasks(workerID: string, options: Models.OrchestrationAPIGetTasksOptionalParams, callback: msRest.ServiceCallback<Models.Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>): void;
  getTasks(workerID: string, options?: Models.OrchestrationAPIGetTasksOptionalParams | msRest.ServiceCallback<Models.Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>): Promise<Models.GetTasksResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      getTasksOperationSpec,
      callback) as Promise<Models.GetTasksResponse>;
  }

  /**
   * Lists all action worker controls. Supports paging and sorting.
   *
   * Worker controls are used to temporary block action execution for targeted workers, actions,
   * tags, etc.
   * @summary List worker controls
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWorkerControlsResponse>
   */
  listWorkerControls(options?: Models.OrchestrationAPIListWorkerControlsOptionalParams): Promise<Models.ListWorkerControlsResponse>;
  /**
   * @param callback The callback
   */
  listWorkerControls(callback: msRest.ServiceCallback<Models.Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listWorkerControls(options: Models.OrchestrationAPIListWorkerControlsOptionalParams, callback: msRest.ServiceCallback<Models.Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema>): void;
  listWorkerControls(options?: Models.OrchestrationAPIListWorkerControlsOptionalParams | msRest.ServiceCallback<Models.Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema>): Promise<Models.ListWorkerControlsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listWorkerControlsOperationSpec,
      callback) as Promise<Models.ListWorkerControlsResponse>;
  }

  /**
   * Worker Controls limit which tasks are eligible to be acquired by a worker.
   * To stop all worker execution, create a Worker Control with `stopAllExecution`
   * set to `true`. Otherwise you can stop a specific `taskID`, `runID`, `rootRunID`,
   * `workerID`, `actionID`, `teamName` or set of `tags`.
   * @summary Create worker control
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWorkerControlResponse>
   */
  createWorkerControl(options?: Models.OrchestrationAPICreateWorkerControlOptionalParams): Promise<Models.CreateWorkerControlResponse>;
  /**
   * @param callback The callback
   */
  createWorkerControl(callback: msRest.ServiceCallback<Models.WorkerControl>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createWorkerControl(options: Models.OrchestrationAPICreateWorkerControlOptionalParams, callback: msRest.ServiceCallback<Models.WorkerControl>): void;
  createWorkerControl(options?: Models.OrchestrationAPICreateWorkerControlOptionalParams | msRest.ServiceCallback<Models.WorkerControl>, callback?: msRest.ServiceCallback<Models.WorkerControl>): Promise<Models.CreateWorkerControlResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createWorkerControlOperationSpec,
      callback) as Promise<Models.CreateWorkerControlResponse>;
  }

  /**
   * Delete all workers and allow action execution to proceed unrestricted
   * @summary Delete worker controls
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWorkerControlsResponse>
   */
  deleteWorkerControls(options?: msRest.RequestOptionsBase): Promise<Models.DeleteWorkerControlsResponse>;
  /**
   * @param callback The callback
   */
  deleteWorkerControls(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWorkerControls(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWorkerControls(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWorkerControlsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteWorkerControlsOperationSpec,
      callback) as Promise<Models.DeleteWorkerControlsResponse>;
  }

  /**
   * Gets a worker control
   * @summary Get worker control
   * @param controlID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWorkerControlResponse>
   */
  getWorkerControl(controlID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWorkerControlResponse>;
  /**
   * @param controlID
   * @param callback The callback
   */
  getWorkerControl(controlID: string, callback: msRest.ServiceCallback<Models.WorkerControl>): void;
  /**
   * @param controlID
   * @param options The optional parameters
   * @param callback The callback
   */
  getWorkerControl(controlID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.WorkerControl>): void;
  getWorkerControl(controlID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.WorkerControl>, callback?: msRest.ServiceCallback<Models.WorkerControl>): Promise<Models.GetWorkerControlResponse> {
    return this.sendOperationRequest(
      {
        controlID,
        options
      },
      getWorkerControlOperationSpec,
      callback) as Promise<Models.GetWorkerControlResponse>;
  }

  /**
   * Updates a worker control.
   * @summary Update worker control
   * @param controlID
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWorkerControlResponse>
   */
  updateWorkerControl(controlID: string, options?: Models.OrchestrationAPIUpdateWorkerControlOptionalParams): Promise<Models.UpdateWorkerControlResponse>;
  /**
   * @param controlID
   * @param callback The callback
   */
  updateWorkerControl(controlID: string, callback: msRest.ServiceCallback<Models.WorkerControl>): void;
  /**
   * @param controlID
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWorkerControl(controlID: string, options: Models.OrchestrationAPIUpdateWorkerControlOptionalParams, callback: msRest.ServiceCallback<Models.WorkerControl>): void;
  updateWorkerControl(controlID: string, options?: Models.OrchestrationAPIUpdateWorkerControlOptionalParams | msRest.ServiceCallback<Models.WorkerControl>, callback?: msRest.ServiceCallback<Models.WorkerControl>): Promise<Models.UpdateWorkerControlResponse> {
    return this.sendOperationRequest(
      {
        controlID,
        options
      },
      updateWorkerControlOperationSpec,
      callback) as Promise<Models.UpdateWorkerControlResponse>;
  }

  /**
   * Deletes a worker control
   * @summary Delete worker control
   * @param controlID
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWorkerControlResponse>
   */
  deleteWorkerControl(controlID: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWorkerControlResponse>;
  /**
   * @param controlID
   * @param callback The callback
   */
  deleteWorkerControl(controlID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param controlID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWorkerControl(controlID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWorkerControl(controlID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWorkerControlResponse> {
    return this.sendOperationRequest(
      {
        controlID,
        options
      },
      deleteWorkerControlOperationSpec,
      callback) as Promise<Models.DeleteWorkerControlResponse>;
  }

  /**
   * Acquires a lock on a single task for this worker to execute.
   * `lockDurationSeconds` can be up to 300 (5 minutes). It defaults to 30 if not
   * specified. If `waitTimeMillis` is not specified, this request will return
   * immediately, either with a `"status": "success"` and a `"task"` in the body,
   * or with a `"status": "timeout"` and a `null` `"task"` in the body. If `waitTimeMillis` is
   * specified, the request will block up to the requested
   * number of milliseconds waiting for a task to be ready to acquire.
   * `waitTimeMillis` must be between `500` (0.5 seconds) and `60000` (1 minute).
   * @summary Acquire a task
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.AcquireWorkerTaskResponse>
   */
  acquireWorkerTask(workerID: string, options?: Models.OrchestrationAPIAcquireWorkerTaskOptionalParams): Promise<Models.AcquireWorkerTaskResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  acquireWorkerTask(workerID: string, callback: msRest.ServiceCallback<Models.Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  acquireWorkerTask(workerID: string, options: Models.OrchestrationAPIAcquireWorkerTaskOptionalParams, callback: msRest.ServiceCallback<Models.Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema>): void;
  acquireWorkerTask(workerID: string, options?: Models.OrchestrationAPIAcquireWorkerTaskOptionalParams | msRest.ServiceCallback<Models.Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema>): Promise<Models.AcquireWorkerTaskResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      acquireWorkerTaskOperationSpec,
      callback) as Promise<Models.AcquireWorkerTaskResponse>;
  }

  /**
   * Acquires a lock on several tasks for this worker to execute.
   * `lockDurationSeconds` and `waitTimeMillis` work just like they do when
   * [acquiring a single task](#acquire-a-task-to-execute). The batch version of
   * this call takes an additional parameter `maxTaskCount`.
   *
   * `maxTaskCount` indicates the maximum number of tasks to acquire locks on.
   * `maxTaskCount` must be specified and must be an integer between `1`
   * and `100` inclusive.
   *
   * *Note:* The response body includes a `lockRequestID`, `count`,
   * `waitingTaskCount` and `tasks` array. `count` may be greater than the
   * length of the `tasks` array, in which case the caller should page
   * through the `/worers/{workerID}/tasks?lockRequestID={lockRequestID}`
   * results to get all locked tasks. `waitingTaskCount` is the number of
   * tasks still waiting in the backlog for this worker after this batch
   * of tasks.
   * @summary Acquire a task
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.BatchAcquireTasksResponse>
   */
  batchAcquireTasks(workerID: string, options?: Models.OrchestrationAPIBatchAcquireTasksOptionalParams): Promise<Models.BatchAcquireTasksResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  batchAcquireTasks(workerID: string, callback: msRest.ServiceCallback<Models.Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  batchAcquireTasks(workerID: string, options: Models.OrchestrationAPIBatchAcquireTasksOptionalParams, callback: msRest.ServiceCallback<Models.Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema>): void;
  batchAcquireTasks(workerID: string, options?: Models.OrchestrationAPIBatchAcquireTasksOptionalParams | msRest.ServiceCallback<Models.Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema>): Promise<Models.BatchAcquireTasksResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      batchAcquireTasksOperationSpec,
      callback) as Promise<Models.BatchAcquireTasksResponse>;
  }

  /**
   * Lists all tasks currently being executed by a specific worker.
   * Use the `?lockRequestID` query parameter to request only those
   * tasks acquired in a specific batch-acquire-tasks request.
   * Supports paging and sorting.
   * @summary List all tasks locked by a worker
   * @param workerID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWorkersResponse>
   */
  listWorkers(workerID: string, options?: Models.OrchestrationAPIListWorkersOptionalParams): Promise<Models.ListWorkersResponse>;
  /**
   * @param workerID
   * @param callback The callback
   */
  listWorkers(workerID: string, callback: msRest.ServiceCallback<Models.Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param options The optional parameters
   * @param callback The callback
   */
  listWorkers(workerID: string, options: Models.OrchestrationAPIListWorkersOptionalParams, callback: msRest.ServiceCallback<Models.Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>): void;
  listWorkers(workerID: string, options?: Models.OrchestrationAPIListWorkersOptionalParams | msRest.ServiceCallback<Models.Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema>): Promise<Models.ListWorkersResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        options
      },
      listWorkersOperationSpec,
      callback) as Promise<Models.ListWorkersResponse>;
  }

  /**
   * Update a task that is being processed. This can be used to update the status
   * of a task, extend the lock duration and update output fields. By keeping the
   * initial `lockDurationSeconds` small and repeatedly calling this endpoint to
   * extend the lock, this endpoint serves as a health check for long-running
   * actions.
   *
   * The request body may optionally contain a `progressStatus` field, a
   * `progressPercent` field and a `lockDurationSeconds` field. The
   * `progressStatus` field can be set to a description of the worker's progress
   * in executing this task, for example `verifying results` or `performing OCR`.
   * The `progressPercent` field is an integer between `0` and `99` representing
   * how close to completion this task is. The `lockDurationSeconds` field can be
   * set to extend the duration of the currently held lock on this task.
   *
   * Additionally, the body may contain fields for each of the named output
   * parameters of the action. For example, if you have an output field named
   * `"spreadsheet"` of type `file`, you would add a `file` field to the request
   * body named `spreadsheet`. That would look like:
   *
   * ```
   * -----------------------------1234-boundary
   * Content-Disposition: form-data; name="lockDurationSeconds"
   *
   * 30
   *
   * -----------------------------1234-boundary
   * Content-Disposition: form-data; name="progressPercent"
   *
   * 80
   *
   * -----------------------------1234-boundary
   * Content-Disposition: form-data; name="progressStatus"
   *
   * verifying results
   *
   * -----------------------------1234-boundry
   * Content-Disposition: form-data; name="spreadsheet"; filename="result.csv"
   * Content-Type: text/csv
   *
   * Column 1, Column 2, ...
   * ```
   * @summary Update a task
   * @param workerID
   * @param taskID
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWorkerTaskResponse>
   */
  updateWorkerTask(workerID: string, taskID: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWorkerTaskResponse>;
  /**
   * @param workerID
   * @param taskID
   * @param callback The callback
   */
  updateWorkerTask(workerID: string, taskID: string, callback: msRest.ServiceCallback<Models.Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param taskID
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWorkerTask(workerID: string, taskID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema>): void;
  updateWorkerTask(workerID: string, taskID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema>): Promise<Models.UpdateWorkerTaskResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        taskID,
        options
      },
      updateWorkerTaskOperationSpec,
      callback) as Promise<Models.UpdateWorkerTaskResponse>;
  }

  /**
   * Completes a task and sets the `output` fields of that task. Any action output
   * that is not included in the body and was not previously set with an
   * [update task](#update-a-task) call will be set to `null`. Outputs that were
   * previously set can be overwritten by including them in the request body.
   *
   * The body may contain fields for each of the named output parameters of the
   * action. For example, if you have an output field named `"spreadsheet"` of
   * type `file` and a `string` field named `"summary"`, you would add a `file`
   * field to the request body named `spreadsheet` and a string field named
   * `summary`. That would look like:
   *
   * ```
   * -----------------------------1234-boundary
   * Content-Disposition: form-data; name="summary"
   *
   * This is some summary text
   *
   * -----------------------------1234-boundry
   * Content-Disposition: form-data; name="spreadsheet"; filename="result.csv"
   * Content-Type: text/csv
   *
   * Column 1, Column 2, ...
   * ```
   * @summary Complete a task
   * @param workerID
   * @param taskID
   * @param [options] The optional parameters
   * @returns Promise<Models.CompleteWorkerTaskResponse>
   */
  completeWorkerTask(workerID: string, taskID: string, options?: msRest.RequestOptionsBase): Promise<Models.CompleteWorkerTaskResponse>;
  /**
   * @param workerID
   * @param taskID
   * @param callback The callback
   */
  completeWorkerTask(workerID: string, taskID: string, callback: msRest.ServiceCallback<Models.Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param taskID
   * @param options The optional parameters
   * @param callback The callback
   */
  completeWorkerTask(workerID: string, taskID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema>): void;
  completeWorkerTask(workerID: string, taskID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema>): Promise<Models.CompleteWorkerTaskResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        taskID,
        options
      },
      completeWorkerTaskOperationSpec,
      callback) as Promise<Models.CompleteWorkerTaskResponse>;
  }

  /**
   * Download an input file from an acquired task. The input `value`
   * will contain the `fileID` that can be used with this endpoint.
   *
   * The response will stream the file after setting the `Cache-Control`,
   * `Content-Type`, `Content-Disposition`, `Content-Length` and `ETag`
   * headers.
   * @summary Download an input file
   * @param workerID
   * @param taskID
   * @param fileID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWorkerTaskFileResponse>
   */
  getWorkerTaskFile(workerID: string, taskID: string, fileID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWorkerTaskFileResponse>;
  /**
   * @param workerID
   * @param taskID
   * @param fileID
   * @param callback The callback
   */
  getWorkerTaskFile(workerID: string, taskID: string, fileID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param workerID
   * @param taskID
   * @param fileID
   * @param options The optional parameters
   * @param callback The callback
   */
  getWorkerTaskFile(workerID: string, taskID: string, fileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  getWorkerTaskFile(workerID: string, taskID: string, fileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.GetWorkerTaskFileResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        taskID,
        fileID,
        options
      },
      getWorkerTaskFileOperationSpec,
      callback) as Promise<Models.GetWorkerTaskFileResponse>;
  }

  /**
   * Post a comment to a task
   * @summary Post a comment
   * @param workerID
   * @param taskID
   * @param [options] The optional parameters
   * @returns Promise<Models.PostCommentToWorkerTaskResponse>
   */
  postCommentToWorkerTask(workerID: string, taskID: string, options?: Models.OrchestrationAPIPostCommentToWorkerTaskOptionalParams): Promise<Models.PostCommentToWorkerTaskResponse>;
  /**
   * @param workerID
   * @param taskID
   * @param callback The callback
   */
  postCommentToWorkerTask(workerID: string, taskID: string, callback: msRest.ServiceCallback<Models.Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param taskID
   * @param options The optional parameters
   * @param callback The callback
   */
  postCommentToWorkerTask(workerID: string, taskID: string, options: Models.OrchestrationAPIPostCommentToWorkerTaskOptionalParams, callback: msRest.ServiceCallback<Models.Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema>): void;
  postCommentToWorkerTask(workerID: string, taskID: string, options?: Models.OrchestrationAPIPostCommentToWorkerTaskOptionalParams | msRest.ServiceCallback<Models.Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema>): Promise<Models.PostCommentToWorkerTaskResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        taskID,
        options
      },
      postCommentToWorkerTaskOperationSpec,
      callback) as Promise<Models.PostCommentToWorkerTaskResponse>;
  }

  /**
   * Fetch an Action implemented by the Worker
   * @summary Get Action
   * @param workerID
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActionAsWorkerResponse>
   */
  getActionAsWorker(workerID: string, actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetActionAsWorkerResponse>;
  /**
   * @param workerID
   * @param actionID
   * @param callback The callback
   */
  getActionAsWorker(workerID: string, actionID: string, callback: msRest.ServiceCallback<Models.Action>): void;
  /**
   * @param workerID
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  getActionAsWorker(workerID: string, actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Action>): void;
  getActionAsWorker(workerID: string, actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Action>, callback?: msRest.ServiceCallback<Models.Action>): Promise<Models.GetActionAsWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        actionID,
        options
      },
      getActionAsWorkerOperationSpec,
      callback) as Promise<Models.GetActionAsWorkerResponse>;
  }

  /**
   * List metadata for Action Configuration Files associated with Action implemented by Worker
   * @summary List Action Configuration Files
   * @param workerID
   * @param actionID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListActionConfigurationFilesAsWorkerResponse>
   */
  listActionConfigurationFilesAsWorker(workerID: string, actionID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListActionConfigurationFilesAsWorkerResponse>;
  /**
   * @param workerID
   * @param actionID
   * @param callback The callback
   */
  listActionConfigurationFilesAsWorker(workerID: string, actionID: string, callback: msRest.ServiceCallback<Models.Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param workerID
   * @param actionID
   * @param options The optional parameters
   * @param callback The callback
   */
  listActionConfigurationFilesAsWorker(workerID: string, actionID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): void;
  listActionConfigurationFilesAsWorker(workerID: string, actionID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema>): Promise<Models.ListActionConfigurationFilesAsWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        actionID,
        options
      },
      listActionConfigurationFilesAsWorkerOperationSpec,
      callback) as Promise<Models.ListActionConfigurationFilesAsWorkerResponse>;
  }

  /**
   * Get metadata for a specific Action Configuration File associated with an Action implemented by
   * the Worker
   * @summary Get Action Configuration File
   * @param workerID
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActionConfigurationFileAsWorkerResponse>
   */
  getActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetActionConfigurationFileAsWorkerResponse>;
  /**
   * @param workerID
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  getActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  /**
   * @param workerID
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  getActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActionConfigurationFile>): void;
  getActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActionConfigurationFile>, callback?: msRest.ServiceCallback<Models.ActionConfigurationFile>): Promise<Models.GetActionConfigurationFileAsWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        actionID,
        configurationFileID,
        options
      },
      getActionConfigurationFileAsWorkerOperationSpec,
      callback) as Promise<Models.GetActionConfigurationFileAsWorkerResponse>;
  }

  /**
   * Download a specific Action Configuration File associated with an Action implemented by the
   * Worker
   * @summary Download Action Configuration File
   * @param workerID
   * @param actionID
   * @param configurationFileID
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadActionConfigurationFileAsWorkerResponse>
   */
  downloadActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadActionConfigurationFileAsWorkerResponse>;
  /**
   * @param workerID
   * @param actionID
   * @param configurationFileID
   * @param callback The callback
   */
  downloadActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param workerID
   * @param actionID
   * @param configurationFileID
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  downloadActionConfigurationFileAsWorker(workerID: string, actionID: string, configurationFileID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DownloadActionConfigurationFileAsWorkerResponse> {
    return this.sendOperationRequest(
      {
        workerID,
        actionID,
        configurationFileID,
        options
      },
      downloadActionConfigurationFileAsWorkerOperationSpec,
      callback) as Promise<Models.DownloadActionConfigurationFileAsWorkerResponse>;
  }

  /**
   * Allows Global Capability Admins to lookup userID across all teams
   * by the user's email address. Optionally filter by teamName.
   * @summary List UserIDs by Email
   * @param email
   * @param [options] The optional parameters
   * @returns Promise<Models.AdminListUserIdsResponse>
   */
  adminListUserIds(email: string, options?: Models.OrchestrationAPIAdminListUserIdsOptionalParams): Promise<Models.AdminListUserIdsResponse>;
  /**
   * @param email
   * @param callback The callback
   */
  adminListUserIds(email: string, callback: msRest.ServiceCallback<Models.Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param email
   * @param options The optional parameters
   * @param callback The callback
   */
  adminListUserIds(email: string, options: Models.OrchestrationAPIAdminListUserIdsOptionalParams, callback: msRest.ServiceCallback<Models.Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema>): void;
  adminListUserIds(email: string, options?: Models.OrchestrationAPIAdminListUserIdsOptionalParams | msRest.ServiceCallback<Models.Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema>): Promise<Models.AdminListUserIdsResponse> {
    return this.sendOperationRequest(
      {
        email,
        options
      },
      adminListUserIdsOperationSpec,
      callback) as Promise<Models.AdminListUserIdsResponse>;
  }

  /**
   * Initialize Workbench Capabilities, granting the
   * "Global Capability Admin" capability to the admin
   * team user making this request for the first time.
   * @summary Initialize Workbench Capabilities
   * @param [options] The optional parameters
   * @returns Promise<Models.InitializeWorkbenchCapabilitiesResponse>
   */
  initializeWorkbenchCapabilities(options?: msRest.RequestOptionsBase): Promise<Models.InitializeWorkbenchCapabilitiesResponse>;
  /**
   * @param callback The callback
   */
  initializeWorkbenchCapabilities(callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  initializeWorkbenchCapabilities(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  initializeWorkbenchCapabilities(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.WorkbenchCapability>, callback?: msRest.ServiceCallback<Models.WorkbenchCapability>): Promise<Models.InitializeWorkbenchCapabilitiesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      initializeWorkbenchCapabilitiesOperationSpec,
      callback) as Promise<Models.InitializeWorkbenchCapabilitiesResponse>;
  }

  /**
   * If this request is unauthenticated, it returns all public capabilities.
   * If authenticated as a "Global Capabilities Admin", it returns all capabilities.
   * If authenticated as anyone else, it returns all public capabilities, plus
   * any capabilities that the user is granted.
   * @summary List All Workbench Capabilities
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalWorkbenchCapabilitiesResponse>
   */
  listGlobalWorkbenchCapabilities(options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalWorkbenchCapabilitiesResponse>;
  /**
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilities(callback: msRest.ServiceCallback<Models.Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilities(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema>): void;
  listGlobalWorkbenchCapabilities(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalWorkbenchCapabilitiesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listGlobalWorkbenchCapabilitiesOperationSpec,
      callback) as Promise<Models.ListGlobalWorkbenchCapabilitiesResponse>;
  }

  /**
   * Creates a new
   * @summary Create a Workbench Capability
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWorkbenchCapabilityResponse>
   */
  createWorkbenchCapability(options?: Models.OrchestrationAPICreateWorkbenchCapabilityOptionalParams): Promise<Models.CreateWorkbenchCapabilityResponse>;
  /**
   * @param callback The callback
   */
  createWorkbenchCapability(callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createWorkbenchCapability(options: Models.OrchestrationAPICreateWorkbenchCapabilityOptionalParams, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  createWorkbenchCapability(options?: Models.OrchestrationAPICreateWorkbenchCapabilityOptionalParams | msRest.ServiceCallback<Models.WorkbenchCapability>, callback?: msRest.ServiceCallback<Models.WorkbenchCapability>): Promise<Models.CreateWorkbenchCapabilityResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createWorkbenchCapabilityOperationSpec,
      callback) as Promise<Models.CreateWorkbenchCapabilityResponse>;
  }

  /**
   * Get a specific workbench capability
   * @summary Get a Workbench Capability
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWorkbenchCapabilityResponse>
   */
  getWorkbenchCapability(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWorkbenchCapabilityResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  getWorkbenchCapability(capabilityID: string, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  getWorkbenchCapability(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  getWorkbenchCapability(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.WorkbenchCapability>, callback?: msRest.ServiceCallback<Models.WorkbenchCapability>): Promise<Models.GetWorkbenchCapabilityResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      getWorkbenchCapabilityOperationSpec,
      callback) as Promise<Models.GetWorkbenchCapabilityResponse>;
  }

  /**
   * Update a specific workbench capability
   * @summary Update a Workbench Capability
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWorkbenchCapabilityResponse>
   */
  updateWorkbenchCapability(capabilityID: string, options?: Models.OrchestrationAPIUpdateWorkbenchCapabilityOptionalParams): Promise<Models.UpdateWorkbenchCapabilityResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  updateWorkbenchCapability(capabilityID: string, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWorkbenchCapability(capabilityID: string, options: Models.OrchestrationAPIUpdateWorkbenchCapabilityOptionalParams, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  updateWorkbenchCapability(capabilityID: string, options?: Models.OrchestrationAPIUpdateWorkbenchCapabilityOptionalParams | msRest.ServiceCallback<Models.WorkbenchCapability>, callback?: msRest.ServiceCallback<Models.WorkbenchCapability>): Promise<Models.UpdateWorkbenchCapabilityResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      updateWorkbenchCapabilityOperationSpec,
      callback) as Promise<Models.UpdateWorkbenchCapabilityResponse>;
  }

  /**
   * Delete a specific workbench capability
   * @summary Delete a Workbench Capability
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteWorkbenchCapability(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  deleteWorkbenchCapability(capabilityID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWorkbenchCapability(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteWorkbenchCapability(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      deleteWorkbenchCapabilityOperationSpec,
      callback);
  }

  /**
   * Lists all grants of a specific capability across all teams.
   * If "-" is specified for `capabilityID`, then all grants for
   * all capabilities will be included.
   * @summary List All Workbench Capability Grants
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalWorkbenchCapabilityGrantsResponse>
   */
  listGlobalWorkbenchCapabilityGrants(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalWorkbenchCapabilityGrantsResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrants(capabilityID: string, callback: msRest.ServiceCallback<Models.Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrants(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>): void;
  listGlobalWorkbenchCapabilityGrants(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalWorkbenchCapabilityGrantsResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      listGlobalWorkbenchCapabilityGrantsOperationSpec,
      callback) as Promise<Models.ListGlobalWorkbenchCapabilityGrantsResponse>;
  }

  /**
   * Lists all grants of a specific capability to a specific user.
   * If "-" is specified for `capabilityID`, then all grants for
   * all capabilities to that user will be included.
   * @summary List All Workbench Capability Grants to a User
   * @param capabilityID
   * @param userID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUserResponse>
   */
  listGlobalWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUserResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, callback: msRest.ServiceCallback<Models.Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>): void;
  listGlobalWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUserResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        options
      },
      listGlobalWorkbenchCapabilityGrantsToUserOperationSpec,
      callback) as Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUserResponse>;
  }

  /**
   * Lists all grants to either use or manage a specific capability to a specific user.
   * If "-" is specified for `capabilityID` then grants for all capabilities
   * will be included.
   * If "-" is specified for `userID` then all grants across all users
   * will be included.
   * @summary List All Workbench Capability Grants to a User by Grant Type
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUser1Response>
   */
  listGlobalWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType4, options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUser1Response>;
  /**
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType4, callback: msRest.ServiceCallback<Models.Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType4, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>): void;
  listGlobalWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType4, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUser1Response> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        grantType,
        options
      },
      listGlobalWorkbenchCapabilityGrantsToUser1OperationSpec,
      callback) as Promise<Models.ListGlobalWorkbenchCapabilityGrantsToUser1Response>;
  }

  /**
   * Grant or Deny rights to Use or Manage a Workbench Capability to a User
   * @summary Grant Capability
   * @param capabilityID
   * @param userID
   * @param grantAction Possible values include: 'grant', 'deny'
   * @param grantType Possible values include: 'use', 'manage'
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateGlobalCapabilityGrantResponse>
   */
  createGlobalCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction, grantType: Models.GrantType5, options?: Models.OrchestrationAPICreateGlobalCapabilityGrantOptionalParams): Promise<Models.CreateGlobalCapabilityGrantResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param grantAction Possible values include: 'grant', 'deny'
   * @param grantType Possible values include: 'use', 'manage'
   * @param callback The callback
   */
  createGlobalCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction, grantType: Models.GrantType5, callback: msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param grantAction Possible values include: 'grant', 'deny'
   * @param grantType Possible values include: 'use', 'manage'
   * @param options The optional parameters
   * @param callback The callback
   */
  createGlobalCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction, grantType: Models.GrantType5, options: Models.OrchestrationAPICreateGlobalCapabilityGrantOptionalParams, callback: msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>): void;
  createGlobalCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction, grantType: Models.GrantType5, options?: Models.OrchestrationAPICreateGlobalCapabilityGrantOptionalParams | msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>, callback?: msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>): Promise<Models.CreateGlobalCapabilityGrantResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        grantAction,
        grantType,
        options
      },
      createGlobalCapabilityGrantOperationSpec,
      callback) as Promise<Models.CreateGlobalCapabilityGrantResponse>;
  }

  /**
   * Revoke granted rights to Use or Manage a Workbench Capability from a User
   * @summary Revoke a Granted Capability
   * @param capabilityID
   * @param userID
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  revokeGlobalCapabilityGrant(capabilityID: string, userID: string, options?: Models.OrchestrationAPIRevokeGlobalCapabilityGrantOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param callback The callback
   */
  revokeGlobalCapabilityGrant(capabilityID: string, userID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param options The optional parameters
   * @param callback The callback
   */
  revokeGlobalCapabilityGrant(capabilityID: string, userID: string, options: Models.OrchestrationAPIRevokeGlobalCapabilityGrantOptionalParams, callback: msRest.ServiceCallback<void>): void;
  revokeGlobalCapabilityGrant(capabilityID: string, userID: string, options?: Models.OrchestrationAPIRevokeGlobalCapabilityGrantOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        options
      },
      revokeGlobalCapabilityGrantOperationSpec,
      callback);
  }

  /**
   * Rreturns all public capabilities, plus any non-public capabilities that
   * requesting the user is granted.
   * @summary List All Workbench Capabilities
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkbenchCapabilitiesResponse>
   */
  listTeamWorkbenchCapabilities(options?: msRest.RequestOptionsBase): Promise<Models.ListTeamWorkbenchCapabilitiesResponse>;
  /**
   * @param callback The callback
   */
  listTeamWorkbenchCapabilities(callback: msRest.ServiceCallback<Models.Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkbenchCapabilities(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema>): void;
  listTeamWorkbenchCapabilities(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkbenchCapabilitiesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listTeamWorkbenchCapabilitiesOperationSpec,
      callback) as Promise<Models.ListTeamWorkbenchCapabilitiesResponse>;
  }

  /**
   * Get a specific workbench capability
   * @summary Get a Workbench Capability
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamWorkbenchCapabilityResponse>
   */
  getTeamWorkbenchCapability(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTeamWorkbenchCapabilityResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  getTeamWorkbenchCapability(capabilityID: string, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamWorkbenchCapability(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.WorkbenchCapability>): void;
  getTeamWorkbenchCapability(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.WorkbenchCapability>, callback?: msRest.ServiceCallback<Models.WorkbenchCapability>): Promise<Models.GetTeamWorkbenchCapabilityResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      getTeamWorkbenchCapabilityOperationSpec,
      callback) as Promise<Models.GetTeamWorkbenchCapabilityResponse>;
  }

  /**
   * Request to use a capability. Returns 200 OK if requesting user
   * is granted that capability, or 403 Forbidden otherwise.
   * @summary Use a Granted Capability
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  useTeamCapabilityGrant(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  useTeamCapabilityGrant(capabilityID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  useTeamCapabilityGrant(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  useTeamCapabilityGrant(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      useTeamCapabilityGrantOperationSpec,
      callback);
  }

  /**
   * Lists all grants of a specific capability across all users in the team.
   * If "-" is specified for `capabilityID`, then all grants for
   * all managed capabilities will be included. Only capabilities
   * to which the requesting user has Manage rights will be included.
   * @summary List All Workbench Capability Grants
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkbenchCapabilityGrantsResponse>
   */
  listTeamWorkbenchCapabilityGrants(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTeamWorkbenchCapabilityGrantsResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrants(capabilityID: string, callback: msRest.ServiceCallback<Models.Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrants(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>): void;
  listTeamWorkbenchCapabilityGrants(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkbenchCapabilityGrantsResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      listTeamWorkbenchCapabilityGrantsOperationSpec,
      callback) as Promise<Models.ListTeamWorkbenchCapabilityGrantsResponse>;
  }

  /**
   * Lists all grants of a specific capability to a specific user.
   * If "-" is specified for `capabilityID`, then all grants for
   * all capabilities to that user will be included. Only capabilities
   * to which the requesting user has Manage rights will be included.
   * @summary List All Workbench Capability Grants to a User
   * @param capabilityID
   * @param userID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkbenchCapabilityGrantsToUserResponse>
   */
  listTeamWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTeamWorkbenchCapabilityGrantsToUserResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, callback: msRest.ServiceCallback<Models.Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>): void;
  listTeamWorkbenchCapabilityGrantsToUser(capabilityID: string, userID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkbenchCapabilityGrantsToUserResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        options
      },
      listTeamWorkbenchCapabilityGrantsToUserOperationSpec,
      callback) as Promise<Models.ListTeamWorkbenchCapabilityGrantsToUserResponse>;
  }

  /**
   * Lists all grants to either use or manage a specific capability to a specific user.
   * If "-" is specified for `capabilityID` then grants for all capabilities
   * will be included.
   * If "-" is specified for `userID` then all grants across all users
   * on the team will be included. Only capabilities
   * to which the requesting user has Manage rights will be included.
   * @summary List All Workbench Capability Grants to a User by Grant Type
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkbenchCapabilityGrantsToUser1Response>
   */
  listTeamWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType6, options?: msRest.RequestOptionsBase): Promise<Models.ListTeamWorkbenchCapabilityGrantsToUser1Response>;
  /**
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType6, callback: msRest.ServiceCallback<Models.Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType6, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>): void;
  listTeamWorkbenchCapabilityGrantsToUser1(capabilityID: string, userID: string, grantType: Models.GrantType6, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkbenchCapabilityGrantsToUser1Response> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        grantType,
        options
      },
      listTeamWorkbenchCapabilityGrantsToUser1OperationSpec,
      callback) as Promise<Models.ListTeamWorkbenchCapabilityGrantsToUser1Response>;
  }

  /**
   * Logs all grants of a specific capability across all users in the team.
   * If "-" is specified for `capabilityID`, then all grants for
   * all managed capabilities will be included. Only grants of capabilities
   * to which the requesting user has Manage rights will be included.
   * @summary Logs All Workbench Capability Grants
   * @param capabilityID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkbenchCapabilityGrantLogsResponse>
   */
  listTeamWorkbenchCapabilityGrantLogs(capabilityID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTeamWorkbenchCapabilityGrantLogsResponse>;
  /**
   * @param capabilityID
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantLogs(capabilityID: string, callback: msRest.ServiceCallback<Models.PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantLogs(capabilityID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema>): void;
  listTeamWorkbenchCapabilityGrantLogs(capabilityID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkbenchCapabilityGrantLogsResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        options
      },
      listTeamWorkbenchCapabilityGrantLogsOperationSpec,
      callback) as Promise<Models.ListTeamWorkbenchCapabilityGrantLogsResponse>;
  }

  /**
   * Logs all grants of a specific capability to a specific user.
   * If "-" is specified for `capabilityID`, then all grants for
   * all capabilities to that user will be included. Only grants of
   * capabilities to which the requesting user has Manage rights
   * will be included.
   * @summary Logs All Workbench Capability Grants to a User
   * @param capabilityID
   * @param userID
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTeamWorkbenchCapabilityGrantLogs1Response>
   */
  listTeamWorkbenchCapabilityGrantLogs1(capabilityID: string, userID: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTeamWorkbenchCapabilityGrantLogs1Response>;
  /**
   * @param capabilityID
   * @param userID
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantLogs1(capabilityID: string, userID: string, callback: msRest.ServiceCallback<Models.Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param options The optional parameters
   * @param callback The callback
   */
  listTeamWorkbenchCapabilityGrantLogs1(capabilityID: string, userID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema>): void;
  listTeamWorkbenchCapabilityGrantLogs1(capabilityID: string, userID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema>): Promise<Models.ListTeamWorkbenchCapabilityGrantLogs1Response> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        options
      },
      listTeamWorkbenchCapabilityGrantLogs1OperationSpec,
      callback) as Promise<Models.ListTeamWorkbenchCapabilityGrantLogs1Response>;
  }

  /**
   * Logs all grants to either use or manage a specific capability to a specific user.
   * If "-" is specified for `capabilityID` then grants for all capabilities
   * will be included.
   * If "-" is specified for `userID` then all grants across all users
   * on the team will be included. Only grants of capabilities
   * to which the requesting user has Manage rights will be included.
   * @summary Logs All Workbench Capability Grants to a User by Grant Type
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalWorkbenchCapabilityGrantLogssResponse>
   */
  listGlobalWorkbenchCapabilityGrantLogss(capabilityID: string, userID: string, grantType: Models.GrantType7, options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalWorkbenchCapabilityGrantLogssResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrantLogss(capabilityID: string, userID: string, grantType: Models.GrantType7, callback: msRest.ServiceCallback<Models.PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param grantType Possible values include: 'use', 'manage'
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalWorkbenchCapabilityGrantLogss(capabilityID: string, userID: string, grantType: Models.GrantType7, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema>): void;
  listGlobalWorkbenchCapabilityGrantLogss(capabilityID: string, userID: string, grantType: Models.GrantType7, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema>): Promise<Models.ListGlobalWorkbenchCapabilityGrantLogssResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        grantType,
        options
      },
      listGlobalWorkbenchCapabilityGrantLogssOperationSpec,
      callback) as Promise<Models.ListGlobalWorkbenchCapabilityGrantLogssResponse>;
  }

  /**
   * Grant or Deny rights to Use or Manage a Workbench Capability to a User.
   * Requesting user must have Manage rights to the capability being granted.
   * @summary Grant Capability
   * @param capabilityID
   * @param userID
   * @param grantAction Possible values include: 'grant', 'deny'
   * @param grantType Possible values include: 'use', 'manage'
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTeamCapabilityGrantResponse>
   */
  createTeamCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction1, grantType: Models.GrantType8, options?: Models.OrchestrationAPICreateTeamCapabilityGrantOptionalParams): Promise<Models.CreateTeamCapabilityGrantResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param grantAction Possible values include: 'grant', 'deny'
   * @param grantType Possible values include: 'use', 'manage'
   * @param callback The callback
   */
  createTeamCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction1, grantType: Models.GrantType8, callback: msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param grantAction Possible values include: 'grant', 'deny'
   * @param grantType Possible values include: 'use', 'manage'
   * @param options The optional parameters
   * @param callback The callback
   */
  createTeamCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction1, grantType: Models.GrantType8, options: Models.OrchestrationAPICreateTeamCapabilityGrantOptionalParams, callback: msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>): void;
  createTeamCapabilityGrant(capabilityID: string, userID: string, grantAction: Models.GrantAction1, grantType: Models.GrantType8, options?: Models.OrchestrationAPICreateTeamCapabilityGrantOptionalParams | msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>, callback?: msRest.ServiceCallback<Models.WorkbenchCapabilityGrant>): Promise<Models.CreateTeamCapabilityGrantResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        grantAction,
        grantType,
        options
      },
      createTeamCapabilityGrantOperationSpec,
      callback) as Promise<Models.CreateTeamCapabilityGrantResponse>;
  }

  /**
   * Revoke granted rights to Use or Manage a Workbench Capability from a User.
   * Requesting user must have Manage rights to the capability being revoked.
   * @summary Revoke a Granted Capability
   * @param capabilityID
   * @param userID
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  revokeTeamCapabilityGrant(capabilityID: string, userID: string, options?: Models.OrchestrationAPIRevokeTeamCapabilityGrantOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param capabilityID
   * @param userID
   * @param callback The callback
   */
  revokeTeamCapabilityGrant(capabilityID: string, userID: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param capabilityID
   * @param userID
   * @param options The optional parameters
   * @param callback The callback
   */
  revokeTeamCapabilityGrant(capabilityID: string, userID: string, options: Models.OrchestrationAPIRevokeTeamCapabilityGrantOptionalParams, callback: msRest.ServiceCallback<void>): void;
  revokeTeamCapabilityGrant(capabilityID: string, userID: string, options?: Models.OrchestrationAPIRevokeTeamCapabilityGrantOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        capabilityID,
        userID,
        options
      },
      revokeTeamCapabilityGrantOperationSpec,
      callback);
  }

  /**
   * Get authinfo for a specific team
   * @summary Get authinfo
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthinfoResponse>
   */
  getAuthinfo(options?: msRest.RequestOptionsBase): Promise<Models.GetAuthinfoResponse>;
  /**
   * @param callback The callback
   */
  getAuthinfo(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuthinfo(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  getAuthinfo(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.GetAuthinfoResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuthinfoOperationSpec,
      callback) as Promise<Models.GetAuthinfoResponse>;
  }

  /**
   * Get auth for a specific team
   * @summary Get auth
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthResponse>
   */
  getAuth(options?: Models.OrchestrationAPIGetAuthOptionalParams): Promise<Models.GetAuthResponse>;
  /**
   * @param callback The callback
   */
  getAuth(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuth(options: Models.OrchestrationAPIGetAuthOptionalParams, callback: msRest.ServiceCallback<Models.Team>): void;
  getAuth(options?: Models.OrchestrationAPIGetAuthOptionalParams | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.GetAuthResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuthOperationSpec,
      callback) as Promise<Models.GetAuthResponse>;
  }

  /**
   * Authenticate a specific user
   * @summary Authenticate user
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthenticateUserResponse>
   */
  authenticateUser(options?: msRest.RequestOptionsBase): Promise<Models.AuthenticateUserResponse>;
  /**
   * @param callback The callback
   */
  authenticateUser(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  authenticateUser(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  authenticateUser(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.AuthenticateUserResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      authenticateUserOperationSpec,
      callback) as Promise<Models.AuthenticateUserResponse>;
  }

  /**
   * Community auth
   * @summary Community auth
   * @param [options] The optional parameters
   * @returns Promise<Models.CommunityAuthMethodResponse>
   */
  communityAuthMethod(options?: msRest.RequestOptionsBase): Promise<Models.CommunityAuthMethodResponse>;
  /**
   * @param callback The callback
   */
  communityAuthMethod(callback: msRest.ServiceCallback<Models.CommunityAuth>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communityAuthMethod(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CommunityAuth>): void;
  communityAuthMethod(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunityAuth>, callback?: msRest.ServiceCallback<Models.CommunityAuth>): Promise<Models.CommunityAuthMethodResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      communityAuthMethodOperationSpec,
      callback) as Promise<Models.CommunityAuthMethodResponse>;
  }

  /**
   * Community auth V2
   * @summary Community auth V2
   * @param [options] The optional parameters
   * @returns Promise<Models.CommunityAuthV2MethodResponse>
   */
  communityAuthV2Method(options?: msRest.RequestOptionsBase): Promise<Models.CommunityAuthV2MethodResponse>;
  /**
   * @param callback The callback
   */
  communityAuthV2Method(callback: msRest.ServiceCallback<Models.CommunityAuthV2>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communityAuthV2Method(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CommunityAuthV2>): void;
  communityAuthV2Method(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunityAuthV2>, callback?: msRest.ServiceCallback<Models.CommunityAuthV2>): Promise<Models.CommunityAuthV2MethodResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      communityAuthV2MethodOperationSpec,
      callback) as Promise<Models.CommunityAuthV2MethodResponse>;
  }

  /**
   * Get service provider metadata for a specific team
   * @summary Get service provider metadata
   * @param [options] The optional parameters
   * @returns Promise<Models.GetServiceProviderMetadataResponse>
   */
  getServiceProviderMetadata(options?: msRest.RequestOptionsBase): Promise<Models.GetServiceProviderMetadataResponse>;
  /**
   * @param callback The callback
   */
  getServiceProviderMetadata(callback: msRest.ServiceCallback<Models.SAMLServiceProviderMetadata>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getServiceProviderMetadata(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SAMLServiceProviderMetadata>): void;
  getServiceProviderMetadata(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SAMLServiceProviderMetadata>, callback?: msRest.ServiceCallback<Models.SAMLServiceProviderMetadata>): Promise<Models.GetServiceProviderMetadataResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getServiceProviderMetadataOperationSpec,
      callback) as Promise<Models.GetServiceProviderMetadataResponse>;
  }

  /**
   * SAML login to a specific team
   * @summary SAML login
   * @param [options] The optional parameters
   * @returns Promise<Models.SamlLoginResponse>
   */
  samlLogin(options?: msRest.RequestOptionsBase): Promise<Models.SamlLoginResponse>;
  /**
   * @param callback The callback
   */
  samlLogin(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  samlLogin(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  samlLogin(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SamlLoginResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      samlLoginOperationSpec,
      callback) as Promise<Models.SamlLoginResponse>;
  }

  /**
   * Authenticate to a specific team with SAML
   * @summary Authenticate with SAML
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthenticateWithSamlResponse>
   */
  authenticateWithSaml(options?: msRest.RequestOptionsBase): Promise<Models.AuthenticateWithSamlResponse>;
  /**
   * @param callback The callback
   */
  authenticateWithSaml(callback: msRest.ServiceCallback<Models.SAMLAuth>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  authenticateWithSaml(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SAMLAuth>): void;
  authenticateWithSaml(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SAMLAuth>, callback?: msRest.ServiceCallback<Models.SAMLAuth>): Promise<Models.AuthenticateWithSamlResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      authenticateWithSamlOperationSpec,
      callback) as Promise<Models.AuthenticateWithSamlResponse>;
  }

  /**
   * Create encryption key for a specific team
   * @summary Create encryption key
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateEncryptionKeyResponse>
   */
  createEncryptionKey(options?: msRest.RequestOptionsBase): Promise<Models.CreateEncryptionKeyResponse>;
  /**
   * @param callback The callback
   */
  createEncryptionKey(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createEncryptionKey(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  createEncryptionKey(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.CreateEncryptionKeyResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createEncryptionKeyOperationSpec,
      callback) as Promise<Models.CreateEncryptionKeyResponse>;
  }

  /**
   * Get files for a specific team
   * @summary List files
   * @param [options] The optional parameters
   * @returns Promise<Models.ListtFilesResponse>
   */
  listtFiles(options?: msRest.RequestOptionsBase): Promise<Models.ListtFilesResponse>;
  /**
   * @param callback The callback
   */
  listtFiles(callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listtFiles(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  listtFiles(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListtFilesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listtFilesOperationSpec,
      callback) as Promise<Models.ListtFilesResponse>;
  }

  /**
   * Create a file for a specific team
   * @summary Create file
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateFileResponse>
   */
  createFile(options?: Models.OrchestrationAPICreateFileOptionalParams): Promise<Models.CreateFileResponse>;
  /**
   * @param callback The callback
   */
  createFile(callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createFile(options: Models.OrchestrationAPICreateFileOptionalParams, callback: msRest.ServiceCallback<Models.File>): void;
  createFile(options?: Models.OrchestrationAPICreateFileOptionalParams | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.CreateFileResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createFileOperationSpec,
      callback) as Promise<Models.CreateFileResponse>;
  }

  /**
   * Get a file from a specific team
   * @summary Get file
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFileResponse>
   */
  getFile(fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetFileResponse>;
  /**
   * @param fileid
   * @param callback The callback
   */
  getFile(fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  getFile(fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  getFile(fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.GetFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        options
      },
      getFileOperationSpec,
      callback) as Promise<Models.GetFileResponse>;
  }

  /**
   * Update a file for a specific team
   * @summary Update file
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateFileResponse>
   */
  updateFile(fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateFileResponse>;
  /**
   * @param fileid
   * @param callback The callback
   */
  updateFile(fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateFile(fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  updateFile(fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.UpdateFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        options
      },
      updateFileOperationSpec,
      callback) as Promise<Models.UpdateFileResponse>;
  }

  /**
   * Delete file from a specific team
   * @summary Delete file
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteFileResponse>
   */
  deleteFile(fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteFileResponse>;
  /**
   * @param fileid
   * @param callback The callback
   */
  deleteFile(fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteFile(fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  deleteFile(fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.DeleteFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        options
      },
      deleteFileOperationSpec,
      callback) as Promise<Models.DeleteFileResponse>;
  }

  /**
   * Copy a specific file for a specific team
   * @summary Copy file
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.CopyFileResponse>
   */
  copyFile(fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.CopyFileResponse>;
  /**
   * @param fileid
   * @param callback The callback
   */
  copyFile(fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  copyFile(fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  copyFile(fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.CopyFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        options
      },
      copyFileOperationSpec,
      callback) as Promise<Models.CopyFileResponse>;
  }

  /**
   * Download a specific file from a specific team
   * @summary Download file
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadFileResponse>
   */
  downloadFile(fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadFileResponse>;
  /**
   * @param fileid
   * @param callback The callback
   */
  downloadFile(fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadFile(fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  downloadFile(fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.DownloadFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        options
      },
      downloadFileOperationSpec,
      callback) as Promise<Models.DownloadFileResponse>;
  }

  /**
   * Execute a specific webhook for a specific team
   * @summary Execute webhook
   * @param webhookname
   * @param [options] The optional parameters
   * @returns Promise<Models.ExecuteWebhookResponse>
   */
  executeWebhook(webhookname: string, options?: msRest.RequestOptionsBase): Promise<Models.ExecuteWebhookResponse>;
  /**
   * @param webhookname
   * @param callback The callback
   */
  executeWebhook(webhookname: string, callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param webhookname
   * @param options The optional parameters
   * @param callback The callback
   */
  executeWebhook(webhookname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  executeWebhook(webhookname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ExecuteWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookname,
        options
      },
      executeWebhookOperationSpec,
      callback) as Promise<Models.ExecuteWebhookResponse>;
  }

  /**
   * Execute webform webhook
   * @summary Execute webform webhook
   * @param webhookname
   * @param [options] The optional parameters
   * @returns Promise<Models.ExecuteWebformWebhookResponse>
   */
  executeWebformWebhook(webhookname: string, options?: msRest.RequestOptionsBase): Promise<Models.ExecuteWebformWebhookResponse>;
  /**
   * @param webhookname
   * @param callback The callback
   */
  executeWebformWebhook(webhookname: string, callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param webhookname
   * @param options The optional parameters
   * @param callback The callback
   */
  executeWebformWebhook(webhookname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  executeWebformWebhook(webhookname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ExecuteWebformWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookname,
        options
      },
      executeWebformWebhookOperationSpec,
      callback) as Promise<Models.ExecuteWebformWebhookResponse>;
  }

  /**
   * Get groups for a specific team
   * @summary Get groups
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFroupsResponse>
   */
  getFroups(options?: msRest.RequestOptionsBase): Promise<Models.GetFroupsResponse>;
  /**
   * @param callback The callback
   */
  getFroups(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFroups(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  getFroups(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.GetFroupsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getFroupsOperationSpec,
      callback) as Promise<Models.GetFroupsResponse>;
  }

  /**
   * Create group in a specific team
   * @summary Create group
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateGroupResponse>
   */
  createGroup(options?: msRest.RequestOptionsBase): Promise<Models.CreateGroupResponse>;
  /**
   * @param callback The callback
   */
  createGroup(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createGroup(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  createGroup(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.CreateGroupResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createGroupOperationSpec,
      callback) as Promise<Models.CreateGroupResponse>;
  }

  /**
   * Get all the groups a user is in
   * @summary Get a user's groups
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUsersGroupsResponse>
   */
  getUsersGroups(username: string, options?: msRest.RequestOptionsBase): Promise<Models.GetUsersGroupsResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  getUsersGroups(username: string, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  getUsersGroups(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  getUsersGroups(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.GetUsersGroupsResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      getUsersGroupsOperationSpec,
      callback) as Promise<Models.GetUsersGroupsResponse>;
  }

  /**
   * Update the groups a user is in
   * @summary Update a user's groups
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateUserGroupsResponse>
   */
  updateUserGroups(username: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateUserGroupsResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  updateUserGroups(username: string, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  updateUserGroups(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  updateUserGroups(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.UpdateUserGroupsResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      updateUserGroupsOperationSpec,
      callback) as Promise<Models.UpdateUserGroupsResponse>;
  }

  /**
   * Get group members for a specific team
   * @summary List group members
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGroupMembersResponse>
   */
  listGroupMembers(username: string, options?: msRest.RequestOptionsBase): Promise<Models.ListGroupMembersResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listGroupMembers(username: string, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listGroupMembers(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  listGroupMembers(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.ListGroupMembersResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listGroupMembersOperationSpec,
      callback) as Promise<Models.ListGroupMembersResponse>;
  }

  /**
   * Get integrations for a specific team
   * @summary List integrations
   * @param [options] The optional parameters
   * @returns Promise<Models.ListIntegrationsResponse>
   */
  listIntegrations(options?: msRest.RequestOptionsBase): Promise<Models.ListIntegrationsResponse>;
  /**
   * @param callback The callback
   */
  listIntegrations(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listIntegrations(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  listIntegrations(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.ListIntegrationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listIntegrationsOperationSpec,
      callback) as Promise<Models.ListIntegrationsResponse>;
  }

  /**
   * Create integration for a specific team
   * @summary Create integration
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateIntegrationResponse>
   */
  createIntegration(options?: msRest.RequestOptionsBase): Promise<Models.CreateIntegrationResponse>;
  /**
   * @param callback The callback
   */
  createIntegration(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createIntegration(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  createIntegration(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.CreateIntegrationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createIntegrationOperationSpec,
      callback) as Promise<Models.CreateIntegrationResponse>;
  }

  /**
   * Healthy check for the integrations for a specific team
   * @summary Integrations healthcheck
   * @param [options] The optional parameters
   * @returns Promise<Models.IntegrationsHealthcheckResponse>
   */
  integrationsHealthcheck(options?: msRest.RequestOptionsBase): Promise<Models.IntegrationsHealthcheckResponse>;
  /**
   * @param callback The callback
   */
  integrationsHealthcheck(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  integrationsHealthcheck(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  integrationsHealthcheck(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.IntegrationsHealthcheckResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      integrationsHealthcheckOperationSpec,
      callback) as Promise<Models.IntegrationsHealthcheckResponse>;
  }

  /**
   * Get integration by username for a specific team
   * @summary Get integration
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.GetIntegrationResponse>
   */
  getIntegration(username: string, options?: msRest.RequestOptionsBase): Promise<Models.GetIntegrationResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  getIntegration(username: string, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  getIntegration(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  getIntegration(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.GetIntegrationResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      getIntegrationOperationSpec,
      callback) as Promise<Models.GetIntegrationResponse>;
  }

  /**
   * Update integration for a specific team
   * @summary Update integration
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateIntegrationResponse>
   */
  updateIntegration(username: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateIntegrationResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  updateIntegration(username: string, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  updateIntegration(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  updateIntegration(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.UpdateIntegrationResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      updateIntegrationOperationSpec,
      callback) as Promise<Models.UpdateIntegrationResponse>;
  }

  /**
   * Delete a specific integration froma specific team
   * @summary Delete integration
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteIntegrationResponse>
   */
  deleteIntegration(username: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteIntegrationResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  deleteIntegration(username: string, callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteIntegration(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  deleteIntegration(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.DeleteIntegrationResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      deleteIntegrationOperationSpec,
      callback) as Promise<Models.DeleteIntegrationResponse>;
  }

  /**
   * Get all the invites to a specific team
   * @summary List invites
   * @param [options] The optional parameters
   * @returns Promise<Models.ListInvitesResponse>
   */
  listInvites(options?: msRest.RequestOptionsBase): Promise<Models.ListInvitesResponse>;
  /**
   * @param callback The callback
   */
  listInvites(callback: msRest.ServiceCallback<Models.Invite[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listInvites(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Invite[]>): void;
  listInvites(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Invite[]>, callback?: msRest.ServiceCallback<Models.Invite[]>): Promise<Models.ListInvitesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listInvitesOperationSpec,
      callback) as Promise<Models.ListInvitesResponse>;
  }

  /**
   * Create an invite to a specific team
   * @summary Create invite
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateInviteResponse>
   */
  createInvite(body: Models.InvitesNew, options?: msRest.RequestOptionsBase): Promise<Models.CreateInviteResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  createInvite(body: Models.InvitesNew, callback: msRest.ServiceCallback<Models.Invite>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createInvite(body: Models.InvitesNew, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Invite>): void;
  createInvite(body: Models.InvitesNew, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Invite>, callback?: msRest.ServiceCallback<Models.Invite>): Promise<Models.CreateInviteResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createInviteOperationSpec,
      callback) as Promise<Models.CreateInviteResponse>;
  }

  /**
   * Get an invite for a specific team
   * @summary Get invite
   * @param invitetoken
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInviteResponse>
   */
  getInvite(invitetoken: string, options?: msRest.RequestOptionsBase): Promise<Models.GetInviteResponse>;
  /**
   * @param invitetoken
   * @param callback The callback
   */
  getInvite(invitetoken: string, callback: msRest.ServiceCallback<Models.Invite>): void;
  /**
   * @param invitetoken
   * @param options The optional parameters
   * @param callback The callback
   */
  getInvite(invitetoken: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Invite>): void;
  getInvite(invitetoken: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Invite>, callback?: msRest.ServiceCallback<Models.Invite>): Promise<Models.GetInviteResponse> {
    return this.sendOperationRequest(
      {
        invitetoken,
        options
      },
      getInviteOperationSpec,
      callback) as Promise<Models.GetInviteResponse>;
  }

  /**
   * Create an invite token for a specific team
   * @summary Create invite token
   * @param invitetoken
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateInviteTokenResponse>
   */
  createInviteToken(invitetoken: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateInviteTokenResponse>;
  /**
   * @param invitetoken
   * @param callback The callback
   */
  createInviteToken(invitetoken: string, callback: msRest.ServiceCallback<Models.Invite>): void;
  /**
   * @param invitetoken
   * @param options The optional parameters
   * @param callback The callback
   */
  createInviteToken(invitetoken: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Invite>): void;
  createInviteToken(invitetoken: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Invite>, callback?: msRest.ServiceCallback<Models.Invite>): Promise<Models.CreateInviteTokenResponse> {
    return this.sendOperationRequest(
      {
        invitetoken,
        options
      },
      createInviteTokenOperationSpec,
      callback) as Promise<Models.CreateInviteTokenResponse>;
  }

  /**
   * Get a list of processes for a specific team
   * @summary List processes
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessesResponse>
   */
  listProcesses(options?: Models.OrchestrationAPIListProcessesOptionalParams): Promise<Models.ListProcessesResponse>;
  /**
   * @param callback The callback
   */
  listProcesses(callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcesses(options: Models.OrchestrationAPIListProcessesOptionalParams, callback: msRest.ServiceCallback<Models.Process>): void;
  listProcesses(options?: Models.OrchestrationAPIListProcessesOptionalParams | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.ListProcessesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listProcessesOperationSpec,
      callback) as Promise<Models.ListProcessesResponse>;
  }

  /**
   * Create a process for a specific team
   * @summary Create process
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessResponse>
   */
  createProcess(body: Models.Process, options?: msRest.RequestOptionsBase): Promise<Models.CreateProcessResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  createProcess(body: Models.Process, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcess(body: Models.Process, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  createProcess(body: Models.Process, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.CreateProcessResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createProcessOperationSpec,
      callback) as Promise<Models.CreateProcessResponse>;
  }

  /**
   * Get a specific process for a specific team
   * @summary Get process
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessResponse>
   */
  getProcess(processname: string, options?: Models.OrchestrationAPIGetProcessOptionalParams): Promise<Models.GetProcessResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  getProcess(processname: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcess(processname: string, options: Models.OrchestrationAPIGetProcessOptionalParams, callback: msRest.ServiceCallback<Models.Process>): void;
  getProcess(processname: string, options?: Models.OrchestrationAPIGetProcessOptionalParams | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.GetProcessResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      getProcessOperationSpec,
      callback) as Promise<Models.GetProcessResponse>;
  }

  /**
   * Update a specific process
   * @summary Update process
   * @param body
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessResponse>
   */
  updateProcess(body: Models.ProcessPartial, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessResponse>;
  /**
   * @param body
   * @param processname
   * @param callback The callback
   */
  updateProcess(body: Models.ProcessPartial, processname: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param body
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcess(body: Models.ProcessPartial, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  updateProcess(body: Models.ProcessPartial, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.UpdateProcessResponse> {
    return this.sendOperationRequest(
      {
        body,
        processname,
        options
      },
      updateProcessOperationSpec,
      callback) as Promise<Models.UpdateProcessResponse>;
  }

  /**
   * Delete a specific process
   * @summary Delete process
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteProcessResponse>
   */
  deleteProcess(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteProcessResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  deleteProcess(processname: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteProcess(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  deleteProcess(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.DeleteProcessResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      deleteProcessOperationSpec,
      callback) as Promise<Models.DeleteProcessResponse>;
  }

  /**
   * Update a process data retention policy
   * @summary Updates a process data retention policy
   * @param body
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessDataRetentionPolicyResponse>
   */
  updateProcessDataRetentionPolicy(body: Models.Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema, processid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessDataRetentionPolicyResponse>;
  /**
   * @param body
   * @param processid
   * @param callback The callback
   */
  updateProcessDataRetentionPolicy(body: Models.Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema, processid: string, callback: msRest.ServiceCallback<Models.Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema>): void;
  /**
   * @param body
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessDataRetentionPolicy(body: Models.Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema, processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema>): void;
  updateProcessDataRetentionPolicy(body: Models.Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema, processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema>): Promise<Models.UpdateProcessDataRetentionPolicyResponse> {
    return this.sendOperationRequest(
      {
        body,
        processid,
        options
      },
      updateProcessDataRetentionPolicyOperationSpec,
      callback) as Promise<Models.UpdateProcessDataRetentionPolicyResponse>;
  }

  /**
   * Returns a list of available fields with context for a process
   * @summary Returns a list of available fields with context for a process
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessAvailableFieldsResponse>
   */
  getProcessAvailableFields(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessAvailableFieldsResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  getProcessAvailableFields(processid: string, callback: msRest.ServiceCallback<Models.PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessAvailableFields(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema>): void;
  getProcessAvailableFields(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema>): Promise<Models.GetProcessAvailableFieldsResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      getProcessAvailableFieldsOperationSpec,
      callback) as Promise<Models.GetProcessAvailableFieldsResponse>;
  }

  /**
   * Add a field to the list of writable fields on this process. If the field already exists, this
   * has no action
   * @summary Add writable field
   * @param processid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.AddWritableFieldResponse>
   */
  addWritableField(processid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.AddWritableFieldResponse>;
  /**
   * @param processid
   * @param fieldname
   * @param callback The callback
   */
  addWritableField(processid: string, fieldname: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param processid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  addWritableField(processid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  addWritableField(processid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.AddWritableFieldResponse> {
    return this.sendOperationRequest(
      {
        processid,
        fieldname,
        options
      },
      addWritableFieldOperationSpec,
      callback) as Promise<Models.AddWritableFieldResponse>;
  }

  /**
   * Remove a writable field from this process
   * @summary Remove writable field
   * @param processid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.RemoveWritableFieldResponse>
   */
  removeWritableField(processid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.RemoveWritableFieldResponse>;
  /**
   * @param processid
   * @param fieldname
   * @param callback The callback
   */
  removeWritableField(processid: string, fieldname: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param processid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  removeWritableField(processid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  removeWritableField(processid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.RemoveWritableFieldResponse> {
    return this.sendOperationRequest(
      {
        processid,
        fieldname,
        options
      },
      removeWritableFieldOperationSpec,
      callback) as Promise<Models.RemoveWritableFieldResponse>;
  }

  /**
   * Retrieve a list of files from a process
   * @summary List process files
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessFilesResponse>
   */
  listProcessFiles(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessFilesResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listProcessFiles(processname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessFiles(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  listProcessFiles(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListProcessFilesResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listProcessFilesOperationSpec,
      callback) as Promise<Models.ListProcessFilesResponse>;
  }

  /**
   * Create a file for a specific process
   * @summary Create process file
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessFileResponse>
   */
  createProcessFile(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateProcessFileResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  createProcessFile(processname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessFile(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  createProcessFile(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.CreateProcessFileResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      createProcessFileOperationSpec,
      callback) as Promise<Models.CreateProcessFileResponse>;
  }

  /**
   * Update a file for a specific process
   * @summary Update process file
   * @param fileid
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessFileResponse>
   */
  updateProcessFile(fileid: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessFileResponse>;
  /**
   * @param fileid
   * @param processname
   * @param callback The callback
   */
  updateProcessFile(fileid: string, processname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessFile(fileid: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  updateProcessFile(fileid: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.UpdateProcessFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        processname,
        options
      },
      updateProcessFileOperationSpec,
      callback) as Promise<Models.UpdateProcessFileResponse>;
  }

  /**
   * Get a list of runs for a process
   * @summary List process runs
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessRunsResponse>
   */
  listProcessRuns(processname: string, options?: Models.OrchestrationAPIListProcessRunsOptionalParams): Promise<Models.ListProcessRunsResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listProcessRuns(processname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessRuns(processname: string, options: Models.OrchestrationAPIListProcessRunsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  listProcessRuns(processname: string, options?: Models.OrchestrationAPIListProcessRunsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ListProcessRunsResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listProcessRunsOperationSpec,
      callback) as Promise<Models.ListProcessRunsResponse>;
  }

  /**
   * Retrieve a list of steps for a process
   * @summary List process steps
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessStepsResponse>
   */
  listProcessSteps(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessStepsResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listProcessSteps(processname: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessSteps(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  listProcessSteps(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.ListProcessStepsResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listProcessStepsOperationSpec,
      callback) as Promise<Models.ListProcessStepsResponse>;
  }

  /**
   * Update a step in a process
   * @summary Update process step
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessStepsResponse>
   */
  updateProcessSteps(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessStepsResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  updateProcessSteps(processname: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessSteps(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  updateProcessSteps(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.UpdateProcessStepsResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      updateProcessStepsOperationSpec,
      callback) as Promise<Models.UpdateProcessStepsResponse>;
  }

  /**
   * Create a step in a process
   * @summary Create process step
   * @param body
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessStepResponse>
   */
  createProcessStep(body: Models.StepPartial, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateProcessStepResponse>;
  /**
   * @param body
   * @param processname
   * @param callback The callback
   */
  createProcessStep(body: Models.StepPartial, processname: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param body
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessStep(body: Models.StepPartial, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  createProcessStep(body: Models.StepPartial, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.CreateProcessStepResponse> {
    return this.sendOperationRequest(
      {
        body,
        processname,
        options
      },
      createProcessStepOperationSpec,
      callback) as Promise<Models.CreateProcessStepResponse>;
  }

  /**
   * Get step metrics for a specific team/process for the **most 50 recent** completed/aborted/failed
   * runs. This does NOT include test runs.
   *
   * Can be filtered to a specific timeframe with the query params.
   *
   * The `start` and `end` query params format is `YYYY-MM-DD`.
   *
   * The results are paginated and the `nextPageToken` in the response body is to be used in the
   * query string param `pageToken` to get the next page of results.
   * @summary Get step metrics
   * @param processID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetStepMetricsResponse>
   */
  getStepMetrics(processID: string, options?: Models.OrchestrationAPIGetStepMetricsOptionalParams): Promise<Models.GetStepMetricsResponse>;
  /**
   * @param processID
   * @param callback The callback
   */
  getStepMetrics(processID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param processID
   * @param options The optional parameters
   * @param callback The callback
   */
  getStepMetrics(processID: string, options: Models.OrchestrationAPIGetStepMetricsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getStepMetrics(processID: string, options?: Models.OrchestrationAPIGetStepMetricsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetStepMetricsResponse> {
    return this.sendOperationRequest(
      {
        processID,
        options
      },
      getStepMetricsOperationSpec,
      callback) as Promise<Models.GetStepMetricsResponse>;
  }

  /**
   * Get a step in a process
   * @summary Get process step
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessStepResponse>
   */
  getProcessStep(stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessStepResponse>;
  /**
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  getProcessStep(stepname: string, processname: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessStep(stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  getProcessStep(stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.GetProcessStepResponse> {
    return this.sendOperationRequest(
      {
        stepname,
        processname,
        options
      },
      getProcessStepOperationSpec,
      callback) as Promise<Models.GetProcessStepResponse>;
  }

  /**
   * Update a step in a process
   * @summary Update process step
   * @param body
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessStepResponse>
   */
  updateProcessStep(body: Models.StepPartial, stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessStepResponse>;
  /**
   * @param body
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  updateProcessStep(body: Models.StepPartial, stepname: string, processname: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param body
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessStep(body: Models.StepPartial, stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  updateProcessStep(body: Models.StepPartial, stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.UpdateProcessStepResponse> {
    return this.sendOperationRequest(
      {
        body,
        stepname,
        processname,
        options
      },
      updateProcessStepOperationSpec,
      callback) as Promise<Models.UpdateProcessStepResponse>;
  }

  /**
   * Delete a step in a process
   * @summary Delete process step
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteProcessStepResponse>
   */
  deleteProcessStep(stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteProcessStepResponse>;
  /**
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  deleteProcessStep(stepname: string, processname: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteProcessStep(stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  deleteProcessStep(stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.DeleteProcessStepResponse> {
    return this.sendOperationRequest(
      {
        stepname,
        processname,
        options
      },
      deleteProcessStepOperationSpec,
      callback) as Promise<Models.DeleteProcessStepResponse>;
  }

  /**
   * Get a list of files from a step in a process
   * @summary List process step files
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessStepFilesResponse>
   */
  listProcessStepFiles(stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessStepFilesResponse>;
  /**
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  listProcessStepFiles(stepname: string, processname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessStepFiles(stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  listProcessStepFiles(stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListProcessStepFilesResponse> {
    return this.sendOperationRequest(
      {
        stepname,
        processname,
        options
      },
      listProcessStepFilesOperationSpec,
      callback) as Promise<Models.ListProcessStepFilesResponse>;
  }

  /**
   * Add a file to a step in a process
   * @summary Add process step file
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.AddProcessStepFileResponse>
   */
  addProcessStepFile(stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.AddProcessStepFileResponse>;
  /**
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  addProcessStepFile(stepname: string, processname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  addProcessStepFile(stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  addProcessStepFile(stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.AddProcessStepFileResponse> {
    return this.sendOperationRequest(
      {
        stepname,
        processname,
        options
      },
      addProcessStepFileOperationSpec,
      callback) as Promise<Models.AddProcessStepFileResponse>;
  }

  /**
   * Update a file on a step in a process
   * @summary Update process step file
   * @param fileid
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessStepFileResponse>
   */
  updateProcessStepFile(fileid: string, stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessStepFileResponse>;
  /**
   * @param fileid
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  updateProcessStepFile(fileid: string, stepname: string, processname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param fileid
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessStepFile(fileid: string, stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  updateProcessStepFile(fileid: string, stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.UpdateProcessStepFileResponse> {
    return this.sendOperationRequest(
      {
        fileid,
        stepname,
        processname,
        options
      },
      updateProcessStepFileOperationSpec,
      callback) as Promise<Models.UpdateProcessStepFileResponse>;
  }

  /**
   * Get a test of a step in a process
   * @summary Get process step test
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessStepTestResponse>
   */
  getProcessStepTest(stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessStepTestResponse>;
  /**
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  getProcessStepTest(stepname: string, processname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessStepTest(stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getProcessStepTest(stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetProcessStepTestResponse> {
    return this.sendOperationRequest(
      {
        stepname,
        processname,
        options
      },
      getProcessStepTestOperationSpec,
      callback) as Promise<Models.GetProcessStepTestResponse>;
  }

  /**
   * Create a test of a step in a process
   * @summary Create procss step test
   * @param stepname
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessStepTestResponse>
   */
  createProcessStepTest(stepname: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateProcessStepTestResponse>;
  /**
   * @param stepname
   * @param processname
   * @param callback The callback
   */
  createProcessStepTest(stepname: string, processname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param stepname
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessStepTest(stepname: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createProcessStepTest(stepname: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateProcessStepTestResponse> {
    return this.sendOperationRequest(
      {
        stepname,
        processname,
        options
      },
      createProcessStepTestOperationSpec,
      callback) as Promise<Models.CreateProcessStepTestResponse>;
  }

  /**
   * Get all the triggers for a specific process
   * @summary List process triggers
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessTriggersResponse>
   */
  listProcessTriggers(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessTriggersResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listProcessTriggers(processname: string, callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessTriggers(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  listProcessTriggers(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ListProcessTriggersResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listProcessTriggersOperationSpec,
      callback) as Promise<Models.ListProcessTriggersResponse>;
  }

  /**
   * Get the tables for a specific process
   * @summary List process tables
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessTablesResponse>
   */
  listProcessTables(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessTablesResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  listProcessTables(processid: string, callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessTables(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  listProcessTables(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.ListProcessTablesResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      listProcessTablesOperationSpec,
      callback) as Promise<Models.ListProcessTablesResponse>;
  }

  /**
   * Link a process table
   * @summary Link process table
   * @param processid
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.LinkProcessTableResponse>
   */
  linkProcessTable(processid: string, tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.LinkProcessTableResponse>;
  /**
   * @param processid
   * @param tableid
   * @param callback The callback
   */
  linkProcessTable(processid: string, tableid: string, callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param processid
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  linkProcessTable(processid: string, tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  linkProcessTable(processid: string, tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.LinkProcessTableResponse> {
    return this.sendOperationRequest(
      {
        processid,
        tableid,
        options
      },
      linkProcessTableOperationSpec,
      callback) as Promise<Models.LinkProcessTableResponse>;
  }

  /**
   * Unlink a process table
   * @summary Unlink process table
   * @param processid
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.UnlinkProcessTableResponse>
   */
  unlinkProcessTable(processid: string, tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.UnlinkProcessTableResponse>;
  /**
   * @param processid
   * @param tableid
   * @param callback The callback
   */
  unlinkProcessTable(processid: string, tableid: string, callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param processid
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  unlinkProcessTable(processid: string, tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  unlinkProcessTable(processid: string, tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.UnlinkProcessTableResponse> {
    return this.sendOperationRequest(
      {
        processid,
        tableid,
        options
      },
      unlinkProcessTableOperationSpec,
      callback) as Promise<Models.UnlinkProcessTableResponse>;
  }

  /**
   * Get predictors for a specific process
   * @summary list process predictors
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessPredictorsResponse>
   */
  listProcessPredictors(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessPredictorsResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  listProcessPredictors(processid: string, callback: msRest.ServiceCallback<Models.Predictor>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessPredictors(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Predictor>): void;
  listProcessPredictors(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Predictor>, callback?: msRest.ServiceCallback<Models.Predictor>): Promise<Models.ListProcessPredictorsResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      listProcessPredictorsOperationSpec,
      callback) as Promise<Models.ListProcessPredictorsResponse>;
  }

  /**
   * Link process predictor
   * @summary Link process predictor
   * @param processid
   * @param predictorid
   * @param [options] The optional parameters
   * @returns Promise<Models.LinkProcessPredictorResponse>
   */
  linkProcessPredictor(processid: string, predictorid: string, options?: msRest.RequestOptionsBase): Promise<Models.LinkProcessPredictorResponse>;
  /**
   * @param processid
   * @param predictorid
   * @param callback The callback
   */
  linkProcessPredictor(processid: string, predictorid: string, callback: msRest.ServiceCallback<Models.Predictor>): void;
  /**
   * @param processid
   * @param predictorid
   * @param options The optional parameters
   * @param callback The callback
   */
  linkProcessPredictor(processid: string, predictorid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Predictor>): void;
  linkProcessPredictor(processid: string, predictorid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Predictor>, callback?: msRest.ServiceCallback<Models.Predictor>): Promise<Models.LinkProcessPredictorResponse> {
    return this.sendOperationRequest(
      {
        processid,
        predictorid,
        options
      },
      linkProcessPredictorOperationSpec,
      callback) as Promise<Models.LinkProcessPredictorResponse>;
  }

  /**
   * Unlink process predictor
   * @summary Unlink process predictor
   * @param processid
   * @param predictorid
   * @param [options] The optional parameters
   * @returns Promise<Models.UnlinkProcessPredictorResponse>
   */
  unlinkProcessPredictor(processid: string, predictorid: string, options?: msRest.RequestOptionsBase): Promise<Models.UnlinkProcessPredictorResponse>;
  /**
   * @param processid
   * @param predictorid
   * @param callback The callback
   */
  unlinkProcessPredictor(processid: string, predictorid: string, callback: msRest.ServiceCallback<Models.Predictor>): void;
  /**
   * @param processid
   * @param predictorid
   * @param options The optional parameters
   * @param callback The callback
   */
  unlinkProcessPredictor(processid: string, predictorid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Predictor>): void;
  unlinkProcessPredictor(processid: string, predictorid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Predictor>, callback?: msRest.ServiceCallback<Models.Predictor>): Promise<Models.UnlinkProcessPredictorResponse> {
    return this.sendOperationRequest(
      {
        processid,
        predictorid,
        options
      },
      unlinkProcessPredictorOperationSpec,
      callback) as Promise<Models.UnlinkProcessPredictorResponse>;
  }

  /**
   * Get child processes
   * @summary List child processes
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListChildProcessesResponse>
   */
  listChildProcesses(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListChildProcessesResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  listChildProcesses(processid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  listChildProcesses(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  listChildProcesses(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.ListChildProcessesResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      listChildProcessesOperationSpec,
      callback) as Promise<Models.ListChildProcessesResponse>;
  }

  /**
   * Link a child process to a parent process
   * @summary Link child process to parent
   * @param processid
   * @param childprocessid
   * @param [options] The optional parameters
   * @returns Promise<Models.LinkChildProcessResponse>
   */
  linkChildProcess(processid: string, childprocessid: string, options?: msRest.RequestOptionsBase): Promise<Models.LinkChildProcessResponse>;
  /**
   * @param processid
   * @param childprocessid
   * @param callback The callback
   */
  linkChildProcess(processid: string, childprocessid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param childprocessid
   * @param options The optional parameters
   * @param callback The callback
   */
  linkChildProcess(processid: string, childprocessid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  linkChildProcess(processid: string, childprocessid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.LinkChildProcessResponse> {
    return this.sendOperationRequest(
      {
        processid,
        childprocessid,
        options
      },
      linkChildProcessOperationSpec,
      callback) as Promise<Models.LinkChildProcessResponse>;
  }

  /**
   * Unlink a child process from a parent process
   * @summary Unlink child process from parent
   * @param processid
   * @param childprocessid
   * @param [options] The optional parameters
   * @returns Promise<Models.UnlinkChildProcessResponse>
   */
  unlinkChildProcess(processid: string, childprocessid: string, options?: msRest.RequestOptionsBase): Promise<Models.UnlinkChildProcessResponse>;
  /**
   * @param processid
   * @param childprocessid
   * @param callback The callback
   */
  unlinkChildProcess(processid: string, childprocessid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param childprocessid
   * @param options The optional parameters
   * @param callback The callback
   */
  unlinkChildProcess(processid: string, childprocessid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  unlinkChildProcess(processid: string, childprocessid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.UnlinkChildProcessResponse> {
    return this.sendOperationRequest(
      {
        processid,
        childprocessid,
        options
      },
      unlinkChildProcessOperationSpec,
      callback) as Promise<Models.UnlinkChildProcessResponse>;
  }

  /**
   * Get the parent processes of a specific process
   * @summary List parent processes
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListParentProcessesResponse>
   */
  listParentProcesses(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListParentProcessesResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  listParentProcesses(processid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  listParentProcesses(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  listParentProcesses(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.ListParentProcessesResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      listParentProcessesOperationSpec,
      callback) as Promise<Models.ListParentProcessesResponse>;
  }

  /**
   * Link parent process to a specific child process
   * @summary Link parent process to child
   * @param processid
   * @param parentprocessid
   * @param [options] The optional parameters
   * @returns Promise<Models.LinkParentProcessResponse>
   */
  linkParentProcess(processid: string, parentprocessid: string, options?: msRest.RequestOptionsBase): Promise<Models.LinkParentProcessResponse>;
  /**
   * @param processid
   * @param parentprocessid
   * @param callback The callback
   */
  linkParentProcess(processid: string, parentprocessid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param parentprocessid
   * @param options The optional parameters
   * @param callback The callback
   */
  linkParentProcess(processid: string, parentprocessid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  linkParentProcess(processid: string, parentprocessid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.LinkParentProcessResponse> {
    return this.sendOperationRequest(
      {
        processid,
        parentprocessid,
        options
      },
      linkParentProcessOperationSpec,
      callback) as Promise<Models.LinkParentProcessResponse>;
  }

  /**
   * Unlink parent process from a specific child process
   * @summary Unlink parent process from child
   * @param processid
   * @param parentprocessid
   * @param [options] The optional parameters
   * @returns Promise<Models.UnlinkParentProcessResponse>
   */
  unlinkParentProcess(processid: string, parentprocessid: string, options?: msRest.RequestOptionsBase): Promise<Models.UnlinkParentProcessResponse>;
  /**
   * @param processid
   * @param parentprocessid
   * @param callback The callback
   */
  unlinkParentProcess(processid: string, parentprocessid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param parentprocessid
   * @param options The optional parameters
   * @param callback The callback
   */
  unlinkParentProcess(processid: string, parentprocessid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  unlinkParentProcess(processid: string, parentprocessid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.UnlinkParentProcessResponse> {
    return this.sendOperationRequest(
      {
        processid,
        parentprocessid,
        options
      },
      unlinkParentProcessOperationSpec,
      callback) as Promise<Models.UnlinkParentProcessResponse>;
  }

  /**
   * Given a set of step configuration params, evaluate which output fields will be generated by the
   * step.
   * @summary Evaluate step output fields
   * @param processid
   * @param stepname
   * @param [options] The optional parameters
   * @returns Promise<Models.EvaluateStepOutputFieldsResponse>
   */
  evaluateStepOutputFields(processid: string, stepname: string, options?: Models.OrchestrationAPIEvaluateStepOutputFieldsOptionalParams): Promise<Models.EvaluateStepOutputFieldsResponse>;
  /**
   * @param processid
   * @param stepname
   * @param callback The callback
   */
  evaluateStepOutputFields(processid: string, stepname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processid
   * @param stepname
   * @param options The optional parameters
   * @param callback The callback
   */
  evaluateStepOutputFields(processid: string, stepname: string, options: Models.OrchestrationAPIEvaluateStepOutputFieldsOptionalParams, callback: msRest.ServiceCallback<Models.Field>): void;
  evaluateStepOutputFields(processid: string, stepname: string, options?: Models.OrchestrationAPIEvaluateStepOutputFieldsOptionalParams | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.EvaluateStepOutputFieldsResponse> {
    return this.sendOperationRequest(
      {
        processid,
        stepname,
        options
      },
      evaluateStepOutputFieldsOperationSpec,
      callback) as Promise<Models.EvaluateStepOutputFieldsResponse>;
  }

  /**
   * Add a field to the list of writable fields on this step. If the field already exists, this has
   * no action
   * @summary Add writable field to step
   * @param processid
   * @param stepname
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.AddWritableFieldToStepResponse>
   */
  addWritableFieldToStep(processid: string, stepname: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.AddWritableFieldToStepResponse>;
  /**
   * @param processid
   * @param stepname
   * @param fieldname
   * @param callback The callback
   */
  addWritableFieldToStep(processid: string, stepname: string, fieldname: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param processid
   * @param stepname
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  addWritableFieldToStep(processid: string, stepname: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  addWritableFieldToStep(processid: string, stepname: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.AddWritableFieldToStepResponse> {
    return this.sendOperationRequest(
      {
        processid,
        stepname,
        fieldname,
        options
      },
      addWritableFieldToStepOperationSpec,
      callback) as Promise<Models.AddWritableFieldToStepResponse>;
  }

  /**
   * Remove a field from the list of writable fields on this step. If the field does not exist, this
   * has no action
   * @summary Remove writable field from step
   * @param processid
   * @param stepname
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.RemoveWritableFieldFromStepResponse>
   */
  removeWritableFieldFromStep(processid: string, stepname: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.RemoveWritableFieldFromStepResponse>;
  /**
   * @param processid
   * @param stepname
   * @param fieldname
   * @param callback The callback
   */
  removeWritableFieldFromStep(processid: string, stepname: string, fieldname: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param processid
   * @param stepname
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  removeWritableFieldFromStep(processid: string, stepname: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  removeWritableFieldFromStep(processid: string, stepname: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.RemoveWritableFieldFromStepResponse> {
    return this.sendOperationRequest(
      {
        processid,
        stepname,
        fieldname,
        options
      },
      removeWritableFieldFromStepOperationSpec,
      callback) as Promise<Models.RemoveWritableFieldFromStepResponse>;
  }

  /**
   * Get details of a Custom Action
   * @summary Get custom action
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomActionResponse>
   */
  getCustomAction(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCustomActionResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  getCustomAction(processid: string, callback: msRest.ServiceCallback<Models.App>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomAction(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.App>): void;
  getCustomAction(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.App>, callback?: msRest.ServiceCallback<Models.App>): Promise<Models.GetCustomActionResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      getCustomActionOperationSpec,
      callback) as Promise<Models.GetCustomActionResponse>;
  }

  /**
   * Create a custom action
   * @summary Create custom action
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateCustomActionResponse>
   */
  createCustomAction(processid: string, options?: Models.OrchestrationAPICreateCustomActionOptionalParams): Promise<Models.CreateCustomActionResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  createCustomAction(processid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  createCustomAction(processid: string, options: Models.OrchestrationAPICreateCustomActionOptionalParams, callback: msRest.ServiceCallback<Models.Process>): void;
  createCustomAction(processid: string, options?: Models.OrchestrationAPICreateCustomActionOptionalParams | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.CreateCustomActionResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      createCustomActionOperationSpec,
      callback) as Promise<Models.CreateCustomActionResponse>;
  }

  /**
   * Update a custom action
   * @summary Update custom action
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateCustomActionResponse>
   */
  updateCustomAction(processid: string, options?: Models.OrchestrationAPIUpdateCustomActionOptionalParams): Promise<Models.UpdateCustomActionResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  updateCustomAction(processid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomAction(processid: string, options: Models.OrchestrationAPIUpdateCustomActionOptionalParams, callback: msRest.ServiceCallback<Models.Process>): void;
  updateCustomAction(processid: string, options?: Models.OrchestrationAPIUpdateCustomActionOptionalParams | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.UpdateCustomActionResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      updateCustomActionOperationSpec,
      callback) as Promise<Models.UpdateCustomActionResponse>;
  }

  /**
   * Delete a specific custom action
   * @summary Delete custom action
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteCustomActionResponse>
   */
  deleteCustomAction(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteCustomActionResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  deleteCustomAction(processid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteCustomAction(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  deleteCustomAction(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.DeleteCustomActionResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      deleteCustomActionOperationSpec,
      callback) as Promise<Models.DeleteCustomActionResponse>;
  }

  /**
   * Get all the webhooks for a specific process
   * @summary List process webhooks
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessWebhooksResponse>
   */
  listProcessWebhooks(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessWebhooksResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listProcessWebhooks(processname: string, callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessWebhooks(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  listProcessWebhooks(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ListProcessWebhooksResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listProcessWebhooksOperationSpec,
      callback) as Promise<Models.ListProcessWebhooksResponse>;
  }

  /**
   * Get process visibility
   * @summary Get process visibility
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessVisibilityResponse>
   */
  getProcessVisibility(processname: string, options?: Models.OrchestrationAPIGetProcessVisibilityOptionalParams): Promise<Models.GetProcessVisibilityResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  getProcessVisibility(processname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessVisibility(processname: string, options: Models.OrchestrationAPIGetProcessVisibilityOptionalParams, callback: msRest.ServiceCallback<Models.Field>): void;
  getProcessVisibility(processname: string, options?: Models.OrchestrationAPIGetProcessVisibilityOptionalParams | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.GetProcessVisibilityResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      getProcessVisibilityOperationSpec,
      callback) as Promise<Models.GetProcessVisibilityResponse>;
  }

  /**
   * Get upcomings for a specific process
   * @summary List upcomings
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUpcomingsResponse>
   */
  listUpcomings(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListUpcomingsResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listUpcomings(processname: string, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listUpcomings(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  listUpcomings(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Upcoming>, callback?: msRest.ServiceCallback<Models.Upcoming>): Promise<Models.ListUpcomingsResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listUpcomingsOperationSpec,
      callback) as Promise<Models.ListUpcomingsResponse>;
  }

  /**
   * Create an upcoming for a specific process
   * @summary Create upcoming
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateUpcomingResponse>
   */
  createUpcoming(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateUpcomingResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  createUpcoming(processname: string, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  createUpcoming(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  createUpcoming(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Upcoming>, callback?: msRest.ServiceCallback<Models.Upcoming>): Promise<Models.CreateUpcomingResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      createUpcomingOperationSpec,
      callback) as Promise<Models.CreateUpcomingResponse>;
  }

  /**
   * Get a specific upcoming for a specific process
   * @summary Get upcoming
   * @param upcomingid
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessUpcomingResponse>
   */
  getProcessUpcoming(upcomingid: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessUpcomingResponse>;
  /**
   * @param upcomingid
   * @param processname
   * @param callback The callback
   */
  getProcessUpcoming(upcomingid: string, processname: string, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  /**
   * @param upcomingid
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessUpcoming(upcomingid: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  getProcessUpcoming(upcomingid: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Upcoming>, callback?: msRest.ServiceCallback<Models.Upcoming>): Promise<Models.GetProcessUpcomingResponse> {
    return this.sendOperationRequest(
      {
        upcomingid,
        processname,
        options
      },
      getProcessUpcomingOperationSpec,
      callback) as Promise<Models.GetProcessUpcomingResponse>;
  }

  /**
   * Update a specific upcoming for a specific process
   * @summary Update upcoming
   * @param upcomingid
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessUpcomingResponse>
   */
  updateProcessUpcoming(upcomingid: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessUpcomingResponse>;
  /**
   * @param upcomingid
   * @param processname
   * @param callback The callback
   */
  updateProcessUpcoming(upcomingid: string, processname: string, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  /**
   * @param upcomingid
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessUpcoming(upcomingid: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  updateProcessUpcoming(upcomingid: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Upcoming>, callback?: msRest.ServiceCallback<Models.Upcoming>): Promise<Models.UpdateProcessUpcomingResponse> {
    return this.sendOperationRequest(
      {
        upcomingid,
        processname,
        options
      },
      updateProcessUpcomingOperationSpec,
      callback) as Promise<Models.UpdateProcessUpcomingResponse>;
  }

  /**
   * Delete a specific upcoming from a specific process
   * @summary Delete upcoming
   * @param upcomingid
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteProcessUpcomingResponse>
   */
  deleteProcessUpcoming(upcomingid: string, processname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteProcessUpcomingResponse>;
  /**
   * @param upcomingid
   * @param processname
   * @param callback The callback
   */
  deleteProcessUpcoming(upcomingid: string, processname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param upcomingid
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteProcessUpcoming(upcomingid: string, processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteProcessUpcoming(upcomingid: string, processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteProcessUpcomingResponse> {
    return this.sendOperationRequest(
      {
        upcomingid,
        processname,
        options
      },
      deleteProcessUpcomingOperationSpec,
      callback) as Promise<Models.DeleteProcessUpcomingResponse>;
  }

  /**
   * Get the fields for a specific process
   * @summary List process fields
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessFieldsResponse>
   */
  listProcessFields(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessFieldsResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  listProcessFields(processname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessFields(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  listProcessFields(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.ListProcessFieldsResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      listProcessFieldsOperationSpec,
      callback) as Promise<Models.ListProcessFieldsResponse>;
  }

  /**
   * Create a new field for a specific process
   * @summary Create process field
   * @param processname
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessFieldResponse>
   */
  createProcessField(processname: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateProcessFieldResponse>;
  /**
   * @param processname
   * @param callback The callback
   */
  createProcessField(processname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processname
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessField(processname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  createProcessField(processname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.CreateProcessFieldResponse> {
    return this.sendOperationRequest(
      {
        processname,
        options
      },
      createProcessFieldOperationSpec,
      callback) as Promise<Models.CreateProcessFieldResponse>;
  }

  /**
   * Get a specific field for a specific process
   * @summary Get process field
   * @param processname
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessFieldResponse>
   */
  getProcessField(processname: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessFieldResponse>;
  /**
   * @param processname
   * @param fieldname
   * @param callback The callback
   */
  getProcessField(processname: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processname
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessField(processname: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  getProcessField(processname: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.GetProcessFieldResponse> {
    return this.sendOperationRequest(
      {
        processname,
        fieldname,
        options
      },
      getProcessFieldOperationSpec,
      callback) as Promise<Models.GetProcessFieldResponse>;
  }

  /**
   * Update a specific field for a specific process
   * @summary Update process field
   * @param processname
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateProcessFieldResponse>
   */
  updateProcessField(processname: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateProcessFieldResponse>;
  /**
   * @param processname
   * @param fieldname
   * @param callback The callback
   */
  updateProcessField(processname: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processname
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProcessField(processname: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  updateProcessField(processname: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.UpdateProcessFieldResponse> {
    return this.sendOperationRequest(
      {
        processname,
        fieldname,
        options
      },
      updateProcessFieldOperationSpec,
      callback) as Promise<Models.UpdateProcessFieldResponse>;
  }

  /**
   * Delete a specific field for a specific process
   * @summary Delete process field
   * @param processname
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteProcessFieldResponse>
   */
  deleteProcessField(processname: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteProcessFieldResponse>;
  /**
   * @param processname
   * @param fieldname
   * @param callback The callback
   */
  deleteProcessField(processname: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param processname
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteProcessField(processname: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  deleteProcessField(processname: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.DeleteProcessFieldResponse> {
    return this.sendOperationRequest(
      {
        processname,
        fieldname,
        options
      },
      deleteProcessFieldOperationSpec,
      callback) as Promise<Models.DeleteProcessFieldResponse>;
  }

  /**
   * Gets the rights the calling user has for this process
   * @summary List process rights
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListProcessRightsResponse>
   */
  listProcessRights(processid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListProcessRightsResponse>;
  /**
   * @param processid
   * @param callback The callback
   */
  listProcessRights(processid: string, callback: msRest.ServiceCallback<Models.ProcessRights>): void;
  /**
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  listProcessRights(processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ProcessRights>): void;
  listProcessRights(processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProcessRights>, callback?: msRest.ServiceCallback<Models.ProcessRights>): Promise<Models.ListProcessRightsResponse> {
    return this.sendOperationRequest(
      {
        processid,
        options
      },
      listProcessRightsOperationSpec,
      callback) as Promise<Models.ListProcessRightsResponse>;
  }

  /**
   * Create a processExport
   * @summary Create process export
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessExportResponse>
   */
  createProcessExport(options?: Models.OrchestrationAPICreateProcessExportOptionalParams): Promise<Models.CreateProcessExportResponse>;
  /**
   * @param callback The callback
   */
  createProcessExport(callback: msRest.ServiceCallback<Models.ProcessExport>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessExport(options: Models.OrchestrationAPICreateProcessExportOptionalParams, callback: msRest.ServiceCallback<Models.ProcessExport>): void;
  createProcessExport(options?: Models.OrchestrationAPICreateProcessExportOptionalParams | msRest.ServiceCallback<Models.ProcessExport>, callback?: msRest.ServiceCallback<Models.ProcessExport>): Promise<Models.CreateProcessExportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createProcessExportOperationSpec,
      callback) as Promise<Models.CreateProcessExportResponse>;
  }

  /**
   * Get a processExport
   * @summary Get processExport
   * @param exportid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessExportResponse>
   */
  getProcessExport(exportid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessExportResponse>;
  /**
   * @param exportid
   * @param callback The callback
   */
  getProcessExport(exportid: string, callback: msRest.ServiceCallback<Models.ProcessExport>): void;
  /**
   * @param exportid
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessExport(exportid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ProcessExport>): void;
  getProcessExport(exportid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProcessExport>, callback?: msRest.ServiceCallback<Models.ProcessExport>): Promise<Models.GetProcessExportResponse> {
    return this.sendOperationRequest(
      {
        exportid,
        options
      },
      getProcessExportOperationSpec,
      callback) as Promise<Models.GetProcessExportResponse>;
  }

  /**
   * Create a processImport
   * @summary Create processImport
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateProcessImportResponse>
   */
  createProcessImport(options?: Models.OrchestrationAPICreateProcessImportOptionalParams): Promise<Models.CreateProcessImportResponse>;
  /**
   * @param callback The callback
   */
  createProcessImport(callback: msRest.ServiceCallback<Models.ProcessImport>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createProcessImport(options: Models.OrchestrationAPICreateProcessImportOptionalParams, callback: msRest.ServiceCallback<Models.ProcessImport>): void;
  createProcessImport(options?: Models.OrchestrationAPICreateProcessImportOptionalParams | msRest.ServiceCallback<Models.ProcessImport>, callback?: msRest.ServiceCallback<Models.ProcessImport>): Promise<Models.CreateProcessImportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createProcessImportOperationSpec,
      callback) as Promise<Models.CreateProcessImportResponse>;
  }

  /**
   * Get a specific processImport
   * @summary Get processImport
   * @param importid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessImportResponse>
   */
  getProcessImport(importid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetProcessImportResponse>;
  /**
   * @param importid
   * @param callback The callback
   */
  getProcessImport(importid: string, callback: msRest.ServiceCallback<Models.ProcessImport>): void;
  /**
   * @param importid
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessImport(importid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ProcessImport>): void;
  getProcessImport(importid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProcessImport>, callback?: msRest.ServiceCallback<Models.ProcessImport>): Promise<Models.GetProcessImportResponse> {
    return this.sendOperationRequest(
      {
        importid,
        options
      },
      getProcessImportOperationSpec,
      callback) as Promise<Models.GetProcessImportResponse>;
  }

  /**
   * Get a list of runs for a specific team
   * @summary List runs
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunsResponse>
   */
  listRuns(options?: Models.OrchestrationAPIListRunsOptionalParams): Promise<Models.ListRunsResponse>;
  /**
   * @param callback The callback
   */
  listRuns(callback: msRest.ServiceCallback<Models.Run>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listRuns(options: Models.OrchestrationAPIListRunsOptionalParams, callback: msRest.ServiceCallback<Models.Run>): void;
  listRuns(options?: Models.OrchestrationAPIListRunsOptionalParams | msRest.ServiceCallback<Models.Run>, callback?: msRest.ServiceCallback<Models.Run>): Promise<Models.ListRunsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listRunsOperationSpec,
      callback) as Promise<Models.ListRunsResponse>;
  }

  /**
   * Create a run
   * @summary Create run
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateRunResponse>
   */
  createRun(options?: msRest.RequestOptionsBase): Promise<Models.CreateRunResponse>;
  /**
   * @param callback The callback
   */
  createRun(callback: msRest.ServiceCallback<Models.Run>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createRun(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Run>): void;
  createRun(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Run>, callback?: msRest.ServiceCallback<Models.Run>): Promise<Models.CreateRunResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createRunOperationSpec,
      callback) as Promise<Models.CreateRunResponse>;
  }

  /**
   * Get a specific run
   * @summary Get run
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRunResponse>
   */
  getRun(runid: string, options?: Models.OrchestrationAPIGetRunOptionalParams): Promise<Models.GetRunResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  getRun(runid: string, callback: msRest.ServiceCallback<Models.Run>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  getRun(runid: string, options: Models.OrchestrationAPIGetRunOptionalParams, callback: msRest.ServiceCallback<Models.Run>): void;
  getRun(runid: string, options?: Models.OrchestrationAPIGetRunOptionalParams | msRest.ServiceCallback<Models.Run>, callback?: msRest.ServiceCallback<Models.Run>): Promise<Models.GetRunResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      getRunOperationSpec,
      callback) as Promise<Models.GetRunResponse>;
  }

  /**
   * Update a specific run
   * @summary Update run
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateRunResponse>
   */
  updateRun(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateRunResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  updateRun(runid: string, callback: msRest.ServiceCallback<Models.Run>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRun(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Run>): void;
  updateRun(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Run>, callback?: msRest.ServiceCallback<Models.Run>): Promise<Models.UpdateRunResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      updateRunOperationSpec,
      callback) as Promise<Models.UpdateRunResponse>;
  }

  /**
   * Get the visibility of a specific run
   * @summary Get run visibility
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRunVisibilityResponse>
   */
  getRunVisibility(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetRunVisibilityResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  getRunVisibility(runid: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  getRunVisibility(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  getRunVisibility(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.GetRunVisibilityResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      getRunVisibilityOperationSpec,
      callback) as Promise<Models.GetRunVisibilityResponse>;
  }

  /**
   * Get the audit log of a specific run
   * @summary Get log
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLogResponse>
   */
  getLog(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLogResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  getLog(runid: string, callback: msRest.ServiceCallback<Models.Run>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  getLog(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Run>): void;
  getLog(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Run>, callback?: msRest.ServiceCallback<Models.Run>): Promise<Models.GetLogResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      getLogOperationSpec,
      callback) as Promise<Models.GetLogResponse>;
  }

  /**
   * Get the comments for a specific run
   * @summary List run comments
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunCommentsResponse>
   */
  listRunComments(runid: string, options?: Models.OrchestrationAPIListRunCommentsOptionalParams): Promise<Models.ListRunCommentsResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  listRunComments(runid: string, callback: msRest.ServiceCallback<Models.Comment>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  listRunComments(runid: string, options: Models.OrchestrationAPIListRunCommentsOptionalParams, callback: msRest.ServiceCallback<Models.Comment>): void;
  listRunComments(runid: string, options?: Models.OrchestrationAPIListRunCommentsOptionalParams | msRest.ServiceCallback<Models.Comment>, callback?: msRest.ServiceCallback<Models.Comment>): Promise<Models.ListRunCommentsResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      listRunCommentsOperationSpec,
      callback) as Promise<Models.ListRunCommentsResponse>;
  }

  /**
   * Create a comment on a specific run
   * @summary Create run comment
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateRunCommentResponse>
   */
  createRunComment(runid: string, options?: Models.OrchestrationAPICreateRunCommentOptionalParams): Promise<Models.CreateRunCommentResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  createRunComment(runid: string, callback: msRest.ServiceCallback<Models.Comment>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  createRunComment(runid: string, options: Models.OrchestrationAPICreateRunCommentOptionalParams, callback: msRest.ServiceCallback<Models.Comment>): void;
  createRunComment(runid: string, options?: Models.OrchestrationAPICreateRunCommentOptionalParams | msRest.ServiceCallback<Models.Comment>, callback?: msRest.ServiceCallback<Models.Comment>): Promise<Models.CreateRunCommentResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      createRunCommentOperationSpec,
      callback) as Promise<Models.CreateRunCommentResponse>;
  }

  /**
   * Get the files for a specific run
   * @summary List run files
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunFilesResponse>
   */
  listRunFiles(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListRunFilesResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  listRunFiles(runid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  listRunFiles(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  listRunFiles(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListRunFilesResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      listRunFilesOperationSpec,
      callback) as Promise<Models.ListRunFilesResponse>;
  }

  /**
   * Create a file for a specific run
   * @summary Create run file
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateRunFileResponse>
   */
  createRunFile(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateRunFileResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  createRunFile(runid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  createRunFile(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  createRunFile(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.CreateRunFileResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      createRunFileOperationSpec,
      callback) as Promise<Models.CreateRunFileResponse>;
  }

  /**
   * Update specific file for a run
   * @summary Update run file
   * @param runid
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateRunFileResponse>
   */
  updateRunFile(runid: string, fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateRunFileResponse>;
  /**
   * @param runid
   * @param fileid
   * @param callback The callback
   */
  updateRunFile(runid: string, fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param runid
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRunFile(runid: string, fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  updateRunFile(runid: string, fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.UpdateRunFileResponse> {
    return this.sendOperationRequest(
      {
        runid,
        fileid,
        options
      },
      updateRunFileOperationSpec,
      callback) as Promise<Models.UpdateRunFileResponse>;
  }

  /**
   * Get fields for a specific run
   * @summary List run fields
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunFieldsResponse>
   */
  listRunFields(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListRunFieldsResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  listRunFields(runid: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  listRunFields(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  listRunFields(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.ListRunFieldsResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      listRunFieldsOperationSpec,
      callback) as Promise<Models.ListRunFieldsResponse>;
  }

  /**
   * Create field for a specific run
   * @summary Create run field
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateRunFieldResponse>
   */
  createRunField(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateRunFieldResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  createRunField(runid: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  createRunField(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  createRunField(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.CreateRunFieldResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      createRunFieldOperationSpec,
      callback) as Promise<Models.CreateRunFieldResponse>;
  }

  /**
   * Get a specific field from a specific run
   * @summary Get run field
   * @param runid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRunFieldResponse>
   */
  getRunField(runid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetRunFieldResponse>;
  /**
   * @param runid
   * @param fieldname
   * @param callback The callback
   */
  getRunField(runid: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param runid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  getRunField(runid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  getRunField(runid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.GetRunFieldResponse> {
    return this.sendOperationRequest(
      {
        runid,
        fieldname,
        options
      },
      getRunFieldOperationSpec,
      callback) as Promise<Models.GetRunFieldResponse>;
  }

  /**
   * Update a specific field on a specific run
   * @summary Update run field
   * @param runid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateRunFieldResponse>
   */
  updateRunField(runid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateRunFieldResponse>;
  /**
   * @param runid
   * @param fieldname
   * @param callback The callback
   */
  updateRunField(runid: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param runid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRunField(runid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  updateRunField(runid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.UpdateRunFieldResponse> {
    return this.sendOperationRequest(
      {
        runid,
        fieldname,
        options
      },
      updateRunFieldOperationSpec,
      callback) as Promise<Models.UpdateRunFieldResponse>;
  }

  /**
   * Delete a specific field from a specific run
   * @summary Delete run field
   * @param runid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteRunFieldResponse>
   */
  deleteRunField(runid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteRunFieldResponse>;
  /**
   * @param runid
   * @param fieldname
   * @param callback The callback
   */
  deleteRunField(runid: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param runid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteRunField(runid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  deleteRunField(runid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.DeleteRunFieldResponse> {
    return this.sendOperationRequest(
      {
        runid,
        fieldname,
        options
      },
      deleteRunFieldOperationSpec,
      callback) as Promise<Models.DeleteRunFieldResponse>;
  }

  /**
   * Get the process for a specific run
   * @summary Get run progress
   * @param runid
   * @param progressSecret
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRunProgressResponse>
   */
  getRunProgress(runid: string, progressSecret: string, options?: msRest.RequestOptionsBase): Promise<Models.GetRunProgressResponse>;
  /**
   * @param runid
   * @param progressSecret
   * @param callback The callback
   */
  getRunProgress(runid: string, progressSecret: string, callback: msRest.ServiceCallback<Models.Run>): void;
  /**
   * @param runid
   * @param progressSecret
   * @param options The optional parameters
   * @param callback The callback
   */
  getRunProgress(runid: string, progressSecret: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Run>): void;
  getRunProgress(runid: string, progressSecret: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Run>, callback?: msRest.ServiceCallback<Models.Run>): Promise<Models.GetRunProgressResponse> {
    return this.sendOperationRequest(
      {
        runid,
        progressSecret,
        options
      },
      getRunProgressOperationSpec,
      callback) as Promise<Models.GetRunProgressResponse>;
  }

  /**
   * Re-enqueue any ready tasks for a specific run
   * @summary Re-enqueue run tasks
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.ReenqueueRunTasksResponse>
   */
  reenqueueRunTasks(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.ReenqueueRunTasksResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  reenqueueRunTasks(runid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  reenqueueRunTasks(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  reenqueueRunTasks(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.ReenqueueRunTasksResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      reenqueueRunTasksOperationSpec,
      callback) as Promise<Models.ReenqueueRunTasksResponse>;
  }

  /**
   * Get all the tasks from a specific run
   * @summary List run tasks
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunTasksResponse>
   */
  listRunTasks(runid: string, options?: Models.OrchestrationAPIListRunTasksOptionalParams): Promise<Models.ListRunTasksResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  listRunTasks(runid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  listRunTasks(runid: string, options: Models.OrchestrationAPIListRunTasksOptionalParams, callback: msRest.ServiceCallback<Models.Task>): void;
  listRunTasks(runid: string, options?: Models.OrchestrationAPIListRunTasksOptionalParams | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.ListRunTasksResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      listRunTasksOperationSpec,
      callback) as Promise<Models.ListRunTasksResponse>;
  }

  /**
   * Update a task in a specific run
   * @summary Update run task
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateRunTasksResponse>
   */
  updateRunTasks(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateRunTasksResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  updateRunTasks(runid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRunTasks(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  updateRunTasks(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.UpdateRunTasksResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      updateRunTasksOperationSpec,
      callback) as Promise<Models.UpdateRunTasksResponse>;
  }

  /**
   * Create a task in a specific run
   * @summary Create run task
   * @param runid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateRunTaskResponse>
   */
  createRunTask(runid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateRunTaskResponse>;
  /**
   * @param runid
   * @param callback The callback
   */
  createRunTask(runid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param options The optional parameters
   * @param callback The callback
   */
  createRunTask(runid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  createRunTask(runid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.CreateRunTaskResponse> {
    return this.sendOperationRequest(
      {
        runid,
        options
      },
      createRunTaskOperationSpec,
      callback) as Promise<Models.CreateRunTaskResponse>;
  }

  /**
   * Get a specific task from a specific run
   * @summary Get run task
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRunTaskResponse>
   */
  getRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetRunTaskResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  getRunTask(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  getRunTask(runid: string, taskname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  getRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.GetRunTaskResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      getRunTaskOperationSpec,
      callback) as Promise<Models.GetRunTaskResponse>;
  }

  /**
   * Update a specific task in a specific run
   * @summary Update run task
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateRunTaskResponse>
   */
  updateRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateRunTaskResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  updateRunTask(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRunTask(runid: string, taskname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  updateRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.UpdateRunTaskResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      updateRunTaskOperationSpec,
      callback) as Promise<Models.UpdateRunTaskResponse>;
  }

  /**
   * Delete a specific task from a specific run
   * @summary Delete run task
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteRunTaskResponse>
   */
  deleteRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteRunTaskResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  deleteRunTask(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteRunTask(runid: string, taskname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  deleteRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.DeleteRunTaskResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      deleteRunTaskOperationSpec,
      callback) as Promise<Models.DeleteRunTaskResponse>;
  }

  /**
   * Get all the files from a specific task in a specific run
   * @summary List run task files
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunTaskFilesResponse>
   */
  listRunTaskFiles(runid: string, taskname: string, options?: Models.OrchestrationAPIListRunTaskFilesOptionalParams): Promise<Models.ListRunTaskFilesResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  listRunTaskFiles(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  listRunTaskFiles(runid: string, taskname: string, options: Models.OrchestrationAPIListRunTaskFilesOptionalParams, callback: msRest.ServiceCallback<Models.File>): void;
  listRunTaskFiles(runid: string, taskname: string, options?: Models.OrchestrationAPIListRunTaskFilesOptionalParams | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListRunTaskFilesResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      listRunTaskFilesOperationSpec,
      callback) as Promise<Models.ListRunTaskFilesResponse>;
  }

  /**
   * Create a file for a specific task in a specific run
   * @summary Create run task file
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateRunTaskFileResponse>
   */
  createRunTaskFile(runid: string, taskname: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateRunTaskFileResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  createRunTaskFile(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  createRunTaskFile(runid: string, taskname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  createRunTaskFile(runid: string, taskname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.CreateRunTaskFileResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      createRunTaskFileOperationSpec,
      callback) as Promise<Models.CreateRunTaskFileResponse>;
  }

  /**
   * Update a specific file for a specific task in a specific run
   * @summary Update run task file
   * @param runid
   * @param taskname
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateRunTaskFileResponse>
   */
  updateRunTaskFile(runid: string, taskname: string, fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateRunTaskFileResponse>;
  /**
   * @param runid
   * @param taskname
   * @param fileid
   * @param callback The callback
   */
  updateRunTaskFile(runid: string, taskname: string, fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param runid
   * @param taskname
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRunTaskFile(runid: string, taskname: string, fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  updateRunTaskFile(runid: string, taskname: string, fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.UpdateRunTaskFileResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        fileid,
        options
      },
      updateRunTaskFileOperationSpec,
      callback) as Promise<Models.UpdateRunTaskFileResponse>;
  }

  /**
   * Get the webhooks for a specific task from a specific run
   * @summary List run task webhooks
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRunTaskWebhooksResponse>
   */
  listRunTaskWebhooks(runid: string, taskname: string, options?: msRest.RequestOptionsBase): Promise<Models.ListRunTaskWebhooksResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  listRunTaskWebhooks(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  listRunTaskWebhooks(runid: string, taskname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  listRunTaskWebhooks(runid: string, taskname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ListRunTaskWebhooksResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      listRunTaskWebhooksOperationSpec,
      callback) as Promise<Models.ListRunTaskWebhooksResponse>;
  }

  /**
   * Complete a specific task in a specific run
   * @summary Complete task
   * @param runid
   * @param taskname
   * @param [options] The optional parameters
   * @returns Promise<Models.CompleteRunTaskResponse>
   */
  completeRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase): Promise<Models.CompleteRunTaskResponse>;
  /**
   * @param runid
   * @param taskname
   * @param callback The callback
   */
  completeRunTask(runid: string, taskname: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param runid
   * @param taskname
   * @param options The optional parameters
   * @param callback The callback
   */
  completeRunTask(runid: string, taskname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  completeRunTask(runid: string, taskname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.CompleteRunTaskResponse> {
    return this.sendOperationRequest(
      {
        runid,
        taskname,
        options
      },
      completeRunTaskOperationSpec,
      callback) as Promise<Models.CompleteRunTaskResponse>;
  }

  /**
   * Execute a search for processes, runs, tasks or tables in a team.
   *
   * Note: this endpoint is deprecated in favor of the `search/v2` endpoint
   * @summary Search V1
   * @param q
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<Models.SearchV1Response2>
   */
  searchV1(q: string, options?: Models.OrchestrationAPISearchV1OptionalParams): Promise<Models.SearchV1Response2>;
  /**
   * @param q
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  searchV1(q: string, callback: msRest.ServiceCallback<Models.SearchV1Response>): void;
  /**
   * @param q
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  searchV1(q: string, options: Models.OrchestrationAPISearchV1OptionalParams, callback: msRest.ServiceCallback<Models.SearchV1Response>): void;
  searchV1(q: string, options?: Models.OrchestrationAPISearchV1OptionalParams | msRest.ServiceCallback<Models.SearchV1Response>, callback?: msRest.ServiceCallback<Models.SearchV1Response>): Promise<Models.SearchV1Response2> {
    return this.sendOperationRequest(
      {
        q,
        options
      },
      searchV1OperationSpec,
      callback) as Promise<Models.SearchV1Response2>;
  }

  /**
   * Execute a search for processes, runs, or tasks, tables and other entities within a team.
   * @summary Search V2
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchV2Response2>
   */
  searchV2(options?: Models.OrchestrationAPISearchV2OptionalParams): Promise<Models.SearchV2Response2>;
  /**
   * @param callback The callback
   */
  searchV2(callback: msRest.ServiceCallback<Models.SearchV2Response>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchV2(options: Models.OrchestrationAPISearchV2OptionalParams, callback: msRest.ServiceCallback<Models.SearchV2Response>): void;
  searchV2(options?: Models.OrchestrationAPISearchV2OptionalParams | msRest.ServiceCallback<Models.SearchV2Response>, callback?: msRest.ServiceCallback<Models.SearchV2Response>): Promise<Models.SearchV2Response2> {
    return this.sendOperationRequest(
      {
        options
      },
      searchV2OperationSpec,
      callback) as Promise<Models.SearchV2Response2>;
  }

  /**
   * Execute a search for users within a team.
   * @summary Users search
   * @param [options] The optional parameters
   * @returns Promise<Models.UsersSearchResponse>
   */
  usersSearch(options?: Models.OrchestrationAPIUsersSearchOptionalParams): Promise<Models.UsersSearchResponse>;
  /**
   * @param callback The callback
   */
  usersSearch(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  usersSearch(options: Models.OrchestrationAPIUsersSearchOptionalParams, callback: msRest.ServiceCallback<any>): void;
  usersSearch(options?: Models.OrchestrationAPIUsersSearchOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UsersSearchResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      usersSearchOperationSpec,
      callback) as Promise<Models.UsersSearchResponse>;
  }

  /**
   * Execute a search for processes within a team.
   * @summary Processes search
   * @param [options] The optional parameters
   * @returns Promise<Models.ProcessesSearchResponse>
   */
  processesSearch(options?: Models.OrchestrationAPIProcessesSearchOptionalParams): Promise<Models.ProcessesSearchResponse>;
  /**
   * @param callback The callback
   */
  processesSearch(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  processesSearch(options: Models.OrchestrationAPIProcessesSearchOptionalParams, callback: msRest.ServiceCallback<any>): void;
  processesSearch(options?: Models.OrchestrationAPIProcessesSearchOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ProcessesSearchResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      processesSearchOperationSpec,
      callback) as Promise<Models.ProcessesSearchResponse>;
  }

  /**
   * Get a specific step
   * @summary Get tep
   * @param stepid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetStepResponse>
   */
  getStep(stepid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetStepResponse>;
  /**
   * @param stepid
   * @param callback The callback
   */
  getStep(stepid: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param stepid
   * @param options The optional parameters
   * @param callback The callback
   */
  getStep(stepid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  getStep(stepid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.GetStepResponse> {
    return this.sendOperationRequest(
      {
        stepid,
        options
      },
      getStepOperationSpec,
      callback) as Promise<Models.GetStepResponse>;
  }

  /**
   * Update a specific stem
   * @summary Update step
   * @param stepid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateStepResponse>
   */
  updateStep(stepid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateStepResponse>;
  /**
   * @param stepid
   * @param callback The callback
   */
  updateStep(stepid: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param stepid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateStep(stepid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  updateStep(stepid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.UpdateStepResponse> {
    return this.sendOperationRequest(
      {
        stepid,
        options
      },
      updateStepOperationSpec,
      callback) as Promise<Models.UpdateStepResponse>;
  }

  /**
   * Delete a specific step
   * @summary Delete step
   * @param stepid
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteStepResponse>
   */
  deleteStep(stepid: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteStepResponse>;
  /**
   * @param stepid
   * @param callback The callback
   */
  deleteStep(stepid: string, callback: msRest.ServiceCallback<Models.Step>): void;
  /**
   * @param stepid
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteStep(stepid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Step>): void;
  deleteStep(stepid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Step>, callback?: msRest.ServiceCallback<Models.Step>): Promise<Models.DeleteStepResponse> {
    return this.sendOperationRequest(
      {
        stepid,
        options
      },
      deleteStepOperationSpec,
      callback) as Promise<Models.DeleteStepResponse>;
  }

  /**
   * Add a field to the list of writable fields on this step. If the field already exists, this has
   * no action
   * @summary Update writable field
   * @param stepid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWritableFieldResponse>
   */
  updateWritableField(stepid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWritableFieldResponse>;
  /**
   * @param stepid
   * @param fieldname
   * @param callback The callback
   */
  updateWritableField(stepid: string, fieldname: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param stepid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWritableField(stepid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  updateWritableField(stepid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.UpdateWritableFieldResponse> {
    return this.sendOperationRequest(
      {
        stepid,
        fieldname,
        options
      },
      updateWritableFieldOperationSpec,
      callback) as Promise<Models.UpdateWritableFieldResponse>;
  }

  /**
   * Delete a field from the list of writable fields on this step. If the field does not exist, this
   * has no action
   * @summary Delete writable field
   * @param stepid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWritableFieldResponse>
   */
  deleteWritableField(stepid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWritableFieldResponse>;
  /**
   * @param stepid
   * @param fieldname
   * @param callback The callback
   */
  deleteWritableField(stepid: string, fieldname: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param stepid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWritableField(stepid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  deleteWritableField(stepid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.DeleteWritableFieldResponse> {
    return this.sendOperationRequest(
      {
        stepid,
        fieldname,
        options
      },
      deleteWritableFieldOperationSpec,
      callback) as Promise<Models.DeleteWritableFieldResponse>;
  }

  /**
   * Given a set of step configuration params, evaluate which output fields will be generated by the
   * step.
   * @summary Evaluate step outputs
   * @param stepid
   * @param [options] The optional parameters
   * @returns Promise<Models.EvaluateStepOutputsResponse>
   */
  evaluateStepOutputs(stepid: string, options?: Models.OrchestrationAPIEvaluateStepOutputsOptionalParams): Promise<Models.EvaluateStepOutputsResponse>;
  /**
   * @param stepid
   * @param callback The callback
   */
  evaluateStepOutputs(stepid: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param stepid
   * @param options The optional parameters
   * @param callback The callback
   */
  evaluateStepOutputs(stepid: string, options: Models.OrchestrationAPIEvaluateStepOutputsOptionalParams, callback: msRest.ServiceCallback<Models.Field>): void;
  evaluateStepOutputs(stepid: string, options?: Models.OrchestrationAPIEvaluateStepOutputsOptionalParams | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.EvaluateStepOutputsResponse> {
    return this.sendOperationRequest(
      {
        stepid,
        options
      },
      evaluateStepOutputsOperationSpec,
      callback) as Promise<Models.EvaluateStepOutputsResponse>;
  }

  /**
   * Get all the tasks for a specific team
   * @summary List tasks
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTasksResponse>
   */
  listTasks(options?: Models.OrchestrationAPIListTasksOptionalParams): Promise<Models.ListTasksResponse>;
  /**
   * @param callback The callback
   */
  listTasks(callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listTasks(options: Models.OrchestrationAPIListTasksOptionalParams, callback: msRest.ServiceCallback<Models.Task>): void;
  listTasks(options?: Models.OrchestrationAPIListTasksOptionalParams | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.ListTasksResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listTasksOperationSpec,
      callback) as Promise<Models.ListTasksResponse>;
  }

  /**
   * Create a task
   * @summary Create task
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTaskResponse>
   */
  createTask(options?: msRest.RequestOptionsBase): Promise<Models.CreateTaskResponse>;
  /**
   * @param callback The callback
   */
  createTask(callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createTask(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  createTask(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.CreateTaskResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createTaskOperationSpec,
      callback) as Promise<Models.CreateTaskResponse>;
  }

  /**
   * Get a specific task
   * @summary Get task
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTaskResponse>
   */
  getTask(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTaskResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  getTask(taskid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  getTask(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  getTask(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.GetTaskResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      getTaskOperationSpec,
      callback) as Promise<Models.GetTaskResponse>;
  }

  /**
   * Update a specific task
   * @summary Update task
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTaskResponse>
   */
  updateTask(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateTaskResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  updateTask(taskid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTask(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  updateTask(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.UpdateTaskResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      updateTaskOperationSpec,
      callback) as Promise<Models.UpdateTaskResponse>;
  }

  /**
   * Complete a specific task
   * @summary Complete task
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.CompleteTaskResponse>
   */
  completeTask(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.CompleteTaskResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  completeTask(taskid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  completeTask(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  completeTask(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.CompleteTaskResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      completeTaskOperationSpec,
      callback) as Promise<Models.CompleteTaskResponse>;
  }

  /**
   * Open a task that has not been started yet
   * @summary Open task
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.OpenTaskResponse>
   */
  openTask(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.OpenTaskResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  openTask(taskid: string, callback: msRest.ServiceCallback<Models.Task>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  openTask(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Task>): void;
  openTask(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Task>, callback?: msRest.ServiceCallback<Models.Task>): Promise<Models.OpenTaskResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      openTaskOperationSpec,
      callback) as Promise<Models.OpenTaskResponse>;
  }

  /**
   * Reopen a specific task
   * @summary Reopen task
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.ReopenTaskResponse>
   */
  reopenTask(taskid: string, options?: Models.OrchestrationAPIReopenTaskOptionalParams): Promise<Models.ReopenTaskResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  reopenTask(taskid: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  reopenTask(taskid: string, options: Models.OrchestrationAPIReopenTaskOptionalParams, callback: msRest.ServiceCallback<string>): void;
  reopenTask(taskid: string, options?: Models.OrchestrationAPIReopenTaskOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.ReopenTaskResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      reopenTaskOperationSpec,
      callback) as Promise<Models.ReopenTaskResponse>;
  }

  /**
   * Get all the files from a specific task
   * @summary List task files
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTaskFilesResponse>
   */
  listTaskFiles(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTaskFilesResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  listTaskFiles(taskid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  listTaskFiles(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  listTaskFiles(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListTaskFilesResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      listTaskFilesOperationSpec,
      callback) as Promise<Models.ListTaskFilesResponse>;
  }

  /**
   * Create a file for a specific task
   * @summary Create task file
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTaskFileResponse>
   */
  createTaskFile(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateTaskFileResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  createTaskFile(taskid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  createTaskFile(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  createTaskFile(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.CreateTaskFileResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      createTaskFileOperationSpec,
      callback) as Promise<Models.CreateTaskFileResponse>;
  }

  /**
   * Update a specific file for a specific task
   * @summary Update task file
   * @param taskid
   * @param fileid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTaskFileResponse>
   */
  updateTaskFile(taskid: string, fileid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateTaskFileResponse>;
  /**
   * @param taskid
   * @param fileid
   * @param callback The callback
   */
  updateTaskFile(taskid: string, fileid: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param taskid
   * @param fileid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTaskFile(taskid: string, fileid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  updateTaskFile(taskid: string, fileid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.UpdateTaskFileResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        fileid,
        options
      },
      updateTaskFileOperationSpec,
      callback) as Promise<Models.UpdateTaskFileResponse>;
  }

  /**
   * Get webhooks for a specific task
   * @summary List task webhooks
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTaskWebhooksResponse>
   */
  listTaskWebhooks(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTaskWebhooksResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  listTaskWebhooks(taskid: string, callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  listTaskWebhooks(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  listTaskWebhooks(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ListTaskWebhooksResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      listTaskWebhooksOperationSpec,
      callback) as Promise<Models.ListTaskWebhooksResponse>;
  }

  /**
   * Get fields for a specific task
   * @summary Get task fields
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTaskFieldsResponse>
   */
  getTaskFields(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTaskFieldsResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  getTaskFields(taskid: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  getTaskFields(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  getTaskFields(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.GetTaskFieldsResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      getTaskFieldsOperationSpec,
      callback) as Promise<Models.GetTaskFieldsResponse>;
  }

  /**
   * Create a field for a specific task
   * @summary Create task field
   * @param taskid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTaskFieldResponse>
   */
  createTaskField(taskid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateTaskFieldResponse>;
  /**
   * @param taskid
   * @param callback The callback
   */
  createTaskField(taskid: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param taskid
   * @param options The optional parameters
   * @param callback The callback
   */
  createTaskField(taskid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  createTaskField(taskid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.CreateTaskFieldResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        options
      },
      createTaskFieldOperationSpec,
      callback) as Promise<Models.CreateTaskFieldResponse>;
  }

  /**
   * Get a specific field for a specific task
   * @summary Get task field
   * @param taskid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTaskFieldResponse>
   */
  getTaskField(taskid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTaskFieldResponse>;
  /**
   * @param taskid
   * @param fieldname
   * @param callback The callback
   */
  getTaskField(taskid: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param taskid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  getTaskField(taskid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  getTaskField(taskid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.GetTaskFieldResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        fieldname,
        options
      },
      getTaskFieldOperationSpec,
      callback) as Promise<Models.GetTaskFieldResponse>;
  }

  /**
   * Update a specific field on a specific task
   * @summary Update task field
   * @param taskid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTaskFieldResponse>
   */
  updateTaskField(taskid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateTaskFieldResponse>;
  /**
   * @param taskid
   * @param fieldname
   * @param callback The callback
   */
  updateTaskField(taskid: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param taskid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTaskField(taskid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  updateTaskField(taskid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.UpdateTaskFieldResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        fieldname,
        options
      },
      updateTaskFieldOperationSpec,
      callback) as Promise<Models.UpdateTaskFieldResponse>;
  }

  /**
   * Delete a specific field from a specific task
   * @summary Delete task field
   * @param taskid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteTaskFieldResponse>
   */
  deleteTaskField(taskid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteTaskFieldResponse>;
  /**
   * @param taskid
   * @param fieldname
   * @param callback The callback
   */
  deleteTaskField(taskid: string, fieldname: string, callback: msRest.ServiceCallback<Models.Field>): void;
  /**
   * @param taskid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTaskField(taskid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Field>): void;
  deleteTaskField(taskid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Field>, callback?: msRest.ServiceCallback<Models.Field>): Promise<Models.DeleteTaskFieldResponse> {
    return this.sendOperationRequest(
      {
        taskid,
        fieldname,
        options
      },
      deleteTaskFieldOperationSpec,
      callback) as Promise<Models.DeleteTaskFieldResponse>;
  }

  /**
   * Get tables for a specific team
   * @summary List tables
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTablesResponse>
   */
  listTables(options?: Models.OrchestrationAPIListTablesOptionalParams): Promise<Models.ListTablesResponse>;
  /**
   * @param callback The callback
   */
  listTables(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listTables(options: Models.OrchestrationAPIListTablesOptionalParams, callback: msRest.ServiceCallback<any>): void;
  listTables(options?: Models.OrchestrationAPIListTablesOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ListTablesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listTablesOperationSpec,
      callback) as Promise<Models.ListTablesResponse>;
  }

  /**
   * Create a table for a specific team
   * @summary Create table
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTableResponse>
   */
  createTable(options?: msRest.RequestOptionsBase): Promise<Models.CreateTableResponse>;
  /**
   * @param callback The callback
   */
  createTable(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createTable(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createTable(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateTableResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createTableOperationSpec,
      callback) as Promise<Models.CreateTableResponse>;
  }

  /**
   * Indexes application type data tables created in a migration
   * @summary Index meta tables
   * @param [options] The optional parameters
   * @returns Promise<Models.IndexMetaTablesResponse>
   */
  indexMetaTables(options?: Models.OrchestrationAPIIndexMetaTablesOptionalParams): Promise<Models.IndexMetaTablesResponse>;
  /**
   * @param callback The callback
   */
  indexMetaTables(callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  indexMetaTables(options: Models.OrchestrationAPIIndexMetaTablesOptionalParams, callback: msRest.ServiceCallback<Models.Table>): void;
  indexMetaTables(options?: Models.OrchestrationAPIIndexMetaTablesOptionalParams | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.IndexMetaTablesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      indexMetaTablesOperationSpec,
      callback) as Promise<Models.IndexMetaTablesResponse>;
  }

  /**
   * Import a table into a specific team
   * @summary Import table
   * @param [options] The optional parameters
   * @returns Promise<Models.ImportTableResponse>
   */
  importTable(options?: msRest.RequestOptionsBase): Promise<Models.ImportTableResponse>;
  /**
   * @param callback The callback
   */
  importTable(callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importTable(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  importTable(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.ImportTableResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      importTableOperationSpec,
      callback) as Promise<Models.ImportTableResponse>;
  }

  /**
   * Get a specific table
   * @summary Get table
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTableResponse>
   */
  getTable(tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTableResponse>;
  /**
   * @param tableid
   * @param callback The callback
   */
  getTable(tableid: string, callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  getTable(tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  getTable(tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.GetTableResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        options
      },
      getTableOperationSpec,
      callback) as Promise<Models.GetTableResponse>;
  }

  /**
   * Update a specific table
   * @summary Update table
   * @param body
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTableResponse>
   */
  updateTable(body: Models.TableCopy, tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateTableResponse>;
  /**
   * @param body
   * @param tableid
   * @param callback The callback
   */
  updateTable(body: Models.TableCopy, tableid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param body
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTable(body: Models.TableCopy, tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateTable(body: Models.TableCopy, tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateTableResponse> {
    return this.sendOperationRequest(
      {
        body,
        tableid,
        options
      },
      updateTableOperationSpec,
      callback) as Promise<Models.UpdateTableResponse>;
  }

  /**
   * Export a specific table
   * @summary Export table
   * @param tableid
   * @param exportType
   * @param [options] The optional parameters
   * @returns Promise<Models.ExportTableResponse>
   */
  exportTable(tableid: string, exportType: string, options?: msRest.RequestOptionsBase): Promise<Models.ExportTableResponse>;
  /**
   * @param tableid
   * @param exportType
   * @param callback The callback
   */
  exportTable(tableid: string, exportType: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param tableid
   * @param exportType
   * @param options The optional parameters
   * @param callback The callback
   */
  exportTable(tableid: string, exportType: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  exportTable(tableid: string, exportType: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ExportTableResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        exportType,
        options
      },
      exportTableOperationSpec,
      callback) as Promise<Models.ExportTableResponse>;
  }

  /**
   * Create a column on a specific table
   * @summary Create table column
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTableColumnResponse>
   */
  createTableColumn(tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateTableColumnResponse>;
  /**
   * @param tableid
   * @param callback The callback
   */
  createTableColumn(tableid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  createTableColumn(tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createTableColumn(tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateTableColumnResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        options
      },
      createTableColumnOperationSpec,
      callback) as Promise<Models.CreateTableColumnResponse>;
  }

  /**
   * Get a specific column from a specific table
   * @summary Get table column
   * @param tableid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTableColumnResponse>
   */
  getTableColumn(tableid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTableColumnResponse>;
  /**
   * @param tableid
   * @param fieldname
   * @param callback The callback
   */
  getTableColumn(tableid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  getTableColumn(tableid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getTableColumn(tableid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetTableColumnResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        fieldname,
        options
      },
      getTableColumnOperationSpec,
      callback) as Promise<Models.GetTableColumnResponse>;
  }

  /**
   * Update a specific column in a specific table
   * @summary Update table column
   * @param body
   * @param tableid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTableColumnResponse>
   */
  updateTableColumn(body: Models.Field, tableid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateTableColumnResponse>;
  /**
   * @param body
   * @param tableid
   * @param fieldname
   * @param callback The callback
   */
  updateTableColumn(body: Models.Field, tableid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param body
   * @param tableid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTableColumn(body: Models.Field, tableid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateTableColumn(body: Models.Field, tableid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateTableColumnResponse> {
    return this.sendOperationRequest(
      {
        body,
        tableid,
        fieldname,
        options
      },
      updateTableColumnOperationSpec,
      callback) as Promise<Models.UpdateTableColumnResponse>;
  }

  /**
   * Delete a specific column in a specific table
   * @summary Delete table column
   * @param tableid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteTableColumnResponse>
   */
  deleteTableColumn(tableid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteTableColumnResponse>;
  /**
   * @param tableid
   * @param fieldname
   * @param callback The callback
   */
  deleteTableColumn(tableid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTableColumn(tableid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteTableColumn(tableid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteTableColumnResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        fieldname,
        options
      },
      deleteTableColumnOperationSpec,
      callback) as Promise<Models.DeleteTableColumnResponse>;
  }

  /**
   * Append a row to a specific table
   * @summary Append row to table
   * @param body
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.AppendRowToTableResponse>
   */
  appendRowToTable(body: Models.TableFileReference, tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.AppendRowToTableResponse>;
  /**
   * @param body
   * @param tableid
   * @param callback The callback
   */
  appendRowToTable(body: Models.TableFileReference, tableid: string, callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param body
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  appendRowToTable(body: Models.TableFileReference, tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  appendRowToTable(body: Models.TableFileReference, tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.AppendRowToTableResponse> {
    return this.sendOperationRequest(
      {
        body,
        tableid,
        options
      },
      appendRowToTableOperationSpec,
      callback) as Promise<Models.AppendRowToTableResponse>;
  }

  /**
   * Get the parent processes of a specific table
   * @summary List table parent processes
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTableParentProcessesResponse>
   */
  listTableParentProcesses(tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.ListTableParentProcessesResponse>;
  /**
   * @param tableid
   * @param callback The callback
   */
  listTableParentProcesses(tableid: string, callback: msRest.ServiceCallback<Models.Process>): void;
  /**
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  listTableParentProcesses(tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Process>): void;
  listTableParentProcesses(tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Process>, callback?: msRest.ServiceCallback<Models.Process>): Promise<Models.ListTableParentProcessesResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        options
      },
      listTableParentProcessesOperationSpec,
      callback) as Promise<Models.ListTableParentProcessesResponse>;
  }

  /**
   * Replace a specific table
   * @summary Replace table
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.ReplaceTableResponse>
   */
  replaceTable(tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.ReplaceTableResponse>;
  /**
   * @param tableid
   * @param callback The callback
   */
  replaceTable(tableid: string, callback: msRest.ServiceCallback<Models.Table>): void;
  /**
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  replaceTable(tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Table>): void;
  replaceTable(tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Table>, callback?: msRest.ServiceCallback<Models.Table>): Promise<Models.ReplaceTableResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        options
      },
      replaceTableOperationSpec,
      callback) as Promise<Models.ReplaceTableResponse>;
  }

  /**
   * Get the rows of a specific table
   * @summary List table rows
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTableRowsResponse>
   */
  listTableRows(tableid: string, options?: Models.OrchestrationAPIListTableRowsOptionalParams): Promise<Models.ListTableRowsResponse>;
  /**
   * @param tableid
   * @param callback The callback
   */
  listTableRows(tableid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  listTableRows(tableid: string, options: Models.OrchestrationAPIListTableRowsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  listTableRows(tableid: string, options?: Models.OrchestrationAPIListTableRowsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ListTableRowsResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        options
      },
      listTableRowsOperationSpec,
      callback) as Promise<Models.ListTableRowsResponse>;
  }

  /**
   * Create a row on a specific table
   * @summary Create table row
   * @param tableid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTableRowResponse>
   */
  createTableRow(tableid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateTableRowResponse>;
  /**
   * @param tableid
   * @param callback The callback
   */
  createTableRow(tableid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param options The optional parameters
   * @param callback The callback
   */
  createTableRow(tableid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createTableRow(tableid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateTableRowResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        options
      },
      createTableRowOperationSpec,
      callback) as Promise<Models.CreateTableRowResponse>;
  }

  /**
   * Get a specific row from a specific table
   * @summary Get table row
   * @param tableid
   * @param rowid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTableRowResponse>
   */
  getTableRow(tableid: string, rowid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTableRowResponse>;
  /**
   * @param tableid
   * @param rowid
   * @param callback The callback
   */
  getTableRow(tableid: string, rowid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param rowid
   * @param options The optional parameters
   * @param callback The callback
   */
  getTableRow(tableid: string, rowid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getTableRow(tableid: string, rowid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetTableRowResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        rowid,
        options
      },
      getTableRowOperationSpec,
      callback) as Promise<Models.GetTableRowResponse>;
  }

  /**
   * Delete a specific row from a specific table
   * @summary Delete table row
   * @param tableid
   * @param rowid
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteTableRowResponse>
   */
  deleteTableRow(tableid: string, rowid: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteTableRowResponse>;
  /**
   * @param tableid
   * @param rowid
   * @param callback The callback
   */
  deleteTableRow(tableid: string, rowid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param rowid
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTableRow(tableid: string, rowid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteTableRow(tableid: string, rowid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteTableRowResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        rowid,
        options
      },
      deleteTableRowOperationSpec,
      callback) as Promise<Models.DeleteTableRowResponse>;
  }

  /**
   * Create a field on a specific table row
   * @summary Create table row field
   * @param tableid
   * @param rowid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTableRowFieldResponse>
   */
  createTableRowField(tableid: string, rowid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateTableRowFieldResponse>;
  /**
   * @param tableid
   * @param rowid
   * @param callback The callback
   */
  createTableRowField(tableid: string, rowid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param rowid
   * @param options The optional parameters
   * @param callback The callback
   */
  createTableRowField(tableid: string, rowid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createTableRowField(tableid: string, rowid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateTableRowFieldResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        rowid,
        options
      },
      createTableRowFieldOperationSpec,
      callback) as Promise<Models.CreateTableRowFieldResponse>;
  }

  /**
   * Get a specific field from a specific table row
   * @summary Get table row field
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTableRowFieldResponse>
   */
  getTableRowField(tableid: string, rowid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTableRowFieldResponse>;
  /**
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param callback The callback
   */
  getTableRowField(tableid: string, rowid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  getTableRowField(tableid: string, rowid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getTableRowField(tableid: string, rowid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetTableRowFieldResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        rowid,
        fieldname,
        options
      },
      getTableRowFieldOperationSpec,
      callback) as Promise<Models.GetTableRowFieldResponse>;
  }

  /**
   * Update a specific field from a specific table row
   * @summary Update table row field
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTableRowFieldResponse>
   */
  updateTableRowField(tableid: string, rowid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateTableRowFieldResponse>;
  /**
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param callback The callback
   */
  updateTableRowField(tableid: string, rowid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTableRowField(tableid: string, rowid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateTableRowField(tableid: string, rowid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateTableRowFieldResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        rowid,
        fieldname,
        options
      },
      updateTableRowFieldOperationSpec,
      callback) as Promise<Models.UpdateTableRowFieldResponse>;
  }

  /**
   * Delete a specific field from a specific table row
   * @summary Delete table row field
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteTableRowFieldResponse>
   */
  deleteTableRowField(tableid: string, rowid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteTableRowFieldResponse>;
  /**
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param callback The callback
   */
  deleteTableRowField(tableid: string, rowid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tableid
   * @param rowid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTableRowField(tableid: string, rowid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteTableRowField(tableid: string, rowid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteTableRowFieldResponse> {
    return this.sendOperationRequest(
      {
        tableid,
        rowid,
        fieldname,
        options
      },
      deleteTableRowFieldOperationSpec,
      callback) as Promise<Models.DeleteTableRowFieldResponse>;
  }

  /**
   * Lists all User Tokens visible to user. Admins may see all users' keys; members may see only
   * their own keys
   * @summary List User Tokens
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUserTokensResponse>
   */
  listUserTokens(options?: Models.OrchestrationAPIListUserTokensOptionalParams): Promise<Models.ListUserTokensResponse>;
  /**
   * @param callback The callback
   */
  listUserTokens(callback: msRest.ServiceCallback<Models.Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listUserTokens(options: Models.OrchestrationAPIListUserTokensOptionalParams, callback: msRest.ServiceCallback<Models.Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema>): void;
  listUserTokens(options?: Models.OrchestrationAPIListUserTokensOptionalParams | msRest.ServiceCallback<Models.Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema>): Promise<Models.ListUserTokensResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listUserTokensOperationSpec,
      callback) as Promise<Models.ListUserTokensResponse>;
  }

  /**
   * Create a new User Token in a requested state
   * @summary Create new User Token
   * @param xForwardedFor
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateUserTokenResponse>
   */
  createUserToken(xForwardedFor: string, options?: Models.OrchestrationAPICreateUserTokenOptionalParams): Promise<Models.CreateUserTokenResponse>;
  /**
   * @param xForwardedFor
   * @param callback The callback
   */
  createUserToken(xForwardedFor: string, callback: msRest.ServiceCallback<Models.UserToken>): void;
  /**
   * @param xForwardedFor
   * @param options The optional parameters
   * @param callback The callback
   */
  createUserToken(xForwardedFor: string, options: Models.OrchestrationAPICreateUserTokenOptionalParams, callback: msRest.ServiceCallback<Models.UserToken>): void;
  createUserToken(xForwardedFor: string, options?: Models.OrchestrationAPICreateUserTokenOptionalParams | msRest.ServiceCallback<Models.UserToken>, callback?: msRest.ServiceCallback<Models.UserToken>): Promise<Models.CreateUserTokenResponse> {
    return this.sendOperationRequest(
      {
        xForwardedFor,
        options
      },
      createUserTokenOperationSpec,
      callback) as Promise<Models.CreateUserTokenResponse>;
  }

  /**
   * If authenticated, get User Token
   * @summary Get User Token
   * @param userTokenID
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUserTokenResponse>
   */
  getUserToken(userTokenID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetUserTokenResponse>;
  /**
   * @param userTokenID
   * @param callback The callback
   */
  getUserToken(userTokenID: string, callback: msRest.ServiceCallback<Models.UserToken>): void;
  /**
   * @param userTokenID
   * @param options The optional parameters
   * @param callback The callback
   */
  getUserToken(userTokenID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserToken>): void;
  getUserToken(userTokenID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserToken>, callback?: msRest.ServiceCallback<Models.UserToken>): Promise<Models.GetUserTokenResponse> {
    return this.sendOperationRequest(
      {
        userTokenID,
        options
      },
      getUserTokenOperationSpec,
      callback) as Promise<Models.GetUserTokenResponse>;
  }

  /**
   * Approve an existing User Token that is currently in a requested state
   * Used as part of the web approval flow when User Token is created by the SDK Client.
   * @summary Approve User Token
   * @param userTokenID
   * @param [options] The optional parameters
   * @returns Promise<Models.ApproveUserTokenResponse>
   */
  approveUserToken(userTokenID: string, options?: Models.OrchestrationAPIApproveUserTokenOptionalParams): Promise<Models.ApproveUserTokenResponse>;
  /**
   * @param userTokenID
   * @param callback The callback
   */
  approveUserToken(userTokenID: string, callback: msRest.ServiceCallback<Models.UserToken>): void;
  /**
   * @param userTokenID
   * @param options The optional parameters
   * @param callback The callback
   */
  approveUserToken(userTokenID: string, options: Models.OrchestrationAPIApproveUserTokenOptionalParams, callback: msRest.ServiceCallback<Models.UserToken>): void;
  approveUserToken(userTokenID: string, options?: Models.OrchestrationAPIApproveUserTokenOptionalParams | msRest.ServiceCallback<Models.UserToken>, callback?: msRest.ServiceCallback<Models.UserToken>): Promise<Models.ApproveUserTokenResponse> {
    return this.sendOperationRequest(
      {
        userTokenID,
        options
      },
      approveUserTokenOperationSpec,
      callback) as Promise<Models.ApproveUserTokenResponse>;
  }

  /**
   * Poll for a User Token with "approved" status; supports long polling query params.
   * Used as part of the web approval flow when User Token is created by the SDK Client.
   * @summary Poll for approved User Token
   * @param userTokenID
   * @param [options] The optional parameters
   * @returns Promise<Models.PollUserTokenResponse>
   */
  pollUserToken(userTokenID: string, options?: Models.OrchestrationAPIPollUserTokenOptionalParams): Promise<Models.PollUserTokenResponse>;
  /**
   * @param userTokenID
   * @param callback The callback
   */
  pollUserToken(userTokenID: string, callback: msRest.ServiceCallback<Models.UserToken>): void;
  /**
   * @param userTokenID
   * @param options The optional parameters
   * @param callback The callback
   */
  pollUserToken(userTokenID: string, options: Models.OrchestrationAPIPollUserTokenOptionalParams, callback: msRest.ServiceCallback<Models.UserToken>): void;
  pollUserToken(userTokenID: string, options?: Models.OrchestrationAPIPollUserTokenOptionalParams | msRest.ServiceCallback<Models.UserToken>, callback?: msRest.ServiceCallback<Models.UserToken>): Promise<Models.PollUserTokenResponse> {
    return this.sendOperationRequest(
      {
        userTokenID,
        options
      },
      pollUserTokenOperationSpec,
      callback) as Promise<Models.PollUserTokenResponse>;
  }

  /**
   * Revoke an existing User Token. Admins may revoke any user's User Token; members may only revoke
   * their own User Tokens
   * @summary Revoke User Token
   * @param userTokenID
   * @param [options] The optional parameters
   * @returns Promise<Models.RevokeUserTokenResponse>
   */
  revokeUserToken(userTokenID: string, options?: msRest.RequestOptionsBase): Promise<Models.RevokeUserTokenResponse>;
  /**
   * @param userTokenID
   * @param callback The callback
   */
  revokeUserToken(userTokenID: string, callback: msRest.ServiceCallback<Models.UserToken>): void;
  /**
   * @param userTokenID
   * @param options The optional parameters
   * @param callback The callback
   */
  revokeUserToken(userTokenID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserToken>): void;
  revokeUserToken(userTokenID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserToken>, callback?: msRest.ServiceCallback<Models.UserToken>): Promise<Models.RevokeUserTokenResponse> {
    return this.sendOperationRequest(
      {
        userTokenID,
        options
      },
      revokeUserTokenOperationSpec,
      callback) as Promise<Models.RevokeUserTokenResponse>;
  }

  /**
   * Send a test message
   * @summary Send test message
   * @param username
   * @param messageType
   * @param [options] The optional parameters
   * @returns Promise<Models.SendTestMessageResponse>
   */
  sendTestMessage(username: string, messageType: string, options?: msRest.RequestOptionsBase): Promise<Models.SendTestMessageResponse>;
  /**
   * @param username
   * @param messageType
   * @param callback The callback
   */
  sendTestMessage(username: string, messageType: string, callback: msRest.ServiceCallback<Models.Message>): void;
  /**
   * @param username
   * @param messageType
   * @param options The optional parameters
   * @param callback The callback
   */
  sendTestMessage(username: string, messageType: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Message>): void;
  sendTestMessage(username: string, messageType: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Message>, callback?: msRest.ServiceCallback<Models.Message>): Promise<Models.SendTestMessageResponse> {
    return this.sendOperationRequest(
      {
        username,
        messageType,
        options
      },
      sendTestMessageOperationSpec,
      callback) as Promise<Models.SendTestMessageResponse>;
  }

  /**
   * Gets all triggers for a specific team
   * @summary List triggers
   * @param [options] The optional parameters
   * @returns Promise<Models.ListTriggersResponse>
   */
  listTriggers(options?: Models.OrchestrationAPIListTriggersOptionalParams): Promise<Models.ListTriggersResponse>;
  /**
   * @param callback The callback
   */
  listTriggers(callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listTriggers(options: Models.OrchestrationAPIListTriggersOptionalParams, callback: msRest.ServiceCallback<Models.Webhook>): void;
  listTriggers(options?: Models.OrchestrationAPIListTriggersOptionalParams | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ListTriggersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listTriggersOperationSpec,
      callback) as Promise<Models.ListTriggersResponse>;
  }

  /**
   * Healthcheck for triggers
   * @summary Healthcheck for triggers
   * @param [options] The optional parameters
   * @returns Promise<Models.TriggerHealthcheckResponse>
   */
  triggerHealthcheck(options?: msRest.RequestOptionsBase): Promise<Models.TriggerHealthcheckResponse>;
  /**
   * @param callback The callback
   */
  triggerHealthcheck(callback: msRest.ServiceCallback<Models.Team>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  triggerHealthcheck(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Team>): void;
  triggerHealthcheck(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Team>, callback?: msRest.ServiceCallback<Models.Team>): Promise<Models.TriggerHealthcheckResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      triggerHealthcheckOperationSpec,
      callback) as Promise<Models.TriggerHealthcheckResponse>;
  }

  /**
   * Get an upcoming for a specific team by upcomingID
   * @summary Get upcoming
   * @param upcomingid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUpcomingResponse>
   */
  getUpcoming(upcomingid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetUpcomingResponse>;
  /**
   * @param upcomingid
   * @param callback The callback
   */
  getUpcoming(upcomingid: string, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  /**
   * @param upcomingid
   * @param options The optional parameters
   * @param callback The callback
   */
  getUpcoming(upcomingid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  getUpcoming(upcomingid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Upcoming>, callback?: msRest.ServiceCallback<Models.Upcoming>): Promise<Models.GetUpcomingResponse> {
    return this.sendOperationRequest(
      {
        upcomingid,
        options
      },
      getUpcomingOperationSpec,
      callback) as Promise<Models.GetUpcomingResponse>;
  }

  /**
   * Update an existing upcoming for a specific team
   * @summary Update upcoming
   * @param upcomingid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateUpcomingResponse>
   */
  updateUpcoming(upcomingid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateUpcomingResponse>;
  /**
   * @param upcomingid
   * @param callback The callback
   */
  updateUpcoming(upcomingid: string, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  /**
   * @param upcomingid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateUpcoming(upcomingid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Upcoming>): void;
  updateUpcoming(upcomingid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Upcoming>, callback?: msRest.ServiceCallback<Models.Upcoming>): Promise<Models.UpdateUpcomingResponse> {
    return this.sendOperationRequest(
      {
        upcomingid,
        options
      },
      updateUpcomingOperationSpec,
      callback) as Promise<Models.UpdateUpcomingResponse>;
  }

  /**
   * Delete ax existing upcoming for a specific team
   * @summary Delete upcoming
   * @param upcomingid
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteUpcomingResponse>
   */
  deleteUpcoming(upcomingid: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteUpcomingResponse>;
  /**
   * @param upcomingid
   * @param callback The callback
   */
  deleteUpcoming(upcomingid: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param upcomingid
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteUpcoming(upcomingid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteUpcoming(upcomingid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteUpcomingResponse> {
    return this.sendOperationRequest(
      {
        upcomingid,
        options
      },
      deleteUpcomingOperationSpec,
      callback) as Promise<Models.DeleteUpcomingResponse>;
  }

  /**
   * Get users for a specific team
   * @summary List users
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUsersResponse>
   */
  listUsers(options?: msRest.RequestOptionsBase): Promise<Models.ListUsersResponse>;
  /**
   * @param callback The callback
   */
  listUsers(callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listUsers(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  listUsers(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.ListUsersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listUsersOperationSpec,
      callback) as Promise<Models.ListUsersResponse>;
  }

  /**
   * Create User is only meant for bot / integration users. Human users should be added by
   * invitation.
   * @summary Create user
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateUserResponse>
   */
  createUser(options?: msRest.RequestOptionsBase): Promise<Models.CreateUserResponse>;
  /**
   * @param callback The callback
   */
  createUser(callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createUser(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  createUser(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.CreateUserResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createUserOperationSpec,
      callback) as Promise<Models.CreateUserResponse>;
  }

  /**
   * Get a specific user by username or userID
   * @summary Get user
   * @param username The username or userID of the user to fetch
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUserResponse>
   */
  getUser(username: string, options?: msRest.RequestOptionsBase): Promise<Models.GetUserResponse>;
  /**
   * @param username The username or userID of the user to fetch
   * @param callback The callback
   */
  getUser(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username The username or userID of the user to fetch
   * @param options The optional parameters
   * @param callback The callback
   */
  getUser(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  getUser(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.GetUserResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      getUserOperationSpec,
      callback) as Promise<Models.GetUserResponse>;
  }

  /**
   * Update a specific user by username
   * @summary Update user
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateUserResponse>
   */
  updateUser(username: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateUserResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  updateUser(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  updateUser(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  updateUser(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.UpdateUserResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      updateUserOperationSpec,
      callback) as Promise<Models.UpdateUserResponse>;
  }

  /**
   * Delete a specific user by username
   * @summary Delete user
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteUserResponse>
   */
  deleteUser(username: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteUserResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  deleteUser(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteUser(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  deleteUser(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.DeleteUserResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      deleteUserOperationSpec,
      callback) as Promise<Models.DeleteUserResponse>;
  }

  /**
   * Authenticate a specific user
   * @summary Authenticate user
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthenticateUserGetResponse>
   */
  authenticateUserGet(username: string, options?: Models.OrchestrationAPIAuthenticateUserGetOptionalParams): Promise<Models.AuthenticateUserGetResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  authenticateUserGet(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  authenticateUserGet(username: string, options: Models.OrchestrationAPIAuthenticateUserGetOptionalParams, callback: msRest.ServiceCallback<Models.User>): void;
  authenticateUserGet(username: string, options?: Models.OrchestrationAPIAuthenticateUserGetOptionalParams | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.AuthenticateUserGetResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      authenticateUserGetOperationSpec,
      callback) as Promise<Models.AuthenticateUserGetResponse>;
  }

  /**
   * Authenticate a specific user
   * @summary Authenticate user
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthenticateTeamUserResponse>
   */
  authenticateTeamUser(username: string, options?: Models.OrchestrationAPIAuthenticateTeamUserOptionalParams): Promise<Models.AuthenticateTeamUserResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  authenticateTeamUser(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  authenticateTeamUser(username: string, options: Models.OrchestrationAPIAuthenticateTeamUserOptionalParams, callback: msRest.ServiceCallback<Models.User>): void;
  authenticateTeamUser(username: string, options?: Models.OrchestrationAPIAuthenticateTeamUserOptionalParams | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.AuthenticateTeamUserResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      authenticateTeamUserOperationSpec,
      callback) as Promise<Models.AuthenticateTeamUserResponse>;
  }

  /**
   * Unlock a user from logging in
   * @summary Unlock user login
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.UnlockUserLoginResponse>
   */
  unlockUserLogin(username: string, options?: msRest.RequestOptionsBase): Promise<Models.UnlockUserLoginResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  unlockUserLogin(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  unlockUserLogin(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  unlockUserLogin(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.UnlockUserLoginResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      unlockUserLoginOperationSpec,
      callback) as Promise<Models.UnlockUserLoginResponse>;
  }

  /**
   * Logs a user out
   * @summary Log out
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.LogoutResponse>
   */
  logout(username: string, options?: msRest.RequestOptionsBase): Promise<Models.LogoutResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  logout(username: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  logout(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  logout(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.LogoutResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      logoutOperationSpec,
      callback) as Promise<Models.LogoutResponse>;
  }

  /**
   * Get files for a specific user
   * @summary List user files
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUserFilesResponse>
   */
  listUserFiles(username: string, options?: msRest.RequestOptionsBase): Promise<Models.ListUserFilesResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listUserFiles(username: string, callback: msRest.ServiceCallback<Models.File>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listUserFiles(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.File>): void;
  listUserFiles(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.File>, callback?: msRest.ServiceCallback<Models.File>): Promise<Models.ListUserFilesResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listUserFilesOperationSpec,
      callback) as Promise<Models.ListUserFilesResponse>;
  }

  /**
   * Get all the groups for a specific user
   * @summary List groups
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUserGroupsResponse>
   */
  listUserGroups(username: string, options?: msRest.RequestOptionsBase): Promise<Models.ListUserGroupsResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listUserGroups(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listUserGroups(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  listUserGroups(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.ListUserGroupsResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listUserGroupsOperationSpec,
      callback) as Promise<Models.ListUserGroupsResponse>;
  }

  /**
   * Get all the integrations for a specific user
   * @summary List user integrations
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUserIntegrationsResponse>
   */
  listUserIntegrations(username: string, options?: msRest.RequestOptionsBase): Promise<Models.ListUserIntegrationsResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listUserIntegrations(username: string, callback: msRest.ServiceCallback<Models.User>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listUserIntegrations(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.User>): void;
  listUserIntegrations(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.User>, callback?: msRest.ServiceCallback<Models.User>): Promise<Models.ListUserIntegrationsResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listUserIntegrationsOperationSpec,
      callback) as Promise<Models.ListUserIntegrationsResponse>;
  }

  /**
   * Get all notifications for a specific user
   * @summary List user notifications
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListUserNotificationsResponse>
   */
  listUserNotifications(username: string, options?: msRest.RequestOptionsBase): Promise<Models.ListUserNotificationsResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listUserNotifications(username: string, callback: msRest.ServiceCallback<Models.Notification>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listUserNotifications(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Notification>): void;
  listUserNotifications(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Notification>, callback?: msRest.ServiceCallback<Models.Notification>): Promise<Models.ListUserNotificationsResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listUserNotificationsOperationSpec,
      callback) as Promise<Models.ListUserNotificationsResponse>;
  }

  /**
   * Get a specific notification for a specific user
   * @summary Get user notification
   * @param username
   * @param notificationid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUserNotificationResponse>
   */
  getUserNotification(username: string, notificationid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetUserNotificationResponse>;
  /**
   * @param username
   * @param notificationid
   * @param callback The callback
   */
  getUserNotification(username: string, notificationid: string, callback: msRest.ServiceCallback<Models.Notification>): void;
  /**
   * @param username
   * @param notificationid
   * @param options The optional parameters
   * @param callback The callback
   */
  getUserNotification(username: string, notificationid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Notification>): void;
  getUserNotification(username: string, notificationid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Notification>, callback?: msRest.ServiceCallback<Models.Notification>): Promise<Models.GetUserNotificationResponse> {
    return this.sendOperationRequest(
      {
        username,
        notificationid,
        options
      },
      getUserNotificationOperationSpec,
      callback) as Promise<Models.GetUserNotificationResponse>;
  }

  /**
   * Update a specific notification for a specific user
   * @summary Update user notification
   * @param username
   * @param notificationid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateUserNotificationResponse>
   */
  updateUserNotification(username: string, notificationid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateUserNotificationResponse>;
  /**
   * @param username
   * @param notificationid
   * @param callback The callback
   */
  updateUserNotification(username: string, notificationid: string, callback: msRest.ServiceCallback<Models.Notification>): void;
  /**
   * @param username
   * @param notificationid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateUserNotification(username: string, notificationid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Notification>): void;
  updateUserNotification(username: string, notificationid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Notification>, callback?: msRest.ServiceCallback<Models.Notification>): Promise<Models.UpdateUserNotificationResponse> {
    return this.sendOperationRequest(
      {
        username,
        notificationid,
        options
      },
      updateUserNotificationOperationSpec,
      callback) as Promise<Models.UpdateUserNotificationResponse>;
  }

  /**
   * Mark all notifications of a specified type for a user as read
   * @summary Mark all notifications of a specified type for a user as read
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.MarkUserNotificationsReadResponse>
   */
  markUserNotificationsRead(username: string, options?: Models.OrchestrationAPIMarkUserNotificationsReadOptionalParams): Promise<Models.MarkUserNotificationsReadResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  markUserNotificationsRead(username: string, callback: msRest.ServiceCallback<Models.Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  markUserNotificationsRead(username: string, options: Models.OrchestrationAPIMarkUserNotificationsReadOptionalParams, callback: msRest.ServiceCallback<Models.Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema>): void;
  markUserNotificationsRead(username: string, options?: Models.OrchestrationAPIMarkUserNotificationsReadOptionalParams | msRest.ServiceCallback<Models.Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema>): Promise<Models.MarkUserNotificationsReadResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      markUserNotificationsReadOperationSpec,
      callback) as Promise<Models.MarkUserNotificationsReadResponse>;
  }

  /**
   * Get the recent logins for a specific user and team
   * @summary List recent logins
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListRecentLoginsResponse>
   */
  listRecentLogins(username: string, options?: msRest.RequestOptionsBase): Promise<Models.ListRecentLoginsResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listRecentLogins(username: string, callback: msRest.ServiceCallback<Models.UserLogins>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listRecentLogins(username: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserLogins>): void;
  listRecentLogins(username: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserLogins>, callback?: msRest.ServiceCallback<Models.UserLogins>): Promise<Models.ListRecentLoginsResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listRecentLoginsOperationSpec,
      callback) as Promise<Models.ListRecentLoginsResponse>;
  }

  /**
   * Retrieve a list of favorite processes for a user
   * @summary List favorite processes for a user
   * @param username
   * @param [options] The optional parameters
   * @returns Promise<Models.ListFavoritesResponse>
   */
  listFavorites(username: string, options?: Models.OrchestrationAPIListFavoritesOptionalParams): Promise<Models.ListFavoritesResponse>;
  /**
   * @param username
   * @param callback The callback
   */
  listFavorites(username: string, callback: msRest.ServiceCallback<Models.FavoritesList>): void;
  /**
   * @param username
   * @param options The optional parameters
   * @param callback The callback
   */
  listFavorites(username: string, options: Models.OrchestrationAPIListFavoritesOptionalParams, callback: msRest.ServiceCallback<Models.FavoritesList>): void;
  listFavorites(username: string, options?: Models.OrchestrationAPIListFavoritesOptionalParams | msRest.ServiceCallback<Models.FavoritesList>, callback?: msRest.ServiceCallback<Models.FavoritesList>): Promise<Models.ListFavoritesResponse> {
    return this.sendOperationRequest(
      {
        username,
        options
      },
      listFavoritesOperationSpec,
      callback) as Promise<Models.ListFavoritesResponse>;
  }

  /**
   * Retrieve the favorite status of a process for a user
   * @summary Get favorite status of a process for a user
   * @param username
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFavoriteStatusResponse>
   */
  getFavoriteStatus(username: string, processid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetFavoriteStatusResponse>;
  /**
   * @param username
   * @param processid
   * @param callback The callback
   */
  getFavoriteStatus(username: string, processid: string, callback: msRest.ServiceCallback<Models.Favorite>): void;
  /**
   * @param username
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  getFavoriteStatus(username: string, processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Favorite>): void;
  getFavoriteStatus(username: string, processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Favorite>, callback?: msRest.ServiceCallback<Models.Favorite>): Promise<Models.GetFavoriteStatusResponse> {
    return this.sendOperationRequest(
      {
        username,
        processid,
        options
      },
      getFavoriteStatusOperationSpec,
      callback) as Promise<Models.GetFavoriteStatusResponse>;
  }

  /**
   * Update the favorite status of a process for a user
   * @summary Update favorite status of a process for a user
   * @param body
   * @param username
   * @param processid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateFavoriteStatusResponse>
   */
  updateFavoriteStatus(body: Models.FavoritesNew, username: string, processid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateFavoriteStatusResponse>;
  /**
   * @param body
   * @param username
   * @param processid
   * @param callback The callback
   */
  updateFavoriteStatus(body: Models.FavoritesNew, username: string, processid: string, callback: msRest.ServiceCallback<Models.Favorite>): void;
  /**
   * @param body
   * @param username
   * @param processid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateFavoriteStatus(body: Models.FavoritesNew, username: string, processid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Favorite>): void;
  updateFavoriteStatus(body: Models.FavoritesNew, username: string, processid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Favorite>, callback?: msRest.ServiceCallback<Models.Favorite>): Promise<Models.UpdateFavoriteStatusResponse> {
    return this.sendOperationRequest(
      {
        body,
        username,
        processid,
        options
      },
      updateFavoriteStatusOperationSpec,
      callback) as Promise<Models.UpdateFavoriteStatusResponse>;
  }

  /**
   * Gets all webhooks for a specific team
   * @summary List webhooks for a specific team
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWebhooksResponse>
   */
  listWebhooks(options?: msRest.RequestOptionsBase): Promise<Models.ListWebhooksResponse>;
  /**
   * @param callback The callback
   */
  listWebhooks(callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listWebhooks(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  listWebhooks(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.ListWebhooksResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listWebhooksOperationSpec,
      callback) as Promise<Models.ListWebhooksResponse>;
  }

  /**
   * Creates a webhook for a specific team
   * @summary Create a webhook for a specific team
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTeamWebhookResponse>
   */
  createTeamWebhook(options?: msRest.RequestOptionsBase): Promise<Models.CreateTeamWebhookResponse>;
  /**
   * @param callback The callback
   */
  createTeamWebhook(callback: msRest.ServiceCallback<Models.Webhook>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createTeamWebhook(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Webhook>): void;
  createTeamWebhook(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Webhook>, callback?: msRest.ServiceCallback<Models.Webhook>): Promise<Models.CreateTeamWebhookResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createTeamWebhookOperationSpec,
      callback) as Promise<Models.CreateTeamWebhookResponse>;
  }

  /**
   * Get a specific webhook
   * @summary Get webhook
   * @param webhookid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamWebhookResponse>
   */
  getTeamWebhook(webhookid: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTeamWebhookResponse>;
  /**
   * @param webhookid
   * @param callback The callback
   */
  getTeamWebhook(webhookid: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param webhookid
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamWebhook(webhookid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getTeamWebhook(webhookid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetTeamWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookid,
        options
      },
      getTeamWebhookOperationSpec,
      callback) as Promise<Models.GetTeamWebhookResponse>;
  }

  /**
   * Update a specific webhook
   * @summary Update webhook
   * @param webhookid
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWebhookResponse>
   */
  updateWebhook(webhookid: string, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWebhookResponse>;
  /**
   * @param webhookid
   * @param callback The callback
   */
  updateWebhook(webhookid: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param webhookid
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWebhook(webhookid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  updateWebhook(webhookid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookid,
        options
      },
      updateWebhookOperationSpec,
      callback) as Promise<Models.UpdateWebhookResponse>;
  }

  /**
   * Delete a specific webhook
   * @summary Delete webhook
   * @param webhookid
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWebhookResponse>
   */
  deleteWebhook(webhookid: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWebhookResponse>;
  /**
   * @param webhookid
   * @param callback The callback
   */
  deleteWebhook(webhookid: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param webhookid
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWebhook(webhookid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  deleteWebhook(webhookid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DeleteWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookid,
        options
      },
      deleteWebhookOperationSpec,
      callback) as Promise<Models.DeleteWebhookResponse>;
  }

  /**
   * Gets a webhook by its id
   * @summary Get webhook
   * @param webhookid
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWebhookResponse>
   */
  getWebhook(webhookid: string, options?: Models.OrchestrationAPIGetWebhookOptionalParams): Promise<Models.GetWebhookResponse>;
  /**
   * @param webhookid
   * @param callback The callback
   */
  getWebhook(webhookid: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param webhookid
   * @param options The optional parameters
   * @param callback The callback
   */
  getWebhook(webhookid: string, options: Models.OrchestrationAPIGetWebhookOptionalParams, callback: msRest.ServiceCallback<string>): void;
  getWebhook(webhookid: string, options?: Models.OrchestrationAPIGetWebhookOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookid,
        options
      },
      getWebhookOperationSpec,
      callback) as Promise<Models.GetWebhookResponse>;
  }

  /**
   * Create a new webhook
   * @summary Create webhook
   * @param webhookid
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWebhookResponse>
   */
  createWebhook(webhookid: string, options?: msRest.RequestOptionsBase): Promise<Models.CreateWebhookResponse>;
  /**
   * @param webhookid
   * @param callback The callback
   */
  createWebhook(webhookid: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param webhookid
   * @param options The optional parameters
   * @param callback The callback
   */
  createWebhook(webhookid: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  createWebhook(webhookid: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.CreateWebhookResponse> {
    return this.sendOperationRequest(
      {
        webhookid,
        options
      },
      createWebhookOperationSpec,
      callback) as Promise<Models.CreateWebhookResponse>;
  }

  /**
   * Get process metrics for a specific team/process for the **most 50 recent**
   * completed/aborted/failed runs. This does NOT include test runs.
   *
   * Can be filtered to a specific timeframe with the query params.
   *
   * The `start` and `end` query params format is `YYYY-MM-DD`.
   * @summary Get process metrics
   * @param processID The id of the process to get metrics for
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessMetricsResponse>
   */
  getProcessMetrics(processID: string, options?: Models.OrchestrationAPIGetProcessMetricsOptionalParams): Promise<Models.GetProcessMetricsResponse>;
  /**
   * @param processID The id of the process to get metrics for
   * @param callback The callback
   */
  getProcessMetrics(processID: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param processID The id of the process to get metrics for
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessMetrics(processID: string, options: Models.OrchestrationAPIGetProcessMetricsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getProcessMetrics(processID: string, options?: Models.OrchestrationAPIGetProcessMetricsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.GetProcessMetricsResponse> {
    return this.sendOperationRequest(
      {
        processID,
        options
      },
      getProcessMetricsOperationSpec,
      callback) as Promise<Models.GetProcessMetricsResponse>;
  }

  /**
   * Returns a list of all feature modules, optionally filtered by what is available to the team.
   * @summary Lists modules.
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamModulesResponse>
   */
  getTeamModules(options?: Models.OrchestrationAPIGetTeamModulesOptionalParams): Promise<Models.GetTeamModulesResponse>;
  /**
   * @param callback The callback
   */
  getTeamModules(callback: msRest.ServiceCallback<Models.Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamModules(options: Models.OrchestrationAPIGetTeamModulesOptionalParams, callback: msRest.ServiceCallback<Models.Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema>): void;
  getTeamModules(options?: Models.OrchestrationAPIGetTeamModulesOptionalParams | msRest.ServiceCallback<Models.Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema>): Promise<Models.GetTeamModulesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getTeamModulesOperationSpec,
      callback) as Promise<Models.GetTeamModulesResponse>;
  }

  /**
   * Retrieve a feature module.
   * @summary Retrieve feature module.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamModuleResponse>
   */
  getTeamModule(modulename: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTeamModuleResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  getTeamModule(modulename: string, callback: msRest.ServiceCallback<Models.Module>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamModule(modulename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Module>): void;
  getTeamModule(modulename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Module>, callback?: msRest.ServiceCallback<Models.Module>): Promise<Models.GetTeamModuleResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      getTeamModuleOperationSpec,
      callback) as Promise<Models.GetTeamModuleResponse>;
  }

  /**
   * Returns all features of a module, optionally filtered by what is available to the team.
   * @summary Lists features of a module.
   * @param modulename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamModuleFeaturesResponse>
   */
  getTeamModuleFeatures(modulename: string, options?: Models.OrchestrationAPIGetTeamModuleFeaturesOptionalParams): Promise<Models.GetTeamModuleFeaturesResponse>;
  /**
   * @param modulename
   * @param callback The callback
   */
  getTeamModuleFeatures(modulename: string, callback: msRest.ServiceCallback<Models.Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param modulename
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamModuleFeatures(modulename: string, options: Models.OrchestrationAPIGetTeamModuleFeaturesOptionalParams, callback: msRest.ServiceCallback<Models.Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>): void;
  getTeamModuleFeatures(modulename: string, options?: Models.OrchestrationAPIGetTeamModuleFeaturesOptionalParams | msRest.ServiceCallback<Models.Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema>): Promise<Models.GetTeamModuleFeaturesResponse> {
    return this.sendOperationRequest(
      {
        modulename,
        options
      },
      getTeamModuleFeaturesOperationSpec,
      callback) as Promise<Models.GetTeamModuleFeaturesResponse>;
  }

  /**
   * Returns a list of all features, optionally filtered by what is available to the team.
   * @summary Lists features.
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamFeaturesResponse>
   */
  getTeamFeatures(options?: Models.OrchestrationAPIGetTeamFeaturesOptionalParams): Promise<Models.GetTeamFeaturesResponse>;
  /**
   * @param callback The callback
   */
  getTeamFeatures(callback: msRest.ServiceCallback<Models.Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamFeatures(options: Models.OrchestrationAPIGetTeamFeaturesOptionalParams, callback: msRest.ServiceCallback<Models.Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema>): void;
  getTeamFeatures(options?: Models.OrchestrationAPIGetTeamFeaturesOptionalParams | msRest.ServiceCallback<Models.Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema>): Promise<Models.GetTeamFeaturesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getTeamFeaturesOperationSpec,
      callback) as Promise<Models.GetTeamFeaturesResponse>;
  }

  /**
   * Retrieves a feature.
   * @summary Retrieve feature.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamFeatureResponse>
   */
  getTeamFeature(featurename: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTeamFeatureResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  getTeamFeature(featurename: string, callback: msRest.ServiceCallback<Models.Feature>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamFeature(featurename: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Feature>): void;
  getTeamFeature(featurename: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Feature>, callback?: msRest.ServiceCallback<Models.Feature>): Promise<Models.GetTeamFeatureResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      getTeamFeatureOperationSpec,
      callback) as Promise<Models.GetTeamFeatureResponse>;
  }

  /**
   * Returns all modules that contain this feature, optionally filtered by what is available to the
   * team.
   * @summary Returns all modules containing this feature.
   * @param featurename
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamFeatureModulesResponse>
   */
  getTeamFeatureModules(featurename: string, options?: Models.OrchestrationAPIGetTeamFeatureModulesOptionalParams): Promise<Models.GetTeamFeatureModulesResponse>;
  /**
   * @param featurename
   * @param callback The callback
   */
  getTeamFeatureModules(featurename: string, callback: msRest.ServiceCallback<Models.Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param featurename
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamFeatureModules(featurename: string, options: Models.OrchestrationAPIGetTeamFeatureModulesOptionalParams, callback: msRest.ServiceCallback<Models.Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>): void;
  getTeamFeatureModules(featurename: string, options?: Models.OrchestrationAPIGetTeamFeatureModulesOptionalParams | msRest.ServiceCallback<Models.Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema>): Promise<Models.GetTeamFeatureModulesResponse> {
    return this.sendOperationRequest(
      {
        featurename,
        options
      },
      getTeamFeatureModulesOperationSpec,
      callback) as Promise<Models.GetTeamFeatureModulesResponse>;
  }

  /**
   * Returns a list of frequently used actions based on number of steps that reference the action
   * @summary Returns frequently used actions for a team
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamFrequentlyUsedActionsResponse>
   */
  getTeamFrequentlyUsedActions(options?: Models.OrchestrationAPIGetTeamFrequentlyUsedActionsOptionalParams): Promise<Models.GetTeamFrequentlyUsedActionsResponse>;
  /**
   * @param callback The callback
   */
  getTeamFrequentlyUsedActions(callback: msRest.ServiceCallback<Models.Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamFrequentlyUsedActions(options: Models.OrchestrationAPIGetTeamFrequentlyUsedActionsOptionalParams, callback: msRest.ServiceCallback<Models.Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema>): void;
  getTeamFrequentlyUsedActions(options?: Models.OrchestrationAPIGetTeamFrequentlyUsedActionsOptionalParams | msRest.ServiceCallback<Models.Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema>): Promise<Models.GetTeamFrequentlyUsedActionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getTeamFrequentlyUsedActionsOperationSpec,
      callback) as Promise<Models.GetTeamFrequentlyUsedActionsResponse>;
  }

  /**
   * Returns a list of recently used actions based on created date of the associated step
   * @summary Returns recently used actions for a team
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTeamRecentlyUsedActionsResponse>
   */
  getTeamRecentlyUsedActions(options?: Models.OrchestrationAPIGetTeamRecentlyUsedActionsOptionalParams): Promise<Models.GetTeamRecentlyUsedActionsResponse>;
  /**
   * @param callback The callback
   */
  getTeamRecentlyUsedActions(callback: msRest.ServiceCallback<Models.Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTeamRecentlyUsedActions(options: Models.OrchestrationAPIGetTeamRecentlyUsedActionsOptionalParams, callback: msRest.ServiceCallback<Models.Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema>): void;
  getTeamRecentlyUsedActions(options?: Models.OrchestrationAPIGetTeamRecentlyUsedActionsOptionalParams | msRest.ServiceCallback<Models.Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema>, callback?: msRest.ServiceCallback<Models.Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema>): Promise<Models.GetTeamRecentlyUsedActionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getTeamRecentlyUsedActionsOperationSpec,
      callback) as Promise<Models.GetTeamRecentlyUsedActionsResponse>;
  }

  /**
   * Add a field to the list of form fields on this process. Form fields are a subset of writable
   * fields that are intended for user input. If the field already exists, this has no action.
   * @summary Add form field
   * @param processid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.PutTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse>
   */
  putTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.PutTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse>;
  /**
   * @param processid
   * @param fieldname
   * @param callback The callback
   */
  putTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param processid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  putTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  putTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.PutTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse> {
    return this.sendOperationRequest(
      {
        processid,
        fieldname,
        options
      },
      putTeamsTeamnameProcessesProcessidFormFieldsFieldnameOperationSpec,
      callback) as Promise<Models.PutTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse>;
  }

  /**
   * Delete a field from the list of form fields on this process. Form fields are a subset of
   * writable fields that are intended for user input.
   * @summary Remove form field
   * @param processid
   * @param fieldname
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse>
   */
  deleteTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse>;
  /**
   * @param processid
   * @param fieldname
   * @param callback The callback
   */
  deleteTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param processid
   * @param fieldname
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteTeamsTeamnameProcessesProcessidFormFieldsFieldname(processid: string, fieldname: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse> {
    return this.sendOperationRequest(
      {
        processid,
        fieldname,
        options
      },
      deleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameOperationSpec,
      callback) as Promise<Models.DeleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const listAppsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "apps",
  queryParameters: [
    Parameters.action0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAppOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "apps/{appname}",
  urlParameters: [
    Parameters.appname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAppActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "apps/{appname}/{action}",
  urlParameters: [
    Parameters.action1,
    Parameters.appname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAppActionVersionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "apps/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.appversion,
    Parameters.action1,
    Parameters.appname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const createAppActionVersionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "apps/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.appversion,
    Parameters.appname,
    Parameters.action1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const hellosignCallbackOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "hellosign/callback",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const slackCommandsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "slack/commands",
  responses: {
    201: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const slackInteractiveOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "slack/interactive",
  responses: {
    201: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const awaitTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "await/{transactionid}",
  urlParameters: [
    Parameters.transactionid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getEnvOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "env",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const communitySsoOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "community-sso",
  queryParameters: [
    Parameters.clientId,
    Parameters.timestamp,
    Parameters.signature
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CommunitySSO
    },
    default: {}
  },
  serializer
};

const communitySsoV2OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "community-sso/v2",
  queryParameters: [
    Parameters.jwt
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CommunitySSOV2
    },
    default: {}
  },
  serializer
};

const smsInboundOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "sms/inbound",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const onSesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "aws/on-ses",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const onSesSnsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "aws/on-ses-sns",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const snsOnHelpuUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "aws/sns/on-help-updated",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const sesBounceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "aws/ses/bounce",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const sesOpenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "aws/ses/open",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getFeaturesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "features",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FeatureState"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const reindexHelpOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "help/reindex",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Field"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getModuleFeaturesModulesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/modules",
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createModuleFeaturesModuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "module-features/modules",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Pathsdk40osmoduleFeaturesModulespostrequestbodycontentapplicationJsonschema
  },
  responses: {
    201: {
      bodyMapper: Mappers.Module
    },
    409: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getModuleFeaturesModuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/modules/{modulename}",
  urlParameters: [
    Parameters.modulename
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Module
    },
    default: {}
  },
  serializer
};

const updateModuleFeaturesModuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "module-features/modules/{modulename}",
  urlParameters: [
    Parameters.modulename
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1t80n3vmoduleFeaturesModulesModulenamepatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Module
    },
    409: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteModuleFeaturesModuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "module-features/modules/{modulename}",
  urlParameters: [
    Parameters.modulename
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateTeamModuleAvailabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "module-features/teams/${teamname}/modules/{modulename}",
  urlParameters: [
    Parameters.modulename,
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1bbj998moduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getModuleFeaturesModuleFeaturesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/modules/{modulename}/features",
  urlParameters: [
    Parameters.modulename
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const addModuleFeaturesFeatureToModuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "module-features/modules/{modulename}/features",
  urlParameters: [
    Parameters.modulename
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths16s58acmoduleFeaturesModulesModulenameFeaturespostrequestbodycontentapplicationJsonschema
  },
  responses: {
    201: {
      bodyMapper: Mappers.Feature
    },
    default: {}
  },
  serializer
};

const getModuleFeaturesModuleFeatureOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/modules/{modulename}/features/{featurename}",
  urlParameters: [
    Parameters.modulename,
    Parameters.featurename
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Feature
    },
    default: {}
  },
  serializer
};

const deleteModuleFeaturesModuleFeatureOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "module-features/modules/{modulename}/features/{featurename}",
  urlParameters: [
    Parameters.modulename,
    Parameters.featurename
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getModuleFeaturesFeaturesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/features",
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createModuleFeaturesFeatureOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "module-features/features",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths10iunt5moduleFeaturesFeaturespostrequestbodycontentapplicationJsonschema
  },
  responses: {
    201: {
      bodyMapper: Mappers.Feature
    },
    409: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getModuleFeaturesFeatureOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/features/{featurename}",
  urlParameters: [
    Parameters.featurename
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Feature
    },
    default: {}
  },
  serializer
};

const deleteModuleFeaturesFeatureOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "module-features/features/{featurename}",
  urlParameters: [
    Parameters.featurename
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateModuleFeaturesFeaturesOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "module-features/features/{featurename}",
  urlParameters: [
    Parameters.featurename
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1j5fzv6moduleFeaturesFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Feature
    },
    409: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateTeamFeatureAvailabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "module-features/teams/{teamname}/features/{featurename}",
  urlParameters: [
    Parameters.featurename,
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths23ngpnmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getModuleFeaturesFeatureModulesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "module-features/features/{featurename}/modules",
  urlParameters: [
    Parameters.featurename
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createProcessInAnotherTeamOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "processes",
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.Process,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const getAdminProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "processes/{processid}",
  urlParameters: [
    Parameters.processid
  ],
  queryParameters: [
    Parameters.exportParameter
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const createTeamOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams",
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.Team,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const getTeamOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const updateTeamOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const deleteTeamOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const createAccessRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/access-requests",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AccessRequest
    },
    default: {}
  },
  serializer
};

const approveAccessRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/access-requests/{accessrequestid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.accessrequestid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AccessRequest
    },
    default: {}
  },
  serializer
};

const getAppsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/apps",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.action0,
    Parameters.includeSharedApps,
    Parameters.integrationDefinition,
    Parameters.customIntegrationWildcard,
    Parameters.ignoreAvailability
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createAppOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/apps",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getTeamAppOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/apps/{appname}",
  urlParameters: [
    Parameters.appname,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.includeSharedApps
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteAppOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/apps/{appname}",
  urlParameters: [
    Parameters.appname,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.importedCollection
  ],
  responses: {
    204: {
      bodyMapper: Mappers.ImportAction
    },
    default: {}
  },
  serializer
};

const getTeamAppActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/apps/{appname}/{action}",
  urlParameters: [
    Parameters.appname,
    Parameters.teamname,
    Parameters.action1
  ],
  queryParameters: [
    Parameters.includeSharedApps
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "App"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getActionVersionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/apps/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.appversion,
    Parameters.appname,
    Parameters.teamname,
    Parameters.action1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const updateAppActionVersionOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/apps/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.appversion,
    Parameters.appname,
    Parameters.teamname,
    Parameters.action1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const createTeamAppActionVersionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/apps/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.appversion,
    Parameters.appname,
    Parameters.teamname,
    Parameters.action1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const importAppOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/apps:import",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.ImportAction,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.ImportAction
    },
    default: {}
  },
  serializer
};

const createCustomIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/custom-integration-definition",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.CustomIntegrationDefinition,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const getCustomIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/custom-integration-definition/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.teamname,
    Parameters.appname,
    Parameters.action1,
    Parameters.appversion
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const updateCustomIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/custom-integration-definition/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.teamname,
    Parameters.appname,
    Parameters.action1,
    Parameters.appversion
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.CustomIntegrationDefinition,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const deleteCustomIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/custom-integration-definition/{appname}/{action}/{appversion}",
  urlParameters: [
    Parameters.teamname,
    Parameters.appname,
    Parameters.action1,
    Parameters.appversion
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const listActionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/actions",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/workbench/actions",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Action
  },
  responses: {
    201: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const getActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/actions/{actionID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const updateActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "teams/{teamname}/workbench/actions/{actionID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const deleteActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/workbench/actions/{actionID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID
  ],
  responses: {
    204: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listActionConfigurationFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/actions/{actionID}/configuration-files",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/workbench/actions/{actionID}/configuration-files",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActionConfigurationFile
    },
    default: {}
  },
  serializer
};

const getActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/actions/{actionID}/configuration-files/{configurationFileID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActionConfigurationFile
    },
    default: {}
  },
  serializer
};

const deleteActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/workbench/actions/{actionID}/configuration-files/{configurationFileID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    204: {},
    default: {}
  },
  serializer
};

const downloadActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/actions/{actionID}/configuration-files/{configurationFileID}:download",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listGlobalActionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/actions",
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createGlobalActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/actions",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Action
  },
  responses: {
    201: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const getGlobalActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/actions/{actionID}",
  urlParameters: [
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const updateGlobalActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "workbench/actions/{actionID}",
  urlParameters: [
    Parameters.actionID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const deleteGlobalActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "workbench/actions/{actionID}",
  urlParameters: [
    Parameters.actionID
  ],
  responses: {
    204: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listGlobalActionConfigurationFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/actions/{actionID}/configuration-files",
  urlParameters: [
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createGlobalActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/actions/{actionID}/configuration-files",
  urlParameters: [
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActionConfigurationFile
    },
    default: {}
  },
  serializer
};

const getGlobalActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/actions/{actionID}/configuration-files/{configurationFileID}",
  urlParameters: [
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActionConfigurationFile
    },
    default: {}
  },
  serializer
};

const deleteGlobalActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "workbench/actions/{actionID}/configuration-files/{configurationFileID}",
  urlParameters: [
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    204: {},
    default: {}
  },
  serializer
};

const downloadGlobalActionConfigurationFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/actions/{actionID}/configuration-files/{configurationFileID}:download",
  urlParameters: [
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listTeamWorkersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/workers",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/workbench/workers",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Worker
  },
  responses: {
    201: {
      bodyMapper: Mappers.Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const invokeActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/workbench/actions/{actionID}/invocations",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActionInvocationRequest
  },
  responses: {
    201: {
      bodyMapper: Mappers.ActionInvocation
    },
    default: {}
  },
  serializer
};

const getActionInvocationzOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/actions/{actionID}/invocations/{actionInvocationID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.actionID,
    Parameters.actionInvocationID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActionInvocation
    },
    default: {}
  },
  serializer
};

const getWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/workers/{workerID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.workerID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Worker
    },
    default: {}
  },
  serializer
};

const updateWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "teams/{teamname}/workbench/workers/{workerID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.workerID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1mf3sbjteamsTeamnameWorkbenchWorkersWorkeridpatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Worker
    },
    default: {}
  },
  serializer
};

const deleteWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/workbench/workers/{workerID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.workerID
  ],
  responses: {
    204: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getTasksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/workbench/workers/{workerID}/tasks",
  urlParameters: [
    Parameters.teamname,
    Parameters.workerID
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listWorkerControlsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/controls",
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createWorkerControlOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/controls",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Worker
  },
  responses: {
    201: {
      bodyMapper: Mappers.WorkerControl
    },
    default: {}
  },
  serializer
};

const deleteWorkerControlsOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "workbench/controls",
  responses: {
    204: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getWorkerControlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/controls/{controlID}",
  urlParameters: [
    Parameters.controlID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.WorkerControl
    },
    default: {}
  },
  serializer
};

const updateWorkerControlOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "workbench/controls/{controlID}",
  urlParameters: [
    Parameters.controlID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths151lzq5workbenchControlsControlidpatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.WorkerControl
    },
    default: {}
  },
  serializer
};

const deleteWorkerControlOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "workbench/controls/{controlID}",
  urlParameters: [
    Parameters.controlID
  ],
  responses: {
    204: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const acquireWorkerTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/workers/{workerID}/acquire-task",
  urlParameters: [
    Parameters.workerID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1llxakgworkbenchWorkersWorkeridAcquireTaskpostrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const batchAcquireTasksOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/workers/{workerID}/batch-acquire-tasks",
  urlParameters: [
    Parameters.workerID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1idfmsmworkbenchWorkersWorkeridBatchAcquireTaskspostrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listWorkersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/workers/{workerID}/tasks",
  urlParameters: [
    Parameters.workerID
  ],
  queryParameters: [
    Parameters.lockRequestID,
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const updateWorkerTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/workers/{workerID}/tasks/{taskID}/update-task",
  urlParameters: [
    Parameters.workerID,
    Parameters.taskID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const completeWorkerTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/workers/{workerID}/tasks/{taskID}/complete-task",
  urlParameters: [
    Parameters.workerID,
    Parameters.taskID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getWorkerTaskFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/workers/{workerID}/tasks/{taskID}/files/{fileID}:download",
  urlParameters: [
    Parameters.workerID,
    Parameters.taskID,
    Parameters.fileID
  ],
  responses: {
    200: {
      headersMapper: Mappers.GetWorkerTaskFileHeaders
    },
    default: {}
  },
  serializer
};

const postCommentToWorkerTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/workers/{workerID}/tasks/{taskID}/comments",
  urlParameters: [
    Parameters.workerID,
    Parameters.taskID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths8f3s9eworkbenchWorkersWorkeridTasksTaskidCommentspostrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getActionAsWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/workers/{workerID}/actions/{actionID}",
  urlParameters: [
    Parameters.workerID,
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Action
    },
    default: {}
  },
  serializer
};

const listActionConfigurationFilesAsWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/workers/{workerID}/actions/{actionID}/configuration-files",
  urlParameters: [
    Parameters.workerID,
    Parameters.actionID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getActionConfigurationFileAsWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/workers/{workerID}/actions/{actionID}/configuration-files/{configurationFileID}",
  urlParameters: [
    Parameters.workerID,
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActionConfigurationFile
    },
    default: {}
  },
  serializer
};

const downloadActionConfigurationFileAsWorkerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/workers/{workerID}/actions/{actionID}/configuration-files/{configurationFileID}:download",
  urlParameters: [
    Parameters.workerID,
    Parameters.actionID,
    Parameters.configurationFileID
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const adminListUserIdsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/users",
  queryParameters: [
    Parameters.email,
    Parameters.teamName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const initializeWorkbenchCapabilitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/capabilities:initialize",
  responses: {
    200: {
      bodyMapper: Mappers.WorkbenchCapability
    },
    default: {}
  },
  serializer
};

const listGlobalWorkbenchCapabilitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/capabilities",
  responses: {
    200: {
      bodyMapper: Mappers.Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createWorkbenchCapabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/capabilities",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.WorkbenchCapabilityDefinition
  },
  responses: {
    200: {
      bodyMapper: Mappers.WorkbenchCapability
    },
    default: {}
  },
  serializer
};

const getWorkbenchCapabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/capabilities/{capabilityID}",
  urlParameters: [
    Parameters.capabilityID
  ],
  responses: {
    201: {
      bodyMapper: Mappers.WorkbenchCapability
    },
    default: {}
  },
  serializer
};

const updateWorkbenchCapabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "workbench/capabilities/{capabilityID}",
  urlParameters: [
    Parameters.capabilityID
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths4rs19sworkbenchCapabilitiesCapabilityidpatchrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.WorkbenchCapability
    },
    default: {}
  },
  serializer
};

const deleteWorkbenchCapabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "workbench/capabilities/{capabilityID}",
  urlParameters: [
    Parameters.capabilityID
  ],
  responses: {
    204: {},
    default: {}
  },
  serializer
};

const listGlobalWorkbenchCapabilityGrantsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/capabilities/{capabilityID}/grants",
  urlParameters: [
    Parameters.capabilityID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listGlobalWorkbenchCapabilityGrantsToUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/capabilities/{capabilityID}/grants/{userID}",
  urlParameters: [
    Parameters.capabilityID,
    Parameters.userID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listGlobalWorkbenchCapabilityGrantsToUser1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "workbench/capabilities/{capabilityID}/grants/{userID}/{grantType}",
  urlParameters: [
    Parameters.capabilityID,
    Parameters.userID,
    Parameters.grantType0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createGlobalCapabilityGrantOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/capabilities/{capabilityID}/grants/{userID}/{grantAction}:{grantType}",
  urlParameters: [
    Parameters.capabilityID,
    Parameters.userID,
    Parameters.grantAction,
    Parameters.grantType0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.WorkbenchCapabilityGrant
    },
    default: {}
  },
  serializer
};

const revokeGlobalCapabilityGrantOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "workbench/capabilities/{capabilityID}/grants/{userID}:revoke",
  urlParameters: [
    Parameters.capabilityID,
    Parameters.userID
  ],
  queryParameters: [
    Parameters.grantType1
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const listTeamWorkbenchCapabilitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getTeamWorkbenchCapabilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}",
  urlParameters: [
    Parameters.capabilityID,
    Parameters.teamname
  ],
  responses: {
    201: {
      bodyMapper: Mappers.WorkbenchCapability
    },
    default: {}
  },
  serializer
};

const useTeamCapabilityGrantOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/capabilities/{capabilityID}/grants:use",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID
  ],
  responses: {
    200: {},
    403: {},
    default: {}
  },
  serializer
};

const listTeamWorkbenchCapabilityGrantsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}/grants",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listTeamWorkbenchCapabilityGrantsToUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}/grants/{userID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID,
    Parameters.userID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listTeamWorkbenchCapabilityGrantsToUser1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}/grants/{userID}/{grantType}",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID,
    Parameters.userID,
    Parameters.grantType0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listTeamWorkbenchCapabilityGrantLogsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}/grant-logs",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listTeamWorkbenchCapabilityGrantLogs1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}/grant-logs/{userID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID,
    Parameters.userID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listGlobalWorkbenchCapabilityGrantLogssOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/capabilities/{capabilityID}/grant-logs/{userID}/{grantType}",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID,
    Parameters.userID,
    Parameters.grantType0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createTeamCapabilityGrantOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/capabilities/{capabilityID}/grants/{userID}/{grantAction}:{grantType}",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID,
    Parameters.userID,
    Parameters.grantAction,
    Parameters.grantType0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.WorkbenchCapabilityGrant
    },
    default: {}
  },
  serializer
};

const revokeTeamCapabilityGrantOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/capabilities/{capabilityID}/grants/{userID}:revoke",
  urlParameters: [
    Parameters.teamname,
    Parameters.capabilityID,
    Parameters.userID
  ],
  queryParameters: [
    Parameters.grantType1
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getAuthinfoOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/authinfo",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const getAuthOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/auth",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.token,
    Parameters.authWords,
    Parameters.system
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    401: {},
    default: {}
  },
  serializer
};

const authenticateUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/auth",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const communityAuthMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/community-auth",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CommunityAuth
    },
    default: {}
  },
  serializer
};

const communityAuthV2MethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/community-auth/v2",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CommunityAuthV2
    },
    default: {}
  },
  serializer
};

const getServiceProviderMetadataOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/saml/serviceProviderMetadata",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SAMLServiceProviderMetadata
    },
    default: {}
  },
  serializer
};

const samlLoginOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/saml/login",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      },
      headersMapper: Mappers.SamlLoginHeaders
    },
    302: {
      headersMapper: Mappers.SamlLoginHeaders
    },
    default: {}
  },
  serializer
};

const authenticateWithSamlOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/saml/auth",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SAMLAuth
    },
    default: {}
  },
  serializer
};

const createEncryptionKeyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/data-keys",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const listtFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/files",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const createFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/files",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.fileSize,
    Parameters.done,
    Parameters.contentType,
    Parameters.filename,
    Parameters.multipart,
    Parameters.fileid0,
    Parameters.previousVersion,
    Parameters.number,
    Parameters.partSize
  ],
  headerParameters: [
    Parameters.xContentMd5
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const getFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/files/{fileid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.fileid1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const updateFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/files/{fileid}",
  urlParameters: [
    Parameters.fileid1,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const deleteFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/files/{fileid}",
  urlParameters: [
    Parameters.fileid1,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const copyFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/files/{fileid}/copy",
  urlParameters: [
    Parameters.fileid1,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const downloadFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/files/{fileid}/download",
  urlParameters: [
    Parameters.fileid1,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const executeWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/forms/{webhookname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.webhookname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const executeWebformWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/forms/{webhookname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.webhookname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const getFroupsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/groups",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const createGroupOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/groups",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const getUsersGroupsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/groups/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const updateUserGroupsOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/groups/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const listGroupMembersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/groups/{username}/members",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const listIntegrationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/integrations",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const createIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/integrations",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const integrationsHealthcheckOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/integrations/health",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const getIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/integrations/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const updateIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/integrations/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const deleteIntegrationOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/integrations/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const listInvitesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/invites",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Invite"
            }
          }
        }
      }
    },
    404: {},
    default: {}
  },
  serializer
};

const createInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/invites",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.InvitesNew,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Invite
    },
    default: {}
  },
  serializer
};

const getInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/invites/{invitetoken}",
  urlParameters: [
    Parameters.invitetoken,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Invite
    },
    default: {}
  },
  serializer
};

const createInviteTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/invites/{invitetoken}",
  urlParameters: [
    Parameters.invitetoken,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Invite
    },
    default: {}
  },
  serializer
};

const listProcessesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.showOnStartMenu,
    Parameters.owner,
    Parameters.priority,
    Parameters.category,
    Parameters.createdBy,
    Parameters.published,
    Parameters.exportParameter,
    Parameters.limit0,
    Parameters.after,
    Parameters.listRoots
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const createProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.Process,
      required: true
    }
  },
  responses: {
    201: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const getProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  queryParameters: [
    Parameters.includeChildren,
    Parameters.exportParameter
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const updateProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.ProcessPartial,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const deleteProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const updateProcessDataRetentionPolicyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}:update-data-retention-policy",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getProcessAvailableFieldsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/available-fields",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const addWritableFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processid}/writable-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const removeWritableFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/writable-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const listProcessFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/files",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const createProcessFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/files",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const updateProcessFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}/files/{fileid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.fileid1,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const listProcessRunsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/runs",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  queryParameters: [
    Parameters.status,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.format,
    Parameters.columns,
    Parameters.rows,
    Parameters.limit0,
    Parameters.after,
    Parameters.includeChildren,
    Parameters.includeWhollyOwned
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    404: {
      bodyMapper: Mappers.Paths16pex2yteamsTeamnameProcessesProcessnameRunsgetresponses404contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listProcessStepsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/steps",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const updateProcessStepsOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}/steps",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const createProcessStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/steps",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.StepPartial,
      required: true
    }
  },
  responses: {
    201: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const getStepMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processID}/step-metrics",
  urlParameters: [
    Parameters.teamname,
    Parameters.processID
  ],
  queryParameters: [
    Parameters.start,
    Parameters.end,
    Parameters.pageSize,
    Parameters.pageToken
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1u5z66gteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschema
    },
    401: {
      bodyMapper: Mappers.Pathsa9d74uteamsTeamnameProcessesProcessidStepMetricsgetresponses401contentapplicationJsonschema
    },
    403: {
      bodyMapper: Mappers.Paths1h8t2whteamsTeamnameProcessesProcessidStepMetricsgetresponses403contentapplicationJsonschema
    },
    404: {},
    500: {},
    default: {}
  },
  serializer
};

const getProcessStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const updateProcessStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.StepPartial,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const deleteProcessStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const listProcessStepFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}/files",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const addProcessStepFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}/files",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const updateProcessStepFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}/files/{fileid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.fileid1,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const getProcessStepTestOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}/test",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const createProcessStepTestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/steps/{stepname}/test",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listProcessTriggersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/triggers",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const listProcessTablesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/tables",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const linkProcessTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}/tables/{tableid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const unlinkProcessTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/tables/{tableid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const listProcessPredictorsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/predictors",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Predictor
    },
    default: {}
  },
  serializer
};

const linkProcessPredictorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}/predictors/{predictorid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.predictorid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Predictor
    },
    default: {}
  },
  serializer
};

const unlinkProcessPredictorOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/predictors/{predictorid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.predictorid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Predictor
    },
    default: {}
  },
  serializer
};

const listChildProcessesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/childprocesses",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const linkChildProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}/childprocesses/{childprocessid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.childprocessid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const unlinkChildProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/childprocesses/{childprocessid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.childprocessid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const listParentProcessesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/parentprocesses",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const linkParentProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}/parentprocesses/{parentprocessid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.parentprocessid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const unlinkParentProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/parentprocesses/{parentprocessid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.parentprocessid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const evaluateStepOutputFieldsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}/steps/{stepname}/evaluate-outputs",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.stepname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Object"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const addWritableFieldToStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processid}/steps/{stepname}/writable-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.stepname,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const removeWritableFieldFromStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/steps/{stepname}/writable-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.stepname,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getCustomActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/custom-action",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.App
    },
    default: {}
  },
  serializer
};

const createCustomActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processid}/custom-action",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Action
  },
  responses: {
    201: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const updateCustomActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "teams/{teamname}/processes/{processid}/custom-action",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Action
  },
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const deleteCustomActionOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/custom-action",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    204: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const listProcessWebhooksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/webhooks",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const getProcessVisibilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/visibility",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  queryParameters: [
    Parameters.includeIgnored
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const listUpcomingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/upcoming",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    default: {}
  },
  serializer
};

const createUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/upcoming",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    default: {}
  },
  serializer
};

const getProcessUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/upcoming/{upcomingid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.upcomingid,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    default: {}
  },
  serializer
};

const updateProcessUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}/upcoming/{upcomingid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.upcomingid,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    default: {}
  },
  serializer
};

const deleteProcessUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processname}/upcoming/{upcomingid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.upcomingid,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    404: {
      bodyMapper: Mappers.Paths1gxjkemteamsTeamnameProcessesProcessnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listProcessFieldsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/fields",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    404: {},
    default: {}
  },
  serializer
};

const createProcessFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/processes/{processname}/fields",
  urlParameters: [
    Parameters.processname,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const getProcessFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processname}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const updateProcessFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processname}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const deleteProcessFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processname}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processname,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const listProcessRightsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processid}/rights",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProcessRights
    },
    404: {},
    default: {}
  },
  serializer
};

const createProcessExportOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/process-exports",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ProcessExport
  },
  responses: {
    200: {
      bodyMapper: Mappers.ProcessExport
    },
    default: {}
  },
  serializer
};

const getProcessExportOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/process-exports/{exportid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.exportid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProcessExport
    },
    default: {}
  },
  serializer
};

const createProcessImportOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/process-imports",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ProcessImport
  },
  responses: {
    200: {
      bodyMapper: Mappers.ProcessImport
    },
    default: {}
  },
  serializer
};

const getProcessImportOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/process-imports/{importid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.importid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProcessImport
    },
    default: {}
  },
  serializer
};

const listRunsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.owner,
    Parameters.status,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.columns,
    Parameters.rows,
    Parameters.format,
    Parameters.limit0,
    Parameters.after
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    default: {}
  },
  serializer
};

const createRunOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    default: {}
  },
  serializer
};

const getRunOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.includeChildren
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    default: {}
  },
  serializer
};

const updateRunOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/runs/{runid}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    default: {}
  },
  serializer
};

const getRunVisibilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/visibility",
  urlParameters: [
    Parameters.teamname,
    Parameters.runid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const getLogOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/log",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    default: {}
  },
  serializer
};

const listRunCommentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/comments",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.sort,
    Parameters.limit0,
    Parameters.after
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Comment
    },
    default: {}
  },
  serializer
};

const createRunCommentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/comments",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Comment
  },
  responses: {
    200: {
      bodyMapper: Mappers.Comment
    },
    default: {}
  },
  serializer
};

const listRunFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/files",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const createRunFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/files",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const updateRunFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/runs/{runid}/files/{fileid}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.fileid1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const listRunFieldsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/fields",
  urlParameters: [
    Parameters.teamname,
    Parameters.runid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    404: {},
    default: {}
  },
  serializer
};

const createRunFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/fields",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const getRunFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.runid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const updateRunFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/runs/{runid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.runid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const deleteRunFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/runs/{runid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.runid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const getRunProgressOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/progress/{progressSecret}",
  urlParameters: [
    Parameters.teamname,
    Parameters.runid,
    Parameters.progressSecret
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Run
    },
    default: {}
  },
  serializer
};

const reenqueueRunTasksOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/requeue",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const listRunTasksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/tasks",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.status,
    Parameters.limit0,
    Parameters.after
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const updateRunTasksOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/runs/{runid}/tasks",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const createRunTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/tasks",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const getRunTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const updateRunTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const deleteRunTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const listRunTaskFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}/files",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname
  ],
  queryParameters: [
    Parameters.includeVersions,
    Parameters.includeDownloadUrls
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const createRunTaskFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}/files",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const updateRunTaskFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}/files/{fileid}",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname,
    Parameters.fileid1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const listRunTaskWebhooksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}/webhooks",
  urlParameters: [
    Parameters.runid,
    Parameters.teamname,
    Parameters.taskname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const completeRunTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/runs/{runid}/tasks/{taskname}/complete",
  urlParameters: [
    Parameters.runid,
    Parameters.taskname,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const searchV1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/search",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.q,
    Parameters.types,
    Parameters.limit0,
    Parameters.pageToken
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SearchV1Response
    },
    default: {}
  },
  serializer
};

const searchV2OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/search/v2",
  urlParameters: [
    Parameters.teamname
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SearchV2Request
  },
  responses: {
    200: {
      bodyMapper: Mappers.SearchV2Response
    },
    default: {}
  },
  serializer
};

const usersSearchOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users:search",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1dpewitteamsTeamnameUsersSearchgetrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "User"
            }
          }
        }
      }
    },
    401: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    403: {
      bodyMapper: Mappers.Paths10k14zuteamsTeamnameUsersSearchgetresponses403contentapplicationJsonschema
    },
    500: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const processesSearchOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes:search",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Pathsqfzj7vteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Process"
            }
          }
        }
      }
    },
    401: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    403: {
      bodyMapper: Mappers.Pathsmxqu7steamsTeamnameProcessesSearchgetresponses403contentapplicationJsonschema
    },
    500: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/steps/{stepid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const updateStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/steps/{stepid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const deleteStepOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/steps/{stepid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Step
    },
    default: {}
  },
  serializer
};

const updateWritableFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/steps/{stepid}/writable-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteWritableFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/steps/{stepid}/writable-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const evaluateStepOutputsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/steps/{stepid}/evaluate-outputs",
  urlParameters: [
    Parameters.teamname,
    Parameters.stepid
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Object"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const listTasksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tasks",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.limit0,
    Parameters.after
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const createTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tasks",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const getTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tasks/{taskid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const updateTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/tasks/{taskid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const completeTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tasks/{taskid}/complete",
  urlParameters: [
    Parameters.taskid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const openTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tasks/{taskid}/open",
  urlParameters: [
    Parameters.taskid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Task
    },
    default: {}
  },
  serializer
};

const reopenTaskOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tasks/{taskid}/reopen",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  queryParameters: [
    Parameters.optional
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const listTaskFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tasks/{taskid}/files",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const createTaskFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tasks/{taskid}/files",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const updateTaskFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/tasks/{taskid}/files/{fileid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid,
    Parameters.fileid1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const listTaskWebhooksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tasks/{taskid}/webhooks",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const getTaskFieldsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tasks/{taskid}/fields",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    404: {},
    default: {}
  },
  serializer
};

const createTaskFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tasks/{taskid}/fields",
  urlParameters: [
    Parameters.taskid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const getTaskFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tasks/{taskid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const updateTaskFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/tasks/{taskid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const deleteTaskFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/tasks/{taskid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.taskid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Field
    },
    default: {}
  },
  serializer
};

const listTablesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.limit0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const createTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const indexMetaTablesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "tables/index-meta-tables",
  queryParameters: [
    Parameters.subtype
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const importTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/import",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const getTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables/{tableid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const updateTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/tables/{tableid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.TableCopy,
      required: true
    }
  },
  responses: {
    201: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const exportTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/{tableid}/export",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  queryParameters: [
    Parameters.exportType
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const createTableColumnOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/{tableid}/columns",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getTableColumnOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables/{tableid}/columns/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateTableColumnOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/tables/{tableid}/columns/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.fieldname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.Field,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteTableColumnOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/tables/{tableid}/columns/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const appendRowToTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/{tableid}/append",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.TableFileReference,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const listTableParentProcessesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables/{tableid}/parentprocesses",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Process
    },
    default: {}
  },
  serializer
};

const replaceTableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/{tableid}/replace",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Table
    },
    default: {}
  },
  serializer
};

const listTableRowsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables/{tableid}/rows",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  queryParameters: [
    Parameters.limit0,
    Parameters.pageToken,
    Parameters.fields,
    Parameters.filter,
    Parameters.select,
    Parameters.columns
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const createTableRowOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/{tableid}/rows",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getTableRowOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables/{tableid}/rows/{rowid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.rowid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteTableRowOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/tables/{tableid}/rows/{rowid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.rowid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const createTableRowFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/tables/{tableid}/rows/{rowid}/fields",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.rowid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const getTableRowFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/tables/{tableid}/rows/{rowid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.rowid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateTableRowFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/tables/{tableid}/rows/{rowid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.rowid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteTableRowFieldOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/tables/{tableid}/rows/{rowid}/fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.tableid,
    Parameters.rowid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listUserTokensOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/user-tokens",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize,
    Parameters.orderBy,
    Parameters.allUsers
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const createUserTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/user-tokens",
  urlParameters: [
    Parameters.teamname
  ],
  headerParameters: [
    Parameters.xForwardedFor0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Paths1ixwjwdteamsTeamnameUserTokenspostrequestbodycontentapplicationJsonschema
  },
  responses: {
    201: {
      bodyMapper: Mappers.UserToken
    },
    default: {}
  },
  serializer
};

const getUserTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/user-tokens/{userTokenID}",
  urlParameters: [
    Parameters.teamname,
    Parameters.userTokenID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserToken
    },
    default: {}
  },
  serializer
};

const approveUserTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/user-tokens/{userTokenID}:approve",
  urlParameters: [
    Parameters.teamname,
    Parameters.userTokenID
  ],
  headerParameters: [
    Parameters.xForwardedFor1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserToken
    },
    default: {}
  },
  serializer
};

const pollUserTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/user-tokens/{userTokenID}:poll",
  urlParameters: [
    Parameters.teamname,
    Parameters.userTokenID
  ],
  queryParameters: [
    Parameters.waitTimeMillis
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Object"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.UserToken
    },
    default: {}
  },
  serializer
};

const revokeUserTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/user-tokens/{userTokenID}:revoke",
  urlParameters: [
    Parameters.teamname,
    Parameters.userTokenID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserToken
    },
    default: {}
  },
  serializer
};

const sendTestMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/users/{username}/_test/message/{messageType}",
  urlParameters: [
    Parameters.username,
    Parameters.messageType,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Message
    },
    default: {}
  },
  serializer
};

const listTriggersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/triggers",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.limit0,
    Parameters.after
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const triggerHealthcheckOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/triggers/health",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Team
    },
    default: {}
  },
  serializer
};

const getUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/upcoming/{upcomingid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.upcomingid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    default: {}
  },
  serializer
};

const updateUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/upcoming/{upcomingid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.upcomingid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Upcoming
    },
    default: {}
  },
  serializer
};

const deleteUpcomingOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/upcoming/{upcomingid}",
  urlParameters: [
    Parameters.teamname,
    Parameters.upcomingid
  ],
  responses: {
    204: {
      bodyMapper: Mappers.Upcoming
    },
    404: {
      bodyMapper: Mappers.Pathsr1wgloteamsTeamnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listUsersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    404: {},
    default: {}
  },
  serializer
};

const createUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/users",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const getUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const updateUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/users/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const deleteUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/users/{username}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const authenticateUserGetOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/auth",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.token
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    401: {},
    default: {}
  },
  serializer
};

const authenticateTeamUserOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/users/{username}/auth",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.token
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const unlockUserLoginOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/users/{username}/login-lock",
  urlParameters: [
    Parameters.teamname,
    Parameters.username
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const logoutOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/users/{username}/logout",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const listUserFilesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/files",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.File
    },
    default: {}
  },
  serializer
};

const listUserGroupsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/groups",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const listUserIntegrationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/integrations",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.User
    },
    default: {}
  },
  serializer
};

const listUserNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/notifications",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Notification
    },
    default: {}
  },
  serializer
};

const getUserNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/notifications/{notificationid}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname,
    Parameters.notificationid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Notification
    },
    default: {}
  },
  serializer
};

const updateUserNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/users/{username}/notifications/{notificationid}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname,
    Parameters.notificationid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Notification
    },
    default: {}
  },
  serializer
};

const markUserNotificationsReadOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/users/{username}/notifications:read",
  urlParameters: [
    Parameters.teamname,
    Parameters.username
  ],
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.Pathsbjy3erteamsTeamnameUsersUsernameNotificationsReadpostrequestbodycontentapplicationJsonschema
  },
  responses: {
    200: {
      bodyMapper: Mappers.Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const listRecentLoginsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/recent-logins",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserLogins
    },
    default: {}
  },
  serializer
};

const listFavoritesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/favorites/processes",
  urlParameters: [
    Parameters.username,
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.limit1,
    Parameters.after
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FavoritesList
    },
    default: {}
  },
  serializer
};

const getFavoriteStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/users/{username}/favorites/processes/{processid}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname,
    Parameters.processid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Favorite
    },
    default: {}
  },
  serializer
};

const updateFavoriteStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/users/{username}/favorites/processes/{processid}",
  urlParameters: [
    Parameters.username,
    Parameters.teamname,
    Parameters.processid
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.FavoritesNew,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.Favorite
    },
    default: {}
  },
  serializer
};

const listWebhooksOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/webhooks",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const createTeamWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "teams/{teamname}/webhooks",
  urlParameters: [
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Webhook
    },
    default: {}
  },
  serializer
};

const getTeamWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/webhooks/{webhookid}",
  urlParameters: [
    Parameters.webhookid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/webhooks/{webhookid}",
  urlParameters: [
    Parameters.webhookid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/webhooks/{webhookid}",
  urlParameters: [
    Parameters.webhookid,
    Parameters.teamname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "webhooks/{webhookid}",
  urlParameters: [
    Parameters.webhookid
  ],
  queryParameters: [
    Parameters.addFields
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const createWebhookOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "webhooks/{webhookid}",
  urlParameters: [
    Parameters.webhookid
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getProcessMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/processes/{processID}/metrics",
  urlParameters: [
    Parameters.teamname,
    Parameters.processID
  ],
  queryParameters: [
    Parameters.start,
    Parameters.end
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProcessMetric
    },
    401: {
      bodyMapper: Mappers.Paths1muma8lteamsTeamnameProcessesProcessidMetricsgetresponses401contentapplicationJsonschema
    },
    403: {
      bodyMapper: Mappers.Pathsk1n3clteamsTeamnameProcessesProcessidMetricsgetresponses403contentapplicationJsonschema
    },
    404: {},
    500: {},
    default: {}
  },
  serializer
};

const getTeamModulesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/modules",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.isAvailable,
    Parameters.pageToken,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getTeamModuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/modules/{modulename}",
  urlParameters: [
    Parameters.teamname,
    Parameters.modulename
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Module
    },
    default: {}
  },
  serializer
};

const getTeamModuleFeaturesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/modules/{modulename}/features",
  urlParameters: [
    Parameters.teamname,
    Parameters.modulename
  ],
  queryParameters: [
    Parameters.isAvailable,
    Parameters.pageToken,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getTeamFeaturesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/features",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.isAvailable,
    Parameters.pageToken,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getTeamFeatureOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/features/{featurename}",
  urlParameters: [
    Parameters.teamname,
    Parameters.featurename
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Feature
    },
    default: {}
  },
  serializer
};

const getTeamFeatureModulesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/features/{featurename}/modules",
  urlParameters: [
    Parameters.teamname,
    Parameters.featurename
  ],
  queryParameters: [
    Parameters.isAvailable,
    Parameters.pageToken,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getTeamFrequentlyUsedActionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/frequently-used-actions",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const getTeamRecentlyUsedActionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teams/{teamname}/recently-used-actions",
  urlParameters: [
    Parameters.teamname
  ],
  queryParameters: [
    Parameters.pageToken,
    Parameters.pageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema
    },
    default: {}
  },
  serializer
};

const putTeamsTeamnameProcessesProcessidFormFieldsFieldnameOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "teams/{teamname}/processes/{processid}/form-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    403: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteTeamsTeamnameProcessesProcessidFormFieldsFieldnameOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "teams/{teamname}/processes/{processid}/form-fields/{fieldname}",
  urlParameters: [
    Parameters.teamname,
    Parameters.processid,
    Parameters.fieldname
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    403: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};

export {
  OrchestrationAPI,
  OrchestrationAPIContext,
  Models as OrchestrationAPIModels,
  Mappers as OrchestrationAPIMappers
};
