import { ViewContext, ViewMap } from '@catalytic/react-view';
import React from 'react';
import { ViewContextExtend, ViewContextExtendProps } from '../util/ViewContextExtend';
import { CheckboxView } from '../views/CheckboxView';
import { DateTimeView, DateTimeViewEditor, DateTimeViewReader } from '../views/DateTimeView/DateTimeView';
import { DateView } from '../views/DateView';
import { EmailView } from '../views/EmailView';
import { FileReferenceView } from '../views/FileReferenceView';
import { GroupView } from '../views/GroupView';
import { IntegerView } from '../views/IntegerView';
import { MultipleSelectionView } from '../views/MultipleSelectionView';
import { NumberView } from '../views/NumberView';
import { ReferenceView } from '../views/ReferenceView';
import { SelectionView } from '../views/SelectionView';
import { TableView } from '../views/TableView';
import { TextAreaView, TextAreaViewEditor } from '../views/TextAreaView';
import { TextView } from '../views/TextView';
import { ToggleSwitchView } from '../views/ToggleSwitchView';
import { FileView, FileViewEditor, FileViewReader } from '../views/FileView/FileView';
import { CheckboxViewEditor, CheckboxViewReader } from '../views/CheckboxView/CheckboxView';
import { DateViewEditor, DateViewReader } from '../views/DateView/DateView';
import { EmailViewEditor, EmailViewReader } from '../views/EmailView/EmailView';
import { FileReferenceViewEditor, FileReferenceViewReader } from '../views/FileReferenceView/FileReferenceView';
import { IntegerViewEditor, IntegerViewReader } from '../views/IntegerView/IntegerView';
import { MultipleSelectionViewEditor, MultipleSelectionViewReader } from '../views/MultipleSelectionView/MultipleSelectionView';
import { NumberViewEditor, NumberViewReader } from '../views/NumberView/NumberView';
import { SelectionViewEditor, SelectionViewReader } from '../views/SelectionView/SelectionView';
import { TextViewEditor, TextViewReader } from '../views/TextView/TextView';
import { ToggleSwitchViewEditor, ToggleSwitchViewReader } from '../views/ToggleSwitchView/ToggleSwitchView';
import { TextAreaViewReader } from '../views/TextAreaView/TextAreaView';

export const EditorOnlyViewMap: ViewMap = {
  checkboxView: CheckboxViewEditor,
  dateView: DateViewEditor,
  dateTimeView: DateTimeViewEditor,
  emailView: EmailViewEditor,
  fileReferenceView: FileReferenceViewEditor,
  fileView: FileViewEditor,
  integerView: IntegerViewEditor,
  groupView: GroupView,
  multipleSelectionView: MultipleSelectionViewEditor,
  numberView: NumberViewEditor,
  referenceView: ReferenceView,
  selectionView: SelectionViewEditor,
  textView: TextViewEditor,
  textAreaView: TextAreaViewEditor,
  tableView: TableView,
  toggleSwitchView: ToggleSwitchViewEditor
};

export const EditorViewMap: ViewMap = {
  checkboxView: CheckboxView,
  dateView: DateView,
  dateTimeView: DateTimeView,
  emailView: EmailView,
  fileReferenceView: FileReferenceView,
  fileView: FileView,
  integerView: IntegerView,
  groupView: GroupView,
  multipleSelectionView: MultipleSelectionView,
  numberView: NumberView,
  referenceView: ReferenceView,
  selectionView: SelectionView,
  textView: TextView,
  textAreaView: TextAreaView,
  tableView: TableView,
  toggleSwitchView: ToggleSwitchView
};

export const ReaderViewMap: ViewMap = {
  ...EditorViewMap
};

export const ReaderOnlyViewMap: ViewMap = {
  checkboxView: CheckboxViewReader,
  dateView: DateViewReader,
  dateTimeView: DateTimeViewReader,
  emailView: EmailViewReader,
  fileReferenceView: FileReferenceViewReader,
  fileView: FileViewReader,
  integerView: IntegerViewReader,
  groupView: GroupView,
  multipleSelectionView: MultipleSelectionViewReader,
  numberView: NumberViewReader,
  referenceView: ReferenceView,
  selectionView: SelectionViewReader,
  textView: TextViewReader,
  textAreaView: TextAreaViewReader,
  tableView: TableView,
  toggleSwitchView: ToggleSwitchViewReader
};


export type ViewProviderProps = ViewContextExtendProps;

export const ViewProvider = (props: ViewProviderProps) =>
  <ViewContext.Provider value={{ editor: EditorViewMap, reader: ReaderViewMap }}>
    <ViewContextExtend {...props} />
  </ViewContext.Provider>
