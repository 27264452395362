// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }
declare var TERMINATOR: any;
declare var STATEMENT_SELECT: any;
declare var STAR: any;
declare var STATEMENT_WHERE: any;
declare var STATEMENT_FROM: any;
declare var STATEMENT_ORDER: any;
declare var STATEMENT_BY: any;
declare var DIRECTION: any;
declare var STATEMENT_LIMIT: any;
declare var STATEMENT_OFFSET: any;
declare var MAP: any;
declare var OPERATOR_BINARY: any;
declare var OPERATOR_COMPARISON: any;
declare var RESULT: any;
declare var ALIAS: any;
declare var ACCESSOR: any;
declare var ACCESSOR_OPEN: any;
declare var ACCESSOR_CLOSE: any;
declare var LIST_OPEN: any;
declare var LIST_CLOSE: any;
declare var LIST_DELIMETER: any;
declare var IDENTIFIER: any;
declare var LITERAL: any;
declare var WHITESPACE: any;

import { compile } from '../lexer/lexer';
import {
  fromAlias,
  fromBinaryOperation,
  fromBracketAccessorExpression,
  fromComparisonOperation,
  fromDotAccessorExpression,
  fromExpression,
  fromFunction,
  fromIdentifier,
  fromLimit,
  fromLiteral,
  fromMap,
  fromMapJoin,
  fromOrder,
  fromRange,
  fromResult,
  fromSelect
} from './grammar.transform';
const lexer = compile();

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: lexer,
  ParserRules: [
    {"name": "query", "symbols": ["operation"], "postprocess": id},
    {"name": "query", "symbols": ["unary_expression"], "postprocess": id},
    {"name": "query", "symbols": ["statement"], "postprocess": id},
    {"name": "query", "symbols": ["sub_query"], "postprocess": id},
    {"name": "query", "symbols": ["_", "query", "_"], "postprocess": ([,id]) => id},
    {"name": "query", "symbols": ["_", "query"], "postprocess": ([,id]) => id},
    {"name": "query", "symbols": ["query", "_"], "postprocess": id},
    {"name": "statement", "symbols": ["select_statement"], "postprocess": id},
    {"name": "statement", "symbols": ["select_statement", (lexer.has("TERMINATOR") ? {type: "TERMINATOR"} : TERMINATOR)], "postprocess": id},
    {"name": "statement", "symbols": ["select_statement", "_", (lexer.has("TERMINATOR") ? {type: "TERMINATOR"} : TERMINATOR)], "postprocess": id},
    {"name": "select_statement", "symbols": [(lexer.has("STATEMENT_SELECT") ? {type: "STATEMENT_SELECT"} : STATEMENT_SELECT), "_", "select_result"], "postprocess": ([,,result]) => fromSelect([undefined, result, undefined, undefined, undefined, undefined, undefined])},
    {"name": "select_statement", "symbols": ["select_statement", "_", "from_statement"], "postprocess": ([select,,fromNode]) => fromSelect([select, undefined ,fromNode, undefined, undefined, undefined, undefined])},
    {"name": "select_statement", "symbols": ["select_statement", "_", "where_statement"], "postprocess": ([select,,operation]) => fromSelect([select, undefined ,undefined, operation, undefined, undefined, undefined])},
    {"name": "select_statement", "symbols": ["select_statement", "_", "order_statement"], "postprocess": ([select,,order]) => fromSelect([select, undefined ,undefined, undefined, order, undefined, undefined])},
    {"name": "select_statement", "symbols": ["select_statement", "_", "offset_statement"], "postprocess": ([select,,offset]) => fromSelect([select, undefined ,undefined, undefined, undefined, offset ,undefined])},
    {"name": "select_statement", "symbols": ["select_statement", "_", "limit_statement"], "postprocess": ([select,,limit]) => fromSelect([select, undefined ,undefined, undefined, undefined, undefined ,limit])},
    {"name": "select_statement", "symbols": ["from_statement"], "postprocess": ([fromNode]) => fromSelect([undefined, undefined ,fromNode, undefined, undefined, undefined, undefined])},
    {"name": "select_statement", "symbols": ["where_statement"], "postprocess": ([operation]) => fromSelect([undefined, undefined ,undefined, operation, undefined, undefined, undefined])},
    {"name": "select_statement", "symbols": ["order_statement"], "postprocess": ([order]) => fromSelect([undefined, undefined ,undefined, undefined, order, undefined, undefined])},
    {"name": "select_statement", "symbols": ["offset_statement"], "postprocess": ([offset]) => fromSelect([undefined, undefined ,undefined, undefined, undefined, offset, undefined])},
    {"name": "select_statement", "symbols": ["limit_statement"], "postprocess": ([limit]) => fromSelect([undefined, undefined ,undefined, undefined, undefined , undefined, limit])},
    {"name": "select_result", "symbols": [(lexer.has("STAR") ? {type: "STAR"} : STAR)], "postprocess": () => []},
    {"name": "select_result", "symbols": ["result"], "postprocess": ([id]) => [id]},
    {"name": "select_result", "symbols": ["select_result", "list_delimeter", "result"], "postprocess": ([results,,id]) => [...results, id]},
    {"name": "where_statement", "symbols": [(lexer.has("STATEMENT_WHERE") ? {type: "STATEMENT_WHERE"} : STATEMENT_WHERE), "_", "where_clause"], "postprocess": ([,,id]) => id},
    {"name": "where_clause", "symbols": ["operation"], "postprocess": id},
    {"name": "where_clause", "symbols": ["function"], "postprocess": id},
    {"name": "from_statement", "symbols": [(lexer.has("STATEMENT_FROM") ? {type: "STATEMENT_FROM"} : STATEMENT_FROM), "_", "map_expression"], "postprocess": ([id, , source]) => fromMap([id, source])},
    {"name": "from_statement", "symbols": ["from_statement", "_", "map"], "postprocess": ([left, ,right]) => fromMapJoin([left, right])},
    {"name": "order_statement", "symbols": [(lexer.has("STATEMENT_ORDER") ? {type: "STATEMENT_ORDER"} : STATEMENT_ORDER), "_", (lexer.has("STATEMENT_BY") ? {type: "STATEMENT_BY"} : STATEMENT_BY), "_", "unary_expression", "_", (lexer.has("DIRECTION") ? {type: "DIRECTION"} : DIRECTION)], "postprocess": ([,,,,expression,,direction]) => fromOrder([expression, direction, undefined])},
    {"name": "order_statement", "symbols": [(lexer.has("STATEMENT_ORDER") ? {type: "STATEMENT_ORDER"} : STATEMENT_ORDER), "_", (lexer.has("STATEMENT_BY") ? {type: "STATEMENT_BY"} : STATEMENT_BY), "_", "unary_expression"], "postprocess": ([,,,,expression]) => fromOrder([expression, undefined, undefined])},
    {"name": "order_statement", "symbols": ["order_statement", "list_delimeter", "unary_expression", "_", (lexer.has("DIRECTION") ? {type: "DIRECTION"} : DIRECTION)], "postprocess": ([orders,,expression,,direction]) => fromOrder([expression, direction, orders])},
    {"name": "order_statement", "symbols": ["order_statement", "list_delimeter", "unary_expression"], "postprocess": ([orders,,expression]) => fromOrder([expression, undefined, orders])},
    {"name": "limit_statement", "symbols": [(lexer.has("STATEMENT_LIMIT") ? {type: "STATEMENT_LIMIT"} : STATEMENT_LIMIT), "_", "unary_expression"], "postprocess": ([,,length]) => fromLimit([length, undefined, undefined])},
    {"name": "offset_statement", "symbols": [(lexer.has("STATEMENT_OFFSET") ? {type: "STATEMENT_OFFSET"} : STATEMENT_OFFSET), "_", "unary_expression"], "postprocess": ([,,offset]) => fromLimit([undefined, offset, undefined])},
    {"name": "map", "symbols": [(lexer.has("MAP") ? {type: "MAP"} : MAP), "_", "map_expression"], "postprocess": ([id, , source]) => fromMap([id, source])},
    {"name": "map", "symbols": ["map", "_", "map"], "postprocess": ([left, ,right]) => fromMapJoin([left, right])},
    {"name": "map_expression", "symbols": ["unary_expression"], "postprocess": id},
    {"name": "map_expression", "symbols": ["operation"], "postprocess": id},
    {"name": "operation", "symbols": ["comparison_operation"], "postprocess": id},
    {"name": "operation", "symbols": ["binary_operation"], "postprocess": id},
    {"name": "binary_operation", "symbols": ["left_operation_expression", "_", (lexer.has("OPERATOR_BINARY") ? {type: "OPERATOR_BINARY"} : OPERATOR_BINARY), "_", "right_operation_expression"], "postprocess": ([r,,o,,l]) => fromBinaryOperation([r,o,l])},
    {"name": "binary_operation", "symbols": ["binary_operation", "_", (lexer.has("OPERATOR_BINARY") ? {type: "OPERATOR_BINARY"} : OPERATOR_BINARY), "_", "right_operation_expression"], "postprocess": ([r,,o,,l]) => fromBinaryOperation([r,o,l])},
    {"name": "right_operation_expression", "symbols": ["comparison_operation"], "postprocess": id},
    {"name": "right_operation_expression", "symbols": ["expression"], "postprocess": id},
    {"name": "right_operation_expression", "symbols": ["function"], "postprocess": id},
    {"name": "right_operation_expression", "symbols": ["identifier_kind"], "postprocess": id},
    {"name": "right_operation_expression", "symbols": ["range"], "postprocess": id},
    {"name": "left_operation_expression", "symbols": ["comparison_operation"], "postprocess": id},
    {"name": "left_operation_expression", "symbols": ["identifier_kind"], "postprocess": id},
    {"name": "comparison_operation", "symbols": ["unary_expression", "_", (lexer.has("OPERATOR_COMPARISON") ? {type: "OPERATOR_COMPARISON"} : OPERATOR_COMPARISON), "_", "unary_expression"], "postprocess": ([r,,o,,l]) => fromComparisonOperation([r,o,l])},
    {"name": "comparison_operation", "symbols": ["unary_expression", "_", (lexer.has("OPERATOR_COMPARISON") ? {type: "OPERATOR_COMPARISON"} : OPERATOR_COMPARISON), "unary_expression"], "postprocess": ([r,,o,l]) => fromComparisonOperation([r,o,l])},
    {"name": "comparison_operation", "symbols": ["unary_expression", (lexer.has("OPERATOR_COMPARISON") ? {type: "OPERATOR_COMPARISON"} : OPERATOR_COMPARISON), "unary_expression"], "postprocess": ([r,o,l]) => fromComparisonOperation([r,o,l])},
    {"name": "range", "symbols": ["range_expression", "_", (lexer.has("OPERATOR_BINARY") ? {type: "OPERATOR_BINARY"} : OPERATOR_BINARY), "_", "range_expression"], "postprocess": ([s,,,,e]) => fromRange([s,e])},
    {"name": "range_expression", "symbols": ["identifier_kind"], "postprocess": id},
    {"name": "range_expression", "symbols": ["literal"], "postprocess": id},
    {"name": "function", "symbols": ["identifier", "expression"], "postprocess": ([name, args]) => fromFunction([name, args])},
    {"name": "function", "symbols": ["identifier", "_", "expression"], "postprocess": ([name, ,args]) => fromFunction([name, args])},
    {"name": "expression", "symbols": ["list_open", "list_close"], "postprocess": ([]) => fromExpression([])},
    {"name": "expression", "symbols": ["list_open", "argument_list", "list_close"], "postprocess": ([,id]) => fromExpression([id])},
    {"name": "argument_list", "symbols": ["argument"], "postprocess": ([id]) => [id]},
    {"name": "argument_list", "symbols": ["argument", "list_delimeter", "argument_list"], "postprocess": ([id,,ids]) => [id, ...ids]},
    {"name": "argument", "symbols": ["identifier_kind"], "postprocess": id},
    {"name": "argument", "symbols": ["literal"], "postprocess": id},
    {"name": "argument", "symbols": ["select_statement"], "postprocess": id},
    {"name": "argument", "symbols": ["sub_query"], "postprocess": id},
    {"name": "argument", "symbols": ["function"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["identifier_kind"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["literal"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["function"], "postprocess": id},
    {"name": "unary_expression", "symbols": ["alias_expression"], "postprocess": id},
    {"name": "result", "symbols": ["result_expression"], "postprocess": ([source]) => fromResult([,,source])},
    {"name": "result", "symbols": [(lexer.has("RESULT") ? {type: "RESULT"} : RESULT), "_", "result_expression"], "postprocess": ([result, ,source]) => fromResult([result,,source])},
    {"name": "result", "symbols": [(lexer.has("RESULT") ? {type: "RESULT"} : RESULT), "_", (lexer.has("RESULT") ? {type: "RESULT"} : RESULT), "_", "result_expression"], "postprocess": ([r1, ,r2, ,source]) => fromResult([r1, r2,source])},
    {"name": "result_expression", "symbols": ["identifier_kind"], "postprocess": id},
    {"name": "result_expression", "symbols": ["alias_expression"], "postprocess": id},
    {"name": "alias_expression", "symbols": ["identifier_kind", "_", (lexer.has("ALIAS") ? {type: "ALIAS"} : ALIAS), "_", "identifier"], "postprocess": ([node,,,,alias]) => fromAlias([node, alias])},
    {"name": "alias_expression", "symbols": ["sub_query", "_", (lexer.has("ALIAS") ? {type: "ALIAS"} : ALIAS), "_", "identifier"], "postprocess": ([node,,,,alias]) => fromAlias([node, alias])},
    {"name": "alias_expression", "symbols": ["function", "_", (lexer.has("ALIAS") ? {type: "ALIAS"} : ALIAS), "_", "identifier"], "postprocess": ([node,,,,alias]) => fromAlias([node, alias])},
    {"name": "identifier_kind", "symbols": ["identifier"], "postprocess": id},
    {"name": "identifier_kind", "symbols": ["accessor"], "postprocess": id},
    {"name": "sub_query", "symbols": ["list_open", "select_statement", "list_close"], "postprocess": ([,id]) => id},
    {"name": "accessor", "symbols": ["identifier", (lexer.has("ACCESSOR") ? {type: "ACCESSOR"} : ACCESSOR), "identifier"], "postprocess": fromDotAccessorExpression},
    {"name": "accessor", "symbols": ["accessor", (lexer.has("ACCESSOR") ? {type: "ACCESSOR"} : ACCESSOR), "identifier"], "postprocess": fromDotAccessorExpression},
    {"name": "accessor", "symbols": ["identifier", (lexer.has("ACCESSOR_OPEN") ? {type: "ACCESSOR_OPEN"} : ACCESSOR_OPEN), "literal", (lexer.has("ACCESSOR_CLOSE") ? {type: "ACCESSOR_CLOSE"} : ACCESSOR_CLOSE)], "postprocess": fromBracketAccessorExpression},
    {"name": "accessor", "symbols": ["accessor", (lexer.has("ACCESSOR_OPEN") ? {type: "ACCESSOR_OPEN"} : ACCESSOR_OPEN), "literal", (lexer.has("ACCESSOR_CLOSE") ? {type: "ACCESSOR_CLOSE"} : ACCESSOR_CLOSE)], "postprocess": fromBracketAccessorExpression},
    {"name": "list_open", "symbols": [(lexer.has("LIST_OPEN") ? {type: "LIST_OPEN"} : LIST_OPEN)], "postprocess": id},
    {"name": "list_open", "symbols": [(lexer.has("LIST_OPEN") ? {type: "LIST_OPEN"} : LIST_OPEN), "_"], "postprocess": ([,id]) => id},
    {"name": "list_close", "symbols": [(lexer.has("LIST_CLOSE") ? {type: "LIST_CLOSE"} : LIST_CLOSE)], "postprocess": id},
    {"name": "list_close", "symbols": ["_", (lexer.has("LIST_CLOSE") ? {type: "LIST_CLOSE"} : LIST_CLOSE)], "postprocess": ([,id]) => id},
    {"name": "list_delimeter", "symbols": [(lexer.has("LIST_DELIMETER") ? {type: "LIST_DELIMETER"} : LIST_DELIMETER)], "postprocess": id},
    {"name": "list_delimeter", "symbols": ["_", (lexer.has("LIST_DELIMETER") ? {type: "LIST_DELIMETER"} : LIST_DELIMETER), "_"], "postprocess": ([,id]) => id},
    {"name": "list_delimeter", "symbols": ["_", (lexer.has("LIST_DELIMETER") ? {type: "LIST_DELIMETER"} : LIST_DELIMETER)], "postprocess": ([,id]) => id},
    {"name": "list_delimeter", "symbols": [(lexer.has("LIST_DELIMETER") ? {type: "LIST_DELIMETER"} : LIST_DELIMETER), "_"], "postprocess": id},
    {"name": "identifier", "symbols": [(lexer.has("IDENTIFIER") ? {type: "IDENTIFIER"} : IDENTIFIER)], "postprocess": fromIdentifier},
    {"name": "literal", "symbols": [(lexer.has("LITERAL") ? {type: "LITERAL"} : LITERAL)], "postprocess": fromLiteral},
    {"name": "_", "symbols": [(lexer.has("WHITESPACE") ? {type: "WHITESPACE"} : WHITESPACE)], "postprocess": () => undefined}
  ],
  ParserStart: "query",
};

export default grammar;
