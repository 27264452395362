import { Clause, literal, OperatorType } from '@catalytic/query';
import { AnyOperatorType, ArrayOperatorType, BooleanOperatorType, NullOperatorType, NumberOperatorType, StringOperatorType } from '../../query/query.interfaces';
import { Option } from './Option.interfaces';


export interface AnyOption<V extends string = AnyOperatorType, C = Clause> extends Option<string, V> {
  clause?: C
}

export interface ArrayOption extends AnyOption<ArrayOperatorType> { }
export interface BooleanOption extends AnyOption<BooleanOperatorType> { }
export interface NullOption extends AnyOption<NullOperatorType> { }
export interface NumberOption extends AnyOption<NumberOperatorType> { }
export interface StringOption extends AnyOption<StringOperatorType> { }

export const NULL_OPTIONS: NullOption[] = [
  { value: OperatorType.IS, label: 'Is empty', clause: literal({ value: null }) },
  { value: OperatorType.NOT_IS, label: 'Is not empty', clause: literal({ value: null }) }
]

export const ARRAY_OPTIONS: ArrayOption[] = [
  ...NULL_OPTIONS,
  { value: OperatorType.IN, label: 'Includes' },
  { value: OperatorType.NOT_IN, label: 'Does not include' }
]

export const BOOLEAN_OPTIONS: BooleanOption[] = [
  ...NULL_OPTIONS,
  { value: OperatorType.EQ, label: 'Is' },
  { value: OperatorType.NOT_EQ, label: 'Is not' }
]

export const NUMBER_OPTIONS: NumberOption[] = [
  ...NULL_OPTIONS,
  { value: OperatorType.EQ, label: '=' },
  { value: OperatorType.NOT_EQ, label: '≠' },
  { value: OperatorType.GT, label: '>' },
  { value: OperatorType.LT, label: '<' },
  { value: OperatorType.GTE, label: '≥' },
  { value: OperatorType.LTE, label: '≤' },
  //{ value: OperatorType.IN, label: 'Includes' },
  //{ value: OperatorType.NOT_IN, label: 'Does not include' },
]

export const STRING_OPTIONS: StringOption[] = [
  ...NULL_OPTIONS,
  { value: OperatorType.EQ, label: 'Is' },
  { value: OperatorType.NOT_EQ, label: 'Is not' },
  //{ value: OperatorType.LIKE, label: 'Contains' },
  //{ value: OperatorType.NOT_LIKE, label: 'Does not contain' },
  //{ value: OperatorType.IN, label: 'Includes' },
  //{ value: OperatorType.NOT_IN, label: 'Does not include' }
]
