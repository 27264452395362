import { Box } from 'grommet';
import React, { memo } from 'react';
import { Form as FormComponent, FormProps as FormComponentProps } from '../../components/Form/Form';
import { Loading } from '../../components/Loading';
import { PageHeader } from '../../components/PageHeader';
import { useFormBinding } from '../../form-binding/form-binding.hooks';
import { UseFormState } from '../../form/form.hooks';
import { FormData } from '../../form/form.interfaces';


export type ConnectedFormProps = Omit<UseFormState, 'onChange'> & {
  title?: string;
  formProps?: Omit<FormComponentProps, 'defaultValue' | 'disabled' | 'schema' | 'node' | 'value'>;
};

export type ConnectedFormState = {
  title?: string;
  data?: FormData;
}

export const ConnectedForm = memo(({ title: titleProp, submit, data: dataProp, disabled, configuration, formProps }: ConnectedFormProps) => {
  const { data, loading } = useFormBinding({ data: dataProp, configuration })
  const title = titleProp ?? (data?.node?.title as string);
  const { node, schema, value: defaultValue } = data || {};
  if (loading || !node || !schema) return <Loading />;

  return <Box fill>
    <PageHeader title={title} />
    <Box direction="column" fill background="background-contrast" gap="large" pad="medium" overflow="auto">
      <Box width="large">
        <FormComponent {...{ node, schema, defaultValue, disabled, submit, loading, ...formProps }} />
      </Box>
    </Box>
  </Box>
})
