export interface NextID {
  (): string;
}

export type NextIDOptions = {
  prefix?: string;
  offset?: number;
};

const createNextID = (options?: NextIDOptions): NextID => {
  const { prefix = "id", offset = 0 } = options || {};
  let id = offset;
  return (): string => `${prefix}-${id++}`;
};

export default createNextID;
