/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const AppParameter: msRest.CompositeMapper = {
  serializedName: "AppParameter",
  type: {
    name: "Composite",
    className: "AppParameter",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      example: {
        serializedName: "example",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const App: msRest.CompositeMapper = {
  serializedName: "App",
  type: {
    name: "Composite",
    className: "App",
    modelProperties: {
      appName: {
        serializedName: "appName",
        type: {
          name: "String"
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "String"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      appID: {
        required: true,
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      featureName: {
        serializedName: "featureName",
        type: {
          name: "String"
        }
      },
      moduleNames: {
        serializedName: "moduleNames",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      baseAppID: {
        serializedName: "baseAppID",
        type: {
          name: "String"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        required: true,
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      published: {
        required: true,
        serializedName: "published",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      shared: {
        serializedName: "shared",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      trigger: {
        serializedName: "trigger",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      params: {
        serializedName: "params",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "AppParameter"
            }
          }
        }
      },
      paramOrder: {
        serializedName: "paramOrder",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      oauthUrl: {
        serializedName: "oauthUrl",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      configuredWithUserDefinedFields: {
        serializedName: "configuredWithUserDefinedFields",
        type: {
          name: "Boolean"
        }
      },
      authAppIDs: {
        serializedName: "authAppIDs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      outputFields: {
        serializedName: "outputFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      authRequired: {
        serializedName: "authRequired",
        type: {
          name: "Boolean"
        }
      },
      featureFlag: {
        serializedName: "featureFlag",
        type: {
          name: "String"
        }
      },
      featureFlagActive: {
        serializedName: "featureFlagActive",
        type: {
          name: "Boolean"
        }
      },
      customIntegration: {
        serializedName: "customIntegration",
        type: {
          name: "Boolean"
        }
      },
      oauthConfig: {
        serializedName: "oauthConfig",
        type: {
          name: "Object"
        }
      },
      integrationDefinition: {
        serializedName: "integrationDefinition",
        type: {
          name: "Boolean"
        }
      },
      requiresDbEntry: {
        serializedName: "requiresDbEntry",
        type: {
          name: "Boolean"
        }
      },
      helpDocumentPath: {
        serializedName: "helpDocumentPath",
        type: {
          name: "Boolean"
        }
      },
      credentialConfig: {
        serializedName: "credentialConfig",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const FeatureState: msRest.CompositeMapper = {
  serializedName: "FeatureState",
  type: {
    name: "Composite",
    className: "FeatureState",
    modelProperties: {
      triggers: {
        serializedName: "triggers",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FieldPartial: msRest.CompositeMapper = {
  serializedName: "FieldPartial",
  type: {
    name: "Composite",
    className: "FieldPartial",
    modelProperties: {
      fieldID: {
        serializedName: "fieldID",
        type: {
          name: "Uuid"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      descriptionSourceText: {
        serializedName: "descriptionSourceText",
        type: {
          name: "Object"
        }
      },
      example: {
        serializedName: "example",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        defaultValue: 'string',
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      intent: {
        serializedName: "intent",
        type: {
          name: "String"
        }
      },
      restrictions: {
        serializedName: "restrictions",
        type: {
          name: "Object"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        type: {
          name: "String"
        }
      },
      visibility: {
        serializedName: "visibility",
        defaultValue: 'public',
        type: {
          name: "String"
        }
      },
      visibleToUsers: {
        serializedName: "visibleToUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "String"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "String"
        }
      },
      tableID: {
        serializedName: "tableID",
        type: {
          name: "String"
        }
      },
      rowID: {
        serializedName: "rowID",
        type: {
          name: "String"
        }
      },
      predictedBy: {
        serializedName: "predictedBy",
        type: {
          name: "String"
        }
      },
      isCreatedByApp: {
        serializedName: "isCreatedByApp",
        type: {
          name: "Boolean"
        }
      },
      displayOnly: {
        serializedName: "displayOnly",
        type: {
          name: "Boolean"
        }
      },
      isMetadata: {
        serializedName: "isMetadata",
        type: {
          name: "Boolean"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Composite",
          className: "FieldPartialItems"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      view: {
        serializedName: "view",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const Field: msRest.CompositeMapper = {
  serializedName: "Field",
  type: {
    name: "Composite",
    className: "Field",
    modelProperties: {
      ...FieldPartial.type.modelProperties
    }
  }
};

export const Module: msRest.CompositeMapper = {
  serializedName: "Module",
  type: {
    name: "Composite",
    className: "Module",
    modelProperties: {
      moduleID: {
        required: true,
        serializedName: "moduleID",
        type: {
          name: "Uuid"
        }
      },
      moduleName: {
        required: true,
        serializedName: "moduleName",
        type: {
          name: "String"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      createTime: {
        required: true,
        serializedName: "createTime",
        type: {
          name: "String"
        }
      },
      updateTime: {
        required: true,
        serializedName: "updateTime",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      updatedBy: {
        required: true,
        serializedName: "updatedBy",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1aaqrpg·module_features-modules·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1aaqrpgmoduleFeaturesModulesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      modules: {
        required: true,
        serializedName: "modules",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Module"
            }
          }
        }
      }
    }
  }
};

export const PostContentSchemaModule: msRest.CompositeMapper = {
  serializedName: "post-content-schema-module",
  type: {
    name: "Composite",
    className: "PostContentSchemaModule",
    modelProperties: {
      moduleName: {
        serializedName: "moduleName",
        type: {
          name: "String"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsdk40osmoduleFeaturesModulespostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·dk40os·module_features-modules·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsdk40osmoduleFeaturesModulespostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      module: {
        serializedName: "module",
        type: {
          name: "Composite",
          className: "PostContentSchemaModule"
        }
      }
    }
  }
};

export const PatchContentSchemaModule: msRest.CompositeMapper = {
  serializedName: "patch-content-schema-module",
  type: {
    name: "Composite",
    className: "PatchContentSchemaModule",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1t80n3vmoduleFeaturesModulesModulenamepatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1t80n3v·module_features-modules-modulename·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1t80n3vmoduleFeaturesModulesModulenamepatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      module: {
        required: true,
        serializedName: "module",
        type: {
          name: "Composite",
          className: "PatchContentSchemaModule"
        }
      }
    }
  }
};

export const Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·14bu896·module_features-teams-teamname-modules-modulename·patch·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths14bu896moduleFeaturesTeamsTeamnameModulesModulenamepatchresponses200contentapplicationJsonschema",
    modelProperties: {
      isAvailable: {
        required: true,
        serializedName: "isAvailable",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths1l7qp5smoduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschemapropertiesmodule: msRest.CompositeMapper = {
  serializedName: "paths·1l7qp5s·module_features-teams-teamname-modules-modulename·patch·requestbody·content·application-json·schema·properties·module",
  type: {
    name: "Composite",
    className: "Paths1l7qp5smoduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschemapropertiesmodule",
    modelProperties: {
      isAvailable: {
        serializedName: "isAvailable",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths1bbj998moduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1bbj998·module_features-teams-teamname-modules-modulename·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1bbj998moduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      module: {
        required: true,
        serializedName: "module",
        type: {
          name: "Composite",
          className: "Paths1l7qp5smoduleFeaturesTeamsTeamnameModulesModulenamepatchrequestbodycontentapplicationJsonschemapropertiesmodule"
        }
      }
    }
  }
};

export const Feature: msRest.CompositeMapper = {
  serializedName: "Feature",
  type: {
    name: "Composite",
    className: "Feature",
    modelProperties: {
      featureID: {
        required: true,
        serializedName: "featureID",
        type: {
          name: "Uuid"
        }
      },
      featureName: {
        required: true,
        serializedName: "featureName",
        type: {
          name: "String"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      createTime: {
        required: true,
        serializedName: "createTime",
        type: {
          name: "String"
        }
      },
      updateTime: {
        required: true,
        serializedName: "updateTime",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      updatedBy: {
        required: true,
        serializedName: "updatedBy",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1vn6vqt·module_features-modules-modulename-features·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1vn6vqtmoduleFeaturesModulesModulenameFeaturesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      results: {
        required: true,
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Feature"
            }
          }
        }
      }
    }
  }
};

export const PostContentSchemaFeature: msRest.CompositeMapper = {
  serializedName: "post-content-schema-feature",
  type: {
    name: "Composite",
    className: "PostContentSchemaFeature",
    modelProperties: {
      featureName: {
        required: true,
        serializedName: "featureName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths16s58acmoduleFeaturesModulesModulenameFeaturespostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·16s58ac·module_features-modules-modulename-features·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths16s58acmoduleFeaturesModulesModulenameFeaturespostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      feature: {
        required: true,
        serializedName: "feature",
        type: {
          name: "Composite",
          className: "PostContentSchemaFeature"
        }
      }
    }
  }
};

export const Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1gc9uzm·module_features-features·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1gc9uzmmoduleFeaturesFeaturesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      features: {
        required: true,
        serializedName: "features",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Feature"
            }
          }
        }
      }
    }
  }
};

export const Paths1r5u2qmmoduleFeaturesFeaturespostrequestbodycontentapplicationJsonschemapropertiesfeature: msRest.CompositeMapper = {
  serializedName: "paths·1r5u2qm·module_features-features·post·requestbody·content·application-json·schema·properties·feature",
  type: {
    name: "Composite",
    className: "Paths1r5u2qmmoduleFeaturesFeaturespostrequestbodycontentapplicationJsonschemapropertiesfeature",
    modelProperties: {
      featureName: {
        required: true,
        serializedName: "featureName",
        type: {
          name: "String"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths10iunt5moduleFeaturesFeaturespostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·10iunt5·module_features-features·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths10iunt5moduleFeaturesFeaturespostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      feature: {
        required: true,
        serializedName: "feature",
        type: {
          name: "Composite",
          className: "Paths1r5u2qmmoduleFeaturesFeaturespostrequestbodycontentapplicationJsonschemapropertiesfeature"
        }
      }
    }
  }
};

export const PatchContentSchemaFeature: msRest.CompositeMapper = {
  serializedName: "patch-content-schema-feature",
  type: {
    name: "Composite",
    className: "PatchContentSchemaFeature",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1j5fzv6moduleFeaturesFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1j5fzv6·module_features-features-featurename·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1j5fzv6moduleFeaturesFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      feature: {
        required: true,
        serializedName: "feature",
        type: {
          name: "Composite",
          className: "PatchContentSchemaFeature"
        }
      }
    }
  }
};

export const PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·ismtgy·module_features-teams-teamname-features-featurename·patch·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "PathsismtgymoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchresponses200contentapplicationJsonschema",
    modelProperties: {
      isAvailable: {
        required: true,
        serializedName: "isAvailable",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths1vk5ombmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschemapropertiesfeature: msRest.CompositeMapper = {
  serializedName: "paths·1vk5omb·module_features-teams-teamname-features-featurename·patch·requestbody·content·application-json·schema·properties·feature",
  type: {
    name: "Composite",
    className: "Paths1vk5ombmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschemapropertiesfeature",
    modelProperties: {
      isAvailable: {
        serializedName: "isAvailable",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths23ngpnmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·23ngpn·module_features-teams-teamname-features-featurename·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths23ngpnmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      feature: {
        required: true,
        serializedName: "feature",
        type: {
          name: "Composite",
          className: "Paths1vk5ombmoduleFeaturesTeamsTeamnameFeaturesFeaturenamepatchrequestbodycontentapplicationJsonschemapropertiesfeature"
        }
      }
    }
  }
};

export const Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·g0bpio·module_features-features-featurename-modules·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsg0bpiomoduleFeaturesFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      results: {
        required: true,
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Module"
            }
          }
        }
      }
    }
  }
};

export const ActionInputsItem: msRest.CompositeMapper = {
  serializedName: "Action-inputsItem",
  type: {
    name: "Composite",
    className: "ActionInputsItem",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      defaultValue: {
        serializedName: "defaultValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActionOutputsItem: msRest.CompositeMapper = {
  serializedName: "Action-outputsItem",
  type: {
    name: "Composite",
    className: "ActionOutputsItem",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Action: msRest.CompositeMapper = {
  serializedName: "Action",
  type: {
    name: "Composite",
    className: "Action",
    modelProperties: {
      actionID: {
        serializedName: "actionID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      requiredWorkerTags: {
        serializedName: "requiredWorkerTags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      inputs: {
        serializedName: "inputs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActionInputsItem"
            }
          }
        }
      },
      outputs: {
        serializedName: "outputs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActionOutputsItem"
            }
          }
        }
      },
      published: {
        serializedName: "published",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByID: {
        serializedName: "createdByID",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1bc6uwh·teams-teamname-workbench-actions·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1bc6uwhteamsTeamnameWorkbenchActionsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Action"
            }
          }
        }
      }
    }
  }
};

export const ActionConfigurationFile: msRest.CompositeMapper = {
  serializedName: "ActionConfigurationFile",
  type: {
    name: "Composite",
    className: "ActionConfigurationFile",
    modelProperties: {
      actionConfigurationFileID: {
        serializedName: "actionConfigurationFileID",
        type: {
          name: "Uuid"
        }
      },
      actionID: {
        serializedName: "actionID",
        type: {
          name: "Uuid"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "String"
        }
      },
      size: {
        serializedName: "size",
        type: {
          name: "String"
        }
      },
      eTag: {
        serializedName: "ETag",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1485med·teams-teamname-workbench-actions-actionid-configuration_files·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1485medteamsTeamnameWorkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      actionConfigurationFiles: {
        serializedName: "actionConfigurationFiles",
        type: {
          name: "Composite",
          className: "ActionConfigurationFile"
        }
      },
      nextPageKey: {
        serializedName: "nextPageKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·x0xj60·workbench-actions·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsx0xj60workbenchActionsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Action"
            }
          }
        }
      }
    }
  }
};

export const Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·101nlxf·workbench-actions-actionid-configuration_files·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths101nlxfworkbenchActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      actionConfigurationFiles: {
        serializedName: "actionConfigurationFiles",
        type: {
          name: "Composite",
          className: "ActionConfigurationFile"
        }
      },
      nextPageKey: {
        serializedName: "nextPageKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Worker: msRest.CompositeMapper = {
  serializedName: "Worker",
  type: {
    name: "Composite",
    className: "Worker",
    modelProperties: {
      workerID: {
        serializedName: "workerID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      hostname: {
        serializedName: "hostname",
        type: {
          name: "String"
        }
      },
      ipAddress: {
        serializedName: "ipAddress",
        type: {
          name: "String"
        }
      },
      implementedActionIDs: {
        serializedName: "implementedActionIDs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      lastSeenTime: {
        serializedName: "lastSeenTime",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByID: {
        serializedName: "createdByID",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·oj5gql·teams-teamname-workbench-workers·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsoj5gqlteamsTeamnameWorkbenchWorkersgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      workers: {
        serializedName: "workers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Worker"
            }
          }
        }
      }
    }
  }
};

export const Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1t0tq6d·teams-teamname-workbench-workers·post·responses·201·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1t0tq6dteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschema",
    modelProperties: {
      workerID: {
        serializedName: "workerID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      hostname: {
        serializedName: "hostname",
        type: {
          name: "String"
        }
      },
      ipAddress: {
        serializedName: "ipAddress",
        type: {
          name: "String"
        }
      },
      implementedActionIDs: {
        serializedName: "implementedActionIDs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      lastSeenTime: {
        serializedName: "lastSeenTime",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByID: {
        serializedName: "createdByID",
        type: {
          name: "Uuid"
        }
      },
      secretKey: {
        serializedName: "SecretKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsoaf39rteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschemaallof1: msRest.CompositeMapper = {
  serializedName: "paths·oaf39r·teams-teamname-workbench-workers·post·responses·201·content·application-json·schema·allof·1",
  type: {
    name: "Composite",
    className: "Pathsoaf39rteamsTeamnameWorkbenchWorkerspostresponses201contentapplicationJsonschemaallof1",
    modelProperties: {
      secretKey: {
        serializedName: "SecretKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1mf3sbjteamsTeamnameWorkbenchWorkersWorkeridpatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1mf3sbj·teams-teamname-workbench-workers-workerid·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1mf3sbjteamsTeamnameWorkbenchWorkersWorkeridpatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      action: {
        serializedName: "action",
        type: {
          name: "Composite",
          className: "Worker"
        }
      },
      mask: {
        serializedName: "mask",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PendingWorkerTaskInputsItem: msRest.CompositeMapper = {
  serializedName: "PendingWorkerTask-inputsItem",
  type: {
    name: "Composite",
    className: "PendingWorkerTaskInputsItem",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      defaultValue: {
        serializedName: "defaultValue",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PendingWorkerTask: msRest.CompositeMapper = {
  serializedName: "PendingWorkerTask",
  type: {
    name: "Composite",
    className: "PendingWorkerTask",
    modelProperties: {
      taskID: {
        serializedName: "taskID",
        type: {
          name: "Uuid"
        }
      },
      requiredWorkerTags: {
        serializedName: "requiredWorkerTags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      actionID: {
        serializedName: "actionID",
        type: {
          name: "Uuid"
        }
      },
      lockRequestID: {
        serializedName: "lockRequestID",
        type: {
          name: "Uuid"
        }
      },
      workerID: {
        serializedName: "workerID",
        type: {
          name: "Uuid"
        }
      },
      progressStatus: {
        serializedName: "progressStatus",
        type: {
          name: "String"
        }
      },
      progressPercent: {
        serializedName: "progressPercent",
        type: {
          name: "Number"
        }
      },
      taskReadyTime: {
        serializedName: "taskReadyTime",
        type: {
          name: "DateTime"
        }
      },
      lockAcquisitionTime: {
        serializedName: "lockAcquisitionTime",
        type: {
          name: "DateTime"
        }
      },
      lockLastRenewedTime: {
        serializedName: "lockLastRenewedTime",
        type: {
          name: "DateTime"
        }
      },
      lockExpirationTime: {
        serializedName: "lockExpirationTime",
        type: {
          name: "DateTime"
        }
      },
      inputs: {
        serializedName: "inputs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PendingWorkerTaskInputsItem"
            }
          }
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·13en27q·teams-teamname-workbench-workers-workerid-tasks·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths13en27qteamsTeamnameWorkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PendingWorkerTask"
            }
          }
        }
      }
    }
  }
};

export const WorkerControl: msRest.CompositeMapper = {
  serializedName: "WorkerControl",
  type: {
    name: "Composite",
    className: "WorkerControl",
    modelProperties: {
      controlID: {
        serializedName: "controlID",
        type: {
          name: "Uuid"
        }
      },
      enabled: {
        serializedName: "enabled",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      stopAllExecution: {
        serializedName: "stopAllExecution",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      taskID: {
        serializedName: "taskID",
        type: {
          name: "Uuid"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Uuid"
        }
      },
      rootRunID: {
        serializedName: "rootRunID",
        type: {
          name: "Uuid"
        }
      },
      workerID: {
        serializedName: "workerID",
        type: {
          name: "Uuid"
        }
      },
      actionID: {
        serializedName: "actionID",
        type: {
          name: "Uuid"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByID: {
        serializedName: "createdByID",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·14w7g86·workbench-controls·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths14w7g86workbenchControlsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      workers: {
        serializedName: "workers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkerControl"
            }
          }
        }
      }
    }
  }
};

export const Paths151lzq5workbenchControlsControlidpatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·151lzq5·workbench-controls-controlid·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths151lzq5workbenchControlsControlidpatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      action: {
        serializedName: "action",
        type: {
          name: "Composite",
          className: "WorkerControl"
        }
      },
      mask: {
        serializedName: "mask",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1llxakgworkbenchWorkersWorkeridAcquireTaskpostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1llxakg·workbench-workers-workerid-acquire_task·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1llxakgworkbenchWorkersWorkeridAcquireTaskpostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      lockDurationSeconds: {
        serializedName: "lockDurationSeconds",
        defaultValue: 30,
        constraints: {
          InclusiveMaximum: 300,
          InclusiveMinimum: 5
        },
        type: {
          name: "Number"
        }
      },
      waitTimeMillis: {
        serializedName: "waitTimeMillis",
        defaultValue: 0,
        constraints: {
          InclusiveMaximum: 60000,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1d8y6gj·workbench-workers-workerid-acquire_task·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1d8y6gjworkbenchWorkersWorkeridAcquireTaskpostresponses200contentapplicationJsonschema",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      lockRequestID: {
        serializedName: "lockRequestID",
        type: {
          name: "Uuid"
        }
      },
      waitingTaskCount: {
        serializedName: "waitingTaskCount",
        type: {
          name: "Number"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "PendingWorkerTask"
        }
      }
    }
  }
};

export const Paths1idfmsmworkbenchWorkersWorkeridBatchAcquireTaskspostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1idfmsm·workbench-workers-workerid-batch_acquire_tasks·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1idfmsmworkbenchWorkersWorkeridBatchAcquireTaskspostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      lockDurationSeconds: {
        serializedName: "lockDurationSeconds",
        defaultValue: 30,
        constraints: {
          InclusiveMaximum: 300,
          InclusiveMinimum: 5
        },
        type: {
          name: "Number"
        }
      },
      waitTimeMillis: {
        serializedName: "waitTimeMillis",
        defaultValue: 0,
        constraints: {
          InclusiveMaximum: 60000,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      maxTaskCount: {
        required: true,
        serializedName: "maxTaskCount",
        constraints: {
          InclusiveMaximum: 100,
          InclusiveMinimum: 1
        },
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1e1axld·workbench-workers-workerid-batch_acquire_tasks·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1e1axldworkbenchWorkersWorkeridBatchAcquireTaskspostresponses200contentapplicationJsonschema",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      lockRequestID: {
        serializedName: "lockRequestID",
        type: {
          name: "Uuid"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      waitingTaskCount: {
        serializedName: "waitingTaskCount",
        type: {
          name: "Number"
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PendingWorkerTask"
            }
          }
        }
      }
    }
  }
};

export const Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1rl016v·workbench-workers-workerid-tasks·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1rl016vworkbenchWorkersWorkeridTasksgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PendingWorkerTask"
            }
          }
        }
      }
    }
  }
};

export const Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·5g6tq4·workbench-workers-workerid-tasks-taskid-update_task·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths5g6tq4workbenchWorkersWorkeridTasksTaskidUpdateTaskpostresponses200contentapplicationJsonschema",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      lockRequestID: {
        serializedName: "lockRequestID",
        type: {
          name: "Uuid"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "PendingWorkerTask"
        }
      }
    }
  }
};

export const Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·187borw·workbench-workers-workerid-tasks-taskid-complete_task·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths187borwworkbenchWorkersWorkeridTasksTaskidCompleteTaskpostresponses200contentapplicationJsonschema",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      lockRequestID: {
        serializedName: "lockRequestID",
        type: {
          name: "Uuid"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "PendingWorkerTask"
        }
      }
    }
  }
};

export const Paths8f3s9eworkbenchWorkersWorkeridTasksTaskidCommentspostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·8f3s9e·workbench-workers-workerid-tasks-taskid-comments·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths8f3s9eworkbenchWorkersWorkeridTasksTaskidCommentspostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      comment: {
        required: true,
        serializedName: "comment",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1j23h14·workbench-workers-workerid-tasks-taskid-comments·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1j23h14workbenchWorkersWorkeridTasksTaskidCommentspostresponses200contentapplicationJsonschema",
    modelProperties: {
      comment: {
        serializedName: "comment",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1s31oh·workbench-workers-workerid-actions-actionid-configuration_files·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1s31ohworkbenchWorkersWorkeridActionsActionidConfigurationFilesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      actionConfigurationFiles: {
        serializedName: "actionConfigurationFiles",
        type: {
          name: "Composite",
          className: "ActionConfigurationFile"
        }
      },
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Get200ApplicationJsonPropertiesItemsItem: msRest.CompositeMapper = {
  serializedName: "get-200-application-json-properties-itemsItem",
  type: {
    name: "Composite",
    className: "Get200ApplicationJsonPropertiesItemsItem",
    modelProperties: {
      userID: {
        serializedName: "userID",
        type: {
          name: "Uuid"
        }
      },
      teamName: {
        serializedName: "teamName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1a84rzv·workbench-users·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1a84rzvworkbenchUsersgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      capabilities: {
        serializedName: "capabilities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Get200ApplicationJsonPropertiesItemsItem"
            }
          }
        }
      }
    }
  }
};

export const WorkbenchCapability: msRest.CompositeMapper = {
  serializedName: "WorkbenchCapability",
  type: {
    name: "Composite",
    className: "WorkbenchCapability",
    modelProperties: {
      capabilityID: {
        serializedName: "capabilityID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      grantedByDefault: {
        serializedName: "grantedByDefault",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      isPublic: {
        serializedName: "isPublic",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      updatedBy: {
        serializedName: "updatedBy",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·dt5xvr·workbench-capabilities·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsdt5xvrworkbenchCapabilitiesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      capabilities: {
        serializedName: "capabilities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapability"
            }
          }
        }
      }
    }
  }
};

export const PatchContentSchemaCapability: msRest.CompositeMapper = {
  serializedName: "patch-content-schema-capability",
  type: {
    name: "Composite",
    className: "PatchContentSchemaCapability",
    modelProperties: {
      decription: {
        serializedName: "decription",
        type: {
          name: "String"
        }
      },
      isPublic: {
        serializedName: "isPublic",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths4rs19sworkbenchCapabilitiesCapabilityidpatchrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·4rs19s·workbench-capabilities-capabilityid·patch·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths4rs19sworkbenchCapabilitiesCapabilityidpatchrequestbodycontentapplicationJsonschema",
    modelProperties: {
      capability: {
        serializedName: "capability",
        type: {
          name: "Composite",
          className: "PatchContentSchemaCapability"
        }
      },
      mask: {
        serializedName: "mask",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkbenchCapabilityGrant: msRest.CompositeMapper = {
  serializedName: "WorkbenchCapabilityGrant",
  type: {
    name: "Composite",
    className: "WorkbenchCapabilityGrant",
    modelProperties: {
      capabilityID: {
        serializedName: "capabilityID",
        type: {
          name: "Uuid"
        }
      },
      userID: {
        serializedName: "userID",
        type: {
          name: "Uuid"
        }
      },
      granted: {
        serializedName: "granted",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      grantType: {
        serializedName: "grantType",
        type: {
          name: "String"
        }
      },
      expirationTime: {
        serializedName: "expirationTime",
        type: {
          name: "DateTime"
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1c14pyd·workbench-capabilities-capabilityid-grants·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1c14pydworkbenchCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrant"
            }
          }
        }
      }
    }
  }
};

export const Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1fowo3r·workbench-capabilities-capabilityid-grants-userid·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1fowo3rworkbenchCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrant"
            }
          }
        }
      }
    }
  }
};

export const Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·kp9zqf·workbench-capabilities-capabilityid-grants-userid-granttype·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathskp9zqfworkbenchCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrant"
            }
          }
        }
      }
    }
  }
};

export const Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1gs5ipm·teams-teamname-capabilities·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1gs5ipmteamsTeamnameCapabilitiesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      capabilities: {
        serializedName: "capabilities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapability"
            }
          }
        }
      }
    }
  }
};

export const Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·c0zxoi·teams-teamname-capabilities-capabilityid-grants·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsc0zxoiteamsTeamnameCapabilitiesCapabilityidGrantsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrant"
            }
          }
        }
      }
    }
  }
};

export const Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·ldhi41·teams-teamname-capabilities-capabilityid-grants-userid·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsldhi41teamsTeamnameCapabilitiesCapabilityidGrantsUseridgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrant"
            }
          }
        }
      }
    }
  }
};

export const Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·12qt4is·teams-teamname-capabilities-capabilityid-grants-userid-granttype·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths12qt4isteamsTeamnameCapabilitiesCapabilityidGrantsUseridGranttypegetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrant"
            }
          }
        }
      }
    }
  }
};

export const WorkbenchCapabilityGrantLog: msRest.CompositeMapper = {
  serializedName: "WorkbenchCapabilityGrantLog",
  type: {
    name: "Composite",
    className: "WorkbenchCapabilityGrantLog",
    modelProperties: {
      logTimestamp: {
        serializedName: "logTimestamp",
        type: {
          name: "DateTime"
        }
      },
      logEvent: {
        serializedName: "logEvent",
        type: {
          name: "String"
        }
      },
      logUserID: {
        serializedName: "logUserID",
        type: {
          name: "Uuid"
        }
      },
      capabilityName: {
        serializedName: "capabilityName",
        type: {
          name: "String"
        }
      },
      capabilityID: {
        serializedName: "capabilityID",
        type: {
          name: "Uuid"
        }
      },
      userID: {
        serializedName: "userID",
        type: {
          name: "Uuid"
        }
      },
      granted: {
        serializedName: "granted",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      grantType: {
        serializedName: "grantType",
        type: {
          name: "String"
        }
      },
      expirationTime: {
        serializedName: "expirationTime",
        type: {
          name: "DateTime"
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·nmqfqo·teams-teamname-capabilities-capabilityid-grant_logs·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "PathsnmqfqoteamsTeamnameCapabilitiesCapabilityidGrantLogsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrantLog"
            }
          }
        }
      }
    }
  }
};

export const Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1apdmm4·teams-teamname-capabilities-capabilityid-grant_logs-userid·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1apdmm4teamsTeamnameCapabilitiesCapabilityidGrantLogsUseridgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrantLog"
            }
          }
        }
      }
    }
  }
};

export const PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·ieaekn·teams-teamname-capabilities-capabilityid-grant_logs-userid-granttype·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "PathsieaeknteamsTeamnameCapabilitiesCapabilityidGrantLogsUseridGranttypegetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      grants: {
        serializedName: "grants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkbenchCapabilityGrantLog"
            }
          }
        }
      }
    }
  }
};

export const Invite: msRest.CompositeMapper = {
  serializedName: "Invite",
  type: {
    name: "Composite",
    className: "Invite",
    modelProperties: {
      teamName: {
        serializedName: "teamName",
        type: {
          name: "String"
        }
      },
      invitedBy: {
        serializedName: "invitedBy",
        type: {
          name: "String"
        }
      },
      inviteToken: {
        serializedName: "inviteToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1gmj5mh·teams-teamname-processes-processid-update_data_retention_policy·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1gmj5mhteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      durationCompletedBeforeDelete: {
        nullable: true,
        serializedName: "durationCompletedBeforeDelete",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1ch2vsf·teams-teamname-processes-processid-update_data_retention_policy·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1ch2vsfteamsTeamnameProcessesProcessidUpdateDataRetentionPolicypostresponses200contentapplicationJsonschema",
    modelProperties: {
      durationCompletedBeforeDelete: {
        serializedName: "durationCompletedBeforeDelete",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetResponses200ContentApplicationJsonSchemaGlobalFieldsItemField: msRest.CompositeMapper = {
  serializedName: "get-responses-200-content-application-json-schema-globalFields-item-field",
  type: {
    name: "Composite",
    className: "GetResponses200ContentApplicationJsonSchemaGlobalFieldsItemField",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Get200ApplicationJsonPropertiesItemsContextsItem: msRest.CompositeMapper = {
  serializedName: "get-200-application-json-properties-items-contextsItem",
  type: {
    name: "Composite",
    className: "Get200ApplicationJsonPropertiesItemsContextsItem",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths115ws3lteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesglobalfieldsitems: msRest.CompositeMapper = {
  serializedName: "paths·115ws3l·teams-teamname-processes-processid-available_fields·get·responses·200·content·application-json·schema·properties·globalfields·items",
  type: {
    name: "Composite",
    className: "Paths115ws3lteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesglobalfieldsitems",
    modelProperties: {
      field: {
        serializedName: "field",
        type: {
          name: "Composite",
          className: "GetResponses200ContentApplicationJsonSchemaGlobalFieldsItemField"
        }
      },
      contexts: {
        serializedName: "contexts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Get200ApplicationJsonPropertiesItemsContextsItem"
            }
          }
        }
      }
    }
  }
};

export const GetResponses200ContentApplicationJsonSchemaFieldsItemField: msRest.CompositeMapper = {
  serializedName: "get-responses-200-content-application-json-schema-fields-item-field",
  type: {
    name: "Composite",
    className: "GetResponses200ContentApplicationJsonSchemaFieldsItemField",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      fieldID: {
        serializedName: "fieldID",
        type: {
          name: "String"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "String"
        }
      },
      teamName: {
        serializedName: "teamName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsrqi8kvteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitemspropertiescontextsitems: msRest.CompositeMapper = {
  serializedName: "paths·rqi8kv·teams-teamname-processes-processid-available_fields·get·responses·200·content·application-json·schema·properties·fields·items·properties·contexts·items",
  type: {
    name: "Composite",
    className: "Pathsrqi8kvteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitemspropertiescontextsitems",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1y5a2ixteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitems: msRest.CompositeMapper = {
  serializedName: "paths·1y5a2ix·teams-teamname-processes-processid-available_fields·get·responses·200·content·application-json·schema·properties·fields·items",
  type: {
    name: "Composite",
    className: "Paths1y5a2ixteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitems",
    modelProperties: {
      field: {
        serializedName: "field",
        type: {
          name: "Composite",
          className: "GetResponses200ContentApplicationJsonSchemaFieldsItemField"
        }
      },
      contexts: {
        serializedName: "contexts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Pathsrqi8kvteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitemspropertiescontextsitems"
            }
          }
        }
      }
    }
  }
};

export const PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·rgrsgl·teams-teamname-processes-processid-available_fields·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "PathsrgrsglteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      globalFields: {
        serializedName: "globalFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Paths115ws3lteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesglobalfieldsitems"
            }
          }
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Paths1y5a2ixteamsTeamnameProcessesProcessidAvailableFieldsgetresponses200contentapplicationJsonschemapropertiesfieldsitems"
            }
          }
        }
      },
      totalFieldCount: {
        serializedName: "totalFieldCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Paths16pex2yteamsTeamnameProcessesProcessnameRunsgetresponses404contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·16pex2y·teams-teamname-processes-processname-runs·get·responses·404·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths16pex2yteamsTeamnameProcessesProcessnameRunsgetresponses404contentapplicationJsonschema",
    modelProperties: {
      message: {
        required: true,
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsttt0dlteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschemapropertiesstepsitems: msRest.CompositeMapper = {
  serializedName: "paths·ttt0dl·teams-teamname-processes-processid-step_metrics·get·responses·200·content·application-json·schema·properties·steps·items",
  type: {
    name: "Composite",
    className: "Pathsttt0dlteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschemapropertiesstepsitems",
    modelProperties: {
      stepID: {
        serializedName: "stepID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      percentPresent: {
        serializedName: "percentPresent",
        type: {
          name: "Number"
        }
      },
      averageDuration: {
        serializedName: "averageDuration",
        type: {
          name: "TimeSpan"
        }
      },
      medianDuration: {
        serializedName: "medianDuration",
        type: {
          name: "TimeSpan"
        }
      },
      minDuration: {
        serializedName: "minDuration",
        type: {
          name: "TimeSpan"
        }
      },
      maxDuration: {
        serializedName: "maxDuration",
        type: {
          name: "TimeSpan"
        }
      }
    }
  }
};

export const Paths1u5z66gteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1u5z66g·teams-teamname-processes-processid-step_metrics·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1u5z66gteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      steps: {
        required: true,
        serializedName: "steps",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Pathsttt0dlteamsTeamnameProcessesProcessidStepMetricsgetresponses200contentapplicationJsonschemapropertiesstepsitems"
            }
          }
        }
      },
      totalCount: {
        required: true,
        serializedName: "totalCount",
        type: {
          name: "String"
        }
      },
      nextPageToken: {
        required: true,
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsa9d74uteamsTeamnameProcessesProcessidStepMetricsgetresponses401contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·a9d74u·teams-teamname-processes-processid-step_metrics·get·responses·401·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsa9d74uteamsTeamnameProcessesProcessidStepMetricsgetresponses401contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1h8t2whteamsTeamnameProcessesProcessidStepMetricsgetresponses403contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1h8t2wh·teams-teamname-processes-processid-step_metrics·get·responses·403·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1h8t2whteamsTeamnameProcessesProcessidStepMetricsgetresponses403contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1gxjkemteamsTeamnameProcessesProcessnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1gxjkem·teams-teamname-processes-processname-upcoming-upcomingid·delete·responses·404·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1gxjkemteamsTeamnameProcessesProcessnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetContentSchemaFullName: msRest.CompositeMapper = {
  serializedName: "get-content-schema-fullName",
  type: {
    name: "Composite",
    className: "GetContentSchemaFullName",
    modelProperties: {
      contains: {
        serializedName: "contains",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetContentSchemaEmail: msRest.CompositeMapper = {
  serializedName: "get-content-schema-email",
  type: {
    name: "Composite",
    className: "GetContentSchemaEmail",
    modelProperties: {
      contains: {
        serializedName: "contains",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetContentSchemaCreatedDate: msRest.CompositeMapper = {
  serializedName: "get-content-schema-createdDate",
  type: {
    name: "Composite",
    className: "GetContentSchemaCreatedDate",
    modelProperties: {
      between: {
        serializedName: "between",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const Paths1dpewitteamsTeamnameUsersSearchgetrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1dpewit·teams-teamname-users-search·get·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1dpewitteamsTeamnameUsersSearchgetrequestbodycontentapplicationJsonschema",
    modelProperties: {
      userID: {
        serializedName: "userID",
        type: {
          name: "Uuid"
        }
      },
      fullName: {
        serializedName: "fullName",
        type: {
          name: "Composite",
          className: "GetContentSchemaFullName"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "Composite",
          className: "GetContentSchemaEmail"
        }
      },
      or: {
        serializedName: "or",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      },
      and: {
        serializedName: "and",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      },
      isTeamOwner: {
        serializedName: "isTeamOwner",
        type: {
          name: "Boolean"
        }
      },
      isTeamAdmin: {
        serializedName: "isTeamAdmin",
        type: {
          name: "Boolean"
        }
      },
      isDeactivated: {
        serializedName: "isDeactivated",
        type: {
          name: "Boolean"
        }
      },
      isLockedOut: {
        serializedName: "isLockedOut",
        type: {
          name: "Boolean"
        }
      },
      buildingAllowed: {
        serializedName: "buildingAllowed",
        type: {
          name: "Boolean"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "GetContentSchemaCreatedDate"
        }
      }
    }
  }
};

export const UserNotificationPreferenceEmail: msRest.CompositeMapper = {
  serializedName: "User-notificationPreference-email",
  type: {
    name: "Composite",
    className: "UserNotificationPreferenceEmail",
    modelProperties: {
      enabled: {
        required: true,
        serializedName: "enabled",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UserNotificationPreferenceSms: msRest.CompositeMapper = {
  serializedName: "User-notificationPreference-sms",
  type: {
    name: "Composite",
    className: "UserNotificationPreferenceSms",
    modelProperties: {
      enabled: {
        required: true,
        serializedName: "enabled",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UserNotificationPreference: msRest.CompositeMapper = {
  serializedName: "User-notificationPreference",
  type: {
    name: "Composite",
    className: "UserNotificationPreference",
    modelProperties: {
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "Composite",
          className: "UserNotificationPreferenceEmail"
        }
      },
      sms: {
        serializedName: "sms",
        type: {
          name: "Composite",
          className: "UserNotificationPreferenceSms"
        }
      },
      updates: {
        serializedName: "updates",
        defaultValue: 'email',
        type: {
          name: "String"
        }
      },
      assignment: {
        serializedName: "assignment",
        defaultValue: 'email',
        type: {
          name: "String"
        }
      },
      reminders: {
        serializedName: "reminders",
        defaultValue: 'email',
        type: {
          name: "String"
        }
      },
      dailySummary: {
        serializedName: "dailySummary",
        type: {
          name: "String"
        }
      },
      weeklyTeamOwner: {
        serializedName: "weeklyTeamOwner",
        type: {
          name: "String"
        }
      },
      runCompletion: {
        serializedName: "runCompletion",
        defaultValue: 'email',
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UserLastLogin: msRest.CompositeMapper = {
  serializedName: "User-lastLogin",
  type: {
    name: "Composite",
    className: "UserLastLogin",
    modelProperties: {
      ipAddress: {
        serializedName: "ipAddress",
        type: {
          name: "String"
        }
      },
      currentIP: {
        serializedName: "currentIP",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      loginDate: {
        serializedName: "loginDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const User: msRest.CompositeMapper = {
  serializedName: "User",
  type: {
    name: "Composite",
    className: "User",
    modelProperties: {
      username: {
        required: true,
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      slackUsername: {
        serializedName: "slackUsername",
        type: {
          name: "String"
        }
      },
      userID: {
        required: true,
        serializedName: "userID",
        type: {
          name: "Uuid"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      appState: {
        serializedName: "appState",
        type: {
          name: "String"
        }
      },
      appID: {
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "String"
        }
      },
      appVersion: {
        serializedName: "appVersion",
        type: {
          name: "Number"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      fullName: {
        serializedName: "fullName",
        type: {
          name: "String"
        }
      },
      experience: {
        serializedName: "experience",
        type: {
          name: "String"
        }
      },
      userThumbnail: {
        serializedName: "userThumbnail",
        type: {
          name: "String"
        }
      },
      isDeactivated: {
        serializedName: "isDeactivated",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isTeamOwner: {
        serializedName: "isTeamOwner",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isTeamAdmin: {
        serializedName: "isTeamAdmin",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isSudoer: {
        serializedName: "isSudoer",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isRestricted: {
        serializedName: "isRestricted",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isIntegration: {
        serializedName: "isIntegration",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isShared: {
        serializedName: "isShared",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isGroup: {
        serializedName: "isGroup",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isRuntime: {
        serializedName: "isRuntime",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isLockedOut: {
        serializedName: "isLockedOut",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      userVisibility: {
        serializedName: "userVisibility",
        type: {
          name: "String"
        }
      },
      integrationVisibleToUsers: {
        serializedName: "integrationVisibleToUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      notificationPreference: {
        serializedName: "notificationPreference",
        type: {
          name: "Composite",
          className: "UserNotificationPreference"
        }
      },
      lastLocation: {
        serializedName: "lastLocation",
        type: {
          name: "String"
        }
      },
      lastLogin: {
        serializedName: "lastLogin",
        type: {
          name: "Composite",
          className: "UserLastLogin"
        }
      },
      nickname: {
        serializedName: "nickname",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      buildingAllowed: {
        serializedName: "buildingAllowed",
        type: {
          name: "Boolean"
        }
      },
      canBuild: {
        serializedName: "canBuild",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths10k14zuteamsTeamnameUsersSearchgetresponses403contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·10k14zu·teams-teamname-users-search·get·responses·403·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths10k14zuteamsTeamnameUsersSearchgetresponses403contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetContentSchemaDisplayName: msRest.CompositeMapper = {
  serializedName: "get-content-schema-displayName",
  type: {
    name: "Composite",
    className: "GetContentSchemaDisplayName",
    modelProperties: {
      contains: {
        serializedName: "contains",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetContentSchemaOwner: msRest.CompositeMapper = {
  serializedName: "get-content-schema-owner",
  type: {
    name: "Composite",
    className: "GetContentSchemaOwner",
    modelProperties: {
      contains: {
        serializedName: "contains",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetContentSchemaDescription: msRest.CompositeMapper = {
  serializedName: "get-content-schema-description",
  type: {
    name: "Composite",
    className: "GetContentSchemaDescription",
    modelProperties: {
      contains: {
        serializedName: "contains",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1p531mrteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschemapropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "paths·1p531mr·teams-teamname-processes-search·get·requestbody·content·application-json·schema·properties·createddate",
  type: {
    name: "Composite",
    className: "Paths1p531mrteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschemapropertiescreateddate",
    modelProperties: {
      between: {
        serializedName: "between",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const GetContentSchemaCategory: msRest.CompositeMapper = {
  serializedName: "get-content-schema-category",
  type: {
    name: "Composite",
    className: "GetContentSchemaCategory",
    modelProperties: {
      contains: {
        serializedName: "contains",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsqfzj7vteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·qfzj7v·teams-teamname-processes-search·get·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsqfzj7vteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschema",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "Composite",
          className: "GetContentSchemaDisplayName"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "Composite",
          className: "GetContentSchemaOwner"
        }
      },
      or: {
        serializedName: "or",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      },
      and: {
        serializedName: "and",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "Composite",
          className: "GetContentSchemaDescription"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Paths1p531mrteamsTeamnameProcessesSearchgetrequestbodycontentapplicationJsonschemapropertiescreateddate"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "GetContentSchemaCategory"
        }
      }
    }
  }
};

export const ProcessPartial: msRest.CompositeMapper = {
  serializedName: "ProcessPartial",
  type: {
    name: "Composite",
    className: "ProcessPartial",
    modelProperties: {
      processName: {
        serializedName: "processName",
        type: {
          name: "String"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "String"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "String"
        }
      },
      stepCount: {
        serializedName: "stepCount",
        type: {
          name: "Number"
        }
      },
      maximumDuration: {
        serializedName: "maximumDuration",
        type: {
          name: "String"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "String"
        }
      },
      thumbnail: {
        serializedName: "thumbnail",
        type: {
          name: "String"
        }
      },
      published: {
        serializedName: "published",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FieldPartial"
            }
          }
        }
      },
      formFields: {
        serializedName: "formFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      writableFields: {
        serializedName: "writableFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      showOnStartMenu: {
        serializedName: "showOnStartMenu",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      fieldVisibility: {
        serializedName: "fieldVisibility",
        defaultValue: 'public',
        type: {
          name: "String"
        }
      },
      fieldVisibleToUsers: {
        serializedName: "fieldVisibleToUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      lastRunDate: {
        serializedName: "lastRunDate",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "String"
        }
      },
      adminUsers: {
        serializedName: "adminUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      manuallyStartable: {
        serializedName: "manuallyStartable",
        type: {
          name: "Boolean"
        }
      },
      standardUsers: {
        serializedName: "standardUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      isPublic: {
        serializedName: "isPublic",
        type: {
          name: "Boolean"
        }
      },
      skipReporting: {
        serializedName: "skipReporting",
        type: {
          name: "String"
        }
      },
      skipTable: {
        serializedName: "skipTable",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isAdHoc: {
        serializedName: "isAdHoc",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      testMode: {
        serializedName: "testMode",
        type: {
          name: "String"
        }
      },
      runVisibility: {
        serializedName: "runVisibility",
        type: {
          name: "String"
        }
      },
      rootProcessID: {
        serializedName: "rootProcessID",
        type: {
          name: "Uuid"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        type: {
          name: "String"
        }
      },
      publishedDate: {
        serializedName: "publishedDate",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      baseProcessID: {
        serializedName: "baseProcessID",
        type: {
          name: "Uuid"
        }
      },
      whollyOwnedBy: {
        serializedName: "whollyOwnedBy",
        type: {
          name: "Uuid"
        }
      },
      rootWhollyOwnedBy: {
        serializedName: "rootWhollyOwnedBy",
        type: {
          name: "Uuid"
        }
      },
      durationCompletedBeforeDelete: {
        serializedName: "durationCompletedBeforeDelete",
        type: {
          name: "String"
        }
      },
      defaultDependencyType: {
        serializedName: "defaultDependencyType",
        defaultValue: 'startsAfterPreviousStep',
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Process: msRest.CompositeMapper = {
  serializedName: "Process",
  type: {
    name: "Composite",
    className: "Process",
    modelProperties: {
      ...ProcessPartial.type.modelProperties
    }
  }
};

export const Pathsmxqu7steamsTeamnameProcessesSearchgetresponses403contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·mxqu7s·teams-teamname-processes-search·get·responses·403·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsmxqu7steamsTeamnameProcessesSearchgetresponses403contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UserToken: msRest.CompositeMapper = {
  serializedName: "UserToken",
  type: {
    name: "Composite",
    className: "UserToken",
    modelProperties: {
      userTokenID: {
        serializedName: "userTokenID",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      userID: {
        serializedName: "userID",
        type: {
          name: "Uuid"
        }
      },
      status: {
        required: true,
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      creatorIPAddress: {
        serializedName: "creatorIPAddress",
        type: {
          name: "String"
        }
      },
      approverIPAddress: {
        serializedName: "approverIPAddress",
        type: {
          name: "String"
        }
      },
      lastSeenIPAddress: {
        serializedName: "lastSeenIPAddress",
        type: {
          name: "String"
        }
      },
      lastSeenTime: {
        serializedName: "lastSeenTime",
        type: {
          name: "DateTime"
        }
      },
      approvedTime: {
        serializedName: "approvedTime",
        type: {
          name: "DateTime"
        }
      },
      revokedTime: {
        serializedName: "revokedTime",
        type: {
          name: "DateTime"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      firstSixTokenChars: {
        serializedName: "firstSixTokenChars",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1qblhnn·teams-teamname-user_tokens·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1qblhnnteamsTeamnameUserTokensgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      userTokens: {
        serializedName: "userTokens",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserToken"
            }
          }
        }
      }
    }
  }
};

export const Paths1ixwjwdteamsTeamnameUserTokenspostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1ixwjwd·teams-teamname-user_tokens·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1ixwjwdteamsTeamnameUserTokenspostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsr1wgloteamsTeamnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·r1wglo·teams-teamname-upcoming-upcomingid·delete·responses·404·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsr1wgloteamsTeamnameUpcomingUpcomingiddeleteresponses404contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsbjy3erteamsTeamnameUsersUsernameNotificationsReadpostrequestbodycontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·bjy3er·teams-teamname-users-username-notifications-read·post·requestbody·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsbjy3erteamsTeamnameUsersUsernameNotificationsReadpostrequestbodycontentapplicationJsonschema",
    modelProperties: {
      type: {
        required: true,
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1671y1y·teams-teamname-users-username-notifications-read·post·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1671y1yteamsTeamnameUsersUsernameNotificationsReadpostresponses200contentapplicationJsonschema",
    modelProperties: {
      updatedCount: {
        serializedName: "updatedCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Paths1muma8lteamsTeamnameProcessesProcessidMetricsgetresponses401contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·1muma8l·teams-teamname-processes-processid-metrics·get·responses·401·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths1muma8lteamsTeamnameProcessesProcessidMetricsgetresponses401contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsk1n3clteamsTeamnameProcessesProcessidMetricsgetresponses403contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·k1n3cl·teams-teamname-processes-processid-metrics·get·responses·403·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsk1n3clteamsTeamnameProcessesProcessidMetricsgetresponses403contentapplicationJsonschema",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·ihm2va·teams-teamname-modules·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsihm2vateamsTeamnameModulesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      modules: {
        required: true,
        serializedName: "modules",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Module"
            }
          }
        }
      }
    }
  }
};

export const Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·11m17tv·teams-teamname-modules-modulename-features·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths11m17tvteamsTeamnameModulesModulenameFeaturesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      features: {
        required: true,
        serializedName: "features",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Feature"
            }
          }
        }
      }
    }
  }
};

export const Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·iiwx1p·teams-teamname-features·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsiiwx1pteamsTeamnameFeaturesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      features: {
        required: true,
        serializedName: "features",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Feature"
            }
          }
        }
      }
    }
  }
};

export const Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·2ub8hq·teams-teamname-features-featurename-modules·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths2ub8hqteamsTeamnameFeaturesFeaturenameModulesgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      modules: {
        serializedName: "modules",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Module"
            }
          }
        }
      }
    }
  }
};

export const Paths100lpxxteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems: msRest.CompositeMapper = {
  serializedName: "paths·100lpxx·teams-teamname-frequently_used_actions·get·responses·200·content·application-json·schema·properties·actions·items",
  type: {
    name: "Composite",
    className: "Paths100lpxxteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems",
    modelProperties: {
      appID: {
        required: true,
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "String"
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "String"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·82tu7b·teams-teamname-frequently_used_actions·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Paths82tu7bteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Paths100lpxxteamsTeamnameFrequentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems"
            }
          }
        }
      }
    }
  }
};

export const Pathswn3ek6teamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems: msRest.CompositeMapper = {
  serializedName: "paths·wn3ek6·teams-teamname-recently_used_actions·get·responses·200·content·application-json·schema·properties·actions·items",
  type: {
    name: "Composite",
    className: "Pathswn3ek6teamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems",
    modelProperties: {
      appID: {
        required: true,
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "String"
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "String"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "paths·a2twfj·teams-teamname-recently_used_actions·get·responses·200·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Pathsa2twfjteamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschema",
    modelProperties: {
      nextPageToken: {
        serializedName: "nextPageToken",
        type: {
          name: "String"
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Pathswn3ek6teamsTeamnameRecentlyUsedActionsgetresponses200contentapplicationJsonschemapropertiesactionsitems"
            }
          }
        }
      }
    }
  }
};

export const ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "components·jadoad·requestbodies·update_actionupdate·content·application-json·schema",
  type: {
    name: "Composite",
    className: "ComponentsjadoadrequestbodiesupdateActionupdatecontentapplicationJsonschema",
    modelProperties: {
      action: {
        serializedName: "action",
        type: {
          name: "Composite",
          className: "Action"
        }
      },
      mask: {
        serializedName: "mask",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema: msRest.CompositeMapper = {
  serializedName: "components·1e9mesq·requestbodies·create_global_capability_grantgrantrequest·content·application-json·schema",
  type: {
    name: "Composite",
    className: "Components1e9mesqrequestbodiescreateGlobalCapabilityGrantgrantrequestcontentapplicationJsonschema",
    modelProperties: {
      lifetimeSeconds: {
        serializedName: "lifetimeSeconds",
        type: {
          name: "Number"
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AccessRequest: msRest.CompositeMapper = {
  serializedName: "AccessRequest",
  type: {
    name: "Composite",
    className: "AccessRequest",
    modelProperties: {
      requesterEmailAddress: {
        serializedName: "requesterEmailAddress",
        type: {
          name: "String"
        }
      },
      requestedObjectType: {
        serializedName: "requestedObjectType",
        type: {
          name: "String"
        }
      },
      requestedObjectID: {
        serializedName: "requestedObjectID",
        type: {
          name: "String"
        }
      },
      approvedByUserID: {
        serializedName: "approvedByUserID",
        type: {
          name: "Uuid"
        }
      },
      approvedAt: {
        serializedName: "approvedAt",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CustomIntegrationDefinition: msRest.CompositeMapper = {
  serializedName: "CustomIntegrationDefinition",
  type: {
    name: "Composite",
    className: "CustomIntegrationDefinition",
    modelProperties: {
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        required: true,
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      config: {
        required: true,
        serializedName: "config",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const InvitesNew: msRest.CompositeMapper = {
  serializedName: "InvitesNew",
  type: {
    name: "Composite",
    className: "InvitesNew",
    modelProperties: {
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      invitationThreadID: {
        serializedName: "invitationThreadID",
        type: {
          name: "String"
        }
      },
      isRestricted: {
        serializedName: "isRestricted",
        type: {
          name: "Boolean"
        }
      },
      isAdmin: {
        serializedName: "isAdmin",
        type: {
          name: "Boolean"
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "String"
        }
      },
      webhookID: {
        serializedName: "webhookID",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProcessRights: msRest.CompositeMapper = {
  serializedName: "ProcessRights",
  type: {
    name: "Composite",
    className: "ProcessRights",
    modelProperties: {
      canView: {
        required: true,
        serializedName: "canView",
        type: {
          name: "Boolean"
        }
      },
      canEdit: {
        required: true,
        serializedName: "canEdit",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FavoritesNew: msRest.CompositeMapper = {
  serializedName: "FavoritesNew",
  type: {
    name: "Composite",
    className: "FavoritesNew",
    modelProperties: {
      favorite: {
        required: true,
        serializedName: "favorite",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Favorite: msRest.CompositeMapper = {
  serializedName: "Favorite",
  type: {
    name: "Composite",
    className: "Favorite",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      favoritedOn: {
        serializedName: "favoritedOn",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FavoritesListPageInfo: msRest.CompositeMapper = {
  serializedName: "FavoritesList-pageInfo",
  type: {
    name: "Composite",
    className: "FavoritesListPageInfo",
    modelProperties: {
      last: {
        serializedName: "last",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FavoritesList: msRest.CompositeMapper = {
  serializedName: "FavoritesList",
  type: {
    name: "Composite",
    className: "FavoritesList",
    modelProperties: {
      results: {
        required: true,
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Favorite"
            }
          }
        }
      },
      totalCount: {
        required: true,
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      },
      pageInfo: {
        required: true,
        serializedName: "pageInfo",
        type: {
          name: "Composite",
          className: "FavoritesListPageInfo"
        }
      }
    }
  }
};

export const SAMLAuthResponseBody: msRest.CompositeMapper = {
  serializedName: "SAML-auth-responseBody",
  type: {
    name: "Composite",
    className: "SAMLAuthResponseBody",
    modelProperties: {
      requestId: {
        serializedName: "requestId",
        type: {
          name: "String"
        }
      },
      userKey: {
        serializedName: "userKey",
        type: {
          name: "String"
        }
      },
      userEmail: {
        serializedName: "userEmail",
        type: {
          name: "String"
        }
      },
      initialLocation: {
        serializedName: "initialLocation",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SAMLAuthHeaders: msRest.CompositeMapper = {
  serializedName: "SAML-auth-headers",
  type: {
    name: "Composite",
    className: "SAMLAuthHeaders",
    modelProperties: {
      setCookie: {
        serializedName: "set-cookie",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SAMLAuth: msRest.CompositeMapper = {
  serializedName: "SAML-auth",
  type: {
    name: "Composite",
    className: "SAMLAuth",
    modelProperties: {
      responseBody: {
        serializedName: "responseBody",
        type: {
          name: "Composite",
          className: "SAMLAuthResponseBody"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "Object"
        }
      },
      headers: {
        serializedName: "headers",
        type: {
          name: "Composite",
          className: "SAMLAuthHeaders"
        }
      }
    }
  }
};

export const SAMLServiceProviderMetadataBody: msRest.CompositeMapper = {
  serializedName: "SAML-serviceProviderMetadata-body",
  type: {
    name: "Composite",
    className: "SAMLServiceProviderMetadataBody",
    modelProperties: {
      entityID: {
        serializedName: "entityID",
        type: {
          name: "String"
        }
      },
      acsURL: {
        serializedName: "acsURL",
        type: {
          name: "String"
        }
      },
      metadataXML: {
        serializedName: "metadataXML",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SAMLServiceProviderMetadata: msRest.CompositeMapper = {
  serializedName: "SAML-serviceProviderMetadata",
  type: {
    name: "Composite",
    className: "SAMLServiceProviderMetadata",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "SAMLServiceProviderMetadataBody"
        }
      }
    }
  }
};

export const SearchV1ResponseResult: msRest.CompositeMapper = {
  serializedName: "SearchV1Response-result",
  type: {
    name: "Composite",
    className: "SearchV1ResponseResult",
    modelProperties: {
      pageToken: {
        serializedName: "pageToken",
        type: {
          name: "String"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchV1Response: msRest.CompositeMapper = {
  serializedName: "SearchV1Response",
  type: {
    name: "Composite",
    className: "SearchV1Response",
    modelProperties: {
      result: {
        serializedName: "result",
        type: {
          name: "Composite",
          className: "SearchV1ResponseResult"
        }
      },
      pageToken: {
        serializedName: "pageToken",
        type: {
          name: "String"
        }
      },
      limit: {
        serializedName: "limit",
        type: {
          name: "String"
        }
      },
      types: {
        serializedName: "types",
        type: {
          name: "String"
        }
      },
      q: {
        serializedName: "q",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchV2RequestFilterProcessesCreatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-processes-createdDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterProcessesCreatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterProcesses: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-processes",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterProcesses",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Object"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Object"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "Object"
        }
      },
      participatingUsers: {
        serializedName: "participatingUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterProcessesCreatedDate"
        }
      },
      isEditable: {
        serializedName: "isEditable",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const SearchV2RequestFilterRunsStartDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-runs-startDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterRunsStartDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterRunsCreatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-runs-createdDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterRunsCreatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterRunsUpdatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-runs-updatedDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterRunsUpdatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterRunsDeadline: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-runs-deadline",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterRunsDeadline",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterRuns: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-runs",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterRuns",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Object"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Object"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Object"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterRunsStartDate"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "Object"
        }
      },
      participatingUsers: {
        serializedName: "participatingUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterRunsCreatedDate"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterRunsUpdatedDate"
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterRunsDeadline"
        }
      },
      rootProcessID: {
        serializedName: "rootProcessID",
        type: {
          name: "String"
        }
      },
      isWhollyOwned: {
        serializedName: "isWhollyOwned",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTasksStartDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tasks-startDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTasksStartDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTasksTaskNumber: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tasks-taskNumber",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTasksTaskNumber",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "Number"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "Number"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "Number"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTasksCreatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tasks-createdDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTasksCreatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTasksUpdatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tasks-updatedDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTasksUpdatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTasksDeadline: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tasks-deadline",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTasksDeadline",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTasks: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tasks",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTasks",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Object"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Object"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Object"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTasksStartDate"
        }
      },
      assignedTo: {
        serializedName: "assignedTo",
        type: {
          name: "Object"
        }
      },
      taskNumber: {
        serializedName: "taskNumber",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTasksTaskNumber"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTasksCreatedDate"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTasksUpdatedDate"
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTasksDeadline"
        }
      },
      participatingUsers: {
        serializedName: "participatingUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const SearchV2RequestFilterTablesCreatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tables-createdDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTablesCreatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterTables: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-tables",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterTables",
    modelProperties: {
      tableID: {
        serializedName: "tableID",
        type: {
          name: "Object"
        }
      },
      tableType: {
        serializedName: "tableType",
        type: {
          name: "Object"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Object"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTablesCreatedDate"
        }
      }
    }
  }
};

export const SearchV2RequestFilterUsersCreatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-users-createdDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterUsersCreatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterUsers: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-users",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterUsers",
    modelProperties: {
      userID: {
        serializedName: "userID",
        type: {
          name: "Object"
        }
      },
      isTeamAdmin: {
        serializedName: "isTeamAdmin",
        type: {
          name: "Boolean"
        }
      },
      isIntegration: {
        serializedName: "isIntegration",
        type: {
          name: "Object"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Object"
        }
      },
      isRuntime: {
        serializedName: "isRuntime",
        type: {
          name: "Object"
        }
      },
      isGroup: {
        serializedName: "isGroup",
        type: {
          name: "Boolean"
        }
      },
      isDeactivated: {
        serializedName: "isDeactivated",
        type: {
          name: "Boolean"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterUsersCreatedDate"
        }
      }
    }
  }
};

export const SearchV2RequestFilterHelpCreatedDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-help-createdDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterHelpCreatedDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterHelpStartDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-help-startDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterHelpStartDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterHelpEndDate: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-help-endDate",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterHelpEndDate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterHelp: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-help",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterHelp",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "Object"
        }
      },
      helpLabels: {
        serializedName: "helpLabels",
        type: {
          name: "Object"
        }
      },
      article: {
        serializedName: "article",
        type: {
          name: "Object"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterHelpCreatedDate"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterHelpStartDate"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterHelpEndDate"
        }
      }
    }
  }
};

export const SearchV2RequestFilterActionsVersion: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-actions-version",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterActionsVersion",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterActionsBaseAppID: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-actions-baseAppID",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterActionsBaseAppID",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestFilterActions: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-actions",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterActions",
    modelProperties: {
      appID: {
        serializedName: "appID",
        type: {
          name: "Object"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "Object"
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "Object"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterActionsVersion"
        }
      },
      baseAppID: {
        serializedName: "baseAppID",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterActionsBaseAppID"
        }
      }
    }
  }
};

export const SearchV2RequestFilterWebhooks: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-webhooks",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterWebhooks",
    modelProperties: {
      webhookID: {
        serializedName: "webhookID",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const SearchV2RequestFilterUpcomings: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter-upcomings",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilterUpcomings",
    modelProperties: {
      upcomingID: {
        serializedName: "upcomingID",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const SearchV2RequestFilter: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-filter",
  type: {
    name: "Composite",
    className: "SearchV2RequestFilter",
    modelProperties: {
      processes: {
        serializedName: "processes",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterProcesses"
        }
      },
      runs: {
        serializedName: "runs",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterRuns"
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTasks"
        }
      },
      tables: {
        serializedName: "tables",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterTables"
        }
      },
      users: {
        serializedName: "users",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterUsers"
        }
      },
      help: {
        serializedName: "help",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterHelp"
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterActions"
        }
      },
      webhooks: {
        serializedName: "webhooks",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterWebhooks"
        }
      },
      upcomings: {
        serializedName: "upcomings",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilterUpcomings"
        }
      }
    }
  }
};

export const SearchV2RequestQueryProperties: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-query-properties",
  type: {
    name: "Composite",
    className: "SearchV2RequestQueryProperties",
    modelProperties: {
      processes: {
        serializedName: "processes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      runs: {
        serializedName: "runs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      tables: {
        serializedName: "tables",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      users: {
        serializedName: "users",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      notifications: {
        serializedName: "notifications",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      help: {
        serializedName: "help",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      webhooks: {
        serializedName: "webhooks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      upcomings: {
        serializedName: "upcomings",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const Components17swrlnschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesprocessespropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "components·17swrln·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·processes·properties·createddate",
  type: {
    name: "Composite",
    className: "Components17swrlnschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesprocessespropertiescreateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SearchV2RequestQueryBoostPropertiesProperties: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-query-boost-properties-properties",
  type: {
    name: "Composite",
    className: "SearchV2RequestQueryBoostPropertiesProperties",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Object"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Object"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "Object"
        }
      },
      participatingUsers: {
        serializedName: "participatingUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Components17swrlnschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesprocessespropertiescreateddate"
        }
      },
      isEditable: {
        serializedName: "isEditable",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const Componentslxzbm1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesstartdate: msRest.CompositeMapper = {
  serializedName: "components·lxzbm1·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·runs·properties·startdate",
  type: {
    name: "Composite",
    className: "Componentslxzbm1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesstartdate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components1kbs0jschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "components·1kbs0j·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·runs·properties·createddate",
  type: {
    name: "Composite",
    className: "Components1kbs0jschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiescreateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsg1pzuzschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesupdateddate: msRest.CompositeMapper = {
  serializedName: "components·g1pzuz·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·runs·properties·updateddate",
  type: {
    name: "Composite",
    className: "Componentsg1pzuzschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesupdateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentscj5oc0schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesdeadline: msRest.CompositeMapper = {
  serializedName: "components·cj5oc0·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·runs·properties·deadline",
  type: {
    name: "Composite",
    className: "Componentscj5oc0schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesdeadline",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsm3fkqkschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesruns: msRest.CompositeMapper = {
  serializedName: "components·m3fkqk·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·runs",
  type: {
    name: "Composite",
    className: "Componentsm3fkqkschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesruns",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Object"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Object"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Object"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "Composite",
          className: "Componentslxzbm1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesstartdate"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "Object"
        }
      },
      participatingUsers: {
        serializedName: "participatingUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Components1kbs0jschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiescreateddate"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "Composite",
          className: "Componentsg1pzuzschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesupdateddate"
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "Composite",
          className: "Componentscj5oc0schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesrunspropertiesdeadline"
        }
      },
      rootProcessID: {
        serializedName: "rootProcessID",
        type: {
          name: "String"
        }
      },
      isWhollyOwned: {
        serializedName: "isWhollyOwned",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Componentsppoo6aschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesstartdate: msRest.CompositeMapper = {
  serializedName: "components·ppoo6a·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tasks·properties·startdate",
  type: {
    name: "Composite",
    className: "Componentsppoo6aschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesstartdate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components15zr4uuschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiestasknumber: msRest.CompositeMapper = {
  serializedName: "components·15zr4uu·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tasks·properties·tasknumber",
  type: {
    name: "Composite",
    className: "Components15zr4uuschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiestasknumber",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "Number"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "Number"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "Number"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Components17gefuwschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "components·17gefuw·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tasks·properties·createddate",
  type: {
    name: "Composite",
    className: "Components17gefuwschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiescreateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components1rayy0mschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesupdateddate: msRest.CompositeMapper = {
  serializedName: "components·1rayy0m·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tasks·properties·updateddate",
  type: {
    name: "Composite",
    className: "Components1rayy0mschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesupdateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components1eok1u4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesdeadline: msRest.CompositeMapper = {
  serializedName: "components·1eok1u4·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tasks·properties·deadline",
  type: {
    name: "Composite",
    className: "Components1eok1u4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesdeadline",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsleqgx7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestasks: msRest.CompositeMapper = {
  serializedName: "components·leqgx7·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tasks",
  type: {
    name: "Composite",
    className: "Componentsleqgx7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestasks",
    modelProperties: {
      processID: {
        serializedName: "processID",
        type: {
          name: "Object"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Object"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Object"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "Composite",
          className: "Componentsppoo6aschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesstartdate"
        }
      },
      assignedTo: {
        serializedName: "assignedTo",
        type: {
          name: "Object"
        }
      },
      taskNumber: {
        serializedName: "taskNumber",
        type: {
          name: "Composite",
          className: "Components15zr4uuschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiestasknumber"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Components17gefuwschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiescreateddate"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "Composite",
          className: "Components1rayy0mschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesupdateddate"
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "Composite",
          className: "Components1eok1u4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestaskspropertiesdeadline"
        }
      },
      participatingUsers: {
        serializedName: "participatingUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const Components1p7ocp3schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestablespropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "components·1p7ocp3·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tables·properties·createddate",
  type: {
    name: "Composite",
    className: "Components1p7ocp3schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestablespropertiescreateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components1cyn58ischemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestables: msRest.CompositeMapper = {
  serializedName: "components·1cyn58i·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·tables",
  type: {
    name: "Composite",
    className: "Components1cyn58ischemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestables",
    modelProperties: {
      tableID: {
        serializedName: "tableID",
        type: {
          name: "Object"
        }
      },
      tableType: {
        serializedName: "tableType",
        type: {
          name: "Object"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Object"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Components1p7ocp3schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestablespropertiescreateddate"
        }
      }
    }
  }
};

export const Components1b2vq0nschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesuserspropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "components·1b2vq0n·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·users·properties·createddate",
  type: {
    name: "Composite",
    className: "Components1b2vq0nschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesuserspropertiescreateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsn3whvsschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesusers: msRest.CompositeMapper = {
  serializedName: "components·n3whvs·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·users",
  type: {
    name: "Composite",
    className: "Componentsn3whvsschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesusers",
    modelProperties: {
      userID: {
        serializedName: "userID",
        type: {
          name: "Object"
        }
      },
      isTeamAdmin: {
        serializedName: "isTeamAdmin",
        type: {
          name: "Boolean"
        }
      },
      isIntegration: {
        serializedName: "isIntegration",
        type: {
          name: "Object"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Object"
        }
      },
      isRuntime: {
        serializedName: "isRuntime",
        type: {
          name: "Object"
        }
      },
      isGroup: {
        serializedName: "isGroup",
        type: {
          name: "Boolean"
        }
      },
      isDeactivated: {
        serializedName: "isDeactivated",
        type: {
          name: "Boolean"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Components1b2vq0nschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesuserspropertiescreateddate"
        }
      }
    }
  }
};

export const Componentsp46hy5schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiescreateddate: msRest.CompositeMapper = {
  serializedName: "components·p46hy5·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·help·properties·createddate",
  type: {
    name: "Composite",
    className: "Componentsp46hy5schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiescreateddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components1j6sqk2schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesstartdate: msRest.CompositeMapper = {
  serializedName: "components·1j6sqk2·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·help·properties·startdate",
  type: {
    name: "Composite",
    className: "Components1j6sqk2schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesstartdate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Components1mog8x1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesenddate: msRest.CompositeMapper = {
  serializedName: "components·1mog8x1·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·help·properties·enddate",
  type: {
    name: "Composite",
    className: "Components1mog8x1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesenddate",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsypjx3rschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelp: msRest.CompositeMapper = {
  serializedName: "components·ypjx3r·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·help",
  type: {
    name: "Composite",
    className: "Componentsypjx3rschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelp",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "Object"
        }
      },
      helpLabels: {
        serializedName: "helpLabels",
        type: {
          name: "Object"
        }
      },
      article: {
        serializedName: "article",
        type: {
          name: "Object"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "Composite",
          className: "Componentsp46hy5schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiescreateddate"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "Composite",
          className: "Components1j6sqk2schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesstartdate"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "Composite",
          className: "Components1mog8x1schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelppropertiesenddate"
        }
      }
    }
  }
};

export const Components178djz4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesversion: msRest.CompositeMapper = {
  serializedName: "components·178djz4·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·actions·properties·version",
  type: {
    name: "Composite",
    className: "Components178djz4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesversion",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsivzon7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesbaseappid: msRest.CompositeMapper = {
  serializedName: "components·ivzon7·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·actions·properties·baseappid",
  type: {
    name: "Composite",
    className: "Componentsivzon7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesbaseappid",
    modelProperties: {
      gt: {
        serializedName: "gt",
        type: {
          name: "DateTime"
        }
      },
      lt: {
        serializedName: "lt",
        type: {
          name: "DateTime"
        }
      },
      gte: {
        serializedName: "gte",
        type: {
          name: "DateTime"
        }
      },
      lte: {
        serializedName: "lte",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Componentsumb4thschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactions: msRest.CompositeMapper = {
  serializedName: "components·umb4th·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·actions",
  type: {
    name: "Composite",
    className: "Componentsumb4thschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactions",
    modelProperties: {
      appID: {
        serializedName: "appID",
        type: {
          name: "Object"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "Object"
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "Object"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "Composite",
          className: "Components178djz4schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesversion"
        }
      },
      baseAppID: {
        serializedName: "baseAppID",
        type: {
          name: "Composite",
          className: "Componentsivzon7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactionspropertiesbaseappid"
        }
      }
    }
  }
};

export const Components17065sbschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieswebhooks: msRest.CompositeMapper = {
  serializedName: "components·17065sb·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·webhooks",
  type: {
    name: "Composite",
    className: "Components17065sbschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieswebhooks",
    modelProperties: {
      webhookID: {
        serializedName: "webhookID",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const Componentsso3kq7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesupcomings: msRest.CompositeMapper = {
  serializedName: "components·so3kq7·schemas·searchv2request·properties·query·properties·boost·items·properties·filter·properties·upcomings",
  type: {
    name: "Composite",
    className: "Componentsso3kq7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesupcomings",
    modelProperties: {
      upcomingID: {
        serializedName: "upcomingID",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const SearchV2RequestQueryBoostItemFilter: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-query-boost-item-filter",
  type: {
    name: "Composite",
    className: "SearchV2RequestQueryBoostItemFilter",
    modelProperties: {
      processes: {
        serializedName: "processes",
        type: {
          name: "Composite",
          className: "SearchV2RequestQueryBoostPropertiesProperties"
        }
      },
      runs: {
        serializedName: "runs",
        type: {
          name: "Composite",
          className: "Componentsm3fkqkschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesruns"
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Composite",
          className: "Componentsleqgx7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestasks"
        }
      },
      tables: {
        serializedName: "tables",
        type: {
          name: "Composite",
          className: "Components1cyn58ischemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiestables"
        }
      },
      users: {
        serializedName: "users",
        type: {
          name: "Composite",
          className: "Componentsn3whvsschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesusers"
        }
      },
      help: {
        serializedName: "help",
        type: {
          name: "Composite",
          className: "Componentsypjx3rschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieshelp"
        }
      },
      actions: {
        serializedName: "actions",
        type: {
          name: "Composite",
          className: "Componentsumb4thschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesactions"
        }
      },
      webhooks: {
        serializedName: "webhooks",
        type: {
          name: "Composite",
          className: "Components17065sbschemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertieswebhooks"
        }
      },
      upcomings: {
        serializedName: "upcomings",
        type: {
          name: "Composite",
          className: "Componentsso3kq7schemassearchv2requestpropertiesquerypropertiesboostitemspropertiesfilterpropertiesupcomings"
        }
      }
    }
  }
};

export const SearchV2RequestQueryBoostItem: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-query-boostItem",
  type: {
    name: "Composite",
    className: "SearchV2RequestQueryBoostItem",
    modelProperties: {
      filter: {
        serializedName: "filter",
        type: {
          name: "Composite",
          className: "SearchV2RequestQueryBoostItemFilter"
        }
      },
      types: {
        serializedName: "types",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      boost: {
        serializedName: "boost",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SearchV2RequestQuery: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-query",
  type: {
    name: "Composite",
    className: "SearchV2RequestQuery",
    modelProperties: {
      text: {
        required: true,
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      prefixSearch: {
        serializedName: "prefixSearch",
        type: {
          name: "Boolean"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Composite",
          className: "SearchV2RequestQueryProperties"
        }
      },
      boost: {
        serializedName: "boost",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchV2RequestQueryBoostItem"
            }
          }
        }
      }
    }
  }
};

export const SearchV2RequestOptionsActions: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-options-actions",
  type: {
    name: "Composite",
    className: "SearchV2RequestOptionsActions",
    modelProperties: {
      ignoreAvailability: {
        serializedName: "ignoreAvailability",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SearchV2RequestOptions: msRest.CompositeMapper = {
  serializedName: "SearchV2Request-options",
  type: {
    name: "Composite",
    className: "SearchV2RequestOptions",
    modelProperties: {
      actions: {
        serializedName: "actions",
        type: {
          name: "Composite",
          className: "SearchV2RequestOptionsActions"
        }
      }
    }
  }
};

export const SearchV2Request: msRest.CompositeMapper = {
  serializedName: "SearchV2Request",
  type: {
    name: "Composite",
    className: "SearchV2Request",
    modelProperties: {
      types: {
        serializedName: "types",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      filter: {
        serializedName: "filter",
        type: {
          name: "Composite",
          className: "SearchV2RequestFilter"
        }
      },
      query: {
        serializedName: "query",
        type: {
          name: "Composite",
          className: "SearchV2RequestQuery"
        }
      },
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "SearchV2RequestOptions"
        }
      },
      after: {
        serializedName: "after",
        type: {
          name: "String"
        }
      },
      limit: {
        serializedName: "limit",
        type: {
          name: "Number"
        }
      },
      sort: {
        serializedName: "sort",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchV2ResponseResultsItem: msRest.CompositeMapper = {
  serializedName: "SearchV2Response-resultsItem",
  type: {
    name: "Composite",
    className: "SearchV2ResponseResultsItem",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      item: {
        serializedName: "item",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const SearchV2ResponsePageInfo: msRest.CompositeMapper = {
  serializedName: "SearchV2Response-pageInfo",
  type: {
    name: "Composite",
    className: "SearchV2ResponsePageInfo",
    modelProperties: {
      last: {
        serializedName: "last",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchV2Response: msRest.CompositeMapper = {
  serializedName: "SearchV2Response",
  type: {
    name: "Composite",
    className: "SearchV2Response",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchV2ResponseResultsItem"
            }
          }
        }
      },
      pageInfo: {
        serializedName: "pageInfo",
        type: {
          name: "Composite",
          className: "SearchV2ResponsePageInfo"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SMSEventForm: msRest.CompositeMapper = {
  serializedName: "SMS-event-form",
  type: {
    name: "Composite",
    className: "SMSEventForm",
    modelProperties: {
      from: {
        serializedName: "From",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "Body",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SMSEvent: msRest.CompositeMapper = {
  serializedName: "SMS-event",
  type: {
    name: "Composite",
    className: "SMSEvent",
    modelProperties: {
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "SMSEventForm"
        }
      }
    }
  }
};

export const SMS: msRest.CompositeMapper = {
  serializedName: "SMS",
  type: {
    name: "Composite",
    className: "SMS",
    modelProperties: {
      event: {
        serializedName: "event",
        type: {
          name: "Composite",
          className: "SMSEvent"
        }
      }
    }
  }
};

export const Upcoming: msRest.CompositeMapper = {
  serializedName: "Upcoming",
  type: {
    name: "Composite",
    className: "Upcoming",
    modelProperties: {
      upcomingID: {
        required: true,
        serializedName: "upcomingID",
        type: {
          name: "Uuid"
        }
      },
      authUser: {
        required: true,
        serializedName: "authUser",
        type: {
          name: "String"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      schedule: {
        required: true,
        serializedName: "schedule",
        type: {
          name: "DateTime"
        }
      },
      action: {
        required: true,
        serializedName: "action",
        type: {
          name: "String"
        }
      },
      recurring: {
        serializedName: "recurring",
        type: {
          name: "Object"
        }
      },
      timezone: {
        serializedName: "timezone",
        type: {
          name: "String"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      eventData: {
        required: true,
        serializedName: "eventData",
        type: {
          name: "Object"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UserLogins: msRest.CompositeMapper = {
  serializedName: "UserLogins",
  type: {
    name: "Composite",
    className: "UserLogins",
    modelProperties: {
      ipAddress: {
        serializedName: "ipAddress",
        type: {
          name: "String"
        }
      },
      currentIP: {
        serializedName: "currentIP",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      loginDate: {
        serializedName: "loginDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CommunitySSOBody: msRest.CompositeMapper = {
  serializedName: "Community-SSO-body",
  type: {
    name: "Composite",
    className: "CommunitySSOBody",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "String"
        }
      },
      uniqueid: {
        serializedName: "uniqueid",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      photourl: {
        serializedName: "photourl",
        type: {
          name: "String"
        }
      },
      clientId: {
        serializedName: "client_id",
        type: {
          name: "String"
        }
      },
      signature: {
        serializedName: "signature",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommunitySSO: msRest.CompositeMapper = {
  serializedName: "Community-SSO",
  type: {
    name: "Composite",
    className: "CommunitySSO",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CommunitySSOBody"
        }
      }
    }
  }
};

export const CommunitySSOV2Body: msRest.CompositeMapper = {
  serializedName: "Community-SSO-V2-body",
  type: {
    name: "Composite",
    className: "CommunitySSOV2Body",
    modelProperties: {
      jwt: {
        serializedName: "jwt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommunitySSOV2: msRest.CompositeMapper = {
  serializedName: "Community-SSO-V2",
  type: {
    name: "Composite",
    className: "CommunitySSOV2",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CommunitySSOV2Body"
        }
      }
    }
  }
};

export const CommunityAuthBody: msRest.CompositeMapper = {
  serializedName: "Community-Auth-body",
  type: {
    name: "Composite",
    className: "CommunityAuthBody",
    modelProperties: {
      uniqueid: {
        serializedName: "uniqueid",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      photourl: {
        serializedName: "photourl",
        type: {
          name: "String"
        }
      },
      clientId: {
        serializedName: "client_id",
        type: {
          name: "String"
        }
      },
      signature: {
        serializedName: "signature",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommunityAuth: msRest.CompositeMapper = {
  serializedName: "Community-Auth",
  type: {
    name: "Composite",
    className: "CommunityAuth",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CommunityAuthBody"
        }
      }
    }
  }
};

export const CommunityAuthV2Body: msRest.CompositeMapper = {
  serializedName: "Community-Auth-V2-body",
  type: {
    name: "Composite",
    className: "CommunityAuthV2Body",
    modelProperties: {
      userJwt: {
        serializedName: "userJwt",
        type: {
          name: "String"
        }
      },
      rurl: {
        serializedName: "rurl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommunityAuthV2: msRest.CompositeMapper = {
  serializedName: "Community-Auth-V2",
  type: {
    name: "Composite",
    className: "CommunityAuthV2",
    modelProperties: {
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "CommunityAuthV2Body"
        }
      }
    }
  }
};

export const ActionInvocationInputsItem: msRest.CompositeMapper = {
  serializedName: "ActionInvocation-inputsItem",
  type: {
    name: "Composite",
    className: "ActionInvocationInputsItem",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      defaultValue: {
        serializedName: "defaultValue",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActionInvocationOutputsItem: msRest.CompositeMapper = {
  serializedName: "ActionInvocation-outputsItem",
  type: {
    name: "Composite",
    className: "ActionInvocationOutputsItem",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActionInvocation: msRest.CompositeMapper = {
  serializedName: "ActionInvocation",
  type: {
    name: "Composite",
    className: "ActionInvocation",
    modelProperties: {
      actionID: {
        required: true,
        serializedName: "actionID",
        type: {
          name: "Uuid"
        }
      },
      actionInvocationID: {
        required: true,
        serializedName: "actionInvocationID",
        type: {
          name: "Uuid"
        }
      },
      requiredWorkerTags: {
        serializedName: "requiredWorkerTags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      inputs: {
        serializedName: "inputs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActionInvocationInputsItem"
            }
          }
        }
      },
      outputs: {
        serializedName: "outputs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActionInvocationOutputsItem"
            }
          }
        }
      },
      expirationTime: {
        serializedName: "expirationTime",
        type: {
          name: "DateTime"
        }
      },
      completedByWorkerID: {
        serializedName: "completedByWorkerID",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByID: {
        serializedName: "createdByID",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ActionInvocationRequestInputsItem: msRest.CompositeMapper = {
  serializedName: "ActionInvocationRequest-inputsItem",
  type: {
    name: "Composite",
    className: "ActionInvocationRequestInputsItem",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      defaultValue: {
        serializedName: "defaultValue",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActionInvocationRequest: msRest.CompositeMapper = {
  serializedName: "ActionInvocationRequest",
  type: {
    name: "Composite",
    className: "ActionInvocationRequest",
    modelProperties: {
      actionID: {
        required: true,
        serializedName: "actionID",
        type: {
          name: "Uuid"
        }
      },
      requiredWorkerTags: {
        serializedName: "requiredWorkerTags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      },
      inputs: {
        serializedName: "inputs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActionInvocationRequestInputsItem"
            }
          }
        }
      },
      lifetimeSeconds: {
        serializedName: "lifetimeSeconds",
        defaultValue: 86400,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ImportActionActionsItemAppData: msRest.CompositeMapper = {
  serializedName: "ImportAction-actions-item-appData",
  type: {
    name: "Composite",
    className: "ImportActionActionsItemAppData",
    modelProperties: {
      appID: {
        required: true,
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      key: {
        required: true,
        serializedName: "key",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ImportActionActionsItem: msRest.CompositeMapper = {
  serializedName: "ImportAction-actionsItem",
  type: {
    name: "Composite",
    className: "ImportActionActionsItem",
    modelProperties: {
      app: {
        serializedName: "app",
        type: {
          name: "Composite",
          className: "App"
        }
      },
      appData: {
        serializedName: "appData",
        type: {
          name: "Composite",
          className: "ImportActionActionsItemAppData"
        }
      }
    }
  }
};

export const ImportAction: msRest.CompositeMapper = {
  serializedName: "ImportAction",
  type: {
    name: "Composite",
    className: "ImportAction",
    modelProperties: {
      actions: {
        required: true,
        serializedName: "actions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImportActionActionsItem"
            }
          }
        }
      }
    }
  }
};

export const WorkbenchCapabilityDefinition: msRest.CompositeMapper = {
  serializedName: "WorkbenchCapabilityDefinition",
  type: {
    name: "Composite",
    className: "WorkbenchCapabilityDefinition",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      grantedByDefault: {
        serializedName: "grantedByDefault",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      isPublic: {
        serializedName: "isPublic",
        defaultValue: true,
        type: {
          name: "Boolean"
        }
      },
      metadata: {
        serializedName: "metadata",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppStepConfiguration: msRest.CompositeMapper = {
  serializedName: "AppStepConfiguration",
  type: {
    name: "Composite",
    className: "AppStepConfiguration",
    modelProperties: {
      appID: {
        required: true,
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "String"
        }
      },
      params: {
        serializedName: "params",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      }
    }
  }
};

export const ProcessMetric: msRest.CompositeMapper = {
  serializedName: "ProcessMetric",
  type: {
    name: "Composite",
    className: "ProcessMetric",
    modelProperties: {
      numberOfCompletedRuns: {
        required: true,
        serializedName: "numberOfCompletedRuns",
        type: {
          name: "Number"
        }
      },
      numberOfCancelledRuns: {
        required: true,
        serializedName: "numberOfCancelledRuns",
        type: {
          name: "Number"
        }
      },
      numberOfFixTasks: {
        required: true,
        serializedName: "numberOfFixTasks",
        type: {
          name: "Number"
        }
      },
      averageRunDuration: {
        required: true,
        serializedName: "averageRunDuration",
        type: {
          name: "TimeSpan"
        }
      },
      minRunDuration: {
        required: true,
        serializedName: "minRunDuration",
        type: {
          name: "TimeSpan"
        }
      },
      maxRunDuration: {
        required: true,
        serializedName: "maxRunDuration",
        type: {
          name: "TimeSpan"
        }
      }
    }
  }
};

export const RolePartial: msRest.CompositeMapper = {
  serializedName: "RolePartial",
  type: {
    name: "Composite",
    className: "RolePartial",
    modelProperties: {
      users: {
        serializedName: "users",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "String"
        }
      },
      rank: {
        serializedName: "rank",
        type: {
          name: "String"
        }
      },
      roleID: {
        serializedName: "roleID",
        type: {
          name: "String"
        }
      },
      roleType: {
        serializedName: "roleType",
        type: {
          name: "String"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "String"
        }
      },
      stepName: {
        serializedName: "stepName",
        type: {
          name: "String"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      userID: {
        serializedName: "userID",
        type: {
          name: "String"
        }
      },
      username: {
        serializedName: "username",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Role: msRest.CompositeMapper = {
  serializedName: "Role",
  type: {
    name: "Composite",
    className: "Role",
    modelProperties: {
      ...RolePartial.type.modelProperties
    }
  }
};

export const SAMLSSOConfiguration: msRest.CompositeMapper = {
  serializedName: "SAMLSSOConfiguration",
  type: {
    name: "Composite",
    className: "SAMLSSOConfiguration",
    modelProperties: {
      loginUrl: {
        required: true,
        serializedName: "loginUrl",
        type: {
          name: "String"
        }
      },
      logoutUrl: {
        serializedName: "logoutUrl",
        type: {
          name: "String"
        }
      },
      entityId: {
        required: true,
        serializedName: "entityId",
        type: {
          name: "String"
        }
      },
      certificate: {
        required: true,
        serializedName: "certificate",
        type: {
          name: "String"
        }
      },
      enabled: {
        required: true,
        serializedName: "enabled",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CommentCommentsAuthor: msRest.CompositeMapper = {
  serializedName: "Comment-comments-author",
  type: {
    name: "Composite",
    className: "CommentCommentsAuthor",
    modelProperties: {
      username: {
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommentComments: msRest.CompositeMapper = {
  serializedName: "Comment-comments",
  type: {
    name: "Composite",
    className: "CommentComments",
    modelProperties: {
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      },
      threadID: {
        serializedName: "threadID",
        type: {
          name: "String"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "CommentCommentsAuthor"
        }
      }
    }
  }
};

export const CommentMessageFrom: msRest.CompositeMapper = {
  serializedName: "Comment-message-from",
  type: {
    name: "Composite",
    className: "CommentMessageFrom",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommentMessage: msRest.CompositeMapper = {
  serializedName: "Comment-message",
  type: {
    name: "Composite",
    className: "CommentMessage",
    modelProperties: {
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      isNotification: {
        serializedName: "isNotification",
        type: {
          name: "String"
        }
      },
      actor: {
        serializedName: "actor",
        type: {
          name: "String"
        }
      },
      from: {
        serializedName: "from",
        type: {
          name: "Composite",
          className: "CommentMessageFrom"
        }
      }
    }
  }
};

export const Comment: msRest.CompositeMapper = {
  serializedName: "Comment",
  type: {
    name: "Composite",
    className: "Comment",
    modelProperties: {
      comments: {
        serializedName: "comments",
        type: {
          name: "Composite",
          className: "CommentComments"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "Composite",
          className: "CommentMessage"
        }
      }
    }
  }
};

export const File: msRest.CompositeMapper = {
  serializedName: "File",
  type: {
    name: "Composite",
    className: "File",
    modelProperties: {
      fileID: {
        required: true,
        serializedName: "fileID",
        type: {
          name: "Uuid"
        }
      },
      fileName: {
        required: true,
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      filePath: {
        serializedName: "filePath",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "String"
        }
      },
      size: {
        serializedName: "size",
        type: {
          name: "String"
        }
      },
      displaySize: {
        serializedName: "displaySize",
        type: {
          name: "String"
        }
      },
      isPublic: {
        serializedName: "isPublic",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      mD5Hash: {
        serializedName: "MD5Hash",
        type: {
          name: "String"
        }
      },
      eTag: {
        serializedName: "ETag",
        type: {
          name: "String"
        }
      },
      createdDate: {
        required: true,
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      previousVersion: {
        serializedName: "previousVersion",
        type: {
          name: "Uuid"
        }
      },
      nextVersion: {
        serializedName: "nextVersion",
        type: {
          name: "Uuid"
        }
      },
      originalFile: {
        serializedName: "originalFile",
        type: {
          name: "Uuid"
        }
      },
      thumbnails: {
        serializedName: "thumbnails",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      extractedFiles: {
        serializedName: "extractedFiles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      versions: {
        serializedName: "versions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      downloadUrl: {
        serializedName: "downloadUrl",
        type: {
          name: "String"
        }
      },
      copyOf: {
        serializedName: "copyOf",
        type: {
          name: "Uuid"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      processName: {
        serializedName: "processName",
        type: {
          name: "String"
        }
      },
      stepName: {
        serializedName: "stepName",
        type: {
          name: "String"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Uuid"
        }
      },
      tableID: {
        serializedName: "tableID",
        type: {
          name: "Uuid"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      threadID: {
        serializedName: "threadID",
        type: {
          name: "String"
        }
      },
      messageID: {
        serializedName: "messageID",
        type: {
          name: "Uuid"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      serverSideEncryption: {
        serializedName: "serverSideEncryption",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Message: msRest.CompositeMapper = {
  serializedName: "Message",
  type: {
    name: "Composite",
    className: "Message",
    modelProperties: {
      threadID: {
        required: true,
        serializedName: "threadID",
        type: {
          name: "String"
        }
      },
      messageID: {
        required: true,
        serializedName: "messageID",
        type: {
          name: "Uuid"
        }
      },
      commentID: {
        serializedName: "commentID",
        type: {
          name: "Uuid"
        }
      },
      username: {
        required: true,
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      medium: {
        required: true,
        serializedName: "medium",
        type: {
          name: "String"
        }
      },
      mediumAddress: {
        required: true,
        serializedName: "mediumAddress",
        type: {
          name: "String"
        }
      },
      mediumMessageID: {
        required: true,
        serializedName: "mediumMessageID",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      timestamp: {
        required: true,
        serializedName: "timestamp",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      participants: {
        required: true,
        serializedName: "participants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      tags: {
        required: true,
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      context: {
        required: true,
        serializedName: "context",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      shortBody: {
        serializedName: "shortBody",
        type: {
          name: "String"
        }
      },
      shortBodyTruncated: {
        serializedName: "shortBodyTruncated",
        type: {
          name: "Boolean"
        }
      },
      envelope: {
        serializedName: "envelope",
        type: {
          name: "Object"
        }
      },
      files: {
        serializedName: "files",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "File"
            }
          }
        }
      },
      notificationID: {
        serializedName: "notificationID",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const Notification: msRest.CompositeMapper = {
  serializedName: "Notification",
  type: {
    name: "Composite",
    className: "Notification",
    modelProperties: {
      notificationID: {
        required: true,
        serializedName: "notificationID",
        type: {
          name: "String"
        }
      },
      userID: {
        required: true,
        serializedName: "userID",
        type: {
          name: "String"
        }
      },
      date: {
        required: true,
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      read: {
        serializedName: "read",
        type: {
          name: "Boolean"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      type: {
        required: true,
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      username: {
        required: true,
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "String"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Predictor: msRest.CompositeMapper = {
  serializedName: "Predictor",
  type: {
    name: "Composite",
    className: "Predictor",
    modelProperties: {
      predictorID: {
        required: true,
        serializedName: "predictorID",
        type: {
          name: "Uuid"
        }
      },
      tableID: {
        required: true,
        serializedName: "tableID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      type: {
        required: true,
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      dependentFieldName: {
        required: true,
        serializedName: "dependentFieldName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProcessExport: msRest.CompositeMapper = {
  serializedName: "ProcessExport",
  type: {
    name: "Composite",
    className: "ProcessExport",
    modelProperties: {
      exportID: {
        required: true,
        serializedName: "exportID",
        type: {
          name: "Uuid"
        }
      },
      processID: {
        required: true,
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      fileID: {
        serializedName: "fileID",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      errorMessage: {
        serializedName: "errorMessage",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      callbackUrl: {
        serializedName: "callbackUrl",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProcessImport: msRest.CompositeMapper = {
  serializedName: "ProcessImport",
  type: {
    name: "Composite",
    className: "ProcessImport",
    modelProperties: {
      importID: {
        required: true,
        serializedName: "importID",
        type: {
          name: "Uuid"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      fileID: {
        required: true,
        serializedName: "fileID",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      errorMessage: {
        serializedName: "errorMessage",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      callbackUrl: {
        serializedName: "callbackUrl",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TaskDescriptionPieces: msRest.CompositeMapper = {
  serializedName: "Task-descriptionPieces",
  type: {
    name: "Composite",
    className: "TaskDescriptionPieces",
    modelProperties: {
      sourceText: {
        required: true,
        serializedName: "sourceText",
        type: {
          name: "String"
        }
      },
      fieldMap: {
        required: true,
        serializedName: "fieldMap",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const Task: msRest.CompositeMapper = {
  serializedName: "Task",
  type: {
    name: "Composite",
    className: "Task",
    modelProperties: {
      processDisplayName: {
        required: true,
        serializedName: "processDisplayName",
        type: {
          name: "String"
        }
      },
      processID: {
        required: true,
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      runDisplayName: {
        required: true,
        serializedName: "runDisplayName",
        type: {
          name: "String"
        }
      },
      runID: {
        required: true,
        serializedName: "runID",
        type: {
          name: "Uuid"
        }
      },
      taskID: {
        required: true,
        serializedName: "taskID",
        type: {
          name: "Uuid"
        }
      },
      taskName: {
        required: true,
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      taskNumber: {
        required: true,
        serializedName: "taskNumber",
        type: {
          name: "Number"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      descriptionPieces: {
        serializedName: "descriptionPieces",
        type: {
          name: "Composite",
          className: "TaskDescriptionPieces"
        }
      },
      assignedTo: {
        serializedName: "assignedTo",
        type: {
          name: "String"
        }
      },
      completedBy: {
        serializedName: "completedBy",
        type: {
          name: "String"
        }
      },
      roleID: {
        required: true,
        serializedName: "roleID",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "Role"
        }
      },
      maximumDuration: {
        serializedName: "maximumDuration",
        type: {
          name: "String"
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "DateTime"
        }
      },
      priority: {
        required: true,
        serializedName: "priority",
        type: {
          name: "String"
        }
      },
      requiredPreviousTasks: {
        required: true,
        serializedName: "requiredPreviousTasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      previousTasks: {
        required: true,
        serializedName: "previousTasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      requiredResolvedTasks: {
        serializedName: "requiredResolvedTasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      conditions: {
        required: true,
        serializedName: "conditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      writableFields: {
        serializedName: "writableFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      status: {
        required: true,
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "DateTime"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "DateTime"
        }
      },
      isTerminal: {
        serializedName: "isTerminal",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isOverdue: {
        serializedName: "isOverdue",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isAdHoc: {
        serializedName: "isAdHoc",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      appStep: {
        serializedName: "appStep",
        type: {
          name: "Composite",
          className: "AppStepConfiguration"
        }
      },
      files: {
        serializedName: "files",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "File"
            }
          }
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        type: {
          name: "String"
        }
      },
      webhookID: {
        serializedName: "webhookID",
        type: {
          name: "Uuid"
        }
      },
      rejectedReason: {
        serializedName: "rejectedReason",
        type: {
          name: "String"
        }
      },
      previousVersion: {
        serializedName: "previousVersion",
        type: {
          name: "String"
        }
      },
      nextVersion: {
        serializedName: "nextVersion",
        type: {
          name: "String"
        }
      },
      delayStartUntil: {
        serializedName: "delayStartUntil",
        type: {
          name: "String"
        }
      },
      failedTaskName: {
        serializedName: "failedTaskName",
        type: {
          name: "String"
        }
      },
      failedReason: {
        serializedName: "failedReason",
        type: {
          name: "String"
        }
      },
      failedReasonDetail: {
        serializedName: "failedReasonDetail",
        type: {
          name: "String"
        }
      },
      reminderIDs: {
        serializedName: "reminderIDs",
        type: {
          name: "String"
        }
      },
      runStartDate: {
        serializedName: "runStartDate",
        type: {
          name: "String"
        }
      },
      deadlinePattern: {
        serializedName: "deadlinePattern",
        type: {
          name: "String"
        }
      },
      delayStartUntilPattern: {
        serializedName: "delayStartUntilPattern",
        type: {
          name: "String"
        }
      },
      startedByID: {
        serializedName: "startedByID",
        type: {
          name: "Uuid"
        }
      },
      duration: {
        serializedName: "duration",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Run: msRest.CompositeMapper = {
  serializedName: "Run",
  type: {
    name: "Composite",
    className: "Run",
    modelProperties: {
      processDisplayName: {
        required: true,
        serializedName: "processDisplayName",
        type: {
          name: "String"
        }
      },
      processID: {
        required: true,
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      runID: {
        required: true,
        serializedName: "runID",
        type: {
          name: "Uuid"
        }
      },
      taskCount: {
        required: true,
        serializedName: "taskCount",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      owner: {
        required: true,
        serializedName: "owner",
        type: {
          name: "String"
        }
      },
      maximumDuration: {
        serializedName: "maximumDuration",
        type: {
          name: "String"
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "DateTime"
        }
      },
      priority: {
        required: true,
        serializedName: "priority",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "String"
        }
      },
      tasks: {
        serializedName: "tasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Task"
            }
          }
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Field"
            }
          }
        }
      },
      writableFields: {
        serializedName: "writableFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      startDate: {
        required: true,
        serializedName: "startDate",
        type: {
          name: "DateTime"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "DateTime"
        }
      },
      thumbnail: {
        serializedName: "thumbnail",
        type: {
          name: "String"
        }
      },
      files: {
        serializedName: "files",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "File"
            }
          }
        }
      },
      threadID: {
        required: true,
        serializedName: "threadID",
        type: {
          name: "String"
        }
      },
      abortedBy: {
        serializedName: "abortedBy",
        type: {
          name: "String"
        }
      },
      abortedDate: {
        serializedName: "abortedDate",
        type: {
          name: "DateTime"
        }
      },
      percentComplete: {
        serializedName: "percentComplete",
        constraints: {
          InclusiveMaximum: 1,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      percentTimeElapsed: {
        serializedName: "percentTimeElapsed",
        constraints: {
          InclusiveMaximum: 1,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      waitingCount: {
        serializedName: "waitingCount",
        type: {
          name: "Number"
        }
      },
      completedCount: {
        serializedName: "completedCount",
        type: {
          name: "Number"
        }
      },
      runningCount: {
        serializedName: "runningCount",
        type: {
          name: "Number"
        }
      },
      overdueCount: {
        serializedName: "overdueCount",
        type: {
          name: "Number"
        }
      },
      deadlineWarning: {
        serializedName: "deadlineWarning",
        type: {
          name: "String"
        }
      },
      isAdHoc: {
        serializedName: "isAdHoc",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isOverdue: {
        serializedName: "isOverdue",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isTest: {
        serializedName: "isTest",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      callbackUrl: {
        serializedName: "callbackUrl",
        type: {
          name: "String"
        }
      },
      ownerEmail: {
        serializedName: "ownerEmail",
        type: {
          name: "String"
        }
      },
      ownerFullName: {
        serializedName: "ownerFullName",
        type: {
          name: "String"
        }
      },
      progressSecret: {
        serializedName: "progressSecret",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "String"
        }
      },
      skipReporting: {
        serializedName: "skipReporting",
        type: {
          name: "String"
        }
      },
      skipTable: {
        serializedName: "skipTable",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      skippedCount: {
        serializedName: "skippedCount",
        type: {
          name: "Number"
        }
      },
      triggerID: {
        serializedName: "triggerID",
        type: {
          name: "String"
        }
      },
      testMode: {
        serializedName: "testMode",
        type: {
          name: "String"
        }
      },
      adminUsers: {
        serializedName: "adminUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      standardUsers: {
        serializedName: "standardUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      parentTaskID: {
        serializedName: "parentTaskID",
        type: {
          name: "Uuid"
        }
      },
      rootRunID: {
        serializedName: "rootRunID",
        type: {
          name: "Uuid"
        }
      },
      parentRunID: {
        serializedName: "parentRunID",
        type: {
          name: "Uuid"
        }
      },
      runVisibility: {
        serializedName: "runVisibility",
        type: {
          name: "String"
        }
      },
      runVisibleToUsers: {
        serializedName: "runVisibleToUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      fieldVisibility: {
        serializedName: "fieldVisibility",
        type: {
          name: "String"
        }
      },
      fieldVisibleToUsers: {
        serializedName: "fieldVisibleToUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      milestone: {
        serializedName: "milestone",
        type: {
          name: "String"
        }
      },
      deletionEta: {
        serializedName: "deletionEta",
        type: {
          name: "DateTime"
        }
      },
      duration: {
        serializedName: "duration",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StepPartial: msRest.CompositeMapper = {
  serializedName: "StepPartial",
  type: {
    name: "Composite",
    className: "StepPartial",
    modelProperties: {
      processName: {
        serializedName: "processName",
        type: {
          name: "String"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      stepID: {
        serializedName: "stepID",
        type: {
          name: "Uuid"
        }
      },
      stepName: {
        serializedName: "stepName",
        type: {
          name: "String"
        }
      },
      stepNumber: {
        serializedName: "stepNumber",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      maximumDuration: {
        serializedName: "maximumDuration",
        type: {
          name: "String"
        }
      },
      deadlinePattern: {
        serializedName: "deadlinePattern",
        type: {
          name: "String"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "String"
        }
      },
      requiredPreviousSteps: {
        serializedName: "requiredPreviousSteps",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      previousSteps: {
        serializedName: "previousSteps",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      requiredResolvedSteps: {
        serializedName: "requiredResolvedSteps",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      writableFields: {
        serializedName: "writableFields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      roleID: {
        serializedName: "roleID",
        type: {
          name: "Uuid"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "RolePartial"
        }
      },
      isTerminal: {
        serializedName: "isTerminal",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      isAdHoc: {
        serializedName: "isAdHoc",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      appStep: {
        serializedName: "appStep",
        type: {
          name: "Composite",
          className: "AppStepConfiguration"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        type: {
          name: "String"
        }
      },
      delayStartUntil: {
        serializedName: "delayStartUntil",
        type: {
          name: "String"
        }
      },
      delayStartUntilPattern: {
        serializedName: "delayStartUntilPattern",
        type: {
          name: "String"
        }
      },
      dependencyType: {
        serializedName: "dependencyType",
        defaultValue: 'startsAfterPreviousStep',
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Step: msRest.CompositeMapper = {
  serializedName: "Step",
  type: {
    name: "Composite",
    className: "Step",
    modelProperties: {
      ...StepPartial.type.modelProperties
    }
  }
};

export const Table: msRest.CompositeMapper = {
  serializedName: "Table",
  type: {
    name: "Composite",
    className: "Table",
    modelProperties: {
      tableID: {
        required: true,
        serializedName: "tableID",
        type: {
          name: "Uuid"
        }
      },
      displayName: {
        required: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      tableType: {
        serializedName: "tableType",
        type: {
          name: "String"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdDate: {
        required: true,
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        type: {
          name: "String"
        }
      },
      modifiedDate: {
        serializedName: "modifiedDate",
        type: {
          name: "DateTime"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        defaultValue: false,
        type: {
          name: "Boolean"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Field"
            }
          }
        }
      },
      visibility: {
        serializedName: "visibility",
        defaultValue: 'public',
        type: {
          name: "String"
        }
      },
      visibleToUsers: {
        serializedName: "visibleToUsers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const TeamSlackIntegration: msRest.CompositeMapper = {
  serializedName: "Team-slackIntegration",
  type: {
    name: "Composite",
    className: "TeamSlackIntegration",
    modelProperties: {
      slackToken: {
        serializedName: "slackToken",
        type: {
          name: "String"
        }
      },
      slackTeam: {
        serializedName: "slackTeam",
        type: {
          name: "String"
        }
      },
      slackTeamID: {
        serializedName: "slackTeamID",
        type: {
          name: "String"
        }
      },
      integrationUser: {
        serializedName: "integrationUser",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Team: msRest.CompositeMapper = {
  serializedName: "Team",
  type: {
    name: "Composite",
    className: "Team",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      thumbnailUrl: {
        serializedName: "thumbnailUrl",
        type: {
          name: "String"
        }
      },
      emailImageBrandingUrl: {
        serializedName: "emailImageBrandingUrl",
        type: {
          name: "String"
        }
      },
      accentColor: {
        serializedName: "accentColor",
        type: {
          name: "String"
        }
      },
      allowedCIDRs: {
        serializedName: "allowedCIDRs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      ownerUsername: {
        serializedName: "ownerUsername",
        type: {
          name: "Object"
        }
      },
      ownerEmail: {
        serializedName: "ownerEmail",
        type: {
          name: "String"
        }
      },
      slackToken: {
        serializedName: "slackToken",
        type: {
          name: "String"
        }
      },
      samlConfig: {
        serializedName: "samlConfig",
        type: {
          name: "Composite",
          className: "SAMLSSOConfiguration"
        }
      },
      slackIntegration: {
        serializedName: "slackIntegration",
        type: {
          name: "Composite",
          className: "TeamSlackIntegration"
        }
      },
      isInternalTeam: {
        serializedName: "isInternalTeam",
        type: {
          name: "Boolean"
        }
      },
      sandboxFor: {
        serializedName: "sandboxFor",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      requireTlsOnEmails: {
        serializedName: "requireTlsOnEmails",
        type: {
          name: "String"
        }
      },
      emailIpAddresses: {
        serializedName: "emailIpAddresses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      emailDomain: {
        serializedName: "emailDomain",
        type: {
          name: "String"
        }
      },
      emailLocalPart: {
        serializedName: "emailLocalPart",
        type: {
          name: "String"
        }
      },
      buildingAllowedByDefault: {
        serializedName: "buildingAllowedByDefault",
        type: {
          name: "Boolean"
        }
      },
      allowedEmbeddedDomains: {
        serializedName: "allowedEmbeddedDomains",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      restrictInvitesToAdmins: {
        serializedName: "restrictInvitesToAdmins",
        type: {
          name: "Boolean"
        }
      },
      iconUrl: {
        serializedName: "iconUrl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Webhook: msRest.CompositeMapper = {
  serializedName: "Webhook",
  type: {
    name: "Composite",
    className: "Webhook",
    modelProperties: {
      webhookID: {
        required: true,
        serializedName: "webhookID",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        required: true,
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdDate: {
        required: true,
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      integrationUser: {
        serializedName: "integrationUser",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      params: {
        serializedName: "params",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      webhookUrl: {
        serializedName: "webhookUrl",
        type: {
          name: "String"
        }
      },
      webhookType: {
        serializedName: "webhookType",
        type: {
          name: "String"
        }
      },
      processID: {
        serializedName: "processID",
        type: {
          name: "Uuid"
        }
      },
      processName: {
        serializedName: "processName",
        type: {
          name: "String"
        }
      },
      processDisplayName: {
        serializedName: "processDisplayName",
        type: {
          name: "String"
        }
      },
      runID: {
        serializedName: "runID",
        type: {
          name: "Uuid"
        }
      },
      runDisplayName: {
        serializedName: "runDisplayName",
        type: {
          name: "String"
        }
      },
      taskID: {
        serializedName: "taskID",
        type: {
          name: "Uuid"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      taskDisplayName: {
        serializedName: "taskDisplayName",
        type: {
          name: "String"
        }
      },
      appName: {
        serializedName: "appName",
        type: {
          name: "String"
        }
      },
      appAction: {
        serializedName: "appAction",
        type: {
          name: "String"
        }
      },
      appVersion: {
        serializedName: "appVersion",
        type: {
          name: "String"
        }
      },
      appID: {
        serializedName: "appID",
        type: {
          name: "String"
        }
      },
      appDisplayName: {
        serializedName: "appDisplayName",
        type: {
          name: "String"
        }
      },
      isPublic: {
        serializedName: "isPublic",
        type: {
          name: "Boolean"
        }
      },
      runStartDate: {
        serializedName: "runStartDate",
        type: {
          name: "String"
        }
      },
      webformUrl: {
        serializedName: "webformUrl",
        type: {
          name: "String"
        }
      },
      webhookName: {
        serializedName: "webhookName",
        type: {
          name: "String"
        }
      },
      softDeleted: {
        serializedName: "softDeleted",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FieldPartialItems: msRest.CompositeMapper = {
  serializedName: "FieldPartial-items",
  type: {
    name: "Composite",
    className: "FieldPartialItems",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const TableFileReference: msRest.CompositeMapper = {
  serializedName: "TableFileReference",
  type: {
    name: "Composite",
    className: "TableFileReference",
    modelProperties: {
      fileID: {
        required: true,
        serializedName: "fileID",
        type: {
          name: "Uuid"
        }
      },
      headerRowNumber: {
        required: true,
        serializedName: "headerRowNumber",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TableCopy: msRest.CompositeMapper = {
  serializedName: "TableCopy",
  type: {
    name: "Composite",
    className: "TableCopy",
    modelProperties: {
      sourceTableID: {
        serializedName: "sourceTableID",
        type: {
          name: "Uuid"
        }
      },
      tableType: {
        serializedName: "tableType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetWorkerTaskFileHeaders: msRest.CompositeMapper = {
  serializedName: "get-worker-task-file-headers",
  type: {
    name: "Composite",
    className: "GetWorkerTaskFileHeaders",
    modelProperties: {
      cacheControl: {
        serializedName: "cache-control",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "content-type",
        type: {
          name: "String"
        }
      },
      contentDisposition: {
        serializedName: "content-disposition",
        type: {
          name: "String"
        }
      },
      contentLength: {
        serializedName: "content-length",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SamlLoginHeaders: msRest.CompositeMapper = {
  serializedName: "saml-login-headers",
  type: {
    name: "Composite",
    className: "SamlLoginHeaders",
    modelProperties: {
      location: {
        serializedName: "location",
        type: {
          name: "String"
        }
      }
    }
  }
};
