import { useState, useCallback, useEffect, useRef, useMemo, useReducer } from 'react';

export interface UseListProps<I = any> {
  items?: I[]
}

export interface UseListAddProps<I = any> {
  item: I,
  index?: number
}

export interface UseListUpdateProps<I = any> {
  item: I,
  index: number
}

export interface UseListRemoveProps {
  index?: number
}

export interface UseListMethods<I = any> {
  update: ({ index: number, item: I }) => I[]
  add: (props: UseListAddProps<I>) => I[]
  remove: (props: UseListRemoveProps) => I[]
}

export interface UseListState<I = any> extends UseListMethods<I> {
  items: I[]
}

export const useList = <I = any>(items: I[] = []): UseListState<I> => {
  const [, update] = useReducer(n => n + 1, 0);
  const list = useRef<I[]>(items);
  const actions = useMemo<UseListMethods<I>>(
    () => ({
      add: ({ index, item }) => {
        const next = list.current.slice();

        if (index !== undefined) {
          next.splice(index, 0, item);
        } else {
          next.push(item)
        }
        list.current = next;
        update();
        return next
      },
      remove: ({ index }) => {
        const next = list.current.slice();
        if (index !== undefined) {
          next.splice(index, 1);
        } else {
          next.pop()
        }
        list.current = next;
        update();
        return next
      },
      update: ({ index, item }) => {
        const next = list.current.slice();
        next.splice(index, 1, item);
        list.current = next;
        update();
        return next;
      }
    }),
    []
  )
  return { items: list.current, ...actions };
}
