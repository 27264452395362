import React from 'react';
import { types } from '@catalytic/react-view';
import { Text, TextInput } from 'grommet';
import { FormField } from '../../components/FormField';
import { useLocaleInteger } from './IntegerView.hooks';

export function IntegerViewEditor(props: types.IntegerView) {
  return <TextInput
    disabled={props.node().disabled}
    name={props.node().reference}
    type="number"
    onChange={(e: any) => {
      e.stopPropagation();
      props.setValue(e.target.value);
    }}
    value={props.value ?? props.defaultValue() ?? ''} />
}

export function IntegerViewReader(props: types.IntegerView) {
  const value = useLocaleInteger(props);
  return <Text>{value}</Text>
}

export function IntegerView(props: types.IntegerView) {
  const { children, ...others } = props;
  return <FormField {...others}>
    {props.node().role === 'reader'
      ? <IntegerViewReader {...props} />
      : <IntegerViewEditor {...props} />}
  </FormField>
}
