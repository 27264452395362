import { Clause, identifier, literal, operation, OperatorType } from '@catalytic/query';
import { Button } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FromMap, WhereClause } from '../../query/query.interfaces';
import { toWhereClause } from '../../query/query.transforms';
import { useColumns } from './Column.hooks';
import { FilterItem } from './FilterItem';
import { List } from './List';
import { useList } from './List.hooks';
import { ListContainer } from './ListContainer';
import { ListFooter } from './ListFooter';
import { CollectionViewProps } from './View.interfaces';

export interface FilterListChangeEvent {
  operation: WhereClause[]
}

export interface FilterListProps extends CollectionViewProps {
  fromMap?: FromMap,
  operation?: Clause[],
  onChange?: (event: FilterListChangeEvent) => void
}

export const FilterList = (props: FilterListProps) => {
  const [items, setItems] = useState<WhereClause[]>(toWhereClause(props.operation ?? [], undefined, props.fromMap));
  const { add, items: operations, remove, update } = useList(items);

  useEffect(
    () => {
      setItems(toWhereClause(props.operation ?? [], undefined, props.fromMap))
    },
    [props.fromMap, props.operation]
  )
  const columns = useColumns(props);

  const handleChange = useCallback(
    ({ operation }: { operation: WhereClause[] }) => {
      if (props.onChange) {
        props.onChange({ operation });
      }
    },
    [props.onChange]
  );
  const handleItemChange = useCallback<(({ index: number, where: WhereClause }) => void)>(
    ({ index, where }) => {
      handleChange({ operation: update({ index, item: where }) })
    },
    []
  )
  const handleItemAdd = useCallback(
    () => {
      const [column] = columns;
      const item: WhereClause = {
        clause: operation({
          operation: OperatorType.EQ,
          left: identifier({
            name: column.value
          }),
          right: literal({
            value: ''
          })
        }),
        operation: operations.length ? OperatorType.AND : undefined
      }
      handleChange({
        operation: add({ item })
      })
    },
    [columns, operations]
  );
  const handleItemRemove = useCallback(
    ({ index }: { index: number }) => {
      handleChange({ operation: remove({ index }) })
    },
    []
  );

  return <List width={{ min: "52.5em", max: "52.5em" }}>
    <ListContainer>
      {
        operations.map(
          (operation, index) =>
            <FilterItem key={index} columns={columns} index={index} where={operation} onChange={
              ({ where }) => handleItemChange({ index, where })
            } onRemove={() => handleItemRemove({ index })} />
        )
      }
    </ListContainer>
    <ListFooter>
      <Button icon={<Add />} label="Add a filter" onClick={handleItemAdd} />
    </ListFooter>
  </List >
};
