import { factory } from 'mathjs';

export const createEqual = factory(
  'equal',
  [],
  () =>
    function equal(a: any, b: any): boolean {
      return a === b;
    }
);

export const createUnequal = factory(
  'unequal',
  [],
  () =>
    function unequal(a: any, b: any): boolean {
      return a !== b;
    }
);

export const createSmaller = factory(
  'smaller',
  [],
  () =>
    function smaller(a: any, b: any): boolean {
      return a < b;
    }
);

export const createSmallerEq = factory(
  'smallerEq',
  [],
  () =>
    function smallerEq(a: any, b: any): boolean {
      return a <= b;
    }
);

export const createLarger = factory(
  'larger',
  [],
  () =>
    function larger(a: any, b: any): boolean {
      return a > b;
    }
);

export const createLargerEq = factory(
  'largerEq',
  [],
  () =>
    function largerEq(a: any, b: any): boolean {
      return a >= b;
    }
);

export const createCompare = factory(
  'compare',
  [],
  () =>
    function compare(a: any, b: any): number {
      return a > b ? 1 : a < b ? -1 : 0;
    }
);
