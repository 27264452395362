import clone from 'ramda/src/clone';
import { Node } from 'unist';
import visit from 'unist-util-visit';

export const disableNode = <N extends Node = Node>(node: N): N => {
  const nextNode = clone(node);
  visit(
    nextNode,
    (node: any) => node.role === 'editor',
    (node: any) => {
      node.role = 'reader'
    }
  )
  return nextNode
}
