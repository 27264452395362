import { Text } from 'grommet';
import React from 'react';
import { types } from '@catalytic/react-view';
import { FormField } from '../../components/FormField';
import { StyledDateInput } from './DateView.styled';
import { useLocaleDate, useLocalISODate } from './DateView.hooks';
import { fromInputValue } from '../../util/toJSONValue';

export function DateViewEditor(props: types.DateView) {
  const value = useLocalISODate(props);
  return <StyledDateInput
    buttonProps={{
      disabled: props.node().disabled
    }}
    inputProps={{
      disabled: props.node().disabled
    }}
    dropProps={{ stretch: false }}
    name={props.node().reference}
    format="mm/dd/yyyy"
    onChange={(e: any) => {
      props.setValue(fromInputValue(e.value, props.schema()));
    }}
    value={value} />
}

export function DateViewReader(props: types.DateView) {
  const value = useLocaleDate(props);
  return <Text>{value}</Text>
}

export function DateView(props: types.DateView) {
  const { children, ...others } = props;
  return <FormField {...others} >
    {props.node().role === 'reader'
      ? <DateViewReader {...props} />
      : <DateViewEditor {...props} />}
  </FormField>
}
