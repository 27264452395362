import { css } from 'styled-components';
import { InputKindType } from '../theme/theme.interfaces';
import { applyFontStyle } from './style.font';
import { applyForegroundBackgroundStyle } from './style.foreground-background';
import { StyleFactory } from "./style.interfaces";
import { applyMarginStyle, applyPadStyle } from './style.edge';
import { applyBorderStyle } from './style.border';
import { createStyleFactory } from './style.factories';
import { mergeKind } from './style.utils';

const applyInputBorderStyle = createStyleFactory({
  applyStyle: applyBorderStyle,
  getKind: ({ theme }) =>
    mergeKind(theme?.components?.input, theme?.global?.input, theme?.global?.control)
})

const applyInputPadStyle = createStyleFactory({
  applyStyle: applyPadStyle,
  getKind: ({ theme }) =>
    mergeKind(
      theme?.components?.input,
      theme?.global?.input?.padding
        ? { pad: theme.global.input.padding }
        : undefined
    )
})

const applyInputFontStyle = createStyleFactory({
  applyStyle: applyFontStyle,
  getKind: ({ theme }) =>
    mergeKind(theme?.components?.input, theme?.global?.input, theme?.global?.control)
})

export const applyInputStyle: StyleFactory<InputKindType> = (props, kind, fragment) =>
  css`
${applyForegroundBackgroundStyle(props, kind, fragment)}
${applyInputFontStyle(props, kind, fragment)}
${applyMarginStyle(props, kind, fragment)}
${applyInputPadStyle(props, kind, fragment)}
${applyInputBorderStyle(props, kind, fragment)}
`
