import moo, { Rules } from "moo";
import { ALIAS, STAR, STRIP_OUTER_QUOTES } from "./lexer.constants";
import {
  BinaryOperator,
  ComparisonOperator,
  Direction,
  Lexer,
  MapVariant,
  ResultFlag,
  Statement,
  Type,
} from "./lexer.interfaces";

const KNOWN_IDENTIFIERS = [
  ALIAS,
  ...Object.values(Direction),
  ...Object.values(MapVariant),
  ...Object.values(ResultFlag),
  ...Object.values(Statement),
  ...Object.values(BinaryOperator),
  ...Object.values(ComparisonOperator),
  STAR,
].reduce((o, key) => {
  o[key] = true;
  return o;
}, {});

const identifierKeywords = moo.keywords({
  [Type.ALIAS]: [ALIAS],
  [Type.DIRECTION]: Object.values(Direction),
  [Type.MAP]: Object.values(MapVariant).filter((v) => v !== MapVariant.FROM),
  [Type.RESULT]: Object.values(ResultFlag),
  [Type.STATEMENT_BY]: [Statement.BY],
  [Type.STATEMENT_FROM]: [MapVariant.FROM],
  [Type.STATEMENT_LIMIT]: [Statement.LIMIT],
  [Type.STATEMENT_OFFSET]: [Statement.OFFSET],
  [Type.STATEMENT_ORDER]: [Statement.ORDER],
  [Type.STATEMENT_SELECT]: [Statement.SELECT],
  [Type.STATEMENT_WHERE]: [Statement.WHERE],
  [Type.OPERATOR_BINARY]: Object.values(BinaryOperator),
  [Type.OPERATOR_COMPARISON]: Object.values(ComparisonOperator),
  [Type.STAR]: [STAR],
});

const identifierType = (x: string): string =>
  identifierKeywords(x.toUpperCase());

export const rules: Rules = {
  [Type.WHITESPACE]: { match: /[\n\v\f\r\t ]+/, lineBreaks: true },
  [Type.LITERAL]: [
    {
      match: /"(?:\\["\\]|[^\n"\\])*"|'(?:\\['\\]|[^\n'\\])*'/,
      value: (x: string): string => x.replace(STRIP_OUTER_QUOTES, "$1"),
    },
    {
      match: /-?[\d]+(?:\.[\d]+)?/,
      value: (x: string): string => JSON.parse(x),
    },
    {
      match: /true|false/,
      value: (x: string): string => JSON.parse(x.toLowerCase()),
    },
    {
      match: /[Nn][Uu][Ll][Ll]/,
      value: (): null => null,
    },
  ],
  [Type.ACCESSOR]: ".",
  [Type.ACCESSOR_CLOSE]: "]",
  [Type.ACCESSOR_OPEN]: "[",
  [Type.LIST_DELIMETER]: ",",
  [Type.LIST_CLOSE]: ")",
  [Type.LIST_OPEN]: "(",
  [Type.IDENTIFIER]: {
    match: /(?:[iI][sS] [Nn][Oo][Tt])|(?:[Nn][Oo][Tt] [a-zA-Z]+)|(?:[a-zA-Z_]+)|(?:[a-zA-Z_][a-zA-Z0-9_]+)|(?:[=><!]+)|(?:\*)/,
    type: identifierType,
    value: (x: string): string => {
      const id = x.toUpperCase();
      return KNOWN_IDENTIFIERS[id] ? id : x;
    },
  },
  [Type.TERMINATOR]: ";",
};

export const compile = (): Lexer => moo.compile(rules) as any;
