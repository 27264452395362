import * as React from "react";
import pointer from "json-pointer";
import { ExpressionContext as ExpressionContextObject } from "@catalytic/expression";
import {
  JSONSchema,
  JSONSchemaValidateFunction,
  JSONValue,
} from "@catalytic/json-schema-validator";
import { Role } from "@catalytic/view";
import ViewContext, { ViewContextProps } from "./context";
import ValidatorContext from "./validator";
import { GenericNode, NodeProps, NodeEvent, NodeEventType } from "./type";
import createNextID from "./nextID";
import ExpressionContext from "./expression";

const nextID = createNextID({ prefix: "view" });

const tryCatch = <I extends any[], O>(fn: (...args: I) => O) => (
  ...args: I
): O | undefined => {
  try {
    return fn(...args);
  } catch (e) {}
};

const pointerGet = tryCatch<[any, string], any>(pointer.get);

const pointerSet = tryCatch<[any, string, any], any>(pointer.set);

export interface ViewUpdateEvent {
  value?: JSONValue;
  valueReference?: string;
}

export interface ViewEvent<N extends GenericNode = GenericNode>
  extends Omit<
    NodeProps<N>,
    "dispatch" | "children" | "items" | "options" | "optionValues" | "setValue"
  > {
  type: string;
}

export interface ViewProps<N extends GenericNode = GenericNode> {
  defaultValue?: any;
  onEvent?: (event: ViewEvent) => void;
  node: N;
  onUpdate?: (event: ViewUpdateEvent) => void;
  optionValueReference?: string;
  reference?: string;
  schema?: JSONSchema;
  setValue?: (value: JSONValue) => void;
  validator?: () => JSONSchemaValidateFunction;
  value?: JSONValue;
  valueReference?: string;
  visible?: () => boolean;
}

export type UseViewHook<N extends GenericNode = GenericNode> = {
  context: ViewContextProps;
  isControlled: () => boolean;
  getComponent: () => React.ReactType<NodeProps<N>> | null;
  getDefaultValue: () => any;
  getDispatcher: (event: NodeEvent) => void;
  getNode: () => N;
  getReference: () => string;
  getSchema: () => JSONSchema | undefined;
  getValidator: () => JSONSchemaValidateFunction;
  getVisibility: () => boolean;
  render: () => React.ReactElement | null;
  renderChildren: () => any;
  renderItems: () => any;
  renderOptions: () => any;
  setValue: (value: JSONValue) => void;
  value: JSONValue;
};

export const useView = (props: ViewProps): UseViewHook => {
  const {
    defaultValue,
    node,
    onEvent,
    onUpdate,
    optionValueReference,
    schema,
    setValue: propSetValue,
    reference = "",
    value: propValue,
    valueReference: propValueReference,
  } = props;

  const [id] = React.useState(nextID());

  const context = React.useContext(ViewContext);
  const expression = React.useContext(ExpressionContext);
  const validator = React.useContext(ValidatorContext);

  const getNode = React.useCallback<() => GenericNode>(
    () => pointerGet(node, reference),
    [reference, node]
  );

  const getComponent = React.useCallback(
    () => context[getNode().role || Role.EDITOR][getNode().type],
    [context, getNode]
  );

  const {
    children = [],
    items,
    options,
    optionValues,
    reference: valueReference,
    schemaReference = "",
    value: nodeValue,
  } = getNode();

  const getReferenceParts = React.useCallback(
    (): any[] => pointer.parse(reference),
    [reference, getNode]
  );

  const getSchemaReferenceParts = React.useCallback(
    (): any[] => pointer.parse(schemaReference),
    [schemaReference, getNode]
  );

  const hasValueReference = React.useCallback(
    (): boolean =>
      typeof propValueReference !== "undefined" ||
      typeof valueReference !== "undefined",
    [propValueReference, valueReference, getNode]
  );

  const getValueReferenceParts = React.useCallback(
    (): any[] =>
      hasValueReference()
        ? pointer.parse(propValueReference || valueReference)
        : [],
    [propValueReference, valueReference, getNode]
  );

  const hasOptionValueReference = React.useCallback(
    (): boolean => typeof optionValueReference !== "undefined",
    [propValueReference, valueReference, getNode]
  );

  const getReference = React.useCallback(
    (...parts: any[]): string =>
      parts.length
        ? pointer.compile(getReferenceParts().concat(parts))
        : reference,
    [getReferenceParts]
  );

  const getSchemaReference = React.useCallback(
    (...parts: any[]): string =>
      parts.length
        ? pointer.compile(getSchemaReferenceParts().concat(parts))
        : schemaReference,
    [getSchemaReferenceParts]
  );

  const getValueReference = React.useCallback(
    (...parts: any[]): string =>
      parts.length
        ? pointer.compile(getValueReferenceParts().concat(parts))
        : propValueReference || valueReference,
    [getValueReferenceParts]
  );

  const getSchema = React.useCallback<() => JSONSchema | undefined>(
    () =>
      schema !== undefined
        ? pointerGet(schema, getSchemaReference())
        : undefined,
    [getNode, getSchemaReference]
  );

  const getDefaultValue = React.useCallback(
    () => (defaultValue !== undefined ? defaultValue : getSchema()?.default),
    [defaultValue, getSchema]
  );

  const isControlled = React.useCallback(
    () =>
      propValue !== undefined ||
      nodeValue !== undefined ||
      typeof propSetValue === "function",
    [propValue, nodeValue]
  );

  const getPropValue = React.useCallback(
    () =>
      propValue !== undefined
        ? propValue
        : nodeValue !== undefined
        ? nodeValue
        : getDefaultValue(),
    [propValue, nodeValue]
  );

  const [renderCount, setRenderCount] = React.useState(0);

  const getValue = React.useCallback(
    () =>
      getPropValue() !== undefined &&
      hasValueReference() &&
      pointer.has(getPropValue(), getValueReference())
        ? pointerGet(getPropValue(), getValueReference())
        : hasOptionValueReference()
        ? getPropValue()
        : nodeValue,
    [getPropValue, getDefaultValue, getReference]
  );

  const [stateValue, setStateValue] = React.useState(getValue());

  const rerender = React.useCallback(() => setRenderCount(renderCount + 1), [
    renderCount,
  ]);

  const getStateValue = React.useCallback(
    () => (isControlled() ? getPropValue() : stateValue),
    [stateValue, getPropValue]
  );

  const value = isControlled() ? getValue() : getStateValue();

  const getValidator = React.useCallback(
    () => validator.compile(getSchema() || { type: "any" }),
    [getSchema, validator]
  );

  const getExpressionContext = React.useCallback<() => ExpressionContextObject>(
    () => ({
      fields: getPropValue(),
      this: getValue(),
    }),
    [getValue, getPropValue]
  );

  const getSchemaVisibility = React.useCallback<() => string | undefined>(
    () => getSchema()?.conditionalDependencies,
    [getSchema]
  );

  const getNodeVisibility = React.useCallback<
    () => string | boolean | undefined
  >(() => getNode().visible, [getNode]);

  const getVisibility = React.useCallback<() => boolean>(() => {
    const nodeVisibility = getNodeVisibility();
    const source: string =
      nodeVisibility === undefined
        ? getSchemaVisibility() || "true"
        : typeof nodeVisibility === "boolean"
        ? JSON.stringify(nodeVisibility)
        : nodeVisibility;
    try {
      return !!expression.evaluate(source, getExpressionContext());
    } catch (e) {
      return true;
    }
  }, [
    expression,
    getExpressionContext,
    getSchemaVisibility,
    getNodeVisibility,
  ]);

  const getDispatcher = React.useCallback<(event: NodeEvent) => void>(
    (event: NodeEvent) => {
      if (!onEvent) return;
      onEvent({
        ...event,
        defaultValue: getDefaultValue,
        node: getNode,
        reference: getReference,
        schema: getSchema,
        value: value,
        valueReference: getValueReference,
        validator: getValidator,
        visible: getVisibility,
      });
    },
    [
      onEvent,
      getDefaultValue,
      getNode,
      getReference,
      getSchema,
      getValueReference,
      value,
      getValidator,
      getVisibility,
    ]
  );

  const dispatchUpdate = React.useCallback(
    (event: ViewUpdateEvent) => {
      if (typeof onUpdate === "function") {
        onUpdate(event);
      }
      getDispatcher({
        type: NodeEventType.Update,
        ...event,
      });
    },
    [onUpdate, getDispatcher]
  );

  const setValue = React.useCallback(
    (nextValue: JSONValue) => {
      // Dispatch update event
      dispatchUpdate({
        value: nextValue,
        valueReference: getValueReference(),
      });

      // If not controlled then update state
      if (!isControlled()) {
        setStateValue(nextValue);

        // React will not track mutations in non-literals, so rerender
        // via a utility state update
        if (typeof nextValue === "object") {
          rerender();
        }
        return;
      }

      // If set value prop is defined, then call it
      if (typeof propSetValue === "function") {
        if (hasValueReference()) {
          // Copy prop value if it is an object, since it will be read only
          const nextPropValue =
            typeof getPropValue() === "object" &&
            getPropValue() &&
            !Array.isArray(getPropValue())
              ? Object.assign({}, getPropValue())
              : getPropValue();
          pointerSet(nextPropValue, getValueReference(), nextValue);
          propSetValue(nextPropValue);
        } else {
          propSetValue(nextValue);
        }
      }
    },
    [isControlled, getValue, propSetValue]
  );

  const getSetValueSetter = React.useCallback(
    () => (typeof propSetValue === "function" ? propSetValue : setValue),
    [propSetValue, setValue]
  );

  const getOptionSchemaReferenceParts = React.useCallback(
    (): any[] =>
      getSchemaReferenceParts().concat(
        pointer.parse(options?.schemaReference || "")
      ),
    [options, getNode]
  );

  const getOptionSchemaReference = React.useCallback(
    (...parts: any[]): string =>
      parts.length
        ? pointer.compile(getOptionSchemaReferenceParts().concat(parts))
        : pointer.compile(getOptionSchemaReferenceParts()),
    [getOptionSchemaReferenceParts]
  );

  const getOptionSchema = React.useCallback(
    () =>
      schema !== undefined && options?.schemaReference !== undefined
        ? pointerGet(schema, getOptionSchemaReference())
        : undefined,
    [getNode, getOptionSchemaReference]
  );

  const getOptionValues = React.useCallback(
    () => getOptionSchema() || optionValues,
    [getSchema, optionValues, options]
  );

  const renderChildren = React.useCallback(
    () =>
      children.map((_v: any, i: number) => {
        return (
          <View
            key={`${id}-child-${i}`}
            reference={getReference("children", i)}
            node={node}
            onEvent={onEvent}
            onUpdate={onUpdate}
            schema={schema}
            setValue={getSetValueSetter()}
            value={getStateValue()}
            validator={getValidator}
            visible={getVisibility}
          />
        );
      }),
    [
      children,
      value,
      getValueReference,
      getStateValue,
      getSetValueSetter,
      getReference,
      getValidator,
      getVisibility,
    ]
  );

  const renderItems = React.useCallback(() => {
    return Array.isArray(value) && items
      ? value.map((_v: any, i: number) => (
          <View
            key={`${id}-item-${i}`}
            reference={getReference("items")}
            node={node}
            onEvent={onEvent}
            onUpdate={onUpdate}
            setValue={getSetValueSetter()}
            value={getStateValue()}
            valueReference={getValueReference(i)}
            validator={getValidator}
            visible={getVisibility}
          />
        ))
      : null;
  }, [
    items,
    value,
    getValueReference,
    getStateValue,
    getSetValueSetter,
    getReference,
    getValidator,
    getVisibility,
  ]);

  const renderOptions = React.useCallback(
    () =>
      Array.isArray(getOptionValues()) && options
        ? getOptionValues().map((v: any, i: number) => (
            <View
              key={`${id}-option-${i}`}
              reference={getReference("options")}
              node={node}
              onEvent={onEvent}
              onUpdate={onUpdate}
              optionValueReference={`/${i}`}
              schema={schema}
              setValue={getSetValueSetter()}
              value={v}
              validator={getValidator}
              visible={getVisibility}
            />
          ))
        : null,
    [
      options,
      getOptionValues,
      value,
      getValueReference,
      getStateValue,
      getSetValueSetter,
      getReference,
      getValidator,
      getVisibility,
    ]
  );

  const render = (): React.ReactElement | null => {
    const Component = getComponent();
    if (!Component) {
      return null;
    }

    return (
      <Component
        children={renderChildren}
        defaultValue={getDefaultValue}
        dispatch={getDispatcher}
        items={renderItems}
        node={getNode}
        reference={getReference}
        options={renderOptions}
        optionValues={getOptionValues}
        schema={getSchema}
        setValue={setValue}
        value={value}
        valueReference={getValueReference}
        validator={getValidator}
        visible={getVisibility}
      />
    );
  };

  return {
    context,
    isControlled,
    getComponent,
    getDefaultValue,
    getDispatcher,
    getNode,
    getReference,
    getSchema,
    getValidator,
    getVisibility,
    renderChildren,
    render,
    renderItems,
    renderOptions,
    setValue,
    value,
  };
};

export const View = (props: ViewProps): React.ReactElement | null => {
  const { render } = useView(props);
  return render();
};

View.displayName = "View";
