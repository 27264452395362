import { Workflow } from '@catalytic/sdk';
import { useState, useEffect } from 'react';
import { useCatalyticClient } from '../catalytic-client/catalytic-client.hooks';
import { UseClient, UseClientState } from '../client/client.hooks';

export const useWorkflow: UseClient = (id: string) => {
  const { instance } = useCatalyticClient();
  const [state, setState] = useState<UseClientState<Workflow>>();
  useEffect(
    () => {
      const init = async () => {
        try {
          const data = await instance.workflows.get(id);
          setState({
            loading: false,
            data
          })
        } catch (e) {
          setState({
            loading: false,
            errors: [e]
          })
        }
      };
      init();
    },
    [id]
  )
  return state
};
