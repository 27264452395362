export enum DirectionType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum OperatorType {
  AND = "AND",
  BETWEEN = "BETWEEN",
  EQ = "EQ",
  GT = "GT",
  GTE = "GTE",
  IN = "IN",
  IS = "IS",
  LIKE = "LIKE",
  LT = "LT",
  LTE = "LTE",
  NOT_BETWEEN = "NOT_BETWEEN",
  NOT_EQ = "NOT_EQ",
  NOT_IN = "NOT_IN",
  NOT_IS = "NOT_IS",
  NOT_LIKE = "NOT_LIKE",
  OR = "OR",
}

export enum NodeType {
  EXPRESSION = "EXPRESSION",
  FUNCTION = "FUNCTION",
  IDENTIFIER = "IDENTIFIER",
  LITERAL = "LITERAL",
  LIMIT = "LIMIT",
  MAP = "MAP",
  OPERATION = "OPERATION",
  ORDER = "ORDER",
  RANGE = "RANGE",
  RESULT = "RESULT",
  SELECT = "SELECT",
}

export enum MapType {
  FROM = "FROM",
  JOIN = "JOIN",
}

export interface Node<T extends NodeType = NodeType> {
  type: T;
}

export type ClauseNodeType =
  | NodeType.EXPRESSION
  | NodeType.FUNCTION
  | NodeType.IDENTIFIER
  | NodeType.LITERAL
  | NodeType.OPERATION
  | NodeType.RANGE
  | NodeType.SELECT;

export type Clause<T extends ClauseNodeType = ClauseNodeType> = Node<T>;

export interface Expression<E extends Clause[] = Clause[]>
  extends Clause<NodeType.EXPRESSION> {
  expression: E;
}

export type LiteralType =
  | boolean
  | number
  | null
  | string
  | { [number: string]: LiteralType }
  | { [key: string]: LiteralType };

export type IdentifierType = string;

export interface Alias<I extends IdentifierType = IdentifierType> {
  alias?: I;
}

export interface Func<
  I extends IdentifierType = IdentifierType,
  E extends Expression = Expression
> extends Alias<I>,
    Clause<NodeType.FUNCTION> {
  args: E;
  name: Identifier;
}

export interface Operation<
  L extends Clause = Clause,
  R extends Clause = Clause,
  O extends OperatorType = OperatorType
> extends Clause<NodeType.OPERATION> {
  left: L;
  right: R;
  operation: O;
}

export interface Range<S extends Clause = Clause, E extends Clause = Clause>
  extends Clause<NodeType.RANGE> {
  start: S;
  end: E;
}

export interface Identifier<I extends IdentifierType = IdentifierType>
  extends Alias<I>,
    Clause<NodeType.IDENTIFIER> {
  alias?: I;
  type: NodeType.IDENTIFIER;
  name: string;
}

export interface Literal<L extends LiteralType = LiteralType> extends Clause {
  type: NodeType.LITERAL;
  value: L;
}

export interface Limit<L extends Clause = Clause, O extends Clause = L>
  extends Node<NodeType.LIMIT> {
  offset?: O;
  type: NodeType.LIMIT;
  length?: L;
}

export interface Map<V extends MapType = MapType, S extends Clause = Clause>
  extends Node<NodeType.MAP> {
  source: S;
  map?: Map[];
  variant: V;
}

export interface Result<S extends Clause = Clause>
  extends Node<NodeType.RESULT> {
  source: S;
  distinct?: boolean;
  value?: boolean;
}

export interface Order<
  D extends DirectionType = DirectionType,
  E extends Clause = Clause
> extends Node<NodeType.ORDER> {
  type: NodeType.ORDER;
  direction: D;
  expression: E;
}

export interface Select<
  FROM extends Map<MapType.FROM> = Map<MapType.FROM>,
  RESULT extends Result = Result,
  WHERE extends Clause = Clause,
  LIMIT extends Limit = Limit,
  ORDER extends Order = Order,
  ALIAS extends IdentifierType = IdentifierType
> extends Alias<ALIAS>,
    Clause<NodeType.SELECT> {
  from?: FROM;
  result?: RESULT[];
  where?: WHERE[];
  order?: ORDER[];
  limit?: LIMIT;
}
