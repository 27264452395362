import { Node, NodeType, selectionView, textView } from '@catalytic/view';
import pointer from 'json-pointer';
import clone from 'ramda/src/clone';
import uniq from 'ramda/src/uniq';
import visit from 'unist-util-visit';
import { FormData, FormValue } from '../form/form.interfaces';
import { FormBinderProps, FormConfiguration } from './form-binding.interfaces';
import { format, validate } from '../template/template.utils';

export const formConfigurationGuardFactory = <TFormConfiguration extends FormConfiguration>(type: string) =>
  (configuration: FormConfiguration): configuration is TFormConfiguration =>
    configuration.type === type

export interface BindFormProps extends FormBinderProps {
  reference: string,
  results: any
}

export const bindForm = <N extends Node = Node, V = FormValue>({
  data,
  reference,
  results: resultsProp,
  configuration
}: BindFormProps): FormData<N, V> => {
  const { field = 'id' } = configuration

  const results = Array.isArray(resultsProp)
    ? uniq(resultsProp.map((item: any) => item[field]))
    : resultsProp && typeof resultsProp === 'object'
      ? resultsProp[field]
      : resultsProp

  const { node, schema, value } = clone(data)

  if (Array.isArray(results)) {
    const optionValues = results

    visit(
      node as any,
      ((node: any) => {
        return node.reference === reference
      }) as any,
      (node: Node) => {
        const options = {
          options: textView({}),
          optionValues: optionValues
        };
        const next = node.type === NodeType.MULTIPLE_SELECTION_VIEW
          || node.type === NodeType.SELECTION_VIEW
          ? options
          : selectionView(options)

        if (schema && node.schemaReference) {
          const nextSchema = node.type === NodeType.MULTIPLE_SELECTION_VIEW
            ? { type: 'array', items: { enum: optionValues } }
            : { type: 'string', enum: optionValues }
          const currentSchema = pointer.get(schema, node.schemaReference);
          pointer.set(schema, node.schemaReference, {
            ...currentSchema,
            ...nextSchema
          })
        }

        if (results.length === 1) {
          pointer.set(
            value,
            reference,
            results[0]
          );
        }

        Object.assign(
          node,
          next
        )
      }
    )
    return {
      node,
      schema,
      value
    };
  } else {
    if (value !== undefined) {
      pointer.set(
        value,
        reference,
        results
      );
    }
    return {
      node,
      schema,
      value
    };
  }
}

export const validateDeep = (value: any, data: Record<string, any>): boolean => {
  if (typeof value === 'string') {
    return validate(value, data)
  }

  if (Array.isArray(value)) {
    return value.every(value => validateDeep(value, data));
  };

  if (typeof value === 'object' && value) {
    return Object.entries(value).every(
      ([k, v]) => {
        return validate(k, data) && validateDeep(v, data);
      }
    );
  }

  return true;
};

export const formatDeep = (value: any, data: Record<string, any>): any => {
  if (typeof value === 'string') {
    return format(value, data) as any
  }

  if (Array.isArray(value)) {
    return value.map(value => formatDeep(value, data)) as any;
  };

  if (typeof value === 'object' && value) {
    return Object.entries(value).reduce(
      (o, [k, v]) => {
        o[format(k, data)] = formatDeep(v, data);
        return o;
      },
      {}
    );
  }

  return value;
};
