import { JSONType } from '@catalytic/json-schema-validator';
import { expression, literal, LiteralType, OperatorType } from '@catalytic/query';
import { isExpression } from '@catalytic/query-util-is';
import { View } from '@catalytic/react-view';
import { Box, Button, Select, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isIdentifier, isInArrayOperation, isLiteral } from '../../query/query.guards';
import { WhereClause } from '../../query/query.interfaces';
import { ColumnOption } from './Column.interfaces';
import { AnyOption, ARRAY_OPTIONS, BOOLEAN_OPTIONS, NUMBER_OPTIONS, STRING_OPTIONS } from './FilterItem.interfaces';
import { basename } from './Identifier.utils';
import { ListItem } from './ListItem';

export interface FilterItemChangeEvent {
  where: WhereClause
}

export interface FilterItemRemoveEvent {
  where: WhereClause
}

export interface FilterItemProps {
  index: number,
  where: WhereClause,
  columns: ColumnOption[],
  onChange?: (event: FilterItemChangeEvent) => void
  onRemove?: (event: FilterItemRemoveEvent) => void
}

const FilterIdentifier = ({ children }) => <Box width={{ min: "10em", max: "10em" }}>{children}</Box>
const FilterOperator = ({ children }) => <Box width={{ min: "10em", max: "10em" }}>{children}</Box>
const FilterValue = ({ children }) => <Box width={{ min: "18em", max: "18em" }}>{children}</Box>

interface FilterBinaryOperatorProps {
  index: number
  operator?: OperatorType,
  onChange?: (event: { operator?: OperatorType }) => void
}

const FilterBinaryOperator = (props: FilterBinaryOperatorProps) => {
  const [operator, setOperator] = useState<OperatorType | undefined>(props.operator);

  useEffect(
    () => {
      setOperator(props.operator)
    },
    [props.operator]
  )

  const handleChange = useCallback<(event: { operator?: OperatorType }) => void>(
    ({ operator }) => {
      if (props.onChange) {
        props.onChange({ operator });
      }
      setOperator(operator)
    },
    [props.onChange]
  )
  return <Box width={{ min: "7em", max: "7em" }}>{
    operator === undefined || props.index === 0
      ? <Text alignSelf="start">Where</Text>
      : <Select
        labelKey="label"
        valueKey={{ key: 'value', reduce: true }}
        options={[
          { label: 'And', value: OperatorType.AND },
          { label: 'Or', value: OperatorType.OR }
        ]}
        onChange={({ value: operator }) => handleChange({ operator })}
        value={operator}
      />}
  </Box>
}

export const FilterItem = (props: FilterItemProps) => {
  const [where, setWhere] = useState(props.where);
  const [identifier, setIdentifier] = useState<string>(props.where.clause.left.name);
  const [column, setColumn] = useState<ColumnOption | undefined>(props.columns.find(c => c.value === basename(identifier)))
  useEffect(
    () => {
      setIdentifier(props.where.clause.left.name)
    },
    [props.where.clause.left.name]
  )
  useEffect(
    () => {
      setColumn(props.columns.find(c => c.value === basename(identifier)))
    },
    [identifier, props.columns]
  )
  useEffect(
    () => setWhere(props.where),
    [props.where]
  )
  const operators = useMemo<AnyOption[]>(
    () => {
      if (!column) return [];
      let operators: AnyOption[] = STRING_OPTIONS;

      switch (column.schema?.type) {
        case JSONType.NUMBER:
        case JSONType.INTEGER:
          operators = NUMBER_OPTIONS
          break;
        case JSONType.BOOLEAN:
          operators = BOOLEAN_OPTIONS
          break;
        case JSONType.ARRAY:
          operators = ARRAY_OPTIONS
      }

      return operators
    },
    [identifier, column]
  );
  const handleChange = useCallback<(event: { where: WhereClause }) => void>(
    ({ where }) => {
      if (props.onChange) {
        props.onChange({ where });
      }
    },
    [props.onChange]
  )
  const handleBinaryOperatorChange = useCallback<(event: { operator?: OperatorType }) => void>(
    ({ operator }) => {
      where.operation = operator
      setWhere(where)
      handleChange({ where })
    },
    [where, handleChange]
  )
  const handleIdentifierChange = useCallback<(event: { identifier: string }) => void>(
    ({ identifier }) => {
      where.clause.left.name = identifier;
      const column = props.columns.find(c => c.value === basename(identifier))
      const defaultValue = column?.schema?.default ?? column?.node.value;
      if (defaultValue) {
        where.clause.right = literal({ value: defaultValue })
      }

      setIdentifier(identifier)
      setColumn(column)
      setWhere(where)
      handleChange({ where })
    },
    [props.columns, where, handleChange]
  )
  const handleOperatorChange = useCallback<(event: { option: AnyOption }) => void>(
    ({ option }) => {
      where.clause.operation = option.value as any;
      if (option.clause) {
        where.clause.right = option.clause
      } else if (isIdentifier(where.clause.left)) {
        const column = props.columns.find(c => c.value === basename(where.clause.left.name))
        const defaultValue = column?.schema?.default ?? column?.node.value ?? '';
        where.clause.right = literal({ value: defaultValue })
      } else {
        where.clause.right = literal({ value: '' })
      }
      setWhere(where)
      handleChange({ where })
    },
    [props.columns, where, handleChange]
  )
  const handleLiteralChange = useCallback<(event: { value: LiteralType }) => void>(
    ({ value }) => {
      try {
        where.clause.right = column?.schema?.type === 'string'
          ? literal({ value })
          : value === ''
            ? literal({ value: null })
            : column?.schema?.type === 'array' && Array.isArray(value)
              ? expression({
                expression: value.map(value => literal({ value }))
              })
              : literal({ value: JSON.parse(value as any) })

        setWhere(where)
        handleChange({ where })
      } catch (e) {
        console.trace(e)
      }
    },
    [column, where, handleChange]
  )
  const handleRemove = useCallback(
    () => {
      if (props.onRemove) {
        props.onRemove({ where });
      }
    },
    [where, props.onRemove]
  )

  return <ListItem>
    <FilterBinaryOperator index={props.index} operator={where.operation} onChange={handleBinaryOperatorChange} />
    <FilterIdentifier>
      <Select
        labelKey="label"
        size="small"
        valueKey={{ key: 'value', reduce: true }}
        options={props.columns}
        onChange={({ value: identifier }) => handleIdentifierChange({ identifier })}
        value={where.clause.left.name}
      />
    </FilterIdentifier>
    <FilterOperator>
      <Select
        labelKey="label"
        valueKey={{ key: 'value', reduce: true }}
        options={operators}
        onChange={({ option }) => {
          handleOperatorChange({ option })
        }}
        value={where.clause.operation}
      />
    </FilterOperator>
    {
      (isLiteral(where.clause.right) && where.clause.right.value !== null)
        ? (
          <FilterValue>
            <View
              schema={column?.schema}
              node={column?.node as any}
              value={where.clause.right.value}
              onUpdate={({ value }) => {
                handleLiteralChange({ value: value as any })
              }} />
          </FilterValue>
        )
        : isInArrayOperation(where.clause)
          ? (
            <FilterValue>
              <View
                schema={column?.schema}
                node={column?.node as any}
                value={where.clause.right.expression.map(v => v.value)}
                onUpdate={({ value }) => {
                  handleLiteralChange({ value: value as any })
                }} />
            </FilterValue>
          )
          : null
    }
    {props.onRemove ? <Button icon={<FormClose />} onClick={handleRemove} /> : null}
  </ListItem>
}
