import { css } from 'styled-components';
import { PropsWithTheme } from '../theme/theme.interfaces';
import { normalizeFragment } from '../theme/theme.utils';
import { StyleFactoryProps, StyleTransformProps, StyleFactory } from './style.interfaces';

export const createStyleTransform = <TKind = any, TOutput = TKind, TProp = any>({
  transform,
  getKind,
  test = () => true
}: StyleTransformProps<TKind, TOutput, TProp>) => {
  return (props: PropsWithTheme<TProp>) => {
    if (!test(props)) return '';
    const fragment = getKind(props);
    const kind = normalizeFragment(props, fragment);
    return typeof kind === 'string'
      ? kind
      : kind !== undefined
        ? transform(props, kind, fragment)
        : undefined
  }
}

export const createStyleFactory = <TKind = any, TProp = any>({
  applyStyle = () => '',
  getKind,
  test = () => true,
  defaults
}: StyleFactoryProps<TKind, TProp>): StyleFactory<TKind, TProp> => {
  return (props: PropsWithTheme<TProp>, kindProp) => {
    if (!test(props)) return '';
    const fragment = getKind(props);
    const kind = normalizeFragment(props, fragment);
    return typeof kind === 'object'
      ? applyStyle(props, { ...defaults, ...kindProp, ...kind }, fragment)
      : applyStyle(props, kind, fragment)
  }
}

export const createExtendedStyleFactory = <TKind = any, TProp = any>({
  applyStyle: applyExtendedStyle = () => '',
  getKind: getExtendedKind,
  test: testExtended = () => true
}: StyleFactoryProps<TKind, TProp>) =>
  ({
    applyStyle = () => '',
    getKind,
    test = () => true
  }: StyleFactoryProps<TKind, TProp>) =>
    createStyleFactory({
      applyStyle: (props, kind, fragment) => css`
${applyExtendedStyle(props, kind, fragment)}
${applyStyle(props, kind, fragment)}
`,
      getKind: (props: PropsWithTheme<TProp>) => getKind(props) || getExtendedKind(props),
      test: (props: PropsWithTheme<TProp>) => testExtended(props) && test(props)
    })
